import styled from '@emotion/styled'

export const Page = styled.div`
  padding: 2rem 0;
`

export const Roles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.25rem 0.5rem;
  min-width: 300px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    min-width: 100%;
  }
`

export const ConstrainedCell = styled.div`
  max-width: 12vw;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
`
