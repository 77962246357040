import { getCurrentDateString } from '../../api/date.util'

const sortByDate = (entry1, entry2) => {
  const entry1Date = entry1 && entry1.date ? entry1.date : getCurrentDateString()
  const entry2Date = entry2 && entry2.date ? entry2.date : getCurrentDateString()
  return entry1Date.localeCompare(entry2Date)
}

export const getRankByRecruiterExperience = (recruiterExperience = {}, user) => {
  if (user && user.rank) {
    return user.rank
  }
  const militaryServiceRanks = recruiterExperience && recruiterExperience.militaryServiceRank ? recruiterExperience.militaryServiceRank : []
  const sortedMilitaryServiceRanks = [...militaryServiceRanks].sort((a, b) => sortByDate(a, b))
  return sortedMilitaryServiceRanks && !!sortedMilitaryServiceRanks.length && sortedMilitaryServiceRanks[sortedMilitaryServiceRanks.length - 1].data ?
    sortedMilitaryServiceRanks[sortedMilitaryServiceRanks.length - 1].data : ''
}
