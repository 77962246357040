import React, { useState } from 'react'

import { Form } from 'semantic-ui-react'
import { dodid } from '../../forms/validation'

export const DodIdentificationInput = ({ onChange, dodIdentification, userId }) => {

  const [updatedDodIdentification, setUpdatedDodIdentification] = useState(dodIdentification)
  const [error, setError] = useState('')

  return (
    <Form>
      <Form.Field error={ !!error }>
        <Form.Input
          maxLength={ 10 }
          onChange={ event => setUpdatedDodIdentification(event.target.value) }
          onBlur={ () => {
            const dodIdentificationError = dodid(updatedDodIdentification)
            setError(dodIdentificationError)
            if (!dodIdentificationError && dodIdentification !== updatedDodIdentification) {
              onChange(userId, updatedDodIdentification)
            }
          } }
          defaultValue={ dodIdentification }
        />
        { error ? <div data-testid='dod-id-form-error' className='ui red basic label'> { error } </div> : <></> }
      </Form.Field>
    </Form>
  )
}
