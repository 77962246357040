import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'
import { Dropdown, Input } from 'semantic-ui-react'
import { Field } from '../../components/Forms/Lead/Field'
import { Header } from '../../components/Forms/Lead/Header'
import { Label } from '../../components/Forms/Lead/Label'
import { TwoColumnGrid } from '../../components/Forms/Lead/TwoColumnGrid'
import RadioButton from './RadioButton'
import { EDUCATION_TYPE } from './constants'

const StyledDropdown = styled(Dropdown)`
  margin-top: ${({ theme }) => theme.spacing.m}px;
`
const StyledInput = styled(Input)`
  margin-top: ${({ theme }) => theme.spacing.m}px;
`
const AsvabDateField = styled.div`
  grid-column: 1;
  grid-row: 1;
  padding-top: ${({ theme }) => theme.spacing.s}px;
  padding-bottom: ${({ theme }) => theme.spacing.m}px;
  padding-right: ${({ theme }) => theme.spacing.s}px;
  @media (max-width: 767px) {
    grid-column: span 2;
    padding-right: 0;
    padding-top: ${({ theme }) => theme.spacing.s}px;
    padding-bottom: ${({ theme }) => theme.spacing.s}px;
  }
`
Education.propTypes = {
  errors: PropTypes.object,
  dispatch: PropTypes.func,
  dispatchError: PropTypes.func,
}

function Education({ dispatch, dispatchError, errors }) {
  const onFocus = name => () => {
    dispatchError({ ...errors, [name]: false })
  }
  const onChangeRadioButton = name => value => {
    dispatch({ type: name, value })
    if (value !== undefined && value !== null) {
      dispatchError({ ...errors, [name]: false })
    } else {
      dispatchError({ ...errors, [name]: true })
    }
  }

  const onChange = name => (_event, { value }) => {
    dispatch({ type: name, value })
  }
  return (
    <>
      <Header>Education</Header>
      <Field>
        <Label htmlFor='educationType' error={ errors.educationType }>Highest Level of Education</Label>
        <StyledDropdown
          id='educationType'
          data-testid='educationType'
          fluid
          selection
          error={ errors.educationType }
          options={ EDUCATION_TYPE }
          onFocus={ onFocus('educationType') }
          onChange={ onChange('educationType') }
        />
      </Field>
      <Field>
        <RadioButton
          group='asvabTaken'
          label='Have you taken the ASVAB?'
          testid='asvabTaken'
          error={ errors.asvabTaken }
          onChange={ onChangeRadioButton('asvabTaken') }
        />
      </Field>
      <TwoColumnGrid>
        <AsvabDateField >
          <Label htmlFor='asvabDate'>ASVAB Date (optional)</Label>
          <StyledInput
            id='asvabDate'
            data-testid='asvabDate'
            fluid
            type='date'
            onFocus={ onFocus('asvabDate') }
            onChange={ onChange('asvabDate') }
          />
        </AsvabDateField>
      </TwoColumnGrid>
    </>
  )
}

export default Education
