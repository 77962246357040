import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Checkbox } from 'semantic-ui-react'
import { ROLES, ROLE_DISPLAY_NAMES } from '../../config'
import { deleteSpecificRoleSaga, updateRolesSaga, updateSpecificRoleSaga } from '../../redux/sagas/user.sagas'
import { selectAuthUserRoles } from '../../redux/selectors/auth.selectors'
import spacing from '../../theme/spacing'
import { Roles } from './users.page.styles'

class UsersRolesSelector extends Component {
  state = { unauthorizedError: false }

  loadId = (id, type) => `${id}_${type}`

  updateRole = (id, roles) => {
    this.props.updateRoles({ id, roles }, { loadId: this.loadId(id, 'ROLES') })
    this.setState({ unauthorizedError: false })
  }

  updateSpecificRole = (id, role) => {
    this.props.updateSpecificRole({ id, role }, { loadId: this.loadId(id, 'ROLES') })
    this.setState({ unauthorizedError: false })
  }

  deleteSpecificRole = (id, role) => {
    this.props.deleteSpecificRole({ id, role }, { loadId: this.loadId(id, 'ROLES') })
    this.setState({ unauthorizedError: false })
  }

  toggleRole = event => {
    const { userRoles, loggedInUserRoles } = this.props
    const id = event.target.parentNode.dataset.id
    const role = event.target.parentNode.dataset.role
    const roles = userRoles.includes(role) ? userRoles.filter(r => r !== role) : userRoles.concat([role])
    if (([ROLES.ADMIN, ROLES.MASTER_ADMIN, ROLES.MASS_ALERTS].includes(role)) && !loggedInUserRoles.includes(ROLES.MASTER_ADMIN)) {
      this.setState({ unauthorizedError: true })
    } else if ([ROLES.RECRUITER].includes(role) || role.startsWith('role_')) {
      if (userRoles.includes(role)) {
        this.deleteSpecificRole(id, role)
      } else {
        this.updateSpecificRole(id, role)
      }
    } else {
      this.updateRole(id, roles)
    }
  }

  render() {
    const { userRoles, roles, id, loggedInUserRoles } = this.props
    return (
      <>
        { this.state.unauthorizedError && (
          <div style={ { color: 'red' } } data-testid='permission-error'>
            { 'You don\'t have permission to change that user role.' }
          </div>
        ) }
        <Roles>
          { Object.entries(roles).map(
            ([key, value]) => {
              const label = ROLE_DISPLAY_NAMES[value]
              let disabled = false
              if (value === ROLES.GENERAL) {
                disabled = true
              } else if (value === ROLES.STUDENT_RECRUITER) {
                disabled = !loggedInUserRoles.includes(ROLES.MASTER_ADMIN) || !userRoles.includes(ROLES.RECRUITER)
              }
              return (
                <Checkbox
                  style={ { marginRight: spacing.s } }
                  key={ key }
                  label={ label }
                  data-id={ id }
                  data-role={ value }
                  onClick={ this.toggleRole }
                  checked={ roles && userRoles.includes(value) }
                  disabled={ disabled }
                />
              )
            }
          ) }
        </Roles>
      </>
    )
  }
}

UsersRolesSelector.propTypes = {
  roles: PropTypes.object,
  id: PropTypes.string,
  userRoles: PropTypes.array,
  updateRoles: PropTypes.func,
  updateSpecificRole: PropTypes.func,
  deleteSpecificRole: PropTypes.func,
  loggedInUserRoles: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    loggedInUserRoles: selectAuthUserRoles(state),
  }
}

const mapActionsToProps = dispatch => {
  return {
    updateRoles: (params, meta) => dispatch(updateRolesSaga.request(params, meta)),
    updateSpecificRole: (params, meta) => dispatch(updateSpecificRoleSaga.request(params, meta)),
    deleteSpecificRole: (params, meta) => dispatch(deleteSpecificRoleSaga.request(params, meta)),
  }
}

export default connect(mapStateToProps, mapActionsToProps)(UsersRolesSelector)

