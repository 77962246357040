import { ThemeProvider } from '@emotion/react'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SvgAirForce from '../../icons/AirForceLogo'
import SvgSpaceForce from '../../icons/SpaceForceLogo'
import { NAV_ROUTES } from '../../navigation/routes'
import { logPageView } from '../../redux/sagas/amplitudeAnalytics.sagas'
import { DarkPalette } from '../../theme'
import {
  Container,
  Header,
  SectionBody,
  SubTitle,
  Wrapper,
  LogoWrapper
} from './starr.page.styles'

const StarrCitation = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(logPageView.request(NAV_ROUTES.STARR_PAGE))
  }, [dispatch])

  return (
    <ThemeProvider theme={ DarkPalette }>
      <Container>
        <Wrapper>
          <Header>
            <LogoWrapper>
              <SvgAirForce size={ 120 } color='#FFF' />
              <a href='https://www.airforce.com'>www.airforce.com</a>
            </LogoWrapper>
            <LogoWrapper>
              <SvgSpaceForce size={ 120 } color='#FFF' />
              <a href='https://www.spaceforce.com'>www.spaceforce.com</a>
            </LogoWrapper>
          </Header>
          <SubTitle data-testid='sub-title'>Citation to accompany the award of Air and Space commendation medal</SubTitle>
          <SectionBody data-testid='section-body'>
            To <u>Rank and Name</u>,<p />
              
            <u>Rank Full Name</u> distinguished <u>himself/herself/themselves</u> by outstanding achievement supporting Air Force Recruiting Service
            at <u>Base, State</u> from <u>Day Month Year</u> to <u>Day Month Year</u>.<p />
            
            <u>Rank Name</u> acted as an ambassador of the Armed Forces, utilizing <u>his/her/their</u> influence and connection to motivate future
            qualified Department of the Air Force personnel. <u>His/Her/Their</u> superb devotion to duty, extraordinary performance, and
            dedication to mission success resulted in the referral of five driven and extremely capable members. With <u>Rank Name</u>’s
            splendid accomplishment, each of these outstanding referrals subsequently enlisted, greatly contributing to the Air Force
            Recruiting Service goal and Air Force mission success. <u>Rank Name</u>’s positive influence and willingness to support the
            recruiting mission reflects a higher degree of professionalism and commitment to <u>his/her/their</u> country.<p />

            The distinctive accomplishment of <u>Rank Name</u> reflects credit upon <u>himself/herself/themselves</u> and the United States Air
            Force.
          </SectionBody>
        </Wrapper>
      </Container>
    </ThemeProvider>
  )
}

export default withRouter(StarrCitation)
