import PropTypes from 'prop-types'
import * as React from 'react'

function UserSearch(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={props.size} width={props.size} viewBox="0 0 32 32"><title>user search</title>
    <g fill={props.color} class="nc-icon-wrapper"><circle cx="13.475" cy="7" r="7" fill={props.color}></circle>
    <path d="M15.186,24.739c-.516-2.408,.064-4.873,1.592-6.763,.361-.446,.765-.843,1.2-1.196-1.41-.497-2.921-.781-4.503-.781C6.208,16,.289,21.692,0,28.96c-.011,.272,.089,.537,.278,.733,.189,.195,.449,.307,.721,.307H19.146c-1.984-1.096-3.48-3.024-3.96-5.261Z" fill={props.color}></path>
    <path d="M31.707,28.879l-3.298-3.298c.611-1.274,.8-2.787,.321-4.373-.614-2.034-2.333-3.62-4.41-4.066-4.329-.928-8.106,2.849-7.178,7.178,.445,2.078,2.031,3.796,4.066,4.41,1.586,.479,3.099,.289,4.373-.321l3.298,3.298c.391,.391,1.024,.391,1.414,0l1.414-1.414c.391-.391,.391-1.024,0-1.414Zm-12.707-5.879c0-2.206,1.794-4,4-4s4,1.794,4,4-1.794,4-4,4-4-1.794-4-4Z" data-color="color-2"></path></g></svg>
  )
}

UserSearch.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

export default UserSearch
