import { createSelector } from 'reselect'
import { createTrackedLoadsSelector, createIsLoadingSelector, createErrorSelector } from './common.selectors'
import { userActions } from '../constants/user.constants'

export const selectUser = ({ user }) => user

export const selectRoleRequests = createSelector(
  [selectUser],
  ({ roleRequests }) => roleRequests
)

export const selectSingleUser = createSelector(
  [selectUser],
  ({ user }) => user
)

export const selectUsers = createSelector(
  [selectUser],
  ({ users }) => users.asMutable({ deep: true })
)

export const selectRoles = createSelector(
  [selectUser],
  ({ roles }) => roles.asMutable({ deep: true })
)

export const selectLastUserKey = createSelector(
  [selectUser],
  ({ lastKey }) => lastKey || ''
)

export const selectLastRoleRequestKey = createSelector(
  [selectUser],
  ({ lastRoleRequestKey }) => lastRoleRequestKey || ''
)

export const selectRecruiterExperienceData = createSelector(
  [selectUser],
  ({ recruiterExperienceData }) => recruiterExperienceData
)

export const selectRecruiterInformation = createSelector(
  [selectUser],
  ({ recruiterInformation }) => recruiterInformation
)

export const selectUpdateUserLoads =
  createTrackedLoadsSelector(userActions.UPDATE_USER, userActions.UPDATE_ROLES, userActions.UPDATE_SPECIFIC_ROLE, userActions.DELETE_SPECIFIC_ROLE)
export const selectSearchingUsers = createIsLoadingSelector(userActions.FETCH_USERS_BY_EMAIL)
export const selectFetchingUsers = createIsLoadingSelector(userActions.FETCH_USERS)
export const selectIsLoadingRecruiterExperienceData = createIsLoadingSelector(userActions.FETCH_RECRUITER_EXPERIENCE_DATA)
export const selectIsLoadingRoleRequests = createIsLoadingSelector(userActions.FETCH_ROLE_REQUESTS)
export const selectFetchRecruiterErrors = createErrorSelector(userActions.FETCH_RECRUITER)
