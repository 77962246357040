import React from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import {
  Header,
  Main
} from '../LineOfficer.styles'
import { theme as ahaTheme } from '@aimhigh/theme'
import Timeline from '../../../icons/Timeline'
import { HeaderInnerWrapper, StyledProgressBar, TimelineGrid, TimelineGridDot } from './LineOfficerTimeline.styles'
import { mockTimelineItems } from '../lineOfficer.constants'

const COMPLETED = 1

const HEADER_HEIGHT = 76

function LineOfficerTimeline() {
  const handleHeaderIcon = iconName => {
    // eslint-disable-next-line no-alert
    alert(iconName)
  }

  return (
    <ThemeProvider theme={ ahaTheme }>
      <Header $customHeight={ HEADER_HEIGHT }>
        <HeaderInnerWrapper>
          <Timeline color={ ahaTheme.colors.text.white } size={ 40 } onClick={ () => handleHeaderIcon('timeline') } />
          <h1>Application<br />Timeline</h1>
        </HeaderInnerWrapper>
      </Header>
      <Main $customHeight={ HEADER_HEIGHT }>
        <section>
          <TimelineGrid>
            <StyledProgressBar direction='vertical' value={ (COMPLETED / 12) * 100 } color={ ahaTheme.colors.system.actionLight } />
            { mockTimelineItems.map((title, i) => (
              <>
                <TimelineGridDot $covered={ i < COMPLETED } />
                <h2 key={ title }>{ title }</h2>
              </>
            )) }
          </TimelineGrid>
        </section>
      </Main>
    </ThemeProvider>
  )
}

export default withRouter(LineOfficerTimeline)
