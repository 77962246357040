import React, { useState } from 'react'
import { Grid, Header } from 'semantic-ui-react'
import themeSpacing from '../../../theme/spacing'

import UsersSearchForm from '../users.search.form'
import RecruiterActivity from './RecruiterActivity'
import { withRouter } from 'react-router'

function UserActivityPage() {
  const [email, setEmail] = useState('')

  return (
    <div style={ { margin: themeSpacing.l } }>
      <Header as='h1'>
        <Grid>
          <Grid.Row columns='equal'>
            <Grid.Column textAlign='center'>
              <UsersSearchForm onSubmit={ values => setEmail(values.emailText) } />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Header>
      { email ? <div data-testid='UserActivityPage_RecruiterActivityPageWrapper'><RecruiterActivity email={ email } /></div> : <></> }
    </div>
  )
}

export default withRouter(UserActivityPage)
