import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { useSelector, useDispatch } from 'react-redux'
import { reduxForm, hasSubmitFailed, getFormSyncErrors, reset, getFormValues } from 'redux-form'
import SvgAirForce from '../../icons/AirForce'
import { DarkPalette, LightPalette } from '../../theme'
import {
  Header,
  Main,
  Footer,
  FormContainer,
  HorizontalRule,
  PrivacyPolicyLink
} from './afcepLandingPage/AfcepLandingPage.styles'
import { PersonalInformation } from './afcep-sections/PersonalInformation'
import { Demographics } from './afcep-sections/Demographics'
import { Program } from './afcep-sections/Program'
import { Education } from './afcep-sections/Education'
import { AfoqtTesting } from './afcep-sections/AfoqtTesting'
import { PilotCandidateSelectMethod } from './afcep-sections/PilotCandidateSelectMethod'
import { CurrentService } from './afcep-sections/CurrentService'
import { PriorService } from './afcep-sections/PriorService'
import { AfscPreferences } from './afcep-sections/AfscPreferences'
import { Recommendation } from './afcep-sections/Recommendation'
import { Dimmer, Loader } from 'semantic-ui-react'
import { AFCEP_FORM_NAME, SVG_LOGO_HEADER_SIZE } from './afcep.constants'
import { NAV_ROUTES } from '../../navigation/routes'
import TimeoutModal from './TimeoutModal'
import { buildRequestBody } from './AfcepRequestForm.utils'
import { Submit } from './afcep-sections/Submit'
import * as afcepQueries from './afcep.queries'
import { E2O_EVENT_TYPE } from '../../components/event/event.duck'
import { AfcepFormHeader } from './afcep-sections/AfcepFormHeader'

const initialValues = { program: 'OTS', present: false, currentServicePresent: true, pcsmType: 'PCSM', qualDegree: false, gradeRelevance: 'BS' }

const validate = values => {
  const errors = {}

  if (!values.race || values.race.length === 0) {
    errors.race = true
  }

  const afoqtValues = [
    values.afoqtFormVersion,
    values.afoqtTestDate,
    values.afoqtPilot,
    values.afoqtCso,
    values.afoqtAbm,
    values.afoqtAa,
    values.afoqtVerbal,
    values.afoqtQuantative,
  ]
  if (!!afoqtValues.find(value => !!value) && !afoqtValues.find(value => !value)) {
    if (!values.afoqtFormVersion) {
      errors.afoqtFormVersion = 'All AFOQT values are required if one is entered'
    }
    if (!values.afoqtTestDate) {
      errors.afoqtTestDate = 'All AFOQT values are required if one is entered'
    }
    if (!values.afoqtPilot) {
      errors.afoqtPilot = 'Required'
    }
    if (!values.afoqtCso) {
      errors.afoqtCso = 'Required'
    }
    if (!values.afoqtAbm) {
      errors.afoqtAbm = 'Required'
    }
    if (!values.afoqtAa) {
      errors.afoqtAa = 'Required'
    }
    if (!values.afoqtVerbal) {
      errors.afoqtVerbal = 'Required'
    }
    if (!values.afoqtQuantative) {
      errors.afoqtQuantative = 'Required'
    }
  }

  return errors
}

const scrollToTop = () => window && window.scrollTo && window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

function AfcepRequestForm(props) {
  const dispatch = useDispatch()
  const { handleSubmit, location, history } = props
  const urlParams = new URLSearchParams(location.search)
  const eventId = urlParams.get('eventId')

  const [recommendationLetter, setRecommendationLetter] = useState(null)
  const [recommendationLetterError, setRecommendationLetterError] = useState(null)
  const [lastEdit, setLastEdit] = useState(Date.now())

  useEffect(() => {
    if (recommendationLetter) {
      setRecommendationLetterError(null)
    }
  }, [recommendationLetter])

  const submitFailed = useSelector(hasSubmitFailed(AFCEP_FORM_NAME))
  const formErrors = useSelector(getFormSyncErrors(AFCEP_FORM_NAME))
  const allFormValues = useSelector(getFormValues(AFCEP_FORM_NAME))
  const [confirmationEmail, setConfirmationEmail] = useState('')

  const {
    isError: eventIdCheckIsError,
    isLoading: eventIdCheckIsLoading,
  } = afcepQueries.useCheckIfEventIdValid(E2O_EVENT_TYPE, eventId)
  const {
    isError: saveAfcepIsError,
    isLoading: saveAfcepIsLoading,
    isSuccess: saveAfcepIsSuccess,
    isPending: saveAfcepIsPending,
    mutate: saveAfcepMutate,
    error: saveAfcepErrors,
  } = afcepQueries.useSaveAfcepRequest()

  useEffect(() => {
    if (JSON.stringify(allFormValues) !== JSON.stringify(initialValues)) {
      setLastEdit(Date.now())
    }
  }, [allFormValues])

  useEffect(() => {
    if (eventIdCheckIsError) {
      window.location.replace(NAV_ROUTES.AFCEP_INVALID_EVENT)
    }
  }, [eventIdCheckIsError])

  const handleFormSubmit = values => {

    if (!recommendationLetter) {
      setRecommendationLetterError('Recommendation letter is required')
      return
    }

    const body = buildRequestBody(values, eventId)

    const formData = new FormData()
    formData.append('file', recommendationLetter)
    formData.append('e2o', JSON.stringify(body))

    setConfirmationEmail(body.businessEmailAddress)
    saveAfcepMutate(formData)
  }

  useEffect(() => {
    if (submitFailed) {
      scrollToTop()
    }
  }, [submitFailed])

  useEffect(() => {
    if (saveAfcepIsSuccess) {
      history.push(NAV_ROUTES.AFCEP_SUCCESS_PAGE, { email: confirmationEmail })
    }
  }, [saveAfcepIsSuccess, confirmationEmail, history])

  return (
    <ThemeProvider theme={ DarkPalette }>
      <Dimmer.Dimmable dimmed={ saveAfcepIsPending || eventIdCheckIsLoading || saveAfcepIsLoading }>
        <Dimmer active={ saveAfcepIsPending || eventIdCheckIsLoading }>
          <Loader>Submitting</Loader>
        </Dimmer>

        <Header>
          <SvgAirForce size={ SVG_LOGO_HEADER_SIZE } color={ LightPalette.background.level0 } />
        </Header>
        <Main>
          <ThemeProvider theme={ LightPalette }>
            <TimeoutModal
              lastEdit={ lastEdit }
              buttonHandler={ () => setLastEdit(Date.now()) }
              expirationHandler={ () => {
                dispatch(reset(AFCEP_FORM_NAME))
                setRecommendationLetter(null)
                setRecommendationLetterError(null)
              } }
            />
            <form onSubmit={ handleSubmit(handleFormSubmit) } >
              <FormContainer>
                <AfcepFormHeader
                  recommendationLetterError={ recommendationLetterError }
                  hasSubmitErrors={ (formErrors && submitFailed) || saveAfcepIsError }
                />
                <PersonalInformation />
                <Demographics />
                <Program />
                <Education />
                <AfoqtTesting />
                <PilotCandidateSelectMethod />
                <CurrentService />
                <PriorService />
                <AfscPreferences />
                <Recommendation
                  onFileSelected={ file => {
                    setRecommendationLetter(file)
                    setLastEdit(Date.now())
                  } }
                  submitError={ recommendationLetterError }
                  setSubmitError={ setRecommendationLetterError }
                  recommendationFileName={ recommendationLetter ? recommendationLetter.name : '' }
                />
                <HorizontalRule />
                <Submit submitError={ saveAfcepErrors } />
              </FormContainer>
            </form>
          </ThemeProvider>
        </Main>
        <Footer style={ { textAlign: 'center', paddingBottom: '60px' } }>
          <PrivacyPolicyLink href={ NAV_ROUTES.PRIVACY_POLICY_PAGE } rel='noopener noreferrer' target='_blank'>Privacy Policy</PrivacyPolicyLink>
        </Footer>
      </Dimmer.Dimmable>
    </ThemeProvider>
  )
}

export default withRouter(reduxForm({
  form: AFCEP_FORM_NAME,
  validate,
  initialValues,
})(AfcepRequestForm))
