import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { Main } from '../LineOfficer.styles'
import { theme as ahaTheme } from '@aimhigh/theme'
import { ApplicationStepsTitle, StyledSection, StyledProgressBar, ApplicationHeader } from './LineOfficerApplicationSteps.styles'
import DemographicsForm from './Forms/DemographicsForm'
import { LineOfficerApplicationForms, lineOfficerFormDescriptions, lineOfficerFormTitles } from '../lineOfficer.constants'
import ChevronLeft from '../../../icons/ChevronLeft'
import ContactInfoForm from './Forms/ContactInfoForm'
import { NAV_ROUTES } from '../../../navigation/routes'
import EligibilityForm from './Forms/EligibilityForm'
import EducationForm from './Forms/EducationForm'
import CareersForm from './Forms/CareersForm'
import AFOQTForm from './Forms/AFOQTForm'
import PriorServiceForm from './Forms/PriorServiceForm'
import DocumentsForm from './Forms/DocumentsForm'
import OverviewForm from './Forms/OverviewForm'
import PilotForm from './Forms/PilotForm'

const HEADER_HEIGHT = 112

const FORM_ORDER = [
  LineOfficerApplicationForms.Demographics,
  LineOfficerApplicationForms.ContactInfo,
  LineOfficerApplicationForms.Eligibility,
  LineOfficerApplicationForms.Education,
  LineOfficerApplicationForms.Careers,
  LineOfficerApplicationForms.AFOQTTest,
  LineOfficerApplicationForms.PilotTest,
  LineOfficerApplicationForms.PriorService,
  LineOfficerApplicationForms.UploadDocuments,
  LineOfficerApplicationForms.Overview,
]

function LineOfficerDemographics() {
  const [currentFormIndex, setCurrentFormIndex] = useState(0)

  const currentForm = FORM_ORDER[currentFormIndex]

  const handleNext = () => {
    setCurrentFormIndex(currentFormIndex + 1)
  }

  const handleBack = () => {
    if (currentFormIndex === 0) {
      return
    }

    setCurrentFormIndex(currentFormIndex - 1)
  }

  const percentage = (currentFormIndex + 1) / FORM_ORDER.length

  const formProps = {
    onSubmit: handleNext,
  }

  let form
  switch (currentForm) {
    case LineOfficerApplicationForms.Demographics:
      form = <DemographicsForm { ...formProps } />
      break
    case LineOfficerApplicationForms.ContactInfo:
      form = <ContactInfoForm { ...formProps } />
      break
    case LineOfficerApplicationForms.Eligibility:
      form = <EligibilityForm { ...formProps } />
      break
    case LineOfficerApplicationForms.Education:
      form = <EducationForm { ...formProps } />
      break
    case LineOfficerApplicationForms.Careers:
      form = <CareersForm { ...formProps } />
      break
    case LineOfficerApplicationForms.AFOQTTest:
      form = <AFOQTForm { ...formProps } />
      break
    case LineOfficerApplicationForms.PilotTest:
      form = <PilotForm { ...formProps } />
      break
    case LineOfficerApplicationForms.PriorService:
      form = <PriorServiceForm { ...formProps } />
      break
    case LineOfficerApplicationForms.UploadDocuments:
      form = <DocumentsForm { ...formProps } />
      break
    case LineOfficerApplicationForms.Overview:
      form = <OverviewForm />
      break
    default:
  }

  return (
    <ThemeProvider theme={ ahaTheme }>
      <ApplicationHeader $customHeight={ HEADER_HEIGHT }>
        <div onClick={ handleBack }><ChevronLeft color='white' /> Back</div>
        <a href={ NAV_ROUTES.LINE_OFFICER_INITIAL_APPLICATION }>Initial Application</a>
      </ApplicationHeader>
      <Main $customHeight={ HEADER_HEIGHT }>
        <StyledSection>
          <StyledProgressBar size={ 10 } value={ percentage * 100 } color={ ahaTheme.colors.system.actionLight } />
          <ApplicationStepsTitle>
            <h1>{ lineOfficerFormTitles[currentForm] }</h1>
            <p>{ lineOfficerFormDescriptions[currentForm] }</p>
          </ApplicationStepsTitle>
          { form }
        </StyledSection>
      </Main>
    </ThemeProvider>
  )
}

export default withRouter(LineOfficerDemographics)
