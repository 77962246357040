import React from 'react'
import styled from '@emotion/styled'

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background: ${({ theme }) => theme.background.accent};
`

const CardItemDivider = ({ ...props }) => {
  return (
    <Divider
      { ...props }
    />
  )
}

export default CardItemDivider
