export const formatSSN = value => {
  if (!value) {
    return value
  }
  const cleanValue = value.replace(/[^\d]/gu, '')

  if (cleanValue.length < 4) {
    return cleanValue
  } else if (cleanValue.length < 6) {
    return `${cleanValue.slice(0, 3)}-${cleanValue.slice(3)}`
  } else {
    return `${cleanValue.slice(0, 3)}-${cleanValue.slice(3, 5)}-${cleanValue.slice(5, 9)}`
  }
}

export const stripToDigits = value => {
  return value.replace(/[^0-9.]/gu, '')
}
