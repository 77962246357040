import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ProfileCard } from './profile.page.styles'
import { Item } from 'semantic-ui-react'
import { fontSizes } from '../../theme/typography'
import spacing from '../../theme/spacing'
import { getCurrentDateString, getMonthAndYear, getYear } from '../../api/date.util'

const Header = styled(Item.Meta)`
  font-family: "Open Sans", sans-serif;
  font-size: ${fontSizes.m}px !important;
  color: ${props => props.palette.text.default} !important;
  margin-top: ${spacing.xs}px !important;
`
const Meta = styled(Item.Meta)`
  font-size: ${fontSizes.s}px !important;
  font-family: "Open Sans", sans-serif;
  color: ${props => props.palette.text.subtle} !important;
  margin-bottom: ${spacing.xs}px !important;
`

const sortByDate = (entry1, entry2) => {
  const entry1Date = entry1 && entry1.date ? entry1.date : getCurrentDateString()
  const entry2Date = entry2 && entry2.date ? entry2.date : getCurrentDateString()
  return entry1Date.localeCompare(entry2Date)
}
const EMPTY_FIELD_TEXT = 'N/A'

EducationList.propTypes = {
  palette: PropTypes.object.isRequired,
  militaryEducation: PropTypes.array,
  civilianEducation: PropTypes.array,
}

function EducationList({ militaryEducation = [], civilianEducation = [], palette }) {

  const getFormattedDate = (date, includeMonth = false, fallbackToNA = false) => {
    if (!date) {
      return fallbackToNA ? EMPTY_FIELD_TEXT : 'Present'
    }

    const dateText = includeMonth ? getMonthAndYear(date) : getYear(date)
    return dateText
  }

  const getFormattedDegree = (degree, typeOfDegree) => {
    const formattedTypeOfDegree = typeOfDegree && typeOfDegree.trim()
    return formattedTypeOfDegree ? `${degree} (${formattedTypeOfDegree})` : degree
  }

  const renderCivilianEducation = item => {
    const graduationDate = item ? item.graduationDate : null
    const formattedGraduationDate = getFormattedDate(graduationDate, true)
    const formattedDegree = getFormattedDegree(item.degree, item?.typeOfDegree)
    return (
      <Item key={ JSON.stringify(item) }>
        <Item.Content verticalAlign='middle'>
          <Meta palette={ palette }>{ formattedGraduationDate }</Meta>
          <Header palette={ palette }>{ formattedDegree }</Header>
          <Meta palette={ palette }>{ item.school }</Meta>
        </Item.Content>
      </Item>
    )
  }

  const renderMilitaryEducation = item => {
    const completionDate = item ? item.completionDate : null
    const formattedCompletionDate = getFormattedDate(completionDate, true)

    return (
      <Item key={ JSON.stringify(item) }>
        <Item.Content verticalAlign='middle'>
          <Meta palette={ palette }>{ formattedCompletionDate }</Meta>
          <Header palette={ palette }>{ item.school }</Header>
          <Meta palette={ palette }>{ item.location }</Meta>
        </Item.Content>
      </Item>
    )
  }

  const getMilitaryEducationEntries = () => {
    if (militaryEducation && militaryEducation.length) {
      return militaryEducation.map(militaryEntry => {
        return {
          ...militaryEntry,
          type: 'MILITARY_EDUCATION',
          date: militaryEntry.completionDate,
        }
      })
    } else {
      return []
    }
  }

  const getCivilianEducationEntries = () => {
    if (civilianEducation && civilianEducation.length) {
      return civilianEducation.map(civEd => {
        return {
          ...civEd,
          type: 'CIVILIAN_EDUCATION',
          date: civEd.graduationDate,
        }
      })
    } else {
      return []
    }
  }

  const mergedEducationEntries = getMilitaryEducationEntries().concat(getCivilianEducationEntries())
  const sortedEducationEntries = [...mergedEducationEntries].sort((a, b) => sortByDate(b, a))

  return (
    !!sortedEducationEntries && !!sortedEducationEntries.length &&
      <ProfileCard>
        <Item.Group data-testid='EducationList'>
          { sortedEducationEntries.map(education => (
            education.type === 'MILITARY_EDUCATION' ?
              renderMilitaryEducation(education) :
              renderCivilianEducation(education)
          )) }
        </Item.Group>
      </ProfileCard>
  )
}

export default EducationList
