import { ThemeProvider } from '@emotion/react'
import axios from 'axios'
import PropTypes from 'prop-types'
import React, { useEffect, useReducer, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Checkbox, Dimmer, Loader } from 'semantic-ui-react'
import { AimHighButton } from '../../components/Button/Button'
import ComponentLogo from '../../components/ComponentLogo/ComponentLogo'
import { Container } from '../../components/Forms/Lead/Container'
import { Label } from '../../components/Forms/Lead/Label'
import { Logo } from '../../components/Forms/Lead/Logo'
import { Message } from '../../components/Forms/Lead/Message'
import { Page } from '../../components/Forms/Lead/Page'
import { PageDescription } from '../../components/Forms/Lead/PageDescription'
import { Title } from '../../components/Forms/Lead/Title'
import config from '../../config'
import { NAV_ROUTES } from '../../navigation/routes'
import { CATEGORY } from '../../redux/constants/analytics.constants'
import { logEventParams, logPageView } from '../../redux/sagas/amplitudeAnalytics.sagas'
import { DarkPalette, LightPalette } from '../../theme'
import Spacing from '../../theme/spacing'
import Citizenship from './Citizenship'
import ContactInformation from './ContactInformation'
import Demographics from './Demographics'
import Education from './Education'
import FamilyAndChildren from './FamilyAndChildren'
import Other from './Other'
import PersonalInformation from './PersonalInformation'
import { HorizontalLine } from './PrescreenLeadForm.styles'
import PriorService from './PriorService'
import { useIsLinkExpired } from './useIsLinkExpired'

const afreServiceUrl = config.apiUrl

const PRESCREEN_SUBMITTED = 'PRESCREEN_SUBMITTED'

const initialState = {
  firstName: null,
  lastName: null,
  dateOfBirth: null,
  heightFeet: null,
  heightInches: null,
  weight: null,
  email: null,
  phoneNumber: null,
  address: null,
  apartmentSuite: null,
  city: null,
  state: null,
  zipCode: null,
  citizenshipStatus: null,
  maritalStatus: null,
  minorDependents: null,
  adultDependents: null,
  race: null,
  hispanicIndicator: null,
  educationType: null,
  asvabTaken: null,
  militaryService: null,
  recruiterProcess: null,
  tattooPiercingsLocationMeaning: null,
}

PrescreenLeadForm.propTypes = {
  initialValuesState: PropTypes.object, // unit testing purposes only
  initialErrorsState: PropTypes.object, // unit testing purposes only
  location: PropTypes.object,
  match: PropTypes.object,
}

function PrescreenLeadForm({ location, match, initialValuesState = initialState, initialErrorsState = initialState }) {
  const { params } = match

  const dispatch = useDispatch()
  const isExpired = useIsLinkExpired(params.leadId)
  const [submitError, setSubmitError] = useState()
  const [successMessage, setSuccessMessage] = useState()
  const [canSubmit, setCanSubmit] = useState(false)
  const [values, dispatchValue] = useReducer((state, action) => ({ ...state, [action.type]: action.value }), initialValuesState)
  const [errors, dispatchError] = useReducer((state, action) => ({ ...state, ...action }), initialErrorsState)
  const isTokenValidationLoading = false

  const urlParams = new URLSearchParams(location.search)
  const contactEmail = urlParams.get('contactEmail')
  const component = urlParams.get('component')

  useEffect(() => {
    dispatch(logPageView.request(NAV_ROUTES.PRESCREEN_LEAD_FORM))
  }, [dispatch])

  const scrollToTop = () => window && window.scrollTo && window.scrollTo(0, 0)

  const validateForm = () => {
    const formErrors = {}
    let hasErrors = false

    Object.keys(values).forEach(item => {
      if (item === 'apartmentSuite' || item === 'asvabDate') {
        formErrors[item] = false
      } else if (values[item] === undefined || values[item] === null || errors[item]) {
        formErrors[item] = true
        hasErrors = true
      } else {
        formErrors[item] = false
      }
    })

    dispatchError(formErrors)
    return hasErrors
  }

  const formatBody = () => {
    const height = parseInt(values.heightFeet * 12, 10) + parseInt(values.heightInches, 10)
    const adultDependents = parseInt(values.adultDependents, 10)
    const minorDependents = parseInt(values.minorDependents, 10)
    const asvabTaken = !!values.asvabTaken
    const militaryService = !!values.militaryService
    const recruiterProcess = !!values.recruiterProcess
    const tattooPiercingsLocationMeaning = values.tattooPiercingsLocationMeaning ? 'Yes' : 'No'
    const dateOfBirth = new Date(values.dateOfBirth).getTime()
    const asvabDate = values.asvabDate ? new Date(values.dateOfBirth).getTime() : null
    return {
      ...values,
      height,
      adultDependents,
      minorDependents,
      asvabTaken,
      asvabDate,
      militaryService,
      recruiterProcess,
      dateOfBirth,
      streetAddress: values.address,
      tattooPiercingsLocationMeaning,
      country: 'United States of America' }
  }

  const handleSubmit = async () => {
    const hasErrors = validateForm()
    if (hasErrors) {
      scrollToTop()
      setSuccessMessage()
      setSubmitError('The Prescreen form contains errors. Please address these issues and resubmit.')
      return
    }

    const body = formatBody()
    const url = `${afreServiceUrl}/lead/prescreening/${params.leadId}`

    try {
      await dispatch(logEventParams.request({ type: CATEGORY.ACTION, label: PRESCREEN_SUBMITTED, value: { id: params.leadId, email: body.email } }))
      await axios.put(url, body)
      scrollToTop()
      setSubmitError()
      setSuccessMessage('Prescreen sent successfully! Please close your browser window.')
    } catch (error) {
      scrollToTop()
      setSuccessMessage()
      setSubmitError(error.response.data.error)
    }
  }

  useEffect(() => {
    if (isExpired) {
      setSubmitError('Your prescreening has expired. Please contact your recruiter.')
    }
  }, [isExpired])

  const contactMessage = contactEmail ? ` For questions please contact ${contactEmail}.` : ' For questions please contact your recruiter.'
  const recruiterComponent = component ? component : 'AF'

  return (
    <ThemeProvider theme={ DarkPalette }>
      <Helmet>
        <meta property='og:url' content={ window.location.href } />
        <meta property='og:type' content='image' />
        <meta property='og:title' content='' />
        <meta property='og:description' content='' />
      </Helmet>
      <Dimmer.Dimmable dimmed={ isTokenValidationLoading }>
        <Dimmer active={ isTokenValidationLoading }>
          <Loader>Loading</Loader>
        </Dimmer>
        <Page>
          <Logo>
            <ComponentLogo recruiterComponent={ recruiterComponent } />
          </Logo>
          <ThemeProvider theme={ LightPalette }>
            <Container>
              <Title>Prescreening Questionnaire</Title>
              <PageDescription>
                Please read each question carefully and answer them to the best of your knowledge.
                { contactMessage }
              </PageDescription>
              <HorizontalLine />
              <Message hasError={ !!submitError }>{ submitError }{ successMessage }</Message>
              <PersonalInformation dispatch={ dispatchValue } dispatchError={ dispatchError } errors={ errors } />
              <ContactInformation dispatch={ dispatchValue } dispatchError={ dispatchError } errors={ errors } />
              <Citizenship dispatch={ dispatchValue } dispatchError={ dispatchError } errors={ errors } />
              <FamilyAndChildren dispatch={ dispatchValue } dispatchError={ dispatchError } errors={ errors } />
              <Demographics dispatch={ dispatchValue } dispatchError={ dispatchError } errors={ errors } />
              <Education dispatch={ dispatchValue } dispatchError={ dispatchError } errors={ errors } />
              <PriorService dispatch={ dispatchValue } dispatchError={ dispatchError } errors={ errors } />
              <Other dispatch={ dispatchValue } dispatchError={ dispatchError } errors={ errors } />
              <HorizontalLine />
              <Checkbox
                data-testid='verify-prescreen-information'
                label={ <Label>I verify that the information provided is accurate to the best of my knowledge.</Label> }
                style={ { paddingTop: `${Spacing.l}px`, paddingBottom: `${Spacing.l}px` } }
                onChange={ (_event, { checked }) => setCanSubmit(!isExpired && checked) }
              />
              <AimHighButton
                disabled={ !canSubmit }
                fluid
                primary
                size='large'
                type='button'
                name='Submit'
                style={ { maxWidth: '350px' } }
                onClick={ handleSubmit }>
                Submit & Send
              </AimHighButton>
            </Container>
          </ThemeProvider>

        </Page>
      </Dimmer.Dimmable>
    </ThemeProvider>
  )
}

export default withRouter(PrescreenLeadForm)
