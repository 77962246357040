import axios from 'axios'

export const get = (url, queryParams, headers) => {
  return axios({
    method: 'get',
    url: `${url}${queryParams ? '?' : ''}${new URLSearchParams(queryParams).toString()}`,
    headers,
  })
}

export default {
  get,
}
