import React from 'react'
import { Form, FormSelect, FormTextField, Button, FormRadio, FormDatepicker } from '@aimhigh/web-components/web'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { fourColStyle, RadioError, RadioWrapper } from '../../LineOfficer.styles'

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  column-gap: ${({ theme }) => theme.spacing.m}px;
  row-gap: ${({ theme }) => theme.spacing.xl}px;

  > * {
    grid-column: span 2;
  }
`

const PilotForm = ({ onSubmit }) => {
  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
    },
  })

  const errors = formHooks.formState.errors

  const handleFormSubmit = values => {
    // eslint-disable-next-line no-alert
    alert(
      `--form values--\n${Object.keys(values)
        .map(key => {
          const value = values[key]
          return `${key}: ${
            typeof value === 'object' ? JSON.stringify(value) : value
          }`
        })
        .join('\n')}`
    )

    onSubmit()
  }

  return (
    <StyledForm formHooks={ formHooks } onSubmit={ handleFormSubmit }>
      <FormSelect label='Pilot License' name='pilot_license' options={ [] } placeholder='Select' style={ fourColStyle } dark />

      <RadioWrapper $isError={ errors?.taken } style={ fourColStyle }>
        <p>Have you taken the Pilot Test?</p>
        <FormRadio label='Yes' name='taken' value={ 1 } dark />
        <FormRadio label='No' name='taken' value={ 0 } dark />
        { errors?.taken && <RadioError>{ errors.taken.message }</RadioError> }
      </RadioWrapper>

      <FormDatepicker label='PCSM Test Date' name='pcsm_date' placeholder='MM/YYYY' dark />
      <FormTextField label='PCSM Score' name='pcsm_score' dark />

      <FormTextField label='Flight Hours' name='flight_hours' type='number' dark />

      <Button type='submit' style={ fourColStyle } dark>Next</Button>
    </StyledForm>
  )
}

export default PilotForm
