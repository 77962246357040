import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import config from '../../config'

const afreServiceUrl = config.apiUrl

export const useIsLinkExpired = leadId => {
  const [isExpired, setIsExpired] = useState()
  const requestPrescreenLinkStatus = useCallback(async () => {
    const url = `${afreServiceUrl}/lead/prescreening/${leadId}`
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        setIsExpired(false)
      } else {
        setIsExpired(true)
      }
    } catch (error) {
      setIsExpired(true)
    }
  }, [leadId])
  useEffect(() => {
    (async () => {
      await requestPrescreenLinkStatus()
    })()
  }, [requestPrescreenLinkStatus])

  return isExpired
}
