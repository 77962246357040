import { ThemeProvider } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { Checkbox, Dimmer, Embed } from 'semantic-ui-react'
import { AimHighButton } from '../../components/Button/Button'
import ComponentLogo from '../../components/ComponentLogo/ComponentLogo'
import { Container } from '../../components/Forms/Lead/Container'
import { Label } from '../../components/Forms/Lead/Label'
import { Logo } from '../../components/Forms/Lead/Logo'
import { Message } from '../../components/Forms/Lead/Message'
import { Page } from '../../components/Forms/Lead/Page'
import { PageDescription } from '../../components/Forms/Lead/PageDescription'
import { Title } from '../../components/Forms/Lead/Title'
import { Loader } from '../../forms/form.styles'
import { NAV_ROUTES } from '../../navigation/routes'
import { CATEGORY } from '../../redux/constants/analytics.constants'
import { logEventParams, logPageView } from '../../redux/sagas/amplitudeAnalytics.sagas'
import { DarkPalette, LightPalette } from '../../theme'
import spacing from '../../theme/spacing'
import { fontSizes } from '../../theme/typography'
import { putId2aWatched, useId2aInfo } from './leadsQueries'

Id2aTrainingVideoForm.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
}

const ID2A_SUBMITTED = 'ID2A_TRAINING_VIDEO_WATCHED'

function Id2aTrainingVideoForm({ location, match }) {
  const { params } = match

  const dispatch = useDispatch()
  const [submitError, setSubmitError] = useState()
  const [successMessage, setSuccessMessage] = useState()
  const [canSubmit, setCanSubmit] = useState(false)
  const {
    error: id2aInfoError,
    isError: isId2aInfoError,
    data: id2aInfoData,
    isLoading: id2aInfoLoading,
    isSuccess: isId2aInfoSuccess,
  } = useId2aInfo(params.leadId)

  useEffect(() => {
    dispatch(logPageView.request(NAV_ROUTES.ID2A_TRAINING_VIDEO_FORM))
  }, [dispatch])
  
  const urlParams = new URLSearchParams(location.search)
  const component = urlParams.get('component') ?? 'AF'
  const hasAlreadySubmitted = id2aInfoData?.id2aStatus === 'ACCEPTED'
  const id2aErrorMessageResponse = id2aInfoError?.response?.data?.error
  const hasError = !!submitError || !!isId2aInfoError
  const hasAlreadyVerified = !!successMessage || hasAlreadySubmitted
  const checkboxLabelStyle = {
    paddingTop: `${spacing.l}px`,
    paddingBottom: `${spacing.l}px`,
    fontSize: '16px',
  }
  const hasAlreadySubmittedMessage = 'You\'ve already watched this video. Please contact your recruiter if you have questions.'
  const ID2A_YOUTUBE_ID = 'SNCwvsX3X5s'

  const scrollToTop = () => window?.scrollTo?.(0, 0)

  const handleSubmit = async () => {
    dispatch(logEventParams.request({
      type: CATEGORY.ACTION,
      label: 'ID2A_TRAINING_VIDEO_WATCHED',
    }))
    
    const response = await putId2aWatched(params.leadId)
    
    scrollToTop()
    
    if (response.error) {
      setSuccessMessage('')
      setSubmitError(response.error)
    } else {
      setSubmitError('')
      setSuccessMessage('Thank you for watching this video. Please contact your recruiter if you have questions.')
      dispatch(logEventParams.request({ type: CATEGORY.ACTION, label: ID2A_SUBMITTED, value: { leadId: params.leadId } }))
    }
  }

  const getStatusMessage = () => {
    let message = ''
    if (submitError) {
      message = submitError
    } else if (successMessage) {
      message = successMessage
    } else if (id2aErrorMessageResponse) {
      message = id2aErrorMessageResponse
    } else if (hasAlreadySubmitted) {
      message = hasAlreadySubmittedMessage
    }
    return (
      <Message style={ { fontSize: fontSizes.m, paddingBottom: spacing.l } } hasError={ hasError }>
        { message }
      </Message>
    )
  }

  const renderSubmitButtons = () => {
    return (
      <>
        <Checkbox
          disabled={ hasAlreadyVerified || !!id2aInfoError }
          data-testid='verify-id2a-video-watched'
          label={ <Label>I verify that I have watched this video and understand its content.</Label> }
          style={ checkboxLabelStyle }
          onChange={ (_event, { checked }) => setCanSubmit(!!checked && isId2aInfoSuccess) }
        />
        <AimHighButton
          disabled={ !canSubmit || hasAlreadyVerified }
          fluid
          primary
          size='large'
          type='button'
          name='Submit'
          style={ { maxWidth: '350px' } }
          onClick={ handleSubmit }>
          Confirm Completion
        </AimHighButton>
      </>
    )
  }
  
  return (
    <ThemeProvider theme={ DarkPalette }>
      <Helmet>
        <meta property='og:url' content={ window.location.href } />
        <meta property='og:type' content='image' />
        <meta property='og:title' content='' />
        <meta property='og:description' content='' />
      </Helmet>
      <Dimmer.Dimmable dimmed={ id2aInfoLoading }>
        <Dimmer active={ id2aInfoLoading }>
          <Loader>Loading</Loader>
        </Dimmer>
        <Page>
          <Logo>
            <ComponentLogo recruiterComponent={ component } />
          </Logo>
          <ThemeProvider theme={ LightPalette }>
            <Container>
              <Title>ID2A Training Video</Title>
              { !hasAlreadySubmitted && (
                <PageDescription>
                  Please watch the following video.
                  Once complete, please verify that you have watched and understand its content and confirm completion.
                </PageDescription>
              ) }
              { getStatusMessage() }
              <Embed id={ ID2A_YOUTUBE_ID } source='youtube' />
              { !hasAlreadySubmitted && renderSubmitButtons() }
            </Container>
          </ThemeProvider>
        </Page>
      </Dimmer.Dimmable>
    </ThemeProvider>
  )
}

export default withRouter(Id2aTrainingVideoForm)
