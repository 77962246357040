import styled from '@emotion/styled'
import { Button } from 'semantic-ui-react'
import spacing from '../../theme/spacing'
import { borderRadius, fontSizes } from '../../theme/typography'

export const FindOrderContent = styled.div`
  background-color: ${props => props.theme.background.highlight};
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100vh;
`

export const FindOrderFormWrapper = styled.div`
  margin: ${spacing.m}px;
  margin-left: ${spacing.l}px;
  width: 15%;
`

export const FindOrderFormFieldWrapper = styled.div`
  margin-top: ${spacing.s}px;
  margin-bottom: ${spacing.m}px;
`

export const FindOrderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`

export const FindOrderFormButton = styled(Button)`
  background-color: ${props => (props.backgroundcolor ? props.backgroundcolor : props.theme.background.positive)}!important;
  color: ${props => props.theme.text.utility}!important;
  width: 100%;
  font-size: ${fontSizes.s};
  padding: ${spacing.m}px;
  border-radius: ${borderRadius}px;
  border: none;
  font-family: 'Open Sans', sans-serif;
`
