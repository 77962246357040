import { ThemeProvider } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { Dimmer, Grid, Input, Loader, Modal, Table } from 'semantic-ui-react'
import { NAV_ROUTES } from '../../navigation/routes'
import { selectIsOpaAdmin } from '../../redux/selectors/auth.selectors'
import { LightPalette } from '../../theme'
import { MtiEmailModal } from '../components/Modals/MtiEmailInputModal'
import { NewOpaPageHeader } from '../components/PageTitle/OpaPageHeader'
import { useDeleteMtiEmail, useGetMtiEmails } from '../reactQueries/opaQueries'
import { EmailListContent, HalfWidthTable, StickyEmailTableHeader } from './MtiEmailManagement.styles'
import { ActionButton, CancelButton, DangerButton, NegativeIcon, ScrollablePage, TableHeaderCell, TableItem } from './Opa.styles'

const COLUMNS = {
  value: 'value',
}

const SORTED_TYPES = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
}

const isSortedByColumn = (sortedByColumn, column, sortType) => {
  return sortedByColumn === column ? sortType : null
}

const resortEmailList = (list, sortColumn, sortedDescending) => {
  return [...list].sort((a, b) => {
    const obj1 = sortedDescending ? a : b
    const obj2 = sortedDescending ? b : a
    if (obj1[sortColumn] > obj2[sortColumn]) {
      return -1
    }
    if (obj2[sortColumn] > obj1[sortColumn]) {
      return 1
    }

    return 0
  })
}

export const MtiEmailManagement = () => {
  const queryClient = useQueryClient()

  const isOpaAdmin = useSelector(selectIsOpaAdmin)
  const [emailList, setEmailList] = useState([])
  const [emailSearchValue, setEmailSearchValue] = useState('')
  const [emailModalOpen, setEmailModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedEmail, setSelectedEmail] = useState(null)
  const [sortedByColumn, setSortedByColumn] = useState(COLUMNS.value)
  const [sortedDescending, setSortedDescending] = useState(false)

  const {
    isLoading: mtiEmailsLoading,
    data: mtiEmailList,
  } = useGetMtiEmails()
  const {
    isLoading: deleteMtiEmailLoading,
    mutate: deleteMtiEmail,
  } = useDeleteMtiEmail(queryClient)
  const deleteMtiEmailHandler = email => {
    setSelectedEmail(email)
    setDeleteModalOpen(true)
  }

  useEffect(() => {
    if (mtiEmailList) {
      const filteredValues = mtiEmailList.filter(emailObject => emailObject.value.toUpperCase().includes(emailSearchValue))
      setEmailList(resortEmailList(filteredValues, sortedByColumn, sortedDescending))
    }
  }, [emailSearchValue, mtiEmailList, sortedDescending, sortedByColumn])

  const sortType = sortedDescending ? SORTED_TYPES.DESCENDING : SORTED_TYPES.ASCENDING

  const handleSearch = (_e, data) => {
    const searchValue = data.value.toUpperCase()
    setEmailSearchValue(searchValue)
  }

  const changeSortType = (currentColumn, newColumn) => {
    if (currentColumn === newColumn) {
      setSortedDescending(!sortedDescending)
    } else {
      setSortedByColumn(newColumn)
    }
  }

  const showSpinner = mtiEmailsLoading || deleteMtiEmailLoading
  return (
    <>
      <ThemeProvider theme={ LightPalette }>
        <MtiEmailModal
          isOpen={ emailModalOpen }
          setOpen={ setEmailModalOpen }
        />
        {
          selectedEmail &&
            <Modal
              open={ deleteModalOpen }>
              <Modal.Header data-testid='confirm-delete-header'>Confirm Delete</Modal.Header>
              <Modal.Content data-testid='confirm-delete-text'>
                <p>Are you sure you want to delete &quot;{ selectedEmail.value }&quot;?</p>
              </Modal.Content>
              <Modal.Actions>
                <CancelButton onClick={ () => {
                  setDeleteModalOpen(false)
                  setSelectedEmail(null)
                } }>
                  Cancel
                </CancelButton>
                <DangerButton
                  content='Delete'
                  icon='trash alternate outline'
                  data-testid='danger-button'
                  onClick={ () => {
                    deleteMtiEmail({ emailId: selectedEmail.sort })
                    setDeleteModalOpen(false)
                    setSelectedEmail(null)
                  } }
                />
              </Modal.Actions>
            </Modal>
        }
        <ScrollablePage>
          <Dimmer.Dimmable dimmed={ showSpinner }>
            <Dimmer active={ showSpinner } page>
              <Loader>Loading</Loader>
            </Dimmer>
            <NewOpaPageHeader
              hideNav={ !isOpaAdmin }
              pageRoute={ NAV_ROUTES.EMAIL_MANAGEMENT_PAGE }
              pageTitle='MTI Email Management'
            />
            <EmailListContent>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <ActionButton
                      key='addEmailAction'
                      data-testid='add-email-button'
                      width={ 2 }
                      onClick={ () => {
                        setSelectedEmail(null)
                        setEmailModalOpen(true)
                      }
                      }>
                      Add Email
                    </ActionButton>
                    <Input
                      data-testid='email-search-input'
                      key='searchEmailInput'
                      width={ 3 }
                      icon='search'
                      placeholder='Search...'
                      onChange={ handleSearch }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    { emailList && emailList.length > 0 ? (
                      <HalfWidthTable celled striped sortable>
                        <StickyEmailTableHeader data-testid='table-header'>
                          <Table.Row data-testid='table-row'>
                            <TableHeaderCell
                              data-testid='email-table-header-cell'
                              sorted={ isSortedByColumn(sortedByColumn, COLUMNS.value, sortType) }
                              onClick={ () => changeSortType(COLUMNS.value, sortedByColumn) }>
                              Email
                            </TableHeaderCell>
                            <TableHeaderCell data-testid='actions-table-header-cell'>Actions</TableHeaderCell>
                          </Table.Row>
                        </StickyEmailTableHeader>
                        <Table.Body data-testid='table-body'>
                          {
                            emailList.map(emailObject => (
                              <Table.Row
                                key={ emailObject.value }
                                data-testid='email-row'
                                emailvalue={ emailObject.value }>
                                <Table.Cell width='14' data-testid='email-table-cell'>{ emailObject.value }</Table.Cell>
                                <Table.Cell width='2' data-testid='actions-table-cell'>
                                  <TableItem>
                                    <NegativeIcon
                                      link
                                      title='Delete MTI Email'
                                      name='trash alternate outline'
                                      size='large'
                                      onClick={ () => deleteMtiEmailHandler(emailObject) }
                                    />
                                  </TableItem>
                                </Table.Cell>
                              </Table.Row>
                            ))
                          }
                        </Table.Body>
                      </HalfWidthTable>
                    ) : (
                      <p data-testid='no-results-found-text'>No Results Found</p>
                    ) }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </EmailListContent>
          </Dimmer.Dimmable>
        </ScrollablePage>
      </ThemeProvider>
    </>
  )
}
