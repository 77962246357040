import styled from '@emotion/styled'
import { Grid } from 'semantic-ui-react'

export const VariationGrid = styled(Grid)`
  background-color: ${props => (props.isdirty ? props.theme.background.level2 : props.theme.background.level0)};
  border-bottom: 1px solid ${props => props.theme.background.level2};
`

export const StickyButtonDiv = styled.div`
  position: sticky;
  top: 100px;
  z-index: 3;
  background-color: ${props => props.theme.background.level0};
  padding: ${props => props.theme.spacing.xs}px;
  width: 100%;
`
