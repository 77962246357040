import { withOpacity } from '@aimhigh/theme'
import styled from '@emotion/styled'

export const HeaderSubTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.s}px;
  font-family: ${({ theme }) => theme.fontFamilies.stratum2};
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-align: center;

  background: ${({ theme }) => theme.colors.text.default};

  border-radius: ${({ theme }) => theme.borderRadius / 4}px;
  padding: ${({ theme }) => `${theme.spacing.xs}px ${theme.spacing.s}px`};
`

export const DashboardStatus = styled.div`
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing.xs}px;

    color: ${({ theme }) => theme.colors.brand.brandDark};
    text-align: center;
    text-transform: uppercase;

    margin: ${({ theme }) => `-${theme.spacing.xl}px -${theme.spacing.m}px ${theme.spacing.m}`}px;
    padding: ${({ theme }) => `${theme.spacing.s}px ${theme.spacing.m}px`};
    background: ${({ theme }) => theme.colors.system.actionLight};

    > h3 {
        font-size: ${({ theme }) => theme.fontSizes.s}px;
        line-height: ${({ theme }) => theme.fontSizes.s}px;
        font-family: ${({ theme }) => theme.fontFamilies.stratum2};
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        color: ${({ theme }) => withOpacity(theme.colors.brand.brandDark, 0.75)};

        margin: 0;
    }

    > p {
        font-size: ${({ theme }) => theme.fontSizes.xl}px;
        line-height: ${({ theme }) => theme.fontSizes.xl}px;
        font-family: ${({ theme }) => theme.fontFamilies.stratum2};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
`

export const DashboardContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    column-gap: ${({ theme }) => theme.spacing.xm}px;
    row-gap: ${({ theme }) => theme.spacing.m}px;

    > h2 {
        margin: 0;

        font-size: ${({ theme }) => theme.fontSizes.m}px;
        line-height: ${({ theme }) => theme.fontSizes.m}px;
        font-family: ${({ theme }) => theme.fontFamilies.stratum2};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        color: ${({ theme }) => theme.colors.text.white};

        text-transform: uppercase;
    }
`

export const DashboardLabelSection = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;

    column-gap: ${({ theme }) => theme.spacing.xm}px;

    border-radius: ${({ theme }) => theme.borderRadius}px;
    background: ${({ theme }) => withOpacity(theme.colors.background.level0, 0.05)};
    border: 1px solid ${({ theme }) => withOpacity(theme.colors.text.white, 0.25)};

    padding: ${({ theme }) => `${theme.spacing.s}px ${theme.spacing.m}px`};

    text-transform: uppercase;

    > svg {
        grid-row: span 2;
    }

    > h2 {
        font-size: ${({ theme }) => theme.fontSizes.m}px;
        font-family: ${({ theme }) => theme.fontFamilies.stratum2};
        font-weight: ${({ theme }) => theme.fontWeights.bold};

        color: ${({ theme }) => theme.colors.text.white};

        margin: 0;
    }

    > p {
        font-size: ${({ theme }) => theme.fontSizes.xs}px;
        font-family: ${({ theme }) => theme.fontFamilies.stratum2};
        font-weight: ${({ theme }) => theme.fontWeights.medium};

        color: ${({ theme }) => withOpacity(theme.colors.text.white, 0.5)};

        margin: 0;
    }
`

export const DashboardPrimarySection = styled.div`
    grid-column: span 2;

    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;

    gap: ${({ theme }) => theme.spacing.xm}px;

    border-radius: ${({ theme }) => theme.borderRadius}px;
    background: ${({ theme }) => withOpacity(theme.colors.background.level0, 0.1)};

    padding: ${({ theme }) => theme.spacing.m}px;

    h2 {
        font-size: ${({ theme }) => theme.fontSizes.m}px;
        font-family: ${({ theme }) => theme.fontFamilies.openSans};
        font-weight: ${({ theme }) => theme.fontWeights.bold};

        color: ${({ theme }) => theme.colors.text.white};

        margin: 0;
    }

    p {
        font-size: ${({ theme }) => theme.fontSizes.xm}px;
        font-family: ${({ theme }) => theme.fontFamilies.openSans};
        font-weight: ${({ theme }) => theme.fontWeights.regular};

        color: ${({ theme }) => withOpacity(theme.colors.text.white, 0.75)};

        margin: 0;
    }
`

export const ProgressInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing.s}px;
`

export const CurrentTaskInnerWrapper = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;

    h2 {
        grid-column: span 2;
    }

    column-gap: ${({ theme }) => theme.spacing.xs}px;
`

export const FinishPrimarySection = styled(DashboardPrimarySection)`
    grid-template-columns: unset;

    gap: ${({ theme }) => theme.spacing.l}px;
`

export const FinishInnerWrapper = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;

    row-gap: ${({ theme }) => theme.spacing.xs}px;
    column-gap: ${({ theme }) => theme.spacing.xm}px;
`

