import styled from '@emotion/styled'
import spacing from '../../theme/spacing'

export const IconCheckboxWrapper = styled.div`
  display: flex;
  width: 50%;
  margin-bottom: ${spacing.m}px;
  cursor: pointer;
`

export const IconCheckboxLabel = styled.label`
  cursor: pointer;
  user-select: none;
  padding-left: ${spacing.s}px;
`

export const IconCheckboxIconWrapper = styled.div`
  cursor: pointer;
`
