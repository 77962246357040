import React from 'react'
import PropTypes from 'prop-types'
import { useGetRecruiterFromCmsByEmail, useGetUserByEmail } from '../userQueries'
import { Divider, Grid, Header, Message, Table } from 'semantic-ui-react'
import theme from '../../../theme'

RecruiterActivity.propTypes = {
  email: PropTypes.string.isRequired,
}

function RecruiterActivity(props) {
  const RecruiterInfo = ({ email, billetCode, recruiterComponent }) => {
    return (
      <Table celled definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Email</Table.Cell>
            <Table.Cell>{ email }</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Billet Code</Table.Cell>
            <Table.Cell>{ billetCode }</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Component</Table.Cell>
            <Table.Cell>{ recruiterComponent }</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    )
  }
  const { isLoading: loadingUserInfo, data: userInfo } = useGetUserByEmail({ email: props.email })
  const { isLoading: loadingRecruiterInfoFromCms, data: cmsRecruiterInfo } = useGetRecruiterFromCmsByEmail({ email: props.email })

  if (!props.email) {
    return (
      <div>
        Missing Recruiter email address
      </div>
    )
  }
  if (loadingUserInfo || loadingRecruiterInfoFromCms) {
    return (<div>Loading content...</div>)
  } else if (!userInfo || !userInfo[0]) {
    return <Message error header={ `Missing user data for ${props.email}` } />
  }

  return (
    <div>
      <Header as='h1' style={ { margin: theme.spacing.l } } textAlign='center'>User Activity for { props.email }</Header>
      <Divider hidden />
      <Grid container columns={ 2 } stackable>
        <Grid.Column>
          <Header as='h3'>Aim High Recruiter Data</Header>
          <RecruiterInfo
            billetCode={ userInfo[0].recruiterCode }
            email={ userInfo[0].email }
            recruiterComponent={ userInfo[0].recruiterComponent }
          />
        </Grid.Column>

        <Grid.Column>
          <Header as='h3'>AFRISS-TF Data</Header>
          { cmsRecruiterInfo && cmsRecruiterInfo[0] ?
            <RecruiterInfo
              billetCode={ cmsRecruiterInfo[0].billetCode }
              email={ cmsRecruiterInfo[0].email }
              recruiterComponent={ cmsRecruiterInfo[0].component }
            /> :
            <Message error header={ `Missing cms recruiter data for ${props.email}` } /> }
        </Grid.Column>
        <Grid.Column width={ 16 }>
          <Header as='h3'> Other Aim High Data</Header>
          <Table celled definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Avatar</Table.Cell>
                <Table.Cell>{ userInfo[0].avatar }</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Squadron</Table.Cell>
                <Table.Cell>{ userInfo[0].squadronName }</Table.Cell>
                <Table.Cell>{ userInfo[0].squadronId }</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Flight</Table.Cell>
                <Table.Cell>{ userInfo[0].flightName }</Table.Cell>
                <Table.Cell>{ userInfo[0].flightId }</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default RecruiterActivity
