import * as htmlDecoder from 'he'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SHARE_APP } from '../../navigation/routes'
import { shareContentTypes } from '../../redux/constants/share.constants'
import { getAppShareSaga, selectAppShare, selectErrorLoadingAppShare } from './appShare.duck'
import Shareable from '../Share/Shareable'
import InvalidShareLink from '../../pages/share/InvalidShareLink'

const HtmlTagRegex = /(<([^>]+)>)/igu
function removeHtml(html) {
  if (html) {
    let text = htmlDecoder.decode(html)
    text = text.replace(/\n/gu, '')
    text = text.replace(HtmlTagRegex, '')

    return text
  }

  return null
}

function AppSharePage() {
  const dispatch = useDispatch()
  const appShare = useSelector(selectAppShare)
  const errorLoadingAppShare = useSelector(selectErrorLoadingAppShare)

  const [imageUrl, setImageUrl] = useState('')
  const [title, setTitle] = useState('')

  useEffect(() => {
    dispatch(getAppShareSaga.request())
  }, [dispatch])

  useEffect(() => {
    setImageUrl(appShare?.image?.url ? appShare.image.url : '')
    setTitle(appShare?.title ? appShare.title : '')
  }, [appShare])

  if (errorLoadingAppShare) {
    return <InvalidShareLink />
  }
  return (
    <Shareable
      sharePath={ SHARE_APP }
      imageUrl={ imageUrl }
      title={ appShare && `${title}` }
      shareContentType={ shareContentTypes.SHARE }>
      { appShare && appShare.description && removeHtml(appShare.description) }
    </Shareable>
  )
}

export default withRouter(AppSharePage)
