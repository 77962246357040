import config from '../../config'
import AsyncSaga from '../../redux/sagas/util/AsyncSaga'
import afreCmsService from '../../api/afre.cms.service'
import { call, put, takeLatest } from 'redux-saga/effects'
import lodashSortBy from 'lodash.sortby'
import immutable from 'seamless-immutable'
import { createErrorSelector } from '../../redux/selectors/common.selectors'

const afreCmsServiceUrl = config.afreCmsServiceUrl

export const GET_EVENTS = 'GET_EVENTS'
export const GET_EVENT = 'GET_EVENT'

export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT'
export const TOGGLE_EVENT_MODAL = 'TOGGLE_EVENT_MODAL'
export const E2O_EVENT_TYPE = 'e2o'

const eventTypes = {
  sch: require(`../../assets/sch.jpg`),
  mtr: require('../../assets/mtr.jpg'),
  com: require('../../assets/com.jpg'),
  stm: require('../../assets/stm.jpg'),
  air: require('../../assets/air.jpg'),
  spr: require('../../assets/spr.jpg'),
  ges: require('../../assets/ges.jpg'),
  airforce: require('../../assets/airforce.jpg'),
  af: require('../../assets/af.png'),
  afr: require('../../assets/afr.png'),
  ang: require('../../assets/ang.png'),
  ST: require('../../assets/STEM.jpg'),
  JF: require('../../assets/JobFairs.jpg'),
  SP: require('../../assets/Sports.jpg'),
  TT: require('../../assets/TourForTheTroops.jpg'),
  AS: require('../../assets/AirShows.jpg'),
}

export const getEventImage = event => {
  if (event.image) {
    return { uri: `${afreCmsServiceUrl}${event.image.url}` }
  }
  if (eventTypes[event.eventTypeCd]) {
    return eventTypes[event.eventTypeCd]
  }
  return eventTypes.airforce
}

export const getEventsSaga = new AsyncSaga(GET_EVENTS, function* getEventsGenerator() {
  const eventsResponse = yield call(afreCmsService.getEvents)
  const eventsData = eventsResponse?.data?.data
  yield put(getEventsSaga.success(eventsData))
})

export const getEventSaga = new AsyncSaga(GET_EVENT, function* getEventGenerator({ params: { eventId } }) {
  const eventResponse = yield call(afreCmsService.getEvent, eventId)
  const eventData = eventResponse?.data?.data
  yield put(getEventSaga.success(eventData))
}).catch(function* getEventCatch(error) {
  yield put(getEventSaga.error(error, false))
})

export const eventSagas = [
  takeLatest(getEventsSaga.REQUEST, getEventsSaga.saga),
  takeLatest(getEventSaga.REQUEST, getEventSaga.saga),
]

export const selectEvent = state => state.event.event
export const selectErrorLoadingEvent = createErrorSelector(GET_EVENT)
export const selectEvents = state => lodashSortBy(state.event.events, ['name'])
export const selectSelectedEvent = state => state.event.selectedEvent
export const selectIsEventModalOpen = state => state.event.isEventModalOpen

const INITIAL_STATE = immutable({
  events: [],
  event: {},
  selectedEvent: {},
  isEventModalOpen: false,
})

export default function eventReducers(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case getEventsSaga.SUCCESS:
      return state.set('events', action.payload)
    case getEventSaga.SUCCESS:
      return state.set('event', action.payload)
    case SET_SELECTED_EVENT:
      return state.set('selectedEvent', action.event)
    case TOGGLE_EVENT_MODAL:
      return state.set('isEventModalOpen', !state.isEventModalOpen)
    default:
      return state
  }
}
