import * as React from 'react';

function ChevronLeft({ size = 30, color, ...rest }) {
  return (
    <svg width={ size } height={ size } viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M17.5 8.75L11.25 15L17.5 21.25" stroke={ color } stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default ChevronLeft;
