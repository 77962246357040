import React from 'react'
import styled from '@emotion/styled'
import { withOpacity } from '@aimhigh/theme'

const BarContainer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  height: ${({ $size, $vertical }) => $vertical ? '100%' : `${$size}px`};
  width: ${({ $size, $vertical }) => $vertical ? `${$size}px` : '100%'};
  background: ${({ theme }) => withOpacity(theme.colors.background.level3, 0.25)};

  > div {
      ${({ $size, $vertical }) => ($vertical ? `width: ${$size}px;` : `height: ${$size}px;`)};
  }
`

const ColorBar = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  height: ${({ $percentage, $vertical }) => $vertical ? `${$percentage}%` : 'auto'};
  width: ${({ $percentage, $vertical }) => $vertical ? 'auto' : `${$percentage}%`};
  ${({ $color }) => ($color ? `background: ${$color};` : '')};
`

const ProgressBar = ({ color, direction = 'horizontal', value = 0, size = 16, ...rest }) => {
  let percentage = 0

  if (value >= 100) {
    percentage = 100
  } else if (value > 0) {
    percentage = value
  }

  return (
    <BarContainer $size={ size } $vertical={ direction === 'vertical' } { ...rest }>
      <ColorBar $color={ color } $vertical={ direction === 'vertical' } $percentage={ percentage } />
    </BarContainer>
  )
}

export default ProgressBar
