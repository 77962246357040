export const FAQS = [
  {
    title: 'What qualifications are needed to become a Line Officer?',
    content: 'Typically, you\'ll need a bachelor\'s degree, pass a medical examination, and meet age, fitness, and background standards. Certain roles may have specific requirements.',
  },
  {
    title: 'What is Officer Training School (OTS)?',
    content: 'OTS Description...',
  },
  {
    title: 'Is prior military experience required?',
    content: 'Military experience details...',
  },
  {
    title: 'What is the commitment length for a Line Officer?',
    content: 'Commitment length details...',
  },
]
