export const filterTypes = {
  ALL: 'all',
  ONLY_ERRORS: 'onlyErrors',
  NO_ERRORS: 'noErrors',
  LINKED_ORDERS: 'linkedOrders',
  UNLINKED_ORDERS: 'unlinkedOrders',
  BULK_ORDERS: 'isBulk',
  SHEPPARD_ORDERS: 'isSheppard',
  SMALL_MOVES_ORDERS: 'isSmallMoves',
  FLIGHT_PHYSICAL_ORDERS: 'isFlightPhysical',
  EDITED_ORDERS: 'isEditedOrders',
}

export const generalFilterOptions = [
  { value: filterTypes.ALL, text: 'All Rows' },
  { value: filterTypes.BULK_ORDERS, text: 'Bulk Orders' },
  { value: filterTypes.SHEPPARD_ORDERS, text: 'Sheppard Orders' },
  { value: filterTypes.SMALL_MOVES_ORDERS, text: 'Small Moves Orders' },
  { value: filterTypes.FLIGHT_PHYSICAL_ORDERS, text: 'Flight Physical Orders' },
  { value: filterTypes.LINKED_ORDERS, text: 'Rows With Active Graduate Links' },
  { value: filterTypes.UNLINKED_ORDERS, text: 'Rows Without Active Graduate Links' },
  { value: filterTypes.ONLY_ERRORS, text: 'Rows With Warnings' },
  { value: filterTypes.NO_ERRORS, text: 'Rows Without Warnings' },
  { value: filterTypes.EDITED_ORDERS, text: 'Rows With Edited Orders' },
]

export const evaluateFilterType = (includeRow, weekGroupRow, filterType) => {
  const weekGroupRowErrors = weekGroupRow.errors || {}
  switch (filterType) {
    case filterTypes.ONLY_ERRORS:
      includeRow = Object.keys(weekGroupRowErrors).length !== 0
      break
    case filterTypes.NO_ERRORS:
      includeRow = Object.keys(weekGroupRowErrors).length === 0
      break
    case filterTypes.LINKED_ORDERS:
      includeRow = !!weekGroupRow.isLinked
      break
    case filterTypes.UNLINKED_ORDERS:
      includeRow = !weekGroupRow.isLinked
      break
    case filterTypes.BULK_ORDERS:
      includeRow = weekGroupRow.afscProps ? weekGroupRow.afscProps.isBulk : false
      break
    case filterTypes.SHEPPARD_ORDERS:
      includeRow = weekGroupRow.afscProps ? weekGroupRow.afscProps.isSheppard : false
      break
    case filterTypes.SMALL_MOVES_ORDERS:
      includeRow = weekGroupRow.afscProps ? weekGroupRow.afscProps.isSmallMove : false
      break
    case filterTypes.FLIGHT_PHYSICAL_ORDERS:
      includeRow = weekGroupRow.afscProps ? weekGroupRow.afscProps.isFlightPhysical : false
      break
    case filterTypes.EDITED_ORDERS:
      includeRow = !!weekGroupRow.isModified
      break
    default:
      break
  }
  return includeRow
}

export const getTextFilterValue = (weekGroupRowSearchValue, weekGroupRowSearchType, weekGroupRow) => {
  if (weekGroupRowSearchValue) {
    let filterValue = weekGroupRow[weekGroupRowSearchType]
    if (weekGroupRowSearchType === 'isSpaceForce') {
      filterValue = filterValue ? 'YES' : 'NO'
    }
    return filterValue
  }
}
