import React from 'react'
import PropTypes from 'prop-types'
import { IconCheckboxLabel, IconCheckboxWrapper, IconCheckboxIconWrapper } from './IconCheckbox.styles'

class IconCheckbox extends React.PureComponent {
  state = {
    checked: false,
  }

  toggle = checked => {
    this.setState({ checked })
    this.props.onChange(null, { checked })
  }

  render() {
    const { checkedIcon, uncheckedIcon, label } = this.props
    return (
      <IconCheckboxWrapper onClick={ () => this.toggle(!this.state.checked) }>
        <IconCheckboxIconWrapper>
          { this.state.checked ? checkedIcon : uncheckedIcon }
        </IconCheckboxIconWrapper>
        <IconCheckboxLabel>{ label }</IconCheckboxLabel>
      </IconCheckboxWrapper>
    )
  }
}

IconCheckbox.propTypes = {
  checkedIcon: PropTypes.node,
  uncheckedIcon: PropTypes.node,
  label: PropTypes.string,
  onChange: PropTypes.func,
}

export default IconCheckbox
