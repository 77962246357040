import PropTypes from 'prop-types'
import * as React from 'react'

function Pen01(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={props.size} width={props.size} viewBox="0 0 32 32">
            <title>{props.title}</title>
            <g fill={props.color} class="nc-icon-wrapper">
                <polygon fill={props.color} points="19.586,6 17,8.586 4,21.586 10.414,28 26,12.414 "></polygon>
                <path data-color="color-2" d="M2.877,23.291l-1.838,6.434c-0.1,0.35-0.002,0.725,0.254,0.982C1.483,30.897,1.738,31,2,31 c0.092,0,0.184-0.013,0.275-0.039l6.434-1.838L2.877,23.291z"></path>
                <path data-color="color-2" d="M30.707,6.293l-5-5c-0.391-0.391-1.023-0.391-1.414,0L21,4.586L27.414,11l3.293-3.293 C31.098,7.316,31.098,6.684,30.707,6.293z"></path>
            </g>
        </svg>
    )
}

Pen01.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
}

export default Pen01