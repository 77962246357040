import React from 'react'
import { Form } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { MAX_LENGTH } from '../../redux/constants/form.values.constants'

const SearchInput = ({ input }) => (
  <Form.Input size='mini' placeholder='Search by Email...' { ...input } />
)

SearchInput.propTypes = {
  input: PropTypes.object,
}

const UserSearchForm = props => {
  return (
    <Form onSubmit={ props.handleSubmit }>
      <Form.Group>
        <Form.Field width='6' data-testid='search-users-field'>
          <Field
            name='emailText'
            type='text'
            component={ SearchInput }
            maxLength={ MAX_LENGTH.LONG }
          />
        </Form.Field>
        <Form.Button data-testid='search-button'>Search</Form.Button>
      </Form.Group>
    </Form>
  )
}

UserSearchForm.propTypes = {
  handleSubmit: PropTypes.func,
}

const mapStateToProps = () => ({})
const mapActionsToProps = () => ({})

const Formed = reduxForm({
  form: 'userSearchForm',
  enableReinitialize: true,
})(UserSearchForm)
const ConnectedUserSearchForm = connect(mapStateToProps, mapActionsToProps)(Formed)

export default withRouter(ConnectedUserSearchForm)

