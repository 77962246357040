import { takeLatest, call, put, select } from 'redux-saga/effects'
import AsyncSaga from './util/AsyncSaga'
import afreService from '../../api/afre.service'
import { userActions } from '../constants/user.constants.js'
import { selectLastUserKey, selectSingleUser } from '../selectors/user.selectors'
import afreCmsService from '../../api/afre.cms.service'

const INVALID_USER_DATE = 'Invalid user data'

export const fetchRoleRequests = new AsyncSaga(userActions.FETCH_ROLE_REQUESTS, function* fetchRoleRequestsSaga({ params }) {
  const startAt = params && params.startAt ? JSON.stringify(params.startAt) : null
  const response = yield call(afreService.listRoleRequest, { startAt })
  yield put(fetchRoleRequests.success({ roleRequests: response.data.data }))
})

export const fetchUser = new AsyncSaga(userActions.FETCH_USER, function* fetchUserSaga({ params }) {
  const { id } = params
  const response = yield call(afreService.getUserById, id)
  if (!response || !response.data || !response.data.data) {
    throw new Error(INVALID_USER_DATE)
  }
  yield put(fetchUser.success({ user: response.data.data }))
})

export const fetchRecruiter = new AsyncSaga(userActions.FETCH_RECRUITER, function* fetchRecruiterSaga({ params }) {
  const { id } = params
  try {
    const response = yield call(afreService.getRecruiterById, id)
    if (response?.data?.data) {
      yield put(fetchRecruiter.success({ user: response.data.data }))
    }
  } catch (error) {
    // do nothing we want to get lead data even if recruiter isn't found
  }
})

export const fetchRecruiterInformation = new AsyncSaga(userActions.FETCH_RECRUITER_INFORMATION, function* fetchRecruiterInformationSaga() {
  const recruiter = yield select(selectSingleUser)
  const response = yield call(afreCmsService.getRecruitersByEmail, recruiter.email)
  yield put(fetchRecruiterInformation.success(response?.data?.data[0]))
})

// eslint-disable-next-line complexity
export const fetchUsers = new AsyncSaga(userActions.FETCH_USERS, function* fetchUsersSaga(action) {
  const roles = action.params && action.params.roles
  const email = action.params && action.params.email
  const lastUserKey = yield select(selectLastUserKey)
  const params = (lastUserKey || roles || email) ? {} : false

  if (email) {
    params.email = email
  } else {
    if (lastUserKey) {
      params.startAt = JSON.stringify(lastUserKey)
    }
    if (roles) {
      params.roles = roles
    }
  }
  const response = yield call(afreService.getUsers, params)
  if (!response || !response.data || !response.data.data) {
    throw new Error('Invalid users data')
  }
  yield put(fetchUsers.success({ users: response.data.data, lastKey: response.data.lastKey, searchTerm: email }))
})

export const updateUser = new AsyncSaga(userActions.UPDATE_USER, function* updateUserSaga({ params: { user } }) {
  const response = yield call(afreService.updateUser, user)
  if (!response || !response.data || !response.data.data) {
    throw new Error('Invalid users data')
  }
  yield put(updateUser.success({ user: response.data.data }))
})

export const updateUserSquadronInfo = new AsyncSaga(userActions.UPDATE_USER_SQUADRON_INFO, function* updateUserSquadronInfoSaga({ params }) {
  const response = yield call(afreService.updateUserSquadronInfo, params)
  if (!response || !response.data || !response.data.data) {
    throw new Error('Invalid user squadron data')
  }
  yield put(updateUserSquadronInfo.success({ user: response.data.data }))
})

export const deleteUserSquadronInfo = new AsyncSaga(userActions.DELETE_USER_SQUADRON_INFO, function* deleteUserSquadronInfoSaga({ params: id }) {
  const response = yield call(afreService.updateUserSquadronInfo, { id })
  if (!response || !response.data || !response.data.data) {
    throw new Error('Invalid user squadron data')
  }
  yield put(deleteUserSquadronInfo.success({ user: response.data.data }))
})

export const fetchRoles = new AsyncSaga(userActions.FETCH_ROLES, function* fetchRolesSaga({ params }) {
  const response = yield call(afreService.getRoles, params || {})
  if (!response || !response.data || !response.data.data) {
    throw new Error('Invalid roles data')
  }
  yield put(fetchRoles.success({ roles: response.data.data }))
})

export const updateSpecificRoleSaga = new AsyncSaga(userActions.UPDATE_SPECIFIC_ROLE, function* updateSpecificRole({ params }) {
  const response = yield call(afreService.updateSpecificRole, params)
  if (!response || !response.data || !response.data.data) {
    throw new Error('Could not update role')
  }
  yield put(updateSpecificRoleSaga.success({ user: response.data.data }))
})

export const deleteSpecificRoleSaga = new AsyncSaga(userActions.DELETE_SPECIFIC_ROLE, function* deleteSpecificRole({ params }) {
  const response = yield call(afreService.deleteSpecificRole, params)
  if (!response || !response.data || !response.data.data) {
    throw new Error('Could not delete role')
  }
  yield put(deleteSpecificRoleSaga.success({ user: response.data.data }))
})

export const updateRolesSaga = new AsyncSaga(userActions.UPDATE_ROLES, function* updateRoles({ params }) {
  const response = yield call(afreService.updateRoles, params)
  if (!response || !response.data || !response.data.data) {
    throw new Error('Invalid roles data')
  }
  yield put(updateRolesSaga.success({ user: response.data.data }))
})

export const fetchRecruiterExperienceData =
  new AsyncSaga(userActions.FETCH_RECRUITER_EXPERIENCE_DATA, function* fetchRecruiterExperienceDataSaga({ params }) {
    const { id } = params
    const response = yield call(afreService.fetchRecruiterExperienceData, id)

    if (!response || !response.data || !response.data.data) {
      throw new Error('Invalid user data')
    }

    const { educationHistory, militaryService } = response.data.data
    const recruiterExperience = {
      civilianEducation: educationHistory.civilian,
      militaryEducation: educationHistory.military,
      militaryServiceExperience: militaryService.experience,
      militaryServiceRank: militaryService.rank,
    }

    yield put(fetchRecruiterExperienceData.success({ recruiterExperience }))
  })

export const createGrantRoleRequest = new AsyncSaga(userActions.CREATE_GRANT_ROLE_REQUEST, function* createGrantRoleRequestSaga(action) {
  const { organization, roles, organizationType } = action.params
  yield call(afreService.createGrantRoleRequest, { organization, roles, organizationType })
  yield put(createGrantRoleRequest.success())
})

export default [
  takeLatest(fetchRoleRequests.REQUEST, fetchRoleRequests.saga),
  takeLatest(fetchRoles.REQUEST, fetchRoles.saga),
  takeLatest(fetchUser.REQUEST, fetchUser.saga),
  takeLatest(fetchRecruiter.REQUEST, fetchRecruiter.saga),
  takeLatest(fetchRecruiterInformation.REQUEST, fetchRecruiterInformation.saga),
  takeLatest(fetchUsers.REQUEST, fetchUsers.saga),
  takeLatest(updateRolesSaga.REQUEST, updateRolesSaga.saga),
  takeLatest(updateSpecificRoleSaga.REQUEST, updateSpecificRoleSaga.saga),
  takeLatest(deleteSpecificRoleSaga.REQUEST, deleteSpecificRoleSaga.saga),
  takeLatest(updateUser.REQUEST, updateUser.saga),
  takeLatest(updateUserSquadronInfo.REQUEST, updateUserSquadronInfo.saga),
  takeLatest(deleteUserSquadronInfo.REQUEST, deleteUserSquadronInfo.saga),
  takeLatest(fetchRecruiterExperienceData.REQUEST, fetchRecruiterExperienceData.saga),
  takeLatest(createGrantRoleRequest.REQUEST, createGrantRoleRequest.saga),
]
