/* eslint-disable */
import * as React from 'react';

function Cms(props) {
  return (
    <svg width={ 30 } height={ 26 } { ...props }>
      <path
        d="M4.733 11.154H12.2v7.384H4.733v-7.384zm11.2.923h9.334m-9.334 3.692h5.6M2 1h26a1 1 0 011 1v22a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1zM1 5.615h28M6.6 1v4.615"
        stroke={ props.color }
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Cms;
