import styled from '@emotion/styled'
import Button from '../components/Button/Button'
import spacing from '../theme/spacing'

export const SmallButton = styled(Button)`
  background-color: ${props => props.buttonColor};
  width: auto;
  height: auto;
  margin: 0;
  padding: ${spacing.s}px ${spacing.m}px;
`
