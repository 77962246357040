import * as React from 'react';

function Share(props) {
  const stroke = props.color ? props.color : '#1B2632'
  return (
    <svg width={ 32 } height={ 32 } { ...props }>
      <g fill="none" fillRule="evenodd">
        <path d="M4 4h24v24H4z" />
        <g
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(5.5 5.5)"
          stroke={ stroke }>
          <path d="M6.607 8.889l7.285-3.778M6.607 12.111l7.285 3.778" />
          <circle cx={ 3.5 } cy={ 10.5 } r={ 3.5 } />
          <circle cx={ 17 } cy={ 3.5 } r={ 3.5 } />
          <circle cx={ 17 } cy={ 17.5 } r={ 3.5 } />
        </g>
      </g>
    </svg>
  );
}

export default Share;
