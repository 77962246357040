import axios from 'axios'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import cmsService from '../../api/afre.cms.service'
import config from '../../config'
import { selectAuthUserToken } from '../../redux/selectors/auth.selectors'

const afreServiceUrl = config.apiUrl
const defaultQueryOptions = { staleTime: 60 * 60 * 1000 }

export const useGetUserByEmail = ({ email }, options = {}) => {
  const authToken = useSelector(selectAuthUserToken)

  const queryOptions = { ...defaultQueryOptions, ...options }
  return useQuery(['getUserByEmail', email], async () => {
    const url = `${afreServiceUrl}/user/users?email=${email}`

    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
    return response.data.data
  }, queryOptions)
}

export const useGetRecruiterFromCmsByEmail = ({ email }, options = {}) => {
  const queryOptions = { ...defaultQueryOptions, ...options }
  return useQuery(['getRecruiterFromCms', email], async () => {
    const response = await cmsService.getRecruitersByEmail(email)
    return response?.data?.data
  }, queryOptions)
}
