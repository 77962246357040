import React, { useState } from 'react'
import { DarkPalette, LightPalette } from '../../theme'
import { ThemeProvider, useTheme } from '@emotion/react'
import { css } from '@emotion/css'
import { PageTitle } from '../afcep/afcepLandingPage/AfcepLandingPage.styles'
import SvgAirForce from '../../icons/AirForce'
import { DeleteAccountPageContent } from './DeleteAccountPageContent'
import { FlatDivider } from '../../components/Forms/FormDividers'
import { DeleteAccountPageButtons } from './DeleteAccountPageButtons'
import { NAV_ROUTES } from '../../navigation/routes'
import PageFooter from '../../components/Page/Footer/PageFooter'
import Main from '../../components/Page/Main/Main'
import PageWrapper from '../../components/Page/PageWrapper'
import PageHeader from '../../components/Page/Header/PageHeader'
import PageContainer from '../../components/Page/PageContainer'
import DeleteAccountModal from './DeleteAccountModal'
import { useDispatch } from 'react-redux'
import { logout } from '../../redux/sagas/auth.sagas'

export const DeleteAccountPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const theme = useTheme()
  const dispatch = useDispatch()

  return (
    <ThemeProvider theme={ DarkPalette }>
      <PageWrapper>
        { isModalOpen &&
          <DeleteAccountModal
            cancelDeleteAccountHandler={ () => setIsModalOpen(false) }
            confirmDeleteAccountHandler={ () => {
              setIsModalOpen(false)
              dispatch(logout.request({ redirectPage: NAV_ROUTES.DELETE_ACCOUNT_PAGE_SUCCESS }))
            } }
          />
        }
        <PageHeader>
          <SvgAirForce size={ 144 } color={ LightPalette.background.level0 } />
          <PageTitle style={ { textTransform: 'uppercase' } }>Account Deletion</PageTitle>
        </PageHeader>
        <Main>
          <PageContainer>
            <DeleteAccountPageContent />
            <FlatDivider className={ css`margin: ${theme.spacing.l}px 0` } />
            <DeleteAccountPageButtons onClickCallback={ () => setIsModalOpen(true) } />
          </PageContainer>
        </Main>
        <PageFooter />
      </PageWrapper>
    </ThemeProvider>
  )
}
