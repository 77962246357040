import { ThemeProvider } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Dimmer, Loader, Modal, Segment } from 'semantic-ui-react'
import HttpService from '../../../api/HttpService'
import config from '../../../config'
import { selectAuthUserToken } from '../../../redux/selectors/auth.selectors'
import { LightPalette } from '../../../theme'
import { ActionButton, CancelButton, ScrollableSection } from '../../pages/Opa.styles'
import { ErrorLabel } from '../../pages/UploadWeekGroupPage.styles'
import { useGetWeekGroupRow } from '../../reactQueries/opaQueries'
import { getWeekGroupRows } from '../../redux/ducks/opa.duck'
import { VariableWidthModal } from '../Modals/VariableWidthModal'
import { OpaRichTextEditor } from '../RichTextEditing/OpaRichTextEditor'

const afreServiceUrl = config.apiUrl

export const EditOrdersModal = ({ isOpen, setOpen, weekGroupId, dataSortValue, readOnly }) => {
  const dispatch = useDispatch()
  const token = useSelector(selectAuthUserToken)
  const [orderTemplate, setOrderTemplate] = useState()
  const [isSubmitted, setIsSubmitted] = useState(false)

  const { data: weekGroupRow, isLoading: isWeekGroupRowLoading, isError: isWeekGroupRowError, mutate: getWeekGroupRow } = useGetWeekGroupRow()
  const saveOrderTemplate = useMutation(async template => {
    return await HttpService.put(`${afreServiceUrl}/opa/weekGroups/${weekGroupId}/${dataSortValue}`, template, {}, token).next().value
  }, { onSuccess: () => {
    dispatch(getWeekGroupRows.request({ weekGroup: weekGroupId }))
  },
  })

  useEffect(() => {
    if (!saveOrderTemplate.isLoading && !saveOrderTemplate.isError && isSubmitted) {
      setOrderTemplate()
      setIsSubmitted(false)
      setOpen(false)
    }
  }, [saveOrderTemplate, setOpen, isSubmitted])

  useEffect(() => {
    if (!(weekGroupRow && weekGroupRow.data)) {
      getWeekGroupRow({ weekGroupId, dataSortValue, fullOrderData: false })
    }
  }, [weekGroupId, dataSortValue, weekGroupRow, getWeekGroupRow])

  const renderActionButtons = () => {
    if (readOnly) {
      return (
        <>
          <CancelButton onClick={ () => {
            setOpen(false)
            setOrderTemplate()
          } }>
            Close
          </CancelButton>
        </>
      )
    }
    return (
      <>
        <CancelButton onClick={ () => {
          setOpen(false)
          setOrderTemplate()
        } }>
          Cancel
        </CancelButton>
        {
          saveOrderTemplate.isLoading ?
            <ActionButton loading>Loading</ActionButton> :
            <ActionButton
              data-testid='modal-update-button'
              content='Update'
              icon='checkmark'
              disabled={ !orderTemplate }
              onClick={ () => {
                saveOrderTemplate.mutate({ orderTemplate })
                setIsSubmitted(true)
              } }
            />
        }
      </>
    )
  }

  return (
    <VariableWidthModal
      isOpen={ isOpen }
      width='900px'
      content={
        <ThemeProvider theme={ LightPalette }>
          <Modal.Header data-testid='modal-header'>{ readOnly ? 'View' : 'Edit' } Orders</Modal.Header>
          <ScrollableSection>
            <Dimmer.Dimmable as={ Segment } dimmed={ isWeekGroupRowLoading || saveOrderTemplate.isLoading }>
              <Dimmer active={ isWeekGroupRowLoading || saveOrderTemplate.isLoading } >
                <Loader>Loading</Loader>
              </Dimmer>
              <Modal.Content data-testid='modal-content' style={ { minHeight: '720px' } }>
                { isWeekGroupRowError && <ErrorLabel>Failed to retrieve orders.  Please try again.</ErrorLabel> }
                { saveOrderTemplate.isError && <ErrorLabel>Failed to save orders.  Please try again.</ErrorLabel> }
                { weekGroupRow && weekGroupRow.data.data.orderTemplate && (
                  <div style={ { display: 'flex', justifyContent: 'center' } }>
                    <OpaRichTextEditor
                      readOnly={ readOnly }
                      value={ weekGroupRow.data.data.orderTemplate }
                      onInputChange={ update => {
                        setOrderTemplate(update)
                      } }
                      textEditorIndex={ 1 }
                    />
                  </div>
                ) }
              </Modal.Content>
            </Dimmer.Dimmable>
          </ScrollableSection>
          <Modal.Actions data-testid='modal-actions'>
            { renderActionButtons() }
          </Modal.Actions>
        </ThemeProvider>
      }
    />
  )
}

EditOrdersModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  weekGroupId: PropTypes.string,
  dataSortValue: PropTypes.string,
  readOnly: PropTypes.bool,
}
