import React from 'react'
import { Form, FormSelect, FormTextField, Button, FormDatepicker } from '@aimhigh/web-components/web'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { IconLabelWrapper, StyledInfoIcon, twoColStyle } from '../../LineOfficer.styles'
import { theme as ahaTheme } from '@aimhigh/theme'
import { HELP_ICON_SIZE } from '../../lineOfficer.constants'

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  column-gap: ${({ theme }) => theme.spacing.m}px;
  row-gap: ${({ theme }) => theme.spacing.xl}px;

  > * {
    grid-column: span 4;
  }
`

const EducationForm = ({ onSubmit }) => {
  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
    },
  })

  const handleFormSubmit = values => {
    // eslint-disable-next-line no-alert
    alert(
      `--form values--\n${Object.keys(values)
        .map(key => {
          const value = values[key]
          return `${key}: ${
            typeof value === 'object' ? JSON.stringify(value) : value
          }`
        })
        .join('\n')}`
    )

    onSubmit()
  }

  return (
    <StyledForm formHooks={ formHooks } onSubmit={ handleFormSubmit }>
      <FormSelect label='School Type' name='school_type' placeholder='Select' options={ [] } dark />

      <FormTextField label='Name of School' name='school_name' dark />

      <FormDatepicker label='Start Date' name='school_start' placeholder='MM/YYYY' style={ twoColStyle } dark />
      <FormDatepicker label='End Date (or expected)' name='school_end' placeholder='MM/YYYY' style={ twoColStyle } dark />

      <FormTextField label='Number of Years Completed' name='school_years' dark />

      <FormSelect label='Degree Major' name='degree_major' placeholder='Select' options={ [] } dark />

      <FormTextField label='If Other, please specify' name='major_specify' dark />

      <FormSelect label='Degree Title' name='degree_title' options={ [] } dark />

      <FormTextField label='If Other, please specify' name='title_specify' dark />

      <FormTextField
        label={
          <IconLabelWrapper>
            GPA <StyledInfoIcon color={ ahaTheme.colors.system.actionLight } size={ HELP_ICON_SIZE } />
          </IconLabelWrapper>
        }
        name='gpa'
        style={ twoColStyle }
        dark
      />
      <FormTextField
        label={
          <IconLabelWrapper>
            CIP Code <StyledInfoIcon color={ ahaTheme.colors.system.actionLight } size={ HELP_ICON_SIZE } />
          </IconLabelWrapper>
        }
        name='cip'
        style={ twoColStyle }
        dark
      />

      <Button variant='secondary' dark>*icon* Add Additional Degree</Button>

      <Button type='submit' dark>Next</Button>
    </StyledForm>
  )
}

export default EducationForm
