import { ThemeProvider } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { Grid, Input, Modal } from 'semantic-ui-react'
import HttpService from '../../../api/HttpService'
import config from '../../../config'
import { selectAuthUserToken } from '../../../redux/selectors/auth.selectors'
import { LightPalette } from '../../../theme'
import spacing from '../../../theme/spacing'
import { ActionButton, CancelButton } from '../../pages/Opa.styles'
import { ErrorLabel } from '../../pages/UploadWeekGroupPage.styles'
import { OpaLabel } from '../Forms/OpaLabel'

const afreServiceUrl = config.apiUrl

export const GainUnitModal = ({ isOpen, setOpen, getUnitQueryKey, existingGainUnit }) => {
  const queryClient = useQueryClient()
  const token = useSelector(selectAuthUserToken)

  const [newName, setNewName] = useState('')

  const saveUnitMutation = useMutation(async unit => {
    return await HttpService.post(`${afreServiceUrl}/opa/templates/units`, unit, {}, token).next().value
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries(getUnitQueryKey)
      setOpen(false)
    },
  })

  const updateUnitMutation = useMutation(async unit => {
    return await HttpService.put(`${afreServiceUrl}/opa/templates/units/${unit.sort}`, unit, {}, token).next().value
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries(getUnitQueryKey)
      setOpen(false)
    },
  })

  const updateName = (eventData, name) => {
    name = name.toUpperCase()
    eventData.target.defaultValue = name
    eventData.target.value = name
    setNewName(name)
  }

  const saveError = saveUnitMutation.error && saveUnitMutation.error.response && saveUnitMutation.error.response.data
  const updateError = updateUnitMutation.error && updateUnitMutation.error.response && updateUnitMutation.error.response.data
  const error = existingGainUnit ? updateError : saveError
  const isLoading = saveUnitMutation.isLoading || updateUnitMutation.isLoading
  return (
    <Modal
      open={ isOpen }>
      <ThemeProvider theme={ LightPalette }>
        <Modal.Header data-testid='modal-header'>{ existingGainUnit ? 'Edit Gain Unit' : 'Add Gain Unit' }</Modal.Header>
        <Modal.Content>
          <Modal.Description data-testid='modal-description'>
            { error && error.error &&
              <div style={ { paddingBottom: spacing.l } }>
                <ErrorLabel>
                  { error.error.toUpperCase() }
                </ErrorLabel>
              </div> }
            <Grid>
              <Grid.Column width={ 14 } >
                <Grid.Row data-testid='name-row'>
                  <OpaLabel label='Name' error={ error && error.name } required />
                </Grid.Row>
                <Grid.Row >
                  <Input
                    fluid
                    data-testid='unit-name-input'
                    placeholder='Enter Gain Unit Name'
                    type='string'
                    defaultValue={ existingGainUnit ? existingGainUnit.name : '' }
                    onChange={ (eventData, event) => updateName(eventData, event.value) }
                  />
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <CancelButton onClick={ () => {
            setOpen(false)
            saveUnitMutation.reset()
            updateUnitMutation.reset()
            setNewName('')
          }
          }>
            Cancel
          </CancelButton>
          {
            isLoading ?
              <ActionButton loading>Loading</ActionButton> :
              <ActionButton
                content={ existingGainUnit ? 'Update' : 'Create' }
                data-testid='action-button'
                icon='checkmark'
                disabled={ !newName }
                onClick={ () => {
                  if (existingGainUnit) {
                    updateUnitMutation.mutate({ name: newName, sort: existingGainUnit.sort })
                  } else {
                    saveUnitMutation.mutate({ name: newName })
                  }
                } }
              />
          }
        </Modal.Actions>
      </ThemeProvider>
    </Modal>
  )
}

GainUnitModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  getUnitQueryKey: PropTypes.array,
  existingGainUnit: PropTypes.object,
}
