import { ThemeProvider } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Form, Grid, Header, Input, Modal } from 'semantic-ui-react'
import { LargeFieldLabel } from '../../../forms/form.styles'
import { LightPalette } from '../../../theme'
import colors from '../../../theme/colors'
import { ActionButton, CancelButton, LeftButtonGroup, RightButtonGroup } from '../../pages/Opa.styles'
import { ErrorLabel } from '../../pages/UploadWeekGroupPage.styles'
import { addAfscProperties, selectAddingAfscProperties, selectAddingAfscPropertiesError } from '../../redux/ducks/opa.duck'
import { OpaLabel } from '../Forms/OpaLabel'

export const AfscModal = ({ isOpen, currentAfscList, setOpen }) => {
  const dispatch = useDispatch()
  const [newAfsc, setNewAfsc] = useState('')
  const [isBulk, setIsBulk] = useState(false)
  const [isFlightPhysical, setIsFlightPhysical] = useState(false)
  const [isSmallMove, setIsSmallMove] = useState(false)
  const [isSheppard, setIsSheppard] = useState(false)
  const [isOver20, setIsOver20] = useState(false)
  const [isSpaceForce, setIsSpaceForce] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const addAfscError = useSelector(selectAddingAfscPropertiesError)
  const isAddingAfsc = useSelector(selectAddingAfscProperties)

  const resetAndCloseModal = useCallback(
    () => {
      setNewAfsc('')
      setIsBulk(false)
      setIsFlightPhysical(false)
      setIsSmallMove(false)
      setIsSheppard(false)
      setIsOver20(false)
      setIsSpaceForce(false)
      setIsSubmitted(false)

      setOpen(false)
    }, [setOpen]
  )

  const makeAfscGeneric = afsc => {
    if (afsc) {
      const afscCharters = afsc.split('')
      if (afsc.length > 3) {
        afscCharters[3] = 'X'
      }
      return afscCharters.join('').toUpperCase()
    }
    return ''
  }

  const validateAfsc = (eventData, afsc) => {
    if (/^[a-zA-Z0-9]{0,6}$/u.test(afsc)) {
      afsc = makeAfscGeneric(afsc)
      afsc = afsc.toUpperCase()
      eventData.target.defaultValue = afsc
      eventData.target.value = afsc
      setNewAfsc(afsc)
    } else {
      eventData.target.value = eventData.target.defaultValue
    }
  }

  const createAfsc = () => {
    const newAfscProperties = {
      id: 'afsc',
      sort: newAfsc,
      isBulk,
      isFlightPhysical,
      isSmallMove,
      isSheppard,
      isOver20,
      isSpaceForce,
    }

    if (isBulk) {
      newAfscProperties.isBulk = true
      newAfscProperties.isFlightPhysical = false
    } else {
      newAfscProperties.isBulk = false
      newAfscProperties.isFlightPhysical = true
    }
    dispatch(addAfscProperties.request({ newAfscProperties, currentAfscList }))
    setIsSubmitted(true)
  }

  const renderErrorLabel = errorProperty => {
    return (
      <Grid.Row columns='equal'>
        <Grid.Column textAlign='center'>
          {
            isSubmitted && addAfscError && addAfscError.data && errorProperty &&
              <ErrorLabel data-testid='add-afsc-error-text'>
                { errorProperty.toUpperCase() }
              </ErrorLabel>
          }
        </Grid.Column>
      </Grid.Row>
    )
  }

  useEffect(() => {
    const addingAfscError = addAfscError && addAfscError.data && addAfscError.data.error
    if (!isAddingAfsc && !addingAfscError && isSubmitted) {
      resetAndCloseModal()
    }
  }, [isAddingAfsc, addAfscError, isSubmitted, resetAndCloseModal])

  const dataErrors = (isSubmitted && addAfscError && addAfscError.data) || {}

  return (
    <Modal
      open={ isOpen }>
      <ThemeProvider theme={ LightPalette }>
        <Modal.Header data-testid='modal-header'>Add AFSC</Modal.Header>
        <Modal.Content>
          <Modal.Description data-testid='modal-description'>
            <Header data-testid='modal-description-header'>New AFSC Properties</Header>
            <Grid>
              <Form>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center' data-testid='opa-label-afsc'>
                    <OpaLabel label='AFSC' error={ dataErrors.afsc } required />
                    <Input
                      fluid
                      data-testid='afsc-input'
                      placeholder='Enter AFSC'
                      type='string'
                      onChange={ (eventData, event) => validateAfsc(eventData, event.value) }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center'>
                    <LargeFieldLabel data-testid='opa-label-bulk-or-flight-physical'>Bulk or Flight Physical<span style={ { color: colors.red.default } }> *</span></LargeFieldLabel>
                    <Button.Group size='small'>
                      <LeftButtonGroup
                        data-testid='modal-bulk-button'
                        active={ isBulk }
                        onClick={ () => {
                          setIsBulk(true)
                          setIsFlightPhysical(false)
                        } }>
                        Bulk
                      </LeftButtonGroup>
                      <RightButtonGroup
                        data-testid='modal-flight-physical-button'
                        active={ isFlightPhysical }
                        onClick={ () => {
                          setIsBulk(false)
                          setIsFlightPhysical(true)
                        } }>
                        Flight Physical
                      </RightButtonGroup>
                    </Button.Group>
                  </Grid.Column>
                </Grid.Row>
                { renderErrorLabel(dataErrors.isBulk) }
                <div>
                  <Grid.Row columns='equal'>
                    <Grid.Column textAlign='center' data-testid='opa-label-small-moves'>
                      <OpaLabel label='Small Moves' error={ dataErrors.isSmallMove } />
                      <Checkbox
                        toggle
                        name='initialSmallMove'
                        data-testid='modal-small-moves-toggle'
                        checked={ isSmallMove }
                        onChange={ () => setIsSmallMove(!isSmallMove) }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns='equal'>
                    <Grid.Column textAlign='center' data-testid='opa-label-sheppard'>
                      <OpaLabel label='Sheppard' error={ dataErrors.isSheppard } />
                      <Checkbox
                        toggle
                        name='initialSheppard'
                        data-testid='modal-sheppard-toggle'
                        checked={ isSheppard }
                        onChange={ () => setIsSheppard(!isSheppard) }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </div>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center' data-testid='opa-label-over-20'>
                    <OpaLabel label='Over 20' error={ dataErrors.isOver20 } />
                    <Checkbox
                      toggle
                      name='initialOver20'
                      data-testid='modal-over-20-toggle'
                      checked={ isOver20 }
                      onChange={ () => setIsOver20(!isOver20) }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center' data-testid='opa-label-space-force'>
                    <OpaLabel label='Space Force' error={ dataErrors.isSpaceForce } />
                    <Checkbox
                      toggle
                      name='initialSpaceForce'
                      data-testid='modal-space-force-toggle'
                      checked={ isSpaceForce }
                      onChange={ () => setIsSpaceForce(!isSpaceForce) }
                    />
                  </Grid.Column>
                </Grid.Row>
                { renderErrorLabel(dataErrors.error) }
              </Form>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <CancelButton onClick={ () => resetAndCloseModal() }>
            Cancel
          </CancelButton>
          {
            isAddingAfsc ?
              <ActionButton loading>Loading</ActionButton> :
              <ActionButton
                content='Create'
                icon='checkmark'
                data-testid='modal-action-button'
                disabled={ !/^[A-Z0-9]{5,6}$/u.test(newAfsc) || !(isBulk || isFlightPhysical) }
                onClick={ () => createAfsc() }
              />
          }
        </Modal.Actions>
      </ThemeProvider>
    </Modal>
  )
}

AfscModal.propTypes = {
  isOpen: PropTypes.bool,
  currentAfscList: PropTypes.array,
  setOpen: PropTypes.func,
}
