import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/css'
import { useTheme } from '@emotion/react'

const PageContainer = ({ children }) => {
  const theme = useTheme()

  const defaultClass = css`
    background-color: ${theme.background.level0};
    max-width: ${theme.breakpoints.m};
    margin-inline: auto;
    font-family: ${theme.typography.fontFamilies.openSansRegular};
    color: ${theme.text.default};
    font-size: ${theme.typography.fontSizes.m}px;
  `

  return (
    <div className={ defaultClass }>
      { children }
    </div>
  )
}

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageContainer
