export const HEIGHT_FEET = [
  { key: '4', text: '4 ft', value: '4' },
  { key: '5', text: '5 ft', value: '5' },
  { key: '6', text: '6 ft', value: '6' },
  { key: '7', text: '7 ft', value: '7' },
]

export const HEIGHT_INCHES = [
  { key: '0', text: '0 in', value: '0' },
  { key: '1', text: '1 in', value: '1' },
  { key: '2', text: '2 in', value: '2' },
  { key: '3', text: '3 in', value: '3' },
  { key: '4', text: '4 in', value: '4' },
  { key: '5', text: '5 in', value: '5' },
  { key: '6', text: '6 in', value: '6' },
  { key: '7', text: '7 in', value: '7' },
  { key: '8', text: '8 in', value: '8' },
  { key: '9', text: '9 in', value: '9' },
  { key: '10', text: '10 in', value: '10' },
  { key: '11', text: '11 in', value: '11' },
]

export const EDUCATION_TYPE = [
  { key: 'H', text: 'I am a High school student or graduate', value: 'H' },
  { key: 'T', text: 'I am Technical/Vocational student or graduate', value: 'T' },
  { key: 'C', text: 'I am a College student or graduate', value: 'C' },
  { key: 'HP', text: 'I am a Healthcare student or professional', value: 'HP' },
]

export const EDUCATION_LEVEL = [
  { key: 'HSS', text: 'High school student/Homeschool student', value: 'HSS' },
  { key: 'HSG', text: 'High school graduate/Homeschool graduate', value: 'HSG' },
  { key: 'GED', text: 'GED', value: 'GED' },
  { key: 'CS', text: '4 Year College student', value: 'CS' },
  { key: 'CG', text: 'College graduate', value: 'CG' },
  { key: 'CGA', text: 'College graduate with advanced degree', value: 'CGA' },
  { key: 'GED15', text: 'GED with at least 15 hours credit', value: 'GED15' },
  { key: 'CCS', text: 'Community college student', value: 'CCS' },
  { key: 'CCG', text: 'Community college graduate', value: 'CCG' },
  { key: 'BSN', text: 'Bachelor of Science Nursing', value: 'BSN' },
  { key: 'DDS', text: 'Dentistry', value: 'DDS' },
  { key: 'DNP', text: 'Nursing (DNP)', value: 'DNP' },
  { key: 'MSN', text: 'Nursing (MSN)', value: 'MSN' },
  { key: 'DPT', text: 'Physical Therapy (DPT)', value: 'DPT' },
  { key: 'MPT', text: 'Physical Therapy (MPT)', value: 'MPT' },
  { key: 'DO', text: 'Physician (DO)', value: 'DO' },
  { key: 'MD', text: 'Physician (MD)', value: 'MD' },
  { key: 'MHS', text: 'Health Science', value: 'MHS' },
  { key: 'MMS', text: 'Medical Science', value: 'MMS' },
  { key: 'MPAS', text: 'Physician Assistant', value: 'MPAS' },
  { key: 'STU', text: 'Studies', value: 'STU' },
  { key: 'MPH', text: 'Public Health', value: 'MPH' },
  { key: 'MSE', text: 'Epidemiology', value: 'MSE' },
  { key: 'MSW', text: 'Social Work', value: 'MSW' },
  { key: 'OD', text: 'Optometry', value: 'OD' },
  { key: 'PHM', text: 'Pharmacology', value: 'PHM' },
  { key: 'POD', text: 'Podiatry', value: 'POD' },
  { key: 'PSY', text: 'Psychology', value: 'PSY' },
  { key: 'DDM', text: 'Doctor of Dental Medicine', value: 'DDM' },
  { key: 'AUD', text: 'Audiology', value: 'AUD' },
  { key: 'OTH', text: 'Other', value: 'OTH' },
]

export const CITIZENSHIP_STATUS = [
  { key: '11', text: 'US citizen by birth in US', value: 11 },
  { key: '21', text: 'US citizen by birth in US territory', value: 21 },
  { key: '31', text: 'US citizen through naturalization', value: 31 },
  { key: '41', text: 'US citizen by birth overseas of US parents', value: 41 },
  { key: '51', text: 'Born in Micronesia or Swains Island (US National)', value: 51 },
  { key: '40', text: 'Non-US citizen (immigrant alien) with green card with 2 years or more remaining', value: 40 },
  { key: '30', text: 'Non-US citizen (immigrant alien) with green card with less than 2 years remaining', value: 30 },
  { key: '20', text: 'Non-US citizen (immigrant alien) applied for green card', value: 20 },
  { key: '10', text: 'Non-US citizen', value: 10 },
]

export const RACE = [
  { key: 'A', text: 'American Indian/Alaska Native', value: 'A' },
  { key: 'B', text: 'Asian', value: 'B' },
  { key: 'C', text: 'Black or African American', value: 'C' },
  { key: 'D', text: 'Native Hawaiian or other Pacific Islander', value: 'D' },
  { key: 'E', text: 'White', value: 'E' },
  { key: 'F', text: 'Declined to Respond', value: 'F' },
]

export const HISPANIC_INDICATOR = [
  { key: 'N', text: 'Not of Hispanic or Latino Descent', value: 'N' },
  { key: 'Y', text: 'Of Hispanic or Latino Descent', value: 'Y' },
  { key: 'D', text: 'Declined to Respond', value: 'D' },
]

export const MARITAL_STATUS = [
  { key: 'A', text: 'Annulled', value: 'A' },
  { key: 'D', text: 'Divorced', value: 'D' },
  { key: 'I', text: 'Interlocutory', value: 'I' },
  { key: 'L', text: 'Legally Separated', value: 'L' },
  { key: 'LM', text: 'Legally Separated from Military Member', value: 'LM' },
  { key: 'M', text: 'Married to a civilian', value: 'M' },
  { key: 'MM', text: 'Married to Military Member', value: 'MM' },
  { key: 'OS', text: 'Separated', value: 'OS' },
  { key: 'S', text: 'Single', value: 'S' },
  { key: 'NM', text: 'Never been Married', value: 'NM' },
  { key: 'W', text: 'Widowed', value: 'W' },
]

export const STATES = [
  { key: 'AL', text: 'Alabama', value: 'AL' },
  { key: 'AK', text: 'Alaska', value: 'AK' },
  { key: 'AS', text: 'American Samoa', value: 'AS' },
  { key: 'AZ', text: 'Arizona', value: 'AZ' },
  { key: 'AR', text: 'Arkansas', value: 'AR' },
  { key: 'CA', text: 'California', value: 'CA' },
  { key: 'CO', text: 'Colorado', value: 'CO' },
  { key: 'CT', text: 'Connecticut', value: 'CT' },
  { key: 'DE', text: 'Delaware', value: 'DE' },
  { key: 'DC', text: 'District Of Columbia', value: 'DC' },
  { key: 'FM', text: 'Federated States Of Micronesia', value: 'FM' },
  { key: 'FL', text: 'Florida', value: 'FL' },
  { key: 'GA', text: 'Georgia', value: 'GA' },
  { key: 'GU', text: 'Guam', value: 'GU' },
  { key: 'HI', text: 'Hawaii', value: 'HI' },
  { key: 'ID', text: 'Idaho', value: 'ID' },
  { key: 'IL', text: 'Illinois', value: 'IL' },
  { key: 'IN', text: 'Indiana', value: 'IN' },
  { key: 'IA', text: 'Iowa', value: 'IA' },
  { key: 'KS', text: 'Kansas', value: 'KS' },
  { key: 'KY', text: 'Kentucky', value: 'KY' },
  { key: 'LA', text: 'Louisiana', value: 'LA' },
  { key: 'ME', text: 'Maine', value: 'ME' },
  { key: 'MH', text: 'Marshall Islands', value: 'MH' },
  { key: 'MD', text: 'Maryland', value: 'MD' },
  { key: 'MA', text: 'Massachusetts', value: 'MA' },
  { key: 'MI', text: 'Michigan', value: 'MI' },
  { key: 'MN', text: 'Minnesota', value: 'MN' },
  { key: 'MS', text: 'Mississippi', value: 'MS' },
  { key: 'MO', text: 'Missouri', value: 'MO' },
  { key: 'MT', text: 'Montana', value: 'MT' },
  { key: 'NE', text: 'Nebraska', value: 'NE' },
  { key: 'NV', text: 'Nevada', value: 'NV' },
  { key: 'NH', text: 'New Hampshire', value: 'NH' },
  { key: 'NJ', text: 'New Jersey', value: 'NJ' },
  { key: 'NM', text: 'New Mexico', value: 'NM' },
  { key: 'NY', text: 'New York', value: 'NY' },
  { key: 'NC', text: 'North Carolina', value: 'NC' },
  { key: 'ND', text: 'North Dakota', value: 'ND' },
  { key: 'MP', text: 'Northern Mariana Islands', value: 'MP' },
  { key: 'OH', text: 'Ohio', value: 'OH' },
  { key: 'OK', text: 'Oklahoma', value: 'OK' },
  { key: 'OR', text: 'Oregon', value: 'OR' },
  { key: 'PW', text: 'Palau', value: 'PW' },
  { key: 'PA', text: 'Pennsylvania', value: 'PA' },
  { key: 'PR', text: 'Puerto Rico', value: 'PR' },
  { key: 'RI', text: 'Rhode Island', value: 'RI' },
  { key: 'SC', text: 'South Carolina', value: 'SC' },
  { key: 'SD', text: 'South Dakota', value: 'SD' },
  { key: 'TN', text: 'Tennessee', value: 'TN' },
  { key: 'TX', text: 'Texas', value: 'TX' },
  { key: 'UT', text: 'Utah', value: 'UT' },
  { key: 'VT', text: 'Vermont', value: 'VT' },
  { key: 'VI', text: 'Virgin Islands', value: 'VI' },
  { key: 'VA', text: 'Virginia', value: 'VA' },
  { key: 'WA', text: 'Washington', value: 'WA' },
  { key: 'WV', text: 'West Virginia', value: 'WV' },
  { key: 'WI', text: 'Wisconsin', value: 'WI' },
  { key: 'WY', text: 'Wyoming', value: 'WY' },
]
