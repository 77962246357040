import React from 'react'
import { Field } from 'redux-form'
import {
  Section,
  TwoColumnGrid,
  SectionTitle,
  ThreeColumnGrid
} from '../afcepLandingPage/AfcepLandingPage.styles'
import { GRADE, BRANCH } from '../afcep.constants'
import { FormItem } from '../../../forms/form.styles'
import DropdownField from '../../../components/Forms/DropdownField'
import InputField from '../../../components/Forms/InputField'
import { dateInPast, maxLength100, maxLength6, startDateIsOlderValidationBuilder, validAfrissDate } from '../../../forms/validation'

const priorServiceStartDateIsOlder = startDateIsOlderValidationBuilder('priorServiceStartOn', 'priorServiceEndedOn')

export const PriorService = () => {

  return (
    <Section>
      <SectionTitle data-testid='prior-service-section-title'>Prior Service</SectionTitle>
      <ThreeColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='prior-service-started-on-tafmsd-field'>
          <Field
            id='priorServiceStartOn'
            name='priorServiceStartOn'
            data-testid='priorServiceStartOn'
            label='Started on (TAFMSD)'
            component={ InputField }
            type='date'
            validate={ [dateInPast, validAfrissDate] }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='prior-service-ended-on-field'>
          <Field
            id='priorServiceEndedOn'
            name='priorServiceEndedOn'
            data-testid='priorServiceEndedOn'
            label='Ended on'
            component={ InputField }
            type='date'
            validate={ [priorServiceStartDateIsOlder, validAfrissDate] }
          />
        </FormItem>
      </ThreeColumnGrid>
      <ThreeColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='prior-service-branch-field'>
          <Field
            id='priorServiceBranch'
            name='priorServiceBranch'
            label='Branch'
            data-testid='priorServiceBranch'
            placeholder='Select'
            options={ BRANCH }
            component={ DropdownField }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='prior-service-grade-field'>
          <Field
            id='priorServiceGrade'
            name='priorServiceGrade'
            label='Grade'
            data-testid='priorServiceGrade'
            placeholder='Select'
            options={ GRADE }
            component={ DropdownField }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='prior-service-dor-field'>
          <Field
            id='priorServiceDateOfRank'
            name='priorServiceDateOfRank'
            data-testid='priorServiceDateOfRank'
            label='DOR'
            component={ InputField }
            type='date'
            validate={ validAfrissDate }
          />
        </FormItem>
      </ThreeColumnGrid>
      <TwoColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='prior-service-prior-afsc-field'>
          <Field
            id='priorServiceAfsc'
            name='priorServiceAfsc'
            data-testid='priorServiceAfsc'
            label='Prior AFSC'
            component={ InputField }
            validate={ [maxLength6] }
            type='text'
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='prior-service-prior-job-title-field'>
          <Field
            id='priorServiceJobTitle'
            name='priorServiceJobTitle'
            data-testid='priorServiceJobTitle'
            label='Prior Job Title'
            component={ InputField }
            validate={ [maxLength100] }
            type='text'
          />
        </FormItem>
      </TwoColumnGrid>
    </Section>
  )
}
