import * as React from 'react';

function Timer({ size = 12, color, ...rest }) {
  return (
    <svg width={ size } height={ size } viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clip-path="url(#clip0_816_5421)">
        <path d="M5.98752 6.68487L5.45274 5.61702L6.39993 6.36497L6.39994 6.36498C6.53282 6.4699 6.5334 6.6671 6.40138 6.77277L6.40133 6.77282C6.26481 6.88213 6.06296 6.83551 5.98752 6.68487Z" stroke={ color } stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.5 2.74275C1.57432 3.65226 1 4.91968 1 6.32155C1 9.0892 3.23857 11.3328 6 11.3328C8.7614 11.3328 11 9.0892 11 6.32155C11 3.89485 9.27905 1.87103 6.9936 1.40919C6.5756 1.32472 6.3666 1.28249 6.1833 1.43286C6 1.58324 6 1.82632 6 2.3125V2.81363" stroke={ color } stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_816_5421">
          <rect width={ size } height={ size } fill={ color } transform="translate(0 0.332764)"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Timer;
