import React from 'react'
import { NAV_ROUTES } from '../../navigation/routes'
import { ProfileFooter } from './profile.page.styles'

function Footer() {
  return (
    <ProfileFooter>
      <a href={ NAV_ROUTES.PRIVACY_POLICY_PAGE } data-testid='privacy-policy-terms-and-conditions' rel='noopener noreferrer' target='_blank'>Privacy Policy / Terms and Conditions</a>
    </ProfileFooter>
  )
}

export default Footer
