export const authActions = {
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  INIT_AUTH: 'INIT_AUTH',
  LOGIN_EMAIL: 'LOGIN_EMAIL',
  LOGOUT: 'LOGOUT',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
}

export const COOKIE_TOKEN_KEY = 'token'
export const COOKIE_REFRESH_TOKEN_KEY = 'refresh'
export const AUTH_TOKEN_REFRESH_BUFFER_MS = 300000 // 5 minutes
export const AUTH_TOKEN_FUTURE_CHECK_MS = 30000 // 30 seconds
