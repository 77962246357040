import styled from '@emotion/styled'
import ProgressBar from '../../../components/ProgressBar'
import { Header } from '../LineOfficer.styles'

export const ApplicationHeader = styled(Header)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: ${({ theme }) => theme.spacing.l}px;

    padding-bottom: ${({ theme }) => theme.spacing.l}px;

    > div {
        display: flex;
        align-items: center;

        font-family: ${({ theme }) => theme.fontFamilies.openSans};
        font-size: ${({ theme }) => theme.fontSizes.m}px;
        font-weight: ${({ theme }) => theme.fontWeights.regular};
        color: ${({ theme }) => theme.colors.text.white};
    }

    > a {
        font-family: ${({ theme }) => theme.fontFamilies.stratum2};
        font-size: ${({ theme }) => theme.fontSizes.m}px;
        font-weight: ${({ theme }) => theme.fontWeights.regular};
        color: ${({ theme }) => theme.colors.system.actionLight};
       
        margin: 0;
    }
`

export const StyledSection = styled.section`
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing.xl}px;

    padding: 0 ${({ theme }) => theme.spacing.s}px;
`

export const StyledProgressBar = styled(ProgressBar)`
    background: ${({ theme }) => theme.colors.background.level0};
`

export const ApplicationStepsTitle = styled.div`
    color: ${({ theme }) => theme.colors.text.white};

    > h1 {
        font-family: ${({ theme }) => theme.fontFamilies.stratum2};
        font-size: ${({ theme }) => theme.fontSizes.xxl}px;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        margin: 0;

        text-transform: uppercase;
    }

    > p {
        font-family: ${({ theme }) => theme.fontFamilies.openSans};
        font-size: ${({ theme }) => theme.fontSizes.s}px;
        font-weight: ${({ theme }) => theme.fontWeights.regular};
        margin: 0;
    }
`
