import PropTypes from 'prop-types'
import * as React from 'react'

function MilitaryMedal(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={props.size} width={props.size} viewBox="0 0 48 48">
            <title>{props.title}</title>
            <g fill={props.color} class="nc-icon-wrapper">
                <path d="M17,8H13v9a1,1,0,0,0,.485.857L17,19.966Z" fill={props.color}></path>
                <path d="M35,6V1a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1V6Z" fill={props.color}></path>
                <path d="M29,8H19V21.166l4.485,2.691a1,1,0,0,0,1.03,0L29,21.166Z" fill={props.color}></path>
                <path d="M31,8V19.966l3.515-2.109A1,1,0,0,0,35,17V8Z" fill={props.color}>
                    </path><path d="M37.043,32.477a1,1,0,0,0-.878-.689l-8.152-.6-3.087-7.568a1,1,0,0,0-1.852,0L19.987,31.19l-8.152.6a1,1,0,0,0-.572,1.761l6.244,5.274-1.951,7.939a1,1,0,0,0,1.5,1.088L24,43.542l6.946,4.308a1,1,0,0,0,1.5-1.088l-1.951-7.939,6.244-5.274A1,1,0,0,0,37.043,32.477Z" data-color="color-2"></path>
            </g>
        </svg>
    )
}

MilitaryMedal.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
}

export default MilitaryMedal