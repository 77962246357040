import React from 'react'
import { Form, Button } from '@aimhigh/web-components/web'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { fourColStyle } from '../../LineOfficer.styles'

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  column-gap: ${({ theme }) => theme.spacing.m}px;
  row-gap: ${({ theme }) => theme.spacing.xl}px;

  > * {
    grid-column: span 2;
  }
`

const CareersForm = ({ onSubmit }) => {
  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
    },
  })

  const handleFormSubmit = values => {
    // eslint-disable-next-line no-alert
    alert(
      `--form values--\n${Object.keys(values)
        .map(key => {
          const value = values[key]
          return `${key}: ${
            typeof value === 'object' ? JSON.stringify(value) : value
          }`
        })
        .join('\n')}`
    )

    onSubmit()
  }

  return (
    <StyledForm formHooks={ formHooks } onSubmit={ handleFormSubmit }>
      <div>Reorderable List...</div>
      <Button type='submit' style={ fourColStyle } dark>Next</Button>
    </StyledForm>
  )
}

export default CareersForm
