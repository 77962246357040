import React, { useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import { theme as ahaTheme } from '@aimhigh/theme'
import { FAQS } from './FAQText'
import ChevronDown from '../../../icons/ChevronDown'
import {
  Container,
  Header,
  Main,
  Title,
  Subtitle,
  ButtonContainer,
  Footer,
  FAQSection,
  BackgroundImage,
  FAQWrapper,
  FAQItem,
  FAQTitle,
  FAQContent, Spacer, SignInButton, RegisterButton
} from './LineOfficerLandingPage.styles'

function LineOfficerLandingPage({ history }) {
  const [expandedFaq, setExpandedFaq] = useState(null)

  const handleRegister = () => {
    history.push('/registration')
  }

  const handleSignIn = () => {
    history.push('/login')
  }

  const toggleFaq = index => {
    setExpandedFaq(expandedFaq === index ? null : index)
  }

  return (
    <ThemeProvider theme={ ahaTheme }>
      <Container>
        <BackgroundImage>
          <Header>
            <Spacer />
            <Title>A CAREER IN THE AIR<br />FORCE WILL TAKE<br />YOU PLACES</Title>
            <Subtitle>
              Discover impactful careers as an Air Force Line Officer, leading with purpose and integrity.
            </Subtitle>
            <ButtonContainer>
              <RegisterButton dark onClick={ handleRegister }>
                REGISTER
              </RegisterButton>
              <SignInButton onClick={ handleSignIn }>
                SIGN IN
              </SignInButton>
            </ButtonContainer>
          </Header>
        </BackgroundImage>

        <Main>
          <FAQSection>
            <h2>FAQS</h2>
            <FAQWrapper>
              { FAQS.map((faq, index) => (
                <FAQItem key={ index }>
                  <FAQTitle onClick={ () => toggleFaq(index) } $expanded={ expandedFaq === index }>
                    { faq.title }
                    <ChevronDown color={ ahaTheme.colors.text.nonessential } />
                  </FAQTitle>
                  <FAQContent $expanded={ expandedFaq === index }>
                    <p>{ faq.content }</p>
                  </FAQContent>
                </FAQItem>
              )) }
            </FAQWrapper>
          </FAQSection>
        </Main>

        <Footer>
          <a href='/privacy'>Privacy policy</a> | <a href='/terms'>Terms and Conditions</a>
        </Footer>
      </Container>
    </ThemeProvider>
  )
}

export default LineOfficerLandingPage
