import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import EventDirections from './EventDirections'
import { SHARE_EVENT_PATH } from '../../navigation/routes'
import { getEventImage, getEventSaga, selectErrorLoadingEvent, selectEvent } from './event.duck'
import { shareContentTypes } from '../../redux/constants/share.constants'
import InvalidShareLink from '../../pages/share/InvalidShareLink'
import Shareable from '../Share/Shareable'

EventSharePage.propTypes = {
  match: PropTypes.object,
}

function EventSharePage(props) {
  const dispatch = useDispatch()
  const event = useSelector(selectEvent)
  const errorLoadingEvent = useSelector(selectErrorLoadingEvent)

  useEffect(() => {
    const { eventId } = props.match.params
    dispatch(getEventSaga.request({ eventId }))
  }, [dispatch, props.match.params])

  if (errorLoadingEvent) {
    return <InvalidShareLink />
  }

  const eventImage = getEventImage(event)
  return (
    <Shareable
      sharePath={ `${SHARE_EVENT_PATH}/${event.id}` }
      imageUrl={ eventImage }
      title={ event && `${event.name}` }
      shareContentType={ shareContentTypes.EVENT }>
      <EventDirections event={ event } />
    </Shareable>
  )
}

export default withRouter(EventSharePage)
