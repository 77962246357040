import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@emotion/react'
import { Dimmer, Loader, Grid } from 'semantic-ui-react'
import { Header } from '../share/share.page.styles'
import afreCmsService from '../../api/afre.cms.service'
import { DarkPalette } from '../../theme'
import Intro from './Intro'
import Footer from './Footer'
import LeadForm from './LeadForm'
import ComponentLogo from '../../components/ComponentLogo/ComponentLogo'
import DetailsAccordion from './DetailsAccordion'
import styled from '@emotion/styled'
import spacing from '../../theme/spacing'
import About from './About'
import { getRankByRecruiterExperience } from './rankUtil'
import { NAV_ROUTES } from '../../navigation/routes'
import { logPageView } from '../../redux/sagas/amplitudeAnalytics.sagas'
import { useViewQrCode, useGetRecruiterExperience, useGetRecruiterFromCms } from '../../queries/qrCodeQueries'
import { ProfileFormGridColumnWrapper, LeadFormContent } from './profile.page.styles'
import { useMediaQuery } from '../../config/useMediaQuery'

export const Page = styled.div`
  background: ${({ theme }) => theme.background.level0};
  min-height: 100vh;
  padding: ${props => props.theme.spacing.m}px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
`

function getRecruiterAvatarUrl(url) {
  const avatarParts = url.split('/')
  return afreCmsService.getUserAvatarUrl(avatarParts[0], avatarParts[1])
}

RecruiterLeadPage.propTypes = {
  match: PropTypes.object,
}

function RecruiterLeadPage(props) {
  const dispatch = useDispatch()

  const { qrSort } = props.match.params
  const { isLoading: isLoadingQrInfo, data: qrInfo = {} } = useViewQrCode(qrSort)
  const { recruiter } = qrInfo
  const showLeadsForm = !recruiter?.hideLeadsCapture

  const { isLoading: isLoadingRecruiterExperienceData, data: recruiterExperience } = useGetRecruiterExperience(recruiter?.id)
  let avatarUrl = ''
  if (!!qrInfo?.recruiter?.avatar) {
    avatarUrl = getRecruiterAvatarUrl(qrInfo.recruiter.avatar)
  }

  const { isLoading: isLoadingRecruiterInfo, data: recruiterInformation } = useGetRecruiterFromCms(recruiter?.email)
  const fullName = recruiter ? `${recruiter.firstName} ${recruiter.lastName}` : ''

  const rank = getRankByRecruiterExperience(recruiterExperience, recruiter)

  useEffect(() => {
    dispatch(logPageView.request(NAV_ROUTES.RECRUITER_LEAD_PAGE))
  }, [dispatch])

  function renderProfileFooter() {
    return <Footer />
  }

  const isMobileSize = useMediaQuery('(max-width: 780px)')

  function renderLeadForm() {
    const onlyShowForm = showLeadsForm && !recruiter
    return (
      <Grid.Column width={ onlyShowForm ? 16 : 6 } style={ { maxWidth: isMobileSize ? '100%' : '50%' } }>
        <LeadForm
          qrSort={ qrSort }
          recruiterComponent={ recruiter?.recruiterComponent }
          recruiterId={ recruiter?.id }
          recruiterLeadType={ recruiter?.recruiterLeadType }
        />
      </Grid.Column>
    )
  }

  function renderAbout() {
    return !!recruiter?.websiteOverview &&
      <About websiteOverview={ recruiter.websiteOverview } title={ `${rank} ${recruiter?.lastName}` } />
  }

  function renderDetailsGrid() {
    if (!recruiter) {
      return
    }
    return (
      <div style={ { padding: spacing.xl } }>
        <Grid.Column style={ { paddingBottom: spacing.m } }>{ renderAbout() }</Grid.Column>
        <DetailsAccordion
          palette={ DarkPalette }
          user={ recruiter }
          recruiterExperience={ recruiterExperience }
          recruiterInformation={ recruiterInformation }
        />
      </div>
    )
  }

  const getIntro = isMobile => (
    <Intro
      isLoadingRecruiterExperienceData={ isLoadingRecruiterExperienceData }
      avatarUrl={ avatarUrl }
      palette={ DarkPalette }
      rank={ rank }
      name={ fullName }
      isMobile={ isMobile }
    />
  )

  const isLoading = isLoadingQrInfo || isLoadingRecruiterExperienceData || isLoadingRecruiterInfo
  const component = recruiter?.recruiterComponent || 'AF'
  const numberOfColumns = (recruiter && showLeadsForm) ? 2 : 1

  return (
    <ThemeProvider theme={ DarkPalette }>
      <Helmet>
        <meta property='og:url' content={ window.location.href } />
        <meta property='og:type' content='image' />
        <meta property='og:title' content='' />
        <meta property='og:description' content='' />
      </Helmet>
      <Dimmer.Dimmable dimmed={ isLoading }>
        <Dimmer active={ isLoading }>
          <Loader>Loading</Loader>
        </Dimmer>
        <Page style={ { justifyContent: 'center' } }>
          <Header style={ { padding: spacing.m, paddingBottom: spacing.l } }>
            { component ? <ComponentLogo recruiterComponent={ component } /> : <></> }
          </Header>
          <ThemeProvider theme={ DarkPalette }>
            <LeadFormContent stackable columns={ numberOfColumns } style={ { justifyContent: 'center' } }>
              { recruiter ?
                <ProfileFormGridColumnWrapper width={ showLeadsForm ? 10 : 16 }>
                  <Grid columns={ 1 }>
                    <Grid.Column only='computer tablet' >
                      { getIntro(false) }
                    </Grid.Column>
                    <Grid.Column only='mobile'>
                      { getIntro(true) }
                    </Grid.Column>
                    <Grid.Column >{ renderDetailsGrid() }</Grid.Column>
                  </Grid>
                </ProfileFormGridColumnWrapper> : <></> }
              { showLeadsForm ? renderLeadForm() : null }
            </LeadFormContent>
          </ThemeProvider>
          { renderProfileFooter() }
        </Page>
      </Dimmer.Dimmable>
    </ThemeProvider >
  )
}

export default RecruiterLeadPage
