import * as React from 'react';

function UserBadge({ size = 32, color, ...rest }) {
  return (
    <svg width={ size } height={ size } viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clip-path="url(#clip0_816_5409)">
        <path d="M19 15.3328H25.6667" stroke={ color } stroke-miterlimit="10" stroke-linecap="square"/>
        <path d="M19 20.6661H25.6667" stroke={ color } stroke-miterlimit="10" stroke-linecap="square"/>
        <path d="M25.6667 4.66611C25.6667 6.13944 24.4734 7.33277 23.0001 7.33277C21.5267 7.33277 20.3334 6.13944 20.3334 4.66611H12.3334C12.3334 6.13944 11.1401 7.33277 9.66675 7.33277C8.19341 7.33277 7.00008 6.13944 7.00008 4.66611H4.33341C2.86008 4.66611 1.66675 5.85944 1.66675 7.33277V25.9994C1.66675 27.4728 2.86008 28.6661 4.33341 28.6661H28.3334C29.8067 28.6661 31.0001 27.4728 31.0001 25.9994V7.33277C31.0001 5.85944 29.8067 4.66611 28.3334 4.66611H25.6667Z" stroke={ color } stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
        <path d="M12.5001 13.9994C12.5001 15.196 11.53 16.1661 10.3334 16.1661C9.1368 16.1661 8.16675 15.196 8.16675 13.9994C8.16675 12.8028 9.1368 11.8328 10.3334 11.8328C11.53 11.8328 12.5001 12.8028 12.5001 13.9994Z" fill={ color } />
        <path d="M5.75496 21.7799L5.75484 21.7803C5.58845 22.2701 5.98372 22.8328 6.56407 22.8328H14.1027C14.6821 22.8328 15.0777 22.2708 14.9118 21.7797L5.75496 21.7799ZM5.75496 21.7799C6.40159 19.8714 8.20743 18.4995 10.3334 18.4995C12.4593 18.4995 14.265 19.8713 14.9117 21.7796L5.75496 21.7799Z" fill={ color } />
      </g>
      <defs>
        <clipPath id="clip0_816_5409">
          <rect width={ size } height={ size } fill={ color } transform="translate(0 0.332764)"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default UserBadge;
