const stripNullAndUndefined = obj => {
  return Object.entries(obj)
    .filter(([_, v]) => v !== null && v !== undefined)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
}

export const queryStringURL = (url, queryParams) => {
  const validQueryParams = stripNullAndUndefined(queryParams)
  const queryParamsString = new URLSearchParams(validQueryParams).toString()
  return queryParamsString ? `${url}?${queryParamsString}` : url
}

export const axiosConfig = authToken => {
  if (authToken) {
    return {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  }
}
