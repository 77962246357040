import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, reset } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { Form, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { createFlight } from '../../redux/sagas/squadron.sagas'
import { NAV_ROUTES } from '../../navigation/routes'
import { SubmitButtonWrapper } from './squadron.form.styles'
import { required } from '../validation'
import InputField from '../../components/Forms/InputField'
import { MAX_LENGTH } from '../../redux/constants/form.values.constants'

class FlightCreateForm extends Component {
  handleFlightSubmit = values => {
    const { createFlight: createNewFlight, squadron, clearForm } = this.props
    values.id = squadron.id
    createNewFlight(values)
    clearForm()
  }

  render() {
    const { handleSubmit, history, squadron } = this.props
    return (
      <Form onSubmit={ handleSubmit(this.handleFlightSubmit) }>
        <Field
          id='name'
          name='name'
          label='Flight Name'
          component={ InputField }
          validate={ required }
          maxLength={ MAX_LENGTH.STANDARD }
        />
        <SubmitButtonWrapper>
          <Button data-testid='cancel-button' onClick={ () => history.push(NAV_ROUTES.FLIGHTS_PAGE, { squadron }) }>Cancel</Button>
          <Button data-testid='submit-button' primary type='submit'>Create</Button>
        </SubmitButtonWrapper>
      </Form>
    )
  }
}

FlightCreateForm.propTypes = {
  handleSubmit: PropTypes.func,
  createFlight: PropTypes.func,
  clearForm: PropTypes.func,
  squadron: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = () => {
  return {}
}

const mapActionsToProps = dispatch => {
  return {
    createFlight: params => dispatch(createFlight.request(params)),
    clearForm: () => dispatch(reset('flightCreate')),
  }
}

const Formed = reduxForm({
  form: 'flightCreate',
  enableReinitialize: true,
})(FlightCreateForm)
const ConnectedFlightCreateForm = connect(mapStateToProps, mapActionsToProps)(Formed)

export default withRouter(ConnectedFlightCreateForm)
