import isHotkey from 'is-hotkey'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Icon, Message } from 'semantic-ui-react'
import { createEditor, Editor, Range, Transforms } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, Slate, withReact } from 'slate-react'
import colors from '../../../theme/colors'
import spacing from '../../../theme/spacing'
import { borderRadius } from '../../../theme/typography'
import { selectVariables } from '../../redux/ducks/opa.duck'
import { OpaBlockButton, OpaElement, OpaHelpButton, OpaLeaf, OpaMarkButton, OpaToolbar } from './OpaRichTextComponents'
import { Table, TableCell, TableRow, withTables } from './Table'
import { insertVariable, Variable, withVariables } from './Variable'

const Element = props => {
  const { element } = props
  let elementComponent
  switch (element.id) {
    case 'variable':
      elementComponent = <Variable { ...props } />
      break
    case 'table':
      elementComponent = <Table { ...props } />
      break
    case 'table-row':
      elementComponent = <TableRow { ...props } />
      break
    case 'table-cell':
      elementComponent = <TableCell { ...props } />
      break
    default:
      elementComponent = <OpaElement { ...props } />
  }
  return elementComponent
}

Element.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  element: PropTypes.object,
}

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+u': 'underline',
  'mod+enter': 'new section',
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const OpaRichTextEditor = ({ value, onInputChange, textEditorIndex, readOnly }) => {

  const ref = useRef()
  const [target, setTarget] = useState()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const renderElement = useCallback(props => <Element { ...props } />, [])
  const renderLeaf = useCallback(props => <OpaLeaf { ...props } />, [])
  const editor = useMemo(() => withVariables(withTables(withReact(withHistory(createEditor())))), [])
  const variables = useSelector(selectVariables)

  const [filterVariables, setFilteredVariables] = useState(variables)

  useEffect(() => {
    if (variables) {
      const variableFilter = c => c.display.toLowerCase().startsWith(search.toLowerCase())
      const filteredVariables = variables.filter(variableFilter).slice(0, 10)
      variables && setFilteredVariables(filteredVariables)
    }
  }, [search, variables])

  const toggleHelpMessage = () => {
    setIsVisible(!isVisible)
  }

  const handleDismiss = () => {
    setIsVisible(false)
  }

  const onKeyDown = useCallback(event => {
    if (target) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault()
          setIndex(index >= filterVariables.length - 1 ? 0 : index + 1)
          break
        case 'ArrowUp':
          event.preventDefault()
          setIndex(index <= 0 ? filterVariables.length - 1 : index - 1)
          break
        case 'Tab':
        case 'Enter':
          event.preventDefault()
          Transforms.select(editor, target)
          insertVariable(editor, filterVariables[index])
          setTarget(null)
          break
        case 'Escape':
          event.preventDefault()
          setTarget(null)
          break
        default:
          // nothing to do
      }
    } else if (event.key === 'Enter') {
      event.preventDefault()
      editor.insertText('\n')
    }
    for (const hotkey in HOTKEYS) {
      if (isHotkey('mod+enter', event) && hotkey === 'mod+enter') {
        editor.insertBreak()
      } else if (isHotkey(hotkey, event)) {
        event.preventDefault()
        const mark = HOTKEYS[hotkey]
        toggleMark(editor, mark)
      }
    }
  },
  [index, target, filterVariables, editor]
  )

  return (
    <div style={ { width: '90ch',
      fontWeight: 'bold',
      fontFamily: 'Courier New',
      padding: spacing.s,
      border: `1px solid ${colors.black.default}`,
    } }>
      { isVisible &&
        <Message
          info
          size='large'
          onDismiss={ handleDismiss }
          header='Shortcuts Help'
          list={ [
            'Ctrl + Enter: Create new Section',
            '$$ Prefix: Insert Variable',
          ] }
        />
      }
      <Slate
        editor={ editor } value={ value }
        onChange={ () => {

          const { selection } = editor

          if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection)
            const wordBefore = Editor.before(editor, start, { unit: 'word' })
            const beforeRange = wordBefore && Editor.range(editor, wordBefore, start)
            const beforeText = beforeRange && Editor.string(editor, beforeRange)
            const beforeMatch = beforeText && beforeText.match(/\$\$(.*)/u)
            const after = Editor.after(editor, start)
            const afterRange = Editor.range(editor, start, after)
            const afterText = Editor.string(editor, afterRange)
            const afterMatch = afterText.match(/^(\s|$)/u)

            if (beforeMatch && afterMatch) {
              setTarget(beforeRange)
              setSearch(beforeMatch[1])
              setIndex(0)
              return
            }
          }

          setTarget(null)

          if (JSON.stringify(value) !== JSON.stringify(editor.children)) {
            onInputChange(editor.children, textEditorIndex)
          }
        } } >
        <OpaToolbar>
          <OpaMarkButton format='underline' icon={ <Icon name='underline' /> } />
          <OpaBlockButton format='center' icon={ <Icon name='align center' /> } />
          <OpaHelpButton icon={ <Icon name='question' /> } onClick={ toggleHelpMessage } active={ isVisible } />
        </OpaToolbar>
        <Editable
          renderElement={ renderElement }
          renderLeaf={ renderLeaf }
          placeholder=''
          spellCheck
          autoFocus
          onKeyDown={ onKeyDown }
          readOnly={ readOnly }
        />
        { target && filterVariables.length > 0 && (
          <div
            ref={ ref }
            style={ {
              zIndex: 1,
              padding: '3px',
              background: colors.white.default,
              borderRadius,
              boxShadow: `0 1px 5px ${colors.gray.mid}`,
            } }
            data-cy='variables-portal'>
            { filterVariables.map((variable, i) => (
              <div
                key={ variable.display }
                style={ {
                  padding: '1px 3px',
                  borderRadius: '3px',
                  color: colors.blue.default,
                  background: i === index ? colors.gray.extraLight : 'transparent',
                } }>
                { `${variable.display} - ${variable.description || ''}` }
              </div>
            )) }
          </div>
        ) }
      </Slate>
    </div>
  )
}

OpaRichTextEditor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onInputChange: PropTypes.func,
  textEditorIndex: PropTypes.number,
  readOnly: PropTypes.bool,
}

