import React from 'react'
import { Field } from 'redux-form'
import {
  P,
  Section,
  TwoColumnGrid,
  SectionTitle
} from '../afcepLandingPage/AfcepLandingPage.styles'
import { FormItem } from '../../../forms/form.styles'
import InputField from '../../../components/Forms/InputField'
import theme from '../../../theme'
import { stripToDigits } from '../../../forms/format'
import { dateInPast, maxValue999, maxValue99999, validAfrissDate } from '../../../forms/validation'

export const PilotCandidateSelectMethod = () => {
  return (
    <Section>
      <SectionTitle style={ { paddingBottom: theme.spacing.xs } } data-testid='pilot-candidate-selection-method-section-title'>Pilot Candidate Select Method (PCSM)</SectionTitle>
      <P data-testid='pcsm-description'>Required for all rated applications, not required for non-rated.</P>
      <TwoColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='pcsm-date-tested-field'>
          <Field
            id='pcsmTestDate'
            name='pcsmTestDate'
            data-testid='pcsmTestDate'
            label='Date Tested'
            component={ InputField }
            type='date'
            validate={ [dateInPast, validAfrissDate] }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='pcsm-score-field'>
          <Field
            id='pcsmScore'
            name='pcsmScore'
            data-testid='pcsmScore'
            label='Score'
            component={ InputField }
            type='text'
            parse={ value => stripToDigits(value) }
            validate={ [maxValue999] }
          />
        </FormItem>
      </TwoColumnGrid>
      <FormItem width={ { base: 'auto' } } data-testid='pcsm-flight-hours-field'>
        <Field
          id='pcsmFlightHours'
          name='pcsmFlightHours'
          data-testid='pcsmFlightHours'
          label='Flight Hours'
          component={ InputField }
          type='text'
          parse={ value => stripToDigits(value) }
          validate={ [maxValue99999] }
        />
      </FormItem>
    </Section>
  )
}
