import React from 'react'
import {
  Section,
  ThreeColumnGrid,
  TwoColumnGrid,
  SectionTitle
} from '../afcepLandingPage/AfcepLandingPage.styles'
import { minAge17, email, phoneNumber, ssn as validateSSN, required, maxLength200, maxLength320, validAfrissDate } from '../../../forms/validation'
import { formatSSN, stripToDigits } from '../../../forms/format'
import { FormItem } from '../../../forms/form.styles'
import InputField from '../../../components/Forms/InputField'
import { Field } from 'redux-form'

export const PersonalInformation = () => {
  return (
    <Section>
      <SectionTitle data-testid='personal-information-section-title'>Personal Information</SectionTitle>
      <ThreeColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='first-name-field'>
          <Field
            id='firstName'
            name='firstName'
            label='First Name*'
            component={ InputField }
            type='text'
            validate={ [required, maxLength200] }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='middle-name-field'>
          <Field
            id='middleName'
            name='middleName'
            label='Middle Name*'
            component={ InputField }
            type='text'
            validate={ [required, maxLength200] }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='last-name-field'>
          <Field
            id='lastName'
            name='lastName'
            label='Last Name*'
            component={ InputField }
            type='text'
            validate={ [required, maxLength200] }
          />
        </FormItem>
      </ThreeColumnGrid>
      <TwoColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='date-of-birth-field'>
          <Field
            id='dateOfBirth'
            name='dateOfBirth'
            label='Date of Birth*'
            component={ InputField }
            type='date'
            validate={ [required, minAge17, validAfrissDate] }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='social-security-number-field'>
          <Field
            id='ssn'
            name='ssn'
            label='Social Security Number*'
            component={ InputField }
            type='text'
            validate={ [required, validateSSN] }
            parse={ value => formatSSN(value) }
            placeholder='###-##-####'
          />
        </FormItem>
      </TwoColumnGrid>
      <ThreeColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='cell-phone-field'>
          <Field
            id='cellPhone'
            name='cellPhone'
            label='Cell Phone'
            component={ InputField }
            type='text'
            validate={ phoneNumber }
            parse={ value => stripToDigits(value) }
            maxLength={ 16 }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='home-phone-field'>
          <Field
            id='homePhone'
            name='homePhone'
            label='Home Phone'
            component={ InputField }
            type='text'
            validate={ phoneNumber }
            parse={ value => stripToDigits(value) }
            maxLength={ 16 }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='work-phone-field'>
          <Field
            id='workPhone'
            name='workPhone'
            label='Work Phone'
            component={ InputField }
            type='text'
            validate={ [phoneNumber] }
            parse={ value => stripToDigits(value) }
            maxLength={ 16 }
          />
        </FormItem>
      </ThreeColumnGrid>
      <FormItem width={ { base: 'auto' } } data-testid='business-email-address-field'>
        <Field
          id='businessEmailAddress'
          name='businessEmailAddress'
          label='Business (Gov) Email Address*'
          component={ InputField }
          type='text'
          validate={ [required, email, maxLength320] }
        />
      </FormItem>
      <FormItem width={ { base: 'auto' } } data-testid='personal-email-address-field'>
        <Field
          id='personalEmailAddress'
          name='personalEmailAddress'
          label='Personal Email Address'
          component={ InputField }
          type='text'
          validate={ [email, maxLength320] }
        />
      </FormItem>
    </Section>
  )
}
