import React from 'react'
import theme from '../../../theme/'

export const Field = ({ children, style, isBottom, ...props }) => {
  const fieldStyle = {
    paddingTop: isBottom ? theme.spacing.s : `${theme.spacing.m}px`,
    paddingBottom: isBottom ? theme.spacing.s : `${theme.spacing.s}px`,
    ...style,
  }
  return <div style={ fieldStyle } { ...props }>{ children }</div>
}

