import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

const getLinkComponent = type => styled(type)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  color: inherit;

  &:hover {
    color: inherit;
  }
`
export const LinkComponent = props => {
  const StyledLinkComponent = getLinkComponent(props.href ? 'a' : Link)
  return <StyledLinkComponent { ...props } />
}
