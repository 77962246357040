import styled from '@emotion/styled'

export const Page = styled.div`
    padding: 2rem;
`

export const ContactBox = styled.div`
    width: 275px;
    height: 150px;
    flex-shrink: 0;

    border: 1px solid ${({ theme }) => theme.background.level1};
    box-shadow: 0px 1px 2px 0 ${({ theme }) => theme.background.level1};
    border-radius: ${({ theme }) => theme.spacing.xs}px;

    @media (min-width: 992px) {
        float: right;
    }
`

export const ContactTextContainer = styled.div`
    display: grid;
    padding: ${({ theme }) => theme.spacing.m}px;
    gap: ${({ theme }) => theme.spacing.s}px;

    font-size: ${({ theme }) => theme.typography.fontSizes.m}px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    line-height: ${({ theme }) => theme.typography.fontSizes.l}px;
    text-align: left;


    .header {
        font-size: ${({ theme }) => theme.typography.fontSizes.l}px;
        font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
        line-height: ${({ theme }) => theme.typography.lineHeight}rem;
    }
`

export const StyledForm = styled.form`
@media (min-width: 992px) {
    max-width: 540px;
}
`
