import styled from '@emotion/styled'

export const ExploreMoreTeaserWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const ExploreListWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const ExploreItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 46px;
`

export const ExploreItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
`

export const ExploreItemText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;

  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 18px;
  color: ${props => props.theme.text.default};
  min-height: 36px;
`
