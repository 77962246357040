import { useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import HttpRequests from '../api/HttpRequests'
import { selectAuthUserToken } from '../redux/selectors/auth.selectors'
import { afreServiceUrl } from '../api/afre.service'

export const useDeleteAccount = () => {
  const authToken = useSelector(selectAuthUserToken)

  return useMutation(['deleteAccount'], async ({ password }) => {
    return await HttpRequests.postRequest(
      `${afreServiceUrl}/user/users/deleteUser`,
      { password },
      {},
      authToken
    )
  })
}
