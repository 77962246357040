import * as React from "react";
const SvgUpload = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2 10c0 1.885 0 2.829.586 3.414C3.171 14 4.115 14 6 14h4c1.885 0 2.829 0 3.414-.586C14 12.829 14 11.885 14 10m-6 .667V2m0 0 2.667 2.917M8 2 5.333 4.917"
    />
  </svg>
);
export default SvgUpload;