import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import {
  P,
  PageTitle,
  Main,
  InvalidEventPage,
  Content,
  TitleContainer,
  Footer,
  PrivacyPolicyLink
} from './afcepLandingPage/AfcepLandingPage.styles'
import SvgAirForce from '../../icons/AirForce'
import { DarkPalette } from '../../theme'
import { SVG_LOGO_HEADER_SIZE } from './afcep.constants'
import { NAV_ROUTES } from '../../navigation/routes'

const AfcepInvalidEventPage = () => {
  return (
    <ThemeProvider theme={ DarkPalette }>
      <InvalidEventPage>
        <Main style={ { maxWidth: '920px' } }>
          <TitleContainer>
            <SvgAirForce size={ SVG_LOGO_HEADER_SIZE } color={ DarkPalette.content.contrast } />
            <PageTitle style={ { textTransform: 'uppercase' } }>AFCEP Request Window Closed </PageTitle>
          </TitleContainer>
          <Content>
            <P>For information on board schedule, eligibility requirements and process of creating an OTS application go to the <a rel='noopener noreferrer' target='_blank' href='https://www.recruiting.af.mil/About-Us/Line-Officer-Candidate-Information-and-Resources/'>Line Officer Candidate Information website</a>.</P>
          </Content>
        </Main>
      </InvalidEventPage>
      <Footer style={ { textAlign: 'center', paddingBottom: '60px' } }>
        <PrivacyPolicyLink href={ NAV_ROUTES.PRIVACY_POLICY_PAGE } rel='noopener noreferrer' target='_blank'>Privacy Policy</PrivacyPolicyLink>
      </Footer>
    </ThemeProvider>
  )
}

export default withRouter(AfcepInvalidEventPage)
