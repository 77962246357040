import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'semantic-ui-react'

VariableWidthModal.propTypes = {
  isOpen: PropTypes.bool,
  content: PropTypes.any,
  width: PropTypes.string,
}

export function VariableWidthModal({ isOpen, width, content }) {
  return (
    <Modal
      style={ { width } }
      open={ isOpen }>
      {
        content
      }
    </Modal>
  )
}
