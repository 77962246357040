import * as React from 'react';

function Checkmark({ size = 10, color, ...rest }) {
  return (
    <svg width={ size * 1.4 } height={ size } viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M1.66675 5.66667L5.00008 9L12.3334 1" stroke={ color } stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default Checkmark;
