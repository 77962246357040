import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Icon } from 'semantic-ui-react'

const RadioButtonGroup = styled.div`
  display: flex;
  flex-direction: column;

  [type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
`

const Option = styled.label`
  max-width: fit-content;
  padding-bottom: ${({ theme }) => theme.spacing.m}px;
`
const OptionLabel = styled.label`
  margin-left: ${({ theme }) => theme.spacing.m}px;  
  color: ${({ error, theme }) => (error ? theme.text.negative : undefined)};
`

const GroupLabel = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.m}px;
  color: ${({ error, theme }) => (error ? theme.text.negative : undefined)};
`

RadioOption.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  group: PropTypes.string,
  role: PropTypes.string,
}

function RadioOption({ role, initialValue, error, checked, onChange, label, id, group }) {
  let color = checked ? 'blue' : 'grey'
  if (error) {
    color = 'red'
  }
  return (
    <RadioButtonGroup>
      <Option>
        <input type='radio' id={ id } name={ group } value={ initialValue } checked={ checked } onChange={ onChange } role={ role } />
        <Icon
          name={ checked ? 'check circle' : 'circle outline' }
          size='big'
          alt='Option 1'
          color={ color }
        />
        <OptionLabel htmlFor={ id } error={ error }>{ label }</OptionLabel>
      </Option>
    </RadioButtonGroup>
  )
}

RadioButton.propTypes = {
  testid: PropTypes.string,
  group: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
}

function RadioButton({ testid, group, label, error, onChange }) {
  const [checked, setChecked] = useState()
  const onOptionChecked = value => {
    setChecked(value)
    onChange(value)
  }
  return (
    <RadioButtonGroup data-testid={ testid }>
      <GroupLabel className={ error ? 'error' : undefined } error={ error }>{ label }</GroupLabel>
      <RadioOption
        initialValue={ 1 }
        checked={ checked === 1 }
        onChange={ e => onOptionChecked(e.target.checked ? 1 : 0) }
        label='Yes'
        group={ group }
        error={ error }
        id={ `${group}-yes` }
        role='option'
      />
      <RadioOption
        initialValue={ 0 }
        checked={ checked === 0 }
        onChange={ e => onOptionChecked(e.target.checked ? 0 : 1) }
        label='No'
        group={ group }
        error={ error }
        id={ `${group}-no` }
        role='option'
      />
    </RadioButtonGroup>
  )
}

export default RadioButton
