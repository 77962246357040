import * as React from 'react';

function Referrals(props) {
  return (
    <svg width={ 30 } height={ 30 } { ...props }>
      <path
        d="M11.333 17.8l2.75 2.8 5.5-5.6M17.75 3.8C17.75 2.253 16.519 1 15 1s-2.75 1.253-2.75 2.8H9.5v3.733h11V3.8h-2.75zm5.5.933H26V29H4V4.733h2.75"
        stroke={ props.color }
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Referrals;
