import PropTypes from 'prop-types'
import React from 'react'
import { Divider, Form, Grid, GridColumn, Input } from 'semantic-ui-react'
import { LargeFieldLabel } from '../../forms/form.styles'
import { ErrorLabel } from './UploadWeekGroupPage.styles'

const renderCondition = (value, sort, variable, onValueChange) => {

  return (
    <Grid.Row>
      { value.condition && value.trueLabel &&
        <GridColumn width={ 6 }>
          <LargeFieldLabel data-testid={ `${sort}-value-true-label` }>{ value.trueLabel }</LargeFieldLabel>
          <Form.TextArea
            data-testid={ `${sort}-true-input` }
            onChange={ (_eventData, event) => {
              onValueChange(event.value, variable, 'trueValue')
            } }
            value={ value.trueValue.text || '' }
          />
        </GridColumn>
      }
      {
        value.condition && value.falseLabel &&
          <GridColumn width={ 6 }>
            <LargeFieldLabel data-testid={ `${sort}-value-false-label` }>{ value.falseLabel }</LargeFieldLabel>
            <Form.TextArea
              data-testid={ `${sort}-false-input` }
              onChange={ (_eventData, event) => {
                onValueChange(event.value, variable, 'falseValue')
              } }
              value={ value.falseValue.text || '' }
            />
          </GridColumn>
      }
    </Grid.Row>
  )
}

export const VariableInput = ({ variable, onDescriptionChange, onValueChange, error }) => {
  const { display, value, sort, description = '', type } = variable
  return (
    <>
      <Grid>
        <Grid.Row data-testid='variable-row' >
          <Grid.Column width={ 12 }>
            <LargeFieldLabel data-testid={ `${sort}-variable-label` } fontWeight='medium' fontSize='l'>{ display }</LargeFieldLabel>
            {
              error && <ErrorLabel>{ error.toUpperCase() }</ErrorLabel>
            }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row >
          <Grid.Column width={ 12 }>
            <LargeFieldLabel data-testid={ `${sort}-description-label` } fontWeight='light'>Description:</LargeFieldLabel>
            <Input
              style={ { width: '100%', marginBottom: 10 } }
              data-testid={ `${sort}-description-input` }
              onChange={ (_eventData, event) => {
                onDescriptionChange(event.value, variable)
              } }
              value={ description }
            />
          </Grid.Column>
        </Grid.Row>
        {
          type === 'dynamic' &&
            <Grid.Row >
              <Grid.Column width={ 12 }>
                <LargeFieldLabel data-testid={ `${sort}-value-label` } fontWeight='light'>Value:</LargeFieldLabel>
                <Form.TextArea
                  data-testid={ `${sort}-input` }
                  onChange={ (_eventData, event) => {
                    onValueChange(event.value, variable)
                  } }
                  value={ value.text || '' }
                />
              </Grid.Column>
            </Grid.Row>
        }
        { value.condition && renderCondition(value, sort, variable, onValueChange) }
      </Grid>
      <Divider />
    </>
  )
}

VariableInput.propTypes = {
  error: PropTypes.string,
  onValueChange: PropTypes.func,
  onDescriptionChange: PropTypes.func,
  variable: PropTypes.object,
}

