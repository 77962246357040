import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router'
import { NAV_ROUTES } from '../../navigation/routes'
import { selectIsOpaAdmin, selectIsOpaMtl } from '../../redux/selectors/auth.selectors'

export const OpaMtlRoute = ({ component: RoutedComponent, ...rest }) => {
  const isOpaAdmin = useSelector(selectIsOpaAdmin)
  const isOpaMtl = useSelector(selectIsOpaMtl)

  return (
    <Route
      { ...rest }
      render={ props => ((isOpaAdmin || isOpaMtl) ? (
        <RoutedComponent { ...props } />
      ) : (
        <Redirect
          to={ {
            pathname: NAV_ROUTES.LOGIN_PAGE,
            state: { from: props.location },
          } }
        />
      ))
      }
    />
  )
}

OpaMtlRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.string,
}
