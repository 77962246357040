import React from 'react';

function UnfilledCircle({ size = 24, ...props }) {
  return (
    <svg viewBox="0 0 25 25" height={ size } width={ size } { ...props }>
      <circle
        cx={ 12.5 }
        cy={ 12.5 }
        stroke="rgba(27, 38, 50, 0.65)"
        r={ 11 }
        strokeWidth={ 1 }
        fill="none"
      />
    </svg>
  );
}
UnfilledCircle.displayName = UnfilledCircle;
export default UnfilledCircle;
