import styled from '@emotion/styled'

export const Header = styled.header`
  padding-bottom: 50px;
  padding-top: 20px;
  display: flex;
  width: 100%;
  align-items: center;
`
export const LeftSide = styled.div`
    display: 'flex';
    padding-left: '40px';
    padding-right: '40px';
    justify-content: 'left';
    align-items: 'center';
    flex-direction: 'column';
    height: '100%';
`
export const Container = styled.div(props => ({
  backgroundColor: props.theme.colors.page.background,
  height: '100%',
  overflow: 'auto',

}))
export const Wrapper = styled.div`
    display: flex;
    padding-left: 200px;
    padding-right: 200px;
    @media screen and (max-width: 780px) {
        padding-left: 15px;
        padding-right: 15px;
    }
    @media screen and (min-width: 780px) and (max-width: 1200px) {
        padding-left: 40px;
        padding-right: 40px;
    }
    justify-content: 'left';
    height: 100%;
    overflow: auto;
    flex-direction: column;
`
export const StarrBody = styled.div`
  display:flex;
  justify-content: space-between;
  @media screen and (max-width: 780px) {
    flex-direction: column;
  }

`
export const Title = styled.div`
    font-family: stratum2-regular;
    color: ${props => props.theme.text.contrast};
    font-size: 70px;
    font-weight: bold;
    line-height: 30px;
    width: 100%;
    text-transform: uppercase;
    @media screen and (max-width: 780px) {
      font-size: 50px;
    }
  `
export const SubTitle = styled.div`
    font-family: stratum2-regular;
    color: ${props => props.theme.text.contrast};
    font-size: 30px;
    font-weight: bold;
    line-height: 28px;
    width: 100%;
    text-transform: uppercase;
    padding-bottom: 30px;
    @media screen and (max-width: 780px) {
      padding-bottom: 20px;
    }
  `

export const HorizontalLineWrapper = styled.div`
    width: 100%;
    height: 10px;
    padding-bottom: 30px;
    padding-top: 30px;
    @media screen and (max-width: 780px) {
      padding-bottom: 20px;
      padding-top: 20px;

    }
  `

export const HorizontalLine = styled.div`
    width: 80px;
    height: 10px;
    background-color:#C8A667;
`
export const ItemsWrapper = styled.div`
   display: flex;
   flex-direction: column;
   a, a:visited, a:hover, a:active {
    color: white;
  }
  padding-bottom: 20px;
`
export const ItemWrapper = styled.div`
   display: flex;
   padding-bottom: 30px;
   align-items: center;
`
export const ItemTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
`
export const ItemDescription = styled.div`
   color: ${props => props.theme.text.contrast};
   font-size: 20px;
   padding-top: 5px;
`
export const ItemDescriptionWrapper = styled.div`
  font-family: stratum2-regular;
  display:flex;
  flex-direction: column;
  color: ${props => props.theme.text.contrast};
  padding-left: 15px;
  width: 85%;

`
export const IconWrapper = styled.div`
  background-color:#C8A667;
  width: 40px;
  height: 40px;
  border-radius:50%;
  box-shadow: 0 0 0 5px #3E3D3D;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const HowToItemsDeskTop = styled.div`
@media screen and (max-width: 780px) {
  display: none;
}
`
export const HowToItemsMobile = styled.div`
 padding-top: 20px;
@media screen and (min-width: 780px) {
  display: none;

}`
export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a, a:visited, a:hover, a:active {
    color: white;
  }
  padding-right: 50px;

`

export const SectionBody = styled.div(props => ({
  fontSize: '14px',
  fontWeight: '300',
  color: props.theme.colors.white.default,
  marginBottom: `${props.theme.spacing.m}px`,
}))
