import { all, put } from 'redux-saga/effects'
import { opaSagas } from '../opa/redux/ducks/opa.duck'
import { startupActions } from '../redux/constants/startup.constants'
import { featureToggleSagas } from '../featureToggles/featureToggleDuck'
import amplitudeAnalyticsSagas from '../redux/sagas/amplitudeAnalytics.sagas'
import { appShareSagas } from '../components/appShare/appShare.duck'
import authSagas from '../redux/sagas/auth.sagas'
import { careerSagas } from '../components/careers/career.duck'
import { eventSagas } from '../components/event/event.duck'
import referralSagas from '../redux/sagas/referral.sagas'
import squadronSagas from '../redux/sagas/squadron.sagas'
import startupSagas from '../redux/sagas/startup.sagas'
import userSagas from '../redux/sagas/user.sagas'

export default function* rootSaga() {
  try {
    yield all([
      ...careerSagas,
      ...authSagas,
      ...referralSagas(),
      ...eventSagas,
      ...userSagas,
      ...startupSagas,
      ...squadronSagas,
      ...appShareSagas,
      ...amplitudeAnalyticsSagas(),
      ...opaSagas,
      ...featureToggleSagas,
    ])
    yield put({ type: startupActions.RUN_STARTUP_SAGA })
  } catch (error) {
    yield put({ type: 'ROOT_FAILURE', error })
  }
}
