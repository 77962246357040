import React, { useEffect, useState } from 'react'
import { NAV_ROUTES } from '../../navigation/routes'
import AHSidebar from '../../components/Navigation/NavSidebar'
import { Header, Grid, Button, Message, Dimmer, Loader, Accordion, AccordionTitle, Icon, AccordionContent, Table } from 'semantic-ui-react'
import LogoutButton from '../../navigation/LogoutButton'
import { ContactBox, ContactTextContainer, Page, StyledForm } from './MassAlertsForm.styles'
import { ThemeProvider } from '@emotion/react'
import theme, { LightPalette } from '../../theme'
import { withRouter } from 'react-router'
import { Field, getFormSyncErrors, hasSubmitFailed, reduxForm } from 'redux-form'
import DropdownField from '../../components/Forms/DropdownField'
import { maxLength250, required, url } from '../../forms/validation'
import { FormItem } from '../../forms/form.styles'
import InputField from '../../components/Forms/InputField'
import { useSelector } from 'react-redux'
import AimHighModal from '../../components/Modal/AimHighModal'
import { useGetMassAlertHistory, useSendMassAlert } from '../../queries/notificationQueries'
import { SuccessMessage } from '../../components/Message/Message'
import TextAreaField from '../../components/Forms/TextAreaField'

const MASS_ALERTS_FORM_NAME = 'mass-alerts'
const initialValues = { alertType: null, message: null, newsArticleLink: null }

const MassAlertsForm = props => {
  const { handleSubmit } = props
  const submitFailed = useSelector(hasSubmitFailed(MASS_ALERTS_FORM_NAME))
  const formErrors = useSelector(getFormSyncErrors(MASS_ALERTS_FORM_NAME))
  const [openAlertConfirmation, setOpenAlertConfirmation] = useState(false)
  const [isAlertHistoryOpen, setIsAlertHistoryOpen] = useState(false)
  const [massAlert, setMassAlert] = useState()

  const {
    isError: getMassAlertHistoryIsError,
    data: massAlertHistory,
    refetch: refetchMassAlertHistory,
  } = useGetMassAlertHistory()
  const {
    isError: saveMassAlertIsError,
    isLoading: saveMassAlertIsLoading,
    isSuccess: saveMassAlertIsSuccess,
    isPending: saveMassAlertIsPending,
    mutate: saveMassAlertMutate,
  } = useSendMassAlert()

  const handleFormSubmit = values => {
    setMassAlert(values)
    setOpenAlertConfirmation(true)
  }

  useEffect(() => {
    if (saveMassAlertIsSuccess) {
      refetchMassAlertHistory()
    }
  }, [saveMassAlertIsSuccess, refetchMassAlertHistory])

  return (
    <ThemeProvider theme={ LightPalette }>
      <AHSidebar currentRoute={ NAV_ROUTES.NOTIFICATIONS_PAGE } />
      <Page>
        <Dimmer.Dimmable dimmed={ saveMassAlertIsPending || saveMassAlertIsLoading }>
          <Dimmer active={ saveMassAlertIsPending || saveMassAlertIsLoading } page>
            <Loader>Sending</Loader>
          </Dimmer>
          <Header as='h1'>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column data-testid='lackland-mass-alert-notifications-header'>
                  Lackland Mass Alert Notifications
                </Grid.Column>
                <Grid.Column textAlign='right'><LogoutButton /></Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
          <Grid doubling columns={ 2 }>
            <Grid.Column>
              <StyledForm onSubmit={ handleSubmit(handleFormSubmit) }>
                <AimHighModal
                  isOpen={ openAlertConfirmation }
                  modalHeader='Confirmation'
                  modalContent='Are you sure you want to send a Mass Alert Notification to all Aim High App Users?'
                  cancelButtonText='Cancel'
                  cancelHandler={ () => {
                    setOpenAlertConfirmation(false)
                  } }
                  confirmButtonText='Send'
                  confirmHandler={ () => {
                    saveMassAlertMutate({
                      alertType: massAlert.alertType,
                      message: massAlert.message,
                      link: massAlert.newsArticleLink,
                    })
                    setOpenAlertConfirmation(false)
                  } }
                />
                <div>
                  { (formErrors && submitFailed) &&
                    <Message
                      error
                      color='red'
                      style={ { marginTop: theme.spacing.s, borderRadius: theme.radii.s } }
                      content={
                        <p data-testid='form-error-message' style={ { fontSize: `${theme.typography.fontSizes.m}px` } }>
                          Error: Ensure all required fields are filled, and verify that <br /> the link entered is valid a URL.
                        </p>
                      }
                    />
                  }
                  { saveMassAlertIsError &&
                    <Message
                      error
                      color='red'
                      style={ { marginTop: theme.spacing.s, borderRadius: theme.radii.s } }
                      header='Mass Alert Send Failure'
                      content={
                        <p data-testid='mass-alert-send-failure' style={ { fontSize: `${theme.typography.fontSizes.m}px` } }>
                          We're sorry, but there was a problem sending your mass alert. Please try again later.
                        </p>
                      }
                    />
                  }
                  { saveMassAlertIsSuccess &&
                    <SuccessMessage
                      header='Mass Alert Successfully Sent'
                      style={ {
                        backgroundColor: LightPalette.background.positive,
                        color: LightPalette.text.utility,
                      } }
                    />
                  }
                  <FormItem data-testid='alert-type-field' width={ { base: 'auto' } }>
                    <Field
                      id='alertType'
                      name='alertType'
                      label='Alert Type*'
                      placeholder='Select'
                      options={ [
                        { text: 'All Clear', value: 'All Clear' },
                        { text: 'Exercise', value: 'Exercise' },
                        { text: 'Emergency', value: 'Emergency' },
                        { text: 'Warning', value: 'Warning' },
                        { text: 'Weather Alert', value: 'Weather Alert' },
                      ] }
                      component={ DropdownField }
                      validate={ required }
                    />
                  </FormItem>
                  <FormItem data-testid='form-message' width={ { base: 'auto' } }>
                    <Field
                      id='message'
                      name='message'
                      label='Message*'
                      component={ TextAreaField }
                      type='textarea'
                      maxLength={ 250 }
                      validate={ [required, maxLength250] }
                      placeholder='Type Message Here...'
                      resize='vertical'
                    />
                  </FormItem>
                  <FormItem data-testid='news-article-link-field' width={ { base: 'auto' } }>
                    <Field
                      id='newsArticleLink'
                      name='newsArticleLink'
                      label='News Article Link'
                      component={ InputField }
                      validate={ url }
                      placeholder='https://news-link.com'
                      type='text'
                    />
                  </FormItem>
                  <FormItem>
                    <div data-testid='send-button' style={ { padding: theme.spacing.s, paddingBottom: 0 } }>
                      <Button
                        primary
                        size='medium'
                        type='submit'
                        name='submit'>
                        Send
                      </Button>
                    </div>
                  </FormItem>
                </div>
              </StyledForm>
            </Grid.Column>
            <Grid.Column>
              <ContactBox>
                <ContactTextContainer data-testid='contact-container'>
                  <div data-testid='contact-header' className='header'>
                    Contact
                  </div>
                  <div data-testid='contact-description'>
                    If you have trouble sending an alert, please contact:
                  </div>
                  <div data-testid='contact-numbers'>
                    MSgt Mustin - 210-419-4531
                    Michael Cabrera - 816-504-7367
                  </div>
                </ContactTextContainer>
              </ContactBox>
            </Grid.Column>
          </Grid>
          <Grid doubling columns={ 1 }>
            <Grid.Column>
              <div style={ { padding: theme.spacing.s } }>
                { getMassAlertHistoryIsError &&
                  <Message
                    error
                    color='red'
                    style={ { marginTop: theme.spacing.s, borderRadius: theme.radii.s } }
                    header='Mass Alert History Error'
                    content={
                      <p style={ { fontSize: `${theme.typography.fontSizes.m}px` } }>
                        We're sorry, but there was a problem retrieving the mass alert history. Please try again later.
                      </p>
                    }
                  />
                }
                <Accordion data-testid='alert-history-accordion'>
                  <AccordionTitle
                    data-testid='alert-history-title'
                    active={ isAlertHistoryOpen }
                    onClick={ () => setIsAlertHistoryOpen(!isAlertHistoryOpen) }
                    style={ {
                      fontWeight: theme.typography.fontWeights.bold,
                      fontSize: `${theme.typography.fontSizes.l}px`,
                      lineHeight: '24px',
                    } }>
                    <Icon name='dropdown' />
                    Alert History
                  </AccordionTitle>
                  <AccordionContent active={ isAlertHistoryOpen }>
                    <Table striped>
                      <Table.Header data-testid='table-header'>
                        <Table.Row data-testid='table-row'>
                          <Table.HeaderCell data-testid='alert-date-header-cell'>Alert Date</Table.HeaderCell>
                          <Table.HeaderCell data-testid='alert-type-header-cell'>Alert Type</Table.HeaderCell>
                          <Table.HeaderCell data-testid='alert-message-header-cell'>Message</Table.HeaderCell>
                          <Table.HeaderCell data-testid='alert-link-header-cell'>Link</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body data-testid='table-body'>
                        { massAlertHistory && massAlertHistory.map(({ sort,
                          alertDate,
                          alertType,
                          message,
                          link,
                        }) => {
                          const formattedAlertDate = new Date(alertDate)
                          const formattedMonth =
                            formattedAlertDate.getMonth() < 10 ? `0${formattedAlertDate.getMonth()}` : formattedAlertDate.getMonth()
                          const formattedDay =
                            formattedAlertDate.getDate() < 10 ? `0${formattedAlertDate.getDate()}` : formattedAlertDate.getDate()

                          return (
                            <Table.Row key={ sort }>
                              <Table.Cell data-testid='alert-date-table-cell'>
                                { formattedMonth }/{ formattedDay }/{ formattedAlertDate.getFullYear() }
                              </Table.Cell>
                              <Table.Cell data-testid='alert-type-table-cell'>{ alertType }</Table.Cell>
                              <Table.Cell data-testid='alert-message-table-cell'>{ message }</Table.Cell>
                              <Table.Cell data-testid='alert-link-table-cell'>
                                { link ? <a rel='noopener noreferrer' target='_blank' href={ link }>{ link }</a> : 'No Link' }
                              </Table.Cell>
                            </Table.Row>
                          )
                        }) }
                      </Table.Body>
                    </Table>
                  </AccordionContent>
                </Accordion>
              </div>
            </Grid.Column>
          </Grid>
        </Dimmer.Dimmable>
      </Page>
    </ThemeProvider>
  )
}

export default withRouter(reduxForm({
  form: MASS_ALERTS_FORM_NAME,
  initialValues,
})(MassAlertsForm))
