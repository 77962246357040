import PropTypes from 'prop-types'
import React from 'react'
import GooglePlayImg from '../../icons/google_play_badge.png'
import SvgAppleStore from '../../icons/SvgAppleStore'
import { APP_STORE_LINKS } from '../../redux/constants/referral.constants'
import { logAppStoreNavigation } from '../../redux/sagas/amplitudeAnalytics.sagas'
import { SuccessMessage } from '../Message/Message'
import { ThankYouCardBody, ThankYouCardContainer, ThankYouCardFooter, ThankYouCardHeader } from './ThankYouCard.styles'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import spacing from '../../theme/spacing'
import { borderRadius, fontSizes } from '../../theme/typography'

ThankYouCard.propTypes = {
  referAFriend: PropTypes.bool,
}

const LearnMoreButton = styled.a`
  background-color: ${props => props.theme.background.action};
  color: ${props => props.theme.text.contrast};
  font-size: ${fontSizes.s};
  padding: ${spacing.s}px;
  border-radius: ${borderRadius}px;
  border: none;
  font-family: stratum2-bold;
  text-transform: uppercase;
`

function ThankYouCard({ referAFriend }) {
  const dispatch = useDispatch()

  const renderWhatsNext = () => (
    <>
      <ThankYouCardHeader data-testid='thank-you-card-header'>{ `What's Next?` }</ThankYouCardHeader>
      <ThankYouCardBody data-testid='thank-you-card-body'>
        Download and visit the Aim High App to learn about Air Force opportunities.
      </ThankYouCardBody>
      <ThankYouCardFooter>
        <a
          onClick={ () => {
            dispatch(logAppStoreNavigation.request(APP_STORE_LINKS.ios))
            window.location.href = APP_STORE_LINKS.ios
          } }
          href={ APP_STORE_LINKS.ios }
          data-testid='download-on-the-app-store'
          download
          style={ { display: 'flex' } }>
          <SvgAppleStore />
        </a>
        <a
          onClick={ () => {
            dispatch(logAppStoreNavigation.request(APP_STORE_LINKS.android))
            window.location.href = APP_STORE_LINKS.android
          } }
          data-testid='get-it-on-google-play'
          download
          style={ { display: 'flex', width: 145 } }>
          <img
            src={ GooglePlayImg }
            alt='Android Directions'
            style={ { width: '100%', height: '100%' } }
          />
        </a>
      </ThankYouCardFooter>
    </>
  )

  const renderReferredAFriend = () => (
    <>
      <ThankYouCardHeader data-testid='thank-you-card-header'>
        Airmen / Guardians Participating in Starr
      </ThankYouCardHeader>
      <ThankYouCardBody data-testid='thank-you-card-body'>
        Learn more about the STARR Program and how you can achieve an award for your referrals.
      </ThankYouCardBody>
      <ThankYouCardFooter>
        <LearnMoreButton
          data-testid='learn-more-button'
          rel='noopener noreferrer'
          target='_blank'
          href='https://www.af.mil/News/Article-Display/Article/3399745/air-force-space-force-to-offer-medals-promotions-for-recruiting-support/'>
          Learn More
        </LearnMoreButton>
      </ThankYouCardFooter>
    </>
  )

  return (
    <ThankYouCardContainer data-testid='success-message'>
      <SuccessMessage
        header='Successfully Sent'
        content={
          referAFriend ? 'Thank you for your referral.' : 'A representative will contact you as soon as possible.'
        }
      />
      { referAFriend ? renderReferredAFriend() : renderWhatsNext() }
    </ThankYouCardContainer>
  )
}

export default ThankYouCard
