import React from 'react'
import { withRouter } from 'react-router-dom'
import { Header, Grid, Container, Button } from 'semantic-ui-react'
import LogoutButton from '../../navigation/LogoutButton'
import FlightsForm from '../../forms/squadron/flights.form'
import PropTypes from 'prop-types'
import NavLink from '../../components/Navigation/NavLink'
import { NAV_ROUTES } from '../../navigation/routes'

const SquadronFlightsPage = ({ location: { state: { squadron } } }) => (
  <Container>
    <Header as='h1'>
      <Grid>
        <Grid.Row columns='equal'>
          <Grid.Column data-testid='flights-for-squadron-header'>Flights for Squadron { squadron.name }</Grid.Column>
          <Grid.Column textAlign='right'><LogoutButton /></Grid.Column>
        </Grid.Row>
      </Grid>
    </Header>
    <FlightsForm squadron={ squadron } />
    <Button data-testid='back-button'>
      <NavLink to={ NAV_ROUTES.SQUADRONS_PAGE }>Back</NavLink>
    </Button>
  </Container>
)

SquadronFlightsPage.propTypes = {
  location: PropTypes.object,
}

export default withRouter(SquadronFlightsPage)
