import { takeLatest, call, put, select } from 'redux-saga/effects'
import AsyncSaga from './util/AsyncSaga'
import afreService from '../../api/afre.service'
import { squadronActions } from '../constants/squadrons.constants'
import { selectLastSquadronKey, selectFlightsForSquadron } from '../selectors/squadron.selectors'

export const createSquadron = new AsyncSaga(squadronActions.CREATE_SQUADRON, function* fetchSquadronsSaga(action) {
  const params = action.params
  const response = yield call(afreService.createSquadron, params)
  if (!response || !response.data || !response.data.data) {
    throw new Error('Could not create Squadron')
  }
  yield put(createSquadron.success({ squadron: response.data.data }))
}).catch(function* createSquadronCatch(error) {
  yield put(createSquadron.error(error, false))
})

export const fetchSquadrons = new AsyncSaga(squadronActions.FETCH_SQUADRONS, function* fetchSquadronsSaga() {
  const lastKey = yield select(selectLastSquadronKey)
  const params = lastKey ? {} : false
  
  if (lastKey) {
    params.startAt = JSON.stringify(lastKey)
  }

  const response = yield call(afreService.fetchSquadrons, params)
  if (!response || !response.data || !response.data.data) {
    throw new Error('Could not fetch Squadrons')
  }
  yield put(fetchSquadrons.success(response.data.data))
}).catch(function* fetchSquadronsCatch(error) {
  yield put(fetchSquadrons.error(error, false))
})

export const updateSquadron = new AsyncSaga(squadronActions.UPDATE_SQUADRON, function* updateSquadronSaga({ params }) {
  const response = yield call(afreService.updateSquadron, params)
  if (!response || !response.data || !response.data.data) {
    throw new Error('Could not update Squadron')
  }
  yield put(updateSquadron.success({ squadron: response.data.data }))
}).catch(function* updateSquadronCatch(error) {
  yield put(updateSquadron.error(error, false))
})

export const deleteSquadron = new AsyncSaga(squadronActions.DELETE_SQUADRON, function* deleteSquadronSaga(action) {
  const response = yield call(afreService.deleteSquadron, action.params)
  if (response.status !== 200) {
    throw new Error('Could not delete Squadron')
  }
  yield put(deleteSquadron.success({ squadronId: action.params }))
}).catch(function* deleteSquadronCatch(error) {
  yield put(deleteSquadron.error(error, false))
})

export const getSquadronFlights = new AsyncSaga(squadronActions.GET_SQUADRON_FLIGHTS, function* getSquadronFlightsSaga(action) {
  const existingFlight = yield select(selectFlightsForSquadron(action.params))
  if (existingFlight.length > 0) {
    return existingFlight
  } else {
    const response = yield call(afreService.getSquadron, action.params)
    if (!response || !response.data || !response.data.data) {
      throw new Error('Could not get Squadron Flights')
    }
    yield put(getSquadronFlights.success(response.data.data))
  }
}).catch(function* getSquadronFlightsCatch(error) {
  yield put(getSquadronFlights.error(error, false))
})

export const createFlight = new AsyncSaga(squadronActions.CREATE_FLIGHT, function* createFlightSaga({ params }) {
  const createFlightResponse = yield call(afreService.createFlight, params.name, params.id)
  if (!createFlightResponse || !createFlightResponse.data || !createFlightResponse.data.data) {
    throw new Error('Could not create Flight')
  }
  yield put(createFlight.success({ flight: createFlightResponse.data.data }))
}).catch(function* createFlightCatch(error) {
  yield put(createFlight.error(error, false))
})

export const updateFlight = new AsyncSaga(squadronActions.UPDATE_FLIGHT, function* updateFlightSaga({ params }) {
  const updateFlightResponse = yield call(afreService.updateFlight, params)
  if (!updateFlightResponse || !updateFlightResponse.data || !updateFlightResponse.data.data) {
    throw new Error('Could not update Flight')
  }
  yield put(updateFlight.success({ flight: updateFlightResponse.data.data }))
}).catch(function* updateFlightCatch(error) {
  yield put(updateFlight.error(error, false))
})

export const deleteFlight = new AsyncSaga(squadronActions.DELETE_FLIGHT, function* deleteFlightSaga({ params }) {
  const response = yield call(afreService.deleteFlight, params)
  if (response.status !== 200) {
    throw new Error('Could not delete Flight')
  }
  yield put(deleteFlight.success({ flightId: params.flightId }))
}).catch(function* deleteFlightCatch(error) {
  yield put(deleteFlight.error(error, false))
})
  
export default [
  takeLatest(createSquadron.REQUEST, createSquadron.saga),
  takeLatest(fetchSquadrons.REQUEST, fetchSquadrons.saga),
  takeLatest(updateSquadron.REQUEST, updateSquadron.saga),
  takeLatest(getSquadronFlights.REQUEST, getSquadronFlights.saga),
  takeLatest(createFlight.REQUEST, createFlight.saga),
  takeLatest(updateFlight.REQUEST, updateFlight.saga),
  takeLatest(deleteSquadron.REQUEST, deleteSquadron.saga),
  takeLatest(deleteFlight.REQUEST, deleteFlight.saga),
]
