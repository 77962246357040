import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { Header } from '../../components/Forms/Lead/Header'
import { Label } from '../../components/Forms/Lead/Label'
import Spacing from '../../theme/spacing'
import { CITIZENSHIP_STATUS } from './constants'

const Field = styled.div`
  padding-top: ${Spacing.m}px;
  padding-bottom: ${Spacing.m}px;
`
const StyledDropdown = styled(Dropdown)`
  margin-top: ${({ theme }) => theme.spacing.m}px;
`

Citizenship.propTypes = {
  errors: PropTypes.object,
  dispatch: PropTypes.func,
  dispatchError: PropTypes.func,
}

function Citizenship({ dispatch, dispatchError, errors }) {
  const onFocus = name => () => {
    dispatchError({ ...errors, [name]: false })
  }
  const onChange = name => (_event, { value }) => {
    dispatch({ type: name, value })
  }

  return (
    <>
      <Header>Citizenship</Header>
      <Field>
        <Label htmlFor='citizenshipStatus' error={ errors.citizenshipStatus }>
          Citizenship Status
        </Label>
        <StyledDropdown
          id='citizenshipStatus'
          fluid
          selection
          data-testid='citizenshipStatus'
          error={ errors.citizenshipStatus }
          options={ CITIZENSHIP_STATUS }
          onFocus={ onFocus('citizenshipStatus') }
          onChange={ onChange('citizenshipStatus') }
        />
      </Field>
    </>
  )
}

export default Citizenship
