import colors from './colors'
import { DarkPalette, LightPalette, PALETTE_IDS } from './palette'
import spacing from './spacing'
import { fontSizes, fontWeights, lineHeight, fontFamilies } from './typography'

export { DarkPalette, LightPalette, PALETTE_IDS }

export default {
  // Colors DEPRECATED: ThemeProvider should load LightPalette or DarkPalette directly
  colors: {
    ...colors,
  },
  spacing: {
    ...spacing,
  },
  typography: {
    fontSizes,
    fontWeights,
    lineHeight,
    fontFamilies,
  },
  radii: {
    s: 4,
    m: 8,
  },
  transition: {
    quick: '0.05s',
    default: '0.125s',
    slow: '0.250s',
  },
  breakpoints: {
    s: '42em', // 672px
    m: '50em', // 800px
    l: '68em', // 1088px
    xl: '80em', // 1280px
  },
}
