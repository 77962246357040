import { ThemeProvider } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Dimmer, Grid, Input, Loader, Modal, Table } from 'semantic-ui-react'
import { NAV_ROUTES } from '../../navigation/routes'
import { LightPalette } from '../../theme'
import spacing from '../../theme/spacing'
import { AfscModal } from '../components/Modals/AfscModal'
import { OpaPageHeader } from '../components/PageTitle/OpaPageHeader'
import {
  deleteAfscProperties,
  getAfscProperties,
  selectAddingAfscProperties,
  selectAfscPropertyList,
  selectDeleteAfscWarning,
  selectDeletingAfscProperties,
  selectGettingAfscProperties,
  selectUpdatingAfscProperties,
  updateAfscProperties
} from '../redux/ducks/opa.duck'
import {
  ActionButton,
  CancelButton,
  DangerButton,
  LeftButtonGroup,
  NegativeIcon,
  RightButtonGroup,
  ScrollablePage,
  StickyTableHeader,
  TableHeaderCell
} from './Opa.styles'

const COLUMNS = {
  afsc: 'afsc',
  isFlightPhysical: 'isFlightPhysical',
  isSmallMove: 'isSmallMove',
  isSheppard: 'isSheppard',
  isOver20: 'isOver20',
  isSpaceForce: 'isSpaceForce',
}

const SORTED_TYPES = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
}

const shouldShowSpinner = (isGettingAfscProperties, isUpdatingAfscProperties, isDeletingAfsc, isAddingAfsc) => isGettingAfscProperties ||
isUpdatingAfscProperties ||
isDeletingAfsc ||
isAddingAfsc

const isSortedByColumn = (sortedByColumn, column, sortType) => {
  return sortedByColumn === column ? sortType : null
}

export const AfscManagementPage = () => {
  const dispatch = useDispatch()
  const isGettingAfscProperties = useSelector(selectGettingAfscProperties)
  const isUpdatingAfscProperties = useSelector(selectUpdatingAfscProperties)
  const isDeletingAfsc = useSelector(selectDeletingAfscProperties)
  const isAddingAfsc = useSelector(selectAddingAfscProperties)
  const currentAfscList = useSelector(selectAfscPropertyList)
  const deleteWarning = useSelector(selectDeleteAfscWarning)

  const [afscList, setAfscList] = useState([])
  const [sortedAfscList, setSortedAfscList] = useState([])
  const [afscToDelete, setAfscToDelete] = useState(null)
  const [afscModalOpen, setAfscModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteWarningModalOpen, setDeleteWarningModalOpen] = useState(false)
  const [afscSearchValue, setAfscSearchValue] = useState('')
  const [sortedByColumn, setSortedByColumn] = useState(COLUMNS.afsc)
  const [sortedDescending, setSortedDescending] = useState(false)

  const showSpinner = shouldShowSpinner(isGettingAfscProperties, isUpdatingAfscProperties, isDeletingAfsc, isAddingAfsc)

  const updateAfsc = (afscData, updatedData) => {
    const updatedAfsc = { ...afscData }
    switch (updatedData) {
      case 'isBulk':
        updatedAfsc.isBulk = true
        updatedAfsc.isFlightPhysical = false
        break
      case 'isFlightPhysical':
        updatedAfsc.isBulk = false
        updatedAfsc.isFlightPhysical = true
        break
      default:
        updatedAfsc[updatedData.name] = updatedData.checked
    }

    dispatch(updateAfscProperties.request({ updatedAfsc, afscData, currentAfscList: sortedAfscList }))
  }

  const deleteAfscPropertiesHandler = afscData => {
    setAfscToDelete(afscData)
    setDeleteModalOpen(true)
  }

  useEffect(() => {
    dispatch(getAfscProperties.request())
  }, [dispatch])

  useEffect(() => {
    setAfscList(currentAfscList)
    setSortedAfscList(currentAfscList ? currentAfscList : [])
  }, [currentAfscList])

  useEffect(() => {
    if (deleteWarning) {
      setDeleteWarningModalOpen(true)
    }
  }, [deleteWarning])

  useEffect(() => {
    const filteredValues = sortedAfscList.filter(afsc => afsc.sort.includes(afscSearchValue))
    setAfscList(filteredValues)
  }, [sortedAfscList, afscSearchValue])

  const sortType = sortedDescending ? SORTED_TYPES.DESCENDING : SORTED_TYPES.ASCENDING

  const secondarySortByAfsc = (a, b) => {
    if (b.sort > a.sort) {
      return -1
    }
    if (a.sort > b.sort) {
      return 1
    }
    return 0
  }

  const resortAfscList = (column, fullList = false) => {
    let sortKey = column
    const isSortedByAfsc = column === COLUMNS.afsc
    if (isSortedByAfsc) {
      sortKey = 'sort'
    }
    const list = fullList ? currentAfscList : afscList
    return [...list].sort((a, b) => {
      const obj1 = sortedDescending ? b : a
      const obj2 = sortedDescending ? a : b
      if (obj1[sortKey] > obj2[sortKey]) {
        return -1
      }
      if (obj2[sortKey] > obj1[sortKey]) {
        return 1
      }
      if (!isSortedByAfsc) {
        return secondarySortByAfsc(a, b)
      }
      return 0
    })
  }

  const handleSearch = (_e, data) => {
    const searchValue = data.value.toUpperCase()
    setAfscSearchValue(searchValue)
  }

  const changeSortType = column => {
    setSortedDescending(!sortedDescending)
    setSortedByColumn(column)
    setAfscList(resortAfscList(column))
    setSortedAfscList(resortAfscList(column, true))
  }

  const headerControls = [
    <ActionButton
      key='addAfscAction'
      data-testid='add-afsc-button'
      width={ 2 }
      onClick={ () => setAfscModalOpen(true) }>
      Add AFSC
    </ActionButton>,
    <Input
      data-testid='search-field'
      key='searchAfscInput'
      width={ 3 }
      icon='search'
      placeholder='Search...'
      onChange={ handleSearch }
    />,
  ]

  return (
    <>
      <ThemeProvider theme={ LightPalette }>
        <AfscModal isOpen={ afscModalOpen } currentAfscList={ sortedAfscList } setOpen={ setAfscModalOpen } />
        {
          afscToDelete &&
            <Modal
              open={ deleteModalOpen }>
              <Modal.Header data-testid='confirm-delete-header'>Confirm Delete</Modal.Header>
              <Modal.Content data-testid='confirm-delete-text'>
                <p>Are you sure you want to delete AFSC: { afscToDelete.sort }?</p>
              </Modal.Content>
              <Modal.Actions>
                <CancelButton onClick={ () => {
                  setDeleteModalOpen(false)
                  setAfscToDelete(null)
                } }>
                  Cancel
                </CancelButton>
                <DangerButton
                  content='Delete'
                  icon='trash alternate outline'
                  data-testid='danger-button'
                  onClick={ () => {
                    dispatch(deleteAfscProperties.request({ afscToDelete, currentAfscList: sortedAfscList }))
                    setDeleteModalOpen(false)
                    setAfscToDelete(null)
                  } }
                />
              </Modal.Actions>
            </Modal>
        }
        <Modal
          open={ deleteWarningModalOpen }>
          <Modal.Header data-testid='delete-warning-header'>Delete Warning</Modal.Header>
          <Modal.Content data-testid='delete-warning-text'>
            <p>{ deleteWarning }</p>
          </Modal.Content>
          <Modal.Actions>
            <ActionButton onClick={ () => {
              setDeleteWarningModalOpen(false)
            } }>
              Close
            </ActionButton>
          </Modal.Actions>
        </Modal>
        <ScrollablePage>
          <Dimmer.Dimmable dimmed={ showSpinner }>
            <Dimmer active={ showSpinner } page>
              <Loader>Loading</Loader>
            </Dimmer>
            <div style={ { margin: spacing.m } }>
              <OpaPageHeader
                pageRoute={ NAV_ROUTES.AFSC_MANAGEMENT_PAGE }
                pageTitle='AFSC Management'
                headerComponents={ headerControls }
              />
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    { afscList && afscList.length > 0 ? (
                      <Table celled striped sortable style={ { width: '65%' } }>
                        <StickyTableHeader data-testid='table-header'>
                          <Table.Row data-testid='table-row'>
                            <TableHeaderCell
                              data-testid='afsc-table-header-cell'
                              sorted={ isSortedByColumn(sortedByColumn, COLUMNS.afsc, sortType) }
                              onClick={ () => changeSortType(COLUMNS.afsc) }>
                              AFSC
                            </TableHeaderCell>
                            <TableHeaderCell
                              data-testid='bulk-or-flight-physical-table-header-cell'
                              sorted={ isSortedByColumn(sortedByColumn, COLUMNS.isFlightPhysical, sortType) }
                              onClick={ () => changeSortType(COLUMNS.isFlightPhysical) }>
                              Bulk or Flight Physical
                            </TableHeaderCell>
                            <TableHeaderCell
                              data-testid='small-moves-table-header-cell'
                              sorted={ isSortedByColumn(sortedByColumn, COLUMNS.isSmallMove, sortType) }
                              onClick={ () => changeSortType(COLUMNS.isSmallMove) }>
                              Small Moves
                            </TableHeaderCell>
                            <TableHeaderCell
                              data-testid='sheppard-table-header-cell'
                              sorted={ isSortedByColumn(sortedByColumn, COLUMNS.isSheppard, sortType) }
                              onClick={ () => changeSortType(COLUMNS.isSheppard) }>
                              Sheppard
                            </TableHeaderCell>
                            <TableHeaderCell
                              data-testid='over-20-table-header-cell'
                              sorted={ isSortedByColumn(sortedByColumn, COLUMNS.isOver20, sortType) }
                              onClick={ () => changeSortType(COLUMNS.isOver20) }>
                              Over 20
                            </TableHeaderCell>
                            <TableHeaderCell
                              data-testid='space-force-table-header-cell'
                              sorted={ isSortedByColumn(sortedByColumn, COLUMNS.isSpaceForce, sortType) }
                              onClick={ () => changeSortType(COLUMNS.isSpaceForce) }>
                              Space Force
                            </TableHeaderCell>
                            <TableHeaderCell data-testid='delete-afsc-table-header-cell'>Delete AFSC</TableHeaderCell>
                          </Table.Row>
                        </StickyTableHeader>

                        <Table.Body data-testid='table-body'>
                          {
                            afscList.map(afscData => (
                              <Table.Row key={ afscData.sort } data-testid='afsc-row'>
                                <Table.Cell data-testid='afsc-table-cell'>{ afscData.sort }</Table.Cell>
                                <Table.Cell data-testid='bulk-or-flight-physical-table-cell'>
                                  <Button.Group size='small'>
                                    <LeftButtonGroup
                                      data-testid='bulk-button'
                                      active={ afscData.isBulk }
                                      onClick={ () => (afscData.isFlightPhysical ? updateAfsc(afscData, 'isBulk') : null) }>
                                      Bulk
                                    </LeftButtonGroup>
                                    <RightButtonGroup
                                      data-testid='flight-physical-button'
                                      active={ afscData.isFlightPhysical }
                                      onClick={ () => (afscData.isBulk ? updateAfsc(afscData, 'isFlightPhysical') : null) }>
                                      Flight Physical
                                    </RightButtonGroup>
                                  </Button.Group>
                                </Table.Cell>
                                <Table.Cell data-testid='small-moves-table-cell'>
                                  <Checkbox
                                    toggle
                                    name='isSmallMove'
                                    data-testid='small-moves-toggle'
                                    checked={ afscData.isSmallMove }
                                    onChange={ (_e, eventData) => updateAfsc(afscData, eventData) }
                                  />
                                </Table.Cell>
                                <Table.Cell data-testid='sheppard-table-cell'>
                                  <Checkbox
                                    toggle
                                    name='isSheppard'
                                    data-testid='sheppard-toggle'
                                    checked={ afscData.isSheppard }
                                    onChange={ (_e, eventData) => updateAfsc(afscData, eventData) }
                                  />
                                </Table.Cell>
                                <Table.Cell data-testid='over-20-table-cell'>
                                  <Checkbox
                                    toggle
                                    name='isOver20'
                                    data-testid='over-20-toggle'
                                    checked={ afscData.isOver20 }
                                    onChange={ (_e, eventData) => updateAfsc(afscData, eventData) }
                                  />
                                </Table.Cell>
                                <Table.Cell data-testid='space-force-table-cell'>
                                  <Checkbox
                                    toggle
                                    name='isSpaceForce'
                                    data-testid='space-force-toggle'
                                    checked={ afscData.isSpaceForce }
                                    onChange={ (_e, eventData) => updateAfsc(afscData, eventData) }
                                  />
                                </Table.Cell>
                                <Table.Cell data-testid='delete-afsc-table-cell'>
                                  <NegativeIcon
                                    link
                                    name='trash alternate outline'
                                    data-testid='trash-icon-button'
                                    size='large'
                                    onClick={ () => deleteAfscPropertiesHandler(afscData) }
                                  />
                                </Table.Cell>
                              </Table.Row>
                            ))
                          }
                        </Table.Body>
                      </Table>
                    ) : (
                      <p data-testid='no-results-found-text'>No Results Found For { afscSearchValue }</p>
                    ) }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </Dimmer.Dimmable>
        </ScrollablePage>
      </ThemeProvider>
    </>
  )
}

