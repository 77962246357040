import styled from '@emotion/styled'
import theme, { LightPalette } from '../../../theme'

export const Container = styled.div`
  background-color: ${LightPalette.background.level0};
  border-radius: ${theme.spacing.m}px;
  padding-top: ${theme.spacing.l}px;
  padding-bottom: ${theme.spacing.l}px;
  padding-left: ${theme.spacing.xl}px;
  padding-right: ${theme.spacing.xl}px;
  max-width: 850px;
  width: 100%;
  align-items: center;

  @media (max-width: 767px) {
    padding-top: ${theme.spacing.s}px;
    padding-bottom: ${theme.spacing.s}px;
    padding-left: ${theme.spacing.m}px;
    padding-right: ${theme.spacing.m}px;
  }
`
