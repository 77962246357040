export const MAX_LENGTH = {
  STANDARD: 200,
  LONG: 400,
  EXTRA_LONG: 1000,
  CHAT_MESSAGES: 2000,
}

export const DEGREE_RECEIVED_OPTIONS = [
  {
    text: 'None',
    value: 'none',
  },
  {
    text: 'High School Student or Graduate',
    value: 'highSchool',
  },
  {
    text: 'Technical/Vocational Student or Graduate',
    value: 'techSchool',
  },
  {
    text: 'College Student or Graduate',
    value: 'college',
  },
  {
    text: 'Healthcare Student or Healthcare Professional',
    value: 'healthcare',
  },
]
