import React from 'react'
import { FormTextField, Button } from '@aimhigh/web-components/web'
import { textFieldValidation, SVG_LOGO_HEADER_SIZE, emailValidation } from './lineOfficer/lineOfficer.constants'
import { useForm } from 'react-hook-form'
import { ThemeProvider } from '@emotion/react'
import { theme as ahaTheme } from '@aimhigh/theme'

import {
  Header,
  Main,
  StyledLogo,
  HeaderText,
  StyledForm,
  Footer,
  ErrorText
} from './lineOfficer/LineOfficer.styles'
import { loginEmail } from '../redux/sagas/auth.sagas'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { selectLoginEmailErrors } from '../redux/selectors/auth.selectors'

export const LoginPage = ({ routedFrom }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const loginError = useSelector(selectLoginEmailErrors)

  const handleFormSubmit = values => {
    dispatch(loginEmail.request({ values, history, routedFrom }))
  }

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  })
  return (
    <ThemeProvider theme={ ahaTheme }>
      <Header>
        <StyledLogo size={ SVG_LOGO_HEADER_SIZE } color={ ahaTheme.colors.text.white } />
        <HeaderText>
          <h1>Sign In</h1>
          <p>Sign in to access your account and continue where you left off.</p>
        </HeaderText>
      </Header>
      <Main $withFooter >
        <StyledForm
          style={ { maxWidth: '345px' } }
          onSubmit={ handleFormSubmit }
          formHooks={ formHooks }>
          { loginError &&
            <ErrorText data-testid='invalid-login-message'>
              { loginError.message || 'Invalid email/password' }
            </ErrorText>
          }
          <FormTextField label='Email Address' name='email' rules={ { ...textFieldValidation, ...emailValidation } } dark />
          <FormTextField label='Password' name='password' rules={ textFieldValidation } dark type='password' />
          <Button type='submit' onClick={ formHooks.handleSubmit(handleFormSubmit) } dark>Sign In</Button>
        </StyledForm>
      </Main>
      <Footer>
        <a href='#'>Privacy policy</a> | <a href='#'>Terms and Conditions</a>
      </Footer>
    </ThemeProvider>
  )
}
