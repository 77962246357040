import React from 'react'
import { Field, formValueSelector } from 'redux-form'
import { useSelector } from 'react-redux'
import theme from '../../../theme'
import {
  P,
  Section,
  ThreeColumnGrid,
  TwoColumnGrid,
  SectionTitle
} from '../afcepLandingPage/AfcepLandingPage.styles'
import { GRADUATED_SCHOOL, GRADE_RELEVANCE, DEGREE_TYPE, AFCEP_FORM_NAME } from '../afcep.constants'
import { EDUCATION_LEVEL } from '../../lead/constants'
import { MAJOR } from '../education.major'
import { FormItem } from '../../../forms/form.styles'
import DropdownField from '../../../components/Forms/DropdownField'
import InputField from '../../../components/Forms/InputField'
import { dateInPast, gpa, maxLength100, maxLength200, maxValue99, required, requiredBooleanField, startDateIsOlder, validAfrissDate } from '../../../forms/validation'
import { stripToDigits } from '../../../forms/format'
import { Checkbox } from '../../../components/Forms/Checkbox'

const getFormValue = formValueSelector(AFCEP_FORM_NAME)

export const Education = () => {
  const presentEducation = useSelector(state => getFormValue(state, 'present'))
  const isEndDateRequired = presentEducation === undefined ? true : !presentEducation

  return (
    <Section>
      <SectionTitle style={ { paddingBottom: theme.spacing.xs } } data-testid='education-section-title'>Education</SectionTitle>
      <P data-testid='education-description'>Only enter qualifying degree information</P>
      <FormItem width={ { base: 'auto' } } data-testid='school-type-field'>
        <Field
          id='schoolType'
          name='schoolType'
          label='School Type*'
          data-testid='schoolType'
          placeholder='Select'
          options={ EDUCATION_LEVEL }
          component={ DropdownField }
          validate={ required }
        />
      </FormItem>
      <FormItem width={ { base: 'auto' } } data-testid='name-of-school-field'>
        <Field
          id='schoolName'
          name='schoolName'
          data-testid='schoolName'
          label='Name of School*'
          component={ InputField }
          type='text'
          validate={ [required, maxLength200] }
        />
      </FormItem>
      <ThreeColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='school-start-date-field'>
          <Field
            id='startDate'
            name='startDate'
            data-testid='startDate'
            label='Started on*'
            component={ InputField }
            type='date'
            validate={ [required, dateInPast, validAfrissDate] }
          />
        </FormItem>
        { isEndDateRequired && (
          <FormItem width={ { base: 'auto' } } data-testid='school-end-date-field'>
            <Field
              id='endDate'
              name='endDate'
              data-testid='endDate'
              label='Ended on'
              component={ InputField }
              type='date'
              validate={ [required, startDateIsOlder, validAfrissDate] }
            />
          </FormItem>
        ) }
        <FormItem style={ { alignSelf: 'flex-end', paddingBottom: `calc(${theme.spacing.s}px - ${theme.spacing.xxs}px)` } } data-testid='present-checkbox'>
          <Field
            name='present'
            component={ ({ input }) => {
              return (
                <Checkbox
                  label='Present'
                  data-testid='present'
                  style={ { paddingBottom: theme.spacing.m, fontSize: theme.typography.fontSizes.m } }
                  checked={ input.name === 'present' && input.value === true }
                  onChange={ (_e, data) => input.onChange(!!data.checked)
                  }
                />
              )
            } }
          />
        </FormItem>
      </ThreeColumnGrid>
      <ThreeColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='number-years-completed-field'>
          <Field
            id='numberOfYearsCompleted'
            name='numberOfYearsCompleted'
            data-testid='numberOfYearsCompleted'
            label='No. Years Completed*'
            component={ InputField }
            type='text'
            validate={ [required, maxValue99] }
            parse={ value => stripToDigits(value) }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='graduated-field'>
          <Field
            id='graduated'
            name='graduated'
            label='Graduated*'
            data-testid='graduated'
            placeholder='Select'
            options={ GRADUATED_SCHOOL }
            component={ DropdownField }
            validate={ requiredBooleanField }
          />
        </FormItem>
        <FormItem style={ { alignSelf: 'flex-end', paddingBottom: `calc(${theme.spacing.s}px - ${theme.spacing.xxs}px)` } } data-testid='qual-degree-checkbox'>
          <Field
            name='qualDegree'
            component={ ({ input }) => {
              return (
                <Checkbox
                  label='Qual Degree'
                  data-testid='qualDegree'
                  style={ { paddingBottom: theme.spacing.m, fontSize: theme.typography.fontSizes.m } }
                  checked={ input.name === 'qualDegree' && input.value === true }
                  onChange={ (_e, data) => input.onChange(!!data.checked)
                  }
                />
              )
            } }
          />
        </FormItem>
      </ThreeColumnGrid>
      <FormItem width={ { base: 'auto' } } data-testid='major-field'>
        <Field
          id='major'
          name='major'
          label='Major*'
          data-testid='major'
          placeholder='Select'
          options={ MAJOR }
          component={ DropdownField }
          validate={ required }
        />
      </FormItem>
      <TwoColumnGrid style={ { alignItems: 'flex-end' } } data-testid='degree-title-field'>
        <FormItem width={ { base: 'auto' } }>
          <Field
            id='degreeTitle'
            name='degreeTitle'
            data-testid='degreeTitle'
            label='Degree Title*'
            tooltip={ <div>Exact major title from transcript</div> }
            component={ InputField }
            type='text'
            validate={ [required, maxLength100] }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='degree-type-field'>
          <Field
            id='degreeType'
            name='degreeType'
            label='Degree Type*'
            data-testid='degreeType'
            placeholder='Select'
            options={ DEGREE_TYPE }
            component={ DropdownField }
            validate={ required }
          />
        </FormItem>
      </TwoColumnGrid>
      <TwoColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='degree-gpa-field'>
          <Field
            id='degreeGpa'
            name='degreeGpa'
            data-testid='degreeGpa'
            label='Degree GPA*'
            component={ InputField }
            type='text'
            validate={ [required, gpa] }
            parse={ value => stripToDigits(value) }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='grade-relevance-field'>
          <Field
            id='gradeRelevance'
            name='gradeRelevance'
            label='Grade Relevance*'
            data-testid='gradeRelevance'
            placeholder='BS'
            options={ GRADE_RELEVANCE }
            component={ DropdownField }
            validate={ required }
            disabled
          />
        </FormItem>
      </TwoColumnGrid>
    </Section>
  )
}
