import PropTypes from 'prop-types'
import React from 'react'
import { Icon, Table } from 'semantic-ui-react'
import spacing from '../../../theme/spacing'
import { ARMS_STATUS_ICONS, canEditWeekGroupOrder } from '../../pages/armsStatusProperties'
import { NegativeIcon } from '../../pages/Opa.styles'
import { ArmsStatusIcon, DynamicTableCell } from '../../pages/ProcessWeekGroupPage.styles'
import { ARMS_STATUS } from '../../redux/ducks/opa.duck'

const getCellValue = (weekGroupRow, dataKey) => {
  if (dataKey === 'isSpaceForce') {
    return weekGroupRow[dataKey] ? 'YES' : 'NO'
  }
  return weekGroupRow[dataKey]
}

const getArmsStatusIcon = weekGroupRow => {
  const { armsStatus } = weekGroupRow
  return armsStatus ? ARMS_STATUS_ICONS[armsStatus] : ARMS_STATUS_ICONS[ARMS_STATUS.NOT_READY]
}

const OpaOrderTableRow = props => {
  const {
    setSelectedWeekGroupRow,
    setEditOrdersModalOpen,
    setEditModalOpen,
    setWeekGroupDataToDelete,
    setDeleteModalText,
    setDeleteModalOpen,
  } = props.setterFunctions

  const isRowEditable = canEditWeekGroupOrder(props.weekGroupRow, props.showArmsFeatures)

  const renderEditOrdersCell = dataKey => {
    return (
      <Table.Cell data-testid='edit-view-full-order-table-cell' key={ dataKey } style={ { textAlign: 'center' } }>
        <Icon
          data-testid='edit-view-full-order-icon'
          title={ `${isRowEditable ? 'Edit' : 'View'} Full Order` }
          name={ isRowEditable ? 'edit' : 'eye' }
          link
          size='large'
          onClick={ () => {
            setSelectedWeekGroupRow(props.weekGroupRow)
            setEditOrdersModalOpen(true)
          } }
        />
      </Table.Cell>
    )
  }

  const renderEditDeleteWeekGroupRowCell = dataKey => {
    return (
      <Table.Cell key={ dataKey }>
        <div style={ { display: 'flex', justifyContent: 'space-between', minWidth: spacing.xl } }>
          <Icon
            link={ isRowEditable }
            data-testid='edit-order-icon'
            title='Edit Row'
            name='pencil'
            size='large'
            disabled={ !isRowEditable }
            onClick={ () => {
              setSelectedWeekGroupRow(props.weekGroupRow)
              setEditModalOpen(true)
            } }
          />
          <NegativeIcon
            link={ isRowEditable }
            data-testid='delete-order-icon'
            title='Delete Row'
            name='trash alternate outline'
            size='large'
            disabled={ !isRowEditable }
            onClick={ () => {
              setWeekGroupDataToDelete(props.weekGroupRow)
              setDeleteModalText(`Are you sure you want to delete ${props.weekGroupRow.name}?`)
              setDeleteModalOpen(true)
            } }
          />
        </div>
      </Table.Cell>
    )
  }

  const renderArmsStatusCell = dataKey => {
    const item = getArmsStatusIcon(props.weekGroupRow)
    const { icon, color } = item
    const status = props.weekGroupRow?.armsStatus ? props.weekGroupRow.armsStatus : ARMS_STATUS.NOT_READY

    return props.showArmsFeatures ? (
      <Table.Cell data-testid='arms-status-table-cell' key={ dataKey } style={ { textAlign: 'center', color } }>
        <ArmsStatusIcon
          title={ status }
          name={ icon }
          iconcolor={ color }
          size='large'
        />
      </Table.Cell>
    ) : null
  }

  const renderDynamicTableCell = dataKey => {
    return (
      <DynamicTableCell
        data-testid='table-cell'
        key={ dataKey }
        errors={ props.weekGroupRowErrors[dataKey] }
        title={
          props.weekGroupRowErrors[dataKey] ?
            props.weekGroupRowErrors[dataKey].toUpperCase() : undefined
        }>
        { getCellValue(props.weekGroupRow, dataKey) }
      </DynamicTableCell>
    )
  }

  return (
    <Table.Row data-testid='order-row'>
      {
        props.weekGroupRowProperties.map(weekGroupRowProperty => {
          const dataKey = weekGroupRowProperty.key
          switch (dataKey) {
            case 'editOrders':
              return renderEditOrdersCell(dataKey)
            case 'editDeleteWeekGroupRow':
              return renderEditDeleteWeekGroupRowCell(dataKey)
            case 'armsStatus':
              return renderArmsStatusCell(dataKey)
            default:
              return renderDynamicTableCell(dataKey)
          }
        })
      }
    </Table.Row>
  )
}

OpaOrderTableRow.propTypes = {
  weekGroupRow: PropTypes.object,
  weekGroupRowProperties: PropTypes.array,
  setterFunctions: PropTypes.object,
  showArmsFeatures: PropTypes.bool,
  weekGroupRowErrors: PropTypes.object,
}

export default OpaOrderTableRow
