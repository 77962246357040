/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@emotion/react'
import { SuccessMessage } from '../../components/Message/Message'
import SvgAirForce from '../../icons/AirForce'
import { P, ContentContainer, Footer, Header, Main, H3, Ol, Page } from './afcepLandingPage/AfcepLandingPage.styles'
import theme, { DarkPalette, LightPalette } from '../../theme'
import { SVG_LOGO_HEADER_SIZE } from './afcep.constants'

const SUCCESS_MESSAGE_STYLES = {
  backgroundColor: LightPalette.background.positive,
  color: LightPalette.text.utility,
  marginBottom: `${theme.spacing.xl}px`,
}

export const AfcepSuccess = ({ location }) => {
  const { state } = location

  return (
    <ThemeProvider theme={ DarkPalette }>
      <Page>
        <Header>
          <SvgAirForce data-testid='air-force-logo' size={ SVG_LOGO_HEADER_SIZE } color={ LightPalette.background.level0 } />
        </Header>
        <Main>
          <ThemeProvider theme={ LightPalette }>
            <ContentContainer>
              <SuccessMessage
                header='Application Request Successfully Sent'
                content={ `A confirmation email will be sent to ${state.email} shortly.` }
                style={ SUCCESS_MESSAGE_STYLES }
              />
              <H3 data-testid='whats-next-header'>What's Next?</H3>
              <Ol data-testid='whats-next-steps'>
                <li>Within the next 10-20 minutes, you will receive an email verification of your successful application request and additional information about the AFCEP process.</li>
                <li>In 2-3 business days, you will receive an AFCEP system email with your AFCEP login information. Your AFCEP login information will expire in 2 weeks upon receiving the AFCEP system email.
                </li>
              </Ol>
              <P data-testid='more-information'>For information on board schedule, go to the Line Officer Candidate Information website
                (<a rel='noopener noreferrer' target='_blank' href='https://www.recruiting.af.mil/About-Us/Line-Officer-Candidate-Information-and-Resources/'>https://www.recruiting.af.mil/About-Us/Line-Officer-Candidate-Information-and-Resources/</a>) and download the board schedule and AD AF or USSF program announcements.
              </P>
              <P data-testid='questions-contact'>If you have additional questions, please contact <a rel='noopener noreferrer' target='_blank' href='mailto:AFRS.LO.Accessions@us.af.mil'>AFRS.LO.Accessions@us.af.mil</a></P>

            </ContentContainer>
          </ThemeProvider>
        </Main>
        <Footer />
      </Page>
    </ThemeProvider>
  )
}

AfcepSuccess.propTypes = {
  email: PropTypes.string.isRequired,
}
