import HttpRequests from '../api/HttpRequests'
import { useQuery } from 'react-query'
import { afreServiceUrl } from '../api/afre.service'
import afreCmsService from '../api/afre.cms.service'

export const useViewQrCode = qrSort => {

  return useQuery([qrSort, 'qrCode'], async () => {
    const response = await HttpRequests.getRequest(`${afreServiceUrl}/recruiting/qrs/view/${qrSort}`)
    return response.data.data
  })
}

export const useGetRecruiterExperience = userId => {

  return useQuery([userId, 'experience'], async () => {
    const response = await HttpRequests.getRequest(`${afreServiceUrl}/user/users/${userId}/recruiterExperience`)
    const { educationHistory, militaryService } = response?.data?.data || {}
    const recruiterExperience = {
      civilianEducation: educationHistory.civilian,
      militaryEducation: educationHistory.military,
      militaryServiceExperience: militaryService.experience,
      militaryServiceRank: militaryService.rank,
    }

    return recruiterExperience
  },
  {
    enabled: !!userId,
  }
  )
}

export const useGetRecruiterFromCms = email => {

  return useQuery([email], async () => {
    const response = await afreCmsService.getRecruitersByEmail(email)

    return response?.data?.data[0]
  },
  {
    enabled: !!email,
  })
}
