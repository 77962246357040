import styled from '@emotion/styled'
import { Button, Icon, Table } from 'semantic-ui-react'
import spacing from '../../theme/spacing'

export const ScrollablePage = styled.div`
  padding: ${spacing.m}px 0;
  height: 100%;
  overflow-y: scroll;
`

export const ScrollableSection = styled.div`
  height: 100%;
  overflow-y: scroll;
`
export const TableItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${spacing.xl};
`

export const ActionButton = styled(Button)`
  background-color: ${props => (props.backgroundcolor ? props.backgroundcolor : props.theme.background.positive)}!important;
  color: ${props => props.theme.text.utility}!important;
`

export const DangerButton = styled(Button)`
  background-color: ${props => props.theme.background.negative}!important;
  color: ${props => props.theme.text.utility}!important;
`

export const InvertedDangerButton = styled(Button)`
  background-color: ${props => props.theme.background.level1}!important;
  color: ${props => props.theme.background.negative}!important;
`

export const CancelButton = styled(Button)`
  background-color: ${props => props.theme.background.level2}!important;
`

export const StickyTableHeader = styled(Table.Header)`
  position: sticky;
  top: 141px;
  z-index: 3;
`

export const StickyModalTableHeader = styled(Table.Header)`
  position: sticky;
  top: 0px;
  z-index: 3;
`

export const LeftButtonGroup = styled(Button)`
  width: 60%;
  background-color: ${props => (props.active ? props.theme.background.action : props.theme.background.level2)}!important;
  color: ${props => (props.active ? props.theme.text.utility : 'default')}!important;
`

export const RightButtonGroup = styled(Button)`
  background-color: ${props => (props.active ? props.theme.background.action : props.theme.background.level2)}!important;
  color: ${props => (props.active ? props.theme.text.utility : 'default')}!important;
`

export const NegativeIcon = styled(Icon)`
  color: ${props => props.theme.background.negative};
`
export const TableHeaderCell = styled(Table.HeaderCell)`
  background-color: ${props => (props.sorted ? props.theme.background.level1 : props.theme.background.level0)}!important;
`
