import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getSquadronFlights, deleteFlight } from '../../redux/sagas/squadron.sagas'
import {
  selectFlights,
  selectCreateFlightErrors,
  selectDeleteFlightErrors,
  selectGetSquadronFlightsErrors } from '../../redux/selectors/squadron.selectors'
import { Container, Header, Grid, Table, Button, Modal, List, Dimmer, Loader } from 'semantic-ui-react'
import { selectIsLoading } from '../../redux/selectors/common.selectors'
import FlightCreateForm from './flight.create.form'
import FlightUpdateForm from './flight.update.form'
import { reduxForm } from 'redux-form'
import { getStandardAmericanDate } from '../../api/date.util'

class FlightsForm extends Component {
  componentDidMount() {
    this.props.getSquadronFlights(this.props.squadron.id)
  }

  renderDeleteModal = flight => {
    const { name, id, flightId } = flight
    return (
      <Modal
        size='mini'
        trigger={ <Button color='red'>Delete</Button> }
        closeIcon>
        <Modal.Header data-testid='confirm-delete-header'>Confirm Delete</Modal.Header>
        <Modal.Content data-testid='confirm-delete-text'>
          Are you sure you want to delete flight { name }?
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='red'
            onClick={ () => this.props.deleteFlight(id, flightId) }>
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  renderUpdateModal = flight => {
    return <FlightUpdateForm form={ `flight-update-${flight.flightId}` } flight={ flight } />
  }

  renderFlightError = () => {
    const { createFlightErrors, deleteFlightErrors, getSquadronFlightsErrors } = this.props
    const errors = []
    if (createFlightErrors) {
      errors.push(createFlightErrors.toString())
    }
    if (deleteFlightErrors) {
      errors.push(deleteFlightErrors.toString())
    }
    if (getSquadronFlightsErrors) {
      errors.push(getSquadronFlightsErrors.toString())
    }
    return (
      <List>
        { errors.map(e => (
          <List.Item style={ { color: 'red' } } key={ e }>{ e }</List.Item>)
        ) }
      </List>
    )
  }

  render() {
    const { flights, isLoading } = this.props
    const squadron = this.props.location.state.squadron
    return (
      <Container>
        <Dimmer.Dimmable dimmed={ isLoading }>
          <Dimmer active={ isLoading } inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          <Header as='h1'>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column textAlign='center'>
                  <FlightCreateForm squadron={ squadron } />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign='center'>
                { this.renderFlightError() }
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Table celled compact striped singleLine>
            <Table.Header data-testid='table-header'>
              <Table.Row data-testid='table-row'>
                <Table.HeaderCell data-testid='flight-name-table-header-cell'>Flight Name</Table.HeaderCell>
                <Table.HeaderCell data-testid='created-at-table-header-cell'>Created At</Table.HeaderCell>
                <Table.HeaderCell data-testid='actions-table-header-cell'>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body data-testid='table-body'>
              { flights && flights.filter(f => f.id === squadron.id).map(flight => {
                const { name, flightId, createdAt } = flight
                const createdAtDate = getStandardAmericanDate(createdAt)
                return (
                  <Table.Row key={ flightId }>
                    <Table.Cell data-testid='flight-name-table-cell'>{ name }</Table.Cell>
                    <Table.Cell data-testid='created-at-table-cell'>{ createdAtDate }</Table.Cell>
                    <Table.Cell data-testid='actions-table-cell'>
                      { this.renderUpdateModal(flight) }
                      { this.renderDeleteModal(flight) }
                    </Table.Cell>
                  </Table.Row>
                )
              }) }
            </Table.Body>
          </Table>
        </Dimmer.Dimmable>
      </Container>
    )
  }
}

FlightsForm.propTypes = {
  flights: PropTypes.array,
  location: PropTypes.object,
  getSquadronFlights: PropTypes.func,
  squadron: PropTypes.object,
  isLoading: PropTypes.bool,
  deleteFlight: PropTypes.func,
  createFlightErrors: PropTypes.object,
  deleteFlightErrors: PropTypes.object,
  getSquadronFlightsErrors: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    flights: selectFlights(state),
    isLoading: selectIsLoading(state),
    createFlightErrors: selectCreateFlightErrors(state),
    deleteFlightErrors: selectDeleteFlightErrors(state),
    getSquadronFlightsErrors: selectGetSquadronFlightsErrors(state),
  }
}

const mapActionsToProps = dispatch => {
  return {
    getSquadronFlights: id => dispatch(getSquadronFlights.request(id)),
    deleteFlight: (id, flightId) => dispatch(deleteFlight.request({ id, flightId })),
  }
}

const Formed = reduxForm({
  form: 'flights',
  enableReinitialize: true,
})(FlightsForm)
const ConnectedFlightsForm = connect(mapStateToProps, mapActionsToProps)(Formed)

export default withRouter(ConnectedFlightsForm)
