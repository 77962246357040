import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import SvgAfBird from '../../icons/AfBird'
import AfscManagement from '../../icons/AfscManagement'
import SvgApprove from '../../icons/Approve'
import BaseManagement from '../../icons/BaseManagement'
import SvgClose from '../../icons/Close'
import SvgCms from '../../icons/Cms'
import DownloadFile from '../../icons/DownloadFile'
import SvgExport from '../../icons/Export'
import SvgExportSquadron from '../../icons/ExportSquadrons'
import SvgGroups from '../../icons/Groups'
import SvgMenu from '../../icons/Menu'
import SvgPeople from '../../icons/People'
import SvgReferrals from '../../icons/Referrals'
import SvgSquadrons from '../../icons/Squadrons'
import UnitManagement from '../../icons/UnitManagement'
import { getAhaSidebarRoutes, getOpaSidebarRoutes, NAV_ROUTES } from '../../navigation/routes'
import { selectFeatureEnabled } from '../../featureToggles/featureToggleDuck'
import { logNavigation } from '../../redux/sagas/amplitudeAnalytics.sagas'
import { selectIsOpaAdmin, selectAuthUser } from '../../redux/selectors/auth.selectors'
import { DarkPalette } from '../../theme'
import spacing from '../../theme/spacing'
import {
  AFBirdTextHeader,
  AFBirdTextSubheader,
  AFBirdTextWrapper,
  AFBirdWrapper,
  CloseButtonWrapper,
  CurrentSidebarItem,
  IconWrapper,
  SidebarHeader,
  SidebarItem,
  SidebarItemHeader,
  SidebarItemSubheader,
  SidebarWrapper,
  StyledSidebar
} from './NavSidebar.styles'

const SIDEBAR_NAV_SUBTITLES = {
  RECRUITER_COUNTS_REPORT_PAGE: 'Recruiter Counts',
  USERS_PAGE: 'View Users',
  ROLE_REQUESTS_PAGE: 'View Role Requests',
  SQUADRONS_PAGE: 'View Squadrons and Flights',
  NOTIFICATIONS_PAGE: 'Send Notifications',
  OPA_USERS_PAGE: 'View OPA Users',
  UPLOAD_WEEK_GROUP_PAGE: 'Upload MilPDS Orders',
  PROCESS_WEEK_GROUP_PAGE: 'Download Order Files',
  VARIABLES_PAGE: 'Manage Template Variables',
  AFSC_MANAGEMENT_PAGE: 'Manage AFSC Properties',
  BASE_MANAGEMENT_PAGE: 'Manage Gain Bases',
  UNIT_MANAGEMENT_PAGE: 'Manage Gain Units',
  SECTIONS_PAGE: 'Manage Template Sections',
  FIND_ORDERS_PAGE: 'Find Orders For New Airmen',
  EMAIL_MANAGEMENT_PAGE: 'Manage MTI Email Addresses',
}

const routeIcons = {
  USERS_PAGE: <SvgPeople size={ spacing.l } color={ DarkPalette.text.default } />,
  ROLE_REQUESTS_PAGE: <SvgApprove size={ spacing.l } color={ DarkPalette.text.default } />,
  RECRUITER_COUNTS_REPORT_PAGE: <SvgExportSquadron size={ spacing.l } color={ DarkPalette.text.default } />,
  SQUADRONS_PAGE: <SvgSquadrons size={ spacing.l } color={ DarkPalette.text.default } />,
  NOTIFICATIONS_PAGE: <SvgGroups size={ spacing.l } color={ DarkPalette.text.default } />,
  OPA_USERS_PAGE: <SvgApprove size={ spacing.l } color={ DarkPalette.text.default } />,
  UPLOAD_WEEK_GROUP_PAGE: <SvgExport size={ spacing.l } color={ DarkPalette.text.default } />,
  PROCESS_WEEK_GROUP_PAGE: <DownloadFile size={ spacing.l } color={ DarkPalette.text.default } />,
  VARIABLES_PAGE: <SvgReferrals size={ spacing.l } color={ DarkPalette.text.default } />,
  AFSC_MANAGEMENT_PAGE: <AfscManagement size={ spacing.l } color={ DarkPalette.text.default } />,
  BASE_MANAGEMENT_PAGE: <BaseManagement size={ spacing.l } color={ DarkPalette.text.default } />,
  UNIT_MANAGEMENT_PAGE: <UnitManagement size={ spacing.l } color={ DarkPalette.text.default } />,
  SECTIONS_PAGE: <SvgCms size={ spacing.l } color={ DarkPalette.text.default } />,
  FIND_ORDERS_PAGE: <SvgAfBird size={ spacing.l } color={ DarkPalette.text.default } />,
  EMAIL_MANAGEMENT_PAGE: <SvgGroups size={ spacing.l } color={ DarkPalette.text.default } />,
}

const mapRouteToTitle = route => {
  const routePieceToRemove = ['PAGE', 'EXPORT']
  const title = route.split('_').filter(routePiece => !routePieceToRemove.includes(routePiece)).join(' ')
  const subtitle = SIDEBAR_NAV_SUBTITLES[route]
  return {
    title,
    subtitle,
  }
}

const AHSidebar = ({ currentRoute, logNavigationAction, testId }) => {
  const isOpaPages = useSelector(selectIsOpaAdmin)
  const currentUser = useSelector(selectAuthUser)
  const shouldShowAfscManagementPage = useSelector(state => selectFeatureEnabled(state, 'opaShowAfscManagementPage'))
  const shouldShowSectionEditingPage = useSelector(state => selectFeatureEnabled(state, 'opaShowSectionEditingPage'))
  const opaSidebarRoutes = getOpaSidebarRoutes(shouldShowSectionEditingPage, shouldShowAfscManagementPage)
  const ahaSidebarRoutes = getAhaSidebarRoutes(currentUser?.roles)
  const SIDEBAR_ROUTES = isOpaPages ? opaSidebarRoutes : ahaSidebarRoutes
  const routes = Object.keys(SIDEBAR_ROUTES)
  const [isVisible, setIsVisible] = useState(false)

  return (
    <SidebarWrapper data-testid={ testId }>
      <Button onClick={ () => setIsVisible(true) } data-testid='sidebar-menu-button'>
        <SvgMenu color={ DarkPalette.background.level0 } />
      </Button>
      <StyledSidebar visible={ isVisible }>
        <SidebarHeader data-testid='sidebar-header'>
          <CloseButtonWrapper>
            <Button
              style={ { background: 'none' } }
              onClick={ () => setIsVisible(false) }
              icon={ <SvgClose size={ spacing.m } color={ DarkPalette.text.default } /> }
              data-testid='sidebar-close-button'
            />
          </CloseButtonWrapper>
          <AFBirdWrapper data-testid='af-bird-logo'>
            <SvgAfBird size={ spacing.xxl } color={ DarkPalette.text.ghost } />
            <AFBirdTextWrapper data-testid='af-bird-aim-high-app-subheader'>
              { isOpaPages ? <AFBirdTextSubheader>OPA</AFBirdTextSubheader> : <AFBirdTextSubheader>AIM HIGH APP</AFBirdTextSubheader> }
              <AFBirdTextHeader data-testid='af-bird-admin-tools-header'>ADMIN TOOLS</AFBirdTextHeader>
            </AFBirdTextWrapper>
          </AFBirdWrapper>
        </SidebarHeader>
        <>
          { routes.map(route => {
            const { title, subtitle } = mapRouteToTitle(route)
            if (ahaSidebarRoutes[route] === currentRoute) {
              return (
                <CurrentSidebarItem key={ route }>
                  <IconWrapper>
                    { routeIcons[route] }
                  </IconWrapper>
                  <div>
                    <SidebarItemHeader data-testid='current-sidebar-item-header'>
                      { title }
                    </SidebarItemHeader>
                    <SidebarItemSubheader data-testid='current-sidebar-item-subheader'>
                      { subtitle }
                    </SidebarItemSubheader>

                  </div>
                </CurrentSidebarItem>
              )
            }
            return (
              <SidebarItem key={ route }>
                <IconWrapper>
                  { routeIcons[route] }
                </IconWrapper>
                <Link to={ NAV_ROUTES[route] } onClick={ () => logNavigationAction(route) }>
                  <SidebarItemHeader data-testid='sidebar-item-header'>
                    { title }
                  </SidebarItemHeader>
                  <SidebarItemSubheader data-testid='sidebar-item-subheader'>
                    { subtitle }
                  </SidebarItemSubheader>
                </Link>
              </SidebarItem>
            )
          }) }
        </>
      </StyledSidebar>
    </SidebarWrapper>
  )
}

AHSidebar.propTypes = {
  currentRoute: PropTypes.string,
  logNavigationAction: PropTypes.func,
  testId: PropTypes.string,
}

const mapStateToProps = () => ({})
const mapActionsToProps = dispatch => ({
  logNavigationAction: route => dispatch(logNavigation.request(route)),
})

export default withRouter(connect(mapStateToProps, mapActionsToProps)(AHSidebar))
