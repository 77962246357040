/* eslint-disable max-len */
import React from 'react'
import { withTheme } from '@emotion/react';

const SvgCheckered = withTheme(({ color, tone, theme, size = 32, ...props }) => {
  color = color || theme.text[tone] || theme.text.contrast;
  return (
    <svg width={ size } height={ size } viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <title>chequered flag</title>
      <g className="nc-icon-wrapper" fill={ color } >
        <path fill={ color } d="M29,2H6V1c0-0.5522461-0.4472656-1-1-1S4,0.4477539,4,1v30c0,0.5522461,0.4472656,1,1,1s1-0.4477539,1-1V20 h23c0.5527344,0,1-0.4477539,1-1V3C30,2.4477539,29.5527344,2,29,2z M28,10h-6V4h6V10z M20,12v6h-6v-6H20z M12,4v6H6V4H12z" />
      </g>
    </svg>
  )
})

SvgCheckered.displayName = SvgCheckered
export default SvgCheckered
