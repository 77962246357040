import { theme as ahaTheme } from '@aimhigh/theme'
import { emailRegex } from '../../forms/validation'
import { MAJOR } from '../afcep/education.major'

export const SVG_LOGO_HEADER_SIZE = 144

export const HELP_ICON_SIZE = 14

export const LINE_OFFICER_ICON_SIZE = 64

export const HEADER_HEIGHT = 160

export const FOOTER_HEIGHT = 20 + (ahaTheme.spacing.l * 2)

export const requiredValidation = {
  required: 'Field is required',
}

export const RADIO_VALUES = {
  YES: 1,
  NO: 0,
}

export const textFieldValidation = {
  ...requiredValidation,
  minLength: {
    value: 2,
    message: `Field must be more than 2 characters`,
  },
}

export const gpaValidation = {
  ...requiredValidation,
  min: {
    value: 0,
    message: 'GPA must be between 0.0 and 4.0',
  },
  max: {
    value: 4,
    message: 'GPA must be between 0.0 and 4.0',
  },
}

export const emailValidation = {
  ...requiredValidation,
  pattern: {
    value: emailRegex,
    message: `Invalid email format`,
  },
}

export const passwordValidation = {
  ...requiredValidation,
  minLength: {
    value: 8,
    message: `Password must be at least 8 characters long`,
  },
  validate: value => {
    if (!/[a-z]/u.test(value) ||
    !/[A-Z]/u.test(value) ||
    !/[0-9]/u.test(value) ||
    !/[!@#$%^]/u.test(value)
    ) {
      return 'Password must contain at least one uppercase letter, lowercase letter, number, and special character'
    }

    return true
  },
}

export const phoneValidation = {
  required: `Field is required`,
  validate: val => {
    if (val.replace(/\D/gu, '').length !== 10) {
      return `Phone Number must contain 10 digits`
    }

    return true
  },
}

export const zipCodeValidation = {
  required: `Field is required`,
  validate: val => {
    if (/\D/u.test(val) || val.length !== 5) {
      return `Zip Code must contain 5 digits`
    }

    return true
  },
}

export const yesNoOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

export const jobCategoryOptions = [
  {
    label: 'Air Battle Management',
    value: 'air_battle_management',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Air Force Transportation',
    value: 'air_force_transportation',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Air Traffic Control',
    value: 'air_traffic_control',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Aircraft Maintenance',
    value: 'aircraft_maintenance',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Airfield Management',
    value: 'airfield_management',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Airplane Pilot',
    value: 'airplane_pilot',
    action: 'Proceed; in the right spot',
    boardType: 'Rated',
  },
  {
    label: 'Avionics',
    value: 'avionics',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Band',
    value: 'band',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Biomedical Equipment',
    value: 'biomedical_equipment',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Chaplain',
    value: 'chaplain',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Chemist',
    value: 'chemist',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Civil Engineer',
    value: 'civil_engineer',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Clinical Psychologist',
    value: 'clinical_psychologist',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Combat Control',
    value: 'combat_control',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Cyberspace Operations',
    value: 'cyberspace_operations',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Dental Student',
    value: 'dental_student',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Dentist',
    value: 'dentist',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Electrical Engineer',
    value: 'electrical_engineer',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Engineer (Other)',
    value: 'engineer_other',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Fire Protection',
    value: 'fire_protection',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Flight Attendant',
    value: 'flight_attendant',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Flight Engineer',
    value: 'flight_engineer',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Flight Surgeon',
    value: 'flight_surgeon',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Fuels',
    value: 'fuels',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Health Services Manager',
    value: 'health_services_manager',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Helicopter Pilot',
    value: 'helicopter_pilot',
    action: 'Proceed; in the right spot',
    boardType: 'Rated',
  },
  {
    label: 'Intelligence',
    value: 'intelligence',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'JAG (Judge Advocate General)',
    value: 'jag_judge_advocate_general',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Linguist',
    value: 'linguist',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Mechanical Engineer',
    value: 'mechanical_engineer',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Medical Healthcare Provider - Other',
    value: 'medical_healthcare_provider_other',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Medical Student',
    value: 'medical_student',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Meteorologist',
    value: 'meteorologist',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Missile & Space Systems',
    value: 'missile_space_systems',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Nuclear Engineer',
    value: 'nuclear_engineer',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Nurse',
    value: 'nurse',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Nurse Practitioner',
    value: 'nurse_practitioner',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Operations Intelligence',
    value: 'operations_intelligence',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Optometrist',
    value: 'optometrist',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Paralegal',
    value: 'paralegal',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Pararescue or Reconnaissance',
    value: 'pararescue_or_reconnaissance',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Personnel or Operations Management',
    value: 'personnel_or_operations_management',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Physician',
    value: 'physician',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Public Affairs',
    value: 'public_affairs',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Resource Administration',
    value: 'resource_administration',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Security Forces',
    value: 'security_forces',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Social Worker',
    value: 'social_worker',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Special Missions',
    value: 'special_missions',
    action: 'Proceed; in the right spot',
    boardType: 'Non-Rated',
  },
  {
    label: 'Surgeon',
    value: 'surgeon',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
  {
    label: 'Weather',
    value: 'weather',
    action: 'Re-direct to a recruiter',
    boardType: 'N/A',
  },
]

export const serviceTypeOptions = [
  { label: 'Full-Time', value: 'full_time' },
  { label: 'Part-Time', value: 'part_time' },
]

export const highestDegreeOptions = [
  { label: 'Current High School Student', value: 'current_high_school_student' },
  { label: 'High School graduate or GED', value: 'high_school_graduate_or_ged' },
  { label: `Associate's`, value: 'associates' },
  { label: `Bachelor's Degree (currently enrolled)`, value: 'bachelors_degree_currently_enrolled' },
  { label: `Bachelor's Degree`, value: 'bachelors_degree' },
  { label: `Master's Degree`, value: 'masters_degree' },
  { label: 'Doctorate', value: 'doctorate' },
  { label: 'Professional Degree (e.g., MD, DVM, JD)', value: 'professional_degree_e_g_md_dvm_jd' },
]

export const majorOptions = [...MAJOR.map(major => ({ ...major, label: major.text })), { label: 'Other', value: 'other' }]

export const mockSteps = [
  { label: 'Demographics', duration: 1, complete: true, href: '#' },
  { label: 'Contact Information', duration: 3, complete: false, href: '#' },
  { label: 'Eligibility', duration: 65, complete: false, href: '#' },
  { label: 'Select Careers', duration: 100, complete: false, href: '#' },
  { label: 'Upload Documents', duration: 17, complete: false, href: '#' },
]

export const mockTimelineItems = [
  'Registration',
  'Application Overview',
  'Initial Application',
  'Register in AFCEP',
  'Sign Air Force Forms',
  'Application Tests',
  'Military Entrance Processing Station',
  'Commander Interview',
  'Finalize Application',
  'Accept',
  'Tasks',
  'EAD Briefing',
]

export const LineOfficerApplicationForms = {
  Demographics: 'Demographics',
  ContactInfo: 'ContactInfo',
  Eligibility: 'Eligibility',
  Education: 'Education',
  Careers: 'Careers',
  AFOQTTest: 'AFOQTTest',
  PilotTest: 'PilotTest',
  PriorService: 'PriorService',
  UploadDocuments: 'Upload Documents',
  Overview: 'Overview',
}

export const lineOfficerFormTitles = {
  [LineOfficerApplicationForms.Demographics]: 'Demographics',
  [LineOfficerApplicationForms.ContactInfo]: 'Contact Information',
  [LineOfficerApplicationForms.Eligibility]: 'Eligibility',
  [LineOfficerApplicationForms.Education]: 'Education',
  [LineOfficerApplicationForms.Careers]: 'Select Careers',
  [LineOfficerApplicationForms.AFOQTTest]: 'AFOQT TEST',
  [LineOfficerApplicationForms.PilotTest]: 'Pilot Test',
  [LineOfficerApplicationForms.PriorService]: 'Prior Service',
  [LineOfficerApplicationForms.UploadDocuments]: 'Upload Documents',
  [LineOfficerApplicationForms.Overview]: 'Application Overview',
}

export const lineOfficerFormDescriptions = {
  [LineOfficerApplicationForms.Demographics]: 'This form gathers key demographic details to assess your eligibility for service. Please fill out all sections accurately.',
  [LineOfficerApplicationForms.ContactInfo]: 'This form collects your contact details to ensure your eligibility for service. Please fill out all sections accurately.',
  [LineOfficerApplicationForms.Eligibility]: 'This form collects information to determine your eligibility for the program. Please fill out all sections accurately.',
  [LineOfficerApplicationForms.Education]: 'This form collects your education details to assess your qualifications. Please fill out all sections accurately.',
  [LineOfficerApplicationForms.Careers]: 'Select and rank up to 20 career options based on your preferences. You will be assigned based on your choices and its availability.',
  [LineOfficerApplicationForms.AFOQTTest]: 'This form collects information to determine your eligibility for the program. Please fill out all sections accurately.',
  [LineOfficerApplicationForms.PilotTest]: 'This form collects information to determine your eligibility for the program. Please fill out all sections accurately.',
  [LineOfficerApplicationForms.PriorService]: 'This form collects details about your prior military service. Please fill out all sections accurately.',
  [LineOfficerApplicationForms.UploadDocuments]: 'Please upload the required documents to complete your application. Ensure all files are clear and accurate.',
  [LineOfficerApplicationForms.Overview]: 'This form collects information to determine your eligibility for the program. Please fill out all sections accurately.',
}
