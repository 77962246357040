import PropTypes from 'prop-types'
import React from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import AHSidebar from '../../../components/Navigation/NavSidebar'
import LogoutButton from '../../../navigation/LogoutButton'
import spacing from '../../../theme/spacing'
import { fontSizes } from '../../../theme/typography'
import OpaNavSidebar from '../Navigation/OpaNavSidebar'
import { OpaPageHeaderLogoutColumn, OpaPageHeaderLogoutContainer, OpaPageHeaderTitleText, StickyHeaderDiv } from './OpaPageHeader.styles'

const renderHeaderComponents = component => component &&
  component.map(headerComponent => (
    <Grid.Column key={ headerComponent.key } width={ headerComponent.props.width }>
      { headerComponent }
    </Grid.Column>
  )
  )

export const OpaPageHeader = ({ pageRoute, pageTitle, headerComponents, hideNav }) => {
  return (
    <>
      <StickyHeaderDiv as='h4'>
        <Grid>
          <Grid.Row columns='equal'>
            <Grid.Column>
              { !hideNav && <AHSidebar testId='headerNavSidebar' currentRoute={ pageRoute } /> }
            </Grid.Column>
            <Grid.Column style={ { fontSize: fontSizes.xxl } } data-testid='opa-page-header'>{ pageTitle }</Grid.Column>
            <Grid.Column textAlign='right'><LogoutButton /></Grid.Column>
          </Grid.Row>
          <Grid.Row style={ { paddingLeft: `${spacing.s}px` } }>
            { renderHeaderComponents(headerComponents) }
          </Grid.Row>
        </Grid>
      </StickyHeaderDiv>
      <Divider />
    </>
  )
}

OpaPageHeader.propTypes = {
  pageRoute: PropTypes.string,
  pageTitle: PropTypes.string,
  headerComponents: PropTypes.array,
  hideNav: PropTypes.bool,
}
export const NewOpaPageHeader = ({ pageRoute, pageTitle, hideNav }) => (
  <>
    <StickyHeaderDiv as='h4'>
      <Grid>
        <Grid.Row columns='equal'>
          <Grid.Column>
            { !hideNav && <OpaNavSidebar testId='headerNavSidebar' currentRoute={ pageRoute } /> }
          </Grid.Column>
          <OpaPageHeaderTitleText data-testid='opa-page-header'>{ pageTitle }</OpaPageHeaderTitleText>
          <OpaPageHeaderLogoutColumn>
            <OpaPageHeaderLogoutContainer>
              <LogoutButton />
            </OpaPageHeaderLogoutContainer>
          </OpaPageHeaderLogoutColumn>
        </Grid.Row>
      </Grid>
    </StickyHeaderDiv>
  </>
)

NewOpaPageHeader.propTypes = {
  pageRoute: PropTypes.string,
  pageTitle: PropTypes.string,
  hideNav: PropTypes.bool,
}
