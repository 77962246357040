import React from 'react'
import theme, { LightPalette } from '../../../theme'

export const ErrorLabel = ({ children, style, ...props }) => {
  const errorLabelStyle = {
    fontSize: `${theme.typography.fontSizes.s}px`,
    color: LightPalette.text.negative,
    ...style,
  }
  return <div style={ errorLabelStyle } { ...props }>{ children }</div>
}
