import * as React from "react";

const Check = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g
      className="check_svg__nc-icon-wrapper"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#111"
      strokeMiterlimit={10}
    >
      <circle cx={12} cy={12} r={11} />
      <path data-color="color-2" strokeWidth={2} d="m6 12 4 4 8-8" />
    </g>
  </svg>
);

export default Check;
