export const analyticsActions = {
  LOG_EVENT: 'LOG_EVENT',
  LOG_EVENT_PARAMS: 'LOG_EVENT_PARAMS',
  LOG_NAVIGATION: 'LOG_NAVIGATION',
  LOG_APP_STORE_NAVIGATION: 'LOG_APP_STORE_NAVIGATION',
  LOG_PAGE_VIEW: 'LOG_PAGE_VIEW',
}

export const CATEGORY = {
  APP_STORE_NAVIGATION: 'APP_STORE_NAVIGATION',
  NAVIGATION: 'NAVIGATION',
  PAGE_VIEW: 'PAGE_VIEW',
  REFERRAL: 'REFERRAL',
  ACTION: 'ACTION',
}

export const addWebPrefix = event => `web_${event}`
