import styled from '@emotion/styled'
import { Sidebar } from 'semantic-ui-react'
import '../../index.css'
import { DarkPalette } from '../../theme'
import spacing from '../../theme/spacing'
import { fontSizes } from '../../theme/typography'

export const StyledSidebar = styled(Sidebar)`
  font-family: 'Open Sans', sans-serif;
  flex: 1;
  background-color: ${DarkPalette.background.level0};
`

export const SidebarItem = styled.div`
  display: flex;
  padding: ${spacing.m}px;
  &:hover{
    background-color: ${DarkPalette.background.level2};
  }
`

export const SidebarItemHeader = styled.div`
  font-family: stratum2-bold;
  font-size: ${fontSizes.m}px;
  color: ${DarkPalette.text.contrast};
`

export const SidebarItemSubheader = styled.div`
  color: ${DarkPalette.text.nonessential};
  font-size: ${fontSizes.s}px;
`

export const CurrentSidebarItem = styled.div`
  box-shadow:inset ${spacing.xxs}px 0px 0px 0px ${DarkPalette.background.action};
  background-color: ${DarkPalette.background.level1};
  display: flex;
  padding: ${spacing.m}px;
`

export const IconWrapper = styled.div`
  padding-right: 5%;
`

export const SidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
`

export const CloseButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

export const AFBirdWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AFBirdTextWrapper = styled.div`
  font-family: stratum2-bold;
  position: absolute;
  text-align: center;
`

export const AFBirdTextSubheader = styled.div`
  color: ${DarkPalette.text.subtle};
  font-size: ${fontSizes.l}px;
`

export const AFBirdTextHeader = styled.div`
  padding-top: ${spacing.s}px;
  font-size: ${fontSizes.xl}px;
  color: ${DarkPalette.text.contrast};
`

export const SidebarWrapper = styled.div`
  padding: ${spacing.s}px;
`

export const OpaSidebarIconWrapper = styled.button`
  align-self: center;
  text-align: center;
  margin: ${spacing.l}px;
  margin-right: ${spacing.xl}px;
  border: none;
  background-color: ${props => props.theme.background.level0};
  cursor: pointer;
`
