import * as React from "react";

const ChevronDown = ({ size= 24, color, ...rest }) => {
    return(
        <svg width={ size } height={ size } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M17 10L12 15L7 10" stroke={ color } stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
};

export default ChevronDown;
