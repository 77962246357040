import React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from '@emotion/css'

const DEFAULT_CLASS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Header = ({ children, className }) => {
  const classNames = cx(DEFAULT_CLASS, className)

  return <header className={ classNames }>{ children }</header>
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Header
