import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Field, FormSection, getFormSyncErrors, hasSubmitFailed, reduxForm } from 'redux-form'
import { Dropdown } from 'semantic-ui-react'
import IconCheckbox from '../../components/IconCheckbox/IconCheckbox'
import { ROLES, ROLE_DISPLAY_NAMES } from '../../config'
import SvgCheck from '../../icons/Check'
import SvgUnfilledCircle from '../../icons/UnfilledCircle'
import { LightPalette } from '../../theme'
import { ORGANIZATION_NAMES } from './role.request.page'
import {
  ContentContainer, OrganizationContentContainer, Roles, SendRequestButton, Subtitle,
  Title
} from './role.request.page.styles'

const RenderIconCheckbox = ({ input, label }) => (
  <IconCheckbox
    { ...input }
    label={ label }
    onChange={ (_e, data) => input.onChange(data.checked) }
    value={ undefined }
    checkedIcon={ <SvgCheck size={ 20 } fill={ LightPalette.background.action } data-testid='checked-checkbox' /> }
    uncheckedIcon={ <SvgUnfilledCircle size={ 20 } data-testid='unchecked-checkbox' /> }
  />
)

RenderIconCheckbox.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
}

const RenderRoleCheckboxes = ({ filteredRoles }) => (
  filteredRoles.map(
    ([key, value]) => {
      const label = ROLE_DISPLAY_NAMES[value] ? ROLE_DISPLAY_NAMES[value] : value
      return (
        <Field
          name={ value }
          type='checkbox'
          component={ RenderIconCheckbox }
          key={ key }
          label={ label }
        />
      )
    }
  )
)

RenderRoleCheckboxes.propTypes = {
  filteredRoles: PropTypes.array,
}

const RenderDropdown = ({ input, fluid, selection, search, options, placeholder }) => (
  <Dropdown
    { ...input }
    fluid={ fluid }
    selection={ selection }
    search={ search }
    options={ options }
    placeholder={ placeholder }
    onBlur={ null }
    onChange={ (_e, data) => input.onChange(data.value) }
  />
)

RenderDropdown.propTypes = {
  input: PropTypes.object,
  fluid: PropTypes.bool,
  selection: PropTypes.any,
  search: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  options: PropTypes.array,
  placeholder: PropTypes.string,
}

const validate = values => {
  const errors = {}

  if (!values.organization) {
    errors.organization = true
  }

  if (!values.roles || values.roles.length === 0) {
    errors.roles = true
  }

  return errors
}

const RoleRequestForm = props => {
  const { roles, squadrons, handleSubmit, formSyncErrors, submitFailed } = props
  const filteredRoles = Object.entries(roles).filter(([key, value]) => {
    return !!key && value !== ROLES.GENERAL && value !== ROLES.MASTER_ADMIN && value !== ROLES.DEP
  })
  const squadronOptions = squadrons.map(squadron => {
    return {
      key: squadron.id,
      text: squadron.name,
      value: squadron.id,
    }
  })
  const sortedSquadronOptions = [...squadronOptions].sort((a, b) => (a.text || '').localeCompare(b.text))

  const organizationOptions = ORGANIZATION_NAMES.map(name => {
    return {
      key: name,
      text: name,
      value: name,
    }
  })

  const options = organizationOptions.concat(sortedSquadronOptions)

  return (
    <form onSubmit={ handleSubmit }>
      <ContentContainer>
        <Title data-testid='select-a-role-title' error={ submitFailed && formSyncErrors.roles }>Select A Role</Title>
        <Subtitle data-testid='select-roles-applied-to-you-subtitle'>Select the roles that apply to you.</Subtitle>
        <Roles data-testid='roles-section'>
          <FormSection name='roles' component={ RenderRoleCheckboxes } filteredRoles={ filteredRoles } />
        </Roles>
      </ContentContainer>
      <OrganizationContentContainer>
        <Title data-testid='select-an-organization-title' error={ submitFailed && formSyncErrors.organization }>Select An Organization</Title>
        <Subtitle data-testid='dropdown-instructions-subtitle'>Using the dropdown menu below, select the organization that you are in charge of.</Subtitle>
        <Field
          component={ RenderDropdown }
          name='organization'
          placeholder='Select organization'
          fluid
          selection
          search
          options={ options }
        />
        <SendRequestButton data-testid='send-request-button' type='submit'>
          Send Request
        </SendRequestButton>
      </OrganizationContentContainer>
    </form>
  )
}

RoleRequestForm.propTypes = {
  roles: PropTypes.object,
  squadrons: PropTypes.array,
  handleSubmit: PropTypes.func,
  formSyncErrors: PropTypes.object,
  submitFailed: PropTypes.bool,
}

const Formed = reduxForm({
  form: 'roleRequest',
  validate,
})(RoleRequestForm)

const ConnectedRoleRequestForm = connect(state => ({
  formSyncErrors: getFormSyncErrors('roleRequest')(state),
  submitFailed: hasSubmitFailed('roleRequest')(state),
}))(Formed)

export default ConnectedRoleRequestForm
