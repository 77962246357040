import React from 'react'
import { css } from '@emotion/css'
import { useTheme } from '@emotion/react'
import Header from './Header'

const PageHeader = ({ children }) => {
  const theme = useTheme()

  const defaultClass = css`
    background-color: ${theme.background.level0};
    padding: ${theme.spacing.l}px 5%;
    color: ${theme.text.default};
    font-size: ${theme.typography.fontSizes.m}px;
  `

  return (
    <Header className={ defaultClass }>
      { children }
    </Header>
  )
}

export default PageHeader
