import { MAJOR } from './education.major'

const isoDateStringToEpoch = dateString => Date.parse(dateString)

const getOptionalFormValues = formValues => {
  const endDate = isoDateStringToEpoch(formValues?.endDate)
  const pcsmTestDate = isoDateStringToEpoch(formValues?.pcsmTestDate)
  const priorServiceStartOn = isoDateStringToEpoch(formValues?.priorServiceStartOn)
  const priorServiceEndedOn = isoDateStringToEpoch(formValues?.priorServiceEndedOn)
  const priorServiceDateOfRank = isoDateStringToEpoch(formValues?.priorServiceDateOfRank)
  const afoqtTestDate = isoDateStringToEpoch(formValues?.afoqtTestDate)
  const pcsmScore = parseInt(formValues?.pcsmScore, 10)
  const pcsmFlightHours = parseInt(formValues?.pcsmFlightHours, 10)
  const afoqtPilot = parseInt(formValues?.afoqtPilot, 10)
  const afoqtCso = parseInt(formValues?.afoqtCso, 10)
  const afoqtAbm = parseInt(formValues?.afoqtAbm, 10)
  const afoqtAa = parseInt(formValues?.afoqtAa, 10)
  const afoqtVerbal = parseInt(formValues?.afoqtVerbal, 10)
  const afoqtQuantative = parseInt(formValues?.afoqtQuantative, 10)
  return {
    endDate,
    pcsmTestDate,
    priorServiceStartOn,
    priorServiceEndedOn,
    priorServiceDateOfRank,
    afoqtTestDate,
    pcsmScore,
    pcsmFlightHours,
    afoqtPilot,
    afoqtCso,
    afoqtAbm,
    afoqtAa,
    afoqtVerbal,
    afoqtQuantative,
  }
}

const addOptionalFormValues = (body, formValues) => {
  let updatedBody = body
  const {
    endDate,
    pcsmTestDate,
    priorServiceStartOn,
    priorServiceEndedOn,
    priorServiceDateOfRank,
    afoqtTestDate,
    pcsmScore,
    pcsmFlightHours,
    afoqtPilot,
    afoqtCso,
    afoqtAbm,
    afoqtAa,
    afoqtVerbal,
    afoqtQuantative,
  } = getOptionalFormValues(formValues)

  if (afoqtTestDate) {
    updatedBody = { ...updatedBody, afoqtTestDate, afoqtPilot, afoqtCso, afoqtAbm, afoqtAa, afoqtVerbal, afoqtQuantative }
  }

  if (priorServiceStartOn) {
    updatedBody = { ...updatedBody, priorServiceStartOn }
  }

  if (priorServiceEndedOn) {
    updatedBody = { ...updatedBody, priorServiceEndedOn }
  }

  if (priorServiceDateOfRank) {
    updatedBody = { ...updatedBody, priorServiceDateOfRank }
  }

  if (pcsmTestDate) {
    updatedBody = { ...updatedBody, pcsmTestDate }
  }

  if (pcsmScore || pcsmScore === 0) {
    updatedBody = { ...updatedBody, pcsmScore }
  }

  if (pcsmFlightHours || pcsmFlightHours === 0) {
    updatedBody = { ...updatedBody, pcsmFlightHours }
  }

  if (formValues?.present) {
    delete updatedBody.endDate
  } else {
    updatedBody = { ...updatedBody, endDate }
  }

  return updatedBody
}

export const buildRequestBody = (values, eventId) => {
  const dateOfBirth = isoDateStringToEpoch(values?.dateOfBirth)
  const startDate = isoDateStringToEpoch(values?.startDate)

  const currentServiceStartOn = isoDateStringToEpoch(values?.currentServiceStartOn)

  const currentServiceDateOfRank = isoDateStringToEpoch(values?.currentServiceDateOfRank)
  const ssn = values?.ssn.replaceAll('-', '')
  const majorCode = values?.major
  const major = MAJOR.find(m => m.value === majorCode).text
  const numberOfYearsCompleted = parseInt(values?.numberOfYearsCompleted, 10)
  const degreeGpa = parseFloat(values?.degreeGpa)

  const body = {
    ...values,
    dateOfBirth,
    startDate,
    currentServiceStartOn,
    currentServiceDateOfRank,
    eventId,
    ssn,
    majorCode,
    major,
    numberOfYearsCompleted,
    degreeGpa,
  }

  return addOptionalFormValues(body, values)
}
