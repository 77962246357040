/* eslint-disable complexity */
import immutable from 'seamless-immutable'

export const commonActions = {
  ADD_LOAD: 'COMMON_ADD_LOAD',
  REMOVE_LOAD: 'COMMON_REMOVE_LOAD',
  ADD_ERROR: 'COMMON_ADD_ERROR',
  REMOVE_ERROR: 'COMMON_REMOVE_ERROR',
  ADD_NOTIFICATION: 'COMMON_ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'COMMON_REMOVE_NOTIFICATION',
}

const INITIAL_STATE = immutable({
  globalLoads: [],
  notifications: [],
  loads: {},
  trackedLoads: {},
  errors: {},
})

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case commonActions.ADD_LOAD:
      if (action.global && !state.globalLoads.includes(action.id)) {
        state = state.set('globalLoads', state.globalLoads.concat([action.id]))
      }
      if (action.meta && action.meta.loadId && (!state.trackedLoads[action.id] || !state.trackedLoads[action.id].includes(action.meta.loadId))) {
        let group = state.trackedLoads[action.id] || []
        group = group.concat([action.meta.loadId])
        state = state.setIn(['trackedLoads', action.id], group)
      }
      return state.setIn(['loads', action.id], true)
    case commonActions.REMOVE_LOAD:
      state = state.set('globalLoads', state.globalLoads.filter(load => load !== action.id))
      if (action.meta && action.meta.loadId) {
        state = state.setIn(['trackedLoads', action.id], state.trackedLoads[action.id].filter(load => load !== action.meta.loadId))
      }
      return state.set('loads', immutable.without(state.loads, action.id))
    case commonActions.ADD_ERROR:
      return state.setIn(['errors', action.id], immutable(action.error))
    case commonActions.REMOVE_ERROR:
      return state.set('errors', immutable.without(state.errors, action.id))
    case commonActions.ADD_NOTIFICATION:
      if (state.notifications.find(({ id }) => id === action.notification.id)) {
        return state
      }
      return state.set('notifications', state.notifications.concat([immutable(action.notification)]))
    case commonActions.REMOVE_NOTIFICATION:
      return state
        .set('notifications', state.notifications.filter(({ id, type }) => id !== action.notification.id || type !== action.notification.type))
    default:
      return state
  }
}
