import styled from '@emotion/styled'
import Theme from '../../theme'

export const DownloadWrapper = styled.div`
  align-items: center;
  height: 100%;
  overflow: hidden;
  position: relative;
 `

export const HoldingApp = styled.img`
 object-fit: cover;
 height: auto;
 width: 100%;
 z-index: 1;
 position: relative;
 @media screen and (max-width: 780px) {
  display: none;
}

`
export const Download = styled.div`
  background-color: rgba(27,41,57, .5);
  border-radius: 15px 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30px;
  right: 30px;
  @media screen and (max-width: 780px) {
    display: none;

  }

`
export const Download2 = styled.div`
  background-color: rgba(27,41,57, .5);
  border-radius: 15px 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @media screen and (min-width: 780px) {
    display: none;

  }

`
export const DownloadIconWrapper = styled.div`
  padding-bottom: 10px;
  justify-content: center;
  display:flex;
  @media screen and (max-width: 780px) {
    padding-left: 5px;
    padding-right: 5px;
  }

`
export const DownloadIcons = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 780px) {
    flex-direction: column;
  }

`
export const SubTitle = styled.div`
    font-family: stratum2-regular;
    text-align: center;
    color: ${props => props.theme.text.contrast};
    font-size: ${Theme.typography.fontSizes.xxl};
    font-weight: bold;
    line-height: 46px;
    width: 100%;
    padding-bottom: 5px;
    text-transform: uppercase;
    z-index: 1;
    position: relative;
  `
export const Title = styled.div`
    font-family: stratum2-regular;
    text-align: center;
    color: ${props => props.theme.text.contrast};
    font-size: 75px;
    font-weight: bold;
    line-height: 28px;
    width: 100%;
    padding-bottom: 20px;
    text-transform: uppercase;
    z-index: 1;
    position: relative;
  `

