/* eslint-disable max-lines */
import { ThemeProvider } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dimmer, Dropdown, Input, Loader, Modal, Table } from 'semantic-ui-react'
import HttpService from '../../api/HttpService'
import config from '../../config'
import { NAV_ROUTES } from '../../navigation/routes'
import { selectFeatureEnabled } from '../../featureToggles/featureToggleDuck'
import { selectAuthUserToken } from '../../redux/selectors/auth.selectors'
import { LightPalette } from '../../theme'
import spacing from '../../theme/spacing'
import { OpaPageHeader } from '../components/PageTitle/OpaPageHeader'
import { ApproveOrdersModal } from '../components/WeekGroupProcessing/ApproveOrdersModal'
import ArmsConfirmationModal from '../components/WeekGroupProcessing/ArmsConfirmationModal'
import { EditOrdersModal } from '../components/WeekGroupProcessing/EditOrdersModal'
import { EditWeekGroupRowModal } from '../components/WeekGroupProcessing/EditWeekGroupRowModal'
import OpaAfscErrorMessage from '../components/WeekGroupProcessing/OpaAfscErrorMessage'
import OpaOrderTableRow from '../components/WeekGroupProcessing/OpaOrderTableRow'
import OpaProcessWeekGroupFooter from '../components/WeekGroupProcessing/OpaProcessWeekGroupFooter'
import ProcessWeekGroupModal from '../components/WeekGroupProcessing/ProcessWeekGroupModal'
import { useGetWeekGroups } from '../reactQueries/opaQueries'
import {
  ARMS_STATUS,
  deleteWeekGroupRow,
  getAfscProperties,
  getVariables,
  getWeekGroupRows,
  selectAfscPropertyList,
  selectDeletingWeekGroup,
  selectDeletingWeekGroupRow,
  selectGettingAfscProperties,
  selectGettingTemplateInfo,
  selectGettingWeekGroupRows,
  selectGettingWeekGroups,
  selectUpdatingWeekGroupRow,
  selectWeekGroupRows,
  selectWeekGroupRowsLoading
} from '../redux/ducks/opa.duck'
import { canEditWeekGroupOrder } from './armsStatusProperties'
import { evaluateFilterType, filterTypes, generalFilterOptions, getTextFilterValue } from './determineRowBasedOnFilterType'
import { ActionButton, CancelButton, DangerButton, InvertedDangerButton, StickyTableHeader } from './Opa.styles'
import { FooterPage } from './ProcessWeekGroupPage.styles'
import { weekGroupRowProperties, weekGroupRowPropertiesWithTextValues } from './weekGroupRowProperties'

const afreServiceUrl = config.apiUrl

// eslint-disable-next-line max-statements, complexity
export const ProcessWeekGroupPage = () => {
  const dispatch = useDispatch()
  const showArmsFeatures = useSelector(state => selectFeatureEnabled(state, 'armsFeatures'))
  const isGettingAfscProperties = useSelector(selectGettingAfscProperties)
  const isGettingWeekGroupRows = useSelector(selectGettingWeekGroupRows)
  const isGettingWeekGroups = useSelector(selectGettingWeekGroups)
  const isDeletingWeekGroup = useSelector(selectDeletingWeekGroup)
  const isProcessingWeekGroupRows = useSelector(selectWeekGroupRowsLoading)
  const isDeletingWeekGroupRow = useSelector(selectDeletingWeekGroupRow)
  const isUpdatingWeekGroupRow = useSelector(selectUpdatingWeekGroupRow)
  const isGettingTemplateInfo = useSelector(selectGettingTemplateInfo)
  const weekGroupRows = useSelector(selectWeekGroupRows)
  const currentAfscList = useSelector(selectAfscPropertyList)

  const [weekGroupDropdownOptions, setWeekGroupDropdownOptions] = useState([])
  const [selectedWeekGroupId, setSelectedWeekGroupId] = useState(undefined)
  const [selectedWeekGroup, setSelectedWeekGroup] = useState(undefined)
  const [isWeekGroupReady, setIsWeekGroupReady] = useState(false)
  const [weekGroupIndex, setWeekGroupIndex] = useState(0)
  const [weekGroupRowList, setWeekGroupRowList] = useState([])
  const [sortedWeekGroupRowList, setSortedWeekGroupRowList] = useState([])
  const [weekGroupDataToDelete, setWeekGroupDataToDelete] = useState(undefined)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [processConfirmationModalOpen, setProcessConfirmationModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editOrdersModalOpen, setEditOrdersModalOpen] = useState(false)
  const [selectedWeekGroupRow, setSelectedWeekGroupRow] = useState(undefined)
  const [weekGroupRowSearchValue, setWeekGroupRowSearchValue] = useState('')
  const [weekGroupRowSearchType, setWeekGroupRowSearchType] = useState('maskedSocial')
  const [filterType, setFilterType] = useState(filterTypes.ALL)
  const [armsStatusTypes, setArmsStatusTypes] = useState([])
  const [deleteModalText, setDeleteModalText] = useState('')
  const [isArmsErrorModalOpen, setIsArmsErrorModalOpen] = useState(false)
  const [armsErrorMessage, setArmsErrorMessage] = useState('')
  const [armsConfirmationModalOpen, setArmsConfirmationModalOpen] = useState(false)
  const [hasAfscErrors, setHasAfscErrors] = useState(false)
  const [approveOrdersModalOpen, setApproveOrdersModalOpen] = useState(false)
  const [sendWeekGroupCodeEmail, setSendWeekGroupCodeEmail] = useState(false)
  const [hasRowsWithErrors, setHasRowsWithErrors] = useState(false)

  const token = useSelector(selectAuthUserToken)
  const orderConstantsQueryKey = ['orderConstants']
  const orderConstants = useQuery(orderConstantsQueryKey, async () => {
    const response = await HttpService.get(`${afreServiceUrl}/opa/rowConstants`, {}, token).next().value
    return response.data.data
  })

  const { data: weekGroups, mutate: getWeekGroups } = useGetWeekGroups()

  const deleteWeekGroupMutation = useMutation(async weekGroupId => {
    return await HttpService.delete(`${afreServiceUrl}/opa/weekGroups/${weekGroupId}`, {}, token).next().value
  }, {
    onSuccess: () => {
      getWeekGroups()
      setSelectedWeekGroupId(null)
      setSelectedWeekGroup(null)
      setWeekGroupRowList([])
    },
  })

  const armsStatusQueryKey = ['armsReadyStatus', 'weekGroupId']
  const markReadyForArmsStatus = useMutation(armsStatusQueryKey, async weekGroupId => {
    await HttpService.put(`${afreServiceUrl}/opa/readyForArms/${weekGroupId}`, null, {}, token).next().value
  }, {
    onSuccess: () => {
      getWeekGroups()
    },
    onError: err => {
      setIsArmsErrorModalOpen(true)
      setArmsErrorMessage(err.response.data.error)
    },
  })

  const showSpinner =
    isGettingAfscProperties ||
    isGettingTemplateInfo ||
    orderConstants.isLoading ||
    isGettingWeekGroupRows ||
    isGettingWeekGroups ||
    isDeletingWeekGroup ||
    isProcessingWeekGroupRows ||
    isDeletingWeekGroupRow ||
    isUpdatingWeekGroupRow ||
    markReadyForArmsStatus.isLoading ||
    deleteWeekGroupMutation.isLoading

  useEffect(() => {
    dispatch(getVariables.request())
    dispatch(getAfscProperties.request())
    getWeekGroups()
  }, [dispatch, getWeekGroups])

  useEffect(() => {
    if (!!weekGroups) {
      const weekGroupsCopy = [...weekGroups]
      weekGroupsCopy.sort((a, b) => ((a.createdAt < b.createdAt) ? 1 : -1))
      setWeekGroupDropdownOptions(
        weekGroupsCopy.map(weekGroup => {
          const displayValue = `${weekGroup.weekGroup} (${weekGroup.weekGroupYear})`
          return { key: weekGroup.sort, text: displayValue, value: weekGroup.sort }
        })
      )
    }
  }, [weekGroups])

  useEffect(() => {
    if (weekGroupDropdownOptions.length > 0) {
      if (weekGroupDropdownOptions[weekGroupIndex]) {
        setSelectedWeekGroupId(weekGroupDropdownOptions[weekGroupIndex].value)
      } else {
        setWeekGroupIndex(0)
      }
    }
  }, [weekGroupDropdownOptions, weekGroupIndex])

  useEffect(() => {
    if (selectedWeekGroupId) {
      dispatch(getWeekGroupRows.request({ weekGroup: selectedWeekGroupId }))
      if (weekGroups && weekGroups.length > 0) {
        const selectedWeekGroupObject = weekGroups.find(weekGroup => weekGroup.sort === selectedWeekGroupId)
        if (selectedWeekGroupObject) {
          setIsWeekGroupReady(selectedWeekGroupObject.weekGroupReady && showArmsFeatures)
        }
      }
    }
  }, [dispatch, selectedWeekGroupId, weekGroups, showArmsFeatures])

  useEffect(() => {
    if (selectedWeekGroupId && weekGroups?.length > 0) {
      const selectedWeekGroupObject = weekGroups.find(weekGroup => weekGroup.sort === selectedWeekGroupId)
      setSelectedWeekGroup(selectedWeekGroupObject)
    }

    return () => {
      setSelectedWeekGroup(null)
    }
  }, [selectedWeekGroupId, weekGroups])

  useEffect(() => {
    if (weekGroupRows && weekGroupRows.length > 0) {
      const sortedWeekGroupRowsCopy = weekGroupRows ? [...weekGroupRows] : []
      sortedWeekGroupRowsCopy.sort((a, b) => a.specialOrderNumber - b.specialOrderNumber)
      setHasAfscErrors(false)
      let trackedAfscError = false
      const finalWeekGroupRows = sortedWeekGroupRowsCopy.map(row => {
        if (row.errors && !!row.errors.primaryAfsc) {
          trackedAfscError = true
        }

        const { primaryAfsc = '' } = row
        const afscCharters = primaryAfsc.split('')
        if (primaryAfsc.length > 3) {
          afscCharters[3] = 'X'
        }
        const genericAfsc = afscCharters.join('').toUpperCase()
        const afsc = currentAfscList ? currentAfscList.find(a => a.sort === genericAfsc) : null

        const afscProps = afsc || {}
        return {
          ...row,
          genericAfsc,
          afscProps,
        }
      })
      setHasAfscErrors(trackedAfscError)
      setWeekGroupRowList(finalWeekGroupRows)
      setSortedWeekGroupRowList(finalWeekGroupRows)
    }
  }, [weekGroupRows, currentAfscList])

  const handleSearch = (_e, data) => {
    setWeekGroupRowSearchValue(data.value.toUpperCase())
  }

  useEffect(() => {
    let weekGroupHasErrors = false

    const filteredValues = sortedWeekGroupRowList.filter(weekGroupRow => {
      let includeRow = true
      const filterValue = getTextFilterValue(weekGroupRowSearchValue, weekGroupRowSearchType, weekGroupRow)
      if (filterValue) {
        includeRow = filterValue.toUpperCase().includes(weekGroupRowSearchValue)
      }

      if (includeRow) {
        includeRow = evaluateFilterType(includeRow, weekGroupRow, filterType)
      }

      const weekGroupRowArmsStatus = weekGroupRow.armsStatus || ARMS_STATUS.NOT_READY
      if (includeRow && armsStatusTypes.length !== 0) {
        includeRow = armsStatusTypes.includes(weekGroupRowArmsStatus)
      }

      if (includeRow) {
        const weekGroupRowErrors = weekGroupRow.errors || {}
        weekGroupHasErrors = !!Object.values(weekGroupRowErrors).length || weekGroupHasErrors
      }
      return includeRow
    })
    setHasRowsWithErrors(weekGroupHasErrors)
    setWeekGroupRowList(filteredValues)
  }, [sortedWeekGroupRowList, weekGroupRowSearchType, weekGroupRowSearchValue, filterType, armsStatusTypes])

  const handleSearchTypeChange = (_e, data) => {
    const newSearchType = weekGroupRowProperties.find(row => row.value === data.value).value
    setWeekGroupRowSearchType(newSearchType)
  }

  const handleFilterTypeChange = (_e, data) => {
    setFilterType(data.value)
  }

  const handleArmsStatusTypeChange = (_e, data) => {
    setArmsStatusTypes(data.value)
  }

  const dismissArmsConfirmationModal = () => {
    setArmsConfirmationModalOpen(false)
  }

  const headerControls = [
    <div key='week-group-controls' width={ 3 }>
      <Dropdown
        data-testid='week-group-dropdown'
        search
        selection
        options={ weekGroupDropdownOptions }
        placeholder='Select a Week Group'
        value={ selectedWeekGroupId }
        onChange={ (_e, data) => {
          setWeekGroupRowList([])
          setWeekGroupIndex(weekGroupDropdownOptions.findIndex(wg => wg.key === data.value))
        } }
      />
    </div>,
    <div key='filter-controls' width={ 13 } style={ { textAlign: 'right' } }>
      { showArmsFeatures &&
        <Dropdown
          data-testid='status-dropdown'
          search
          selection
          multiple
          value={ armsStatusTypes }
          options={
            Object.values(ARMS_STATUS).map(status => ({
              key: status,
              value: status,
              text: status,
            }))
          }
          onChange={ handleArmsStatusTypeChange }
          placeholder='ARMS-LC Status'
        />
      }
      <Dropdown
        data-testid='row-filters-dropdown'
        style={ { marginLeft: spacing.m } }
        search
        selection
        value={ filterType }
        options={ generalFilterOptions }
        onChange={ handleFilterTypeChange }
      />
      <Input
        data-testid='row-search-filter'
        style={ { marginLeft: spacing.m } }
        placeholder='Filter by property...'
        onChange={ handleSearch }
        label={
          <Dropdown
            defaultValue='maskedSocial'
            options={ weekGroupRowPropertiesWithTextValues }
            onChange={ handleSearchTypeChange }
          />
        }
      />
      <ActionButton
        data-testid='add-manual-order-button'
        disabled={ isWeekGroupReady }
        style={ { marginLeft: spacing.m } }
        onClick={ () => {
          setSelectedWeekGroupRow({ id: selectedWeekGroupId })
          setEditModalOpen(true)
        } }>
        Add Manual Order
      </ActionButton>
      <InvertedDangerButton
        data-testid='delete-week-group-button'
        disabled={ isWeekGroupReady }
        style={ { marginLeft: spacing.m } }
        content='Delete Week Group'
        icon='trash alternate outline'
        onClick={ () => {
          setWeekGroupDataToDelete(selectedWeekGroup)
          setDeleteModalText(`Are you sure you want to delete ${selectedWeekGroup.weekGroup}?`)
          setDeleteModalOpen(true)
        } }
      />
    </div>,
  ]

  return (
    <>
      <ThemeProvider theme={ LightPalette }>
        {
          isArmsErrorModalOpen && showArmsFeatures &&
            <Modal open={ isArmsErrorModalOpen }>
              <Modal.Header data-testid='error-header'>Error Changing Orders&#39; Status</Modal.Header>
              <Modal.Content data-testid='error-content'>{ armsErrorMessage }</Modal.Content>
              <Modal.Actions>
                <Button
                  onClick={ () => {
                    setIsArmsErrorModalOpen(false)
                    setArmsErrorMessage('')
                  } }>
                  Ok
                </Button>
              </Modal.Actions>
            </Modal>
        }
        {
          selectedWeekGroupRow && editModalOpen &&
            <EditWeekGroupRowModal
              isOpen={ editModalOpen }
              setOpen={ setEditModalOpen }
              weekGroupRow={ selectedWeekGroupRow }
              weekGroupRowList={ sortedWeekGroupRowList }
              weekGroupRowProperties={
                weekGroupRowPropertiesWithTextValues.filter(property => (showArmsFeatures ? true : property.key !== 'armsStatus'))
              }
              orderConstants={ orderConstants }
            />
        }
        {
          selectedWeekGroupRow && editOrdersModalOpen &&
            <EditOrdersModal
              readOnly={ !canEditWeekGroupOrder(selectedWeekGroupRow, showArmsFeatures) }
              isOpen={ editOrdersModalOpen }
              setOpen={ setEditOrdersModalOpen }
              weekGroupId={ selectedWeekGroupRow.id }
              dataSortValue={ selectedWeekGroupRow.sort }
            />
        }
        {
          approveOrdersModalOpen &&
            <ApproveOrdersModal
              isOpen={ approveOrdersModalOpen }
              setOpen={ setApproveOrdersModalOpen }
              weekGroupId={ selectedWeekGroupId }
              flightPhysicalOrders={
                sortedWeekGroupRowList.filter(weekGroupRow => {
                  return weekGroupRow.afscProps ? weekGroupRow.afscProps.isFlightPhysical : false
                })
              }
            />
        }
        {
          weekGroupDataToDelete &&
            <Modal
              open={ deleteModalOpen }>
              <Modal.Header data-testid='confirm-delete-header'>Confirm Delete</Modal.Header>
              <Modal.Content data-testid='confirm-delete-text'>
                <p>{ deleteModalText }</p>
              </Modal.Content>
              <Modal.Actions>
                <CancelButton onClick={ () => {
                  setDeleteModalOpen(false)
                  setWeekGroupDataToDelete(null)
                } }>
                  Cancel
                </CancelButton>
                <DangerButton
                  content='Delete'
                  icon='trash alternate outline'
                  data-testid='danger-button'
                  onClick={ () => {
                    if (weekGroupDataToDelete.id === 'weekGroup') {
                      deleteWeekGroupMutation.mutate(weekGroupDataToDelete.sort)
                    } else {
                      dispatch(deleteWeekGroupRow.request({ weekGroupDataToDelete, currentWeekGroupRows: sortedWeekGroupRowList }))
                    }
                    setDeleteModalOpen(false)
                    setWeekGroupDataToDelete(null)
                  } }
                />
              </Modal.Actions>
            </Modal>
        }
        {
          processConfirmationModalOpen &&
            <ProcessWeekGroupModal
              dispatch={ action => dispatch(action) }
              getters={ {
                hasRowsWithErrors,
                processConfirmationModalOpen,
                sendWeekGroupCodeEmail,
                weekGroupRowList,
                selectedWeekGroup: selectedWeekGroupId,
              } }
              setterFunctions={ {
                setSendWeekGroupCodeEmail,
                setProcessConfirmationModalOpen,
              } }
            />
        }
        <ArmsConfirmationModal
          isOpen={ armsConfirmationModalOpen }
          onCancel={ () => dismissArmsConfirmationModal() }
          onReady={ () => {
            markReadyForArmsStatus.mutate(selectedWeekGroupId)
            dismissArmsConfirmationModal()
          } }
        />
        <FooterPage>
          <Dimmer.Dimmable dimmed={ showSpinner }>
            <Dimmer active={ showSpinner } page>
              <Loader>Loading</Loader>
            </Dimmer>
            <OpaPageHeader
              pageRoute={ NAV_ROUTES.PROCESS_WEEK_GROUP_PAGE }
              pageTitle='Process Week Group'
              headerComponents={ headerControls }
            />
            { hasAfscErrors &&
              <OpaAfscErrorMessage
                afscErrorsSetter={ setHasAfscErrors }
              />
            }
            <div style={ { marginTop: `${spacing.m}px`, marginLeft: `${spacing.xs}px` } }>
              { weekGroupRowList && weekGroupRowList.length > 0 ? (
                <Table data-testid='data-table' compact striped celled>
                  <StickyTableHeader data-testid='table-header'>
                    <Table.Row data-testid='table-row'>
                      {
                        weekGroupRowProperties.map(weekGroupRowProperty => {
                          const dataKey = weekGroupRowProperty.key
                          if (dataKey === 'editOrders') {
                            return (<Table.HeaderCell data-testid='table-header-cell' key={ dataKey }>Edit/View Orders</Table.HeaderCell>)
                          } else if (dataKey === 'editDeleteWeekGroupRow') {
                            return (<Table.HeaderCell data-testid='table-header-cell' key={ dataKey }>Edit Row</Table.HeaderCell>)
                          } else if (dataKey === 'armsStatus') {
                            return showArmsFeatures ? <Table.HeaderCell data-testid='table-header-cell' key={ dataKey }>{ weekGroupRowProperty.text }</Table.HeaderCell> : null
                          } else {
                            return (<Table.HeaderCell data-testid='table-header-cell' key={ dataKey }>{ weekGroupRowProperty.text }</Table.HeaderCell>)
                          }
                        })
                      }
                    </Table.Row>
                  </StickyTableHeader>
                  <Table.Body data-testid='table-body'>
                    {
                      weekGroupRowList.map(weekGroupRow => {
                        const setterFunctions = {
                          setSelectedWeekGroupRow,
                          setEditOrdersModalOpen,
                          setEditModalOpen,
                          setWeekGroupDataToDelete,
                          setDeleteModalText,
                          setDeleteModalOpen,
                        }
                        return (
                          <OpaOrderTableRow
                            key={ weekGroupRow.sort }
                            weekGroupRow={ weekGroupRow }
                            weekGroupRowProperties={ weekGroupRowProperties }
                            setterFunctions={ setterFunctions }
                            showArmsFeatures={ showArmsFeatures }
                            weekGroupRowErrors={ weekGroupRow.errors || {} }
                          />
                        )
                      })
                    }
                  </Table.Body>
                </Table>
              ) : (
                <p data-testid='no-results-found'>No Results Found { weekGroupRowSearchValue ? `For ${weekGroupRowSearchValue}` : '' }</p>
              ) }
            </div>
          </Dimmer.Dimmable>
          <OpaProcessWeekGroupFooter
            getters={ {
              showArmsFeatures,
              isWeekGroupReady,
              weekGroupRowList,
            } }
            setterFunctions={ {
              setApproveOrdersModalOpen,
              setProcessConfirmationModalOpen,
              setArmsConfirmationModalOpen,
            } }
          />
        </FooterPage>
      </ThemeProvider>
    </>
  )
}
