import { call, put, takeLatest } from 'redux-saga/effects'
import AsyncSaga from '../../redux/sagas/util/AsyncSaga'
import afreCmsService from '../../api/afre.cms.service'
import immutable from 'seamless-immutable'
import { createErrorSelector } from '../../redux/selectors/common.selectors'

const INITIAL_STATE = immutable({
  appShare: {},
})

const GET_APP_SHARE = 'GET_APP_SHARE'

export const getAppShareSaga = new AsyncSaga(GET_APP_SHARE, function* getAppShareGenerator() {
  const appShareResponse = yield call(afreCmsService.getAimHighApp)
  const appShare = appShareResponse?.data?.data[0]
  yield put(getAppShareSaga.success(appShare))
}).catch(function* getAppShareCatch(error) {
  yield put(getAppShareSaga.error(error, false))
})

export const selectAppShare = state => state.appShare.appShare
export const selectErrorLoadingAppShare = createErrorSelector(GET_APP_SHARE)

export default function appShareReducers(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case getAppShareSaga.SUCCESS:
      return state.set('appShare', action.payload)
    default:
      return state
  }
}

export const appShareSagas = [
  takeLatest(getAppShareSaga.REQUEST, getAppShareSaga.saga),
]
