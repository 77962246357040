import styled from '@emotion/styled'
import { Button } from '@aimhigh/web-components/web'

const TOP_SPACING = '90px'

export const Container = styled.div`
    min-height: 100vh;
    color: ${({ theme }) => theme.colors.text.white};
    background: ${({ theme }) => theme.colors.brand.brandDark};
    position: relative;
`

export const BackgroundImage = styled.div`
    background-image: url(${require('../../../assets/Landing.png')});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${({ theme }) => theme.colors.brand.brandDark};
        opacity: 0.2;
        z-index: 1;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 400px;
        background: linear-gradient(
                to top,
                ${({ theme }) => theme.colors.brand.brandDark} 0%,
                rgba(0, 0, 0, 0.8) 40%,
                transparent 100%
        );
        pointer-events: none;
        z-index: 1;
    }
`

export const Header = styled.header`
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 ${({ theme }) => theme.spacing.l}px;
    text-align: center;
    position: relative;
    z-index: 2;
`

export const Spacer = styled.div`
    height: ${TOP_SPACING};
`

export const Main = styled.main`
    max-width: 800px;
    margin: 10vh auto 0;
    padding: ${({ theme }) => theme.spacing.xl}px ${({ theme }) => theme.spacing.l}px;
    flex: 1;
    width: 100%;
    position: relative;
    z-index: 2;
`

export const Title = styled.h1`
    font-family: ${({ theme }) => theme.fontFamilies.stratum2};
    font-size: ${({ theme }) => theme.fontSizes.xxl}px;
    text-transform: uppercase;
    margin: ${({ theme }) => theme.spacing.m}px 0 ${({ theme }) => theme.spacing.s}px;
    line-height: 1.0;
    position: relative;
    z-index: 2;
`

export const Subtitle = styled.p`
    font-family: ${({ theme }) => theme.fontFamilies.openSans};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    line-height: 1.5;
    max-width: 300px;
    margin: 0 auto ${({ theme }) => theme.spacing.m}px;
    text-align: center;
    position: relative;
    z-index: 2;
`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.s}px;
    width: 100%;
    max-width: 400px;
    max-height: 50px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
`

export const RegisterButton = styled(Button)`
    width: 100%;
    min-height: 40px;
    font-size: ${({ theme }) => theme.fontSizes.m}px;
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.brand.brandPrimary};
    color: ${({ theme }) => theme.colors.text.white};
    display: flex;
    align-items: center;
    justify-content: center;
`

export const SignInButton = styled(Button)`
    width: 100%;
    min-height: 40px;
    font-size: ${({ theme }) => theme.fontSizes.m}px;
    font-weight: normal;
    background-color: transparent;
    color: #0faffa; // Theme color isn't working here for some reason
    border: 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const FAQSection = styled.section`
    h2 {
        color: ${({ theme }) => theme.colors.text.white};
        font-family: ${({ theme }) => theme.fontFamilies.stratum2};
        text-align: center;
        margin-bottom: ${({ theme }) => theme.spacing.m}px;
    }
`

export const FAQWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.m}px;
`

export const FAQItem = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.text.nonessential};
`

export const FAQTitle = styled.div`
    color: ${({ theme }) => theme.colors.system.actionLight};
    padding: ${({ theme }) => theme.spacing.m}px 0;
    cursor: pointer;
    font-family: ${({ theme }) => theme.fontFamilies.openSans};
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    outline: none;

    &:active,
    &:focus {
        background: transparent;
        outline: none;
    }

    svg {
        transition: transform 0.3s ease;
        transform: ${({ $expanded }) => ($expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
        pointer-events: none;
        color: ${({ theme }) => theme.colors.text.nonessential};
    }
`

export const FAQContent = styled.div`
    max-height: ${({ $expanded }) => ($expanded ? '500px' : '0')};
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    padding: ${({ $expanded }) => ($expanded ? `0 0 ${({ theme }) => theme.spacing.xl}px` : '0')};
    color: ${({ theme }) => theme.colors.text.nonessential};
    font-family: ${({ theme }) => theme.fontFamilies.openSans};
    p {
        margin-bottom: ${({ theme }) => theme.spacing.m}px;
    }
`

export const Footer = styled.footer`
    padding: ${({ theme }) => theme.spacing.l}px;
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.openSans};
    font-size: ${({ theme }) => theme.fontSizes.s}px;

    a {
        color: ${({ theme }) => theme.colors.text.nonessential};
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`
