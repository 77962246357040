import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'
import { Dropdown, Input } from 'semantic-ui-react'
import { Field } from '../../components/Forms/Lead/Field'
import { Header } from '../../components/Forms/Lead/Header'
import { Label } from '../../components/Forms/Lead/Label'
import { TwoColumnGrid } from '../../components/Forms/Lead/TwoColumnGrid'
import { MARITAL_STATUS } from './constants'

const MinorDependentsField = styled.div`
  grid-column: 1;
  grid-row: 1;
  padding-top: ${({ theme }) => theme.spacing.s}px;
  padding-bottom: ${({ theme }) => theme.spacing.m}px;
  padding-right: ${({ theme }) => theme.spacing.s}px;
  @media (max-width: 767px) {
    grid-column: span 2;
    padding-right: 0;
    padding-top: ${({ theme }) => theme.spacing.s}px;
    padding-bottom: ${({ theme }) => theme.spacing.s}px;
  }
`
const AdultDependentsField = styled.div`
  grid-column: 2;
  grid-row: 1;
  padding-top: ${({ theme }) => theme.spacing.s}px;
  padding-bottom: ${({ theme }) => theme.spacing.m}px;
  padding-left: ${({ theme }) => theme.spacing.s}px;
  @media (max-width: 767px) {
    grid-column: span 2;
    grid-row: 2;
    padding-left: 0;
    padding-top: ${({ theme }) => theme.spacing.s}px;
    padding-bottom: ${({ theme }) => theme.spacing.m}px;
  }
`
const StyledDropdown = styled(Dropdown)`
  margin-top: ${({ theme }) => theme.spacing.m}px;
`
const StyledInput = styled(Input)`
  margin-top: ${({ theme }) => theme.spacing.m}px;
`

FamilyAndChildren.propTypes = {
  errors: PropTypes.object,
  dispatch: PropTypes.func,
  dispatchError: PropTypes.func,
}

function FamilyAndChildren({ dispatch, dispatchError, errors }) {
  const onFocus = name => () => {
    dispatchError({ ...errors, [name]: false })
  }
  const onChange = name => (_event, { value }) => {
    dispatch({ type: name, value })
  }
  const validateNumber = name => ({ target }) => {
    const value = parseInt(target.value, 10)
    if (target.value === undefined || target.value === null || isNaN(value) || value < 0) {
      dispatchError({ ...errors, [name]: true })
    }
  }

  return (
    <>
      <Header>Family & Children</Header>
      <Field>
        <Label htmlFor='maritalStatus' error={ errors.maritalStatus }>Marital Status</Label>
        <StyledDropdown
          id='maritalStatus'
          data-testid='maritalStatus'
          fluid
          selection
          options={ MARITAL_STATUS }
          error={ errors.maritalStatus }
          onFocus={ onFocus('maritalStatus') }
          onChange={ onChange('maritalStatus') }
        />
      </Field>
      <TwoColumnGrid>
        <MinorDependentsField>
          <Label htmlFor='minorDependents' error={ errors.minorDependents }>Number of Minor Dependents</Label>
          <StyledInput
            id='minorDependents'
            data-testid='minorDependents'
            fluid
            type='number'
            min={ 0 }
            error={ errors.minorDependents }
            onFocus={ onFocus('minorDependents') }
            onChange={ onChange('minorDependents') }
            onBlur={ validateNumber('minorDependents') }
          />
        </MinorDependentsField>
        <AdultDependentsField>
          <Label htmlFor='adultDependents' error={ errors.adultDependents }>Number of Adult Dependents</Label>
          <StyledInput
            id='adultDependents'
            data-testid='adultDependents'
            fluid
            type='number'
            min={ 0 }
            error={ errors.adultDependents }
            onFocus={ onFocus('adultDependents') }
            onChange={ onChange('adultDependents') }
            onBlur={ validateNumber('adultDependents') }
          />
        </AdultDependentsField>
      </TwoColumnGrid>
    </>
  )
}

export default FamilyAndChildren
