import React from 'react'
import PropTypes from 'prop-types'
import MilitaryService from './MilitaryService'
import { Item } from 'semantic-ui-react'
import { getCurrentDateString } from '../../api/date.util'

MilitaryServiceList.propTypes = {
  list: PropTypes.array.isRequired,
  palette: PropTypes.object.isRequired,
}

function MilitaryServiceList(props) {

  const renderMilitaryServiceExperience = militaryServiceExperience => {
    return (
      <MilitaryService
        key={ JSON.stringify(militaryServiceExperience) }
        startDate={ militaryServiceExperience.startDate }
        endDate={ militaryServiceExperience.endDate }
        dutyTitle={ militaryServiceExperience.dutyTitle }
        dutyStation={ militaryServiceExperience.dutyStation }
        palette={ props.palette }
      />
    )
  }

  const data = props.list.filter(element => (!!element))

  const sortedMilitaryServiceExperiences = [...data].sort((a, b) => {
    const aStartDate = a && a.startDate ? a.startDate : getCurrentDateString()
    const bStartDate = b && b.startDate ? b.startDate : getCurrentDateString()
    return aStartDate.localeCompare(bStartDate)
  })

  return (
    !!sortedMilitaryServiceExperiences && !!sortedMilitaryServiceExperiences.length &&
      <Item.Group data-testid='MilitaryServiceList'>
        { sortedMilitaryServiceExperiences.map(renderMilitaryServiceExperience) }
      </Item.Group>
  )
}

export default MilitaryServiceList
