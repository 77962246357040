import PropTypes from 'prop-types'
import React from 'react'
import { Icon, Message as SemanticMessage } from 'semantic-ui-react'
import theme from '../../theme'
import { fontFamilies } from '../../theme/typography'

Message.propTypes = {
  header: PropTypes.any,
  content: PropTypes.any,
  icon: PropTypes.any,
  style: PropTypes.object,
}

function Message(props) {
  return (
    <SemanticMessage
      icon
      style={ { borderRadius: 8, color: theme.colors.white.default, fontFamily: fontFamilies.openSansRegular, ...props.style } }>
      <Icon name={ props.icon } />
      <SemanticMessage.Content data-testid='success-content'>
        <SemanticMessage.Header style={ { fontFamily: fontFamilies.stratum2Bold, textTransform: 'uppercase' } }>
          { props.header }
        </SemanticMessage.Header>
        { props.content }
      </SemanticMessage.Content>
    </SemanticMessage>
  )
}

SuccessMessage.propTypes = {
  header: PropTypes.any,
  content: PropTypes.any,
}

export function SuccessMessage(props) {
  const styles = { backgroundColor: theme.colors.green.light, ...props.style }
  return (
    <Message
      icon='check circle outline'
      header={ props.header }
      content={ props.content }
      style={ styles }
    />
  )
}

ContentErrorMessage.propTypes = {
  onDismiss: PropTypes.func,
  content: PropTypes.any,
  header: PropTypes.string,
}

export function ContentErrorMessage(props) {
  return (
    <SemanticMessage
      data-testid='error-button-message'
      attached
      icon='cancel'
      color='red'
      onDismiss={ props.onDismiss }
      header={ props.header }
      content={ props.content }
      style={ props.style }
    />
  )
}

export default Message
