import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Dimmer, Loader, Message } from 'semantic-ui-react'

import AimHighModal from '../../components/Modal/AimHighModal'
import InputField from '../../components/Forms/InputField'
import { required } from '../../forms/validation'
import { useDeleteAccount } from '../../queries/accountQueries'

const DeleteAccountModal = ({
  cancelDeleteAccountHandler,
  confirmDeleteAccountHandler,
}) => {
  const theme = useTheme()
  const [userPassword, setUserPassword] = useState()
  const [meta, setMeta] = useState({ touched: false, error: undefined })

  const {
    error: deleteUserAccountErrors,
    isLoading: deleteUserAccountIsLoading,
    isSuccess: deleteUserAccountIsSuccess,
    mutate: deleteUserAcountMutate,
  } = useDeleteAccount()

  const handleFormSubmit = () => {
    deleteUserAcountMutate({ password: userPassword })
  }

  useEffect(() => {
    if (deleteUserAccountIsSuccess) {
      confirmDeleteAccountHandler()
    }
  }, [confirmDeleteAccountHandler, deleteUserAccountIsSuccess])

  const validateInputField = value => {
    const errorText = required(value)
    setMeta({ touched: true, error: errorText })
  }

  const onPasswordChange = value => {
    validateInputField(value)
    setUserPassword(value)
  }

  const renderModalContent = () => {
    return (
      <>
        <Dimmer active={ deleteUserAccountIsLoading }>
          <Loader>Deleting</Loader>
        </Dimmer>
        <p style={ {
          fontSize: `${theme.typography.fontSizes.m}px`,
          paddingLeft: `${theme.typography.fontSizes.s}px`,
          paddingRight: `${theme.typography.fontSizes.s}px`,
        } }>
          Please enter your password and click "Delete Account" to continue. This action cannot be undone.
        </p>
        <InputField
          label='Password*'
          type='password'
          input={ {
            onChange: ({ target }) => {
              onPasswordChange(target.value)
            },
            onBlur: ({ target }) => {
              onPasswordChange(target.value)
            },
            'data-testid': 'password',
            name: 'password',
            id: 'password',
          } }
          meta={ meta }
        />
        { deleteUserAccountErrors &&
          <Message
            error
            color='red'
            style={ { marginTop: theme.spacing.s, borderRadius: theme.radii.s } }
            header='Delete Account Failure'
            content={
              <p style={ { fontSize: `${theme.typography.fontSizes.m}px` } }>
                We're sorry, but there was a problem deleting your account. Please try again later.
              </p>
            }
          />
        }
      </>
    )
  }

  return (
    <AimHighModal
      isOpen
      modalHeader='Are you sure you want to delete your account?'
      modalContent={ renderModalContent() }
      cancelButtonText='Cancel'
      cancelHandler={ cancelDeleteAccountHandler }
      confirmButtonText='Delete Account'
      confirmHandler={ handleFormSubmit }
      confirmButtonDisabled={ (!meta.touched || (meta.touched && meta.error)) }
    />
  )
}

export default DeleteAccountModal
