import React from 'react'
import {
  Section,
  TwoColumnGrid,
  SectionTitle
} from '../afcepLandingPage/AfcepLandingPage.styles'
import { SUB_PROGRAM } from '../afcep.constants'
import { FormItem } from '../../../forms/form.styles'
import DropdownField from '../../../components/Forms/DropdownField'
import InputField from '../../../components/Forms/InputField'
import { Field } from 'redux-form'
import { maxLength3, required } from '../../../forms/validation'

export const Program = () => {

  return (
    <Section>
      <SectionTitle data-testid='program-section-title'>Program</SectionTitle>
      <TwoColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='program-field'>
          <Field
            id='program'
            name='program'
            label='Program*'
            data-testid='program'
            component={ InputField }
            type='text'
            validate={ [required, maxLength3] }
            value='OTS'
            disabled
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='sub-program-field'>
          <Field
            id='subProgram'
            name='subProgram'
            label='Sub-Program*'
            data-testid='subProgram'
            placeholder='Select'
            options={ SUB_PROGRAM }
            component={ DropdownField }
            validate={ required }
          />
        </FormItem>
      </TwoColumnGrid>
    </Section>
  )
}
