import React from 'react'
import { DownloadWrapper, SubTitle, Title, Download, HoldingApp, DownloadIconWrapper, DownloadIcons, Download2 } from './starr.download.styles'
import { APP_STORE_LINKS } from '../../redux/constants/referral.constants'
import SvgAppleStore from '../../icons/AppleStore'
import SvgGooglePlay from '../../icons/GooglePlay'
import HoldingAimHigh from '../../assets/HoldPhone.png'
import { useMediaQuery } from '../../config/useMediaQuery'

const TakeAwayDownload = () => {
  const isMobile = useMediaQuery('(max-width: 780px)')
  const appSize = isMobile ? 35 : 55
  const andSize = isMobile ? 33 : 53
  return (

    <DownloadWrapper>
      <Download2 />
      <Download />
      <SubTitle data-testid='download'>Download</SubTitle>
      <Title data-testid='aim-high'>Aim High</Title>
      <DownloadIcons>
        <DownloadIconWrapper>
          <a className='download' data-testid='download-on-the-app-store' href={ APP_STORE_LINKS.ios }><SvgAppleStore height={ appSize } /></a>
        </DownloadIconWrapper>
        <DownloadIconWrapper>
          <a className='download' data-testid='get-it-on-google-play' href={ APP_STORE_LINKS.android }><SvgGooglePlay height={ andSize } /></a>
        </DownloadIconWrapper>
      </DownloadIcons>
      <HoldingApp data-testid='holding-aim-high' src={ HoldingAimHigh } alt='Person holding aim high' />
    </DownloadWrapper>
  )
}

export default TakeAwayDownload
