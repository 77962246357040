import { call } from 'redux-saga/effects'

export function* downloadFile(data64, contentType, fileName) {
  const data64Response = yield call(fetch, `data:${contentType};base64,${data64}`)
  const datablob = yield call(() => data64Response.blob())

  const a = document.createElement('a')
  a.style = 'display: none'
  const url = window.URL.createObjectURL(datablob)
  a.href = url
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(url)
}
