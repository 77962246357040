import styled from '@emotion/styled'
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react'

export const Checkbox = styled(SemanticCheckbox)`
  padding-top: ${({ theme }) => theme.spacing.s}px;
  padding-bottom: ${({ theme }) => theme.spacing.s}px;

  label {
    color: ${({ theme, hasError }) => (hasError ? theme.system.error : 'currentcolor')} !important;
  }
`
