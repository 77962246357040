import React from 'react'
import PropTypes from 'prop-types'
import FieldLabel from './FieldLabel'
import { AimHighDropdown, DropdownWrapper } from '../../forms/form.styles'

DropdownField.propTypes = {
  options: PropTypes.array,
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  meta: PropTypes.object,
  search: PropTypes.bool,
}

function DropdownField({
  options,
  label,
  name,
  placeholder,
  hasError,
  input,
  meta: { touched, error },
  search,
  disabled,
}) {
  const value = input ? input.value : undefined
  return (
    <>
      <DropdownWrapper data-testid='dropdown'>
        { label && (
          <FieldLabel
            htmlFor={ name }
            hasError={ (touched && hasError) || (touched && error) }>
            { label }
          </FieldLabel>
        ) }
        <AimHighDropdown
          { ...input }
          name={ name }
          onChange={ (_e, data) => {
            input.onChange(data.value)
          } }
          value={ value }
          placeholder={ placeholder || '0' }
          scrolling
          selection
          icon='chevron down'
          options={ options }
          search={ search }
          disabled={ disabled }
        />
      </DropdownWrapper>
    </>
  )
}

export default DropdownField
