import { css } from '@emotion/css'
import styled from '@emotion/styled'
import spacing from '../../../theme/spacing'

export const NoOrdersDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${spacing.xl}vh;
  flex-direction: column;
`

export const OrderContents = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${spacing.l}px;
`

export const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: right;
  margin-left: ${spacing.xl}px;
`

export const ActionButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 0px ${spacing.m}px 0px ${spacing.m}px;
`

export const ActionIconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const app = document.getElementById('root') ? document.getElementById('root') : { classList: new Set() }
const myStyle = css`
  .pdf-document {
    margin: 1em 0;
  }

  .pdf-document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pdf-document .react-pdf__Page {
    max-width: calc(100% - 2em);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    margin: 1em;
  }

  .pdf-document .react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
  }

  .pdf-document .react-pdf__message {
    padding: 20px;
    color: white;
  }
`
app.classList.add(myStyle)
