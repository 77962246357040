import React from 'react'
import { Form, FormSelect, FormTextField, Button, FormDatepicker } from '@aimhigh/web-components/web'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { fourColStyle, IconLabelWrapper, StyledInfoIcon } from '../../LineOfficer.styles'
import { HELP_ICON_SIZE } from '../../lineOfficer.constants'
import { theme as ahaTheme } from '@aimhigh/theme'

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  column-gap: ${({ theme }) => theme.spacing.m}px;
  row-gap: ${({ theme }) => theme.spacing.xl}px;

  > * {
    grid-column: span 2;
  }
`

const PriorServiceForm = ({ onSubmit }) => {
  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
    },
  })

  const handleFormSubmit = values => {
    // eslint-disable-next-line no-alert
    alert(
      `--form values--\n${Object.keys(values)
        .map(key => {
          const value = values[key]
          return `${key}: ${
            typeof value === 'object' ? JSON.stringify(value) : value
          }`
        })
        .join('\n')}`
    )

    onSubmit()
  }

  return (
    <StyledForm formHooks={ formHooks } onSubmit={ handleFormSubmit }>
      <FormDatepicker label='Start Date' name='start_date' placeholder='MM/YYYY' dark />
      <FormDatepicker label='End Date' name='end_date' placeholder='MM/YYYY' dark />

      <FormSelect label='Branch' name='branch' options={ [] } placeholder='Select' style={ fourColStyle } dark />

      <FormSelect label='Grade' name='grade' options={ [] } placeholder='Select' style={ fourColStyle } dark />

      <FormDatepicker label='DOR Date' name='dor_date' placeholder='MM/YYYY' dark />
      <FormTextField label='Prior Job' name='prior_job' dark />

      <FormSelect label='Type of Discharge' name='discharge_type' options={ [] } placeholder='Select' style={ fourColStyle } dark />

      <FormTextField
        label={
          <IconLabelWrapper>
            Retirement Eligibility Code
            <StyledInfoIcon color={ ahaTheme.colors.system.actionLight } size={ HELP_ICON_SIZE } />
          </IconLabelWrapper>
        }
        name='alt_email'
        style={ fourColStyle }
        dark
      />

      <Button type='submit' style={ fourColStyle } dark>Next</Button>
    </StyledForm>
  )
}

export default PriorServiceForm
