import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Header, Table, Dimmer, Loader, Grid, List } from 'semantic-ui-react'
import LogoutButton from '../../navigation/LogoutButton'
import { fetchRoleRequests } from '../../redux/sagas/user.sagas'
import {
  selectRoleRequests,
  selectLastRoleRequestKey,
  selectIsLoadingRoleRequests
} from '../../redux/selectors/user.selectors'
import { Page, ConstrainedCell } from '../users/users.page.styles'
import AHSidebar from '../../components/Navigation/NavSidebar'
import { NAV_ROUTES } from '../../navigation/routes'
import { selectAuthUserRoles } from '../../redux/selectors/auth.selectors'
import { ROLE_DISPLAY_NAMES } from '../../config'
import { getStandardAmericanDate } from '../../api/date.util'

class ListRoleRequestsPage extends React.PureComponent {

  componentDidMount() {
    this.props.fetchRoleRequests()
  }

  render() {
    const { roleRequests, lastRoleRequestKey, isLoading } = this.props
    return (
      <>
        <AHSidebar currentRoute={ NAV_ROUTES.ROLE_REQUESTS_PAGE } />
        <Page>
          <Dimmer.Dimmable dimmed={ isLoading }>
            <Dimmer active={ isLoading } page>
              <Loader>Loading</Loader>
            </Dimmer>
            <div style={ { margin: 20 } }>
              <Header as='h1'>
                <Grid>
                  <Grid.Row columns='equal'>
                    <Grid.Column data-testid='role-requests-header'>Role Requests</Grid.Column>
                    <Grid.Column textAlign='right'><LogoutButton /></Grid.Column>
                  </Grid.Row>
                </Grid>
              </Header>
              <Table compact striped singleLine>
                <Table.Header data-testid='table-header'>
                  <Table.Row data-testid='table-row'>
                    <Table.HeaderCell data-testid='email-table-header-cell'>Email</Table.HeaderCell>
                    <Table.HeaderCell data-testid='organization-table-header-cell'>Organization</Table.HeaderCell>
                    <Table.HeaderCell data-testid='creation-date-table-header-cell'>Creation Date</Table.HeaderCell>
                    <Table.HeaderCell data-testid='requested-roles-table-header-cell'>Requested Roles</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body data-testid='table-body'>
                  { roleRequests && roleRequests.map(roleRequest => {
                    const formattedCreatedAt = getStandardAmericanDate(roleRequest.createdAt)

                    return (
                      <Table.Row key={ roleRequest.id }>
                        <Table.Cell data-testid='email-table-cell'><ConstrainedCell>{ roleRequest.email }</ConstrainedCell></Table.Cell>
                        <Table.Cell data-testid='organization-table-cell'><ConstrainedCell>{ roleRequest.organizationName }</ConstrainedCell></Table.Cell>
                        <Table.Cell data-testid='creation-date-table-cell'>{ formattedCreatedAt }</Table.Cell>
                        <Table.Cell data-testid='requested-roles-table-cell'>
                          <List items={ roleRequest.requestedRoles
                            .map(request => (ROLE_DISPLAY_NAMES[request] ? ROLE_DISPLAY_NAMES[request] : request)) }
                          />
                        </Table.Cell>
                      </Table.Row>
                    )
                  }) }
                </Table.Body>
              </Table>
              { lastRoleRequestKey &&
                <Button fluid onClick={ () => this.props.fetchRoleRequests({ startAt: lastRoleRequestKey }) }>
                  Load More Role Requests
                </Button> }
            </div>
          </Dimmer.Dimmable>
        </Page>
      </>
    )
  }
}

ListRoleRequestsPage.propTypes = {
  roleRequests: PropTypes.array,
  fetchRoleRequests: PropTypes.func,
  isLoading: PropTypes.bool,
  lastRoleRequestKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}

function bindAction(dispatch) {
  return {
    fetchRoleRequests: lastKey => dispatch(fetchRoleRequests.request(lastKey)),
  }
}

const mapStateToProps = state => ({
  roleRequests: selectRoleRequests(state),
  lastRoleRequestKey: selectLastRoleRequestKey(state),
  loggedInUserRoles: selectAuthUserRoles(state),
  isLoading: !!selectIsLoadingRoleRequests(state),
})

export default connect(mapStateToProps, bindAction)(withRouter(ListRoleRequestsPage))

