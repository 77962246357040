import axios from 'axios'
import { useQuery } from 'react-query'
import config from '../../config'

const afreServiceUrl = config.apiUrl

export const putId2aWatched = async leadId => {
  const url = `${afreServiceUrl}/lead/ID2A/${leadId}`
  try {
    const response = await axios.put(url)
    if (response.status === 200) {
      return response
    } else {
      return { error: response.data.message }
    }
  } catch (error) {
    return { error: error.response.data.error }
  }
}

export const useId2aInfo = leadId => {
  return useQuery(['id2aInfo', leadId], async () => {
    const response = await axios.get(`${afreServiceUrl}/lead/ID2A/${leadId}`)
    return response.data.data
  }, { retry: false, cacheTime: 0 })
}
