import styled from '@emotion/styled'
import { Icon, Table } from 'semantic-ui-react'
import { withOpacity } from '../../theme/colors'
import spacing from '../../theme/spacing'

export const FooterPage = styled.div`
  padding: ${spacing.m}px 0;
  height: 95%;
  overflow-y: scroll;
`

export const FullsizeDatepicker = styled.div`
  div.field {
    width: 100%;
  }

  div.field .field .ui.icon.input {
    width: 100%;
  }
`

export const DynamicTableCell = styled(Table.Cell)`
  color: ${props => (props.errors ? props.theme.system.error : 'default')};
  border-color: ${props => (props.errors ? props.theme.system.error : 'default')};
  background-color: ${props => (props.errors ? withOpacity(props.theme.system.error, 0.1) : 'default')};
`

export const Footer = styled.div`
  position: absolute;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: ${spacing.m}px;
  padding-left: ${spacing.m}px;
  bottom: 0;
  width: 100%;
  height: ${spacing.xl}px;
  background-color: ${props => props.theme.canvas.primary};
  filter: drop-shadow(0 -${spacing.xs}px ${spacing.xs}px ${props => props.theme.background.level3});
`

export const FooterLegend = styled.div`
  width: 70%;
  height: 100%;
  flex-direction: row;
`

export const FooterLegendText = styled.div`
  font-weight: bold;
  margin-top: ${spacing.xs}px;
`

export const FooterLegendContent = styled.div`
  display: flex;
  flex-direction: row;
`

export const FooterLegendValue = styled.div`
  margin-right: ${spacing.m}px;
  margin-top: ${spacing.xs}px;
`

export const ProcessWarningText = styled.p`
  color: ${props => props.theme.text.warning};
`

export const ModalSpacingDiv = styled.div`
  padding-bottom: ${spacing.m}px;
`

export const ArmsStatusIcon = styled(Icon)`
  color: ${props => props.iconcolor}!important;
`
