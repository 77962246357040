/* eslint-disable max-statements */
import { ThemeProvider } from '@emotion/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SeamlessImmutable from 'seamless-immutable'
import { Dimmer, Grid, Loader, Modal, Tab } from 'semantic-ui-react'
import { CustomDropdown } from '../../components/Forms/CustomDropdown'
import { NAV_ROUTES } from '../../navigation/routes'
import { LightPalette } from '../../theme'
import spacing from '../../theme/spacing'
import { fontSizes } from '../../theme/typography'
import { OpaPageHeader } from '../components/PageTitle/OpaPageHeader'
import { OpaRichTextEditor } from '../components/RichTextEditing/OpaRichTextEditor'
import {
  deleteVariation,
  getAfscProperties,
  getConditionals,
  getTemplateSection,
  getTemplateSections,
  getVariables,
  selectAfscPropertyList,
  selectConditionals,
  selectDeletingVariation,
  selectGettingTemplateSection,
  selectGettingTemplateSections,
  selectTemplateSections,
  selectUpdatingSectionVariations,
  selectUpdatingSectionVariationsError,
  updateSectionVariations
} from '../redux/ducks/opa.duck'
import { ActionButton, CancelButton, DangerButton, InvertedDangerButton, ScrollablePage } from './Opa.styles'
import { StickyButtonDiv, VariationGrid } from './SectionManagementPage.styles'
import { ErrorLabel } from './UploadWeekGroupPage.styles'

export const SectionManagementPage = () => {
  const dispatch = useDispatch()

  const afscPropertyList = useSelector(selectAfscPropertyList)
  const conditionalList = useSelector(selectConditionals)
  const templateSections = useSelector(selectTemplateSections)
  const isGettingTemplateInfo = useSelector(selectGettingTemplateSections)
  const isRefreshingSectionInfo = useSelector(selectGettingTemplateSection)
  const isUpdatingSectionVariations = useSelector(selectUpdatingSectionVariations)
  const isDeletingVariation = useSelector(selectDeletingVariation)
  const updatingSectionVariationsError = useSelector(selectUpdatingSectionVariationsError)
  const [activeSectionIndex, setActiveSectionIndex] = useState(0)
  const [previousSectionIndex, setPreviousSectionIndex] = useState(0)
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0)
  const [templateData, setTemplateData] = useState(templateSections)
  const [allAfscs, setAllAfscs] = useState([])
  const [conditionalOptions, setConditionalOptions] = useState([])
  const [dirtyVariations, setDirtyVariations] = useState({})
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [navToPrevSection, setNavToPrevSection] = useState(false)
  const [variationToDelete, setVariationToDelete] = useState(null)

  const showSpinner = isGettingTemplateInfo || (navToPrevSection && isRefreshingSectionInfo) || isDeletingVariation || isUpdatingSectionVariations

  useEffect(() => {
    dispatch(getAfscProperties.request())
    dispatch(getConditionals.request())
    dispatch(getVariables.request())
    dispatch(getTemplateSections.request())
  }, [dispatch])

  useEffect(() => {
    setTemplateData(templateSections)
  }, [templateSections])

  useEffect(() => {
    setAllAfscs(
      afscPropertyList ? afscPropertyList.map(afscProperties => {
        return { key: afscProperties.sort, text: afscProperties.sort, value: afscProperties.sort }
      }) : []
    )
    setConditionalOptions(
      conditionalList ? conditionalList.map(conditional => {
        return { key: conditional.sort, text: conditional.display, value: conditional.sort }
      }) : []
    )
  }, [afscPropertyList, conditionalList])

  useEffect(() => {
    const updatingVariationsError = updatingSectionVariationsError && updatingSectionVariationsError.data && updatingSectionVariationsError.data.error
    if (!isUpdatingSectionVariations && !updatingVariationsError && isSubmitted) {
      setDirtyVariations({})
      setIsSubmitted(false)
    }
  }, [isUpdatingSectionVariations, updatingSectionVariationsError, isSubmitted])

  const deleteVariationHandler = variation => {
    setVariationToDelete(variation)
    setDeleteModalOpen(true)
  }

  const updateLocalTemplateVariations = (value, variationIndex) => {
    const updatedSections = JSON.parse(JSON.stringify(templateData))
    const updatedVariation = updatedSections[activeSectionIndex].variations[variationIndex]

    const data = value[0]
    if (data.type === 'paragraph' || data.id === 'table') {
      updatedVariation.value = data
      updatedVariation.isDirty = true
    } else if (data.type === 'center') {
      updatedVariation.value = data.children[0]
      updatedVariation.isDirty = true
    }

    setTemplateData(updatedSections)
    setDirtyVariations({ ...dirtyVariations, [updatedVariation.sort]: updatedVariation })
  }

  const updateVariationAfscs = (data, variationIndex) => {
    const updatedSections = JSON.parse(JSON.stringify(templateData))
    const updatedVariation = updatedSections[activeSectionIndex].variations[variationIndex]

    updatedVariation.afscs = data.value
    updatedVariation.isDirty = true

    setTemplateData(updatedSections)
    setDirtyVariations({ ...dirtyVariations, [updatedVariation.sort]: updatedVariation })
  }

  const updateVariationConditionals = (data, variationIndex) => {
    const updatedSections = JSON.parse(JSON.stringify(templateData))
    const updatedVariation = updatedSections[activeSectionIndex].variations[variationIndex]

    updatedVariation.conditionals = conditionalList.filter(conditional => data.value.includes(conditional.sort))
    updatedVariation.isDirty = true

    setTemplateData(updatedSections)
    setDirtyVariations({ ...dirtyVariations, [updatedVariation.sort]: updatedVariation })
  }

  const addNewVariation = () => {
    const updatedSections = [...templateData]

    const updatedVariations = [...updatedSections[activeSectionIndex].variations]
    const newVariation = {
      id: 'variation',
      sort: `${updatedSections[activeSectionIndex].sort}_${Date.now()}_new`,
      displayOrder: updatedVariations[updatedVariations.length - 1].displayOrder + 1,
      afscs: [],
      conditionals: [],
      value: JSON.parse('{"type":"paragraph","children":[{"text":""}]}'),
      isNew: true,
    }
    updatedVariations.push(newVariation)

    updatedSections[activeSectionIndex] = { ...updatedSections[activeSectionIndex], variations: updatedVariations }
    setTemplateData(updatedSections)

    setDirtyVariations({ ...dirtyVariations, [newVariation.sort]: newVariation })
  }

  const saveVariations = () => {
    dispatch(updateSectionVariations.request({ templateData, activeSectionIndex }))
    setIsSubmitted(true)
  }

  const renderErrorLabel = (variationError, errorField) => {
    return (
      isSubmitted && updatingSectionVariationsError && updatingSectionVariationsError.data && variationError && variationError[errorField] &&
        <ErrorLabel data-testid='update-variation-field-error-text'>
          { variationError[errorField].toUpperCase() }
        </ErrorLabel>
    )
  }

  const getUnusedAfscs = useCallback((template, variation) => {
    return allAfscs.filter(afsc => {
      const usedAfscs = template.variations
        .map(v => v.afscs)
        .flat()
        .filter(variationAfscs => variation.afscs && !variation.afscs.includes(variationAfscs))
      return !usedAfscs.includes(afsc.value)
    })
  }, [allAfscs])

  const renderEditorColumn = (variation, variationIndex) => (
    <>
      { variation.default && <br /> }
      {
        variation.value &&
          <OpaRichTextEditor
            value={ [SeamlessImmutable.asMutable(variation.value, { deep: true })] }
            onInputChange={ updateLocalTemplateVariations }
            textEditorIndex={ variationIndex }
          />
      }
      {
        renderErrorLabel(
          updatingSectionVariationsError &&
        updatingSectionVariationsError.data &&
        updatingSectionVariationsError.data[variation.sort],
          'value'
        )
      }
    </>
  )

  const templatePanes = templateData && templateData.map(template => {
    return {
      menuItem: template.name,
      render: () => {
        return (
          <Tab.Pane data-testid='active-tab-pane'>
            <StickyButtonDiv>
              <Grid.Row columns='equal'>
                <Grid.Column textAlign='center' style={ { padding: '3px' } }>
                  {
                    isSubmitted &&
                    updatingSectionVariationsError &&
                    updatingSectionVariationsError.data &&
                    updatingSectionVariationsError.data.error &&
                      <ErrorLabel data-testid='update-variation-error-text'>
                        { updatingSectionVariationsError.data.error.toUpperCase() }
                      </ErrorLabel>
                  }
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <ActionButton data-testid='add-variation-button' onClick={ () => addNewVariation() }>Add Variation</ActionButton>
                <ActionButton data-testid='save-button' disabled={ Object.keys(dirtyVariations).length === 0 } onClick={ saveVariations }>Save</ActionButton>
              </Grid.Row>
            </StickyButtonDiv>
            <Grid.Row style={ { paddingTop: spacing.m } }>
              { template.variations.map((variation, variationIndex) => {
                return (
                  <VariationGrid key={ variation.sort } isdirty={ variation.isDirty ? 1 : 0 } data-testid='variation-form'>
                    <Grid.Row>
                      <Grid.Column width={ 6 } style={ { paddingLeft: spacing.m, display: 'flex', flexDirection: 'column' } }>
                        <CustomDropdown
                          variation={ variation }
                          variationIndex={ variationIndex }
                          defaultCase={ <div><b style={ { fontSize: fontSizes.xl } } data-testid='default-text'>DEFAULT</b></div> }
                          dropdownOptions={ getUnusedAfscs(template, variation) }
                          placeholder='Add AFSC'
                          dropdownValues={ variation.afscs ? variation.afscs : [] }
                          updateFunction={ updateVariationAfscs }
                        />
                        {
                          renderErrorLabel(
                            updatingSectionVariationsError &&
                            updatingSectionVariationsError.data &&
                            updatingSectionVariationsError.data[variation.sort],
                            'afscs'
                          )
                        }
                        <div style={ { paddingTop: spacing.s } }>
                          <CustomDropdown
                            variation={ variation }
                            variationIndex={ variationIndex }
                            defaultCase={ null }
                            dropdownOptions={ conditionalOptions }
                            placeholder='Add Conditional'
                            dropdownValues={ variation.conditionals ? variation.conditionals.map(condition => condition.sort) : [] }
                            updateFunction={ updateVariationConditionals }
                          />
                          {
                            renderErrorLabel(
                              updatingSectionVariationsError &&
                              updatingSectionVariationsError.data &&
                              updatingSectionVariationsError.data[variation.sort],
                              'conditionals'
                            )
                          }
                        </div>
                        {
                          !variation.default &&
                            <div style={ { paddingTop: spacing.l, display: 'flex', flex: 1 } }>
                              <InvertedDangerButton
                                data-testid='delete-button'
                                style={ { marginTop: 'auto' } }
                                content='Delete'
                                icon='trash alternate outline'
                                onClick={ () => deleteVariationHandler(variation) }
                              />
                            </div>
                        }
                      </Grid.Column>
                      <Grid.Column data-testid='edit-column'>
                        { renderEditorColumn(variation, variationIndex) }
                      </Grid.Column>
                    </Grid.Row>
                  </VariationGrid>
                )
              }) }
            </Grid.Row>
          </Tab.Pane>
        )
      },
    }
  })

  const scrollToTop = () => {
    setTimeout(() => document.getElementsByClassName('scroll-page')[0].scrollTo(0, 0), 1)
  }

  const updateTab = tabData => {
    if (previousSectionIndex === tabData.activeIndex) {
      setNavToPrevSection(true)
    } else {
      setNavToPrevSection(false)
    }

    setPreviousSectionIndex(activeSectionIndex)
    if (Object.keys(dirtyVariations).length > 0) {
      setUnsavedModalOpen(true)
      setSelectedSectionIndex(tabData.activeIndex)
    } else {
      setPreviousSectionIndex(null)
      setActiveSectionIndex(tabData.activeIndex)
      scrollToTop()
    }
  }

  return (
    <>
      <ThemeProvider theme={ LightPalette }>
        <Modal
          open={ unsavedModalOpen }>
          <Modal.Header data-testid='modal-header'>Unsaved Changes</Modal.Header>
          <Modal.Content data-testid='modal-content'>
            <p>You have unsaved changes that will be lost. What would you like to do?</p>
          </Modal.Content>
          <Modal.Actions>
            <CancelButton onClick={ () => {
              setUnsavedModalOpen(false)
              setActiveSectionIndex(previousSectionIndex)
            } }>
              Cancel
            </CancelButton>
            <DangerButton
              content='Discard Changes'
              icon='trash alternate outline'
              data-testid='modal-discard-changes-button'
              onClick={ () => {
                setUnsavedModalOpen(false)
                setDirtyVariations({})
                setActiveSectionIndex(selectedSectionIndex)
                setIsSubmitted(false)
                scrollToTop()
                dispatch(getTemplateSection.request({ templateData, activeSectionIndex }))
              } }
            />
            <ActionButton
              content='Save Changes'
              icon='checkmark'
              data-testid='modal-save-changes-button'
              onClick={ () => {
                setUnsavedModalOpen(false)
                setActiveSectionIndex(previousSectionIndex)
                scrollToTop()
                saveVariations()
              } }
            />
          </Modal.Actions>
        </Modal>
        <Modal
          open={ deleteModalOpen }>
          <Modal.Header data-testid='modal-header'>Confirm Delete</Modal.Header>
          <Modal.Content data-testid='modal-content'>
            <p>Are you sure you want to delete this variation?</p>
          </Modal.Content>
          <Modal.Actions>
            <CancelButton onClick={ () => {
              setDeleteModalOpen(false)
              setVariationToDelete(null)
            } }>
              Cancel
            </CancelButton>
            <DangerButton
              content='Delete'
              icon='trash alternate outline'
              data-testid='modal-delete-button'
              onClick={ () => {
                dispatch(deleteVariation.request({ section: templateData[activeSectionIndex], variationToDelete, templateData }))
                setDeleteModalOpen(false)
                setVariationToDelete(null)
              } }
            />
          </Modal.Actions>
        </Modal>
        <ScrollablePage className='scroll-page'>
          <Dimmer.Dimmable dimmed={ showSpinner }>
            <Dimmer active={ showSpinner } page>
              <Loader>Loading</Loader>
            </Dimmer>
            <div style={ { margin: spacing.m } }>
              <OpaPageHeader pageRoute={ NAV_ROUTES.SECTIONS_PAGE } pageTitle='Section Management' />
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Tab
                      activeIndex={ activeSectionIndex }
                      menu={ { fluid: true, vertical: true, tabular: true } }
                      grid={ { paneWidth: '14', tabWidth: '2' } }
                      panes={ templatePanes }
                      onTabChange={ (_e, data) => updateTab(data) }
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </Dimmer.Dimmable>
        </ScrollablePage>
      </ThemeProvider>
    </>
  )
}
