export const squadronActions = {
  CREATE_SQUADRON: 'CREATE_SQUADRON',
  FETCH_SQUADRONS: 'FETCH_SQUADRONS',
  UPDATE_SQUADRON: 'UPDATE_SQUADRON',
  DELETE_SQUADRON: 'DELETE_SQUADRON',
  GET_SQUADRON_FLIGHTS: 'GET_SQUADRON_FLIGHTS',
  CREATE_FLIGHT: 'CREATE_FLIGHT',
  DELETE_FLIGHT: 'DELETE_FLIGHT',
  UPDATE_FLIGHT: 'UPDATE_FLIGHT',
}
