import immutable from 'seamless-immutable'

export const setClientId = 'SET_CLIENT_ID'
export const clearClientId = 'CLEAR_CLIENT_ID'

const INITIAL_STATE = immutable({
  clientId: null,
})

export default function amplitudeAnalyticsReducers(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case setClientId:
      return state.set('clientId', action.payload.clientId)
    case clearClientId:
      return state.set('clientId', null)
    default:
      return state
  }
}
