import immutable from 'seamless-immutable'

import {
  initializeAuth,
  createAccount,
  loginEmail,
  logout,
  refreshToken
} from '../sagas/auth.sagas'

const INITIAL_STATE = immutable({
  token: undefined,
  refreshToken: undefined,
})

export default function authReducers(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case initializeAuth.SUCCESS:
    case createAccount.SUCCESS:
    case loginEmail.SUCCESS:
      return state.set('token', action.payload.token).set('refreshToken', action.payload.refreshToken)

    case refreshToken.SUCCESS:
      return state.set('token', action.payload.token)

    case logout.REQUEST:
      return state.set('token', null).set('refreshToken', null)

    default:
      return state
  }
}
