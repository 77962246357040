import React from 'react'
import { ShareTeaserWrapper, TeaserTitle, TeaserDownloadLinks, TeaserText, TeaserTextList } from './ShareTeaser.styles'
import { APP_STORE_LINKS } from '../../redux/constants/referral.constants'
import SvgAppleStore from '../../icons/AppleStore'
import SvgGooglePlay from '../../icons/GooglePlay'

const ShareTeaser = () => {

  return (
    <ShareTeaserWrapper>
      <TeaserTitle data-testid='teaser-title'>Explore the transition from civilian to airman</TeaserTitle>
      <TeaserTextList data-testid='teaser-text-list' style={ { paddingBottom: '20px' } }>
        <ul>
          <li style={ { paddingBottom: '10px' } }>Learn about education and career options</li>
          <li style={ { paddingBottom: '10px' } }>Follow the journey of a service member</li>
          <li style={ { paddingBottom: '10px' } }>Connect with a recruiter</li>
        </ul>
      </TeaserTextList>
      <TeaserText data-testid='download-the-aim-high-app-today' style={ { paddingBottom: '20px' } }>
        Download the Aim High app today.
      </TeaserText>

      <TeaserDownloadLinks>
        <a className='download' data-testid='download-on-the-app-store' href={ APP_STORE_LINKS.ios } style={ { paddingRight: '40px' } }><SvgAppleStore /></a>
        <a className='download' data-testid='get-it-on-google-play' href={ APP_STORE_LINKS.android }><SvgGooglePlay /></a>
      </TeaserDownloadLinks>
    </ShareTeaserWrapper>
  )
}

export default ShareTeaser
