import * as React from 'react';

function EmptyStateConnectionsRequest(props) {
  return (
    <svg width={ props.size } height={ props.size } viewBox="0 0 120 120" { ...props }>
      <defs />
      <g fill="none" fillRule="evenodd">
        <circle fill="#E8E9EA" cx={ 60 } cy={ 60 } r={ 40 } />
        <g
          transform="translate(31 29)">
          <g stroke="#3C4650">
            <path fill="#FFF" d="M.5.5h45v62H.5z" />
            <path
              d="M45.5.5v62H14a13.458 13.458 0 01-9.546-3.954A13.458 13.458 0 01.5 49h0V.5h45z"
              fill="#FFF"
            />
            <path
              d="M.622 48.794c2.317 2.722 5.537 4.341 9.66 4.858 0 4.663 1.823 7.072 5.09 8.703"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <path
            d="M47.29 40.197a4.299 4.299 0 00-.007-6.075 4.288 4.288 0 00-6.07-.006 4.299 4.299 0 00.007 6.074l.79 12.6 4.467.003.812-12.596z"
            stroke="#3C4650"
            fill="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M54 49.5c.966 0 1.841.392 2.475 1.025A3.489 3.489 0 0157.5 53h0v9.5h-27V53c0-.966.392-1.841 1.025-2.475A3.489 3.489 0 0134 49.5h0z"
            stroke="#3C4650"
            fill="#FFF"
          />
          <path
            stroke="#3C4650"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M30.609 55.79h26.384"
          />
          <circle stroke="#0C884A" cx={ 23 } cy={ 31 } r={ 11 } />
          <path
            stroke="#0C884A"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18 30.415L21.763 34 29 27"
          />
        </g>
      </g>
    </svg>
  );
}
EmptyStateConnectionsRequest.displayName = EmptyStateConnectionsRequest;
export default EmptyStateConnectionsRequest;
