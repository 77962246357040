import lodashFindIndex from 'lodash.findindex'
import lodashSortBy from 'lodash.sortby'
import { createSelector } from 'reselect'
import { CREATE_AFRISS_LEAD_ID, CREATE_REFERRAL_SAGA_ID, GET_LEAD_SAGA_ID } from '../constants/referral.constants'
import { createErrorSelector, createIsLoadingSelector } from './common.selectors'

export const selectReferral = ({ referral }) => referral
export const selectLead = ({ referral }) => referral.lead

export const selectReferralError = createErrorSelector(CREATE_REFERRAL_SAGA_ID)
export const selectReferralLoading = createIsLoadingSelector(CREATE_REFERRAL_SAGA_ID)
export const selectLeadSubmitting = createIsLoadingSelector(CREATE_AFRISS_LEAD_ID)
export const selectLeadError = createErrorSelector(GET_LEAD_SAGA_ID)
export const selectCreateLeadError = createErrorSelector(CREATE_AFRISS_LEAD_ID)
export const selectLeadLoading = createIsLoadingSelector(GET_LEAD_SAGA_ID)

export const selectLeadPageLoading = createSelector(
  [selectLeadLoading, selectReferralLoading],
  (leadLoading, referralLoading) => {
    return leadLoading && referralLoading
  }
)

export const selectGeneralInfo = createSelector(
  [selectLead],
  lead => {
    return lead ? {
      generalInfo: lead,
    } : null
  })

export const selectReferrals = state => lodashSortBy(state.referral.referrals, ['createdAt']).reverse()
export const selectSelectedReferral = state => state.referral.selectedReferral
export const selectIsReferralModalOpen = state => state.referral.isModalOpen

export const selectPreviousReferral = createSelector(
  [selectSelectedReferral, selectReferrals],
  (selectedReferral, referrals) => {
    const indexOfSelectedReferral = lodashFindIndex(referrals, referral => referral.id === selectedReferral.id)
    const newReferralIndex = indexOfSelectedReferral - 1
    if (newReferralIndex < 0) {
      return referrals[0]
    } else {
      return referrals[newReferralIndex]
    }
  }
)

export const selectNextReferral = createSelector(
  [selectSelectedReferral, selectReferrals],
  (selectedReferral, referrals) => {
    const indexOfSelectedReferral = lodashFindIndex(referrals, referral => referral.id === selectedReferral.id)
    const newReferralIndex = indexOfSelectedReferral + 1
    if (newReferralIndex >= referrals.length) {
      return referrals[referrals.length - 1]
    } else {
      return referrals[newReferralIndex]
    }
  }
)

export const getReferrerInfo = location => {
  const params = new URLSearchParams(location.search)
  if (params.get('referAFriend') === 'true') {
    const referrerEmailAddress = params.get('referrerEmailAddress')
    const referrerDodIdentification = params.get('referrerDodIdentification')
    const referrerFirstName = params.get('referrerFirstName')
    const referrerLastName = params.get('referrerLastName')
    const referrerCssEmail = params.get('referrerCssEmail')
    return {
      referrerFirstName,
      referrerLastName,
      referrerEmailAddress,
      referrerDodIdentification,
      referrerCssEmail,
    }
  }
  return null
}
