import styled from '@emotion/styled'
import { Container } from '../../../components/Forms/Lead/Container'
import { Dropdown, Input } from 'semantic-ui-react'
import { ThreeColumnGrid as BaseThreeColumnGrid } from '../../../components/Forms/Lead/ThreeColumnGrid'
import { TwoColumnGrid as BaseTwoColumnGrid } from '../../../components/Forms/Lead/TwoColumnGrid'
import { Label } from '../../../components/Forms/Lead/Label'
import { SixColumnGrid as BaseSixColumnGrid } from '../SixColumnGrid'
import { ServerError } from '../../../forms/ValidationError'

export const AfcepRequestContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AfcepRequestContent = styled.div`
  width: 50%;
`
export const AfcepRequestCheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.l}px;
`

export const AfcepRequestLegalInfo = styled.div`
  border: 1px solid ${({ theme }) => theme.background.level2};
  height: ${({ theme }) => theme.spacing.xxl}px;
  overflow-y: scroll;
`

export const AfcepRequestFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.background.level1};
  padding-top: ${({ theme }) => theme.spacing.l}px;
  padding-bottom: ${({ theme }) => theme.spacing.l}px;
`

export const AfcepRequestFooterContentContactInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const AfcepRequestFooterContentContactInfo = styled.div`
  max-width: 40%;
`

export const AfcepRequestSectionContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.l}px;
  margin-bottom: ${({ theme }) => theme.spacing.l}px;
`

export const PrivacyPolicyLink = styled.a`
  color: ${({ theme }) => theme.text.subtle};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.text.subtle};
  cursor: pointer;
`

export const Header = styled.header`
  padding: ${({ theme }) => theme.spacing.l}px 5% 0;
  background: ${({ theme }) => theme.background.level0};
  text-align: center;
`

export const Main = styled.main`
  padding: ${({ theme }) => theme.spacing.m}px 5% 0;
  background: ${({ theme }) => theme.background.level0};
`

export const Footer = styled.footer`
  background: ${({ theme }) => theme.background.level0};
  padding: 30px 5% 0;
`

export const FormContainer = styled(Container)`
  align-items: start;
  margin: 0 auto;
`

export const PageTitle = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSizes.xxxl}px;
  line-height: 1.0;
  color: ${({ theme }) => theme.text.contrast};
  font-family: ${({ theme }) => theme.typography.fontFamilies.stratum2Bold};

  @media (max-width: 600px) {
    font-size: ${({ theme }) => theme.typography.fontSizes.xxl}px;
  }
`

export const HorizontalRule = styled.hr`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.background.level1};
  margin: 0;
`

export const Section = styled.div`
  padding-top: ${({ theme }) => theme.spacing.l}px;
`

export const SectionTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.fontSizes.xl}px;
  padding-bottom: ${({ theme }) => theme.spacing.l}px;
  margin: 0;

  @media (max-width: 600px) {
    font-size: ${({ theme }) => theme.typography.fontSizes.l}px;
    padding-bottom: ${({ theme }) => theme.spacing.m}px;
  }
`

export const P = styled.p`
  font-size: ${({ size, theme }) => theme.typography.fontSizes[size ? size : 'm']}px;
  font-family: ${({ theme }) => theme.typography.fontFamilies.openSansRegular};
  color: ${({ theme }) => theme.text.contrast};

  strong {
    text-transform: uppercase;
  }
`

export const ThreeColumnGrid = styled(BaseThreeColumnGrid)`
  grid-column-gap: ${({ theme }) => theme.spacing.m}px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

export const TwoColumnGrid = styled(BaseTwoColumnGrid)`
  grid-column-gap: ${({ theme }) => theme.spacing.m}px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

export const SixColumnGrid = styled(BaseSixColumnGrid)`
  grid-column-gap: ${({ theme }) => theme.spacing.m}px;

  @media (max-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const StyledLabel = styled(Label)`
  font-family: ${({ theme }) => theme.typography.fontFamilies.openSansRegular};
`

export const StyledErrorLabel = styled(StyledLabel)`
  font-family: ${({ theme }) => theme.typography.fontFamilies.openSansRegular};
  color: ${({ theme }) => theme.text.negative};
`

export const StyledInput = styled(Input)`
  margin-top: ${({ theme }) => theme.spacing.m}px;

  > input[type='date'] {
    text-transform: uppercase;
  }
`

export const StyledDropdown = styled(Dropdown)`
  margin-top: ${({ theme }) => theme.spacing.m}px;
`

export const StyledFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: ${({ theme }) => theme.spacing.s}px;
`

export const FieldsetContent = styled.div`
  padding-left: ${({ theme }) => theme.spacing.m}px;
  display: flex;
  flex-direction: column;
`

export const StyledLegend = styled.legend`
  padding-bottom: ${({ theme }) => theme.spacing.s}px;
  display: block;
  box-sizing: inherit;
  font-family: ${({ theme }) => theme.typography.fontFamilies.openSansRegular};
  font-size: ${({ theme }) => theme.typography.fontSizes.m}px;

  span {
    color: ${({ theme }) => theme.text.nonessential}
  }

  &.hasError,
  &.hasError > span {
    color: ${({ theme }) => theme.text.negative}
  }
`

export const UploadFileButton = styled(Label)`
  border: 1px solid ${({ theme }) => theme.colors.button.default.border};
  border-color: ${({ error, theme }) => (error ? theme.text.negative : theme.colors.button.default.border)};
  border-radius: ${({ theme }) => theme.radii.m}px;
  padding: ${({ theme }) => theme.spacing.s}px ${({ theme }) => theme.spacing.l}px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.typography.fontFamilies.stratum2Regular};
  color: ${({ theme }) => theme.colors.blue.default};
  font-size: ${({ theme }) => theme.typography.fontSizes.m}px;
  display: flex;
  flex-direction: row;
  align-items: bottom;
  justify-content: center;
  max-width: 180px;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing.m}px;

  svg {
    margin-right: ${({ theme }) => theme.spacing.s}px
  }
`

export const UploadFileButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing.s}px;
`

export const ContentContainer = styled(Container)`
  align-items: start;
  margin: 0 auto;
`

export const H3 = styled.h3`
  font-size: ${({ theme }) => theme.typography.fontSizes.l}px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.typography.fontFamilies.stratum2Regular};
`

export const Ol = styled.ol`
  font-size: ${({ theme }) => theme.typography.fontSizes.m}px;
  padding-inline-start: ${({ theme }) => theme.typography.fontSizes.m}px;
  font-family: ${({ theme }) => theme.typography.fontFamilies.openSansRegular};

  > li {
    padding: ${({ theme }) => theme.typography.fontSizes.s}px 0;
  }
`

export const Page = styled.div`
  background: ${({ theme }) => theme.background.level0};
  height: 100dvh;
`

export const StyledServerError = styled(ServerError)`
  text-align: center;
  font-family: ${({ theme }) => theme.typography.fontSizes.openSansRegular};
  font-size: ${({ theme }) => `${theme.typography.fontSizes.m}px`}
`

// AFCEP Invalid Event Page
export const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.m}px;
  text-align: center;
`

export const TitleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.l}px;
  text-align: center;

  h1 {
    font-size: ${({ theme }) => theme.typography.fontSizes.xxl}px;
  }
`

export const InvalidEventPage = styled(Page)`
  display: flex;
  align-items: center;
  justify-content: center;
`
