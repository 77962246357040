import * as React from 'react';

function AddUser({ size = 24, color, ...rest }) {
  return (
    <svg width={ size } height={ size } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clip-path="url(#clip0_816_5357)">
        <path d="M10.5 10.5C12.7091 10.5 14.5 8.70914 14.5 6.5C14.5 4.29086 12.7091 2.5 10.5 2.5C8.29086 2.5 6.5 4.29086 6.5 6.5C6.5 8.70914 8.29086 10.5 10.5 10.5Z" stroke={ color } stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
        <path d="M10.5 13.5C6.082 13.5 2.5 17.082 2.5 21.5C7.833 22.833 13.167 22.833 18.5 21.5C18.5 17.082 14.918 13.5 10.5 13.5Z" stroke={ color } stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
        <path d="M20.5 14.5V8.5" stroke={ color } stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
        <path d="M17.5 11.5H23.5" stroke={ color } stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      </g>
      <defs>
        <clipPath id="clip0_816_5357">
          <rect width={ size } height={ size } fill={ color } />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AddUser;
