/*eslint-disable*/
import * as React from 'react';

function AfBird(props) {
  return (
    <svg width={ props.size } height={ props.size } viewBox="0 0 36 32" { ...props }>
      <defs>
        <path
          d="M32.907 0L28.68 8.93l6.281-4.613L32.907 0zm2.461 5l-17.31 12.785 1.042 3.196 13.363-9.859L35.368 5zm-3.616 7.643l-12.399 9.15h5.394l4.099-3.029 2.906-6.121zM19.569 24.138c0 .516-.19.967-.559 1.328-.355.374-.799.567-1.332.567-.52 0-.964-.193-1.333-.567a1.844 1.844 0 01-.545-1.328c0-.54.19-.992.545-1.353a1.845 1.845 0 011.333-.554c.533 0 .977.193 1.332.554.368.361.559.812.559 1.353zm.825.877l1.282 4.047 4.111-3.029-3.388-2.5-2.005 1.482zM.419 4.317l6.269 4.614L2.475 0 .419 4.317zm2.474 6.805l13.389 9.859 1.04-3.196L0 5l2.893 6.122zm3.617 7.642l4.112 3.029h5.394l-12.399-9.15 2.893 6.121zm6.46 4.769l-3.388 2.5 4.124 3.029L15 25.015l-2.03-1.482zM17.678 32l3.388-2.487-3.388-2.5-3.376 2.5L17.678 32z"
          id="AF_Bird_svg__a"
        />
      </defs>
      <use fill={ props.color } xlinkHref="#AF_Bird_svg__a" fillRule="evenodd" />
    </svg>
  );
}

export default AfBird;
