import styled from '@emotion/styled'

export const ShareContentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.text.contrast};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
`
