import { useQuery } from 'react-query'
import config from '../../config'
import { useSelector } from 'react-redux'
import { selectAuthUserToken } from '../../redux/selectors/auth.selectors'
import axios from 'axios'

const afreServiceUrl = config.apiUrl

export const useRecruiterCounts = ({ start, end }, options) => {
  const authToken = useSelector(selectAuthUserToken)

  const queryOptions = { staleTime: 60 * 60 * 1000, ...options }
  return useQuery(['recruiterCounts', start, end], async () => {
    const url = `${afreServiceUrl}/reports/recruiterCounts`

    const response = await axios.post(url, {
      start,
      end,
    }, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
    return response.data.data
  }, queryOptions)
}
