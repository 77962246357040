import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { Header } from '../../components/Forms/Lead/Header'
import { Label } from '../../components/Forms/Lead/Label'
import { TwoColumnGrid } from '../../components/Forms/Lead/TwoColumnGrid'
import { HISPANIC_INDICATOR, RACE } from './constants'

const RaceField = styled.div`
  grid-column: 1;
  grid-row: 1;
  padding-top: ${({ theme }) => theme.spacing.s}px;
  padding-bottom: ${({ theme }) => theme.spacing.m}px;
  padding-right: ${({ theme }) => theme.spacing.s}px;
  @media (max-width: 767px) {
    grid-column: span 2;
    padding-right: 0;
    padding-top: ${({ theme }) => theme.spacing.s}px;
    padding-bottom: ${({ theme }) => theme.spacing.s}px;
  }
`
const EthnicityField = styled.div`
  grid-column: 2;
  grid-row: 1;
  padding-top: ${({ theme }) => theme.spacing.s}px;
  padding-bottom: ${({ theme }) => theme.spacing.m}px;
  padding-left: ${({ theme }) => theme.spacing.s}px;
  @media (max-width: 767px) {
    grid-column: span 2;
    grid-row: 2;
    padding-left: 0;
    padding-top: ${({ theme }) => theme.spacing.s}px;
    padding-bottom: ${({ theme }) => theme.spacing.m}px;
  }
`

const StyledDropdown = styled(Dropdown)`
  margin-top: ${({ theme }) => theme.spacing.m}px;
`

Demographics.propTypes = {
  errors: PropTypes.object,
  dispatch: PropTypes.func,
  dispatchError: PropTypes.func,
}

function Demographics({ dispatch, dispatchError, errors }) {
  const onFocus = name => () => {
    dispatchError({ ...errors, [name]: false })
  }
  const onChange = name => (_event, { value }) => {
    dispatch({ type: name, value })
  }
  
  return (
    <>
      <Header>Demographics</Header>
      <TwoColumnGrid>
        <RaceField>
          <Label htmlFor='race' error={ errors.race }>Race</Label>
          <StyledDropdown
            id='race'
            data-testid='race'
            fluid
            selection
            error={ errors.race }
            options={ RACE }
            onFocus={ onFocus('race') }
            onChange={ onChange('race') }
          />
        </RaceField>
        <EthnicityField>
          <Label htmlFor='hispanicIndicator' error={ errors.hispanicIndicator }>Ethnicity</Label>
          <StyledDropdown
            id='hispanicIndicator'
            data-testid='hispanicIndicator'
            fluid
            selection
            error={ errors.hispanicIndicator }
            options={ HISPANIC_INDICATOR }
            onFocus={ onFocus('hispanicIndicator') }
            onChange={ onChange('hispanicIndicator') }
          />
        </EthnicityField>
      </TwoColumnGrid>
    </>
  )
}

export default Demographics
