import React from 'react'
import Heading from '../../components/Page/Heading'

export const DeleteAccountPageContent = () => {
  return (
    <>
      <Heading headingLevel='h3'>Benefits of Aim High</Heading>
      <p>Whether you currently serve, are interested in joining or want to stay connected to friends and family who serve,
        let the Aim High app be your guide for U.S. Air Force news, events and career opportunities.
      </p>
      <Heading headingLevel='h4'>Explore the Possibilities</Heading>
      <p>Strengthen your knowledge by exploring careers, playing games and keeping up with Air Force news.</p>
      <ul>
        <li>Explore over 200 careers, learn what they entail and what’s required to pursue them.</li>
        <li>See if you have what it takes to complete missions like real Airmen in games that test relevant skills.</li>
        <li>Stay updated on Air Force news with videos and articles featuring Airmen and their initiatives.</li>
      </ul>
      <p>For a more personal and engaging Air Force experience, attend an event or connect with recruiters in your area.</p>
      <ul>
        <li>If you’d like to learn more about joining the Air Force, you can find a recruiter by searching a map of your area.</li>
        <li>From air shows to NASCAR races, keep track of exciting Air Force events across the country.</li>
        <li>Recruiters, you can use this app to reach out to recruits.</li>
      </ul>
      <p>Are you sure you still want to delete your account?</p>
    </>
  )
}
