import axios from 'axios'
import { axiosConfig, queryStringURL } from './util'

const getRequest = (url, queryParams = {}, authToken = undefined) => {
  return axios.get(queryStringURL(url, queryParams), axiosConfig(authToken))
}

const putRequest = (url, data, queryParams = {}, authToken = undefined) => {
  return axios.put(queryStringURL(url, queryParams), data, axiosConfig(authToken))
}

const postRequest = (url, data, queryParams = {}, authToken = undefined) => {
  return axios.post(queryStringURL(url, queryParams), data, axiosConfig(authToken))
}

const deleteRequest = (url, queryParams = {}, authToken = undefined) => {
  return axios.delete(queryStringURL(url, queryParams), axiosConfig(authToken))
}

export default {
  getRequest,
  putRequest,
  postRequest,
  deleteRequest,
}
