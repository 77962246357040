import React from 'react'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { createRoot } from 'react-dom/client'

const root = createRoot(document.getElementById('root'))
root.render(<App />)

serviceWorker.unregister()
