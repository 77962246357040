import React from 'react'
import theme, { LightPalette } from '../../../theme/'

export const PageDescription = ({ children, style, ...props }) => {
  const pageDescriptionStyle = {
    textAlign: 'left',
    color: LightPalette.text.default,
    paddingBottom: theme.spacing.m,
    ...style,
  }
  return <div style={ pageDescriptionStyle } { ...props }>{ children }</div>
}
