import React from 'react'
import styled from '@emotion/styled'

export const CardFooterWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 0 0 8px 8px;
  background: ${({ theme }) => theme.background.level0};
  padding: 0 40px 40px 40px;
`

const CardFooter = ({ ...props }) => {

  return (
    <CardFooterWrapper
      { ...props }
    />
  )
}

export default CardFooter
