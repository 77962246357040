import React from 'react'
import { Section, StyledServerError } from '../afcepLandingPage/AfcepLandingPage.styles'
import { FormItem } from '../../../forms/form.styles'
import { Field, formValueSelector } from 'redux-form'
import theme, { LightPalette } from '../../../theme'
import { required } from '../../../forms/validation'
import { AimHighButton } from '../../../components/Button/Button'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { AFCEP_FORM_NAME } from '../afcep.constants'
import { Checkbox } from '../../../components/Forms/Checkbox'

const getFormValue = formValueSelector(AFCEP_FORM_NAME)

const SERVER_ERROR_STYLES = {
  padding: theme.spacing.m,
  borderRadius: theme.radii.s,
  border: `1px solid ${LightPalette.system.error}`,
  marginBottom: theme.spacing.l,
  textAlign: 'center',
}
const SERVER_ERROR_MESSAGE = "We're sorry, but there was a problem processing your application. Please try again later."
const AIM_HIGH_BUTTON_STYLES = {
  width: '100%',
  maxWidth: '320px',
  marginBottom: theme.spacing.l,
}

export const Submit = ({ submitError }) => {
  const hasAcceptedInformation = useSelector(state => getFormValue(state, 'acceptInformation'))
  const submitDisabled = !hasAcceptedInformation

  return (
    <Section data-testid='submit-section'>
      <FormItem width={ { base: 'auto' } } style={ { paddingBottom: theme.spacing.m } }>
        <Field
          name='acceptInformation'
          component={ ({ input }) => {
            return (
              <Checkbox
                label='I verify that the information provided is accurate to the best of my knowledge.'
                data-testid='acceptInformation'
                style={ { paddingBottom: theme.spacing.m, fontSize: theme.typography.fontSizes.m } }
                checked={ input.name === 'acceptInformation' && input.value === true }
                onChange={ (_e, data) => input.onChange(!!data.checked) }
              />
            )
          } }
          validate={ required }
        />
      </FormItem>
      <FormItem>
        <AimHighButton
          data-testid='submit-button'
          primary
          size='large'
          type='submit'
          name='submit'
          disabled={ submitDisabled }
          style={ AIM_HIGH_BUTTON_STYLES }>
          Submit
        </AimHighButton>
      </FormItem>
      { submitError &&
        <StyledServerError
          error={ { message: SERVER_ERROR_MESSAGE } }
          style={ SERVER_ERROR_STYLES }
        />
      }
    </Section>
  )
}

Submit.propTypes = {
  submitError: PropTypes.any,
}
