import { ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '../components/Page/PageTitle/PageTitle'
import ReferralForm from '../forms/referral/ReferralForm'
import { NAV_ROUTES } from '../navigation/routes'
import { logPageView } from '../redux/sagas/amplitudeAnalytics.sagas'
import { DarkPalette } from '../theme'
import Colors from '../theme/colors'
import { useLocation } from 'react-router-dom'
import { selectGeneralInfo, getReferrerInfo } from '../redux/selectors/referral.selectors'

const Wrapper = styled.div(props => ({
  backgroundColor: props.theme.colors.page.background,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'auto',
}))

const ReferralPageContainer = styled.div(props => ({
  backgroundColor: props.theme.colors.page.background,
  color: Colors.white.default,
  minHeight: '100vh',
}))

const FormContainer = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

const ReferralPage = () => {
  const location = useLocation()
  const generalInfo = useSelector(selectGeneralInfo)
  const referrerInfo = getReferrerInfo(location)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(logPageView.request(NAV_ROUTES.REFERRAL_PAGE))
  }, [dispatch])

  return (
    <ThemeProvider theme={ DarkPalette }>
      <Wrapper>
        <ReferralPageContainer>
          <FormContainer>
            <PageTitle title='' siteName='' />
            <ReferralForm fromReferralPage initialValues={ { ...generalInfo, ...referrerInfo } } />
          </FormContainer>
        </ReferralPageContainer>
      </Wrapper>
    </ThemeProvider>
  )
}

export default ReferralPage
