import PropTypes from 'prop-types'
import * as React from 'react'

const SvgAfscManagement = props => (
  <svg
    id="AFSCManagement_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    height={ props.size }
    width={ props.size }
    viewBox="0 0 32 32"
    style={ {
      enableBackground: 'new 0 0 32 32',
    } }
    xmlSpace="preserve"
    role="img"
    { ...props }>
    <style>
      {
        '.AFSCManagement_svg__st0{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round}'
      }
    </style>
    <path
      className="AFSCManagement_svg__st0"
      d="M16 27v4M11.9 19.6l-8.7 4.3C1.9 24.6 1 26 1 27.5V31h30v-3.5c0-1.5-.9-2.9-2.2-3.6l-8.7-4.3"
    />
    <path
      className="AFSCManagement_svg__st0"
      d="M24 11c0 6.4-4.4 10-8 10s-8-3.6-8-10M6 11h20M7 11v-1c0-5 4-9 9-9s9 4 9 9v1M16 3V1v1"
    />
  </svg>
)
export default SvgAfscManagement

SvgAfscManagement.propTypes = {
  size: PropTypes.number,
}
