import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getReferrals, setSelectedReferral, toggleReferralModal, markReferralCompleted } from '../redux/sagas/referral.sagas'
import { selectReferrals } from '../redux/selectors/referral.selectors'
import styled from '@emotion/styled'
import 'semantic-ui-css/semantic.min.css'
import LogoutButton from '../navigation/LogoutButton'
import { Header, Table, Checkbox } from 'semantic-ui-react'
import ReferralModal from '../components/referral/referralModal'
import { getStandardAmericanDate } from '../api/date.util'

const ReferralListContainer = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  padding: `${props.theme.spacing.m}px ${props.theme.spacing.m}px`,
}))

const HeaderContainer = styled.div(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  maxWidth: 700,
  marginTop: `${props.theme.spacing.l}px`,
  marginBottom: `${props.theme.spacing.m}px`,
}))

class ReferralListPage extends Component {

  componentDidMount() {
    this.props.onGetReferrals()
  }

  handleRowSelected = referral => {
    const { onSetSelectedReferral, onToggleReferralModal } = this.props
    onSetSelectedReferral(referral)
    onToggleReferralModal()
  }

  handleMarkReferralCompleted = referral => {
    const { onMarkReferralCompleted } = this.props
    onMarkReferralCompleted(referral)
  }

  render() {

    const { referrals } = this.props
    return (
      <ReferralListContainer>
        <HeaderContainer>
          <Header as='h2' style={ { marginBottom: 0 } }>Referrals</Header>
          <LogoutButton />
        </HeaderContainer>
        <Table compact collapsing striped celled structered selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan='2' />
              <Table.HeaderCell rowSpan='2'>Fast</Table.HeaderCell>
              <Table.HeaderCell rowSpan='2'>First</Table.HeaderCell>
              <Table.HeaderCell rowSpan='2'>M.I.</Table.HeaderCell>
              <Table.HeaderCell rowSpan='2'>Last</Table.HeaderCell>
              <Table.HeaderCell rowSpan='2'>DoB</Table.HeaderCell>
              <Table.HeaderCell colSpan='3'>Contact</Table.HeaderCell>
              <Table.HeaderCell rowSpan='2'>Submitted</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>USAF</Table.HeaderCell>
              <Table.HeaderCell>ANG</Table.HeaderCell>
              <Table.HeaderCell>RES</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            { referrals.map(referral => {
              const submitted = getStandardAmericanDate(referral.createdAt)
              const fastTrack = referral.fastTrack ? 'Yes' : 'No'
              const dateOfBirth = getStandardAmericanDate(referral.dateOfBirth)
              const contactByAirForce = referral.contactByAirForce ? 'Yes' : 'No'
              const contactByAirNationalGuard = referral.contactByAirNationalGuard ? 'Yes' : 'No'
              const contactByTheReserve = referral.contactByTheReserve ? 'Yes' : 'No'

              return (
                <Table.Row key={ referral.id } onClick={ () => this.handleRowSelected(referral) }>
                  <Table.Cell onClick={ e => e.stopPropagation() }>
                    <Checkbox
                      toggle
                      color='green'
                      onClick={ () => this.handleMarkReferralCompleted(referral) }
                      checked={ referral.completed }
                    />
                  </Table.Cell>
                  <Table.Cell
                    negative={ !referral.fastTrack }
                    positive={ referral.fastTrack }>
                    { fastTrack }
                  </Table.Cell>
                  <Table.Cell>{ referral.firstName }</Table.Cell>
                  <Table.Cell>{ referral.middleName }</Table.Cell>
                  <Table.Cell>{ referral.lastName }</Table.Cell>
                  <Table.Cell>{ dateOfBirth }</Table.Cell>
                  <Table.Cell
                    negative={ !referral.contactByAirForce }
                    positive={ referral.contactByAirForce }>
                    { contactByAirForce }
                  </Table.Cell>
                  <Table.Cell
                    negative={ !referral.contactByAirNationalGuard }
                    positive={ referral.contactByAirNationalGuard }>
                    { contactByAirNationalGuard }
                  </Table.Cell>
                  <Table.Cell
                    negative={ !referral.contactByTheReserve }
                    positive={ referral.contactByTheReserve }>{ contactByTheReserve }
                  </Table.Cell>
                  <Table.Cell>{ submitted }</Table.Cell>
                </Table.Row>
              )
            }) }

          </Table.Body>
        </Table>

        <ReferralModal />
      </ReferralListContainer>
    )
  }
}

ReferralListPage.propTypes = {
  referrals: PropTypes.array,
  onGetReferrals: PropTypes.func,
  onSetSelectedReferral: PropTypes.func,
  onToggleReferralModal: PropTypes.func,
  onMarkReferralCompleted: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    referrals: selectReferrals(state),
  }
}

const mapActionsToProps = dispatch => {
  return {
    onGetReferrals: () => dispatch(getReferrals.request()),
    onSetSelectedReferral: referral => dispatch(setSelectedReferral.request(referral)),
    onToggleReferralModal: () => dispatch(toggleReferralModal.request()),
    onMarkReferralCompleted: referral => dispatch(markReferralCompleted.request(referral)),
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReferralListPage)

