import { ThemeProvider } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { Dimmer, Icon, Loader } from 'semantic-ui-react'
import NavLink from '../../../components/Navigation/NavLink'
import { LargeFieldLabel } from '../../../forms/form.styles'
import { NAV_ROUTES } from '../../../navigation/routes'
import { selectAuthUserToken } from '../../../redux/selectors/auth.selectors'
import { LightPalette } from '../../../theme'
import spacing from '../../../theme/spacing'
import { findUserOrders } from '../../reactQueries/opaQueries'
import { ActionButtonContainer, ActionButtonDiv, ActionIconDiv, NoOrdersDiv, OrderContents } from './OrdersDocument.styles'

const downloadFile = async (fileData, ssnInput) => {
  const last4Digits = ssnInput.slice(-4)
  const maskedSocial = last4Digits.padStart(ssnInput.length, '#')

  const data64Response = await fetch(fileData)
  const dataBlob = await data64Response.blob()

  const a = document.createElement('a')
  a.style = 'display: none'
  const url = window.URL.createObjectURL(dataBlob)
  a.href = url
  const date = new Date()
  a.download = `${date.toISOString().split('T')[0]}_${maskedSocial}.pdf`
  a.click()
  window.URL.revokeObjectURL(url)
}

const OrdersDocument = props => {
  const { Document, Page } = require('react-pdf/dist/esm/entry.webpack')
  const authToken = useSelector(selectAuthUserToken)
  const [numPages, setNumPages] = useState(null)
  const queryKey = ['userOrders', props.ssnInput]
  const orderQuery = useQuery(queryKey, async () => {
    return await findUserOrders(props.ssnInput, authToken)
  }, { retry: false, cacheTime: 0 })

  const showSpinner = orderQuery.isLoading
  const onDocumentLoadSuccess = ({ _pdfInfo }) => {
    setNumPages(_pdfInfo.numPages)
  }

  const renderActionButtons = (downloadFunction, fileData) => {
    return (
      <ActionButtonContainer>
        <ActionButtonDiv className='downloadButton'>
          <ActionIconDiv>
            <Icon data-testid='download-button' name='download' size='big' link onClick={ () => downloadFunction(fileData, props.ssnInput) } />
          </ActionIconDiv>
          Download
        </ActionButtonDiv>
        <ActionButtonDiv className='closeButton' data-testid='close-button'>
          <NavLink to={ NAV_ROUTES.FIND_ORDERS_PAGE } data-testid='close-button'>
            <Icon name='x' size='big' color='red' />
          </NavLink>
          Close
        </ActionButtonDiv>
      </ActionButtonContainer>
    )
  }

  const renderDocument = () => {
    return (
      <div className='pdf-document' data-testid='successful-pdf-render'>
        <Document file={ orderQuery.data } onLoadSuccess={ onDocumentLoadSuccess } data-testid='orders-document-element'>
          { Array.from(new Array(numPages), (_el, index) => (
            <Page
              key={ `page_${index + 1}` }
              pageNumber={ index + 1 }
            />
          )) }
        </Document>
      </div>
    )
  }
  const renderNoOrders = () => {
    return (
      <NoOrdersDiv data-testid='no-orders'>
        <Icon data-testid='red-times-circle' name='times circle' size='big' color='red' />
        <LargeFieldLabel data-testid='no-orders-found-text' style={ { marginBottom: spacing.m, fontWeight: 'bold' } }>
          No Orders Found
        </LargeFieldLabel>
        <LargeFieldLabel data-testid='no-ssn-match-to-order-text'>
          The social security number was not matched to an order.
        </LargeFieldLabel>
        <LargeFieldLabel data-testid='please-try-again-text'>
          Please try again.
        </LargeFieldLabel>
      </NoOrdersDiv>
    )
  }

  const renderContent = () => {
    if (!orderQuery.isLoading) {
      return orderQuery.isSuccess ? renderDocument() : renderNoOrders()
    }
  }

  return (
    <ThemeProvider theme={ LightPalette }>
      <Dimmer.Dimmable dimmed={ showSpinner }>
        <Dimmer active={ showSpinner } page>
          <Loader>Loading</Loader>
        </Dimmer>
        <OrderContents>
          { renderContent() }
          { orderQuery.data && renderActionButtons(downloadFile, orderQuery.data) }
        </OrderContents>
      </Dimmer.Dimmable>
    </ThemeProvider>
  )
}

OrdersDocument.propTypes = {
  ssnInput: PropTypes.any,
}

export default OrdersDocument
