import React from 'react'
import { withRouter } from 'react-router-dom'
import { Header, Grid, Container } from 'semantic-ui-react'
import LogoutButton from '../../navigation/LogoutButton'
import SquadronForm from '../../forms/squadron/squadron.form'
import { NAV_ROUTES } from '../../navigation/routes'
import AHSidebar from '../../components/Navigation/NavSidebar'

const SquadronsPage = () => (
  <>
    <AHSidebar currentRoute={ NAV_ROUTES.SQUADRONS_PAGE } />
    <Container>
      <Header as='h1'>
        <Grid>
          <Grid.Row columns='equal'>
            <Grid.Column data-testid='squadrons-page-title'>Squadrons Page</Grid.Column>
            <Grid.Column textAlign='right'><LogoutButton /></Grid.Column>
          </Grid.Row>
        </Grid>
      </Header>
      <SquadronForm />
    </Container>
  </>
)

SquadronsPage.propTypes = {
}

export default withRouter(SquadronsPage)
