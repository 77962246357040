import PropTypes from 'prop-types'
import * as React from 'react'

function Download(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={props.size} width={props.size} viewBox="0 0 64 64">
        <title>{props.title}</title>
        <g fill={props.color} class="nc-icon-wrapper">
            <path d="M50,23H40a1,1,0,0,0,0,2H50a3.957,3.957,0,0,1,4,4V54a3.957,3.957,0,0,1-4,4H14a3.957,3.957,0,0,1-4-4V29a3.957,3.957,0,0,1,4-4H24a1,1,0,0,0,0-2H14a5.937,5.937,0,0,0-6,6V54a5.937,5.937,0,0,0,6,6H50a5.937,5.937,0,0,0,6-6V29A5.937,5.937,0,0,0,50,23Z" fill={props.color}>
                </path><path data-color="color-2" d="M21,30a1,1,0,0,0-.807,1.591l11,15a1,1,0,0,0,1.614,0l11-15A1,1,0,0,0,43,30H35V5a3,3,0,0,0-6,0V30Z">
                    </path>
                    </g>
    </svg>
  )
}

Download.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

export default Download
