import { withOpacity } from '@aimhigh/theme'
import styled from '@emotion/styled'
import React from 'react'

const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 150px;

  text-align: center;

  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: 1px dashed ${({ theme }) => theme.colors.text.nonessential}; /* Need a background-image or other CSS trick for long dashes */

  background: ${({ theme }) => theme.colors.text.default};

  cursor: pointer;

  > h3 {
    font-family: ${({ theme }) => theme.fontFamilies.openSans};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    color: ${({ theme }) => theme.colors.text.white};

    margin: 0;
  }

  > p {
    font-family: ${({ theme }) => theme.fontFamilies.openSans};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    color: ${({ theme }) => withOpacity(theme.colors.text.white, 0.5)};

    margin: 0;
  }
`

const DocumentUpload = ({ title, description }) => {
  const handleClick = () => {
    // eslint-disable-next-line no-alert
    alert('Start upload')
  }

  return (
    <UploadWrapper onClick={ handleClick }>
      <div>Icon...</div>
      <h3>{ title }</h3>
      <p>{ description }</p>
    </UploadWrapper>
  )
}

export default DocumentUpload
