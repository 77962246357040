import { createSelector } from 'reselect'

export const selectCommon = ({ common }) => common

export const selectIsLoading = createSelector(
  [selectCommon],
  common => !!common.globalLoads.length
)

export const selectNotifications = createSelector(
  [state => state.common.notifications],
  notifications => notifications
    .asMutable({ deep: true })
    .slice(0, 10)
    .reverse()
    .map(notification => ({
      key: `${notification.id}-${notification.type}`,
      ...notification,
    }))
)

export function createErrorSelector(type) {
  return createSelector([selectCommon],
    common => common.errors[type]
  )
}

export function createIsLoadingSelector(type) {
  return createSelector([selectCommon],
    common => common.loads[type]
  )
}

export function createTrackedLoadsSelector(...types) {
  return createSelector([selectCommon],
    common => types.reduce((loads, type) => loads.concat(common.trackedLoads[type]), [])
  )
}
