import styled from '@emotion/styled'

export const HorizontalLine = styled.div`
  display: block;
  height: 1px;
  margin-top: ${({ theme }) => theme.spacing.m}px;
  margin-bottom: ${({ theme }) => theme.spacing.m}px;
  border-top: 1px solid ${({ theme }) => theme.background.level2};
`

