import PropTypes from 'prop-types'
import React from 'react'
import { Field } from '../../components/Forms/Lead/Field'
import { Header } from '../../components/Forms/Lead/Header'
import RadioButton from './RadioButton'

Other.propTypes = {
  errors: PropTypes.object,
  dispatch: PropTypes.func,
  dispatchError: PropTypes.func,
}

function Other({ dispatch, dispatchError, errors }) {
  const onChange = name => value => {
    dispatch({ type: name, value })
    if (value !== undefined && value !== null) {
      dispatchError({ ...errors, [name]: false })
    }
  }
  return (
    <>
      <Header>Other</Header>
      <Field>
        <RadioButton
          group='tattooPiercingsLocationMeaning'
          testid='tattooPiercingsLocationMeaning'
          label='Do you have any tattoos on your hands or above the collarbone?'
          error={ errors.tattooPiercingsLocationMeaning }
          onChange={ onChange('tattooPiercingsLocationMeaning') }
        />
      </Field>
    </>
  )
}

export default Other
