import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Header, Table, Checkbox, Form, Dimmer, Loader, Grid } from 'semantic-ui-react'
import LogoutButton from '../../navigation/LogoutButton'
import { fetchUsers, updateUser, fetchRoles } from '../../redux/sagas/user.sagas'
import { userAccountStatuses } from '../../redux/constants/user.constants'
import {
  selectUsers,
  selectUpdateUserLoads,
  selectLastUserKey,
  selectRoles,
  selectSearchingUsers,
  selectFetchingUsers
} from '../../redux/selectors/user.selectors'
import { Page, ConstrainedCell } from './users.page.styles'
import UsersSearchForm from './users.search.form'
import AHSidebar from '../../components/Navigation/NavSidebar'
import { NAV_ROUTES } from '../../navigation/routes'
import { selectSquadrons, selectFlights } from '../../redux/selectors/squadron.selectors'
import SquadronInfoForm from '../../forms/squadron/squadron.info.form'
import { fetchSquadrons } from '../../redux/sagas/squadron.sagas'
import UsersRolesSelector from './UsersRolesSelector'
import { selectAuthUserRoles } from '../../redux/selectors/auth.selectors'
import { DodIdentificationInput } from './DodIdentificationInput'
import { getStandardAmericanDate } from '../../api/date.util'

class UsersPage extends React.PureComponent {
  state = { recruiterCodes: {} }
  updateTypes = {
    STATUS: 'STATUS',
    RECRUITER_CODE: 'RECRUITER_CODE',
    DOD_IDENTIFICATION: 'DOD_IDENTIFICATION',
    ROLES: 'ROLES',
  }

  loadId = (id, type) => `${id}_${type}`
  isLoading = (id, type) => this.props.updateUserLoads.includes(this.loadId(id, type))

  componentDidMount() {
    this.props.fetchUsers()
    this.props.fetchRoles()
    this.props.fetchSquadrons()

    const params = new URLSearchParams(this.props.location.search)
    const email = params.get('email')
    if (email) {
      this.props.fetchUsers(email)
    }
  }

  toggleStatus = event => {
    const id = event.target.parentNode.dataset.id
    const { status: currentStatus, firstName, lastName } = this.props.users.find(({ id: userId }) => userId === id)
    const status = currentStatus === userAccountStatuses.DISABLED ?
      userAccountStatuses.ACTIVE :
      userAccountStatuses.DISABLED
    this.props.updateUser(
      { id, status, firstName, lastName },
      { loadId: this.loadId(id, this.updateTypes.STATUS) }
    )
  }

  updateRecruiterCode = id => {
    const recruiterCode = this.state.recruiterCodes[id]
    const { firstName, lastName, recruiterCode: currentRecruiterCode } = this.props.users.find(({ id: userId }) => userId === id)
    if (currentRecruiterCode !== recruiterCode && recruiterCode !== undefined) {
      this.props.updateUser(
        { id, recruiterCode, firstName, lastName },
        { loadId: this.loadId(id, this.updateTypes.RECRUITER_CODE) }
      )
    }
  }

  setRecruiterCode = (id, event) => {
    this.setState({
      recruiterCodes: Object.assign(this.state.recruiterCodes, {
        [id]: event.target.value,
      }),
    })
  }

  updateDodIdentification = (id, dodIdentification) => {
    const { firstName, lastName } = this.props.users.find(({ id: userId }) => userId === id)

    this.props.updateUser(
      { id, dodIdentification, firstName, lastName },
      { loadId: this.loadId(id, this.updateTypes.DOD_IDENTIFICATION) }
    )
  }

  renderSquadronInfoModal = id => {
    const { squadrons, flights } = this.props
    const userInfo = this.props.users.find(user => user.id === id)
    return (
      <SquadronInfoForm
        form={ `squadron-info-${id}` }
        squadrons={ squadrons }
        flights={ flights }
        userInfo={ userInfo }
      />
    )
  }

  renderRoleSelection = (userRoles, id) => (
    <UsersRolesSelector userRoles={ userRoles } roles={ this.props.roles } id={ id } />
  )

  render() {
    const { users, lastUserKey, isSearching } = this.props
    return (
      <>
        <AHSidebar currentRoute={ NAV_ROUTES.USERS_PAGE } />
        <Page>
          <Dimmer.Dimmable dimmed={ isSearching }>
            <Dimmer active={ isSearching } page>
              <Loader>Loading</Loader>
            </Dimmer>
            <div style={ { margin: 20 } }>
              <Header as='h1'>
                <Grid>
                  <Grid.Row columns='equal'>
                    <Grid.Column data-testid='users-header'>Users</Grid.Column>
                    <Grid.Column textAlign='right'><LogoutButton /></Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns='equal'>
                    <Grid.Column textAlign='center'>
                      <UsersSearchForm onSubmit={ values => this.props.fetchUsers(values.emailText) } />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Header>
              <Table compact striped singleLine>
                <Table.Header data-testid='table-header'>
                  <Table.Row data-testid='table-row'>
                    <Table.HeaderCell data-testid='active-table-header-cell'>Active</Table.HeaderCell>
                    <Table.HeaderCell data-testid='email-table-header-cell'>Email</Table.HeaderCell>
                    <Table.HeaderCell data-testid='first-table-header-cell'>First</Table.HeaderCell>
                    <Table.HeaderCell data-testid='last-table-header-cell'>Last</Table.HeaderCell>
                    <Table.HeaderCell data-testid='creation-date-table-header-cell'>Creation Date</Table.HeaderCell>
                    <Table.HeaderCell data-testid='roles-table-header-cell'>Roles</Table.HeaderCell>
                    <Table.HeaderCell data-testid='squadron-flight-info-table-header-cell'>Squadron & Flight Info</Table.HeaderCell>
                    <Table.HeaderCell data-testid='afriss-tf-billetid-table-header-cell'>AFRISS-TF BilletID</Table.HeaderCell>
                    <Table.HeaderCell data-testid='dod-id-table-header-cell'>DoD ID</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body data-testid='table-body'>
                  { users.map(({ id,
                    status,
                    email,
                    firstName,
                    lastName,
                    createdAt,
                    roles,
                    recruiterCode,
                    squadronName,
                    flightName,
                    dodIdentification,
                  }) => {
                    createdAt = getStandardAmericanDate(createdAt)
                    const loadingStatus = this.isLoading(id, this.updateTypes.STATUS)
                    const loadingRoles = this.isLoading(id, this.updateTypes.ROLES)
                    const loadingRecruiter = this.isLoading(id, this.updateTypes.RECRUITER_CODE)
                    const loadingDodi = this.isLoading(id, this.updateTypes.DOD_IDENTIFICATION)

                    return (
                      <Table.Row key={ id }>
                        <Dimmer.Dimmable as={ Table.Cell } dimmed={ loadingStatus } width={ 1 }>
                          <Dimmer active={ loadingStatus } inverted>
                            <Loader size='tiny' />
                          </Dimmer>
                          <Checkbox
                            toggle
                            color='green'
                            data-testid='active-toggle'
                            onClick={ this.toggleStatus }
                            data-id={ id }
                            checked={ status !== userAccountStatuses.DISABLED }
                          />
                        </Dimmer.Dimmable>
                        <Table.Cell data-testid='email-table-cell'><ConstrainedCell>{ email }</ConstrainedCell></Table.Cell>
                        <Table.Cell data-testid='first-table-cell'><ConstrainedCell>{ firstName }</ConstrainedCell></Table.Cell>
                        <Table.Cell data-testid='last-table-cell'><ConstrainedCell>{ lastName }</ConstrainedCell></Table.Cell>
                        <Table.Cell data-testid='creation-date-table-cell'>{ createdAt }</Table.Cell>
                        <Dimmer.Dimmable as={ Table.Cell } dimmed={ loadingRoles } data-testid='roles-table-cell'>
                          <Dimmer active={ loadingRoles } inverted>
                            <Loader size='tiny' />
                          </Dimmer>
                          { this.renderRoleSelection(roles, id) }
                        </Dimmer.Dimmable>
                        <Table.Cell data-testid='squadron-flight-table-cell'>
                          <p data-testid='squadron-table-cell'>Squadron: { squadronName || 'None' }</p>
                          <p data-testid='flight-table-cell'>Flight: { flightName || 'None' }</p>
                          { this.renderSquadronInfoModal(id) }
                        </Table.Cell>
                        <Dimmer.Dimmable as={ Table.Cell } dimmed={ loadingRecruiter } data-testid='afriss-tf-billet-id-table-cell'>
                          <Dimmer active={ loadingRecruiter } inverted>
                            <Loader size='tiny' />
                          </Dimmer>
                          <Form onSubmit={ this.updateRecruiterCode.bind(this, id) }>
                            <Form.Field>
                              <Form.Input
                                onChange={ this.setRecruiterCode.bind(this, id) }
                                onBlur={ this.updateRecruiterCode.bind(this, id) }
                                defaultValue={ recruiterCode }
                              />
                            </Form.Field>
                          </Form>
                        </Dimmer.Dimmable>
                        <Dimmer.Dimmable as={ Table.Cell } dimmed={ loadingDodi } data-testid='dod-id-table-cell'>
                          <Dimmer active={ loadingDodi } inverted>
                            <Loader size='tiny' />
                          </Dimmer>
                          <DodIdentificationInput onChange={ this.updateDodIdentification } dodIdentification={ dodIdentification } userId={ id } />
                        </Dimmer.Dimmable>
                      </Table.Row>
                    )
                  }) }
                </Table.Body>
              </Table>
              { lastUserKey && <Button fluid onClick={ () => this.props.fetchUsers() }>Load More Users</Button> }
            </div>
          </Dimmer.Dimmable>
        </Page>
      </>
    )
  }
}

UsersPage.propTypes = {
  fetchUsers: PropTypes.func,
  fetchRoles: PropTypes.func,
  updateUser: PropTypes.func,
  updateUserLoads: PropTypes.array,
  users: PropTypes.array,
  roles: PropTypes.object,
  lastUserKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  isSearching: PropTypes.bool,
  squadrons: PropTypes.array,
  flights: PropTypes.array,
  fetchSquadrons: PropTypes.func,
  location: PropTypes.object,
}

function bindAction(dispatch) {
  return {
    fetchUsers: email => dispatch(fetchUsers.request({ email })),
    fetchRoles: () => dispatch(fetchRoles.request()),
    updateUser: (user, meta) => dispatch(updateUser.request({ user }, meta)),
    fetchSquadrons: () => dispatch(fetchSquadrons.request()),
  }
}

const mapStateToProps = state => ({
  users: selectUsers(state),
  roles: selectRoles(state),
  squadrons: selectSquadrons(state),
  flights: selectFlights(state),
  updateUserLoads: selectUpdateUserLoads(state),
  lastUserKey: selectLastUserKey(state),
  isSearching: selectSearchingUsers(state) || !!selectFetchingUsers(state),
  loggedInUserRoles: selectAuthUserRoles(state),
})

export default connect(mapStateToProps, bindAction)(withRouter(UsersPage))

