import { withOpacity, theme as ahaTheme } from '@aimhigh/theme'
import styled from '@emotion/styled'
import React from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const PROGRESS_CIRCLE_STROKE_URL = 'progress-stroke'

export const ProgressCircle = styled(CircularProgressbar)`
  width: ${({ theme, $size }) => ($size ?? 40) + theme.spacing.m}px;
  height: ${({ theme, $size }) => ($size ?? 40) + theme.spacing.m}px;

  && {
    overflow: visible;
  }

  .CircularProgressbar-trail {
    stroke-width: ${({ theme }) => theme.spacing.s}px;
    stroke: ${({ theme }) => withOpacity(theme.colors.background.level3, 0.25)};
  }

  .CircularProgressbar-path {
    stroke-width: ${({ theme }) => theme.spacing.xm}px;
    stroke: url(#${PROGRESS_CIRCLE_STROKE_URL});
  }
`

const LineOfficerProgressCircle = ({ percentage, size, className, style, ...rest }) => (
  <div className={ className } style={ style }>
    <svg style={ { height: 0, width: 0 } }>
      <defs>
        <linearGradient id={ PROGRESS_CIRCLE_STROKE_URL }>
          <stop offset='5%' stopColor={ ahaTheme.colors.system.actionLight } />
          <stop offset='95%' stopColor={ ahaTheme.colors.system.pink } />
        </linearGradient>
      </defs>
    </svg>
    <ProgressCircle
      $size={ size }
      value={ percentage * 100 }
      styles={ buildStyles({ rotation: 0.5 }) }
      { ...rest }
    />
  </div>
)

export default LineOfficerProgressCircle
