// eslint-disable-next-line max-len
export const SELF_FILLED_REFERRAL_CERTIFICATION_TEXT = 'I verify that the information provided is accurate to the best of my knowledge.'
export const REFER_A_FRIEND_REFERRAL_CERTIFICATION_TEXT = 'I certify that the applicant information provided was not obtained from a military' +
  ' recruiter or anyone affiliated with the Air Force Recruiting Service.'

export const HEIGHT_FEET_OPTIONS = [
  {
    value: 4,
    text: `4 ft`,
    key: `4 ft`,
  },
  {
    value: 5,
    text: `5 ft`,
    key: `5 ft`,
  },
  {
    value: 6,
    text: `6 ft`,
    key: `6 ft`,
  },
  {
    value: 7,
    text: `7 ft`,
    key: `7 ft`,
  },
  {
    value: 8,
    text: `8 ft`,
    key: `8 ft`,
  },
]

export const HEIGHT_FEET_OPTIONS_WITHOUT_UNITS = HEIGHT_FEET_OPTIONS.map(option => ({ ...option, text: option.value.toString() }))

export const HEIGHT_INCHES_OPTIONS = [
  {
    value: 0,
    text: '0 in',
    key: '0 in',
  },
  {
    value: 1,
    text: '1 in',
    key: '1 in',
  },
  {
    value: 2,
    text: '2 in',
    key: '2 in',
  },
  {
    value: 3,
    text: '3 in',
    key: '3 in',
  },
  {
    value: 4,
    text: '4 in',
    key: '4 in',
  },
  {
    value: 5,
    text: '5 in',
    key: '5 in',
  },
  {
    value: 6,
    text: '6 in',
    key: '6 in',
  },
  {
    value: 7,
    text: '7 in',
    key: '7 in',
  },
  {
    value: 8,
    text: '8 in',
    key: '8 in',
  },
  {
    value: 9,
    text: '9 in',
    key: '9 in',
  },
  {
    value: 10,
    text: '10 in',
    key: '10 in',
  },
  {
    value: 11,
    text: '11 in',
    key: '11 in',
  },
]

export const HEIGHT_INCHES_OPTIONS_WITHOUT_UNITS = HEIGHT_INCHES_OPTIONS.map(option => ({ ...option, text: option.value.toString() }))

const generateWeightOptions = () => {
  const arr = []
  arr.push({ value: 0, key: '< 91 lbs', text: '< 91 lbs' })
  for (let i = 91; i <= 400; i++) {
    arr.push({
      value: i,
      text: `${i} lbs`,
      key: `${i} lbs`,
    })
  }
  return arr
}

export const WEIGHT_OPTIONS = generateWeightOptions()

export const WEIGHT_OPTIONS_WITHOUT_UNITS = WEIGHT_OPTIONS.map(opt => ({ ...opt, text: opt.value.toString() }))
