import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import afreService from '../../api/afre.service'
import { WAS_REFERRED, stripSpecialCharacters } from '../../forms/validation'
import { CATEGORY } from '../constants/analytics.constants'
import {
  CREATE_AFRISS_LEAD_ID, CREATE_REFERRAL_SAGA_ID, GET_LEAD_SAGA_ID, GET_REFERRALS_SAGA_ID
} from '../constants/referral.constants'
import { selectNextReferral, selectPreviousReferral } from '../selectors/referral.selectors'
import { selectSingleUser } from '../selectors/user.selectors'
import { logEventParams } from './amplitudeAnalytics.sagas'
import AsyncSaga from './util/AsyncSaga'
import SafeSaga from './util/SafeSaga'

const formatPhoneNumber = phoneNumberString => {
  if (!phoneNumberString) {
    return phoneNumberString
  }
  const cleaned = (`${phoneNumberString}`).replace(/\D/gu, '')
  const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/u)
  if (match) {
    return [match[1], '-', match[2], '-', match[3]].join('')
  }
  return null
}

export const createAfrissLeadSaga = new AsyncSaga(CREATE_AFRISS_LEAD_ID, function* createAfrissLead(action) {
  const { leadId,
    recruiterId,
    afrissEventId,
    generalInfo,
    fromRecruiterProfilePage = false,
    fromReferralPage = false,
    recruiterComponent,
    recruiterLeadType,
    qrSort } = action.params

  if (generalInfo) {
    let leadToSend = {
      ...generalInfo,
      email: generalInfo?.email?.toLowerCase(),
      recruiterComponent,
      recruiterLeadType,
      recruiterId,
      userId: recruiterId,
      qrSort,
      afrissEventId,
    }
    const height = Number(generalInfo.heightFeet) * 12 + (isNaN(generalInfo.heightInches) ? 0 : Number(generalInfo.heightInches))
    if (height) {
      leadToSend = { ...leadToSend, height }
    }

    const dateOfBirth = Date.parse(generalInfo.dateOfBirth)
    if (dateOfBirth) {
      leadToSend = { ...leadToSend, dateOfBirth }
    }

    if (qrSort) {
      yield call(afreService.submitLead, leadToSend)
    } else {
      yield call(afreService.createAlternateLead, leadId, recruiterId, afrissEventId, leadToSend)
    }
    yield put(createAfrissLeadSaga.success(action.params))
    const logInformation = {
      fromRecruiterProfilePage,
      fromReferralPage,
      ...leadToSend,
    }
    delete logInformation.dateOfBirth
    yield put(logEventParams.request({ type: CATEGORY.REFERRAL, label: CREATE_AFRISS_LEAD_ID, value: logInformation }))
  } else {
    yield put(createAfrissLeadSaga.error(new Error('Error submitting lead. Lead info is empty.')))
  }
}).catch(function* submitReferralFormCatch(error) {
  if (error && error.response && error.response.status === 409) {
    const user = yield select(selectSingleUser)
    let message = 'This email address has already been submitted. If you need assistance, please contact your recruiter.'
    if (!!user && !!user.email && !!user.phoneNumber) {
      // eslint-disable-next-line max-len
      message = `This email address has already been submitted. If you need assistance, please contact your recruiter at ${user.email} or ${formatPhoneNumber(user.phoneNumber)}.`
    } else if (!!user && !!user.email) {
      message = `This email address has already been submitted. If you need assistance, please contact your recruiter at ${user.email}.`
    }
    yield put(createAfrissLeadSaga.error(new Error(message)))
  } else {
    yield put(createAfrissLeadSaga.error(new Error('Error submitting lead')))
  }
})

export const createReferral = new AsyncSaga(CREATE_REFERRAL_SAGA_ID, function* createReferralSaga(action) {
  const {
    generalInfo,
    education,
    leadId,
    previousService,
    militaryLikelihood,
    referAFriend,
    referrerInfo,
    unknownReferrer,
    referrerId,
    qrSort,
  } = action.params
  let referralToSave = {
    leadId,
    qrSort,
    ...generalInfo,
    ...education,
    ...previousService,
    ...militaryLikelihood,
    ...referrerInfo,
    height: Number(generalInfo.heightFeet) * 12 + Number(generalInfo.heightInches),
    email: generalInfo?.email?.toLowerCase(),
    firstName: stripSpecialCharacters(generalInfo.firstName),
    lastName: stripSpecialCharacters(generalInfo.lastName),
    referrerEmailAddress: referrerInfo?.referrerEmailAddress?.toLowerCase(),
    referrerFirstName: stripSpecialCharacters(referrerInfo?.referrerFirstName),
    referrerLastName: stripSpecialCharacters(referrerInfo?.referrerLastName),
    referrerCssEmail: referrerInfo?.referrerCssEmail?.toLowerCase(),
  }

  const notes = generalInfo.notes ? `${WAS_REFERRED}${generalInfo.notes}` : WAS_REFERRED

  if (referAFriend) {
    referralToSave = {
      ...referralToSave,
      notes,
      referAFriend: true,
    }
  } else if (referrerId) {
    referralToSave = {
      ...referralToSave,
      referrerId,
      notes,
    }
  }

  delete referralToSave.password

  const dateOfBirth = Date.parse(generalInfo.dateOfBirth)
  if (dateOfBirth) {
    referralToSave.dateOfBirth = dateOfBirth
  }
  if (generalInfo.weight) {
    referralToSave.weight = Number(generalInfo.weight)
  }
  const createReferralResponse = yield call(afreService.createReferral, referralToSave)

  yield put(createReferral.success({ referral: createReferralResponse.data.data }))

  const logInformation = {
    referAFriend,
    referrerId,
    unknownReferrer,
  }
  yield put(logEventParams.request({ type: CATEGORY.REFERRAL, label: CREATE_REFERRAL_SAGA_ID, value: logInformation }))
}).catch(function* createReferralCatch(error) {
  yield put(createReferral.error(error, false))
})

export const getLead = new AsyncSaga(GET_LEAD_SAGA_ID, function* getLeadSaga(action) {
  const id = action.params.id
  const getLeadResponse = yield call(afreService.getLead, id)

  yield put(getLead.success(getLeadResponse.data.data))
})
  .catch(function* getLeadCatch(error) {
    yield put(getLead.error(error, false))
  })

export const getReferrals = new AsyncSaga(GET_REFERRALS_SAGA_ID, function* getReferralsSaga() {
  const response = yield call(afreService.getReferrals, {})
  yield put(getReferrals.success(response.data.data))
})

export const MARK_REFERRAL_COMPLETED = 'MARK_REFERRAL_COMPLETED_SAGA_ID'
export const markReferralCompleted = new AsyncSaga(MARK_REFERRAL_COMPLETED, function* markReferralCompletedSaga(action) {
  const { id } = action.params
  const completed = !action.params.completed
  const response = yield call(afreService.markReferralCompleted, id, { completed })

  yield put(getReferrals.request())
  yield put(markReferralCompleted.success(response.data.data))
}).catch(function* markReferralCompletedCatch(error) {
  yield put(markReferralCompleted.error(error, false))
})

export const toggleReferralModal = new SafeSaga('TOGGLE_IS_REFERRAL_MODAL_OPEN', function* toggleReferralModalSaga() {
  yield null
})

export const setSelectedReferral = new SafeSaga('SET_SELECTED_REFERRAL', function* setSelectedReferralSaga() {
  yield null
})

export const setPreviousReferral = new SafeSaga('SET_PREVIOUS_REFERRAL', function* setPreviousReferralSaga() {
  const previousReferral = yield select(selectPreviousReferral)
  yield put(setSelectedReferral.request(previousReferral))
})

export const setNextReferral = new SafeSaga('SET_NEXT_REFERRAL', function* setNextReferralSaga() {
  const nextReferral = yield select(selectNextReferral)
  yield put(setSelectedReferral.request(nextReferral))
})

export default () => [
  takeLatest(getLead.REQUEST, getLead.saga),
  takeLatest(createReferral.REQUEST, createReferral.saga),
  takeLatest(createAfrissLeadSaga.REQUEST, createAfrissLeadSaga.saga),
  takeLatest(getReferrals.REQUEST, getReferrals.saga),
  takeEvery(markReferralCompleted.REQUEST, markReferralCompleted.saga),
  takeEvery(setSelectedReferral.REQUEST, setSelectedReferral.saga),
  takeEvery(setPreviousReferral.REQUEST, setPreviousReferral.saga),
  takeEvery(setNextReferral.REQUEST, setNextReferral.saga),
  takeEvery(toggleReferralModal.REQUEST, toggleReferralModal.saga),
]
