import React from 'react'
import theme from '../../../theme/'

export const Logo = ({ children, style, ...props }) => {
  const logoStyle = {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: `${theme.spacing.l}px`,
    ...style,
  }
  return (
    <div { ...props } style={ logoStyle }>
      { children }
    </div>
  )
}
