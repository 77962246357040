import styled from '@emotion/styled'

export const CardItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 40px 40px 40px;
  background: ${({ theme }) => theme.background.level0};
  color: ${props => props.theme.text.default};
`
