import { ThemeProvider } from '@emotion/react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import ShareTeaser from '../../components/Share/ShareTeaser'
import SvgAirForce from '../../icons/AirForce'
import { NAV_ROUTES } from '../../navigation/routes'
import { DarkPalette } from '../../theme'
import { Content, Footer, Header, Logo, Main, Page } from './share.page.styles'

import InvalidShareLink from './InvalidShareLink'

function PhotoSharePage() {

  return (
    <ThemeProvider theme={ DarkPalette }>
      <Helmet>
        <meta property='og:url' content={ window.location.href } />
      </Helmet>
      <Page>
        <Header style={ { paddingTop: 0 } }>
          <Logo rel='noopener noreferrer' target='_blank' href='https://airforce.com/'>
            <SvgAirForce size={ 79 } />
          </Logo>
        </Header>
        <InvalidShareLink />
        <Main>
          <Content><ShareTeaser /></Content>
        </Main>
        <Footer>
          <a href={ NAV_ROUTES.PRIVACY_POLICY_PAGE } rel='noopener noreferrer' target='_blank'>Terms and Conditions</a>
        </Footer>
      </Page>
    </ThemeProvider>
  )
}

export default withRouter(PhotoSharePage)
