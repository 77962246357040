import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { DarkPalette, LightPalette } from '../../theme'
import { PageTitle } from '../afcep/afcepLandingPage/AfcepLandingPage.styles'
import SvgAirForce from '../../icons/AirForce'
import PageFooter from '../../components/Page/Footer/PageFooter'
import Main from '../../components/Page/Main/Main'
import PageWrapper from '../../components/Page/PageWrapper'
import PageHeader from '../../components/Page/Header/PageHeader'
import PageContainer from '../../components/Page/PageContainer'

export const DeleteAccountSuccessPage = () => {
  return (
    <ThemeProvider theme={ DarkPalette }>
      <PageWrapper>
        <PageHeader>
          <SvgAirForce size={ 144 } color={ LightPalette.background.level0 } />
          <PageTitle style={ { textTransform: 'uppercase' } }>Account Deletion</PageTitle>
        </PageHeader>
        <Main>
          <PageContainer>
            Your request for account deletion has been processed successfully. We acknowledge and confirm the removal of your Aim High account.
            We appreciate your engagement with our platform.
            Should you have any further inquiries or feedback, please do not hesitate to contact our support team.
            Thank you for your association with Aim High.
          </PageContainer>
        </Main>
        <PageFooter />
      </PageWrapper>
    </ThemeProvider>
  )
}
