import styled from '@emotion/styled'
import ProgressBar from '../../../components/ProgressBar'

export const HeaderInnerWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.spacing.xm}px;

  padding-top: ${({ theme }) => theme.spacing.xm}px;
  padding-left: ${({ theme }) => theme.spacing.m}px;

  h1 {
    font-family: ${({ theme }) => theme.fontFamilies.stratum2};
    font-size: ${({ theme }) => theme.fontSizes.l}px;
    line-height: ${({ theme }) => theme.fontSizes.l}px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};

    text-align: left;
    text-transform: uppercase;

    margin: 0;
  }
`

export const TimelineGrid = styled.div`
  position: relative;

  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;

  row-gap: ${({ theme }) => theme.spacing.xxl}px;
  column-gap: ${({ theme }) => theme.spacing.s}px;

  margin: 0 ${({ theme }) => theme.spacing.m}px;

  h2 {
    font-family: ${({ theme }) => theme.fontFamilies.openSans};
    font-size: ${({ theme }) => theme.fontSizes.m}px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};

    color: ${({ theme }) => theme.colors.text.white};
    margin: 0;
  }
`

export const TimelineGridDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;

  margin: ${({ theme }) => theme.spacing.xs}px;

  background: ${({ theme, $covered }) => $covered ? theme.colors.text.white : theme.colors.system.actionLight};

  z-index: 1;
`

export const StyledProgressBar = styled(ProgressBar)`
  position: absolute;
  top: 0;
  left: 0;

  background: ${({ theme }) => theme.colors.background.level0};

  z-index: 0;
`
