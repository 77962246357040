const size = 16

const spacing = {
  xxs: size * 0.125,
  xs: size * 0.25,
  s: size * 0.5,
  m: size,
  l: size * 2,
  xl: size * 4,
  xxl: size * 8,
}

export default spacing
