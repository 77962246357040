import PropTypes from 'prop-types'
import * as React from 'react'

function Team(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={props.size} width={props.size} viewBox="0 0 64 64">
        <title>sports fan</title><g fill={props.color} class="nc-icon-wrapper">
            <path d="M60,22.378a1.019,1.019,0,0,1-.371-.071,74.574,74.574,0,0,0-55.258,0A1,1,0,0,1,3,21.378V7.169a1,1,0,0,1,.643-.934,79.42,79.42,0,0,1,56.714,0A1,1,0,0,1,61,7.169V21.378a1,1,0,0,1-1,1Z" data-color="color-2"></path>
            <circle cx="32" cy="34" r="6" fill={props.color}></circle> 
            <path d="M41,63V51.182a3,3,0,0,1,.807-2.046L59.363,30.32a2.53,2.53,0,0,0,.174-3.243l-.039-.052a2.527,2.527,0,0,0-3.795-.3c-3.876,3.793-12.136,11.815-14.832,14.433A2.991,2.991,0,0,1,38.783,42H25.217a3,3,0,0,1-2.09-.847L8.278,26.74a2.529,2.529,0,0,0-3.785.3l-.007.009a2.529,2.529,0,0,0,.172,3.241L22.2,49.136A3,3,0,0,1,23,51.18V63Z" fill={props.color}></path>
        </g>
    </svg>
  )
}

Team.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

export default Team
