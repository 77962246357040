import React from 'react'
import PropTypes from 'prop-types'
import { ExploreMoreTeaserWrapper, ExploreListWrapper, ExploreItem, ExploreItemIcon, ExploreItemText } from './ExploreMoreTeaser.styles'
import { shareContentTypes } from '../../redux/constants/share.constants'
import SvgExplore from '../../icons/SvgExplore'
import SvgCheckered from '../../icons/SvgCheckered'
import SvgShare from '../../icons/Share'
import SvgFollow from '../../icons/SvgFollow'

const BEGIN_YOUR_JOURNEY = 'Begin your journey in the Air Force'
const FOLLOW_THE_JOURNEY = 'Follow the journey of a service member'
const CONNECT_WITH_OTHERS = 'Connect with recruiters and other service members'
const FOLLOW_RECRUITMENT_JOURNEY = 'Follow the Air Force recruitment journey'

const getBMTPhotoExploreMoreList = () => {
  return (
    <ExploreListWrapper>
      <ExploreItem>
        <ExploreItemIcon><SvgExplore /></ExploreItemIcon>
        <ExploreItemText>Find photos from Basic Military Training</ExploreItemText>
      </ExploreItem>
      <ExploreItem>
        <ExploreItemIcon><SvgCheckered /></ExploreItemIcon>
        <ExploreItemText>{ BEGIN_YOUR_JOURNEY }</ExploreItemText>
      </ExploreItem>
      <ExploreItem>
        <ExploreItemIcon><SvgFollow /></ExploreItemIcon>
        <ExploreItemText>{ FOLLOW_THE_JOURNEY }</ExploreItemText>
      </ExploreItem>
      <ExploreItem>
        <ExploreItemIcon><SvgShare /></ExploreItemIcon>
        <ExploreItemText>Connect with friends and family in Basic Training</ExploreItemText>
      </ExploreItem>
    </ExploreListWrapper>
  )
}

const getCareerExploreMoreList = () => {
  return (
    <ExploreListWrapper>
      <ExploreItem>
        <ExploreItemIcon><SvgExplore /></ExploreItemIcon>
        <ExploreItemText>Find careers that interest you</ExploreItemText>
      </ExploreItem>
      <ExploreItem>
        <ExploreItemIcon><SvgFollow /></ExploreItemIcon>
        <ExploreItemText>{ FOLLOW_THE_JOURNEY }</ExploreItemText>
      </ExploreItem>
      <ExploreItem>
        <ExploreItemIcon><SvgCheckered /></ExploreItemIcon>
        <ExploreItemText>{ BEGIN_YOUR_JOURNEY }</ExploreItemText>
      </ExploreItem>
      <ExploreItem>
        <ExploreItemIcon><SvgShare /></ExploreItemIcon>
        <ExploreItemText>Connect with recruiters to find the right fit for you</ExploreItemText>
      </ExploreItem>
    </ExploreListWrapper>
  )
}

const getEventExploreMoreList = () => {
  return (
    <ExploreListWrapper>
      <ExploreItem>
        <ExploreItemIcon><SvgExplore /></ExploreItemIcon>
        <ExploreItemText>Find events in your area</ExploreItemText>
      </ExploreItem>
      <ExploreItem>
        <ExploreItemIcon><SvgFollow /></ExploreItemIcon>
        <ExploreItemText>{ FOLLOW_RECRUITMENT_JOURNEY }</ExploreItemText>
      </ExploreItem>
      <ExploreItem>
        <ExploreItemIcon><SvgCheckered /></ExploreItemIcon>
        <ExploreItemText>{ BEGIN_YOUR_JOURNEY }</ExploreItemText>
      </ExploreItem>
      <ExploreItem>
        <ExploreItemIcon><SvgShare /></ExploreItemIcon>
        <ExploreItemText>{ CONNECT_WITH_OTHERS }</ExploreItemText>
      </ExploreItem>
    </ExploreListWrapper>
  )
}

const getAppExploreMoreList = () => {
  return (
    <ExploreListWrapper>
      <ExploreItem>
        <ExploreItemIcon><SvgExplore /></ExploreItemIcon>
        <ExploreItemText>Find photos, events and careers</ExploreItemText>
      </ExploreItem>
      <ExploreItem>
        <ExploreItemIcon><SvgFollow /></ExploreItemIcon>
        <ExploreItemText>{ FOLLOW_RECRUITMENT_JOURNEY }</ExploreItemText>
      </ExploreItem>
      <ExploreItem>
        <ExploreItemIcon><SvgCheckered /></ExploreItemIcon>
        <ExploreItemText>{ BEGIN_YOUR_JOURNEY }</ExploreItemText>
      </ExploreItem>
      <ExploreItem>
        <ExploreItemIcon><SvgShare /></ExploreItemIcon>
        <ExploreItemText>{ CONNECT_WITH_OTHERS }</ExploreItemText>
      </ExploreItem>
    </ExploreListWrapper>
  )
}
const ExploreMoreTeaser = ({ shareContentType }) => {

  return (
    <ExploreMoreTeaserWrapper data-testid='explore-more-teaser'>
      { shareContentType === shareContentTypes.BMT_PHOTO &&
        getBMTPhotoExploreMoreList()
      }
      { shareContentType === shareContentTypes.CAREER &&
        getCareerExploreMoreList()
      }
      { shareContentType === shareContentTypes.EVENT &&
        getEventExploreMoreList()
      }
      { shareContentType === shareContentTypes.SHARE &&
        getAppExploreMoreList()
      }
    </ExploreMoreTeaserWrapper>
  )
}

ExploreMoreTeaser.propTypes = {
  shareContentType: PropTypes.string,
}

export default ExploreMoreTeaser
