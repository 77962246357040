import React, { useState } from 'react'
import { Checkbox } from '../../components/Forms/Checkbox'
import { AimHighButton } from '../../components/Button/Button'
import { useTheme } from '@emotion/react'
import { css } from '@emotion/css'

export const DeleteAccountPageButtons = ({ onClickCallback }) => {
  const theme = useTheme()
  const [hasConfirmed, setHasConfirmed] = useState(false)

  return (
    <>
      <div className={ css`margin-bottom: ${theme.spacing.l}px` }>
        <Checkbox
          data-testid='deleteAccount-confirmCheckbox'
          label='Yes, I confirm that I want to delete my account'
          onChange={ () => setHasConfirmed(!hasConfirmed) }
        />
      </div>
      <AimHighButton
        primary
        disabled={ !hasConfirmed }
        onClick={ onClickCallback }
        style={ { minWidth: '320px', padding: `${theme.spacing.m}px 0` } }>
        Delete Account
      </AimHighButton>
    </>
  )
}
