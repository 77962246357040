import React from 'react'
import { Field } from 'redux-form'
import {
  Section,
  TwoColumnGrid,
  SectionTitle,
  SixColumnGrid
} from '../afcepLandingPage/AfcepLandingPage.styles'
import { FormItem } from '../../../forms/form.styles'
import InputField from '../../../components/Forms/InputField'
import { dateInPast, validAfrissDate, maxValue999 } from '../../../forms/validation'
import { stripToDigits } from '../../../forms/format'
import { FORM_VERSIONS } from '../afcep.constants'
import DropdownField from '../../../components/Forms/DropdownField'

export const AfoqtTesting = () => {

  return (
    <Section>
      <SectionTitle data-testid='afoqt-testing-section-title'>AFOQT Testing</SectionTitle>
      <TwoColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='form-version-field'>
          <Field
            id='afoqtFormVersion'
            name='afoqtFormVersion'
            data-testid='afoqtFormVersion'
            label='Form/Version'
            placeholder='Select'
            options={ FORM_VERSIONS }
            component={ DropdownField }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='date-tested-field'>
          <Field
            id='afoqtTestDate'
            name='afoqtTestDate'
            data-testid='afoqtTestDate'
            label='Date Tested'
            component={ InputField }
            type='date'
            validate={ [dateInPast, validAfrissDate] }
          />
        </FormItem>
      </TwoColumnGrid>
      <SixColumnGrid>
        <FormItem width={ { base: 'auto', min: 'auto' } } data-testid='afoqt-pilot-field'>
          <Field
            id='afoqtPilot'
            name='afoqtPilot'
            data-testid='afoqtPilot'
            label='Pilot'
            component={ InputField }
            type='text'
            validate={ maxValue999 }
            parse={ value => stripToDigits(value) }
          />
        </FormItem>
        <FormItem width={ { base: 'auto', min: 'auto' } } data-testid='afoqt-cso-field'>
          <Field
            id='afoqtCso'
            name='afoqtCso'
            data-testid='afoqtCso'
            label='CSO'
            component={ InputField }
            type='text'
            validate={ maxValue999 }
            parse={ value => stripToDigits(value) }
          />
        </FormItem>
        <FormItem width={ { base: 'auto', min: 'auto' } } data-testid='afoqt-abm-field'>
          <Field
            id='afoqtAbm'
            name='afoqtAbm'
            data-testid='afoqtAbm'
            label='ABM'
            component={ InputField }
            type='text'
            validate={ maxValue999 }
            parse={ value => stripToDigits(value) }
          />
        </FormItem>
        <FormItem width={ { base: 'auto', min: 'auto' } } data-testid='afoqt-aa-field'>
          <Field
            id='afoqtAa'
            name='afoqtAa'
            data-testid='afoqtAa'
            label='AA'
            component={ InputField }
            type='text'
            validate={ maxValue999 }
            parse={ value => stripToDigits(value) }
          />
        </FormItem>
        <FormItem width={ { base: 'auto', min: 'auto' } } data-testid='afoqt-verbal-field'>
          <Field
            id='afoqtVerbal'
            name='afoqtVerbal'
            data-testid='afoqtVerbal'
            label='Verbal'
            component={ InputField }
            type='text'
            validate={ maxValue999 }
            parse={ value => stripToDigits(value) }
          />
        </FormItem>
        <FormItem width={ { base: 'auto', min: 'auto' } } data-testid='afoqt-quantitative-field'>
          <Field
            id='afoqtQuantative'
            name='afoqtQuantative'
            data-testid='afoqtQuantative'
            label='Quantitative'
            component={ InputField }
            type='text'
            validate={ maxValue999 }
            parse={ value => stripToDigits(value) }
          />
        </FormItem>
      </SixColumnGrid>
    </Section>
  )
}
