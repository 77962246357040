import PropTypes from 'prop-types'
import * as React from 'react'

function Tap02(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={props.size} width={props.size} viewBox="0 0 24 24">
        <title>{props.title}</title>
        <g fill={props.color} class="nc-icon-wrapper">
            <path data-color="color-2" d="M12,4c-0.55225,0-1-0.44775-1-1V1c0-0.55225,0.44775-1,1-1s1,0.44775,1,1v2 C13,3.55225,12.55225,4,12,4z"></path>
            <path data-color="color-2" d="M18.36377,6.63623c-0.25586,0-0.51172-0.09766-0.70703-0.29297 c-0.39062-0.39062-0.39062-1.02344,0-1.41406l1.41455-1.41455c0.39062-0.39062,1.02344-0.39062,1.41406,0s0.39062,1.02344,0,1.41406 L19.0708,6.34326C18.87549,6.53857,18.61963,6.63623,18.36377,6.63623z"></path>
            <path data-color="color-2" d="M23,13h-2c-0.55225,0-1-0.44775-1-1s0.44775-1,1-1h2c0.55225,0,1,0.44775,1,1 S23.55225,13,23,13z"></path>
            <path data-color="color-2" d="M3,13H1c-0.55225,0-1-0.44775-1-1s0.44775-1,1-1h2c0.55225,0,1,0.44775,1,1S3.55225,13,3,13z "></path>
            <path data-color="color-2" d="M5.63623,6.63623c-0.25586,0-0.51172-0.09766-0.70703-0.29297L3.51465,4.92871 c-0.39062-0.39062-0.39062-1.02344,0-1.41406s1.02344-0.39062,1.41406,0L6.34326,4.9292c0.39062,0.39062,0.39062,1.02344,0,1.41406 C6.14795,6.53857,5.89209,6.63623,5.63623,6.63623z"></path>
            <path fill={props.color} d="M12,7c-2.75684,0-5,2.24316-5,5v11c0,0.55225,0.44775,1,1,1h8c0.55225,0,1-0.44775,1-1V12 C17,9.24316,14.75684,7,12,7z M15,13c-0.6283,0.24414-1.66187,0.55756-2.96875,0.5625C10.69189,13.56757,9.63428,13.24646,9,13 c0-0.33337,0-0.66669,0-1c0-1.65686,1.34314-3,3-3s3,1.34314,3,3V13z"></path>
        </g>
    </svg>
  )
}

Tap02.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

export default Tap02
