import immutable from 'seamless-immutable'
import {
  createReferral,
  createAfrissLeadSaga,
  getReferrals,
  markReferralCompleted,
  toggleReferralModal,
  setSelectedReferral,
  getLead
} from '../sagas/referral.sagas'

const INITIAL_STATE = immutable({
  lead: null,
  createReferralSuccess: false,
  createAfrissLeadSuccess: false,
  referrals: [],
  selectedReferral: {},
  isModalOpen: false,
})

export default function referralReducers(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case createReferral.SUCCESS:
      return state.merge({ createReferralSuccess: true })
    case createAfrissLeadSaga.SUCCESS:
      return { ...state, createAfrissLeadSuccess: true }
    case getLead.SUCCESS:
      return state.merge({ lead: action.payload })
    case getReferrals.SUCCESS:
      return state.set('referrals', action.payload)
    case toggleReferralModal.REQUEST:
      return state.set('isModalOpen', !state.isModalOpen)
    case setSelectedReferral.REQUEST:
      return state.set('selectedReferral', action.params)
    case markReferralCompleted.SUCCESS:
      return state.set('selectedReferral', action.payload)
    default:
      return state
  }
}
