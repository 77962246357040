import React from 'react'
import PropTypes from 'prop-types'
import {
  Disclaimer,
  LeadSubmitButtonsContainer, Link,
  Loader,
  LoaderWrapper,
  VerifyContainer,
  VerifyText
} from '../form.styles'
import { Checkbox } from 'semantic-ui-react'
import { AimHighButton } from '../../components/Button/Button'
import { NAV_ROUTES } from '../../navigation/routes'
import ValidationError, { ServerError } from '../ValidationError'
import { REFER_A_FRIEND_REFERRAL_CERTIFICATION_TEXT, SELF_FILLED_REFERRAL_CERTIFICATION_TEXT } from './referral.constants'

ReferralSubmissionContainer.propTypes = {
  createAfrissLeadSuccess: PropTypes.bool,
  createLeadError: PropTypes.any,
  createReferralSuccess: PropTypes.bool,
  formSyncErrors: PropTypes.any,
  leadSubmitting: PropTypes.bool,
  piiVerified: PropTypes.bool,
  referAFriend: PropTypes.bool,
  referralError: PropTypes.any,
  referralLoading: PropTypes.bool,
  setPiiVerified: PropTypes.func,
  submitFailed: PropTypes.bool,
}

function ReferralSubmissionContainer(props) {
  const {
    createAfrissLeadSuccess,
    createLeadError,
    createReferralSuccess,
    formSyncErrors,
    leadSubmitting,
    referAFriend,
    piiVerified,
    referralError,
    referralLoading,
    setPiiVerified,
    submitFailed,
  } = props

  const isDisabled = referralLoading || leadSubmitting || !piiVerified

  const disclaimerText = referAFriend ? 'By submitting this form, you are allowing the U.S. Air Force to contact this individual.' :
    'By submitting this form, you are allowing the U.S. Air Force to contact you.'

  return createReferralSuccess || createAfrissLeadSuccess ? null : (
    <LeadSubmitButtonsContainer
      data-testid='referralFormSubmitButtonsContainer'>
      { (referralLoading || leadSubmitting) && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) }
      <VerifyContainer>
        <Checkbox data-testid='referralform-verifycheckbox' onClick={ () => setPiiVerified(!piiVerified) } />
        <VerifyText data-testid='referral-form-verification-text'>{ referAFriend ? REFER_A_FRIEND_REFERRAL_CERTIFICATION_TEXT : SELF_FILLED_REFERRAL_CERTIFICATION_TEXT }</VerifyText>
      </VerifyContainer>
      <AimHighButton
        data-testid='Submit and Connect Button'
        disabled={ isDisabled }
        fluid
        primary
        size='large'
        type='submit'
        name='Submit'>
        { referAFriend ? 'Submit' : 'Submit & Connect' }
      </AimHighButton>
      { formSyncErrors && submitFailed && (
        <ValidationError
          data-testid='validationError'
          validationErrors={ formSyncErrors }
        />
      ) }
      { referralError && <ServerError error={ referralError } /> }
      { createLeadError && <ServerError error={ createLeadError } /> }
      <Disclaimer data-testid='referral-form-disclaimer'>{ disclaimerText }</Disclaimer>
      <Link
        data-testid='privacy-policy'
        href={ NAV_ROUTES.PRIVACY_POLICY_PAGE }
        rel='noopener noreferrer'
        target='_blank'>
        Privacy Policy
      </Link>
    </LeadSubmitButtonsContainer>
  )
}

export default ReferralSubmissionContainer
