import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react'
import { css, cx } from '@emotion/css'

const Main = ({ children, className }) => {
  const theme = useTheme()

  const defaultClass = css`
    background-color: ${theme.background.level0};
    padding: ${theme.spacing.l}px 5%;
    flex-grow: 1;
  `

  return (
    <main className={ cx(defaultClass, className) }>
      { children }
    </main>
  )
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Main
