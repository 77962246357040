import React from 'react'
import PropTypes from 'prop-types'
import { Item } from 'semantic-ui-react'
import styled from '@emotion/styled'
import { fontSizes } from '../../theme/typography'
import spacing from '../../theme/spacing'
import { getMonthAndYear } from '../../api/date.util'

const Header = styled(Item.Meta)`
  font-family: "Open Sans", sans-serif;
  font-size: ${fontSizes.m}px !important;
  color: ${props => props.palette.text.default} !important;
  margin-top: ${spacing.xs}px !important;
`
const Meta = styled(Item.Meta)`
  font-size: ${fontSizes.s}px !important;
  font-family: "Open Sans", sans-serif;
  color: ${props => props.palette.text.subtle} !important;
  margin-bottom: ${spacing.xs}px !important;
`

MilitaryService.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  dutyTitle: PropTypes.string,
  dutyStation: PropTypes.string,
  palette: PropTypes.object.isRequired,
}

function MilitaryService(props) {
  const getFormattedDate = date => {
    if (!date) {
      return 'Present'
    }

    return getMonthAndYear(date)
  }

  const startDate = props.startDate ? props.startDate : null
  const endDate = props.endDate ? props.endDate : null
  const formattedStartDate = getFormattedDate(startDate)
  const formattedEndDate = getFormattedDate(endDate)
  const dateRange = startDate ? `${formattedStartDate} - ${formattedEndDate}` : formattedEndDate

  return (
    <Item>
      <Item.Content data-testid='MilitaryService' verticalAlign='middle'>
        <Meta palette={ props.palette }>{ dateRange }</Meta>
        <Header palette={ props.palette }>{ props.dutyTitle }</Header>
        <Meta palette={ props.palette }>{ props.dutyStation }</Meta>
      </Item.Content>
    </Item>
  )
}

export default MilitaryService
