import React from 'react'
import PropTypes from 'prop-types'
import { ContentContainer, Subtitle, Title, IconContainer } from './role.request.page.styles'
import SvgEmptyStateConnectionsRequest from '../../icons/EmptyStateConnectionsRequest'
import { ROLES, ROLE_DISPLAY_NAMES } from '../../config'

const getFormattedDisplayRoles = roles => {
  const displayRoles = roles.map(role => ROLE_DISPLAY_NAMES[role])

  if (displayRoles.length === 1) {
    const a = roles[0] && roles[0].toLowerCase() === ROLES.ADMIN ? 'an' : 'a'
    return `${a} ${displayRoles} role`
  }

  const lastRole = displayRoles.pop()
  const formattedDisplayRoles = displayRoles.join(', ')
  const oxfordComma = displayRoles.length > 2 ? ',' : ''
  return `${formattedDisplayRoles}${oxfordComma} and ${lastRole} roles`
}

const AccessRequested = props => {
  const { roles, organization } = props

  const formattedDisplayRoles = getFormattedDisplayRoles(roles)
  // eslint-disable-next-line max-len
  const text = `Access for ${formattedDisplayRoles} for ${organization} has been requested. Please allow some time for your request to be reviewed.`
  return (
    <ContentContainer>
      <IconContainer data-testid='empty-state-connection-request-icon'>
        <SvgEmptyStateConnectionsRequest size={ 300 } />
      </IconContainer>
      <Title data-testid='access-requested-title'>ACCESS REQUESTED</Title>
      <Subtitle data-testid='access-confirmation-text'>{ text }</Subtitle>
    </ContentContainer>
  )

}

AccessRequested.propTypes = {
  roles: PropTypes.array,
  organization: PropTypes.string,
}

export default AccessRequested
