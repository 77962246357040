import PropTypes from 'prop-types'
import * as React from 'react'
import colors from '../theme/colors'

function SpaceForceLog(props) {
  return (
    <svg data-testid="space-force-logo" width={props.size * .67} height={props.size} viewBox="0 0 33 49" fill={props.color} xmlns="http://www.w3.org/2000/svg">
        <path d="M19.9765 31.5965L17.5967 32.2805L16.9162 34.6603L16.2322 32.2805L13.8525 31.5965L16.2322 30.9126L16.9162 28.5363L17.5967 30.9126L19.9765 31.5965ZM7.32298 40.7844L12.4774 34.5087L15.8128 12.4523L5.71529 42.801L15.8868 36.6982L13.5493 35.3056L7.32298 40.7844ZM18.0198 12.4523L21.355 34.5123L26.5095 40.7879L20.2833 35.309L17.9457 36.7016L28.1207 42.8046L18.0198 12.4523ZM32.8839 48.6289L16.9162 39.0779L0.952148 48.6324L16.9198 0.78244L32.8874 48.6289H32.8839ZM16.9162 37.8898L31.0435 46.3408L16.9198 4.00487L2.78901 46.3408L16.9198 37.8898H16.9162Z" fill={props.color} />
    </svg>

  )
}

SpaceForceLog.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

export default SpaceForceLog