import styled from '@emotion/styled'

export const PageTitleContainer = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: props.includeMargins ? `${props.theme.spacing.l}px` : `0`,
  position: 'relative',
}))

export const PageTitleLight = styled.div(props => ({
  fontSize: props.theme.typography.fontSizes.m,
  fontWeight: props.theme.typography.fontWeights.light,
  textTransform: 'uppercase',
  color: props.theme.colors.white.default,
}))

export const PageTitle = styled.div(props => ({
  fontSize: props.theme.typography.fontSizes.xl,
  fontWeight: props.theme.typography.fontWeights.bold,
  textTransform: 'uppercase',
  textAlign: 'center',
  lineHeight: 1.0,
  color: props.theme.colors.white.default,
}))

export const PageSubtitle = styled.div(props => ({
  fontSize: props.theme.typography.fontSizes.m,
  fontWeight: props.theme.typography.fontWeights.bold,
  textTransform: 'uppercase',
  textAlign: 'center',
  lineHeight: 1.0,
  color: props.theme.colors.white.default,
  paddingTop: props.theme.spacing.m,
}))

