import SvgAirForce from '../../icons/AirForce'
import SvgAirForceReserve from '../../icons/AirForceReserve'
import SvgAirForceNationalGuard from '../../icons/AirForceNationalGuard'
import SvgSpaceForce from '../../icons/SpaceForce'
import React from 'react'

export const lookupComponentWebLink = component => {
  if (!component) {
    return 'https://www.airforce.com/'
  }
  switch (component) {
    case 'AFR':
      return 'https://afreserve.com/'
    case 'ANG':
      return 'https://www.goang.com/'
    case 'SF':
      return 'https://www.spaceforce.com'
    case 'AF':
    default:
      return 'https://www.airforce.com/'
  }
}

export const lookupComponentLogo = component => {
  switch (component) {
    case 'AF':
      return <SvgAirForce size={ 79 } data-testid='AirForce' />
    case 'AFR':
      return <SvgAirForceReserve size={ 50 } data-testid='AirForceReserve' />
    case 'ANG':
      return <SvgAirForceNationalGuard size={ 50 } data-testid='AirNationalGuard' />
    case 'SF':
      return <SvgSpaceForce size={ 100 } data-testid='SpaceForce' />
    default:
      return <SvgAirForce size={ 79 } data-testid='AirForce' />
  }
}
