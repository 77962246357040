import React from 'react'
import { Card, Message } from 'semantic-ui-react'
import { Main } from './share.page.styles'

function InvalidShareLink() {
  // eslint-disable-next-line max-len
  const message = 'The shared item could not be found. It is likely an old link, an invalid link, or was shared from an outdated version of the Aim High Application.'
  return (
    <Main>
      <Card fluid>
        <Message
          warning
          header='We apologize for the inconvenience'
          content={ message }
        />
      </Card>
    </Main>
  )
}

export default InvalidShareLink
