import * as htmlDecoder from 'he'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SHARE_CAREER_PATH } from '../../navigation/routes'
import { shareContentTypes } from '../../redux/constants/share.constants'
import { getCareerSaga, selectCareer, selectErrorLoadingCareer } from './career.duck'
import InvalidShareLink from '../../pages/share/InvalidShareLink'
import Shareable from '../Share/Shareable'

const HtmlTagRegex = /(<([^>]+)>)/igu
function removeHtml(html) {
  if (html) {
    let text = htmlDecoder.decode(html)
    text = text.replace(/\n/gu, '')
    text = text.replace(HtmlTagRegex, '')

    return text
  }

  return null
}

CareerSharePage.propTypes = {
  match: PropTypes.object,
}

function CareerSharePage(props) {
  const dispatch = useDispatch()
  const career = useSelector(selectCareer)
  const errorLoadingCareer = useSelector(selectErrorLoadingCareer)

  useEffect(() => {
    const { slug } = props.match.params
    dispatch(getCareerSaga.request({ slug }))
  }, [dispatch, props.match.params])

  if (errorLoadingCareer) {
    return <InvalidShareLink />
  }

  return (
    <Shareable
      sharePath={ `${SHARE_CAREER_PATH}/${career.slug}` }
      imageUrl={ (career?.image?.large) ? `https://www.airforce.com${encodeURI(career.image.large)}` : require('../../assets/AirShows.jpg') }
      title={ career && `${career.title}` }
      shareContentType={ shareContentTypes.CAREER }>
      { career.description && removeHtml(career.description) }
    </Shareable>
  )
}

export default withRouter(CareerSharePage)
