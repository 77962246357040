import immutable from 'seamless-immutable'
import AsyncSaga from '../../redux/sagas/util/AsyncSaga'
import afreCmsService from '../../api/afre.cms.service'
import { call, put, takeLatest } from 'redux-saga/effects'
import { createErrorSelector } from '../../redux/selectors/common.selectors'

const GET_CAREER = 'GET_CAREER'

export const selectCareer = state => state.career.career
export const selectErrorLoadingCareer = createErrorSelector(GET_CAREER)

const INITIAL_STATE = immutable({
  career: {},
})

export const getCareerSaga = new AsyncSaga(GET_CAREER, function* getCareerGenerator({ params: { slug } }) {
  const response = yield call(afreCmsService.getCareer, slug)
  if (response?.data?.data[0].slug) {
    const careerData = response?.data?.data[0]
    yield put(getCareerSaga.success(careerData))
  } else {
    yield put(getCareerSaga.error(new Error(`No career found matching slug: ${slug}`), false))
  }
}).catch(function* getCareerCatch(error) {
  yield put(getCareerSaga.error(error, false))
})

export const careerSagas = [
  takeLatest(getCareerSaga.REQUEST, getCareerSaga.saga),
]

export default function careerReducers(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case getCareerSaga.SUCCESS:
      return state.set('career', action.payload)
    default:
      return state
  }
}
