import React from 'react'
import theme, { LightPalette } from '../../../theme/'

export const Title = ({ children, style, ...props }) => {
  const titleStyle = {
    color: LightPalette.text.contrast,
    margin: -1,
    paddingTop: theme.spacing.m,
    paddingBottom: theme.spacing.l,
    ...style,
  }
  return (
    <h1 style={ titleStyle } { ...props }>{ children }</h1>
  )
}
