import { ThemeProvider } from '@emotion/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Dimmer, Input, Loader } from 'semantic-ui-react'
import { OpaFieldLabel } from '../../forms/form.styles.js'
import { NAV_ROUTES } from '../../navigation/routes'
import { selectIsOpaAdmin } from '../../redux/selectors/auth.selectors'
import { LightPalette } from '../../theme'
import OrdersDocument from '../components/OrdersViewing/OrdersDocument.js'
import { NewOpaPageHeader } from '../components/PageTitle/OpaPageHeader.js'
import {
  FindOrderContent,
  FindOrderFormButton,
  FindOrderFormFieldWrapper,
  FindOrderFormWrapper,
  FindOrderWrapper
} from './MtlFindOrdersPage.styles'
import { formatSSN } from '../../forms/format'

export const MtlFindOrdersPage = () => {
  const isOpaAdmin = useSelector(selectIsOpaAdmin)
  const [ssn, setSsn] = useState('')
  const [searchSsnParam, setSearchSsnParam] = useState()

  const ssnFormatter = (_e, data) => {
    setSsn(formatSSN(data.value))
  }

  const renderSSNField = () => (
    <FindOrderFormFieldWrapper>
      <OpaFieldLabel data-testid='ssn-field-label'>SSN</OpaFieldLabel>
      <Input
        focus
        value={ ssn }
        onChange={ ssnFormatter }
        placeholder='###-##-####'
        data-testid='ssn-input'
        fluid
      />
    </FindOrderFormFieldWrapper>
  )
  const showSpinner = false
  const ssnRegex = /^\d{3}-?\d{2}-?\d{4}$/gu
  const isSsnValid = !!ssn && ssnRegex.test(ssn)
  const canSubmit = isSsnValid
  return (
    <>
      <ThemeProvider theme={ LightPalette }>
        <Dimmer.Dimmable dimmed={ showSpinner }>
          <Dimmer active={ showSpinner } page>
            <Loader>Loading</Loader>
          </Dimmer>
          <NewOpaPageHeader
            hideNav={ !isOpaAdmin }
            pageRoute={ NAV_ROUTES.FIND_ORDERS_PAGE }
            pageTitle='Find Orders'
          />
          <FindOrderContent>
            <FindOrderFormWrapper>
              { renderSSNField() }
              <FindOrderFormButton
                data-testid='searchButton'
                disabled={ !canSubmit }
                size='big'
                onClick={ () => setSearchSsnParam(ssn.replace(/[^\d]/gu, '')) }>
                Search
              </FindOrderFormButton>
            </FindOrderFormWrapper>
            <FindOrderWrapper>
              { searchSsnParam && <OrdersDocument ssnInput={ searchSsnParam } /> }
            </FindOrderWrapper>
          </FindOrderContent>
        </Dimmer.Dimmable>
      </ThemeProvider>
    </>
  )
}
