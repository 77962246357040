import React from 'react'
import { Field, formValueSelector } from 'redux-form'
import {
  P,
  Section,
  TwoColumnGrid,
  SectionTitle
} from '../afcepLandingPage/AfcepLandingPage.styles'
import { FormItem } from '../../../forms/form.styles'
import DropdownField from '../../../components/Forms/DropdownField'
import { required } from '../../../forms/validation'
import { AFCEP_FORM_NAME, AFSC_OPTIONS } from '../afcep.constants'
import theme from '../../../theme'
import { useSelector } from 'react-redux'

export const AfscPreferences = () => {
  const NUMBER_OF_FIELDS = 8

  const getAfcepFormValues = formValueSelector(AFCEP_FORM_NAME)
  const afscPreferencesValues = [...Array(NUMBER_OF_FIELDS).keys()]
    // eslint-disable-next-line react-hooks/rules-of-hooks
    .map((_key, index) => useSelector(state => getAfcepFormValues(state, `afscChoice${index + 1}`)))

  const getNumber = num => {
    let number
    switch (num) {
      case 1:
        number = `${num}st`
        break
      case 2:
        number = `${num}nd`
        break
      case 3:
        number = `${num}rd`
        break
      default:
        number = `${num}th`
        break
    }
    return number
  }

  const renderFields = () => {
    const options = AFSC_OPTIONS.map(option => {
      if (afscPreferencesValues.includes(option.value)) {
        return { ...option, disabled: true }
      }
      return option
    })
    const choices = []
    for (let index = 1; index <= NUMBER_OF_FIELDS; index++) {
      const fieldId = `afscChoice${index}`
      choices.push(
        <FormItem width={ { base: 'auto' } } key={ fieldId } data-testid='afsc-preferences-choices'>
          <Field
            id={ fieldId }
            name={ fieldId }
            label={ `${getNumber(index)} Choice` }
            data-testid={ fieldId }
            placeholder='Select'
            options={ options.map(({ text, value, disabled }) => ({ text: `${text} - ${value}`, value, disabled })) }
            component={ DropdownField }
            validate={ index === 1 ? required : null }
          />
        </FormItem>
      )
    }

    return choices
  }

  return (
    <Section>
      <SectionTitle style={ { paddingBottom: theme.spacing.xs } } data-testid='afsc-preferences-section-title'>AFSC Preferences</SectionTitle>
      <P data-testid='afsc-preferences-description'>Minimum of 1 choice required.</P>
      <TwoColumnGrid style={ { gridTemplateRows: 'repeat(4, 1fr)' } }>
        { renderFields() }
      </TwoColumnGrid>
    </Section>
  )
}
