import PropTypes from 'prop-types'
import React from 'react'
import SvgAirForce from '../../../icons/AirForce'
import { PageTitleContainer, PageTitle as PageTitleStyle } from './PageTitle.styles'

const PageTitle = ({ siteName = 'AIM HIGH', title = '', includeMargins = true }) => {
  return (
    <PageTitleContainer includeMargins={ includeMargins }>
      <SvgAirForce data-testid='air-force-logo' color='#415161' size={ 160 } />
      <PageTitleStyle data-testid='site-name'>{ siteName }</PageTitleStyle>
      <PageTitleStyle data-testid='site-title'>{ title }</PageTitleStyle>
    </PageTitleContainer>
  )
}

PageTitle.propTypes = {
  siteName: PropTypes.string,
  title: PropTypes.string,
  includeMargins: PropTypes.bool,
}

export default PageTitle
