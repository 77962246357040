import React from 'react';

const Close = ({ color, size, ...props }) => {
  return (
    <svg width={ size } height={ size } viewBox="0 0 32 32" { ...props }>
      <path
        d="M28 24l-8-8 8-8-4-4-8 8-8-8-4 4 8 8-8 8 4 4 8-8 8 8 4-4z"
        fill={ color }
      />
    </svg >
  );
};
Close.displayName = Close;
export default Close;
