import { takeEvery, put } from 'redux-saga/effects'
import AsyncSaga from './util/AsyncSaga'
import { analyticsActions, CATEGORY, addWebPrefix } from '../constants/analytics.constants'
import * as Amplitude from 'amplitude-js'
import { createAfrissLeadSaga, createReferral } from './referral.sagas'
import { APP_STORE_LINKS } from '../constants/referral.constants'

const removeForwardSlashPrefix = someString => someString.substring(1)

export const logPageView = new AsyncSaga(analyticsActions.LOG_PAGE_VIEW, function* logPageViewSaga(action) {
  const pathName = removeForwardSlashPrefix(action.params)
  Amplitude.getInstance().logEvent(addWebPrefix(CATEGORY.PAGE_VIEW), { page: pathName })
  yield put(logPageView.success())
}).catch(function* logPageViewCatch() {
  yield put(logPageView.error(new Error('Error sending page view data to Amplitude')))
})

export const logAppStoreNavigation = new AsyncSaga(analyticsActions.LOG_APP_STORE_NAVIGATION, function* logAppStoreNavigationSaga(action) {
  const storeName = action.params === APP_STORE_LINKS.ios ? 'iOS' : 'Android'
  Amplitude.getInstance().logEvent(addWebPrefix(CATEGORY.APP_STORE_NAVIGATION), { appStore: storeName })
  yield put(logAppStoreNavigation.success())
}).catch(function* logAppStoreNavigationCatch() {
  yield put(logAppStoreNavigation.error(new Error('Error sending app store data to Amplitude')))
})

export const logNavigation = new AsyncSaga(analyticsActions.LOG_NAVIGATION, function* logNavigationSaga(action) {
  const pathName = removeForwardSlashPrefix(action.params)
  Amplitude.getInstance().logEvent(addWebPrefix(CATEGORY.NAVIGATION), { path: pathName })
  yield put(logNavigation.success())
}).catch(function* logNavigationCatch() {
  yield put(logNavigation.error(new Error('Error sending navigation data to Amplitude')))
})

const logEvent = new AsyncSaga(analyticsActions.LOG_EVENT, function* logEventSaga(action) {
  Amplitude.getInstance().logEvent(addWebPrefix(action.type), { label: action.label })
  yield put(logEvent.success())
}).catch(function* logEventCatch() {
  yield put(logEvent.error(new Error('Error sending event to Amplitude')))
})

export const logEventParams = new AsyncSaga(analyticsActions.LOG_EVENT_PARAMS, function* logEventParamsSaga(action) {
  Amplitude.getInstance().logEvent(addWebPrefix(action.type), { label: action.params.label, value: action.params.value })
  yield put(logEvent.success())
}).catch(function* logEventParamsCatch() {
  yield put(logEvent.error(new Error('Error sending event to Amplitude')))
})

export default () => [
  takeEvery(createReferral.REQUEST, logEvent.saga),
  takeEvery(createAfrissLeadSaga.SUCCESS, logEvent.saga),
  takeEvery(logEventParams.REQUEST, logEventParams.saga),
  takeEvery(logNavigation.REQUEST, logNavigation.saga),
  takeEvery(logAppStoreNavigation.REQUEST, logAppStoreNavigation.saga),
  takeEvery(logPageView.REQUEST, logPageView.saga),
  takeEvery(logEvent.REQUEST, logEvent.saga),
]
