import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react'
import { css, cx } from '@emotion/css'

const VALID_HEADING_LEVELS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']

const Heading = ({ children, className, headingLevel }) => {
  const AHHeading = headingLevel.toLowerCase()
  if (!VALID_HEADING_LEVELS.includes(AHHeading)) {
    throw new Error('Need a valid headlingLevel')
  }

  const theme = useTheme()
  const headingStyles = VALID_HEADING_LEVELS.map(h => {
    const defaultStyles = {
      color: `${theme.text.contrast}`,
      fontFamily: theme.typography.fontFamilies.openSansRegular,
    }
    const styles = {}
    switch (h) {
      case 'h1':
        styles[h] = {
          ...defaultStyles,
          fontFamily: theme.typography.fontFamilies.stratum2Bold,
          fontSize: `${theme.typography.fontSizes.xxl}px`,
          textTransform: 'uppercase',
        }
        break
      case 'h2':
        styles[h] = {
          ...defaultStyles,
          fontSize: `${theme.typography.fontSizes.xl}px`,
        }
        break
      case 'h3':
        styles[h] = {
          ...defaultStyles,
          fontSize: `${theme.typography.fontSizes.l}px`,
        }
        break
      case 'h4':
      case 'h5':
      case 'h6':
      default:
        styles[h] = {
          ...defaultStyles,
          fontSize: `${theme.typography.fontSizes.m}px`,
          textTransform: 'uppercase',
          fontWeight: 400,
        }
        break
    }

    return styles
  })

  const flattenedHeadingStyles = headingStyles.reduce((acc, obj) => {
    const key = Object.keys(obj)[0]
    const value = obj[key]
    acc[key] = value
    return acc
  }, {})

  const classNames = cx(css(flattenedHeadingStyles[AHHeading]), className)

  return (
    <AHHeading className={ classNames }>{ children }</AHHeading>
  )
}

Heading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string,
  headingLevel: PropTypes.oneOf(VALID_HEADING_LEVELS).isRequired,
}

export default Heading
