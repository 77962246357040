import React from 'react'
import theme, { LightPalette } from '../../../theme/'

export const Header = ({ children, style, ...props }) => {
  const headerStyle = {
    color: LightPalette.text.contrast,
    margin: `0 !important`,
    paddingTop: `${theme.spacing.m}px`,
    paddingBottom: `${theme.spacing.m}px`,
    ...style,
  }
  return (
    <h3 style={ headerStyle } { ...props }>{ children }</h3>
  )
}
