import HttpRequests from '../api/HttpRequests'
import { useMutation, useQuery } from 'react-query'
import { afreServiceUrl } from '../api/afre.service'
import { useSelector } from 'react-redux'
import { selectAuthUserToken } from '../redux/selectors/auth.selectors'

export const useGetMassAlertHistory = () => {
  const authToken = useSelector(selectAuthUserToken)
  
  return useQuery(['getMassAlertHistory'], async () => {
    const response = await HttpRequests.getRequest(`${afreServiceUrl}/pushNotifications/massNotifications`, {}, authToken)
    return response.data.data
  })
}

export const useSendMassAlert = () => {
  const authToken = useSelector(selectAuthUserToken)
  
  return useMutation(['sendMassAlert'], async massAlert => {
    const response = await HttpRequests.postRequest(`${afreServiceUrl}/pushNotifications/massNotifications/send`, massAlert, {}, authToken)
    return response.data
  })
}
