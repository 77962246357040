import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router'
import { NAV_ROUTES } from '../navigation/routes'
import { selectHasMassAlerts } from '../redux/selectors/auth.selectors'

MassAlertsRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.string,
}

function MassAlertsRoute({ component: RoutedComponent, ...rest }) {
  const hasMassAlerts = useSelector(selectHasMassAlerts)
  return (
    <Route
      { ...rest }
      render={ props => ((hasMassAlerts) ? (
        <RoutedComponent { ...props } />
      ) : (
        <Redirect
          to={ {
            pathname: NAV_ROUTES.LOGIN_PAGE,
            state: { from: props.location },
          } }
        />
      ))
      }
    />
  )
}

export default MassAlertsRoute
