import { ThemeProvider } from '@emotion/react'
import PropTypes from 'prop-types'
import React from 'react'
import { AimHighButton } from '../Button/Button'
import { Modal } from 'semantic-ui-react'
import { LightPalette } from '../../theme'
import styled from '@emotion/styled'

const ModalButton = styled(AimHighButton)`
  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0px !important;
  }
`

AimHighModal.propTypes = {
  isOpen: PropTypes.bool,
  cancelHandler: PropTypes.func,
  cancelButtonText: PropTypes.string,
  confirmHandler: PropTypes.func,
  confirmButtonDisabled: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  modalHeader: PropTypes.string,
  modalContent: PropTypes.node.isRequired,
}

function AimHighModal({
  isOpen,
  cancelHandler,
  cancelButtonText,
  confirmHandler,
  confirmButtonDisabled = false,
  confirmButtonText,
  modalHeader,
  modalContent,
}) {
  const getContent = () => {
    if (typeof modalContent === 'string') {
      return (
        <Modal.Description>
          <p data-testid='modal-description'>
            { modalContent }
          </p>
        </Modal.Description>
      )
    }

    return modalContent
  }

  return (
    <Modal
      open={ isOpen }
      style={ { maxWidth: '700px' } }>
      <ThemeProvider theme={ LightPalette }>
        <Modal.Header data-testid='modal-header'>{ modalHeader }</Modal.Header>
        <Modal.Content>
          { getContent() }
        </Modal.Content>
        <Modal.Actions>
          <ModalButton basic primary onClick={ cancelHandler } data-testid='cancel-button'>
            { cancelButtonText }
          </ModalButton>
          <ModalButton disabled={ confirmButtonDisabled } primary onClick={ confirmHandler } data-testid='confirm-button'>
            { confirmButtonText }
          </ModalButton>
        </Modal.Actions>
      </ThemeProvider>
    </Modal>
  )
}

export default AimHighModal
