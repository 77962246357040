import styled from '@emotion/styled'
import React from 'react'

export const FlatDivider = styled.div`
  height: 1px;
  background-color: ${({ hasError, theme }) => (hasError ? theme.text.warning : theme.text.default)};
`

const FloatingDiv = styled.div`
  content: "";
  background-color: ${({ theme }) => theme.text.default};
  width: 1px;
  height: 90%;
`

export const FloatingDivider = () => {
  return (
    <div style={ { borderBottom: '1px solid' } }>
      <FloatingDiv />
    </div>
  )
}
