import React from 'react'
import PropTypes from 'prop-types'
import { CardHeaderWrapper, TitleWrapper, MilitaryTitle, Title } from './CardHeader.styles'

const CardHeader = ({ backgroundImageUrl, mutedTitle, title }) => {

  return (
    <CardHeaderWrapper
      style={ {
        background: `linear-gradient(-180deg, rgba(27, 38, 50, 0.3) 100%, rgba(27, 38, 50, 0) 0%), 
        url(${backgroundImageUrl}) no-repeat center` } } data-testid='background-image'>
      <TitleWrapper>
        <MilitaryTitle data-testid='muted-title'>{ mutedTitle }</MilitaryTitle>
        <Title data-testid='title'>{ title }</Title>
      </TitleWrapper>
    </CardHeaderWrapper>
  )
}

CardHeader.propTypes = {
  backgroundImageUrl: PropTypes.string,
  mutedTitle: PropTypes.string,
  title: PropTypes.string,
}
export default CardHeader
