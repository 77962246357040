import React from 'react'
import PropTypes from 'prop-types'
import { Logo } from '../../pages/share/share.page.styles'
import { lookupComponentLogo, lookupComponentWebLink } from './componentUtil'

ComponentLogo.propTypes = {
  recruiterComponent: PropTypes.string,
}

function ComponentLogo({ recruiterComponent }) {
  const componentLink = lookupComponentWebLink(recruiterComponent)
  return (
    <Logo rel='noopener noreferrer' target='_blank' href={ componentLink }>
      { lookupComponentLogo(recruiterComponent) }
    </Logo>
  )
}

export default ComponentLogo
