import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react'
import {
  AFBirdTextHeader,
  AFBirdTextSubheader,
  AFBirdTextWrapper,
  AFBirdWrapper,
  CloseButtonWrapper,
  CurrentSidebarItem,
  IconWrapper,
  OpaSidebarIconWrapper,
  SidebarHeader,
  SidebarItem,
  SidebarItemHeader,
  SidebarItemSubheader,
  SidebarWrapper,
  StyledSidebar
} from '../../../components/Navigation/NavSidebar.styles'
import SvgAfBird from '../../../icons/AfBird'
import AfscManagement from '../../../icons/AfscManagement'
import SvgApprove from '../../../icons/Approve'
import BaseManagement from '../../../icons/BaseManagement'
import SvgClose from '../../../icons/Close'
import SvgCms from '../../../icons/Cms'
import DownloadFile from '../../../icons/DownloadFile'
import SvgExport from '../../../icons/Export'
import SvgGroups from '../../../icons/Groups'
import SvgReferrals from '../../../icons/Referrals'
import UnitManagement from '../../../icons/UnitManagement'
import { getOpaSidebarRoutes, NAV_ROUTES } from '../../../navigation/routes'
import { selectFeatureEnabled } from '../../../featureToggles/featureToggleDuck'
import { logNavigation } from '../../../redux/sagas/amplitudeAnalytics.sagas'
import { DarkPalette } from '../../../theme'
import spacing from '../../../theme/spacing'

const SIDEBAR_NAV_SUBTITLES = {
  OPA_USERS_PAGE: 'View OPA Users',
  UPLOAD_WEEK_GROUP_PAGE: 'Upload MilPDS Orders',
  PROCESS_WEEK_GROUP_PAGE: 'Download Order Files',
  VARIABLES_PAGE: 'Manage Template Variables',
  AFSC_MANAGEMENT_PAGE: 'Manage AFSC Properties',
  BASE_MANAGEMENT_PAGE: 'Manage Gain Bases',
  UNIT_MANAGEMENT_PAGE: 'Manage Gain Units',
  SECTIONS_PAGE: 'Manage Template Sections',
  FIND_ORDERS_PAGE: 'Find Orders For New Airmen',
  EMAIL_MANAGEMENT_PAGE: 'Manage MTI Email Addresses',
}

const routeIcons = {
  OPA_USERS_PAGE: <SvgApprove size={ spacing.l } color={ DarkPalette.text.default } />,
  UPLOAD_WEEK_GROUP_PAGE: <SvgExport size={ spacing.l } color={ DarkPalette.text.default } />,
  PROCESS_WEEK_GROUP_PAGE: <DownloadFile size={ spacing.l } color={ DarkPalette.text.default } />,
  VARIABLES_PAGE: <SvgReferrals size={ spacing.l } color={ DarkPalette.text.default } />,
  AFSC_MANAGEMENT_PAGE: <AfscManagement size={ spacing.l } color={ DarkPalette.text.default } />,
  BASE_MANAGEMENT_PAGE: <BaseManagement size={ spacing.l } color={ DarkPalette.text.default } />,
  UNIT_MANAGEMENT_PAGE: <UnitManagement size={ spacing.l } color={ DarkPalette.text.default } />,
  SECTIONS_PAGE: <SvgCms size={ spacing.l } color={ DarkPalette.text.default } />,
  FIND_ORDERS_PAGE: <SvgAfBird size={ spacing.l } color={ DarkPalette.text.default } />,
  EMAIL_MANAGEMENT_PAGE: <SvgGroups size={ spacing.l } color={ DarkPalette.text.default } />,
}

const mapRouteToTitle = route => {
  const routePieceToRemove = ['PAGE', 'EXPORT']
  const title = route.split('_').filter(routePiece => !routePieceToRemove.includes(routePiece)).join(' ')
  const subtitle = SIDEBAR_NAV_SUBTITLES[route]
  return {
    title,
    subtitle,
  }
}

const OPASidebar = ({ currentRoute, logNavigationAction, testId }) => {
  const shouldShowAfscManagementPage = useSelector(state => selectFeatureEnabled(state, 'opaShowAfscManagementPage'))
  const shouldShowSectionEditingPage = useSelector(state => selectFeatureEnabled(state, 'opaShowSectionEditingPage'))
  const opaSidebarRoutes = getOpaSidebarRoutes(shouldShowSectionEditingPage, shouldShowAfscManagementPage)
  const routes = Object.keys(opaSidebarRoutes)
  const [isVisible, setIsVisible] = useState(false)

  return (
    <SidebarWrapper data-testid={ testId }>
      <OpaSidebarIconWrapper onClick={ () => setIsVisible(true) } >
        <Icon data-testid='sidebar-menu-button' name='bars' size='big' />MENU
      </OpaSidebarIconWrapper>
      <StyledSidebar data-testid='sidebar-container' visible={ isVisible }>
        <SidebarHeader data-testid='sidebar-header'>
          <CloseButtonWrapper>
            <Button
              style={ { background: 'none' } }
              onClick={ () => setIsVisible(false) }
              icon={ <SvgClose size={ spacing.m } color={ DarkPalette.text.default } /> }
              data-testid='sidebar-close-button'
            />
          </CloseButtonWrapper>
          <AFBirdWrapper data-testid='af-bird-logo'>
            <SvgAfBird size={ spacing.xxl } color={ DarkPalette.text.ghost } />
            <AFBirdTextWrapper>
              <AFBirdTextSubheader data-testid='af-bird-opa-subheader'>OPA</AFBirdTextSubheader>
              <AFBirdTextHeader data-testid='af-bird-admin-tools-header'>ADMIN TOOLS</AFBirdTextHeader>
            </AFBirdTextWrapper>
          </AFBirdWrapper>
        </SidebarHeader>
        <>
          { routes.map(route => {
            const { title, subtitle } = mapRouteToTitle(route)
            if (opaSidebarRoutes[route] === currentRoute) {
              return (
                <CurrentSidebarItem key={ route }>
                  <IconWrapper>
                    { routeIcons[route] }
                  </IconWrapper>
                  <div>
                    <SidebarItemHeader data-testid='current-sidebar-item-header'>
                      { title }
                    </SidebarItemHeader>
                    <SidebarItemSubheader data-testid='current-sidebar-item-subheader'>
                      { subtitle }
                    </SidebarItemSubheader>

                  </div>
                </CurrentSidebarItem>
              )
            }
            return (
              <SidebarItem key={ route }>
                <IconWrapper>
                  { routeIcons[route] }
                </IconWrapper>
                <Link to={ NAV_ROUTES[route] } onClick={ () => logNavigationAction(route) }>
                  <SidebarItemHeader data-testid='sidebar-item-header'>
                    { title }
                  </SidebarItemHeader>
                  <SidebarItemSubheader data-testid='sidebar-item-subheader'>
                    { subtitle }
                  </SidebarItemSubheader>
                </Link>
              </SidebarItem>
            )
          }) }
        </>
      </StyledSidebar>
    </SidebarWrapper>
  )
}

OPASidebar.propTypes = {
  currentRoute: PropTypes.string,
  logNavigationAction: PropTypes.func,
  testId: PropTypes.string,
}

const mapStateToProps = () => ({})
const mapActionsToProps = dispatch => ({
  logNavigationAction: route => dispatch(logNavigation.request(route)),
})

export default withRouter(connect(mapStateToProps, mapActionsToProps)(OPASidebar))
