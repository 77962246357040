import PropTypes from 'prop-types'
import React from 'react'
import { LargeFieldLabel } from '../../../forms/form.styles'
import colors from '../../../theme/colors'
import spacing from '../../../theme/spacing'
import { ErrorLabel } from '../../pages/UploadWeekGroupPage.styles'

export const OpaLabel = ({ label, error, required }) => {

  return (
    <div style={ { display: 'flex', flexDirection: 'row', paddingBottom: spacing.xs } }>
      <LargeFieldLabel data-testid='field-label'>{ label }{ required && <span style={ { color: colors.red.default } }> *</span> }</LargeFieldLabel>
      { error &&
        <ErrorLabel>
          { error.toUpperCase() }
        </ErrorLabel>
      }
    </div>
  )
}

OpaLabel.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
}
