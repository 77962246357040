import styled from '@emotion/styled'
import { LightPalette } from '../../theme'
import spacing from '../../theme/spacing'
import { borderRadius } from '../../theme/typography'

export const Page = styled.div`
  padding: ${spacing.m}px 0;
`

export const FormDiv = styled.div`
  margin-top: ${spacing.m}px;
`

export const ErrorLabel = styled.div`
  width: fit-content;
  border-radius: ${borderRadius}%;
  background: ${LightPalette.background.level2};
  color: ${LightPalette.text.negative};
  padding: ${spacing.s}px;
`
