import { ThemeProvider } from '@emotion/react'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import PageHeader from '../../components/Page/Header/PageHeader'
import PageTitle from '../../components/Page/PageTitle/PageTitle'
import LogoutButton from '../../navigation/LogoutButton'
import { fetchSquadrons } from '../../redux/sagas/squadron.sagas'
import { createGrantRoleRequest, fetchRoles } from '../../redux/sagas/user.sagas'
import { selectSquadrons } from '../../redux/selectors/squadron.selectors'
import { selectRoles } from '../../redux/selectors/user.selectors'
import { LightPalette } from '../../theme'
import AccessRequested from './AccessRequested'
import RoleRequestForm from './ConnectedRoleRequestForm'
import {
  Content,
  LogoutButtonContainer
} from './role.request.page.styles'

export const ORGANIZATION_NAMES = [
  'Air Force',
  'Air Force Reserve',
  'Air National Guard',
]

export const ORGANIZATION_TYPES = {
  ORGANIZATION: 'ORGANIZATION',
  SQUADRON: 'SQUADRON',
}

class RoleRequestPage extends React.PureComponent {
  state = {
    hasRequestedAccess: false,
    formRoles: [],
    formOrganization: '',
  }

  componentDidMount() {
    this.props.fetchRoles()
    this.props.fetchSquadrons()
  }

  handleRoleRequestSubmit = values => {
    const roles = []
    Object.entries(values.roles).forEach(([key, value]) => {
      if (value) {
        roles.push(key)
      }
    })
    if (ORGANIZATION_NAMES.includes(values.organization)) {
      const organization = { name: values.organization }
      this.props.createGrantRoleRequest(organization, roles, ORGANIZATION_TYPES.ORGANIZATION)
      this.setState({
        hasRequestedAccess: true,
        formRoles: roles,
        formOrganization: organization.name,
      })
    } else {
      const selectedSquadron = this.props.squadrons.find(squadron => squadron.id === values.organization)
      this.props.createGrantRoleRequest(selectedSquadron, roles, ORGANIZATION_TYPES.SQUADRON)
      this.setState({
        hasRequestedAccess: true,
        formRoles: roles,
        formOrganization: selectedSquadron.name,
      })
    }
  }

  render() {
    const { roles, squadrons } = this.props
    const { hasRequestedAccess, formRoles, formOrganization } = this.state

    return (
      <ThemeProvider theme={ LightPalette }>
        <PageHeader>
          <PageTitle title='Role Request' includeMargins={ false } />
          <LogoutButtonContainer>
            <LogoutButton semanticUI={ false } />
          </LogoutButtonContainer>
        </PageHeader>
        <Content>
          { hasRequestedAccess ?
            <AccessRequested roles={ formRoles } organization={ formOrganization } /> :
            <RoleRequestForm roles={ roles } squadrons={ squadrons } onSubmit={ this.handleRoleRequestSubmit } />
          }
        </Content>
      </ThemeProvider>
    )
  }
}

RoleRequestPage.propTypes = {
  roles: PropTypes.object,
  squadrons: PropTypes.array,
  fetchRoles: PropTypes.func,
  fetchSquadrons: PropTypes.func,
  createGrantRoleRequest: PropTypes.func,
}

const bindAction = dispatch => ({
  fetchRoles: () => dispatch(fetchRoles.request({ requestableRoles: true })),
  fetchSquadrons: () => dispatch(fetchSquadrons.request()),
  createGrantRoleRequest:
    (organization, roles, organizationType) => dispatch(createGrantRoleRequest.request({ organization, roles, organizationType })),
})

const mapStateToProps = state => ({
  roles: selectRoles(state),
  squadrons: selectSquadrons(state),
})

const Formed = reduxForm({
  form: 'roleRequest',
})(RoleRequestPage)

export default connect(mapStateToProps, bindAction)(Formed)
