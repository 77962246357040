import styled from '@emotion/styled'
import { Accordion, Divider as SemanticDivider, Icon } from 'semantic-ui-react'

export const AccordionIcon = styled(Icon)`
  color: ${props => props.theme.text.contrast};
`

export const AccordionHeader = styled(Accordion.Title)`
  display: flex;
  justify-content: space-between;
  font-family: stratum2-bold;
  color: ${props => props.theme.text.contrast};
  height: ${props => props.theme.spacing.xs}px;
  padding-bottom: ${props => props.theme.spacing.l}px !important;
`

export const AccordionContent = styled(Accordion.Content)`
  padding-top: 0 !important;
  padding-bottom: 0px !important;
  
  .ui.items > .item {
    margin-top: 0px;
    margin-bottom: ${props => props.theme.spacing.m}px;
  }
`

export const Divider = styled(SemanticDivider)`
  margin-top: 0px !important;
`
