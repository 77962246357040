import styled from '@emotion/styled'
import theme, { DarkPalette, LightPalette } from '../../../theme'

export const Page = styled.div`
  background: ${DarkPalette.background.level0};
  min-height: 100vh;
  padding: ${theme.spacing.m}px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ui.input.error>input {
    border-color: ${LightPalette.background.negative} !important;
    border-width: 1px;
    color: ${LightPalette.background.negative} !important;
  }
  .ui.selection.dropdown.error {
    border-color: ${LightPalette.background.negative} !important;
    border-width: 1px;
    color: ${LightPalette.background.negative} !important;
  }

  @media (max-width: 767px) {
    padding: ${theme.spacing.s}px !important;
  }
`
