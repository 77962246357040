import React from 'react'
import PropTypes from 'prop-types'
import { DarkPalette, LightPalette } from '../../theme'
import { Helmet } from 'react-helmet'
import { Content, Footer, Header, Logo, Main, Page, ShareLink } from '../../pages/share/share.page.styles'
import Share from './Share'
import { NAV_ROUTES } from '../../navigation/routes'
import SvgAirForce from '../../icons/AirForce'
import Card from '../Card/Card'
import CardHeader from '../Card/CardHeader'
import { ThemeProvider } from '@emotion/react'
import CardItem from '../Card/CardItem'
import CardItemDivider from '../Card/CardItemDivider'
import ShareContentTitle from './ShareContentTitle'
import ExploreMoreTeaser from './ExploreMoreTeaser'
import CardFooter from '../Card/CardFooter'
import ShareTeaser from './ShareTeaser'

Shareable.propTypes = {
  sharePath: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  shareContentType: PropTypes.string.isRequired,
  children: PropTypes.node,
}

function Shareable(props) {
  return (
    <ThemeProvider theme={ DarkPalette }>
      <Helmet>
        <meta property='og:url' content={ window.location.href } />
        <meta property='og:type' content='image' />
        <meta property='og:title' content='' />
        <meta property='og:description' content='' />
        { !!props.imageUrl && <meta property='og:image' content={ props.imageUrl } /> }
      </Helmet>
      <Page>
        <ShareLink>
          <Share sharePath={ props.sharePath } />
        </ShareLink>
        <Header style={ { paddingTop: 0 } }>
          <Logo data-testid='aim-high-logo-link' rel='noopener noreferrer' target='_blank' href='https://airforce.com/'>
            <SvgAirForce size={ 79 } />
          </Logo>
        </Header>
        <Main>
          <Card data-testid='Card'>
            <CardHeader
              backgroundImageUrl={ props.imageUrl }
              mutedTitle='USAF'
              title={ props.title }
            />
            <ThemeProvider theme={ LightPalette }>
              { !!props.children &&
                <CardItem style={ { paddingTop: 20 } }>
                  { props.children }
                </CardItem>
              }
              <CardItem>
                <CardItemDivider />
              </CardItem>
              <CardItem>
                <ShareContentTitle shareContentType={ props.shareContentType } />
              </CardItem>
              <CardItem>
                <ExploreMoreTeaser shareContentType={ props.shareContentType } />
              </CardItem>
              <CardFooter style={ { padding: '10px' } } />
            </ThemeProvider>
          </Card>
          <Content>
            <ShareTeaser />
          </Content>
        </Main>
        <Footer>
          <a href={ NAV_ROUTES.PRIVACY_POLICY_PAGE } data-testid='terms-and-conditions' rel='noopener noreferrer' target='_blank'>Terms and Conditions</a>
        </Footer>
      </Page>
    </ThemeProvider>
  )
}

export default Shareable
