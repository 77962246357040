import styled from '@emotion/styled'

export const ShareWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 20px;
  margin: -20px;
  
  > .icon {
    transition: transform 0.15s ease-in-out;
  }

  > a {
    transition: all 0.15s ease-in-out;
    padding: 10px 0;
    display: block;
    fill: ${props => props.theme.text.contrast};
    overflow: hidden;
    width: 0;
    box-sizing: border-box;

    &:hover {
      fill: ${props => props.theme.text.action};
    }
  }

  &:hover {
    > .icon {
      transform: translateX(-60px) rotate(25deg);
      cursor: pointer;
    }

    > a {
      width: 30px;
      padding: 10px;
    }
  } 

  > a > svg {
    width: 20px;
    height: 20px;
  }
`
