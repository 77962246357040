import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { logNavigation } from '../../redux/sagas/amplitudeAnalytics.sagas'
import PropTypes from 'prop-types'
import { LinkComponent } from './NavLink.styles'

const NavLink = ({ logNavigationAction, to: route, children, shouldRefresh = false }) => {
  const props = shouldRefresh ? { href: route } : { to: route }

  return (
    <LinkComponent
      onClick={ () => logNavigationAction(route) }
      { ...props }>
      { children }
    </LinkComponent>
  )
}

NavLink.propTypes = {
  logNavigationAction: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.any,
  shouldRefresh: PropTypes.bool,
}

const mapStateToProps = () => ({})
const mapActionsToProps = dispatch => ({
  logNavigationAction: route => dispatch(logNavigation.request(route)),

})

export default withRouter(connect(mapStateToProps, mapActionsToProps)(NavLink))
