import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { logout } from '../redux/sagas/auth.sagas'
import { Button as SemanticUiButton } from 'semantic-ui-react'
import { SmallButton } from './LogoutButton.styles'
import { LightPalette } from '../theme'
import { NAV_ROUTES } from './routes'

class LogoutButton extends Component {

  handleLogout = () => {
    const { onLogout } = this.props
    onLogout()
  }

  render() {
    const { semanticUI = true, customButtonColor = LightPalette.background.action } = this.props
    return semanticUI ?
      <SemanticUiButton onClick={ this.handleLogout } data-testid='logout-button' >
        Logout
      </SemanticUiButton> :
      <SmallButton buttonColor={ customButtonColor } onClick={ this.handleLogout } data-testid='logout-button' >
        Logout
      </SmallButton>
  }
}

LogoutButton.propTypes = {
  onLogout: PropTypes.func,
  semanticUI: PropTypes.bool,
  customButtonColor: PropTypes.string,
}

const mapStateToProps = () => {
  return {}
}

const mapActionsToProps = dispatch => {
  return {
    onLogout: () => dispatch(logout.request({ redirectPage: NAV_ROUTES.LOGIN_PAGE })),
  }
}

export default connect(mapStateToProps, mapActionsToProps)(LogoutButton)
