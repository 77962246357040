export const userActions = {
  FETCH_ROLES: 'FETCH_ROLES',
  FETCH_ROLE_REQUESTS: 'FETCH_ROLE_REQUESTS',
  FETCH_USER: 'FETCH_USER',
  FETCH_RECRUITER: 'FETCH_RECRUITER',
  FETCH_RECRUITER_INFORMATION: 'FETCH_RECRUITER_INFORMATION',
  FETCH_USERS: 'FETCH_USERS',
  UPDATE_ROLES: 'UPDATE_ROLES',
  UPDATE_ROLE_REQUESTS: 'UPDATE_ROLE_REQUESTS',
  UPDATE_SPECIFIC_ROLE: 'UPDATE_SPECIFIC_ROLE',
  DELETE_SPECIFIC_ROLE: 'DELETE_SPECIFIC_ROLE',
  UPDATE_USER: 'UPDATE_USER',
  FETCH_USERS_BY_EMAIL: 'FETCH_USERS_BY_EMAIL',
  UPDATE_USER_SQUADRON_INFO: 'UPDATE_USER_SQUADRON_INFO',
  DELETE_USER_SQUADRON_INFO: 'DELETE_USER_SQUADRON_INFO',
  FETCH_USER_COUNT: 'FETCH_USER_COUNT',
  FETCH_RECRUITER_EXPERIENCE_DATA: 'FETCH_RECRUITER_EXPERIENCE_DATA',
  CREATE_GRANT_ROLE_REQUEST: 'CREATE_GRANT_ROLE_REQUEST',
}

export const userAccountStatuses = {
  ACTIVE: 'active',
  DISABLED: 'disabled',
}
