import { ThemeProvider } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Checkbox, Dimmer, Loader, Modal, Segment, Table } from 'semantic-ui-react'
import { LightPalette } from '../../../theme'
import { ActionButton, CancelButton, ScrollableSection, StickyModalTableHeader } from '../../pages/Opa.styles'
import { ErrorLabel } from '../../pages/UploadWeekGroupPage.styles'
import { useUpdateWeekGroupRows } from '../../reactQueries/opaQueries'
import { VariableWidthModal } from '../Modals/VariableWidthModal'

ApproveOrdersModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  weekGroupId: PropTypes.string,
  flightPhysicalOrders: PropTypes.array,
}

export function ApproveOrdersModal({ isOpen, setOpen, weekGroupId, flightPhysicalOrders }) {
  const [updatedOrderData, setUpdatedOrderData] = useState(flightPhysicalOrders)
  const [hasChanges, setHasChanges] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const {
    isLoading: flightPhysicalOrdersLoading,
    isError: flightPhysicalOrdersError,
    mutate: saveFlightPhysicalOrders,
  } = useUpdateWeekGroupRows()

  useEffect(() => {
    if (!flightPhysicalOrdersLoading && !flightPhysicalOrdersError && isSubmitted) {
      setHasChanges(false)
      setUpdatedOrderData(null)
      setIsSubmitted(false)
      setOpen(false)
    }
  }, [flightPhysicalOrdersLoading, flightPhysicalOrdersError, setOpen, isSubmitted])

  const checkAllApproved = () => {
    return !updatedOrderData.find(order => !order.isLinkingApproved)
  }

  const renderActionButtons = () => {
    return (
      <>
        <CancelButton onClick={ () => {
          setUpdatedOrderData(null)
          setHasChanges(false)
          setIsSubmitted(false)
          setOpen(false)
        } }>
          Cancel
        </CancelButton>
        {
          flightPhysicalOrdersLoading ?
            <ActionButton loading>Loading</ActionButton> :
            <ActionButton
              content='Update'
              icon='checkmark'
              disabled={ !hasChanges }
              onClick={ () => {
                saveFlightPhysicalOrders({ updatedOrderData, weekGroupId })
                setIsSubmitted(true)
              } }
            />
        }
      </>
    )
  }

  return (
    <VariableWidthModal
      isOpen={ isOpen }
      width='1000px'
      content={
        <ThemeProvider theme={ LightPalette }>
          <Modal.Header>Approve Flight Physical Orders</Modal.Header>
          <ScrollableSection>
            <Dimmer.Dimmable as={ Segment } dimmed={ flightPhysicalOrdersLoading }>
              <Dimmer active={ flightPhysicalOrdersLoading } >
                <Loader>Loading</Loader>
              </Dimmer>
              <Modal.Content style={ { height: '750px' } }>
                { flightPhysicalOrdersError &&
                  <ErrorLabel data-testid='save-failed-error'>Failed to save orders. Please try again.</ErrorLabel>
                }
                { updatedOrderData && updatedOrderData.length > 0 ? (
                  <Table compact striped celled>
                    <StickyModalTableHeader>
                      <Table.Row>
                        <Table.HeaderCell>Sp. Order Number</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>SSAN</Table.HeaderCell>
                        <Table.HeaderCell>Original Flight</Table.HeaderCell>
                        <Table.HeaderCell>Current Flight</Table.HeaderCell>
                        <Table.HeaderCell>PAFSC</Table.HeaderCell>
                        <Table.HeaderCell>CAFSC</Table.HeaderCell>
                        <Table.HeaderCell style={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }>
                          Approved?
                          <Checkbox
                            toggle
                            data-testid='master-approval-toggle'
                            checked={ checkAllApproved() }
                            onChange={ (_e, eventData) => {
                              setHasChanges(true)
                              const updatedOrdersCopy = updatedOrderData.map(order => {
                                order = { ...order, isLinkingApproved: eventData.checked }
                                return order
                              })
                              setUpdatedOrderData(updatedOrdersCopy)
                            } }
                          />
                        </Table.HeaderCell>
                      </Table.Row>
                    </StickyModalTableHeader>
                    <Table.Body>
                      {
                        updatedOrderData.map((order, orderIndex) => {
                          return (
                            <Table.Row key={ order.sort } data-testid='order-row'>
                              <Table.Cell>
                                { order.specialOrderNumber }
                              </Table.Cell>
                              <Table.Cell>
                                { order.name }
                              </Table.Cell>
                              <Table.Cell>
                                { order.maskedSocial }
                              </Table.Cell>
                              <Table.Cell>
                                { order.originalFlight }
                              </Table.Cell>
                              <Table.Cell>
                                { order.currentFlight }
                              </Table.Cell>
                              <Table.Cell>
                                { order.primaryAfsc }
                              </Table.Cell>
                              <Table.Cell>
                                { order.controlAfsc }
                              </Table.Cell>
                              <Table.Cell textAlign='right'>
                                <Checkbox
                                  toggle
                                  data-testid='approval-toggle'
                                  checked={ order.isLinkingApproved }
                                  onChange={ (_e, eventData) => {
                                    setHasChanges(true)
                                    const updatedOrdersCopy = [...updatedOrderData]
                                    updatedOrdersCopy[orderIndex] = { ...updatedOrdersCopy[orderIndex], isLinkingApproved: eventData.checked }
                                    setUpdatedOrderData(updatedOrdersCopy)
                                  } }
                                />
                              </Table.Cell>
                            </Table.Row>
                          )
                        })
                      }
                    </Table.Body>
                  </Table>
                ) : (
                  <ErrorLabel data-testid='no-orders-error'>There are no flight physical orders to approve in this week group.</ErrorLabel>
                ) }
              </Modal.Content>
            </Dimmer.Dimmable>
          </ScrollableSection>
          <Modal.Actions>
            { renderActionButtons() }
          </Modal.Actions>
        </ThemeProvider>
      }
    />
  )
}
