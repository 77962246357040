import React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from '@emotion/css'

const DEFAULT_CLASS = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const PageWrapper = ({ children, className }) => {
  const classNames = cx(DEFAULT_CLASS, className)

  return (
    <div className={ classNames }>
      { children }
    </div>
  )
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default PageWrapper
