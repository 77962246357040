import * as React from 'react';

function Notification({ size = 30, color, ...rest }) {
  return (
    <svg width={ size * 1.04 } height={ size } viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M23.0725 10.6706L21.8225 9.90358C20.5691 9.13453 19.9425 8.75 19.25 8.75C18.5575 8.75 17.9309 9.13453 16.6775 9.90358L15.4275 10.6706C14.2419 11.3981 13.649 11.7619 13.3245 12.348C13 12.9341 13 13.6411 13 15.0552V22.3868C13 24.7971 13 26.0024 13.7323 26.7511C14.4645 27.5 15.643 27.5 18 27.5H20.5C22.857 27.5 24.0355 27.5 24.7677 26.7511C25.5 26.0024 25.5 24.7971 25.5 22.3868V15.0552C25.5 13.6411 25.5 12.9341 25.1755 12.348C24.851 11.7619 24.2581 11.3981 23.0725 10.6706Z" stroke={ color } stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18 8.88511C17.2015 8.11888 16.7239 7.71606 16.1218 7.56473C15.4218 7.38878 14.6864 7.5861 13.2156 7.98075L11.7489 8.37434C10.3575 8.74768 9.66186 8.93435 9.17859 9.39538C8.6953 9.85641 8.50803 10.512 8.13349 11.8232L6.19159 18.6214C5.55313 20.8565 5.2339 21.974 5.77579 22.8545C6.2417 23.6115 8.10438 24.5424 9.875 25" stroke={ color } stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.6184 12.5C19.67 11.8007 20.6035 10.6786 21.1835 9.28316C22.381 6.40202 21.618 3.44655 19.4795 2.68192C17.3409 1.91729 14.6364 3.63305 13.439 6.51419C13.2388 6.99584 13.0934 7.47957 13 7.95479" stroke={ color } stroke-width="1.5" stroke-linecap="round"/>
    </svg>
  );
}

export default Notification;
