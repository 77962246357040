import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router'
import { NAV_ROUTES } from './routes'
import { selectIsAuthenticated } from '../redux/selectors/auth.selectors'

class AuthRoute extends React.PureComponent {
  
  render() {
    const { component: RoutedComponent, isAuthenticated, ...rest } = this.props
    return (
      <Route
        { ...rest }
        render={ props => (isAuthenticated ? (
          <RoutedComponent { ...props } />
        ) : (
          <Redirect
            to={ {
              pathname: NAV_ROUTES.LOGIN_PAGE,
              state: { from: props.location },
            } }
          />
        ))
        }
      />
    )
  }
}

AuthRoute.propTypes = {
  component: PropTypes.node,
  isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => {
  return {
    isAuthenticated: selectIsAuthenticated(state),
  }
}

export default connect(mapStateToProps, null)(AuthRoute)
