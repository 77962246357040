/* eslint-disable react/display-name */
import PropTypes from 'prop-types'
import React from 'react'
import { Editor, Element as SlateElement, Transforms } from 'slate'
import { useSlate } from 'slate-react'
import colors from '../../../theme/colors'
import spacing from '../../../theme/spacing'
import { fontSizes } from '../../../theme/typography'

const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

export const OpaButton = React.forwardRef(({ active, reversed, ...props }, ref) => {
  let colorScheme = active ? colors.black.default : colors.gray.mid
  if (reversed) {
    colorScheme = active ? colors.white.default : colors.gray.light
  }
  return (
    <span
      { ...props }
      ref={ ref }
      style={ {
        cursor: 'pointer',
        color: colorScheme,
      } }
    />
  )
}
)
OpaButton.propTypes = {
  active: PropTypes.bool,
  reversed: PropTypes.bool,
}

export const OpaIcon = React.forwardRef(({ ...props }, ref) => (
  <span
    { ...props }
    ref={ ref }
    style={ {
      fontSize: fontSizes.m,
      verticalAlign: 'text-bottom',

    } }
  />
)
)

export const OpaMenu = React.forwardRef(({ ...props }, ref) => (
  <div
    { ...props }
    ref={ ref }
  />
)
)

export const OpaToolbar = React.forwardRef(({ ...props }, ref) => (
  <OpaMenu
    { ...props }
    ref={ ref }
    style={ {
      padding: `${spacing.s}px 0 ${spacing.s}px`,
      borderBottom: `1px solid ${colors.gray.light}`,
    } }
  />
)
)

export const OpaElement = ({ attributes, children, element }) => {
  const style = { textAlign: element.align, margin: 0 }
  return (
    <p style={ style } { ...attributes } >
      { children }
    </p>
  )
}
OpaElement.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.array,
  element: PropTypes.object,
}

export const OpaHelpButton = ({ icon, onClick, active }) => {
  return (
    <OpaButton
      active={ active }
      onMouseDown={ onClick }>
      <OpaIcon>{ icon }</OpaIcon>
    </OpaButton>
  )
}

OpaHelpButton.propTypes = {
  icon: PropTypes.object,
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

export const OpaMarkButton = ({ format, icon }) => {

  const isMarkActive = (editor, markFormat) => {
    const marks = Editor.marks(editor)
    return marks ? marks[markFormat] === true : false
  }

  const toggleMark = (editor, markFormat) => {
    const isActive = isMarkActive(editor, markFormat)

    if (isActive) {
      Editor.removeMark(editor, markFormat)
    } else {
      Editor.addMark(editor, markFormat, true)
    }
  }
  const slateEditor = useSlate()
  return (
    <OpaButton
      active={ isMarkActive(slateEditor, format) }
      onMouseDown={ event => {
        event.preventDefault()
        toggleMark(slateEditor, format)
      } }>
      <OpaIcon>{ icon }</OpaIcon>
    </OpaButton>
  )
}
OpaMarkButton.propTypes = {
  format: PropTypes.string,
  icon: PropTypes.object,
}
const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) {
    return false
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n => !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )

  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : format,
    }
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const OpaBlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <OpaButton
      active={ isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      ) }
      onMouseDown={ event => {
        event.preventDefault()
        toggleBlock(editor, format)
      } }>
      <OpaIcon>{ icon }</OpaIcon>
    </OpaButton>
  )
}
OpaBlockButton.propTypes = {
  format: PropTypes.string,
  icon: PropTypes.object,
}

export const OpaLeaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{ children }</strong>
  }

  if (leaf.underline) {
    children = <u>{ children }</u>
  }

  return <span { ...attributes }>{ children }</span>
}
OpaLeaf.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.object,
  leaf: PropTypes.object,
}
