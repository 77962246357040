/*eslint-disable*/
import * as React from "react";

function Menu(props) {
  return (
    <svg width={20} height={20} {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={props.color}
      >
        <path d="M.833 17.5h18.334M.833 2.5h18.334M.833 10h18.334" />
      </g>
    </svg>
  );
}

export default Menu;
