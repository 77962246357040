import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'semantic-ui-react'
import { ActionButton, CancelButton } from '../../pages/Opa.styles'

const ArmsConfirmationModal = props => {
  return (
    <Modal
      open={ props.isOpen }>
      <Modal.Header>Confirm Ready Status for ARMS-LC</Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to mark this Week Group as Ready for ARMS-LC? You will no longer be able to edit or delete orders.</p>
      </Modal.Content>
      <Modal.Actions>
        <CancelButton onClick={ props.onCancel }>
          Cancel
        </CancelButton>
        <ActionButton
          content='Mark Ready'
          icon='checkmark'
          onClick={ props.onReady }
        />
      </Modal.Actions>
    </Modal>
  )
}

ArmsConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onReady: PropTypes.func,
}

export default ArmsConfirmationModal
