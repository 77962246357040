import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Item, Image } from 'semantic-ui-react'
import spacing from '../../theme/spacing'

Intro.propTypes = {
  avatarUrl: PropTypes.string,
  palette: PropTypes.object.isRequired,
  rank: PropTypes.string,
  name: PropTypes.string.isRequired,
  isLoadingRecruiterExperienceData: PropTypes.bool,
  isMobile: PropTypes.bool,
}

export const Rank = styled(Item.Meta)`
  font-family: stratum2-regular;
  text-transform: uppercase;
  color: ${props => props.palette.text.contrast} !important;
`
export const Name = styled(Item.Header)`
  font-family: stratum2-bold !important;
  text-transform: uppercase;
  color: ${props => props.palette.text.contrast} !important;
`

const IntroWrapper = styled(Item)`
    margin-top: 0;
    margin-bottom: ${spacing.m};
    display: flex;
    flex-direction: column;
    align-items: center;
`

const IntroNameAndRank = styled(Item.Content)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

function Intro({ avatarUrl, palette, rank, name, isLoadingRecruiterExperienceData, isMobile }) {
  return (
    <Item.Group>
      <IntroWrapper>
        <IntroNameAndRank style={ { margin: spacing.l } }>
          { rank &&
            <Rank
              data-testid='rank'
              palette={ palette }
              style={ { display: 'flex', justifyContent: 'center' } } >
              { rank }
            </Rank> }
          { name && <Name data-testid='name' palette={ palette }>{ name }</Name> }
        </IntroNameAndRank>
        <Image>
          { !!avatarUrl && (
            <Item.Image
              only='computer tablet'
              data-testid='Custom Avatar'
              src={ avatarUrl }
              size={ isMobile ? 'small' : 'medium' }
            />
          )
          }
          { (!isLoadingRecruiterExperienceData && !avatarUrl) &&
            <Item.Image
              data-testid='DefaultAvatar'
              palette={ palette }
              src={ require('../../assets/recruiter-website-placeholder.jpg') }
              size='medium'
            /> }
        </Image>
      </IntroWrapper>
    </Item.Group>
  )
}

export default Intro
