import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import { Form, Button } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import axios from 'axios'

import ComponentLogo from '../../components/ComponentLogo/ComponentLogo'
import { DarkPalette } from '../../theme'
import spacing from '../../theme/spacing'
import { fontFamilies } from '../../theme/typography'
import { Header } from '../share/share.page.styles'
import { NAV_ROUTES } from '../../navigation/routes'
import { logEventParams, logPageView } from '../../redux/sagas/amplitudeAnalytics.sagas'
import { CATEGORY } from '../../redux/constants/analytics.constants'
import config from '../../config'

const UNSUBSCRIBE_ACTION = 'UNSUBSCRIBE_ACTION'
const CONTACT_EMAIL = 'afrs.aha.workflow@us.af.mil'

const afreServiceUrl = config.apiUrl

const Page = styled.div`
  background: ${({ theme }) => theme.background.level0};
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`

const Container = styled.div`
  background: ${({ theme }) => theme.content.contrast};
  width: 600px;
  align-self: center;
  border-radius: 8px;
  text-align: center;

  > h1 {
    font-family: ${() => fontFamilies.openSansRegular};
    color: ${props => props.theme.brand.brand};
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }

`

const NormalText = styled.p`
  font-family: ${() => fontFamilies.openSansRegular};
  color: ${props => props.theme.brand.brand};
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
`

const ErrorText = styled.p`
  font-family: ${() => fontFamilies.openSansRegular};
  color: ${props => props.theme.system.error};
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
`

const Unsubscribe = ({ match, location, history }) => {
  const dispatch = useDispatch()
  
  const [unsubscribeError, setUnsubscribeError] = useState(false)

  const { params: { emailPreferencesId } } = match
  const { search } = location

  const query = React.useMemo(() => new URLSearchParams(search), [search])
  const email = query.get('email')
  const component = query.get('component')

  useEffect(() => {
    dispatch(logPageView.request(NAV_ROUTES.UNSUBSCRIBE))
  }, [dispatch])

  const handleSubmit = async () => {
    try {
      const url = `${afreServiceUrl}/user/emailPreferences/unsubscribe/${emailPreferencesId}`
      const response = await axios.post(url)

      if (response.status === 200) {
        await dispatch(logEventParams.request({ type: CATEGORY.ACTION, label: UNSUBSCRIBE_ACTION, value: { emailPreferencesId, email, component } }))
        setUnsubscribeError(false)
        history.push(NAV_ROUTES.UNSUBSCRIBE_CONFIRMATION)
      } else {
        setUnsubscribeError(true)
      }

    } catch (e) {
      setUnsubscribeError(true)
    }
  }

  return (
    <ThemeProvider theme={ DarkPalette }>
      <Page>
        <Header style={ { paddingTop: spacing.xl, paddingBottom: spacing.xl } }>
          <ComponentLogo recruiterComponent={ component } />
        </Header>

        <Form onSubmit={ handleSubmit }>
          <Container style={ { paddingTop: spacing.xl, paddingBottom: spacing.xl, paddingLeft: spacing.l, paddingRight: spacing.l } }>
            <h1>Unsubscribe from Aim High Reminder Emails</h1>
            { unsubscribeError ?
              <ErrorText>
                We were unable to unsubscribe you at this time.
                Please refresh and try again or contact <a href={ `mailto:${CONTACT_EMAIL}?subject=Unsubscribe` }>{ CONTACT_EMAIL }</a>.
              </ErrorText> :
              <NormalText>
                Are you sure you want to unsubscribe<br /><span style={ { fontWeight: 700 } }>{ email } </span>from these emails?
              </NormalText>
            }
            
            <Button
              style={ { paddingLeft: spacing.xl, paddingRight: spacing.xl, marginTop: spacing.m, textTransform: 'uppercase' } }
              primary
              role='button'
              type='submit'>
              Unsubscribe
            </Button>
          </Container>
        </Form>
      </Page>
    </ThemeProvider>
  )
}

Unsubscribe.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
}

export default Unsubscribe
