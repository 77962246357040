import * as React from 'react';

function ChevronRight({ size = 24, color, ...rest }) {
  return (
    <svg width={ size } height={ size * 1.05 } viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13 10.5C13 10.6513 12.9586 10.7997 12.8758 10.9277L8.17094 18.2007C7.96002 18.5266 7.5563 18.5964 7.26646 18.3607C6.9779 18.1265 6.91321 17.6727 7.12412 17.3454L11.5534 10.5L7.12412 3.65468C6.91579 3.3303 6.9779 2.87647 7.26646 2.63937C7.5563 2.40372 7.96002 2.47354 8.17094 2.79937L12.8758 10.0724C12.9586 10.2004 13 10.3488 13 10.5Z" fill={ color }/>
    </svg>
  );
}

export default ChevronRight;
