import styled from '@emotion/styled'

export const PrivacyPolicyContainer = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: props.theme.colors.page.background,
  color: props.theme.colors.white.default,
  // scrollSnapType: 'y proximity',
  padding: `${props.theme.spacing.m}px ${props.theme.spacing.m}px`,
  wordWrap: 'break-word',
}))
  
export const SectionContainer = styled.div(props => ({
  maxWidth: '800px',
  width: '100%',
  marginBottom: `${props.theme.spacing.m}px`,
}))
  
export const SectionTitle = styled.div(props => ({
  fontSize: '18px',
  fontWeight: '300',
  textTransform: 'uppercase',
  color: props.theme.colors.white.default,
  marginBottom: `${props.theme.spacing.m}px`,
}))
  
export const SectionBody = styled.div(props => ({
  fontSize: '14px',
  fontWeight: '300',
  color: props.theme.colors.white.default,
  marginBottom: `${props.theme.spacing.m}px`,
}))

export const SectionBodyIndent = styled.div(props => ({
  fontSize: '14px',
  fontWeight: '300',
  color: props.theme.colors.white.default,
  marginBottom: `${props.theme.spacing.m}px`,
  marginLeft: `${props.theme.spacing.m}px`,
}))

export const LoginButtonContainer = styled.div(props => ({
  margin: `${props.theme.spacing.m}px ${props.theme.spacing.m}px`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: `${props.theme.spacing.xxl}px`,
  width: '15%',
}))
