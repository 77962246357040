import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { change, getFormError, getFormSyncErrors, hasSubmitFailed, reduxForm } from 'redux-form'
import AimHighModal from '../../components/Modal/AimHighModal'
import ThankYouCard from '../../components/ThankYouCard/ThankYouCard'
import { DEGREE_RECEIVED_OPTIONS } from '../../redux/constants/form.values.constants'
import { createAfrissLeadSaga, createReferral } from '../../redux/sagas/referral.sagas'
import {
  selectCreateLeadError,
  selectGeneralInfo,
  selectLeadSubmitting,
  selectReferralError,
  selectReferralLoading
} from '../../redux/selectors/referral.selectors'
import ReferralInformationSection from './ReferralInformationSection'
import ReferralSubmissionContainer from './ReferralSubmissionContainer'
import ReferredByFormHeader from './ReferredByFormHeader'
import ReferrerInfoSection from './ReferrerInfoSection'
import ReferringFormHeader from './ReferringFormHeader'
import { useViewQrCode } from '../../queries/qrCodeQueries'

const REFER_A_FRIEND_QUERY_PARAMETER = 'referAFriend'
const REFERRER_ID_QUERY_PARAMETER = 'referrerId'

ReferralForm.propTypes = {
  handleSubmit: PropTypes.func,
  onCreateReferral: PropTypes.func,
  onCreateAfrissLead: PropTypes.func,
  createReferralSuccess: PropTypes.bool,
  createAfrissLeadSuccess: PropTypes.bool,
  location: PropTypes.object,
  referralLoading: PropTypes.bool,
  leadSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  fromReferralPage: PropTypes.bool,
  formSyncErrors: PropTypes.any,
  referralError: PropTypes.any,
  createLeadError: PropTypes.any,
  recruiterId: PropTypes.string,
}

function ReferralForm(props) {
  const [piiVerified, setPiiVerified] = useState(false)
  const [openWarningModal, setOpenWarningModal] = useState(false)
  const [modalHeaderText, setModalHeaderText] = useState('')
  const [modalDescriptionText, setModalDescriptionText] = useState('')
  const [cancelButtonText, setCancelButtonText] = useState('')
  const [confirmButtonText, setConfirmButtonText] = useState('')
  const params = new URLSearchParams(props.location.search)
  const referAFriend = params.get(REFER_A_FRIEND_QUERY_PARAMETER) === 'true'
  const referrerId = params.get(REFERRER_ID_QUERY_PARAMETER)
  const referrerFirstName = params.get('referrerFirstName') || ''
  const referrerLastName = params.get('referrerLastName') || ''
  const unknownReferrer = params.get('unknownReferrer') === 'true'
  const dispatch = useDispatch()

  const qrSort = params.get('qrSort')
  if (qrSort) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useViewQrCode(qrSort)
  }

  const updateFieldValue = (formId, fieldId, value) => {
    dispatch(change(formId, fieldId, value))
  }

  const handleReferralSubmit = values => {
    const { onCreateReferral, onCreateAfrissLead, recruiterId, fromReferralPage } = props
    const leadId = params.get('id')

    if (values.referrerInfo) {
      let hasFilledValue = false
      let hasEmptyValue = false
      const referrerFormValues =
        // eslint-disable-next-line max-len
        [values.referrerInfo.referrerFirstName, values.referrerInfo.referrerLastName, values.referrerInfo.referrerEmailAddress, values.referrerInfo.referrerDodIdentification]
      for (const value of referrerFormValues) {
        if (value) {
          hasFilledValue = true
        } else {
          hasEmptyValue = true
        }
      }

      if (hasFilledValue && hasEmptyValue) {
        setModalHeaderText('"Referred By" Information Incomplete')
        // eslint-disable-next-line max-len
        setModalDescriptionText('Please complete the entire "Referred By" section to continue. If you prefer not to fill in referrer information, you may clear the section entirely before submitting the form. Please note that incorrect referrer details could lead to the inability to validate the referral.')
        setCancelButtonText('Clear Referrer Fields')
        setConfirmButtonText('Complete Referrer Fields')
        setOpenWarningModal(true)
        return
      } else if (values.referrerInfo.referrerEmailAddress && !values.referrerInfo.referrerEmailAddress.toLowerCase().endsWith('.mil')) {
        setModalHeaderText('Referring email must be a .mil email')
        // eslint-disable-next-line max-len
        setModalDescriptionText('Your email must be a valid .mil address to participate in the STARR program.  Please note that an incorrect referrer email could lead to the inability to validate the referral.')
        setCancelButtonText('Close')
        setConfirmButtonText('Update Email')
        setOpenWarningModal(true)
        return
      }
    }

    values = { ...values, leadId, recruiterId, fromReferralPage, referAFriend, unknownReferrer, referrerId }
    if (leadId || recruiterId) {
      onCreateAfrissLead(values)
    } else {
      onCreateReferral({ ...values, qrSort })
    }
  }

  const renderSubmissionContainer = selfSubmittingReferral => {

    return (
      <ReferralSubmissionContainer
        createAfrissLeadSuccess={ props.createAfrissLeadSuccess }
        createLeadError={ props.createLeadError }
        createReferralSuccess={ props.createReferralSuccess }
        formSyncErrors={ props.formSyncErrors }
        leadSubmitting={ props.leadSubmitting }
        referAFriend={ referAFriend }
        selfSubmittingReferral={ selfSubmittingReferral }
        piiVerified={ piiVerified }
        referralError={ props.referralError }
        referralLoading={ props.referralLoading }
        setPiiVerified={ setPiiVerified }
        submitFailed={ props.submitFailed }
      />
    )
  }

  const renderForm = (isCreateReferralSuccess, isLeadCreationSuccess) => {
    const {
      handleSubmit,
    } = props

    return (
      <form onSubmit={ handleSubmit(handleReferralSubmit) }>
        <AimHighModal
          isOpen={ openWarningModal }
          modalHeader={ modalHeaderText }
          modalContent={ modalDescriptionText }
          cancelButtonText={ cancelButtonText }
          cancelHandler={ () => {
            if (cancelButtonText !== 'Close') {
              updateFieldValue('referral', 'referrerInfo.referrerFirstName', '')
              updateFieldValue('referral', 'referrerInfo.referrerLastName', '')
              updateFieldValue('referral', 'referrerInfo.referrerEmailAddress', '')
              updateFieldValue('referral', 'referrerInfo.referrerDodIdentification', '')
            }

            setOpenWarningModal(false)
          } }
          confirmButtonText={ confirmButtonText }
          confirmHandler={ () => {
            const referrerEmailAddressFields = document.querySelectorAll("[name='referrerInfo.referrerEmailAddress']")
            const referrerDodIdentificationFields = document.querySelectorAll("[name='referrerInfo.referrerDodIdentification']")

            if (referrerEmailAddressFields.length > 0) {
              referrerEmailAddressFields[0].focus()
            } else if (referrerDodIdentificationFields.length > 0) {
              referrerDodIdentificationFields[0].focus()
            }
            setOpenWarningModal(false)
          } }
        />
        { referAFriend ? (
          <ReferringFormHeader />
        ) : (
          <></>
        ) }
        { referrerId || unknownReferrer ? (
          <ReferredByFormHeader
            referrerFirstName={ referrerFirstName }
            referrerLastName={ referrerLastName }
          />
        ) : (
          <></>
        ) }

        <ReferralInformationSection
          degreeOptions={ DEGREE_RECEIVED_OPTIONS }
          referAFriend={ referAFriend }
          selfSubmittingReferral={ !!referrerId || unknownReferrer }
        />

        { referAFriend || unknownReferrer ? (
          <ReferrerInfoSection unknownReferrer={ unknownReferrer } />
        ) : (
          <></>
        ) }
        { isCreateReferralSuccess || isLeadCreationSuccess ?
          null :
          renderSubmissionContainer(!!referrerId) }
      </form>
    )
  }

  const {
    createReferralSuccess,
    createAfrissLeadSuccess,
  } = props
  if (createReferralSuccess || createAfrissLeadSuccess) {
    return <ThankYouCard referAFriend={ referAFriend } />
  } else {
    return (renderForm(createReferralSuccess, createAfrissLeadSuccess))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    createReferralSuccess: state.referral.createReferralSuccess,
    createAfrissLeadSuccess: state.referral.createAfrissLeadSuccess,
    submitFailed: hasSubmitFailed('referral')(state),
    formError: getFormError('referral')(state),
    referralError: selectReferralError(state),
    createLeadError: selectCreateLeadError(state),
    formSyncErrors: getFormSyncErrors('referral')(state),
    referralLoading: selectReferralLoading(state),
    leadSubmitting: selectLeadSubmitting(state),
    initialValues: {
      referrerInfo: ownProps.initialValues,
      generalInfo: selectGeneralInfo,
    },
  }
}

const mapActionsToProps = dispatch => {
  return {
    onCreateReferral: params => dispatch(createReferral.request(params)),
    onCreateAfrissLead: params => dispatch(createAfrissLeadSaga.request(params)),
  }
}

const Formed = reduxForm({
  form: 'referral',
  destroyOnUnmount: false,
})(ReferralForm)
const ConnectedReferralForm = connect(mapStateToProps, mapActionsToProps)(Formed)

export default withRouter(ConnectedReferralForm)
