const fontFamilies = {
  openSansRegular: '"Open Sans", sans-serif',
  stratum2Bold: 'stratum2-bold',
  stratum2Regular: 'stratum2-regular',
}

const fontSizes = {
  xxs: 8,
  xs: 10,
  s: 12,
  m: 16,
  l: 20,
  xl: 24,
  xxl: 32,
  xxxl: 48,
}
// Line height
const lineHeight = 1.5

// Border radius
const borderRadius = 8

// Font weights
const fontWeights = {
  light: '300',
  regular: '400',
  medium: '600',
  bold: '700',
}

export {
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeight,
  borderRadius
}
