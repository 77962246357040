import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import 'semantic-ui-css/semantic.min.css'
import { Header, Table, Input } from 'semantic-ui-react'
import { selectEvents, getEventsSaga, SET_SELECTED_EVENT, TOGGLE_EVENT_MODAL } from './event.duck'
import EventModal from './EventModal'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import sortBy from 'lodash.sortby'
import { MAX_LENGTH } from '../../redux/constants/form.values.constants'
import { getStandardAmericanDate, isValidDate } from '../../api/date.util'

const EventListContainer = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  width: '50%',
  padding: `${props.theme.spacing.m}px ${props.theme.spacing.m}px`,
  marginLeft: 'auto',
  marginRight: 'auto',
}))

const HeaderContainer = styled.div(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: `${props.theme.spacing.l}px`,
  marginBottom: `${props.theme.spacing.m}px`,
}))

const EventRow = styled(Table.Row)`
  cursor: pointer;
`

const selector = formValueSelector('eventSearchForm')

class EventListPage extends Component {

  state = {
    column: null,
    listOfEvents: [],
    direction: null,
  }

  handleSort = clickedColumn => () => {
    const { column, listOfEvents, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        listOfEvents: sortBy(listOfEvents, [clickedColumn]),
        direction: 'ascending',
      })

      return
    }
    listOfEvents.reverse()
    this.setState({
      listOfEvents,
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }

  componentDidMount() {
    this.props.onGetEvents()
    if (this.state.listOfEvents.length === 0) {
      this.setState({ listOfEvents: this.props.events })
    }
  }

  componentDidUpdate(prevProps) {
    // If the current search term is different from the previous search term or new events have been loaded,
    // then apply the search filter
    if (this.props.searchTerms !== prevProps.searchTerms || prevProps.events.length !== this.props.events.length) {
      this.applySearchFilter(this.props.searchTerms)
    }
  }

  handleRowSelected = event => {
    const { onSetSelectedEvent, onToggleEventModal } = this.props
    onSetSelectedEvent(event)
    onToggleEventModal()
  }

  applySearchFilter = searchTerms => {
    const { events } = this.props
    if (searchTerms) {
      const result = events.filter(event => event.name && event.name.toLowerCase().trim().includes(searchTerms.toLowerCase().trim()))
      return this.setState({ listOfEvents: result })
    } else {
      return this.setState({ listOfEvents: events })
    }
  }

  render() {
    const { column, direction, listOfEvents } = this.state

    return (
      <EventListContainer>
        <HeaderContainer>
          <Header as='h2' style={ { marginBottom: 0 } }>Events</Header>
          <Field
            id='search'
            name='search'
            label='Search'
            component={ Input }
            type='text'
            style={ { width: '50%' } }
            maxLength={ MAX_LENGTH.LONG }
          />
        </HeaderContainer>
        <Table compact striped celled selectable sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={ column === 'name' ? direction : null }
                onClick={ this.handleSort('name') }>
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={ column === 'city' ? direction : null }
                onClick={ this.handleSort('city') }>
                City
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={ column === 'state' ? direction : null }
                onClick={ this.handleSort('state') }>
                State
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={ column === 'startDate' ? direction : null }
                onClick={ this.handleSort('startDate') }>
                Start Date
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={ column === 'endDate' ? direction : null }
                onClick={ this.handleSort('endDate') }>
                End Date
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={ column === 'eventTypeNm' ? direction : null }
                onClick={ this.handleSort('eventTypeNm') }>
                Event Type
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            { listOfEvents && listOfEvents.map(({ id, name, city, state, startDate, endDate, eventTypeNm }) => {
              const event = { id, name, city, state, startDate, endDate, eventTypeNm }
              return (
                <EventRow key={ id } onClick={ () => this.handleRowSelected(event) }>
                  <Table.Cell>{ name }</Table.Cell>
                  <Table.Cell>{ city }</Table.Cell>
                  <Table.Cell>{ state }</Table.Cell>
                  <Table.Cell>{ isValidDate(startDate) ? getStandardAmericanDate(startDate) : '' }</Table.Cell>
                  <Table.Cell>{ isValidDate(endDate) ? getStandardAmericanDate(endDate) : '' }</Table.Cell>
                  <Table.Cell>{ eventTypeNm }</Table.Cell>
                </EventRow>
              )
            }) }
          </Table.Body>
        </Table>
        <EventModal />
      </EventListContainer>
    )
  }
}

EventListPage.propTypes = {
  onGetEvents: PropTypes.func,
  events: PropTypes.array,
  onSetSelectedEvent: PropTypes.func,
  onToggleEventModal: PropTypes.func,
  searchTerms: PropTypes.string,
}

const mapStateToProps = state => {
  return {
    events: selectEvents(state),
    searchTerms: selector(state, 'search'),
  }
}

const mapActionsToProps = dispatch => {
  return {
    onGetEvents: () => dispatch(getEventsSaga.request()),
    onSetSelectedEvent: event => dispatch({ type: SET_SELECTED_EVENT, event }),
    onToggleEventModal: () => dispatch({ type: TOGGLE_EVENT_MODAL }),
  }
}

const ConnectedEventListPage = connect(mapStateToProps, mapActionsToProps)(EventListPage)
export default reduxForm({ form: 'eventSearchForm' })(ConnectedEventListPage)
