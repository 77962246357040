import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { Button, Form, Modal } from 'semantic-ui-react'
import InputField from '../../components/Forms/InputField'
import { MAX_LENGTH } from '../../redux/constants/form.values.constants'
import { updateFlight } from '../../redux/sagas/squadron.sagas'
import { required } from '../validation'
import { SubmitButtonWrapper } from './squadron.form.styles'

class FlightUpdateForm extends Component {
  state = { isEditModalOpen: false }

  open = () => this.setState({ isEditModalOpen: true })
  close = () => this.setState({ isEditModalOpen: false })

  handleFlightSubmit = values => {
    const { updateFlight: updateThisFlight, flight } = this.props
    values.id = flight.id
    values.flightId = flight.flightId
    updateThisFlight(values)
    this.close()
  }

  renderModal = () => {
    const { handleSubmit, flight } = this.props
    return (
      <Modal open={ this.state.isEditModalOpen } closeIcon onClose={ () => this.close() }>
        <Modal.Header data-testid='edit-flight-header'>Edit Flight { flight.name }</Modal.Header>
        <Modal.Content>
          <Form onSubmit={ handleSubmit(this.handleFlightSubmit) }>
            <Field
              id='name'
              name='name'
              label='Flight Name'
              component={ InputField }
              validate={ required }
              maxLength={ MAX_LENGTH.STANDARD }
            />
            <SubmitButtonWrapper >
              <Button data-testid='update-flight-button' primary type='submit'>Update Flight</Button>
            </SubmitButtonWrapper >
          </Form>
        </Modal.Content>
      </Modal>
    )
  }

  render() {
    return (
      <>
        { this.renderModal() }
        <Button onClick={ () => this.open() }>Edit</Button>
      </>
    )
  }
}

FlightUpdateForm.propTypes = {
  handleSubmit: PropTypes.func,
  updateFlight: PropTypes.func,
  flight: PropTypes.object,
}

const mapStateToProps = (_state, ownProps) => {
  return {
    initialValues: {
      name: ownProps.flight.name,
    },
  }
}

const mapActionsToProps = dispatch => {
  return {
    updateFlight: params => dispatch(updateFlight.request(params)),
  }
}

const Formed = reduxForm({
  enableReinitialize: true,
})(FlightUpdateForm)
const ConnectedFlightUpdateForm = connect(
  mapStateToProps,
  mapActionsToProps
)(Formed)

export default withRouter(ConnectedFlightUpdateForm)
