import React from 'react'
import { Field } from 'redux-form'
import {
  Section,
  TwoColumnGrid,
  SectionTitle,
  ThreeColumnGrid
} from '../afcepLandingPage/AfcepLandingPage.styles'
import { GRADE, BRANCH } from '../afcep.constants'
import { FormItem } from '../../../forms/form.styles'
import DropdownField from '../../../components/Forms/DropdownField'
import InputField from '../../../components/Forms/InputField'
import { dateInPast, maxLength100, maxLength6, required, validAfrissDate } from '../../../forms/validation'
import theme from '../../../theme'
import { Checkbox } from '../../../components/Forms/Checkbox'

export const CurrentService = () => {
  return (
    <Section>
      <SectionTitle data-testid='current-service-section-title'>Current Service</SectionTitle>
      <ThreeColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='started-on-tafmsd-field'>
          <Field
            id='currentServiceStartOn'
            name='currentServiceStartOn'
            data-testid='currentServiceStartOn'
            label='Started on (TAFMSD)*'
            component={ InputField }
            type='date'
            validate={ [required, dateInPast, validAfrissDate] }
          />
        </FormItem>
        <FormItem style={ { alignSelf: 'flex-end', paddingBottom: `calc(${theme.spacing.s}px - ${theme.spacing.xxs}px)` } } data-testid='current-service-present-checkbox'>
          <Field
            name='currentServicePresent'
            value
            component={ () => {
              return (
                <Checkbox
                  label='Present*'
                  data-testid='currentServicePresent'
                  style={ { paddingBottom: theme.spacing.m, fontSize: theme.typography.fontSizes.m } }
                  checked
                  disabled
                />
              )
            } }
          />
        </FormItem>
      </ThreeColumnGrid>
      <ThreeColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='current-service-branch-field'>
          <Field
            id='currentServiceBranch'
            name='currentServiceBranch'
            label='Branch*'
            data-testid='currentServiceBranch'
            placeholder='Select'
            options={ BRANCH }
            component={ DropdownField }
            validate={ required }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='current-service-grade-field'>
          <Field
            id='currentServiceGrade'
            name='currentServiceGrade'
            label='Grade*'
            data-testid='currentServiceGrade'
            placeholder='Select'
            options={ GRADE }
            component={ DropdownField }
            validate={ required }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='current-service-dor-field'>
          <Field
            id='currentServiceDateOfRank'
            name='currentServiceDateOfRank'
            data-testid='currentServiceDateOfRank'
            label='DOR*'
            component={ InputField }
            type='date'
            validate={ [required, dateInPast, validAfrissDate] }
          />
        </FormItem>
      </ThreeColumnGrid>
      <TwoColumnGrid style={ { gridTemplateColumns: '1fr 2fr' } } data-testid='current-service-current-afsc-field'>
        <FormItem width={ { base: 'auto' } }>
          <Field
            id='currentServiceCurrentAfsc'
            name='currentServiceCurrentAfsc'
            data-testid='currentServiceCurrentAfsc'
            label='Current AFSC*'
            component={ InputField }
            type='text'
            validate={ [required, maxLength6] }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='current-service-current-job-title-field'>
          <Field
            id='currentServiceCurrentJobTitle'
            name='currentServiceCurrentJobTitle'
            data-testid='currentServiceCurrentJobTitle'
            label='Current Job Title*'
            component={ InputField }
            type='text'
            validate={ [required, maxLength100] }
          />
        </FormItem>
      </TwoColumnGrid>
    </Section>
  )
}
