import React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from '@emotion/css'

const DEFAULT_CLASS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Footer = ({ children, className }) => {
  return <footer className={ cx(DEFAULT_CLASS, className) }>{ children }</footer>
}

Footer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string,
}

export default Footer
