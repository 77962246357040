import React from 'react'
import { css, cx } from '@emotion/css'

export const ThreeColumnGrid = ({ children, className, style, ...props }) => {
  const defaultClassName = css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  `
  const threeColumnGridClassNames = cx(defaultClassName, className, css(style))
  return <div className={ threeColumnGridClassNames } { ...props }>{ children }</div>
}
