import { commonActions } from '../../reducers/common.reducers'
import { put, take, race } from 'redux-saga/effects'
import SafeSaga, { takeError } from './SafeSaga'

export default class AsyncSaga extends SafeSaga {
    
  SUCCESS

  static defaultMeta = {
    globalLoad: true,
    retryOnFail: false,
    isRetry: false,
    loadId: false,
  }

  static successType = id => `${id}_SUCCESS`

  // Available Meta Params:
  // globalLoad: display a global loading indicator. Default: true
  // persistErrors: remove previous errors for itself when run again. Default: true
  // globalErrors: display caught exceptions in a global notification. Default: true
  // retryOnFail: add action to offline queue after failure due to connection. Default: false
  // isRetry: flag indicating that this action is a retry of a previous failed attempt. Default: false
  static createRequest(type, params, meta) {
    return SafeSaga.createRequest(type, params, { ...AsyncSaga.defaultMeta, ...meta })
  }

  request(params, meta) {
    return AsyncSaga.createRequest(this.REQUEST, params, meta)
  }

  success(payload) {
    return { type: this.SUCCESS, payload }
  }

  constructor(id, saga) {
    super(id, saga)
    this.SUCCESS = `${id}_SUCCESS`
    this.saga = this.wrapAsyncSaga(this.saga)
  }

  wrapAsyncSaga(saga) {
    return function* asyncSaga(action) {
      yield put({
        type: commonActions.ADD_LOAD,
        id: this.id,
        global: action.meta && action.meta.globalLoad === true,
        meta: action.meta,
      })

      yield saga(action)

      yield put({
        type: commonActions.REMOVE_LOAD,
        id: this.id,
        meta: action.meta,
      })
    }.bind(this)
  }
}

export function* takeSuccess(id) {
  return yield take(AsyncSaga.successType(id))
}

export function* runAsyncSaga(id, params, meta) {
  meta = { globalErrors: false, ...meta }
  yield put(AsyncSaga.createRequest(id, params, meta))

  const result = yield race({
    error: takeError(id),
    success: takeSuccess(id),
  })
  
  if (result.error && !meta.globalErrors) {
    throw result.error.error
  }

  return result
}
