import React from 'react'
import { AfcepRequestSectionContainer } from './AfcepLandingPage.styles'
import { MAX_E2O_FILE_SIZE_DISPLAY } from '../afcep.constants'

export const AfcepLandingPageBeforeYouProceed = () => {
  return (
    <AfcepRequestSectionContainer data-testid='before-you-proceed-container'>
      <h3 data-testid='before-you-proceed-header'>Before You Proceed:</h3>
      <div>Congratulations! You are on the home stretch! This site is used to request AFCEP access to submit a completed application for the
        board listed above. Upon completion of the request, you will receive an email with AFCEP login information within 2-3 business days.
      </div>
      <br />
      <div><b>IMPORTANT:</b> The application must be completed in a single session and will time out if the window is inactive for 15 minutes.
        Prior to logging into AFCEP, please have a commanders AFCEP request memo saved as a pdf with a maximum size of { MAX_E2O_FILE_SIZE_DISPLAY }.
      </div>
      <br />
      For information on board schedule, eligibility requirements and process of creating a WTS or OTS application go to the Line Officer Candidate
      Information website (
      <a href='https://www.recruiting.af.mil/About-Us/Line-Officer-Candidate-Information-and-Resources/'>
        https://www.recruiting.af.mil/About-Us/Line-Officer-Candidate-Information-and-Resources/
      </a>)
      and download the board schedule and AD AF program announcements.)
    </AfcepRequestSectionContainer>
  )
}
