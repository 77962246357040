import React from 'react'
import { CardItemWrapper } from './CardItem.styles'

const CardItem = ({ ...props }) => {

  return (
    <CardItemWrapper
      { ...props }
    />
  )
}

export default CardItem
