import { FORM_TIMEOUT } from './afcep.constants'

export const formatTimeRemaining = timeRemaining => {
  const minutes = Math.floor(timeRemaining / 60)
  const seconds = (timeRemaining % 60).toFixed(0)
  const minuteDisplay = minutes ? `${minutes} MIN ` : ''
  return `${minuteDisplay}${seconds} SEC`
}

export const getSecondsRemaining = lastEdit => {
  return FORM_TIMEOUT - Math.floor((Date.now() - lastEdit) / 1000)
}
