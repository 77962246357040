import { ThemeProvider } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { Grid, Input, Modal } from 'semantic-ui-react'
import HttpService from '../../../api/HttpService'
import config from '../../../config'
import { selectAuthUserToken } from '../../../redux/selectors/auth.selectors'
import { LightPalette } from '../../../theme'
import spacing from '../../../theme/spacing'
import { ActionButton, CancelButton } from '../../pages/Opa.styles'
import { ErrorLabel } from '../../pages/UploadWeekGroupPage.styles'
import { OpaLabel } from '../Forms/OpaLabel'

const afreServiceUrl = config.apiUrl

export const GainBaseModal = ({ isOpen, setOpen, getBaseQueryKey, existingGainBase }) => {
  const queryClient = useQueryClient()
  const token = useSelector(selectAuthUserToken)

  const [newName, setNewName] = useState('')

  const saveBaseMutation = useMutation(async base => {
    return await HttpService.post(`${afreServiceUrl}/opa/templates/bases`, base, {}, token).next().value
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries(getBaseQueryKey)
      setOpen(false)
    },
  })

  const updateBaseMutation = useMutation(async base => {
    return await HttpService.put(`${afreServiceUrl}/opa/templates/bases/${base.sort}`, base, {}, token).next().value
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries(getBaseQueryKey)
      setOpen(false)
    },
  })

  const updateName = (eventData, name) => {
    name = name.toUpperCase()
    eventData.target.defaultValue = name
    eventData.target.value = name
    setNewName(name)
  }

  const saveError = saveBaseMutation.error && saveBaseMutation.error.response && saveBaseMutation.error.response.data
  const updateError = updateBaseMutation.error && updateBaseMutation.error.response && updateBaseMutation.error.response.data
  const error = existingGainBase ? updateError : saveError
  const isLoading = saveBaseMutation.isLoading || updateBaseMutation.isLoading
  return (
    <Modal
      open={ isOpen }>
      <ThemeProvider theme={ LightPalette }>
        <Modal.Header data-testid='modal-header'>{ existingGainBase ? 'Edit Gain Base' : 'Add Gain Base' }</Modal.Header>
        <Modal.Content>
          <Modal.Description data-testid='modal-description'>
            { error && error.error &&
              <div style={ { paddingBottom: spacing.m } }>
                <ErrorLabel>
                  { error.error.toUpperCase() }
                </ErrorLabel>
              </div> }
            <Grid>
              <Grid.Column width={ 14 } >
                <Grid.Row data-testid='name-row'>
                  <OpaLabel label='Name' error={ error && error.name } required />
                </Grid.Row>
                <Grid.Row >
                  <Input
                    fluid
                    data-testid='base-name-input'
                    placeholder='Enter Gain Base Name'
                    type='string'
                    defaultValue={ existingGainBase ? existingGainBase.name : '' }
                    onChange={ (eventData, event) => updateName(eventData, event.value) }
                  />
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <CancelButton onClick={ () => {
            setOpen(false)
            saveBaseMutation.reset()
            updateBaseMutation.reset()
            setNewName('')
          }
          }>
            Cancel
          </CancelButton>
          {
            isLoading ?
              <ActionButton loading>Loading</ActionButton> :
              <ActionButton
                content={ existingGainBase ? 'Update' : 'Create' }
                data-testid='action-button'
                icon='checkmark'
                disabled={ !newName }
                onClick={ () => {
                  if (existingGainBase) {
                    updateBaseMutation.mutate({ name: newName, sort: existingGainBase.sort })
                  } else {
                    saveBaseMutation.mutate({ name: newName })
                  }
                } }
              />
          }
        </Modal.Actions>
      </ThemeProvider>
    </Modal>
  )
}

GainBaseModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  getBaseQueryKey: PropTypes.array,
  existingGainBase: PropTypes.object,
}
