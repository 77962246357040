/* eslint-disable */
import * as React from 'react';

function ExportSquadrons(props) {
  return (
    <svg width={ props.size } height={ props.size } viewBox="0 0 32 32" { ...props }>
      <path
        d="M7 23h11v-.821c0-1.013-.829-1.863-1.934-1.988A32.103 32.103 0 0012.5 20c-1.451 0-2.656.089-3.565.191C7.829 20.316 7 21.166 7 22.179V23zm3-7.5a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0zm8-4a1.5 1.5 0 102.999.001A1.5 1.5 0 0018 11.5zm-13 0a1.5 1.5 0 102.999.001A1.5 1.5 0 005 11.5zm6-3a1.5 1.5 0 102.999.001A1.5 1.5 0 0011 8.5zM1 29h23.172V1H1v28z"
        stroke={ props.color }
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ExportSquadrons;
