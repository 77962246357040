import React from 'react'
import { HorizontalRule, P, PageTitle, Section } from '../afcepLandingPage/AfcepLandingPage.styles'
import theme from '../../../theme'
import { ContentErrorMessage } from '../../../components/Message/Message'
import PropTypes from 'prop-types'

export const AfcepFormHeader = ({ recommendationLetterError, hasSubmitErrors }) => {
  return (
    <>
      <Section style={ { paddingBottom: theme.spacing.l } }>
        <PageTitle data-testid='afcep-request-form-title'><strong>AFCEP</strong> Request Form</PageTitle>
        <P data-testid='reminder-text'>
          <strong>Reminder:</strong> For security purposes, this form must be completed in one single session to receive AFCEP access.
        </P>
      </Section>
      <HorizontalRule />
      { (recommendationLetterError || hasSubmitErrors) &&
        <ContentErrorMessage
          header='AFCEP Validation Error(s)'
          style={ { marginTop: theme.spacing.l, borderRadius: theme.radii.s } }
          content={
            <p style={ { fontSize: `${theme.typography.fontSizes.m}px` } } data-testid='afcep-validation-error-message'>
              Please make sure all required fields are filled out correctly.
            </p>
          }
        />
      }
    </>
  )
}

AfcepFormHeader.propTypes = {
  recommendationLetterError: PropTypes.string,
  hasSubmitErrors: PropTypes.bool,
}
