import styled from '@emotion/styled'

export const ShareTeaserWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 400px;
  margin-top: 20px;
`

export const TeaserTitle = styled.div`
  font-family: stratum2-regular;
  color: ${props => props.theme.text.contrast};
  font-size: 32px;
  line-height: 38px;
  font-weight: bold;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  padding-bottom: 20px;
`

export const TeaserText = styled.div`
  font-family: stratum2-regular;
  font-size: 22px;
  font-weight: bold;
  line-height: 24px;
`

export const TeaserTextList = styled.div`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
`

export const TeaserDownloadLinks = styled.div`
  display: flex;
  
`
