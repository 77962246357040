import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Header, Grid, Container, Input, Segment, Table, Form, Message } from 'semantic-ui-react'
import LogoutButton from '../../navigation/LogoutButton'
import { NAV_ROUTES } from '../../navigation/routes'
import AHSidebar from '../../components/Navigation/NavSidebar'
import { useRecruiterCounts } from './reportQueries'
import { fontSizes } from '../../theme/typography'
import { LightPalette } from '../../theme'
import { getTime } from '../../api/date.util'

const RecruiterCountsPage = () => {
  const [formErrorMessage, setFormErrorMessage] = useState('')
  const [inputStart, setInputStart] = useState('')
  const [inputEnd, setInputEnd] = useState('')
  const [queryStart, setQueryStart] = useState('')
  const [queryEnd, setQueryEnd] = useState('')
  const { data, isFetching } = useRecruiterCounts({
    start: queryStart ? getTime(queryStart) : undefined,
    end: queryEnd ? getTime(queryEnd) : undefined,
  })
  const onSubmit = () => {
    setQueryStart(inputStart)
    setQueryEnd(inputEnd)
  }

  const isApplyButtonDisabled = () => {
    if (formErrorMessage) {
      return true
    }
    if (isFetching) {
      return true
    }

    if (inputStart && inputEnd) {
      return false
    }
    if (!inputStart && !inputEnd) {
      return inputStart === queryStart && inputEnd === queryEnd
    }

    return true
  }

  const applyButtonDisabled = isApplyButtonDisabled()

  const handleStartDateChange = value => {
    setFormErrorMessage('')
    if (!!value) {
      const valueInMillis = new Date(value).getTime()
      if (inputEnd && valueInMillis >= new Date(inputEnd).getTime()) {
        setFormErrorMessage('Start Date must be before End Date')
      }
      if (valueInMillis <= new Date('04/13/2022').getTime()) {
        setFormErrorMessage('Start Date must be after 04/13/2022')
      }
      if (valueInMillis >= new Date().getTime()) {
        setFormErrorMessage('Start Date must not be in the future')
      }
    } else if (inputEnd) {
      setFormErrorMessage('Set Start Date or clear End Date')
    }
    setInputStart(value)
  }

  const handleEndDateChange = value => {
    setFormErrorMessage('')
    if (!!value) {
      if (inputStart && new Date(inputStart).getTime() >= new Date(value).getTime()) {
        setFormErrorMessage('Start Date must be before End Date')
      }
      if (!inputStart) {
        setFormErrorMessage('Start Date must be set')
      }
    }
    setInputEnd(value)
  }

  const renderRecruiterCounts = () => {
    if (!data) {
      return null
    }
    return (
      <>
        { (data.startDate && data.endDate) &&
          <Segment>{ `${new Date(data.startDate).toDateString()} through ${new Date(data.endDate).toDateString()}` }</Segment>
        }
        <Table celled>
          <Table.Header data-testid='table-header'>
            <Table.Row data-testid='table-row'>
              <Table.HeaderCell data-testid='air-force-table-header-cell'>Air Force</Table.HeaderCell>
              <Table.HeaderCell data-testid='air-force-reserve-table-header-cell'>Air Force Reserve</Table.HeaderCell>
              <Table.HeaderCell data-testid='air-national-guard-table-header-cell'>Air National Guard</Table.HeaderCell>
              <Table.HeaderCell data-testid='space-force-table-header-cell'>Space Force</Table.HeaderCell>
              <Table.HeaderCell data-testid='unassigned-table-header-cell'>Unassigned *</Table.HeaderCell>
              <Table.HeaderCell data-testid='total-table-header-cell'>Total</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body data-testid='table-body'>
            <Table.Row>
              <Table.Cell data-testid='air-force-table-cell'>{ data.airForce }</Table.Cell>
              <Table.Cell data-testid='air-force-reserve-table-cell'>{ data.airForceReserve }</Table.Cell>
              <Table.Cell data-testid='air-national-guard-table-cell'>{ data.airNationalGuard }</Table.Cell>
              <Table.Cell data-testid='space-force-table-cell'>{ data.spaceForce }</Table.Cell>
              <Table.Cell data-testid='unassigned-table-cell'>{ data.unassignedComponent }</Table.Cell>
              <Table.Cell data-testid='total-table-cell'>{ data.total }</Table.Cell>
            </Table.Row>
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan='6'>
                <Segment size='mini' data-testid='unassigned-description'>
                  * Unassigned is the number of recruiters without a component set. It defaults to Air Force if no component set.
                </Segment>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </>
    )
  }
  return (
    <>
      <AHSidebar currentRoute={ NAV_ROUTES.RECRUITER_COUNTS_REPORT_PAGE } />
      <Container>
        <Header as='h1'>
          <Grid>
            <Grid.Row columns='equal'>
              <Grid.Column data-testid='recruiter-counts-header'>Recruiter Counts</Grid.Column>
              <Grid.Column textAlign='right'>
                <LogoutButton />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Header>
        <Form error={ !!formErrorMessage }>
          <Header as='h5' data-testid='select-custom-date-range-text' >Select custom date range</Header>
          <Form.Group inline>
            <Form.Field>
              <Input
                type='date' disabled={ isFetching } label='Start'
                data-testid='start-date-input'
                value={ inputStart }
                onChange={ (event, inputData) => handleStartDateChange(inputData.value) }
              />
            </Form.Field>
            <Form.Field>
              <Input
                type='date' disabled={ isFetching } label='End'
                data-testid='end-date-input'
                value={ inputEnd }
                onChange={ (event, inputData) => handleEndDateChange(inputData.value) }
              />
            </Form.Field>
            <Form.Button primary disabled={ applyButtonDisabled } loading={ isFetching } onClick={ onSubmit } data-testid='apply-button' >
              Apply
            </Form.Button>
          </Form.Group>
          <Message
            error
            data-testid='action-forbidden-message'
            header='Action Forbidden'
            content={ formErrorMessage }
          />
        </Form>
        <div style={ { fontSize: fontSizes.s, color: LightPalette.text.subtle } } data-testid='custom-date-range-selection-text'>
          Please note: Custom date range selection only available back to 4/14/2022.
        </div>
        { renderRecruiterCounts() }
      </Container>
    </>
  )
}

RecruiterCountsPage.propTypes = {
}

export default withRouter(RecruiterCountsPage)
