import styled from '@emotion/styled'
import { Grid, Header } from 'semantic-ui-react'
import spacing from '../../../theme/spacing'

export const StickyHeaderDiv = styled(Header)`
  position: sticky;
  top: -${props => props.theme.spacing.m}px;
  z-index: 4;
  background-color: ${props => props.theme.background.level0};
`
export const OpaPageHeaderTitleText = styled(Grid.Column)`
  font-size: ${props => props.theme.typography.fontSizes.xl}px;
  align-self: center;
  text-align: center;
`

export const OpaPageHeaderLogoutColumn = styled(Grid.Column)`
  align-self: center;
  text-align: right;
  padding: ${spacing.s}px;
`

export const OpaPageHeaderLogoutContainer = styled.div`
  margin: ${spacing.l}px;
  margin-left: ${spacing.xl}px;
`
