import PropTypes from 'prop-types'
import React from 'react'
import { LightPalette } from '../../../theme'
import { ARMS_STATUS_ICONS } from '../../pages/armsStatusProperties'
import { ActionButton } from '../../pages/Opa.styles'
import {
  ArmsStatusIcon,
  Footer,
  FooterLegend,
  FooterLegendContent,
  FooterLegendText,
  FooterLegendValue
} from '../../pages/ProcessWeekGroupPage.styles'

const renderFooterLegendValue = iconKey => {
  const { icon, color } = ARMS_STATUS_ICONS[iconKey]
  return (
    <div style={ { color } }>
      <ArmsStatusIcon
        title='ARMS-LC Status'
        name={ icon }
        iconcolor={ color }
        size='large'
      />
      { iconKey }
    </div>
  )
}

const OpaProcessWeekGroupFooter = props => {
  const {
    showArmsFeatures, isWeekGroupReady, weekGroupRowList,
  } = props.getters
  const {
    setApproveOrdersModalOpen,
    setProcessConfirmationModalOpen,
    setArmsConfirmationModalOpen,
  } = props.setterFunctions
  return (
    <Footer>
      { showArmsFeatures &&
        <FooterLegend>
          <FooterLegendText data-testid='status-legend-footer-text'>Status Legend:</FooterLegendText>
          <FooterLegendContent data-testid='status-legend-footer-content'>
            {
              Object.keys(ARMS_STATUS_ICONS).map(iconKey => {
                return (
                  <FooterLegendValue key={ iconKey }>{ renderFooterLegendValue(iconKey) }</FooterLegendValue>
                )
              })
            }
          </FooterLegendContent>
        </FooterLegend>
      }
      <ActionButton
        data-testid='approve-flight-physical-orders-button'
        disabled={ isWeekGroupReady }
        backgroundcolor={ LightPalette.background.action }
        onClick={ () => {
          setApproveOrdersModalOpen(true)
        } }>
        Approve Flight Physical Orders
      </ActionButton>
      <ActionButton
        data-testid='process-week-group-button'
        disabled={ weekGroupRowList.length === 0 }
        onClick={ () => {
          setProcessConfirmationModalOpen(true)
        } }>
        Process Week Group
      </ActionButton>
      { showArmsFeatures &&
        <ActionButton
          data-testid='mark-ready-for-arms-lc-button'
          disabled={ isWeekGroupReady }
          backgroundcolor={ LightPalette.background.action }
          onClick={ () => {
            setArmsConfirmationModalOpen(true)
          } }>
          Mark Ready for ARMS-LC
        </ActionButton>
      }
    </Footer>
  )
}

OpaProcessWeekGroupFooter.propTypes = {
  getters: PropTypes.object,
  setterFunctions: PropTypes.object,
}

export default OpaProcessWeekGroupFooter
