import { ThemeProvider } from '@emotion/react'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SvgAirForce from '../../icons/AirForceLogo'
import SvgSpaceForce from '../../icons/SpaceForceLogo'
import { NAV_ROUTES } from '../../navigation/routes'
import { logPageView } from '../../redux/sagas/amplitudeAnalytics.sagas'
import { DarkPalette } from '../../theme'
import StarrDownload from './starr.download.page'
import StarrHowToItems from './starr.how.to.items'
import {
  Container,
  Header,
  HorizontalLine, HorizontalLineWrapper,
  HowToItemsDeskTop, HowToItemsMobile,
  LeftSide, StarrBody,
  SubTitle,
  Title,
  Wrapper,
  LogoWrapper
} from './starr.page.styles'

const StarrPage = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(logPageView.request(NAV_ROUTES.STARR_PAGE))
  }, [dispatch])

  return (
    <ThemeProvider theme={ DarkPalette }>
      <Container>
        <Wrapper>
          <Header>
            <LogoWrapper>
              <SvgAirForce size={ 120 } color='#FFF' />
              <a href='https://www.airforce.com'>www.airforce.com</a>
            </LogoWrapper>
            <LogoWrapper>
              <SvgSpaceForce size={ 120 } color='#FFF' />
              <a href='https://www.spaceforce.com'>www.spaceforce.com</a>
            </LogoWrapper>
          </Header>
          <StarrBody>
            <LeftSide>
              <Title>STARR</Title>
              <HorizontalLineWrapper>
                <HorizontalLine />
              </HorizontalLineWrapper>
              <SubTitle>stellar talent acquisition recruiting referral program</SubTitle>
              <HowToItemsDeskTop>
                <StarrHowToItems />
              </HowToItemsDeskTop>

            </LeftSide>
            <StarrDownload />
            <HowToItemsMobile>
              <StarrHowToItems />
            </HowToItemsMobile>
          </StarrBody>
        </Wrapper>
      </Container>
    </ThemeProvider>
  )
}

export default withRouter(StarrPage)
