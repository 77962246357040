import immutable from 'seamless-immutable'
import {
  fetchRoleRequests,
  fetchUsers,
  updateUser,
  fetchRoles,
  updateRolesSaga,
  updateUserSquadronInfo,
  deleteUserSquadronInfo,
  fetchUser,
  fetchRecruiterExperienceData,
  fetchRecruiter,
  updateSpecificRoleSaga,
  deleteSpecificRoleSaga,
  fetchRecruiterInformation
} from '../sagas/user.sagas'
import { updateFlight, updateSquadron } from '../sagas/squadron.sagas'

const INITIAL_STATE = immutable({
  roleRequests: [],
  users: [],
  roles: {},
  searchTerm: '',
  user: null,
  recruiterExperienceData: null,
  lastRoleRequestKey: null,
})

// eslint-disable-next-line complexity
export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case fetchRoleRequests.SUCCESS: {
      const uniqueRoleRequests = action.payload.roleRequests.roleRequests
        .filter(request => !state.roleRequests.map(stateRequest => stateRequest.id).includes(request.id))
      return state
        .set('roleRequests', state.roleRequests.concat(uniqueRoleRequests))
        .set('lastRoleRequestKey', action.payload.roleRequests.lastKey)
    }

    case fetchRoles.SUCCESS:
      return state.set('roles', action.payload.roles)

    case fetchUser.SUCCESS:
    case fetchRecruiter.SUCCESS:
      return state.set('user', action.payload.user)
    case fetchRecruiterInformation.SUCCESS:
      return state.set('recruiterInformation', action.payload)

    case fetchUsers.SUCCESS: {
      const newSearchTerm = action.payload.searchTerm !== state.searchTerm
      const uniqueUsers = action.payload.users.filter(u => !state.users.map(stateUser => stateUser.id).includes(u.id))
      const users = newSearchTerm ? action.payload.users : state.users.concat(uniqueUsers)
      return state
        .set('users', users)
        .set('lastKey', action.payload.lastKey)
        .set('searchTerm', action.payload.searchTerm)
    }
    case updateUserSquadronInfo.SUCCESS:
    case deleteUserSquadronInfo.SUCCESS:
    case updateRolesSaga.SUCCESS:
    case updateSpecificRoleSaga.SUCCESS:
    case deleteSpecificRoleSaga.SUCCESS:
    case updateUser.SUCCESS: {
      const userIndex = state.users.findIndex(user => user.id === action.payload.user.id)
      if (userIndex !== -1) {
        const stateUsers = [...state.users]
        stateUsers[userIndex] = action.payload.user
        return state.merge({ users: stateUsers })
      }
      return state
    }
    case updateSquadron.SUCCESS: {
      const userIndex = state.users.findIndex(u => u.squadronId === action.payload.squadron.squadronId)
      if (userIndex !== -1) {
        const stateUsers = [...state.users]
        const { name, updatedAt } = action.payload.squadron
        stateUsers[userIndex] = { ...stateUsers[userIndex], squadronName: name, updatedAt }
        return state.merge({ users: stateUsers })
      }
      return state
    }
    case updateFlight.SUCCESS: {
      const userIndex = state.users.findIndex(u => u.flightId === action.payload.flight.flightId)
      if (userIndex !== -1) {
        const stateUsers = [...state.users]
        const { name, updatedAt } = action.payload.flight
        stateUsers[userIndex] = { ...stateUsers[userIndex], flightName: name, updatedAt }
        return state.merge({ users: stateUsers })
      }
      return state
    }
    case fetchRecruiterExperienceData.SUCCESS:
      return state.set('recruiterExperienceData', action.payload.recruiterExperience)

    default:
      return state
  }
}
