import PropTypes from 'prop-types'
import * as React from 'react'

function GCheck(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={props.size} width={props.size} viewBox="0 0 64 64">
            <title>{props.title}</title>
            <g fill={props.color} class="nc-icon-wrapper">
                <path fill={props.color} d="M2,34l6-6l13,8c0,0,16.841-16.75,39-28l2,7c0,0-23.727,13.955-41,41L2,34z"></path>
            </g>
        </svg>
    )
}

GCheck.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
}

export default GCheck