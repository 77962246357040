import React from 'react'
import { LightPalette } from '../../../theme/'

export const Label = ({ children, error, style, ...props }) => {
  const labelStyle = {
    color: error ? LightPalette.text.negative : undefined,
    ...style,
  }
  return <label { ...props } style={ labelStyle }>{ children }</label>
}
