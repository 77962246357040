import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Checkbox, Modal, Popup } from 'semantic-ui-react'
import { NAV_ROUTES } from '../../../navigation/routes'
import { ActionButton, CancelButton } from '../../pages/Opa.styles'
import { ModalSpacingDiv, ProcessWarningText } from '../../pages/ProcessWeekGroupPage.styles'
import { ARMS_STATUS, processWeekGroupRows } from '../../redux/ducks/opa.duck'

const ProcessWeekGroupModal = props => {
  const {
    hasRowsWithErrors,
    processConfirmationModalOpen,
    sendWeekGroupCodeEmail,
    weekGroupRowList,
    selectedWeekGroup,
  } = props.getters
  const {
    setSendWeekGroupCodeEmail,
    setProcessConfirmationModalOpen,
  } = props.setterFunctions
  return (
    <Modal
      open={ processConfirmationModalOpen }>
      <Modal.Header>Confirm Processing</Modal.Header>
      <Modal.Content>
        { hasRowsWithErrors &&
          <ProcessWarningText>
            WARNING: There are still orders with errors, which will not be processed. Are you sure you want to process the current week group?
          </ProcessWarningText>
        }
        <Checkbox
          label='Email Week Group QR Code to all MTIs'
          checked={ sendWeekGroupCodeEmail }
          onChange={ (_e, eventData) => setSendWeekGroupCodeEmail(eventData.checked) }
        />
        <ModalSpacingDiv />
        <Popup trigger={ <Button basic active>About MTI Emails</Button> } hoverable>
          <p>
            When processing the week group, you may email the week group QR to all MTIs.
          </p>
          <p>
            To view or manage the MTI email list, please
            <Link
              to={ NAV_ROUTES.EMAIL_MANAGEMENT_PAGE }
              target='emailManagement'
              rel='noopener noreferrer'> visit the Email Management Page.
            </Link>
          </p>
        </Popup>
      </Modal.Content>
      <Modal.Actions>
        <CancelButton
          onClick={ () => {
            setProcessConfirmationModalOpen(false)
          } }>
          Cancel
        </CancelButton>
        <ActionButton
          content='Process'
          icon='checkmark'
          onClick={ () => {
            const rowsToProcessSortIds = weekGroupRowList
              .filter(row => row.armsStatus && row.armsStatus !== ARMS_STATUS.DO_NOT_SHIP)
              .map(row => row.sort)
            props.dispatch(processWeekGroupRows.request({ selectedWeekGroup, rowSortIds: rowsToProcessSortIds, sendWeekGroupCodeEmail }))
            setSendWeekGroupCodeEmail(false)
            setProcessConfirmationModalOpen(false)
          } }
        />
      </Modal.Actions>
    </Modal>
  )
}

ProcessWeekGroupModal.propTypes = {
  dispatch: PropTypes.func,
  getters: PropTypes.object,
  setterFunctions: PropTypes.object,
}

export default ProcessWeekGroupModal
