import styled from '@emotion/styled'
import { Page } from '../../pages/share/share.page.styles'
import spacing from '../../theme/spacing'
import { fontWeights } from '../../theme/typography'

export const ErrorPageWrapper = styled(Page)`
  height: 100%;
  min-height: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
`

export const ErrorPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

export const ErrorPageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin: 7% auto 0px;
`

export const IconContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: ${spacing.m}px ${spacing.l}px;
`

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: ${spacing.m}px ${spacing.l}px;
  min-width: 150px;
`

export const ErrorPageTitleContainer = styled.div`
  margin-bottom: ${spacing.l}px;
`

export const ErrorStatusContainer = styled.div`
  flex-direction: row;
  display: flex;
  margin-bottom: ${spacing.m}px;
`

export const ErrorStatus = styled.div`
  font-weight: ${fontWeights.bold};
  padding-right: ${spacing.xs}px;
  color: ${props => props.theme.text.contrast};
`

export const ErrorMessage = styled.div`
  text-align: center;
`
