import React from 'react'
import AddLike from '../../icons/AddLike'
import Add from '../../icons/DAdd'
import Download from '../../icons/Download'
import FinalScore from '../../icons/FinalScore'
import Check from '../../icons/GCheck'
import MilitaryMedal from '../../icons/MilitaryMedal'
import Pen from '../../icons/Pen01'
import Tap from '../../icons/Tap02'
import StarrHowItem from './starr.how.item'
import { ItemsWrapper } from './starr.page.styles'

const howToItemList = [
  {
    icon: Download,
    title: 'download',
    description: 'Available for both Android & iOS.',
  },
  {
    icon: Add,
    title: 'signup',
    description: 'Create an account.',
  },
  {
    icon: Tap,
    title: 'setup',
    description: 'Go to "Refer a Friend" to setup STARR',
  },
  {
    icon: Pen,
    title: 'update profile',
    description: 'Add your DoDID',
  },
  {
    icon: Check,
    title: 'starr enabled',
    description: 'You are now ready to start getting recognized for referrals.',
  },
  {
    icon: AddLike,
    title: 'start referring',
    description: 'Refer candidates to the Air Force and Space Force.',
  },
  {
    icon: MilitaryMedal,
    title: 'get recognized',
    description: 'Every referral that makes it to training counts.',
  },
  {
    icon: FinalScore,
    title: 'multiple chances for a decoration',
    description: 'Earn up to 2 ASAM and 1 ASCOM.',
  },
]

const StarrHowToItems = () => {
  return (
    <ItemsWrapper>
      { howToItemList.map((item, index) => {
        return <StarrHowItem key={ index } { ...item } />
      }) }
      <a href='https://static.e-publishing.af.mil/production/1/af_a1/publication/dafman36-2806/dafman36-2806.pdf'>
        See DAFMAN 36-2806 for more details
      </a>
    </ItemsWrapper>
  )
}

export default StarrHowToItems
