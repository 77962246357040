import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchSquadrons, getSquadronFlights, deleteSquadron } from '../../redux/sagas/squadron.sagas'
import {
  selectSquadrons,
  selectFlights,
  selectCreateSquadronErrors,
  selectFetchSquadronErrors,
  selectUpdateSquadronErrors,
  selectDeleteSquadronErrors } from '../../redux/selectors/squadron.selectors'
import { Container, Header, Grid, Table, Button, Modal, List, Dimmer, Loader } from 'semantic-ui-react'
import { NAV_ROUTES } from '../../navigation/routes'
import NavLink from '../../components/Navigation/NavLink'
import { selectIsLoading } from '../../redux/selectors/common.selectors'
import SquadronCreateForm from './squadron.create.form'
import SquadronUpdateForm from './squadron.update.form'
import { reduxForm } from 'redux-form'
import { getStandardAmericanDate } from '../../api/date.util'

class SquadronForm extends Component {
  componentDidMount() {
    this.props.fetchSquadrons()
  }

  renderDeleteModal = squadron => {
    return (
      <Modal data-testid='delete-button' size='mini' trigger={ <Button color='red'>Delete</Button> } closeIcon>
        <Modal.Header data-testid='confirm-delete-header'>Confirm Delete</Modal.Header>
        <Modal.Content data-testid='confirm-delete-text'>
          Are you sure you want to delete squadron { squadron.name }?
        </Modal.Content>
        <Modal.Actions>
          <Button data-testid='confirm-delete-button' color='red' onClick={ () => this.props.deleteSquadron(squadron.id) }>
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  renderEditModal = squadron => (
    <SquadronUpdateForm
      form={ `squadronUpdate-${squadron.id}` }
      squadron={ squadron }
    />
  )

  renderSquadronError = () => {
    const { createSquadronErrors, updateSquadronErrors, fetchSquadronErrors, deleteSquadronErrors } = this.props
    const errors = []
    if (createSquadronErrors) {
      errors.push(createSquadronErrors.toString())
    }
    if (updateSquadronErrors) {
      errors.push(updateSquadronErrors.toString())
    }
    if (fetchSquadronErrors) {
      errors.push(fetchSquadronErrors.toString())
    }
    if (deleteSquadronErrors) {
      errors.push(deleteSquadronErrors.toString())
    }
    return (
      <List>
        { errors.map(e => (
          <List.Item style={ { color: 'red' } } key={ e }>{ e }</List.Item>)
        ) }
      </List>
    )
  }

  renderViewFlightsButton = squadron => {
    return (
      <Button primary data-testid='view-flights-button'>
        <NavLink to={ { pathname: NAV_ROUTES.FLIGHTS_PAGE, state: { squadron } } }>View Flights</NavLink>
      </Button>
    )
  }

  render() {
    const { squadrons, isLoading } = this.props
    return (
      <Container>
        <Dimmer.Dimmable dimmed={ isLoading }>
          <Dimmer active={ isLoading } inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          <Header as='h1'>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column textAlign='center'>
                  <SquadronCreateForm />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign='center'>
                { this.renderSquadronError() }
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Table celled compact striped singleLine>
            <Table.Header data-testid='table-header'>
              <Table.Row data-testid='table-row'>
                <Table.HeaderCell data-testid='squadron-name-table-header-cell'>Squadron Name</Table.HeaderCell>
                <Table.HeaderCell data-testid='created-at-table-header-cell'>Created At</Table.HeaderCell>
                <Table.HeaderCell data-testid='actions-table-header-cell'>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body data-testid='table-body'>
              { squadrons.map(squadron => {
                const { id, name, createdAt } = squadron
                const createdAtDate = getStandardAmericanDate(createdAt)
                return (
                  <Table.Row key={ id }>
                    <Table.Cell data-testid='squadron-name-table-cell'>{ name }</Table.Cell>
                    <Table.Cell data-testid='created-at-table-cell'>{ createdAtDate }</Table.Cell>
                    <Table.Cell data-testid='actions-table-cell'>
                      { this.renderViewFlightsButton(squadron) }
                      { this.renderEditModal(squadron) }
                      { this.renderDeleteModal(squadron) }
                    </Table.Cell>
                  </Table.Row>
                )
              }) }
            </Table.Body>
          </Table>
        </Dimmer.Dimmable>
      </Container>
    )
  }
}

SquadronForm.propTypes = {
  squadrons: PropTypes.array,
  fetchSquadrons: PropTypes.func,
  isLoading: PropTypes.bool,
  deleteSquadron: PropTypes.func,
  createSquadronErrors: PropTypes.object,
  fetchSquadronErrors: PropTypes.object,
  updateSquadronErrors: PropTypes.object,
  deleteSquadronErrors: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    squadrons: selectSquadrons(state),
    flights: selectFlights(state),
    isLoading: selectIsLoading(state),
    createSquadronErrors: selectCreateSquadronErrors(state),
    fetchSquadronErrors: selectFetchSquadronErrors(state),
    updateSquadronErrors: selectUpdateSquadronErrors(state),
    deleteSquadronErrors: selectDeleteSquadronErrors(state),
  }
}

const mapActionsToProps = dispatch => {
  return {
    fetchSquadrons: () => dispatch(fetchSquadrons.request()),
    getSquadronFlights: id => dispatch(getSquadronFlights.request(id)),
    deleteSquadron: id => dispatch(deleteSquadron.request(id)),
  }
}

const Formed = reduxForm({
  form: 'squadron',
  enableReinitialize: true,
})(SquadronForm)
const ConnectedSquadronForm = connect(mapStateToProps, mapActionsToProps)(Formed)

export default withRouter(ConnectedSquadronForm)
