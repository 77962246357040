import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Dropdown, Input } from 'semantic-ui-react'
import { Header } from '../../components/Forms/Lead/Header'
import { Label } from '../../components/Forms/Lead/Label'
import { ThreeColumnGrid } from '../../components/Forms/Lead/ThreeColumnGrid'
import { email, phoneNumber, zipcode } from '../../forms/validation'

import { ErrorLabel } from '../../components/Forms/Lead/ErrorLabel'
import { Field } from '../../components/Forms/Lead/Field'
import { STATES } from './constants'

const CityField = styled.div`
  grid-column: 1;
  grid-row: 1;
  padding-top: ${({ theme }) => theme.spacing.s}px;
  padding-bottom: ${({ theme }) => theme.spacing.m}px;
  padding-right: ${({ theme }) => theme.spacing.s}px;
  @media (max-width: 767px) {
    grid-column: span 3;
    padding-right: 0;
    padding-top: ${({ theme }) => theme.spacing.s}px;
    padding-bottom: ${({ theme }) => theme.spacing.s}px;
  }
`
const StateField = styled.div`
  grid-column: 2;
  grid-row: 1;
  padding-top: ${({ theme }) => theme.spacing.s}px;
  padding-bottom: ${({ theme }) => theme.spacing.m}px;
  padding-left: ${({ theme }) => theme.spacing.s}px;
  padding-right: ${({ theme }) => theme.spacing.s}px;
  @media (max-width: 767px) {
    grid-column: span 3;
    grid-row: 2;
    padding-left: 0;
    padding-right: 0;
    padding-top: ${({ theme }) => theme.spacing.s}px;
    padding-bottom: ${({ theme }) => theme.spacing.s}px;
  }
`
const ZipCodeField = styled.div`
  grid-column: 3;
  grid-row: 1;
  padding-top: ${({ theme }) => theme.spacing.s}px;
  padding-bottom: ${({ theme }) => theme.spacing.m}px;
  padding-left: ${({ theme }) => theme.spacing.s}px;
  @media (max-width: 767px) {
    grid-column: span 3;
    grid-row: 3;
    padding-left: 0;
    padding-top: ${({ theme }) => theme.spacing.s}px;
    padding-bottom: ${({ theme }) => theme.spacing.m}px;
  }
`

const StyledDropdown = styled(Dropdown)`
  margin-top: ${({ theme }) => theme.spacing.m}px;
`
const StyledInput = styled(Input)`
  margin-top: ${({ theme }) => theme.spacing.m}px;
`

ContactInformation.propTypes = {
  errors: PropTypes.object,
  dispatch: PropTypes.func,
  dispatchError: PropTypes.func,
}

function ContactInformation({ dispatch, dispatchError, errors }) {
  const [emailError, setEmailError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [zipCodeError, setZipCodeError] = useState('')

  const onFocus = name => () => {
    dispatchError({ ...errors, [name]: false })
  }

  const onChange = name => (_event, { value }) => {
    dispatch({ type: name, value })
  }

  const validateEmpty = name => ({ target }) => {
    if (target.value === undefined || target.value === null || target.value.trim() === '') {
      dispatchError({ ...errors, [name]: true })
    }
  }

  const validateEmail = ({ target }) => {
    if (target.value === undefined || target.value === null || target.value.trim() === '') {
      dispatchError({ ...errors, email: true })
      return
    }

    const message = email(target.value)
    setEmailError(message)
    if (message) {
      dispatchError({ ...errors, email: true })
    }
  }

  const validatePhone = ({ target }) => {
    if (target.value === undefined || target.value === null || target.value.trim() === '') {
      dispatchError({ ...errors, phoneNumber: true })
      return
    }

    const message = phoneNumber(target.value, 10)
    setPhoneError(message)
    if (message) {
      dispatchError({ ...errors, phoneNumber: true })
    }
  }

  const validateZipCode = ({ target }) => {
    if (target.value === undefined || target.value === null || target.value.trim() === '') {
      dispatchError({ ...errors, zipCode: true })
      return
    }
    const message = zipcode(target.value)
    setZipCodeError(message)
    if (message) {
      dispatchError({ ...errors, zipCode: true })
    }
  }

  return (
    <>
      <Header>Contact Information</Header>
      <Field>
        <Label htmlFor='email' error={ errors.email }>Email</Label>
        <StyledInput
          id='email'
          data-testid='email'
          fluid
          error={ errors.email }
          onFocus={ onFocus('email') }
          onChange={ onChange('email') }
          onBlur={ validateEmail }
        />
        <ErrorLabel htmlFor='email'>{ emailError }</ErrorLabel>
      </Field>
      <Field>
        <Label htmlFor='phoneNumber' error={ errors.phoneNumber }>Phone Number</Label>
        <StyledInput
          id='phoneNumber'
          data-testid='phoneNumber'
          fluid
          error={ errors.phoneNumber }
          onFocus={ onFocus('phoneNumber') }
          onChange={ onChange('phoneNumber') }
          onBlur={ validatePhone }
        />
        <ErrorLabel htmlFor='phoneNumber'>{ phoneError }</ErrorLabel>
      </Field>
      <Field>
        <Label htmlFor='address' error={ errors.address }>Address</Label>
        <StyledInput
          id='address'
          data-testid='address'
          fluid
          error={ errors.address }
          onFocus={ onFocus('address') }
          onChange={ onChange('address') }
          onBlur={ validateEmpty('address') }
        />
      </Field>
      <Field>
        <Label htmlFor='apartmentSuite'>Apartment, suite, etc. (optional)</Label>
        <StyledInput
          id='apartmentSuite'
          data-testid='apartmentSuite'
          fluid
          onFocus={ onFocus('apartmentSuite') }
          onChange={ onChange('apartmentSuite') }
        />
      </Field>

      <ThreeColumnGrid>
        <CityField>
          <Label htmlFor='city' error={ errors.city }>City</Label>
          <StyledInput
            id='city'
            data-testid='city'
            fluid
            error={ errors.city }
            onFocus={ onFocus('city') }
            onChange={ onChange('city') }
            onBlur={ validateEmpty('city') }
          />
        </CityField>
        <StateField>
          <Label htmlFor='state' error={ errors.state }>State</Label>
          <StyledDropdown
            id='state'
            data-testid='state'
            error={ errors.state }
            fluid
            search
            selection
            options={ STATES }
            onFocus={ onFocus('state') }
            onChange={ onChange('state') }
          />
        </StateField>
        <ZipCodeField>
          <Label htmlFor='zipCode' error={ errors.zipCode }>Zip Code</Label>
          <StyledInput
            id='zipCode'
            data-testid='zipcode'
            error={ errors.zipCode }
            fluid
            type='number'
            min={ 0 }
            onFocus={ onFocus('zipCode') }
            onChange={ onChange('zipCode') }
            onBlur={ validateZipCode }
          />
          <ErrorLabel htmlFor='zipCode'>{ zipCodeError }</ErrorLabel>
        </ZipCodeField>
      </ThreeColumnGrid>
    </>
  )
}

export default ContactInformation
