import styled from '@emotion/styled'

export const Page = styled.div`
  background: ${({ theme }) => theme.background.level0};
  color:  ${({ theme }) => theme.text.default};
  min-height: 100vh;
  padding: 0 0 50px;
  box-sizing: border-box;
`

export const ShareLink = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 20px 20px 0;
`

export const Header = styled.header`
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Logo = styled.a`
  path {
    fill: ${props => props.theme.text.contrast};
  }

  &:hover path {
    fill: ${props => props.theme.text.action};
  }
`

export const Main = styled.main`
  display:flex;
  margin: 30px 5% 0;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
`

export const Content = styled.section`
  min-width: 300px;
  margin: 0 60px 20px;
  color: ${props => props.theme.text.default};

  > h1 {
    color: ${props => props.theme.text.contrast};
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 1em;
    text-transform: uppercase;
  }

  > p {
    font-size: 16px;
    line-height: 1.5em;
  }

  >.download + .download {
    margin-left: 10px;
  }
`

export const Footer = styled.footer`
  margin-top: 60px;
  text-align: center;

  a {
    color: ${props => props.theme.text.default};
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 1em;
    text-transform: uppercase;

    &:hover {
      color: ${props => props.theme.text.action};
    }
  }
`

export const AirForceLink = styled.div`
  a {
    color: ${props => props.theme.text.contrast};
    font-size: 16px;
    font-weight: normal;
    height: 20px;
    letter-spacing: 1px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      color: ${props => props.theme.text.action};
    }
  }
`
