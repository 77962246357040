import styled from '@emotion/styled'

export const EventDirectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const EventStartAndEndText = styled.div`
  color: ${props => props.theme.text.default};
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 18px;
  margin-bottom: 10px;
`

export const EventLocationTitleText = styled.div`
  color: ${props => props.theme.text.contrast};
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 18px;
`

export const EventLocationAddressText = styled.div`
  color: ${props => props.theme.text.subtle};
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.38px;
`

export const EventGetDirectionsLinkText = styled.div`
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.38px;
`
