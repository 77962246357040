import React, { useEffect, useRef, useState, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import {
  Header,
  Main,
  StyledLogo,
  HeaderText,
  EmailHighlight,
  VerificationInput,
  InputGroup,
  Description
} from './LineOfficer.styles'
import { SVG_LOGO_HEADER_SIZE } from './lineOfficer.constants'
import { Button } from '@aimhigh/web-components/web'
import { theme as ahaTheme } from '@aimhigh/theme'

const INPUT_COUNT = 6

function LineOfficerEmailVerification() {
  const inputRefs = useRef([])
  const [code, setCode] = useState(['', '', '', '', '', ''])

  const handleSubmit = useCallback(() => {
    const fullCode = code.join('')
    if (fullCode.length === 6) {
      // eslint-disable-next-line no-alert
      alert(`Submitting code: ${code.join('')}`)
    }
  }, [code])

  useEffect(() => {
    !isNaN(code.join('').length === 6) && handleSubmit()
  }, [code, handleSubmit])

  const handleChange = (e, i) => {
    e.preventDefault()

    const value = e.target.value?.substring(0, 1) ?? ''

    setCode([...code.slice(0, i), value, ...code.slice(i + 1)])

    if (value.length === 1) {
      if (i < INPUT_COUNT - 1) {
        inputRefs.current[i + 1].focus()
      } else {
        inputRefs.current[i].blur()
      }
    }
  }

  const handlePaste = (e, inputIndex) => {
    e.preventDefault()

    const pasteValue = e.clipboardData.getData('Text').trim()
    if (!isNaN(pasteValue)) {
      const newCode = [...code]
      
      setCode(newCode.map((char, i) => {
        if (i >= inputIndex) {
          return pasteValue[i - inputIndex]
        }

        return char
      }))
    }
  }

  const handleResend = () => {
    // eslint-disable-next-line no-alert
    alert(`Resend Code`)
  }

  return (
    <ThemeProvider theme={ ahaTheme }>
      <Header>
        <StyledLogo size={ SVG_LOGO_HEADER_SIZE } color={ ahaTheme.colors.text.white } />
        <HeaderText>
          <h1>Email Verification</h1>
          <p>An email verification code was sent to<br /><EmailHighlight>john.smith@gmail.com.</EmailHighlight> Please enter the code below.</p>
        </HeaderText>
      </Header>
      <Main>
        <section>
          <InputGroup>
            { Array.from(Array(INPUT_COUNT).keys()).map((key, i) => (
              <VerificationInput
                key={ key }
                ref={ e => {
                  inputRefs.current[i] = e
                } }
                type='number'
                value={ code[i] }
                onChange={ e => handleChange(e, i) }
                onPaste={ e => handlePaste(e, i) }
                onFocus={ e => e.target.select() }
                maxLength={ 1 }
                tabIndex={ 0 }
              />
            )) }
          </InputGroup>
          <Description>Didn't get an email? Check your spam folder or resend it.</Description>
          <Button variant='ghost' onClick={ handleResend } dark>Resend Verification Code</Button>
        </section>
      </Main>
    </ThemeProvider>
  )
}

export default withRouter(LineOfficerEmailVerification)
