import PropTypes from 'prop-types'
import * as React from 'react'

function AddLike(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={props.size} width={props.size} viewBox="0 0 48 48">
            <title>{props.title}</title>
            <g fill={props.color} class="nc-icon-wrapper">
                <path d="M46.137,17.045l-14.671-2.14L24.9,1.563c-.237-.499-.834-.711-1.333-.473-.207,.099-.375,.266-.473,.473l-6.56,13.342L1.863,17.045c-.552,.082-.933,.596-.851,1.149,.032,.218,.135,.42,.293,.574l10.617,10.383-2.506,14.667c-.096,.547,.27,1.069,.817,1.164,.22,.039,.446,.003,.644-.101l13.123-6.924h0c.001-6.053,4.909-10.959,10.963-10.957,.984,0,1.963,.133,2.911,.394l8.826-8.626c.399-.391,.406-1.031,.015-1.43-.155-.159-.358-.262-.578-.293Z" fill={props.color}>
                    </path>
                <path data-color="color-2" d="M35,29c-4.971,0-9,4.029-9,9s4.029,9,9,9,9-4.029,9-9-4.029-9-9-9Zm5,10h-4v4c0,.553-.448,1-1,1s-1-.447-1-1v-4h-4c-.552,0-1-.448-1-1s.448-1,1-1h4v-4c0-.552,.448-1,1-1s1,.448,1,1v4h4c.552,0,1,.448,1,1s-.448,1-1,1Z"></path>
            </g>
        </svg>
    )
}

AddLike.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
}

export default AddLike