import immutable from 'seamless-immutable'
import {
  createSquadron,
  fetchSquadrons,
  createFlight,
  getSquadronFlights,
  deleteSquadron,
  deleteFlight,
  updateSquadron,
  updateFlight
} from '../sagas/squadron.sagas'

const INITIAL_STATE = immutable({
  squadrons: [],
  flights: [],
})

export default function squadronReducers(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case createSquadron.SUCCESS:
      return state.merge({ squadrons: state.squadrons.concat(action.payload.squadron) })
    case fetchSquadrons.SUCCESS:
      return state.merge({ squadrons: action.payload })
    case updateSquadron.SUCCESS: {
      const stateSquadrons = [...state.squadrons]
      const updatedIndex = state.squadrons.findIndex(s => s.id === action.payload.squadron.id)
      stateSquadrons[updatedIndex] = action.payload.squadron
      return state.merge({ squadrons: stateSquadrons })
    }
    case deleteSquadron.SUCCESS:
      return state.merge({ squadrons: state.squadrons.filter(s => s.id !== action.payload.squadronId) })
    case getSquadronFlights.SUCCESS:
      return state.merge({ flights: state.flights.concat(action.payload.flights) })
    case createFlight.SUCCESS:
      return state.merge({
        flights: state.flights.concat(action.payload.flight) })
    case updateFlight.SUCCESS: {
      const stateFlights = [...state.flights]
      const updatedIndex = state.flights.findIndex(f => f.flightId === action.payload.flight.flightId)
      stateFlights[updatedIndex] = action.payload.flight
      return state.merge({ flights: stateFlights })
    }
    case deleteFlight.SUCCESS:
      return state.merge({ flights: state.flights.filter(f => f.flightId !== action.payload.flightId) })
    default:
      return state
  }
}
