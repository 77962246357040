import React from 'react'
import PropTypes from 'prop-types'
import { Footer, AirForceLink } from '../../pages/share/share.page.styles'
import { ThemeProvider } from '@emotion/react'
import {
  ErrorMessage,
  ErrorPageContainer,
  ErrorPageContent,
  ErrorPageTitleContainer,
  ErrorPageWrapper,
  ErrorStatus,
  ErrorStatusContainer,
  ErrorWrapper,
  IconContainer
} from './ErrorPage.styles'
import ShareContentTitle from '../Share/ShareContentTitle'
import SvgAfBird from '../../icons/AfBird'
import { NAV_ROUTES } from '../../navigation/routes'
import { DarkPalette, LightPalette } from '../../theme'

const STATUS_CODE_TYPES = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  500: 'Internal Server Error',
}

const DEFAULT_ERROR_STATUS_CODE = 500
const DEFAULT_ERROR_TYPE = STATUS_CODE_TYPES[DEFAULT_ERROR_STATUS_CODE]
const DEFAULT_ERROR_MESSAGE = 'Error: Request failed'

class ErrorPage extends React.PureComponent {
  getGenericErrorMessage = statusCode => (statusCode ? `${DEFAULT_ERROR_MESSAGE} with status code ${statusCode}` : DEFAULT_ERROR_MESSAGE)

  getErrorMessage = statusCode => {
    const { errorMessages } = this.props
    if (!!errorMessages && !!errorMessages.length) {
      const errorMessageIndex = errorMessages.findIndex(message => message.statusCode === statusCode)
      return errorMessageIndex === -1 ? this.getGenericErrorMessage(statusCode) : errorMessages[errorMessageIndex].message
    }
    return this.getGenericErrorMessage(statusCode)
  }

  render() {
    const { error } = this.props

    const request = error ? error.request : null
    const statusCode = request && request.status ? request.status : DEFAULT_ERROR_STATUS_CODE
    const type = statusCode ? STATUS_CODE_TYPES[statusCode] : DEFAULT_ERROR_TYPE
    const errorMessage = this.getErrorMessage(statusCode)

    return (
      <ThemeProvider theme={ DarkPalette }>
        <ErrorPageWrapper>
          <ErrorPageContainer>
            <ErrorPageContent>
              <IconContainer>
                <SvgAfBird size={ 170 } color={ LightPalette.background.level0 } />
              </IconContainer>
              <ErrorWrapper>
                <ErrorPageTitleContainer>
                  <ShareContentTitle label='Aim High' />
                </ErrorPageTitleContainer>
                <ErrorStatusContainer>
                  <ErrorStatus>
                    { statusCode }
                  </ErrorStatus>
                  <div>
                    { type }
                  </div>
                </ErrorStatusContainer>
                <ErrorMessage>
                  { errorMessage }
                </ErrorMessage>
              </ErrorWrapper>
            </ErrorPageContent>
          </ErrorPageContainer>
          <Footer>
            <AirForceLink>
              <a rel='noopener noreferrer' target='_blank' href='https://airforce.com'>AIRFORCE.COM</a>
            </AirForceLink>
            <a href={ NAV_ROUTES.PRIVACY_POLICY_PAGE } rel='noopener noreferrer' target='_blank'>Terms and Conditions</a>
          </Footer>
        </ErrorPageWrapper>
      </ThemeProvider>
    )
  }
}

ErrorPage.propTypes = {
  error: PropTypes.object,
  errorMessages: PropTypes.arrayOf(PropTypes.shape({
    statusCode: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
  })),
}

export default ErrorPage
