import { select } from 'redux-saga/effects'
import config from '../config'
import { selectAuthUserToken } from '../redux/selectors/auth.selectors'
import HttpService from './HttpService'

export const afreServiceUrl = config.apiUrl
const LOGIN_URL = `${afreServiceUrl}/auth/auth/login`
const ROLES_URL = `${afreServiceUrl}/user/roles`
const USERS_URL = `${afreServiceUrl}/user/users`
const REFERRALS_URL = `${afreServiceUrl}/lead/referrals`
const LEADS_URL = `${afreServiceUrl}/lead/leads`
const SQUADRONS_URL = `${afreServiceUrl}/squadrons`

// AUTH
function* createAccount(userDetails) {
  return yield post(USERS_URL, userDetails)
}

function* loginEmail(credentials) {
  return yield post(LOGIN_URL, credentials)
}

// eslint-disable-next-line camelcase
function* refreshToken(refresh_token, auth_token) {
  // eslint-disable-next-line camelcase
  return yield post(`${afreServiceUrl}/auth/auth/refresh`, { refresh_token, auth_token })
}

// REFERRAL
function* createReferral(referral) {
  return yield post(REFERRALS_URL, referral)
}

function* getReferrals() {
  return yield get(REFERRALS_URL)
}

function* markReferralCompleted(id, referral) {
  return yield put(`${REFERRALS_URL}/${id}/setCompleted`, referral)
}

function* getLead(id) {
  return yield get(`${LEADS_URL}/${id}`)
}

function* createAlternateLead(leadId, recruiterId, afrissEventId, data) {
  return yield post(`${LEADS_URL}/alternate`, { ...data, leadId, recruiterId, afrissEventId })
}

function* submitLead(data) {
  return yield post(`${LEADS_URL}/alternate`, data)
}

/* Roles */
function* getRoles(params) {
  return yield get(ROLES_URL, params)
}

function* updateRoles({ id, roles }) {
  return yield put(`${USERS_URL}/admin/${id}/roles`, { roles })
}

function* listRoleRequest(startAt) {
  return yield get(`${afreServiceUrl}/user/users/roleRequests`, startAt)
}

function* updateSpecificRole({ id, role }) {
  return yield post(`${afreServiceUrl}/user/users/${id}/roles/${role}`)
}

function* deleteSpecificRole({ id, role }) {
  return yield httpDelete(`${afreServiceUrl}/user/users/${id}/roles/${role}`)
}

function* createGrantRoleRequest({ organization, roles, organizationType }) {
  return yield post(`${afreServiceUrl}/user/users/createGrantRoleRequest`, { organization, roles, organizationType })
}

/* Users */
function* getUserById(userId) {
  return yield get(`${afreServiceUrl}/user/users/${userId}`)
}

function* getRecruiterById(userId) {
  return yield get(`${afreServiceUrl}/user/users/${userId}/recruiter`)
}

function* getUsers(params) {
  return yield get(`${USERS_URL}`, params)
}

function* updateUser({ id, ...user }) {
  return yield put(`${USERS_URL}/${id}`, user)
}

function* updateUserSquadronInfo(params) {
  return yield put(`${USERS_URL}/${params.id}/squadronInfo`, params)
}

function* fetchRecruiterExperienceData(userId) {
  return yield get(`${afreServiceUrl}/user/users/${userId}/recruiterExperience`)
}

// Squadron
function* createSquadron(params) {
  return yield post(`${SQUADRONS_URL}`, params)
}

function* fetchSquadrons(params) {
  return yield get(`${SQUADRONS_URL}`, params)
}

function* getSquadron(id) {
  return yield get(`${SQUADRONS_URL}/${id}`)
}

function* updateSquadron(squadron) {
  const id = squadron.id
  return yield put(`${SQUADRONS_URL}/${id}`, squadron)
}

function* deleteSquadron(id) {
  return yield httpDelete(`${SQUADRONS_URL}/${id}`)
}

function* createFlight(name, id) {
  return yield post(`${SQUADRONS_URL}/${id}/flights`, { name })
}

function* updateFlight({ id, flightId, name }) {
  return yield put(`${SQUADRONS_URL}/${id}/flights/${flightId}`, { name })
}

function* deleteFlight({ id, flightId }) {
  return yield httpDelete(`${SQUADRONS_URL}/${id}/flights/${flightId}`)
}

// OPA
function* processWeekGroupRows(params) {
  return yield post(`${afreServiceUrl}/opa/orders`, params)
}

function* addAfscProperties(params) {
  return yield post(`${afreServiceUrl}/opa/templates/afscs/${params.sort}`, params)
}

function* deleteAfscProperties(params) {
  return yield httpDelete(`${afreServiceUrl}/opa/templates/afscs/${params.sort}`)
}

function* updateAfscProperties(params) {
  return yield put(`${afreServiceUrl}/opa/templates/afscs/${params.sort}`, params)
}

function* getAfscProperties() {
  return yield get(`${afreServiceUrl}/opa/templates/afscs`)
}

function* getConditionals() {
  return yield get(`${afreServiceUrl}/opa/templates/conditionals`)
}

function* getVariables() {
  return yield get(`${afreServiceUrl}/opa/templates/variables`)
}

function* updateVariables(params) {
  return yield post(`${afreServiceUrl}/opa/templates/variables`, params)
}

function* getTemplateSections() {
  return yield get(`${afreServiceUrl}/opa/templates/sections`)
}

function* getTemplateSection(params) {
  return yield get(`${afreServiceUrl}/opa/templates/sections/${params.sort}`)
}

function* updateSectionVariations(params) {
  return yield put(`${afreServiceUrl}/opa/templates/sections/${params.sort}`, params)
}

function* deleteVariation(params) {
  return yield httpDelete(`${afreServiceUrl}/opa/templates/variations/${params.sort}`)
}

function* deleteWeekGroup(params) {
  return yield httpDelete(`${afreServiceUrl}/opa/weekGroups/${params.weekGroupId}`)
}

function* getWeekGroupRows(weekGroup) {
  return yield get(`${afreServiceUrl}/opa/weekGroups/${weekGroup}`)
}

function* updateWeekGroupRow(params) {
  return yield put(`${afreServiceUrl}/opa/weekGroups/${params.weekGroupId}/${params.sortId}`, params.weekGroupRow)
}

function* deleteWeekGroupRow(params) {
  return yield httpDelete(`${afreServiceUrl}/opa/weekGroups/${params.weekGroupId}/${params.sortId}`)
}

// AFCEP (Enlisted to Officer)
function* createAfcepRequest(request) {
  return yield put(`${afreServiceUrl}/e2o`, request)
}

function* checkIfEventIdValid(params) {
  return yield get(`${afreServiceUrl}/recruiting/afrissEvent/verify/${params.eventType}/${params.eventId}`)
}

// UTIL
function* get(url, queryParams = {}) {
  const token = yield select(selectAuthUserToken)
  return yield HttpService.get(url, queryParams, token)
}

function* put(url, data, queryParams = {}) {
  const token = yield select(selectAuthUserToken)
  return yield HttpService.put(url, data, queryParams, token)
}

function* post(url, data, queryParams = {}) {
  const token = yield select(selectAuthUserToken)
  return yield HttpService.post(url, data, queryParams, token)
}

function* httpDelete(url, queryParams = {}) {
  const token = yield select(selectAuthUserToken)
  return yield HttpService.delete(url, queryParams, token)
}

export default {
  createAccount,
  createAlternateLead,
  createReferral,
  deleteSpecificRole,
  getLead,
  getReferrals,
  getRoles,
  getUserById,
  getRecruiterById,
  getUsers,
  updateUser,
  updateRoles,
  updateSpecificRole,
  markReferralCompleted,
  loginEmail,
  refreshToken,
  createSquadron,
  fetchSquadrons,
  getSquadron,
  updateSquadron,
  deleteSquadron,
  createFlight,
  updateFlight,
  deleteFlight,
  updateUserSquadronInfo,
  fetchRecruiterExperienceData,
  listRoleRequest,
  createGrantRoleRequest,
  processWeekGroupRows,
  updateVariables,
  addAfscProperties,
  deleteAfscProperties,
  updateAfscProperties,
  getAfscProperties,
  getConditionals,
  getVariables,
  getTemplateSections,
  getTemplateSection,
  updateSectionVariations,
  deleteVariation,
  deleteWeekGroup,
  getWeekGroupRows,
  updateWeekGroupRow,
  deleteWeekGroupRow,
  createAfcepRequest,
  checkIfEventIdValid,
  submitLead,
}
