import { Form } from '@aimhigh/web-components/web'
import styled from '@emotion/styled'

export const StyledForm = styled(Form)`
  display: grid;

  gap: ${({ theme }) => theme.spacing.l}px;
`

export const FormHeader = styled.div`
  color: ${({ theme }) => theme.colors.text.white};

  > h2 {
    font-family: ${({ theme }) => theme.fontFamilies.stratum2};
    font-size: ${({ theme }) => theme.fontSizes.l}px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-transform: uppercase;

    margin: 0;
  }

  > p {
    font-family: ${({ theme }) => theme.fontFamilies.openSans};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    line-height: ${({ theme }) => theme.fontSizes.xs}px;

    margin: 0;
  }
`

export const SubHeader = styled.h3`
  font-family: ${({ theme }) => theme.fontFamilies.stratum2};
  font-size: ${({ theme }) => theme.fontSizes.m}px;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.text.white};
  
  text-align: center;
  text-transform: uppercase;

  margin: 0;
  margin-top: -${({ theme }) => theme.spacing.m}px;
`

export const ProgressDotContainer = styled.div`
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.spacing.l}px;
`

export const ProgressDot = styled.div`
    height: ${({ $focused }) => $focused ? 14 : 8}px;
    width: ${({ $focused }) => $focused ? 14 : 8}px;

    background: ${({ theme, $focused }) => $focused ? theme.colors.background.level0 : theme.colors.background.level3};

    border-radius: 50%;
    
    ${({ theme, $focused }) => $focused && `border: 2px solid ${theme.colors.system.actionLight};`};
`
