import React from 'react'
import PropTypes from 'prop-types'
import { FormSection } from 'redux-form'
import { FormContainerWrapper, FormSectionWrapper, FormTitle, RequiredInfo } from '../form.styles'
import GeneralInfoSection from './GeneralInfoSection'

ReferralInformationSection.propTypes = {
  degreeOptions: PropTypes.array,
  referAFriend: PropTypes.bool,
  selfSubmittingReferral: PropTypes.bool,
}

function ReferralInformationSection(props) {
  return (
    <FormSectionWrapper>
      <FormSection name='generalInfo'>
        <FormContainerWrapper>
          { !(props.referAFriend || props.selfSubmittingReferral) && (
            <FormTitle data-testid='form-title'>General Information</FormTitle>
          ) }
          { props.selfSubmittingReferral && (
            <FormTitle>Your information</FormTitle>
          ) }
          { props.referAFriend && (
            <FormTitle data-testid='form-title'>Who are you referring?</FormTitle>
          ) }
          <RequiredInfo data-testid='required-information'>*Required Information</RequiredInfo>
        </FormContainerWrapper>
        <GeneralInfoSection
          degreeOptions={ props.degreeOptions }
          referAFriend={ props.referAFriend }
          selfSubmittingReferral={ props.selfSubmittingReferral }
        />
      </FormSection>
    </FormSectionWrapper>
  )
}

export default ReferralInformationSection
