import PropTypes from 'prop-types'
import * as React from 'react'

function Team(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={props.size} width={props.size} viewBox="0 0 32 32"><title>team</title><g fill={props.color} class="nc-icon-wrapper">
        <circle cx="6" cy="6" r="3" data-color="color-2"></circle><circle cx="16" cy="4" r="4" fill={props.color}></circle>
        <path d="M10,25c-1.654,0-3-1.346-3-3v-7c0-1.476,.462-2.845,1.245-3.975-.083-.005-.161-.025-.245-.025H4c-2.209,0-4,1.791-4,4v5c0,.552,.448,1,1,1h1v6c0,.552,.448,1,1,1h6c.552,0,1-.448,1-1v-2Z" data-color="color-2"></path>
        <circle cx="26" cy="6" r="3" data-color="color-2"></circle>
        <path d="M22,25c1.654,0,3-1.346,3-3v-7c0-1.476-.462-2.845-1.245-3.975,.083-.005,.161-.025,.245-.025h2s2,0,2,0c2.209,0,4,1.791,4,4v5c0,.552-.448,1-1,1h-1s0,6,0,6c0,.552-.448,1-1,1h-3s-3,0-3,0c-.552,0-1-.448-1-1v-2Z" data-color="color-2"></path>
        <path d="M18,10h-4c-2.761,0-5,2.239-5,5v7c0,.552,.448,1,1,1h2v7c0,.552,.448,1,1,1h6c.552,0,1-.448,1-1v-7h2c.552,0,1-.448,1-1v-7c0-2.761-2.239-5-5-5Z" fill={props.color}></path>
        </g>
    </svg>
  )
}

Team.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

export default Team
