import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Accordion } from 'semantic-ui-react'
import { AccordionContent, AccordionHeader, AccordionIcon, Divider } from './accordion.styles'
import Contact from './Contact'
import EducationList from './EducationList'
import MilitaryServiceList from './MilitaryServiceList'
import { ProfileSectionTitle } from './profile.page.styles'

DetailsAccordion.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string,
  }).isRequired,
  recruiterExperience: PropTypes.shape({
    militaryServiceExperience: PropTypes.array,
    militaryEducation: PropTypes.array,
    civilianEducation: PropTypes.array,
  }),
  recruiterInformation: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  palette: PropTypes.object.isRequired,
}

function DetailsAccordion({ palette, user = {}, recruiterExperience = {}, recruiterInformation = {} }) {
  const [activeIndexes, setActiveIndexes] = useState([1, 2, 3])

  const handleClick = (_e, titleProps) => {
    const titleIndex = titleProps.index
    const newIndexes = activeIndexes.includes(titleIndex) ? activeIndexes.filter(value => value !== titleIndex) : [...activeIndexes, titleIndex]
    setActiveIndexes(newIndexes)
  }
  const renderAccordionItem = (index, title, content) => {
    return (
      <>
        <Divider />
        <AccordionHeader
          data-testid={ `${title}_Accordion` }
          active={ activeIndexes.includes(index) }
          index={ index }
          onClick={ handleClick }>
          <ProfileSectionTitle data-testid={ `${title} Section Title` }>{ title }</ProfileSectionTitle>
          <AccordionIcon name='chevron down' size='large' />
        </AccordionHeader>
        <AccordionContent active={ activeIndexes.includes(index) } data-testid={ `${title} Section Content` }>
          { content() }
        </AccordionContent>
      </>
    )
  }

  const renderContactInfo = () => (
    <Contact
      palette={ palette }
      phoneNumber={ user.phoneNumber }
      email={ user.email }
      facebook={ user.facebookUrl }
      youtube={ user.youtubeUrl }
      linkedIn={ user.linkedInUrl }
      twitter={ user.twitterUrl }
      instagram={ user.instagramUrl }
      googleBusinessPageUrl={ user.googleBusinessPageUrl }
      address={ recruiterInformation.address }
      city={ recruiterInformation.city }
      state={ recruiterInformation.state }
      zipCode={ recruiterInformation.zipCode }
      documents={ user.documents }
      calendarLink={ user.calendarLink }
    />
  )

  const renderEducation = () => (
    <EducationList
      palette={ palette }
      militaryEducation={ recruiterExperience.militaryEducation }
      civilianEducation={ recruiterExperience.civilianEducation }
    />
  )
  const hasMilitaryServiceExperience = () => {
    return !!recruiterExperience && recruiterExperience.militaryServiceExperience && recruiterExperience.militaryServiceExperience.length
  }
  const hasEducation = () => {
    const hasMilitaryEducation = recruiterExperience && recruiterExperience.militaryEducation && recruiterExperience.militaryEducation.length
    const hasCivilianEducation = recruiterExperience && recruiterExperience.civilianEducation && recruiterExperience.civilianEducation.length
    return hasMilitaryEducation || hasCivilianEducation
  }
  const renderMilitaryServiceList = () => (
    <MilitaryServiceList
      palette={ palette }
      list={ recruiterExperience.militaryServiceExperience }
    />
  )

  return (
    <Accordion fluid>
      { hasMilitaryServiceExperience() && renderAccordionItem(1, 'Military Experience', renderMilitaryServiceList) }
      { hasEducation() && renderAccordionItem(2, 'Education', renderEducation) }
      { !!user && (!!user.email || !!user.phoneNumber) && renderAccordionItem(3, 'Questions?', renderContactInfo) }
    </Accordion>
  )
}

export default DetailsAccordion
