import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, reset } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { Form, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { createSquadron } from '../../redux/sagas/squadron.sagas'
import { required } from '../validation/index'
import { SubmitButtonWrapper } from './squadron.form.styles'
import InputField from '../../components/Forms/InputField'
import { MAX_LENGTH } from '../../redux/constants/form.values.constants'

class SquadronCreateForm extends Component {

  handleSquadronSubmit = values => {
    const { createSquadron: createThisSquadron, clearForm } = this.props
    createThisSquadron(values)
    clearForm()
  }

  render() {
    return (
      <Form onSubmit={ this.props.handleSubmit(this.handleSquadronSubmit) } data-testid='squadron-name-section'>
        <Field
          id='name'
          name='name'
          label='Squadron Name'
          validate={ required }
          component={ InputField }
          maxLength={ MAX_LENGTH.STANDARD }
        />
        <SubmitButtonWrapper >
          <Button data-testid='add-squadron-button' primary type='submit'>Add Squadron</Button>
        </SubmitButtonWrapper>
      </Form>
    )
  }
}

SquadronCreateForm.propTypes = {
  handleSubmit: PropTypes.func,
  createSquadron: PropTypes.func,
  clearForm: PropTypes.func,
}

const mapStateToProps = () => {
  return {}
}

const mapActionsToProps = dispatch => {
  return {
    createSquadron: params => dispatch(createSquadron.request(params)),
    clearForm: () => dispatch(reset('squadronCreate')),
  }
}

const Formed = reduxForm({
  form: 'squadronCreate',
  enableReinitialize: true,
})(SquadronCreateForm)
const ConnectedSquadronCreateForm = connect(mapStateToProps, mapActionsToProps)(Formed)

export default withRouter(ConnectedSquadronCreateForm)
