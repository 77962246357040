import PropTypes from 'prop-types'
import * as React from 'react'

function DAdd(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={props.size} width={props.size} viewBox="0 0 48 48">
            <title>{props.title}</title>
            <g fill={props.color} class="nc-icon-wrapper">
                <path d="M44,20H28V4a1,1,0,0,0-1-1H21a1,1,0,0,0-1,1V20H4a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1H20V44a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V28H44a1,1,0,0,0,1-1V21A1,1,0,0,0,44,20Z" fill={props.color}>
                </path>
            </g>
        </svg>
    )
}

DAdd.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
}

export default DAdd