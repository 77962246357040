import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'

import ComponentLogo from '../../components/ComponentLogo/ComponentLogo'
import { DarkPalette } from '../../theme'
import spacing from '../../theme/spacing'
import { fontFamilies } from '../../theme/typography'
import { Header } from '../share/share.page.styles'
import { NAV_ROUTES } from '../../navigation/routes'
import { logPageView } from '../../redux/sagas/amplitudeAnalytics.sagas'

const Page = styled.div`
  background: ${({ theme }) => theme.background.level0};
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`

const Container = styled.div`
  background: ${({ theme }) => theme.content.contrast};
  width: 600px;
  align-self: center;
  border-radius: 8px;
  text-align: center;

  > h1 {
    font-family: ${() => fontFamilies.openSansRegular};
    color: ${props => props.theme.brand.brand};
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }

  > p {
    font-family: ${() => fontFamilies.openSansRegular};
    color: ${props => props.theme.brand.brand};
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
  }
`

const UnsubscribeConfirmation = ({ type }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logPageView.request(NAV_ROUTES.UNSUBSCRIBE_CONFIRMATION))
  }, [dispatch])

  return (
    <ThemeProvider theme={ DarkPalette }>
      <Page>
        <Header style={ { paddingTop: spacing.xl, paddingBottom: spacing.xl } }>
          <ComponentLogo recruiterComponent={ type } />
        </Header>

        <Container style={ { paddingTop: spacing.xl, paddingBottom: spacing.xl, paddingLeft: spacing.l, paddingRight: spacing.l } }>
          <h1 data-testid='unsubscribe-request-received-header'>Unsubscribe Request Received</h1>
          <p data-testid='unsubscribe-request-received-description'>
            We have received your unsubscribe request.<br />This change may take up to 24 hours to occur.
          </p>
        </Container>
      </Page>
    </ThemeProvider>
    
  )
}

UnsubscribeConfirmation.propTypes = {
  type: PropTypes.string,
}

export default UnsubscribeConfirmation
