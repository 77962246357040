/*eslint-disable*/
import * as React from "react";

function Info(props) {
  return (
    <svg width={ props.size } height={ props.size } viewBox="0 0 16 16" {...props}>
     <g strokeWidth="1" strokeLinejoin="round" strokeLinecap="round">
      <circle cx="8" cy="8" r="7.5" stroke={ props.color } fill="none"></circle>
      <circle cx="8" cy="4" r="1" stroke="none" fill={ props.color }></circle>
      <line x1="8" y1="12.5" x2="8" y2="7" stroke={ props.color } fill="none"></line>
    </g>
    </svg>
  );
}

export default Info;
