import PropTypes from 'prop-types'
import * as React from 'react'

function AirForceLogo(props) {
  return (
    <svg data-testid="air-force-logo" width={props.size * 1.08} height={props.size} viewBox="0 0 53 49" fill={props.color} xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3283 40.6572L31.5613 44.6552L26.3283 48.6322L21.1148 44.6552L26.3283 40.6572ZM33.1682 36.6697L38.0903 40.2763L32.1176 44.6447L30.2555 38.8079L33.1682 36.6697ZM19.4845 36.6697L22.4206 38.8079L20.5489 44.6447L14.5858 40.2763L19.4845 36.6697ZM26.3293 34.0114C27.0681 34.0114 27.6835 34.2814 28.1758 34.7864C28.6857 35.291 28.9497 35.9221 28.9497 36.6787C28.9497 37.3999 28.6857 38.0305 28.1758 38.5351C27.6835 39.0576 27.0681 39.3281 26.3293 39.3281C25.608 39.3281 24.9926 39.0576 24.4823 38.5351C23.9899 38.0305 23.7264 37.3999 23.7264 36.6787C23.7264 35.9221 23.9899 35.291 24.4823 34.7864C24.9926 34.2814 25.608 34.0114 26.3293 34.0114ZM52.4542 8.7574L48.07 17.9222L27.9085 32.6823L26.338 27.8975L52.4542 8.7574ZM47.2309 19.3907L42.9459 28.2834L36.9022 32.6823H28.9497L47.2309 19.3907ZM0.221924 8.7574L26.338 27.8975L24.7687 32.6823L4.58421 17.9222L0.221924 8.7574ZM5.44515 19.3907L23.7264 32.6823H15.7739L9.71105 28.2834L5.44515 19.3907ZM48.1571 0.78244L51.1483 7.20771L42.0077 14.074L48.1571 0.78244ZM4.52534 0.78244L10.6684 14.074L1.52773 7.20771L4.52534 0.78244Z" fill={props.color}/>
</svg>

  )
}

AirForceLogo.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

export default AirForceLogo