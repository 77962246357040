import axios from 'axios'
import { axiosConfig, queryStringURL } from './util'

export default class HttpService {
  static* get(url, queryParams = {}, authToken = undefined) {
    return yield axios.get(queryStringURL(url, queryParams), axiosConfig(authToken))
  }

  static* put(url, data, queryParams = {}, authToken = undefined) {
    return yield axios.put(queryStringURL(url, queryParams), data, axiosConfig(authToken))
  }

  static* post(url, data, queryParams = {}, authToken = undefined) {
    return yield axios.post(queryStringURL(url, queryParams), data, axiosConfig(authToken))
  }

  static* delete(url, queryParams = {}, authToken = undefined) {
    return yield axios.delete(queryStringURL(url, queryParams), axiosConfig(authToken))
  }
}
