import React from 'react'
import { LeadDivider } from '../../pages/profile/profile.page.styles'
import { FormDescription, FormHeading, FormSectionWrapper, ProfileLeadFormHeader } from '../form.styles'

function ReferringFormHeader() {
  return (
    <ProfileLeadFormHeader>
      <FormSectionWrapper>
        <FormHeading data-testid='form-header'>Refer a Friend</FormHeading>
        <FormDescription data-testid='form-description'>Tell us about your friend and we’ll connect them with a recruiter to learn more.</FormDescription>
        <LeadDivider />
      </FormSectionWrapper>
    </ProfileLeadFormHeader>
  )
}

export default ReferringFormHeader
