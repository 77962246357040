import React from 'react'
import { Form, FormSelect, FormTextField, Button } from '@aimhigh/web-components/web'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { fourColStyle } from '../../LineOfficer.styles'

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  column-gap: ${({ theme }) => theme.spacing.m}px;
  row-gap: ${({ theme }) => theme.spacing.xl}px;

  > * {
    grid-column: span 2;
  }
`

const ContactInfoForm = ({ onSubmit }) => {
  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
    },
  })

  const handleFormSubmit = values => {
    // eslint-disable-next-line no-alert
    alert(
      `--form values--\n${Object.keys(values)
        .map(key => {
          const value = values[key]
          return `${key}: ${
            typeof value === 'object' ? JSON.stringify(value) : value
          }`
        })
        .join('\n')}`
    )

    onSubmit()
  }

  return (
    <StyledForm formHooks={ formHooks } onSubmit={ handleFormSubmit }>
      <FormTextField label='Alternate Email' name='alt_email' style={ fourColStyle } dark />
      <FormTextField label='Primary Phone' name='primary_phone' dark />
      <FormTextField label='Alternate Phone' name='alt_phone' dark />
      <FormTextField label='Address Line 1' name='address_1' style={ fourColStyle } dark />
      <FormTextField label='City' name='city' dark />
      <FormSelect label='State' name='state' options={ [] } dark />
      <FormTextField label='Zip COde' name='zipcode' dark />
      <FormSelect label='County' name='county' options={ [] } dark />
      <FormSelect label='Country' name='country' options={ [] } dark />
      <Button type='submit' style={ fourColStyle } dark>Next</Button>
    </StyledForm>
  )
}

export default ContactInfoForm
