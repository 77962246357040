import { ThemeProvider } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dimmer, Form, Grid, Loader } from 'semantic-ui-react'
import { NAV_ROUTES } from '../../navigation/routes'
import { LightPalette } from '../../theme'
import spacing from '../../theme/spacing'
import { OpaPageHeader } from '../components/PageTitle/OpaPageHeader'
import {
  getVariables,
  selectGettingTemplateInfo,
  selectUpdatingTemplateInfo,
  selectUpdatingVariableError,
  selectVariables,
  updateVariables
} from '../redux/ducks/opa.duck'
import { ActionButton } from './Opa.styles'
import { Footer, FooterPage } from './ProcessWeekGroupPage.styles'
import { ErrorLabel } from './UploadWeekGroupPage.styles'
import { VariableInput } from './VariableInput'

const shouldUpdateBeDisabled = variables => {
  let disabled = true

  if (variables) {
    disabled = false
    for (const variable of variables) {
      const { value, type } = variable

      if (type === 'dynamic') {
        disabled = !value.text || !value.text.trim()
      } else if (value.condition) {
        const { trueValue, falseValue } = value
        disabled = !trueValue || !trueValue.text.trim() || !falseValue || !falseValue.text.trim()
      }
      if (disabled) {
        break
      }
    }
    if (!variables.some(variable => variable.isDirty)) {
      disabled = true
    }
  }

  return disabled
}

export const VariableManagementPage = () => {
  const dispatch = useDispatch()
  const currentVariables = useSelector(selectVariables)
  const updatingVariableErrors = useSelector(selectUpdatingVariableError) || { data: {} }
  const isGettingTemplateInfoLoading = useSelector(selectGettingTemplateInfo)
  const isUpdatingTemplateLoading = useSelector(selectUpdatingTemplateInfo)

  const [variables, setVariables] = useState([])

  const showSpinner = isGettingTemplateInfoLoading || isUpdatingTemplateLoading

  const updateTemplate = () => {
    dispatch(updateVariables.request(variables.filter(variable => variable.isDirty)))
  }

  useEffect(() => {
    dispatch(getVariables.request())
  }, [dispatch])

  useEffect(() => {
    setVariables(currentVariables)
  }, [currentVariables])

  function updateVariableValue(updatedValue, originalVariable, conditionCase) {
    const updatedVariables = [...variables]
    const variableIndex = updatedVariables.indexOf(originalVariable)
    if (conditionCase) {
      const value = { ...originalVariable.value }
      value[conditionCase] = { ...value[conditionCase], text: updatedValue }

      updatedVariables[variableIndex] = { ...updatedVariables[variableIndex], value, isDirty: true }
    } else {
      updatedVariables[variableIndex] = { ...updatedVariables[variableIndex], value: { text: updatedValue }, isDirty: true }
    }
    setVariables(updatedVariables)
  }

  function updateVariableDescription(updatedDescription, originalVariable) {
    const updatedVariables = [...variables]
    const variableIndex = updatedVariables.indexOf(originalVariable)

    updatedVariables[variableIndex] = { ...updatedVariables[variableIndex], description: updatedDescription, isDirty: true }

    setVariables(updatedVariables)
  }

  const updateDisabled = shouldUpdateBeDisabled(variables)

  return (
    <>
      <ThemeProvider theme={ LightPalette }>
        <FooterPage>
          <Dimmer.Dimmable dimmed={ showSpinner }>
            <Dimmer active={ showSpinner } page>
              <Loader>Loading</Loader>
            </Dimmer>
            <div style={ { margin: spacing.m } }>
              <OpaPageHeader
                pageRoute={ NAV_ROUTES.VARIABLES_PAGE }
                pageTitle='Variable Management'
              />
              <Grid>
                <Form style={ { width: '80%' } }>
                  {
                    variables && variables.map(variable => (
                      <VariableInput
                        key={ variable.sort }
                        variable={ variable }
                        onValueChange={ updateVariableValue }
                        onDescriptionChange={ updateVariableDescription }
                        error={ updatingVariableErrors.data[variable.sort] }
                      />
                    ))
                  }
                </Form>
              </Grid>
            </div>
          </Dimmer.Dimmable>
          <Footer>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column textAlign='right'>
                  {
                    updatingVariableErrors.data.error &&
                      <ErrorLabel key='variableError' width={ 5 } data-testid='update-variation-error-text'>
                        { updatingVariableErrors.data.error.toUpperCase() }
                      </ErrorLabel>
                  }
                  <ActionButton data-testid='variables-save-button' key='saveVariablesAction' width={ 1 } disabled={ updateDisabled } onClick={ updateTemplate }>Save</ActionButton>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Footer>
        </FooterPage>
      </ThemeProvider>
    </>
  )
}
