import PropTypes from 'prop-types'
import * as React from 'react'

function Team(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={props.size} width={props.size} viewBox="0 0 64 64">
        <title>compass 04</title>
        <g fill={props.color} class="nc-icon-wrapper">
            <path fill={props.color} d="M32,1C14.907,1,1,14.907,1,32s13.907,31,31,31s31-13.907,31-31S49.093,1,32,1z M15.182,40.904 c-0.136,0.063-0.28,0.093-0.421,0.093c-0.377,0-0.738-0.214-0.907-0.579c-1.09-2.345-1.701-4.836-1.827-7.419H8 c-0.552,0-1-0.448-1-1s0.448-1,1-1h4.051C12.56,20.771,20.771,12.56,31,12.051V8c0-0.552,0.448-1,1-1s1,0.448,1,1v4.027 c2.585,0.126,5.079,0.738,7.425,1.829c0.5,0.233,0.718,0.828,0.485,1.329s-0.826,0.718-1.329,0.485C37.2,14.562,34.649,14,32,14 c-9.925,0-18,8.075-18,18c0,2.647,0.561,5.196,1.667,7.576C15.9,40.077,15.683,40.672,15.182,40.904z M17,48 c-0.259,0-0.515-0.1-0.708-0.293c-0.32-0.32-0.385-0.816-0.158-1.208l11-19c0.087-0.151,0.213-0.277,0.364-0.364l19-11 c0.393-0.228,0.889-0.162,1.208,0.158c0.32,0.32,0.385,0.816,0.158,1.208l-11,19c-0.087,0.151-0.213,0.277-0.364,0.364l-19,11 C17.345,47.956,17.172,48,17,48z M56,33h-4.051C51.44,43.229,43.229,51.44,33,51.949V56c0,0.552-0.448,1-1,1s-1-0.448-1-1v-4.027 c-2.584-0.126-5.078-0.738-7.424-1.829c-0.501-0.233-0.718-0.828-0.485-1.328c0.232-0.501,0.827-0.719,1.328-0.485 C26.801,49.438,29.352,50,32,50c9.925,0,18-8.075,18-18c0-2.646-0.56-5.193-1.665-7.572c-0.233-0.501-0.015-1.096,0.485-1.328 c0.5-0.234,1.095-0.015,1.328,0.485c1.089,2.344,1.699,4.834,1.825,7.415H56c0.552,0,1,0.448,1,1S56.552,33,56,33z"></path> 
            <polygon data-color="color-2" points="19.744,44.256 35.268,35.268 28.732,28.732 "></polygon></g></svg>
  )
}

Team.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

export default Team
