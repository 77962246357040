import React from 'react'
import { Button } from '@aimhigh/web-components/web'
import { useForm } from 'react-hook-form'
import DocumentUpload from './DocumentUpload'
import { FormHeader, ProgressDot, ProgressDotContainer, StyledForm, SubHeader } from './DocumentsForm.styles'

const DocumentsForm = ({ onSubmit }) => {
  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
    },
  })

  const handleFormSubmit = values => {
    // eslint-disable-next-line no-alert
    alert(
      `--form values--\n${Object.keys(values)
        .map(key => {
          const value = values[key]
          return `${key}: ${
            typeof value === 'object' ? JSON.stringify(value) : value
          }`
        })
        .join('\n')}`
    )

    onSubmit()
  }

  return (
    <StyledForm formHooks={ formHooks } onSubmit={ handleFormSubmit }>
      <ProgressDotContainer>
        { [1, 2, 3, 4, 5].map((key, i) => (
          <ProgressDot key={ key } $focused={ i === 0 } />
        )) }
      </ProgressDotContainer>

      <FormHeader>
        <h2>Driver's License</h2>
        <p>Please upload an image of the front and back of your Driver's License.</p>
      </FormHeader>

      <DocumentUpload title='Upload' description='doc, docx, jpg, jpeg, png, pdf (max 10MB)' />
      <SubHeader>Front</SubHeader>

      <DocumentUpload title='Upload' description='doc, docx, jpg, jpeg, png, pdf (max 10MB)' />
      <SubHeader>Back</SubHeader>

      <div>
        <Button variant='ghost' dark>Skip for now</Button>
        <Button type='submit' dark>Next</Button>
      </div>
    </StyledForm>
  )
}

export default DocumentsForm
