import { OPA_ROLES, ROLES } from '../config'
import { NAV_ROUTES } from './routes'

const NON_ADMIN_NON_OPA_ROLES = [
  ROLES.GENERAL,
  ROLES.MANAGER,
  ROLES.RECRUITER,
  ROLES.UNIT_MAINTENANCE,
  ROLES.DEP,
  ROLES.FLIGHT_CHIEF,
  ROLES.COMMANDER,
  ROLES.STUDENT_RECRUITER,
]

export const getInitialRouteByRole = (roles = []) => {

  if (roles.includes(ROLES.ADMIN) || roles.includes(ROLES.MASTER_ADMIN)) {
    return NAV_ROUTES.USERS_PAGE
  } else if (roles.includes(ROLES.MASS_ALERTS)) {
    return NAV_ROUTES.NOTIFICATIONS_PAGE
  } else if (roles.includes(OPA_ROLES.ADMIN)) {
    return NAV_ROUTES.UPLOAD_WEEK_GROUP_PAGE
  } else if (roles.includes(OPA_ROLES.MTL)) {
    return NAV_ROUTES.FIND_ORDERS_PAGE
  } else if (roles.includes(ROLES.OA)) {
    return NAV_ROUTES.LINE_OFFICER_DASHBOARD
  } else if (roles.some(r => NON_ADMIN_NON_OPA_ROLES.includes(r))) {
    return NAV_ROUTES.DELETE_ACCOUNT_PAGE
  } else {
    return NAV_ROUTES.LOGIN_PAGE
  }
}
