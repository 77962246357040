export const weekGroupRowProperties = [
  { key: 'armsStatus', value: 'armsStatus', text: 'ARMS-LC Status' },
  { key: 'editOrders', value: 'editOrders', text: '' },
  { key: 'specialOrderNumber', value: 'specialOrderNumber', text: 'Sp. Order Number' },
  { key: 'name', value: 'name', text: 'Name' },
  { key: 'grade', value: 'grade', text: 'Grade' },
  { key: 'maskedSocial', value: 'maskedSocial', text: 'SSAN' },
  { key: 'originalFlight', value: 'originalFlight', text: 'Original Flight' },
  { key: 'currentFlight', value: 'currentFlight', text: 'Current Flight' },
  { key: 'squadron', value: 'squadron', text: 'Squadron' },
  { key: 'primaryAfsc', value: 'primaryAfsc', text: 'PAFSC' },
  { key: 'controlAfsc', value: 'controlAfsc', text: 'CAFSC' },
  { key: 'classId', value: 'classId', text: 'Class Id' },
  { key: 'scheduledEntryDate', value: 'scheduledEntryDate', text: 'Scheduled Entry Date' },
  { key: 'gainUnit', value: 'gainUnit', text: 'Gain Unit' },
  { key: 'gainBase', value: 'gainBase', text: 'Gain Base' },
  { key: 'proceedNoLaterThanDate', value: 'proceedNoLaterThanDate', text: 'Proceed / RNLTD' },
  { key: 'bmtGraduationDate', value: 'bmtGraduationDate', text: 'Graduation Date' },
  { key: 'ssbiInitiatedDate', value: 'ssbiInitiatedDate', text: 'SSBI Initiated' },
  { key: 'maritalStatus', value: 'maritalStatus', text: 'Marital Status' },
  { key: 'isSpaceForce', value: 'isSpaceForce', text: 'Space Force' },
  { key: 'editDeleteWeekGroupRow', value: 'editDeleteWeekGroupRow', text: '' },
]

export const weekGroupRowPropertiesWithTextValues = weekGroupRowProperties.filter(weekGroupRowProp => weekGroupRowProp.text !== '')
