import React from 'react'
import PropTypes from 'prop-types'
import FieldLabel from '../components/Forms/FieldLabel'

export const ServerError = ({ error, style }) => {
  const message = error.message || 'An error occurred'
  return <FieldLabel style={ style } hasError>{ message }</FieldLabel>
}
const ValidationError = ({ validationErrors }) => {
  const keys = Object.keys(validationErrors)
  let numbErrors = 0
  keys.forEach(key => {
    const subKeys = Object.keys(validationErrors[key])
    numbErrors += subKeys.length
  })
  if (numbErrors === 0) {
    return null
  }
  const sentence = numbErrors === 1 ? `There is ${numbErrors} validation error` : `There are ${numbErrors} validation errors`
  return <FieldLabel hasError data-testid='validation-errors'>{ sentence }</FieldLabel>
}
ValidationError.propTypes = {
  validationErrors: PropTypes.any,
}
ServerError.propTypes = {
  error: PropTypes.any,
  style: PropTypes.any,
}
export default ValidationError
