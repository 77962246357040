import styled from '@emotion/styled'
import { Checkbox } from 'semantic-ui-react'
import Button from '../../components/Button/Button'
import { FormTitle } from '../../forms/form.styles'
import { LightPalette } from '../../theme'
import spacing from '../../theme/spacing'
import { fontSizes } from '../../theme/typography'

export const Content = styled.div`
  padding: ${spacing.s}px ${spacing.m}px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ContentContainer = styled.div`
  max-width: 350px;
  padding-bottom: ${fontSizes.s}px;
  padding-top: ${fontSizes.m}px;
`

export const Title = styled(FormTitle)`
  color: ${props => (props.error ? props.theme.text.negative : props.theme.text.default)};
  text-align: center;
`

export const Subtitle = styled.div`
  color: ${props => props.theme.text.default};
  text-align: center;
  font-size: ${fontSizes.m}px;
  margin-bottom: ${spacing.m}px;
`

export const Roles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-height: 120px;
`

export const RoleCheckbox = styled(Checkbox)`
  width: 50%;
  margin-bottom: ${spacing.m}px;
`

export const OrganizationContentContainer = styled(ContentContainer)`
  border-top: ${props => props.theme.background.accent} 1px solid;
`

export const SendRequestButton = styled(Button)`
  margin-top: ${spacing.m}px;
  background-color: ${LightPalette.background.action};
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const LogoutButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${spacing.m}px;
  margin-top: ${spacing.s}px;
  margin-bottom: -${spacing.s}px;
`
