import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Field } from 'redux-form'
import DropdownField from '../../components/Forms/DropdownField'
import InputField from '../../components/Forms/InputField'
import TextAreaField from '../../components/Forms/TextAreaField'
import { AccordionHeader, AccordionIcon } from '../../pages/profile/accordion.styles'
import { LeadDivider } from '../../pages/profile/profile.page.styles'
import { MAX_LENGTH } from '../../redux/constants/form.values.constants'
import theme from '../../theme'
import { FormAccordionHeader, FormAccordionTitle, FormItem, FormItemBreak, FormSectionContainer, PaddedFormItemBreak } from '../form.styles'
import {
  email,
  hasCharactersAfterStrippingSpecial,
  minAge16,
  phoneNumber,
  referredNotesMaxLength,
  required,
  validAfrissDate,
  zipcode
} from '../validation'
import { HEIGHT_FEET_OPTIONS, HEIGHT_INCHES_OPTIONS, WEIGHT_OPTIONS } from './referral.constants'

GeneralInfoSection.propTypes = {
  degreeOptions: PropTypes.array,
  referAFriend: PropTypes.bool,
  selfSubmittingReferral: PropTypes.bool,
}

function GeneralInfoSection({ degreeOptions, referAFriend, selfSubmittingReferral }) {
  const [heightFeet, setHeightFeet] = useState(undefined)
  const [heightInches, setHeightInches] = useState(undefined)
  const [heightError, setHeightError] = useState(undefined)
  const [weightError, setWeightError] = useState(undefined)
  const [optionalAccordionOpen, setOptionalAccordionOpen] = useState(false)

  const [educationLevelError, setEducationLevelError] = useState(undefined)
  const educationLevelChanged = value => {
    setEducationLevelError(value ? undefined : 'Must provide Education Level')
  }

  const heightFeetChanged = value => {
    let hasError
    if (value && (typeof value !== 'number' || typeof heightInches !== 'number')) {
      hasError = 'Invalid Height'
    }
    setHeightError(hasError)
    setHeightFeet(value)
  }

  const heightInchesChanged = value => {
    let hasError
    if (value && (typeof value !== 'number' || typeof heightFeet !== 'number')) {
      hasError = 'Invalid Height'
    }
    if (!!heightFeet && (!value && value !== 0)) {
      hasError = 'Invalid Height Inches'
    }
    setHeightError(hasError)
    setHeightInches(value)
  }

  const weightChanged = value => {
    setWeightError((value && typeof value !== 'number') ? 'Invalid weight' : undefined)
  }

  const requiredFields = () => {
    return (
      <>
        <FormItem width={ { min: '220px', base: 'auto' } } data-testid='first-name'>
          <Field
            id='firstName'
            name='firstName'
            label='First Name*'
            component={ InputField }
            type='text'
            validate={ [required, hasCharactersAfterStrippingSpecial] }
            maxLength={ MAX_LENGTH.STANDARD }
          />
        </FormItem>
        <FormItem width={ { min: '220px', base: 'auto' } } data-testid='last-name'>
          <Field
            id='lastName'
            name='lastName'
            label='Last Name*'
            component={ InputField }
            type='text'
            validate={ [required, hasCharactersAfterStrippingSpecial] }
            maxLength={ MAX_LENGTH.STANDARD }
          />
        </FormItem>
        <FormItem width={ { base: '100%' } } data-testid='email'>
          <Field
            id='email'
            name='email'
            label='Email*'
            component={ InputField }
            type='text'
            validate={ [required, email] }
            maxLength={ MAX_LENGTH.STANDARD }
          />
        </FormItem>
        <FormItem width={ { min: '220px', base: 'auto' } } data-testid='phone-number'>
          <Field
            id='phoneNumber'
            name='phoneNumber'
            label='Phone Number*'
            component={ InputField }
            type='text'
            validate={ [required, phoneNumber] }
            maxLength={ 16 }
          />
        </FormItem>
        <FormItem width={ { min: '220px', base: 'auto' } } data-testid='zipcode'>
          <Field
            id='zipCode'
            name='zipCode'
            label='ZIP Code*'
            placeholder='00000'
            component={ InputField }
            type='text'
            validate={ [required, zipcode] }
            maxLength={ 5 }
            parse={ value => value && value.replace(/(\D)/gu, '') }
          />
        </FormItem>
        <FormItem width={ { min: '220px', base: '50%' } } data-testid='date-of-birth'>
          <Field
            id='dateOfBirth'
            name='dateOfBirth'
            label='Date of Birth*'
            component={ InputField }
            validate={ [required, minAge16, validAfrissDate] }
            type='date'
          />
        </FormItem>
        { referAFriend || selfSubmittingReferral ? (
          <FormItem width={ { min: '220px', base: '100%' } } data-testid='notes'>
            <Field
              id='notes'
              name='notes'
              label='Notes'
              component={ TextAreaField }
              type='textarea'
              validate={ [referredNotesMaxLength] }
              placeholder='Anything you want to tell the recruiter?'
            />
          </FormItem>) : <></> }
      </>
    )
  }

  const expandOptionalFields = () => {
    return (
      <>
        <FormItemBreak />
        <FormItem width={ { min: '220px', base: '50%' } }>
          <div style={ { display: 'flex', flexDirection: 'row' } } data-testid='height-feet-inch'>
            <Field
              id='heightFeet'
              name='heightFeet'
              label='Height'
              placeholder='0 ft'
              value={ heightFeet }
              onChange={ heightFeetChanged }
              options={ [{ value: undefined, text: 'None', key: 'None' }, ...HEIGHT_FEET_OPTIONS] }
              component={ DropdownField }
              hasError={ !!heightError }
            />
            <Field
              id='heightInches'
              name='heightInches'
              placeholder='0 in'
              value={ heightInches }
              onChange={ heightInchesChanged }
              options={ [{ value: undefined, text: 'None', key: 'None' }, ...HEIGHT_INCHES_OPTIONS] }
              component={ DropdownField }
            />
          </div>
        </FormItem>
        <FormItem width={ { min: '220px' } } data-testid='weight'>
          <Field
            id='weight'
            name='weight'
            label='Weight'
            placeholder={ WEIGHT_OPTIONS[0].text }
            options={ [{ value: undefined, text: 'None', key: 'None' }, ...WEIGHT_OPTIONS] }
            component={ DropdownField }
            onChange={ weightChanged }
            hasError={ !!weightError }
          />
        </FormItem>
        <FormItemBreak />
        { degreeOptions?.length ?
          <FormItem data-testid='education-level'>
            <Field
              id='educationLevel'
              name='educationLevel'
              label='Education Level'
              placeholder='Select education level'
              options={ degreeOptions }
              component={ DropdownField }
              hasError={ !!educationLevelError }
              onChange={ educationLevelChanged }
            />
          </FormItem> : <></> }
      </>
    )
  }

  const collapsableSection = name => {

    return (
      <>
        <LeadDivider />
        <AccordionHeader
          data-testid='optional-accordion'
          active={ optionalAccordionOpen }
          onClick={ () => setOptionalAccordionOpen(!optionalAccordionOpen) }
          style={ { width: '100%', marginBottom: theme.spacing.m } }>
          <FormAccordionHeader>
            <FormAccordionTitle>{ name }</FormAccordionTitle>
            <AccordionIcon name='chevron down' size='large' />
          </FormAccordionHeader>
        </AccordionHeader>
        <FormItemBreak />
        { optionalAccordionOpen && expandOptionalFields() }
        <PaddedFormItemBreak />
        <LeadDivider />
      </>
    )
  }

  return (
    <React.Fragment>
      <FormSectionContainer>
        { requiredFields() }
        <PaddedFormItemBreak />
        { referAFriend ? <></> : collapsableSection('Advanced Interest (Optional)') }
      </FormSectionContainer>
    </React.Fragment>
  )
}

export default GeneralInfoSection
