import React from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import {
  Header,
  Main,
  FloatingHeaderIcons,
  TinyButton
} from '../LineOfficer.styles'
import { theme as ahaTheme, withOpacity } from '@aimhigh/theme'
import Timeline from '../../../icons/Timeline'
import AddUser from '../../../icons/AddUser'
import Notification from '../../../icons/Notification'
import Checkmark from '../../../icons/Checkmark'
import ChevronRight from '../../../icons/ChevronRight'
import Timer from '../../../icons/Timer'
import { HeaderTitle, ProgressCircleLabel, ProgressCircleWrapper, StatusCircle, StatusRows, StatusRow, StatusLine } from './LineOfficerInitialApplication.styles'
import LineOfficerProgressCircle from '../LineOfficerProgressCircle'
import { mockSteps } from '../lineOfficer.constants'

const HEADER_HEIGHT = 280 + ahaTheme.spacing.l

function LineOfficerProgress() {
  const latestStep = mockSteps.findIndex(({ complete }) => !complete)
  const completeCount = mockSteps.filter(({ complete }) => complete).length ?? 0

  const handleHeaderIcon = iconName => {
    // eslint-disable-next-line no-alert
    alert(iconName)
  }

  const handleLineClick = href => {
    // eslint-disable-next-line no-alert
    alert(`Navigate to ${href}`)
  }

  return (
    <ThemeProvider theme={ ahaTheme }>
      <Header $customHeight={ HEADER_HEIGHT }>
        <FloatingHeaderIcons>
          <Timeline color={ ahaTheme.colors.text.white } onClick={ () => handleHeaderIcon('timeline') } />
          <div />
          <AddUser color={ ahaTheme.colors.text.white } onClick={ () => handleHeaderIcon('addUser') } />
          <Notification color={ ahaTheme.colors.text.white } onClick={ () => handleHeaderIcon('notification') } />
        </FloatingHeaderIcons>
        <ProgressCircleWrapper>
          <LineOfficerProgressCircle
            size={ 120 }
            percentage={ latestStep / mockSteps.length }
          />
          <ProgressCircleLabel>
            <h3>{ `${completeCount} of ${mockSteps.length}` }</h3>
            <p>Completed</p>
          </ProgressCircleLabel>
        </ProgressCircleWrapper>
        <HeaderTitle>Initial Application</HeaderTitle>
      </Header>
      <Main $customHeight={ HEADER_HEIGHT }>
        <section>
          <StatusRows>
            <StatusLine />
            { mockSteps.map(({ label, duration, complete, href }, index) => (
              <StatusRow key={ label } onClick={ () => handleLineClick(href) }>
                <StatusCircle
                  $complete={ complete }
                  $next={ index === latestStep }>
                  { complete && <Checkmark /> }
                </StatusCircle>
                <div>
                  <h2>{ label }</h2>
                  <p>{ complete ? 'Completed' : <><Timer color={ withOpacity(ahaTheme.colors.text.white, 0.75) } />{ `${duration} minutes` }</> }</p>
                </div>
                { index === latestStep ? <TinyButton dark>Start</TinyButton> : <ChevronRight color={ ahaTheme.colors.text.white } size={ 20 } /> }
              </StatusRow>
            )) }
          </StatusRows>
        </section>
      </Main>
    </ThemeProvider>
  )
}

export default withRouter(LineOfficerProgress)
