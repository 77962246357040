import * as React from "react";

const Approve = (props) => (
  <svg
    width={ props.size }
    height={ props.size }
    fill="none"
    viewBox="0 0 38 38"
    {...props}
  >
    <path
      d="M29.688 24.938a3.563 3.563 0 1 0 0-7.126 3.563 3.563 0 0 0 0 7.125ZM8.313 24.938a3.563 3.563 0 1 0 0-7.126 3.563 3.563 0 0 0 0 7.125ZM15.438 35.625v-3.542c0-.843-.443-1.618-1.168-2.048-1.155-.683-3.157-1.535-5.957-1.535-2.837 0-4.826.847-5.969 1.529a2.373 2.373 0 0 0-1.156 2.041v3.555h14.25ZM36.813 35.625v-3.542c0-.843-.443-1.618-1.168-2.048-1.155-.683-3.157-1.535-5.958-1.535-2.836 0-4.825.847-5.968 1.529a2.373 2.373 0 0 0-1.157 2.041v3.555h14.25ZM13.063 8.313l3.562 3.562 8.313-8.313"
      stroke= { props.color }
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </svg>
);

export default Approve;
