import PropTypes from 'prop-types'
import React from 'react'
import { Editor, Element as SlateElement, Point, Range } from 'slate'

const nodeMatches = node => !Editor.isEditor(node) && SlateElement.isElement(node) && node.type === 'table-cell'

export const withTables = editor => {
  const { deleteBackward, deleteForward, insertBreak } = editor

  editor.deleteBackward = unit => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: nodeMatches,
      })

      if (cell) {
        const [, cellPath] = cell
        const start = Editor.start(editor, cellPath)

        if (Point.equals(selection.anchor, start)) {
          return
        }
      }
    }

    deleteBackward(unit)
  }

  editor.deleteForward = unit => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.id === 'table-cell',
      })

      if (cell) {
        const [, cellPath] = cell
        const end = Editor.end(editor, cellPath)

        if (Point.equals(selection.anchor, end)) {
          return
        }
      }
    }

    deleteForward(unit)
  }

  editor.insertBreak = () => {
    const { selection } = editor
    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: nodeMatches,
      })

      if (table) {
        return
      }
    }

    insertBreak()
  }
  return editor
}

export const Table = ({ attributes, children }) => {

  return (
    <table style={ { margin: '0 0 1em' } }>
      <tbody { ...attributes }>{ children }</tbody>
    </table>
  )

}

Table.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.array,
}

export const TableRow = ({ attributes, children }) => <tr { ...attributes }>{ children }</tr>

TableRow.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.array,
}

export const TableCell = ({ attributes, children }) => <td { ...attributes }>{ children }</td>

TableCell.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.array,
}

