import PropTypes from 'prop-types'
import * as React from 'react'

const SvgBaseManagement = props => (
  <svg
    id="BaseManagement_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    height={ props.size }
    width={ props.size }
    viewBox="0 0 32 32"
    style={ {
      enableBackground: 'new 0 0 32 32',
    } }
    xmlSpace="preserve"
    role="img"
    { ...props }>
    <style>
      {'.BaseManagement_svg__st0{fill:none;stroke:#fff;stroke-miterlimit:10}'}
    </style>
    <path
      className="BaseManagement_svg__st0"
      d="M16 10.9V.8h7.1v5.1H16M2.8 17h26.4M16 18c.2 5.2 2.8 10.1 7.1 13.2h6.1V14c0-1.7-1.4-3-3-3H5.9c-1.7 0-3 1.4-3 3v17.2H9c4.2-3.1 6.8-7.9 7-13.2"
    />
  </svg>
)
export default SvgBaseManagement

SvgBaseManagement.propTypes = {
  size: PropTypes.number,
}
