import React from 'react'
import { IconWrapper, ItemDescription, ItemDescriptionWrapper, ItemTitle, ItemWrapper } from './starr.page.styles'

const StarrItem = props => {
  return (

    <ItemWrapper>
      <IconWrapper>
        <props.icon size={ 25 } color='#FFF' title={ props.title } />
      </IconWrapper>
      <ItemDescriptionWrapper>
        <ItemTitle>
          { props.title }
        </ItemTitle>
        <ItemDescription>
          { props.description }
        </ItemDescription>
      </ItemDescriptionWrapper>
    </ItemWrapper>
  )
}

export default StarrItem
