import React from 'react'
import { Form, FormSelect, FormTextField, FormDatepicker, Button } from '@aimhigh/web-components/web'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { fourColStyle, oneColStyle, threeColStyle } from '../../LineOfficer.styles'

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  column-gap: ${({ theme }) => theme.spacing.m}px;
  row-gap: ${({ theme }) => theme.spacing.xl}px;

  > * {
    grid-column: span 2;
  }
`

const DemographicsForm = ({ onSubmit }) => {
  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
    },
  })

  const handleFormSubmit = values => {
    // eslint-disable-next-line no-alert
    alert(
      `--form values--\n${Object.keys(values)
        .map(key => {
          const value = values[key]
          return `${key}: ${
            typeof value === 'object' ? JSON.stringify(value) : value
          }`
        })
        .join('\n')}`
    )

    onSubmit()
  }

  return (
    <StyledForm formHooks={ formHooks } onSubmit={ handleFormSubmit }>
      <FormSelect label='Prefix' name='prefix' options={ [] } style={ oneColStyle } dark />
      <FormTextField label='Legal First Name' name='first_name' style={ threeColStyle } dark />
      <FormTextField label='Legal Middle Name' name='middle_name' style={ fourColStyle } dark />
      <FormTextField label='Legal Last Name' name='last_name' style={ threeColStyle } dark />
      <FormSelect label='Suffix' name='suffix' options={ [] } style={ oneColStyle } dark />
      <FormSelect label='Birth Gender' name='birth_gender' options={ [] } dark />
      <FormSelect label='Preferred Gender' name='preferred_gender' options={ [] } dark />
      <FormTextField label='Present Occupation' name='occupation' style={ fourColStyle } dark />
      <FormTextField label='SSN' name='ssn' style={ fourColStyle } dark />
      <FormDatepicker label='Date of Birth' name='dob' dark />
      <FormSelect label='Country of Birth' name='country_of_birth' options={ [] } dark />
      <FormSelect label='State of Birth' name='state_of_birth' options={ [] } dark />
      <FormTextField label='City of Birth' name='city_of_birth' dark />
      <FormTextField label='County of Birth' name='county_of_birth' dark />
      <FormSelect label='Martial Status' name='martial_status' options={ [] } dark />
      <Button type='submit' style={ fourColStyle } dark>Next</Button>
    </StyledForm>
  )
}

export default DemographicsForm
