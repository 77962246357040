import React from 'react'
import { AfcepRequestContent, AfcepRequestFooter, AfcepRequestFooterContentContactInfo, AfcepRequestFooterContentContactInfoWrapper, AfcepRequestSectionContainer, PrivacyPolicyLink } from './AfcepLandingPage.styles'
import { NAV_ROUTES } from '../../../navigation/routes'

export const AfcepLandingPageFooter = () => {
  return (
    <AfcepRequestFooter>
      <AfcepRequestContent data-testid='footer-container'>
        <h3 data-testid='questions-header'>Questions?</h3>
        <AfcepRequestFooterContentContactInfoWrapper>
          <AfcepRequestFooterContentContactInfo>
            <div>
              <div><b>Air Force questions: Contact Line Officer Accessions (AFRS/RSCOL).</b></div>
              <br />
              <div>Email: <a href='mailto:AFRS.LO.Accessions@us.af.mil'>AFRS.LO.Accessions@us.af.mil</a></div>
              <div>DSN: 664-0340</div>
              <div>Comm: (210) 565-0340</div>
            </div>
          </AfcepRequestFooterContentContactInfo>
          <AfcepRequestFooterContentContactInfo>
            <div>
              <div><b>Space Force questions: Contact Space Force Recruiting (AFRS/RSOS).</b></div>
              <br />
              <div>Email: <a href='mailto:AFRS.RSOS.AFRSSpaceForceRec@us.af.mil'>AFRS.RSOS.AFRSSpaceForceRec@us.af.mil</a></div>
              <div>DSN: 665-0530</div>
              <div>Comm: (210) 565-0530</div>
            </div>
          </AfcepRequestFooterContentContactInfo>
        </AfcepRequestFooterContentContactInfoWrapper>
        <AfcepRequestSectionContainer>
          <b>Not Reg AF or SF?</b>
          <p>
            Individuals in Guard, Reserves or Sister Services must apply through a Recruiter. Go to AirForce.com to locate a recruiter for officer
            accessions to enter RegAF, U.S. Space Force, Air Guard, Air Force Reserves.
          </p>
        </AfcepRequestSectionContainer>
      </AfcepRequestContent>
      <PrivacyPolicyLink data-testid='privacy-policy-link' href={ NAV_ROUTES.PRIVACY_POLICY_PAGE } rel='noopener noreferrer' target='_blank'>Privacy Policy</PrivacyPolicyLink>
    </AfcepRequestFooter>
  )

}
