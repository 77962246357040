import React from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import {
  Header,
  Main,
  StyledLogo,
  HeaderText,
  TinyButton,
  FloatingHeaderIcons
} from '../LineOfficer.styles'
import { mockSteps, SVG_LOGO_HEADER_SIZE } from '../lineOfficer.constants'
import { theme as ahaTheme, withOpacity } from '@aimhigh/theme'
import {
  FinishPrimarySection,
  FinishInnerWrapper,
  DashboardStatus,
  DashboardLabelSection,
  DashboardContent,
  DashboardPrimarySection,
  ProgressInnerWrapper,
  CurrentTaskInnerWrapper,
  HeaderSubTitle
} from './LineOfficerDashboard.styles'
import Timeline from '../../../icons/Timeline'
import AddUser from '../../../icons/AddUser'
import Notification from '../../../icons/Notification'
import Tags from '../../../icons/Tags'
import Flight from '../../../icons/Flight'
import ChevronRight from '../../../icons/ChevronRight'
import ProgressBar from '../../../components/ProgressBar'
import UserBadge from '../../../icons/UserBadge'
import Timer from '../../../icons/Timer'
import LineOfficerProgressCircle from '../LineOfficerProgressCircle'

function LineOfficerDashboard() {
  const name = 'John Smith'
  const email = 'jsmith@gmail.com'
  const status = 'In Progress'
  const currentStep = 1
  const minutes = 1

  const handleHeaderIcon = iconName => {
    // eslint-disable-next-line no-alert
    alert(iconName)
  }

  return (
    <ThemeProvider theme={ ahaTheme }>
      <Header>
        <FloatingHeaderIcons>
          <Timeline color={ ahaTheme.colors.text.white } onClick={ () => handleHeaderIcon('timeline') } />
          <div />
          <AddUser color={ ahaTheme.colors.text.white } onClick={ () => handleHeaderIcon('addUser') } />
          <Notification color={ ahaTheme.colors.text.white } onClick={ () => handleHeaderIcon('notification') } />
        </FloatingHeaderIcons>
        <StyledLogo size={ SVG_LOGO_HEADER_SIZE } color={ ahaTheme.colors.text.white } />
        <HeaderText>
          <h1>{ name }</h1>
          <HeaderSubTitle>{ email }</HeaderSubTitle>
        </HeaderText>
      </Header>
      <Main>
        <section>
          <DashboardStatus>
            <h3>Application Status</h3>
            <p>{ status }</p>
          </DashboardStatus>
          <DashboardContent>
            <DashboardLabelSection>
              <Flight color={ withOpacity(ahaTheme.colors.text.white, 0.25) } size={ 30 } />
              <h2>Flying</h2>
              <p>Job Category</p>
            </DashboardLabelSection>
            <DashboardLabelSection>
              <Tags color={ withOpacity(ahaTheme.colors.text.white, 0.25) } size={ 30 } />
              <h2>Active Duty</h2>
              <p>Component</p>
            </DashboardLabelSection>
            <DashboardPrimarySection>
              <ProgressInnerWrapper>
                <h2>Application Progress</h2>
                <p>13% Complete</p>
                <ProgressBar size={ ahaTheme.spacing.s } color={ ahaTheme.colors.system.pink } value={ 13 } />
              </ProgressInnerWrapper>
              <ChevronRight color={ ahaTheme.colors.text.white } />
            </DashboardPrimarySection>
            <FinishPrimarySection>
              <FinishInnerWrapper>
                <LineOfficerProgressCircle size={ ahaTheme.iconSizes.m } percentage={ currentStep / mockSteps.length } style={ { gridTemplateRows: 'span 2' } } />
                <div>
                  <h2>Finish your initial application</h2>
                  <p>{ `${currentStep} of ${mockSteps.length} Tasks Completed` }</p>
                </div>
              </FinishInnerWrapper>
              <TinyButton dark>Continue</TinyButton>
            </FinishPrimarySection>
            <h2>Current Task</h2>
            <DashboardPrimarySection style={ { gridTemplateColumns: 'auto 1fr auto' } }>
              <UserBadge color={ withOpacity(ahaTheme.colors.text.white, 0.25) } />
              <CurrentTaskInnerWrapper>
                <h2>Contact Information</h2>
                <Timer color={ ahaTheme.colors.text.white } />
                <p>{ `${minutes} minute` }</p>
              </CurrentTaskInnerWrapper>
              <TinyButton dark>Start</TinyButton>
            </DashboardPrimarySection>
          </DashboardContent>
        </section>
      </Main>
    </ThemeProvider>
  )
}

export default withRouter(LineOfficerDashboard)
