import { useMutation } from 'react-query'
import { afreServiceUrl } from '../../api/afre.service'
import axios from 'axios'

export const useSaveLoaLeadRequest = () => {
  return useMutation(['saveLoaLeadRequest'], async formData => {
    const response = await axios.post(`${afreServiceUrl}/oa/loaLead`, formData)
    return response.data
  })
}
