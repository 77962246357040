import React from 'react'
import Footer from './Footer'
import { NAV_ROUTES } from '../../../navigation/routes'
import { useTheme } from '@emotion/react'
import { css } from '@emotion/css'
import { Link } from 'react-router-dom'

const PageFooter = () => {
  const theme = useTheme()

  const defaultLinkClass = css`
    color: ${theme.text.contrast};
    transition: all ${theme.transition.default} ease-in-out;
    text-decoration: underline;

    &:hover {
      color: ${theme.text.subtle};
    }
  `

  const pageFooterClass = css`
    margin-top: auto;
    background-color: ${theme.background.level0};
    padding: ${theme.spacing.l}px 5%;
  `

  return (
    <Footer className={ pageFooterClass }>
      <Link
        className={ defaultLinkClass }
        to={ NAV_ROUTES.PRIVACY_POLICY_PAGE }
        data-testid='Privacy Policy | Terms and Conditions'
        rel='noopener noreferrer'
        target='_blank'>
        Privacy Policy | Terms and Conditions
      </Link>
    </Footer>
  )
}

export default PageFooter
