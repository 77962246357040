import color from 'color'

export const withOpacity = (baseColor, opacity) => {
  return color(baseColor)
    .alpha(opacity)
    .string()
}

const colors = {
  black: {
    default: '#232323',
    absolute: '#000000',
  },

  white: {
    default: '#ffffff',
  },

  gray: {
    extraDark: '#2E3E4E',
    dark: '#36495C',
    mid: '#99A4AE',
    light: '#D8D8D8',
    extraLight: '#ECF0F1',
  },

  green: {
    default: '#42CA77',
    mid: '#07DF1D',
    light: 'rgba(20, 210, 110, .6)',
  },

  blue: {
    default: '#409AD6',
  },

  red: {
    default: '#E34E47',
  },

  yellow: {
    default: '#EFC242',
  },

  page: {
    background: '#2C3E50',
    text: '#74808C',
  },

  label: '#232323',

  input: {
    default: '#232323',
    disabled: 'rgba(35,35,35,.5)',
    focus: '#FFF',
    background: '#FFF',
    placeholder: 'rgba(35,35,35,.6)',
  },

  button: {
    default: {
      color: '#FFF',
      background: '#2C465D',
      border: '#3498DB',
      hover: {
        background: '#3498DB',
      },
    },
  },
}

export default colors
