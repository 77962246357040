import { ThemeProvider } from '@emotion/react'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SvgAirForce from '../../icons/AirForceLogo'
import SvgSpaceForce from '../../icons/SpaceForceLogo'
import { NAV_ROUTES } from '../../navigation/routes'
import { logPageView } from '../../redux/sagas/amplitudeAnalytics.sagas'
import { DarkPalette } from '../../theme'
import {
  Container,
  Header,
  SectionBody,
  SubTitle,
  Wrapper,
  LogoWrapper
} from './starr.page.styles'

const StarrCitation = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(logPageView.request(NAV_ROUTES.STARR_PAGE))
  }, [dispatch])

  return (
    <ThemeProvider theme={ DarkPalette }>
      <Container>
        <Wrapper>
          <Header>
            <LogoWrapper>
              <SvgAirForce size={ 120 } color='#FFF' />
              <a href='https://www.airforce.com'>www.airforce.com</a>
            </LogoWrapper>
            <LogoWrapper>
              <SvgSpaceForce size={ 120 } color='#FFF' />
              <a href='https://www.spaceforce.com'>www.spaceforce.com</a>
            </LogoWrapper>
          </Header>
          <SubTitle data-testid='sub-title'>Citation to accompany the award of Air and Space achievement medal</SubTitle>
          <SectionBody data-testid='section-body'>
            To <u>Rank and Name</u>,<p />
            <u>Rank Full Name</u> distinguished <u>himself/herself/themselves</u> by outstanding achievement supporting Air Force Recruiting Service
            at <u>Base, State</u> from <u>Day Month Year</u> to <u>Day Month Year</u>.<p />
            <u>Rank Name</u> selflessly promoted the Department of the Air Force with community outreach in a highly pivotal and exemplary
            manner. <u>He/She/They</u> demonstrated superb salesmanship and engagement technique that resulted in successfully referring three highly
            driven and qualified enlistees. This contribution enhanced our nation’s security and sets a remarkable production pace for strengthening
            our military and achievement in numerous command goals. <u>Rank and Name</u>’s willingness, exceptional mentorship and loyal devotion to
            duty proves true leadership. <u>Rank Name</u>’s phenomenal professionalism, unrelenting perseverance and loyal devotion to duty reflects
            credit upon <u>Himself/Herself/Themselves</u> and were in keeping with the highest traditions.<p />
            The distinctive accomplishment of <u>Rank Name</u> reflects credit upon <u>himself/herself/themselves</u> and the United States Air Force.
          </SectionBody>
        </Wrapper>
      </Container>
    </ThemeProvider>
  )
}

export default withRouter(StarrCitation)
