import React from 'react'
import { css, cx } from '@emotion/css'

const DEFAULT_CSS_CLASS = css`
display: grid;
grid-template-columns: repeat(6, 1fr);
`

export const SixColumnGrid = ({ children, className, style, ...props }) => {
  const classNames = cx(DEFAULT_CSS_CLASS, className, css(style))
  return <div className={ classNames } { ...props }>{ children }</div>
}
