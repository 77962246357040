import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { Button, Form, Modal } from 'semantic-ui-react'
import InputField from '../../components/Forms/InputField'
import { MAX_LENGTH } from '../../redux/constants/form.values.constants'
import { updateSquadron } from '../../redux/sagas/squadron.sagas'
import { required } from '../validation/index'
import { SubmitButtonWrapper } from './squadron.form.styles'

class SquadronUpdateForm extends Component {

  state = { isEditModalOpen: false }

  open = () => this.setState({ isEditModalOpen: true })
  close = () => this.setState({ isEditModalOpen: false })

  handleSquadronSubmit = values => {
    const updatedSquadron = { ...this.props.squadron, name: values.name, userId: values.userId }
    this.props.updateSquadron(updatedSquadron)
    this.close()
  }

  renderModal = () => {
    const { squadron, handleSubmit } = this.props
    return (
      <Modal open={ this.state.isEditModalOpen } closeIcon onClose={ () => this.close() }>
        <Modal.Header data-testid='edit-squadron-header'>Edit Squadron { squadron.name }</Modal.Header>
        <Modal.Content>
          <Form onSubmit={ handleSubmit(this.handleSquadronSubmit) }>
            <Field
              id='name'
              name='name'
              label='Squadron Name'
              component={ InputField }
              validate={ required }
              maxLength={ MAX_LENGTH.STANDARD }
            />
            <SubmitButtonWrapper >
              <Button primary type='submit' data-testid='update-squadron-button'>Update Squadron</Button>
            </SubmitButtonWrapper >
          </Form>
        </Modal.Content>
      </Modal>
    )
  }

  render() {
    return (
      <>
        { this.renderModal() }
        <Button onClick={ () => this.open() }>Edit</Button>
      </>
    )
  }
}

SquadronUpdateForm.propTypes = {
  handleSubmit: PropTypes.func,
  updateSquadron: PropTypes.func,
  squadron: PropTypes.object,
}

const mapStateToProps = (_state, ownProps) => {
  return {
    initialValues: {
      name: ownProps.squadron.name,
      userId: ownProps.squadron.userId,
    },
  }
}

const mapActionsToProps = dispatch => {
  return {
    updateSquadron: params => dispatch(updateSquadron.request(params)),
  }
}

const Formed = reduxForm({
  enableReinitialize: true,
})(SquadronUpdateForm)
const ConnectedSquadronUpdateForm = connect(mapStateToProps, mapActionsToProps)(Formed)

export default withRouter(ConnectedSquadronUpdateForm)
