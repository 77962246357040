import React from 'react'
import PropTypes from 'prop-types'
import { LeadDivider } from '../../pages/profile/profile.page.styles'
import { FormDescription, FormSectionWrapper, FormHeading, ProfileLeadFormHeader } from '../form.styles'

ReferredByFormHeader.propTypes = {
  referrerFirstName: PropTypes.string,
  referrerLastName: PropTypes.string,
}

function ReferredByFormHeader({ referrerFirstName, referrerLastName }) {
  let description = 'Tell us about yourself and we\'ll connect you with a recruiter to learn more.'
  if (referrerFirstName && referrerLastName) {
    description = `${referrerFirstName} ${referrerLastName} has invited you to connect with a recruiter to learn more.`
  }

  return (
    <ProfileLeadFormHeader>
      <FormSectionWrapper>
        <FormHeading>Tell us more about yourself</FormHeading>
        <FormDescription>{ description }</FormDescription>
        <LeadDivider />
      </FormSectionWrapper>
    </ProfileLeadFormHeader>
  )
}

export default ReferredByFormHeader
