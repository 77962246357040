import React from 'react'
import { css, cx } from '@emotion/css'

export const TwoColumnGrid = ({ children, className, style, ...props }) => {
  const defaultClassName = css`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `
  const twoColumnGridClassNames = cx(defaultClassName, className, css(style))
  return <div className={ twoColumnGridClassNames } { ...props }>{ children }</div>
}
