import PropTypes from 'prop-types'
import * as React from 'react'

const SvgUnitManagement = props => (
  <svg
    id="UnitManagement_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    height={ props.size }
    width={ props.size }
    viewBox="0 0 32 32"
    style={ {
      enableBackground: 'new 0 0 32 32',
    } }
    xmlSpace="preserve"
    role="img"
    { ...props }>
    <style>
      {
        '.UnitManagement_svg__st0{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round}'
      }
    </style>
    <path className="UnitManagement_svg__st0" d="M.9 11.4 16 3.3l15.1 8.1" />
    <circle className="UnitManagement_svg__st0" cx={ 25.1 } cy={ 18.4 } r={ 3 } />
    <circle className="UnitManagement_svg__st0" cx={ 6.9 } cy={ 18.4 } r={ 3 } />
    <path
      className="UnitManagement_svg__st0"
      // eslint-disable-next-line max-len
      d="M13 28.5v-1c0-.7-.4-1.4-1-1.7-1-.6-2.7-1.3-5.1-1.3-2.4 0-4.1.7-5.1 1.3-.6.4-1 1-1 1.7v1H13zM31.1 28.5v-1c0-.7-.4-1.4-1-1.7-1-.6-2.7-1.3-5.1-1.3-2.4 0-4.1.7-5.1 1.3-.6.4-1 1-1 1.7v1h12.2z"
    />
  </svg>
)
export default SvgUnitManagement

SvgUnitManagement.propTypes = {
  size: PropTypes.number,
}
