import * as React from 'react';

function Timeline({ size = 24, color, ...rest }) {
  return (
    <svg width={ size } height={ size } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M12.5 2.5V22.5" stroke={ color } stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M21.5 4.5H18.5C17.9477 4.5 17.5 4.94772 17.5 5.5V8.5C17.5 9.05228 17.9477 9.5 18.5 9.5H21.5C22.0523 9.5 22.5 9.05228 22.5 8.5V5.5C22.5 4.94772 22.0523 4.5 21.5 4.5Z" stroke={ color } stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M14.5 7.5H12.5" stroke={ color } stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M21.5 15.5H18.5C17.9477 15.5 17.5 15.9477 17.5 16.5V19.5C17.5 20.0523 17.9477 20.5 18.5 20.5H21.5C22.0523 20.5 22.5 20.0523 22.5 19.5V16.5C22.5 15.9477 22.0523 15.5 21.5 15.5Z" stroke={ color } stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M14.5 17.5H12.5" stroke={ color } stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M6.5 9.5H3.5C2.94772 9.5 2.5 9.94772 2.5 10.5V13.5C2.5 14.0523 2.94772 14.5 3.5 14.5H6.5C7.05228 14.5 7.5 14.0523 7.5 13.5V10.5C7.5 9.94772 7.05228 9.5 6.5 9.5Z" stroke={ color } stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M10.5 12.5H12.5" stroke={ color } stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
    </svg>
  );
}

export default Timeline;
