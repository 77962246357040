import React from 'react'
import { Form, FormSelect, FormTextField, Button, FormRadio } from '@aimhigh/web-components/web'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { fourColStyle, IconLabelWrapper, RadioError, RadioWrapper, StyledInfoIcon } from '../../LineOfficer.styles'
import { theme as ahaTheme } from '@aimhigh/theme'
import { HELP_ICON_SIZE, yesNoOptions } from '../../lineOfficer.constants'

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  column-gap: ${({ theme }) => theme.spacing.m}px;
  row-gap: ${({ theme }) => theme.spacing.xl}px;

  > * {
    grid-column: span 2;
  }
`

const PhysicalHeader = styled.h3`
  font-family: ${({ theme }) => theme.fontFamilies.openSans};
  font-size: ${({ theme }) => theme.fontSizes.xm}px;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.text.white};

  margin: 0;
  margin-bottom: -${({ theme }) => theme.spacing.xl}px;
`

const EligibilityForm = ({ onSubmit }) => {
  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
    },
  })

  const errors = formHooks.formState.errors

  const handleFormSubmit = values => {
    // eslint-disable-next-line no-alert
    alert(
      `--form values--\n${Object.keys(values)
        .map(key => {
          const value = values[key]
          return `${key}: ${
            typeof value === 'object' ? JSON.stringify(value) : value
          }`
        })
        .join('\n')}`
    )

    onSubmit()
  }

  return (
    <StyledForm formHooks={ formHooks } onSubmit={ handleFormSubmit }>
      <FormSelect
        label={
          <IconLabelWrapper>
            Are you a US Citizen? <StyledInfoIcon color={ ahaTheme.colors.system.actionLight } size={ HELP_ICON_SIZE } />
          </IconLabelWrapper>
        }
        name='prefix'
        options={ yesNoOptions }
        style={ fourColStyle }
        dark
      />

      <RadioWrapper $isError={ errors?.objector } style={ fourColStyle }>
        <p>
          Are you a Conscientious Objector? <StyledInfoIcon color={ ahaTheme.colors.system.actionLight } size={ HELP_ICON_SIZE } />
        </p>
        <FormRadio label='Yes' name='objector' value={ 1 } dark />
        <FormRadio label='No' name='objector' value={ 0 } dark />
        { errors?.objector && <RadioError>{ errors.objector.message }</RadioError> }
      </RadioWrapper>

      <RadioWrapper $isError={ errors?.objections } style={ fourColStyle }>
        <p>
          Do you have Moral Objections to Military Service? <StyledInfoIcon color={ ahaTheme.colors.system.actionLight } size={ HELP_ICON_SIZE } />
        </p>
        <FormRadio label='Yes' name='objections' value={ 1 } dark />
        <FormRadio label='No' name='objections' value={ 0 } dark />
        { errors?.objections && <RadioError>{ errors.objections.message }</RadioError> }
      </RadioWrapper>

      <FormTextField label='If Yes, please specify' name='objections_specify' style={ fourColStyle } dark />

      <PhysicalHeader style={ fourColStyle }>Physical Remarks</PhysicalHeader>
      <FormSelect label='Height (Ft)' name='height_ft' options={ [] } dark />
      <FormSelect label='Height (In)' name='height_in' options={ [] } dark />
      <FormSelect label='Weight (lbs)' name='weight' options={ [] } dark />

      <RadioWrapper $isError={ errors?.objections } style={ fourColStyle }>
        <p>
          Have you ever used, possessed, sold, or transported any illegal drugs?
          <StyledInfoIcon color={ ahaTheme.colors.system.actionLight } size={ HELP_ICON_SIZE } />
        </p>
        <FormRadio label='Yes' name='drugs' value={ 1 } dark />
        <FormRadio label='No' name='drugs' value={ 0 } dark />
        { errors?.objections && <RadioError>{ errors.objections.message }</RadioError> }
      </RadioWrapper>

      <FormTextField label='If Yes, please specify' name='drugs_specify' style={ fourColStyle } dark />

      <RadioWrapper $isError={ errors?.objections } style={ fourColStyle }>
        <p>
          Do you have any tattoos or gauged ears? <StyledInfoIcon color={ ahaTheme.colors.system.actionLight } size={ HELP_ICON_SIZE } />
        </p>
        <FormRadio label='Yes' name='tattoos' value={ 1 } dark />
        <FormRadio label='No' name='tattoos' value={ 0 } dark />
        { errors?.objections && <RadioError>{ errors.objections.message }</RadioError> }
      </RadioWrapper>

      <FormTextField label='If Yes, please specify' name='tattoos_specify' style={ fourColStyle } dark />

      <Button type='submit' style={ fourColStyle } dark>Next</Button>
    </StyledForm>
  )
}

export default EligibilityForm
