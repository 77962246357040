import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormSection, getFormSyncErrors, hasSubmitFailed, reduxForm } from 'redux-form'
import { Checkbox } from 'semantic-ui-react'
import { AimHighButton } from '../../components/Button/Button'
import ThankYouCard from '../../components/ThankYouCard/ThankYouCard'
import {
  Disclaimer,
  FormDescription,
  FormSectionWrapper,
  FormTitle,
  LeadSubmitButtonsContainer,
  Loader,
  LoaderWrapper,
  ProfileLeadFormHeader,
  RequiredInfo,
  VerifyContainer,
  VerifyText
} from '../../forms/form.styles'
import GeneralInfoSection from '../../forms/referral/GeneralInfoSection'
import { SELF_FILLED_REFERRAL_CERTIFICATION_TEXT } from '../../forms/referral/referral.constants'
import ValidationError, { ServerError } from '../../forms/ValidationError'
import { createAfrissLeadSaga } from '../../redux/sagas/referral.sagas'
import { selectCreateLeadError, selectLeadSubmitting } from '../../redux/selectors/referral.selectors'
import { ProfileFormWrapper } from './profile.page.styles'

LeadForm.propTypes = {
  recruiterId: PropTypes.string,
  recruiterComponent: PropTypes.string,
  recruiterLeadType: PropTypes.string,
  qrSort: PropTypes.string,
  leadId: PropTypes.string,
  afrissEventId: PropTypes.string,
  handleSubmit: PropTypes.func,
}

function LeadForm(props) {
  const dispatch = useDispatch()
  const [piiVerified, setPiiVerified] = useState(false)

  const {
    handleSubmit,
    recruiterId,
    leadId,
    afrissEventId,
    qrSort,
    recruiterComponent,
    recruiterLeadType,
  } = props
  const createAfrissLeadSuccess = useSelector(state => state.referral.createAfrissLeadSuccess)
  const submitFailed = useSelector(hasSubmitFailed('referral'))
  const createLeadError = useSelector(selectCreateLeadError)
  const formSyncErrors = useSelector(getFormSyncErrors('referral'))
  const leadSubmitting = useSelector(selectLeadSubmitting)

  const handleReferralSubmit = values => {
    values = { ...values, recruiterId, leadId, afrissEventId, fromRecruiterProfilePage: true, qrSort, recruiterComponent, recruiterLeadType }
    dispatch(createAfrissLeadSaga.request(values))
  }

  const renderSubmissionContainer = () => {
    const isDisabled = leadSubmitting || !piiVerified
    return createAfrissLeadSuccess ? null : (
      <LeadSubmitButtonsContainer
        data-testid='referralFormSubmitButtonsContainer'>
        { !!leadSubmitting && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) }

        <VerifyContainer>
          <Checkbox
            data-testid='leadform-verifycheckbox'
            onClick={ () => setPiiVerified(!piiVerified) }
          />
          <VerifyText data-testid='lead-form-verification-text'>{ SELF_FILLED_REFERRAL_CERTIFICATION_TEXT }</VerifyText>
        </VerifyContainer>
        <AimHighButton
          data-testid='leadform-submitbutton'
          disabled={ isDisabled }
          fluid
          primary
          size='large'
          type='submit'
          name='Submit'>
          Submit & Connect
        </AimHighButton>
        <Disclaimer data-testid='lead-form-disclaimer'>
          By submitting this form, you are allowing
          <br /> the U.S. Air Force to contact you.
        </Disclaimer>
        { formSyncErrors && submitFailed && (
          <ValidationError
            data-testid='validationError'
            validationErrors={ formSyncErrors }
          />
        ) }
      </LeadSubmitButtonsContainer>
    )
  }

  const renderForm = () => (
    <form onSubmit={ handleSubmit(handleReferralSubmit) }>
      <ProfileLeadFormHeader>
        <FormSectionWrapper>
          <FormTitle data-testid='form-title'>Connect to learn more</FormTitle>
          <FormDescription data-testid='form-description'>{ `Share a bit about yourself and I'll reach out as soon as possible.` }</FormDescription>
          <RequiredInfo data-testid='required-information'>*Required Information</RequiredInfo>
        </FormSectionWrapper>
        { createLeadError && <ServerError error={ createLeadError } /> }
      </ProfileLeadFormHeader>
      <FormSectionWrapper>
        <FormSection name='generalInfo'>
          <GeneralInfoSection />
        </FormSection>
      </FormSectionWrapper>
      { renderSubmissionContainer() }
    </form>
  )

  return (
    <ProfileFormWrapper>
      { createAfrissLeadSuccess ? (<ThankYouCard />) : renderForm() }
    </ProfileFormWrapper>
  )
}

export default reduxForm({
  form: 'referral',
  destroyOnUnmount: false,
})(LeadForm)
