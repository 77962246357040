import * as React from 'react';

function Alert(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width={ props.size } height={ props.size } { ...props }>
      <path fill="none" d="M0 0h32v32H0z" />
      <path
        // eslint-disable-next-line max-len
        d="M31.87 27.5l-15-26a1 1 0 00-1.45-.29 1.14 1.14 0 00-.29.29l-15 26a1 1 0 00.37 1.37A1.06 1.06 0 001 29h30a1 1 0 001-1 1.06 1.06 0 00-.13-.5zM17.71 10L17 20h-2l-.71-10zM16 26a1.78 1.78 0 111.78-1.78A1.78 1.78 0 0116 26z"
        fill={ props.color }
      />
    </svg>
  );
}

export default Alert;
