export const PALETTE_IDS = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
}

export const LightPalette = {
  id: PALETTE_IDS.LIGHT,
  text: {
    contrast: 'rgba(027, 038, 050, 1.00)',
    utility: 'rgba(255, 255, 255, 1.00)',
    default: 'rgba(027, 038, 050, 0.80)',
    subtle: 'rgba(027, 038, 050, 0.65)',
    nonessential: 'rgba(027, 038, 050, 0.50)',
    placeholder: 'rgba(027, 038, 050, 0.45)',
    action: 'rgba(10, 120, 180, 1.00)',
    positive: 'rgba(012, 136, 074, 1.00)',
    warning: 'rgba(138, 117, 015, 1.00)',
    negative: 'rgba(206, 054, 023, 1.00)',
  },

  background: {
    level0: 'rgba(255, 255, 255, 1.00)',
    highlight: 'rgba(244, 244, 244, 1.00)',
    level1: 'rgba(232, 233, 234, 1.00)',
    level2: 'rgba(209,212, 214, 1.00)',
    level3: 'rgba(153, 159, 164, 1.00)',
    cover: 'rgba(255, 255, 255, 0.90)',
    action: 'rgba(10, 120, 180, 1.00)',
    actionLight: 'rgba(009, 123, 179, 0.20)',
    actionBright: 'rgba(84,199,252, 1.00)',
    positive: 'rgba(012, 136, 074, 1.00)',
    warning: 'rgba(138, 117, 015, 1.00)',
    negative: 'rgba(206, 054, 023, 1.00)',
    accent: 'rgba(027, 038, 050, 0.10)',
  },
  brand: {
    brand: 'rgba(027, 038, 050, 1.00)',
  },
  canvas: {
    primary: 'rgba(255, 255, 255, 1.00)',
    secondary: 'rgba(235, 235, 235, 1.00)',
    tertiary: 'rgba(200, 200, 200, 1.00)',
  },
  content: {
    contrast: 'rgba(27, 38, 50, 1.00)',
    default: 'rgba(27, 38, 50, 0.80)',
    subtle: 'rgba(27, 38, 50, 0.65)',
    nonessential: 'rgba(27, 38, 50, 0.5)',
  },
  system: {
    action: 'rgba(10, 120, 180, 1.00)',
    positive: 'rgba(0, 134, 69, 1.00)',
    warning: 'rgba(124, 109, 39, 1.00)',
    error: 'rgba(206, 54, 23, 1.00)',
  },
  buttonText: {
    primary: 'rgba(255, 255, 255, 1.00)',
    secondary: 'rgba(10, 120, 180, 1.00)',
    tertiary: 'rgba(10, 120, 180, 1.00)',
    contrast: 'rgba(27, 38, 50, 1.00)',
    default: 'rgba(27, 38, 50, 0.80)',
    subtle: 'rgba(27, 38, 50, 0.65)',
    nonessential: 'rgba(27, 38, 50, 0.5)',
  },
}

export const DarkPalette = {
  id: PALETTE_IDS.DARK,
  text: {
    contrast: 'rgba(255, 255, 255, 1.00)',
    utility: 'rgba(255, 255, 255, 1.00)',
    default: 'rgba(238, 242, 247, 0.80)',
    subtle: 'rgba(238, 242, 247, 0.65)',
    nonessential: 'rgba(238, 242, 247, 0.40)',
    ghost: 'rgba(238, 242, 247, 0.10)',
    placeholder: 'rgba(027, 038, 050, 0.45)',
    action: 'rgba(015, 175, 250, 1.00)',
    positive: 'rgba(019, 210, 115, 1.00)',
    warning: 'rgba(230, 192, 000, 1.00)',
    negative: 'rgba(234, 099, 072, 1.00)',
    menu: 'rgba(070, 080, 090, 1)',
  },

  background: {
    level0: 'rgba(027, 038, 050, 1.00)',
    level1: 'rgba(038, 049, 060, 1.00)',
    level2: 'rgba(050, 060, 070, 1.00)',
    level3: 'rgba(060, 070, 080, 1.00)',
    cover: 'rgba(027, 038, 050, 0.90)',
    action: 'rgba(015, 175, 250, 1.00)',
    positive: 'rgba(019, 210, 115, 1.00)',
    warning: 'rgba(230, 192, 000, 1.00)',
    negative: 'rgba(234, 099, 072, 1.00)',
    accent: 'rgba(238, 242, 247, 0.10)',
    overlay: 'rgba(0, 0, 0, 0.75)',
    tabBar: 'rgba(200, 200, 200, 1.00)',
  },
  brand: {
    brand: 'rgba(027, 038, 050, 1.00)',
  },
  canvas: {
    primary: 'rgba(255, 255, 255, 1.00)',
    secondary: 'rgba(90, 100, 110, 1.00)',
    tertiary: 'rgba(70, 80, 90, 1.00)',
  },
  content: {
    contrast: 'rgba(255, 255, 255, 1.00)',
    default: 'rgba(255, 255, 255, 0.80)',
    subtle: 'rgba(255, 255, 255, 0.65)',
    nonessential: 'rgba(255, 255, 255, 0.5)',
  },
  system: {
    action: 'rgba(015, 175, 250, 1.00)',
    positive: 'rgba(020, 210, 110, 1.00)',
    warning: 'rgba(225, 190, 0, 1.00)',
    error: 'rgba(216, 110, 71, 1.00)',
  },
  buttonText: {
    primary: 'rgba(027, 038, 050, 1.00)',
    secondary: 'rgba(255, 255, 255, 0.8)',
    tertiary: 'rgba(255, 255, 255, 0.8)',
    contrast: 'rgba(255, 255, 255, 1.00)',
    default: 'rgba(255, 255, 255, 0.80)',
    subtle: 'rgba(255, 255, 255, 0.65)',
    nonessential: 'rgba(255, 255, 255, 0.5)',
  },
}
