import { ThemeProvider } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { Dimmer, Grid, Icon, Input, Loader, Modal, Table } from 'semantic-ui-react'
import HttpService from '../../api/HttpService'
import config from '../../config'
import { NAV_ROUTES } from '../../navigation/routes'
import { selectAuthUserToken } from '../../redux/selectors/auth.selectors'
import { LightPalette } from '../../theme'
import spacing from '../../theme/spacing'
import { GainUnitModal } from '../components/Modals/GainUnitModal'
import { OpaPageHeader } from '../components/PageTitle/OpaPageHeader'
import {
  ActionButton,
  CancelButton,
  DangerButton,
  NegativeIcon,
  ScrollablePage,
  StickyTableHeader,
  TableHeaderCell,
  TableItem
} from './Opa.styles'

const afreServiceUrl = config.apiUrl

const COLUMNS = {
  name: 'name',
}

const SORTED_TYPES = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
}

const isSortedByColumn = (sortedByColumn, column, sortType) => {
  return sortedByColumn === column ? sortType : null
}

const resortGainUnitList = (list, sortColumn, sortedDescending) => {
  return [...list].sort((a, b) => {
    const obj1 = sortedDescending ? a : b
    const obj2 = sortedDescending ? b : a
    if (obj1[sortColumn] > obj2[sortColumn]) {
      return -1
    }
    if (obj2[sortColumn] > obj1[sortColumn]) {
      return 1
    }

    return 0
  })
}

export const GainUnitManagementPage = () => {

  const token = useSelector(selectAuthUserToken)

  const gainUnitQueryKey = ['gainUnits']
  const gainUnitQuery = useQuery(gainUnitQueryKey, async () => {
    const response = await HttpService.get(`${afreServiceUrl}/opa/templates/units`, {}, token).next().value
    return response.data.data
  })
  const queryClient = useQueryClient()
  const deleteUnitMutation = useMutation(async unitId => {
    return await HttpService.delete(`${afreServiceUrl}/opa/templates/units/${unitId}`, {}, token).next().value
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(gainUnitQueryKey)
    },
  })

  const [gainUnitList, setGainUnitList] = useState([])
  const [selectedUnit, setSelectedUnit] = useState(null)
  const [gainUnitModalOpen, setGainUnitModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [nameSearchValue, setNameSearchValue] = useState('')
  const [sortedByColumn, setSortedByColumn] = useState(COLUMNS.name)
  const [sortedDescending, setSortedDescending] = useState(false)

  const showSpinner = gainUnitQuery.isFetching || deleteUnitMutation.isLoading

  const deleteGainUnitHandler = unit => {
    setSelectedUnit(unit)
    setDeleteModalOpen(true)
  }

  const editGainUnitHandler = unit => {
    setSelectedUnit(unit)
    setGainUnitModalOpen(true)
  }

  useEffect(() => {
    if (gainUnitQuery.data) {
      const filteredValues = gainUnitQuery.data.filter(gainUnit => gainUnit.name.toUpperCase().includes(nameSearchValue))
      setGainUnitList(resortGainUnitList(filteredValues, sortedByColumn, sortedDescending))
    }
  }, [nameSearchValue, gainUnitQuery.data, sortedDescending, sortedByColumn])

  const sortType = sortedDescending ? SORTED_TYPES.DESCENDING : SORTED_TYPES.ASCENDING

  const handleSearch = (_e, data) => {
    const searchValue = data.value.toUpperCase()
    setNameSearchValue(searchValue)
  }

  const changeSortType = (currentColumn, newColumn) => {
    if (currentColumn === newColumn) {
      setSortedDescending(!sortedDescending)
    } else {
      setSortedByColumn(newColumn)
    }
  }

  const headerControls = [
    <ActionButton
      key='addUnitAction'
      data-testid='add-unit-button'
      width={ 2 }
      onClick={ () => {
        setSelectedUnit(null)
        setGainUnitModalOpen(true)
      }
      }>
      Add Unit
    </ActionButton>,
    <Input
      key='searchUnitInput'
      data-testid='search-field'
      width={ 3 }
      icon='search'
      placeholder='Search...'
      onChange={ handleSearch }
    />,
  ]

  return (
    <>
      <ThemeProvider theme={ LightPalette }>
        <GainUnitModal
          isOpen={ gainUnitModalOpen }
          setOpen={ setGainUnitModalOpen }
          gainUnitQueryKey={ gainUnitQueryKey }
          existingGainUnit={ selectedUnit }
        />
        {
          selectedUnit &&
            <Modal
              open={ deleteModalOpen }>
              <Modal.Header data-testid='confirm-delete-header'>Confirm Delete</Modal.Header>
              <Modal.Content data-testid='confirm-delete-text'>
                <p>Are you sure you want to delete { selectedUnit.name }?</p>
              </Modal.Content>
              <Modal.Actions>
                <CancelButton onClick={ () => {
                  setDeleteModalOpen(false)
                  setSelectedUnit(null)
                } }>
                  Cancel
                </CancelButton>
                <DangerButton
                  content='Delete'
                  icon='trash alternate outline'
                  data-testid='danger-button'
                  onClick={ () => {
                    deleteUnitMutation.mutate(selectedUnit.sort)
                    setDeleteModalOpen(false)
                    setSelectedUnit(null)
                  } }
                />
              </Modal.Actions>
            </Modal>
        }
        <ScrollablePage>
          <Dimmer.Dimmable dimmed={ showSpinner }>
            <Dimmer active={ showSpinner } page>
              <Loader>Loading</Loader>
            </Dimmer>
            <div style={ { margin: spacing.m } }>
              <OpaPageHeader
                pageRoute={ NAV_ROUTES.UNIT_MANAGEMENT_PAGE }
                pageTitle='Unit Management'
                headerComponents={ headerControls }
              />
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    { gainUnitList && gainUnitList.length > 0 ? (
                      <Table celled striped sortable style={ { width: '65%' } }>
                        <StickyTableHeader data-testid='table-header'>
                          <Table.Row data-testid='table-row'>
                            <TableHeaderCell
                              data-testid='name-table-header-cell'
                              sorted={ isSortedByColumn(sortedByColumn, COLUMNS.name, sortType) }
                              onClick={ () => changeSortType(COLUMNS.name, sortedByColumn) }>
                              Name
                            </TableHeaderCell>
                            <TableHeaderCell data-testid='actions-table-header-cell'>Actions</TableHeaderCell>
                          </Table.Row>
                        </StickyTableHeader>
                        <Table.Body data-testid='table-body'>
                          {
                            gainUnitList.map(gainUnit => (
                              <Table.Row key={ gainUnit.sort } data-testid='unit-row'>
                                <Table.Cell data-testid='gain-unit-table-cell'>{ gainUnit.name }</Table.Cell>
                                <Table.Cell data-testid='actions-table-cell'>
                                  <TableItem>
                                    <Icon
                                      link
                                      title='Edit Gain Unit'
                                      name='pencil'
                                      size='large'
                                      onClick={ () => editGainUnitHandler(gainUnit) }
                                    />
                                    <NegativeIcon
                                      link
                                      title='Delete Gain Unit'
                                      name='trash alternate outline'
                                      size='large'
                                      onClick={ () => deleteGainUnitHandler(gainUnit) }
                                    />
                                  </TableItem>
                                </Table.Cell>
                              </Table.Row>
                            ))
                          }
                        </Table.Body>
                      </Table>
                    ) : (
                      <p data-testid='no-results-found-text'>No Results Found</p>
                    ) }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </Dimmer.Dimmable>
        </ScrollablePage>
      </ThemeProvider>
    </>
  )
}
