import PropTypes from 'prop-types'
import React from 'react'
import { ContentErrorMessage } from '../../../components/Message/Message'
import { NAV_ROUTES } from '../../../navigation/routes'
import spacing from '../../../theme/spacing'
import { DangerButton } from '../../pages/Opa.styles'

const OpaAfscErrorMessage = props => {
  return (
    <div style={ { marginLeft: `${spacing.xs}px`, maxWidth: `1000px` } }>
      <ContentErrorMessage
        onDismiss={ () => props.afscErrorsSetter(false) }
        header='AFSC(s) Not Found'
        content={ (
          <div>
            <p>
              This week group contains an AFSC that does
              not exist in our system. If this AFSC is valid and should be recognized,
              please navigate to the AFSC Management page to add it.
              If this AFSC is incorrect, please use the &quot;Edit Row&quot;
              feature to update the PAFSC and/or CAFSC.
            </p>
            <DangerButton
              href={ NAV_ROUTES.AFSC_MANAGEMENT_PAGE }
              content='Add New AFSC'
            />
          </div>
        ) }
      />
    </div>
  )
}

OpaAfscErrorMessage.propTypes = {
  afscErrorsSetter: PropTypes.func,
}

export default OpaAfscErrorMessage
