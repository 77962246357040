import React from 'react'
import PropTypes from 'prop-types'
import { ProfileCard, ProfileSectionTitle } from './profile.page.styles'

About.propTypes = {
  websiteOverview: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

function About({ websiteOverview, title }) {
  if (websiteOverview === '') {
    return null
  }
  return (
    <ProfileCard data-testid='about-user'>
      <ProfileSectionTitle>{ `About ${title}` }</ProfileSectionTitle>
      { websiteOverview }
    </ProfileCard>
  )
}

export default About
