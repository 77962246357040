import PropTypes from 'prop-types'
import * as React from 'react'

function FinalScore(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={props.size} width={props.size} viewBox="0 0 48 48">
        <title>{props.title}</title>
        <g fill={props.color} class="nc-icon-wrapper">
            <path d="M34,1H14a1,1,0,0,0-1,1V23H35V2A1,1,0,0,0,34,1ZM26.145,18H23.61V11.06l.025-1.14.041-1.247q-.631.631-.878.828L21.42,10.609,20.2,9.083l3.865-3.076h2.083Z" data-color="color-2">
                </path>
            <path d="M2,25a1,1,0,0,0-1,1V46a1,1,0,0,0,1,1H23V25ZM16.262,42H7.878V40.236l3.011-3.043q1.335-1.369,1.747-1.9a4.257,4.257,0,0,0,.591-.981,2.5,2.5,0,0,0,.179-.935,1.362,1.362,0,0,0-.4-1.074,1.545,1.545,0,0,0-1.063-.353,3.054,3.054,0,0,0-1.353.32,6.957,6.957,0,0,0-1.37.911L7.845,31.549a9.031,9.031,0,0,1,1.468-1.066A5.366,5.366,0,0,1,10.585,30a6.522,6.522,0,0,1,1.542-.168,4.572,4.572,0,0,1,1.985.41,3.16,3.16,0,0,1,1.337,1.149,3.042,3.042,0,0,1,.476,1.689,4.139,4.139,0,0,1-.291,1.555,5.966,5.966,0,0,1-.9,1.488A21.9,21.9,0,0,1,12.578,38.3l-1.542,1.452v.115h5.226Z" fill={props.color}>
                </path>
            <path d="M46,25H25V47H46a1,1,0,0,0,1-1V26A1,1,0,0,0,46,25ZM38.892,41.2a5.786,5.786,0,0,1-3.585.964,8.707,8.707,0,0,1-3.462-.648V39.358a7.793,7.793,0,0,0,1.534.575,6.439,6.439,0,0,0,1.657.221,3.227,3.227,0,0,0,1.854-.426,1.566,1.566,0,0,0,.6-1.37,1.238,1.238,0,0,0-.688-1.2,5.106,5.106,0,0,0-2.2-.352H33.69V34.863h.927a4.3,4.3,0,0,0,2.039-.365,1.318,1.318,0,0,0,.644-1.251q0-1.362-1.706-1.361a3.886,3.886,0,0,0-1.2.2,5.837,5.837,0,0,0-1.358.68l-1.173-1.747a6.524,6.524,0,0,1,3.913-1.181,5.052,5.052,0,0,1,2.941.754,2.42,2.42,0,0,1,1.079,2.1,2.827,2.827,0,0,1-.681,1.912A3.534,3.534,0,0,1,37.2,35.684v.049a3.815,3.815,0,0,1,2.2.882,2.47,2.47,0,0,1,.746,1.89A3.2,3.2,0,0,1,38.892,41.2Z" fill={props.color}>
                </path>
        </g>
    </svg>
  )
}

FinalScore.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

export default FinalScore
