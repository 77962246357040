export const SVG_LOGO_HEADER_SIZE = 144

export const BIRTH_SEX = [
  { text: 'Female', value: 'Female' },
  { text: 'Male', value: 'Male' },
]

export const GENDER = [
  { text: 'Female', value: 'Female' },
  { text: 'Male', value: 'Male' },
]

export const US_CITIZENSHIP_TYPE = [
  { text: 'Derived U.S. Citizen', value: 'CV' },
  { text: 'Immigrant Alien', value: 'NYd' },
  { text: 'Non-Immigrant Foreign National', value: 'NYe' },
  { text: 'U.S. At Birth Born Abroad of US Parent', value: 'CC' },
  { text: 'U.S. At Birth Native Born', value: 'CA' },
  { text: 'U.S. Non-Citizen National', value: 'AY' },
  { text: 'U.S. Naturalized', value: 'CD' },
]

export const SUB_PROGRAM = [
  { text: 'Non-Rated', value: 'N' },
  { text: 'Rated', value: 'R' },
  { text: 'USSF', value: 'U' },
]

export const GRADUATED_SCHOOL = [
  { text: 'No', value: false },
  { text: 'Yes', value: true },
]

export const DEGREE_TYPE = [
  { text: 'Associate\'s', value: 'Associate' },
  { text: 'Bachelor\'s', value: 'Bachelor' },
  { text: 'Doctorate', value: 'Doctorate' },
  { text: 'High School Diploma', value: 'HighSchool' },
  { text: 'Master\'s', value: 'Master' },
  { text: 'Other', value: 'Other' },
  { text: 'Professional Degree (e.g. MD, DVM, JD)', value: 'Professional' },
]

export const GRADE_RELEVANCE = [
  { text: 'BS', value: 'BS' },
]

export const GRADE = [
  { text: 'E-1', value: 'E-1' },
  { text: 'E-2', value: 'E-2' },
  { text: 'E-3', value: 'E-3' },
  { text: 'E-4', value: 'E-4' },
  { text: 'E-5', value: 'E-5' },
  { text: 'E-6', value: 'E-6' },
  { text: 'E-7', value: 'E-7' },
  { text: 'E-8', value: 'E-8' },
  { text: 'E-9', value: 'E-9' },
]

export const BRANCH = [
  { text: 'Air Force', value: 'USAF' },
  { text: 'Air Force IRR', value: 'USAFIRR' },
  { text: 'Air Force Academy', value: 'USAFA' },
  { text: 'Air Force ROTC', value: 'USAFROTC' },
  { text: 'Air Force Reserve', value: 'USAFR' },
  { text: 'Air National Guard', value: 'ANG' },
  { text: 'Army', value: 'USA' },
  { text: 'Army IRR', value: 'USAIRR' },
  { text: 'Army Academy', value: 'USAA' },
  { text: 'Army ROTC', value: 'USAROTC' },
  { text: 'Army National Guard', value: 'ARNG' },
  { text: 'Army Reserve', value: 'USAR' },
  { text: 'Coast Guard', value: 'USCG' },
  { text: 'Coast Guard IRR', value: 'USCGIRR' },
  { text: 'Coast Guard Academy', value: 'USCGA' },
  { text: 'Coast Guard Reserve', value: 'USCGR' },
  { text: 'Marines', value: 'USMC' },
  { text: 'Marines Reserve', value: 'USMCR' },
  { text: 'Marines IRR', value: 'USMCIRR' },
  { text: 'Navy', value: 'USN' },
  { text: 'Navy Academy', value: 'USNA' },
  { text: 'Navy ROTC', value: 'USNROTC' },
  { text: 'Navy IRR', value: 'USNIRR' },
  { text: 'Navy Reserve', value: 'USNR' },
  { text: 'USPHS', value: 'USPHS' },
  { text: 'USPHS IRR', value: 'USPHSIRR' },
]

export const AFSC_OPTIONS = [
  { text: 'Aerospace Physiologist', value: '13H1' },
  { text: 'Airfield Operations', value: '13M1' },
  { text: 'Nuclear and Missile Operations', value: '13N1' },
  { text: 'Space Operations (USSF Only)', value: '13S1' },
  { text: 'Information Operations ', value: '14F1' },
  { text: 'Intelligence', value: '14N1' },
  { text: 'Operations Analysis Officer', value: '15A1' },
  { text: 'Weather and Environmental Sciences', value: '15W1' },
  { text: 'Cyberspace/Cyber Warfare Operations', value: '17X1(D/S)' },
  { text: 'Special Warfare (CRO,TACP,STO)', value: '19Z1A/B/C' },
  { text: 'Aircraft Maintenance', value: '21A1' },
  { text: 'Munitions & Missile Maintenance', value: '21M1' },
  { text: 'Logistics Readiness', value: '21R1' },
  { text: 'Security Forces', value: '31P1' },
  { text: 'Architect/Architectural Engineering', value: '32E1A' },
  { text: 'Civil Engineer', value: '32E1C' },
  { text: 'Electrical Engineer', value: '32E1E' },
  { text: 'Mechanical Engineer', value: '32E1F' },
  { text: 'General Engineer', value: '32E1G' },
  { text: 'Environmental Engineer', value: '32E1J' },
  { text: 'Band', value: '35B1' },
  { text: 'Public Affairs', value: '35P1' },
  { text: 'Force Support', value: '38F1' },
  { text: 'Chemist/Nuclear Chemist', value: '61C1' },
  { text: 'Physicist/Nuclear Engineer', value: '61D1' },
  { text: 'Developmental Engineer, Aeronautical Engineer', value: '62E1A' },
  { text: 'Developmental Engineer, Astronautical Engineer', value: '62E1B' },
  { text: 'Developmental Engineer, Computer Systems', value: '62E1C' },
  { text: 'Developmental Engineer, Electrical/Electronic', value: '62E1E' },
  { text: 'Developmental Engineer, Project/General Engineer', value: '62E1G' },
  { text: 'Developmental Engineer, Mechanical Engineer', value: '62E1H' },
  { text: 'Acquisition Manager', value: '63A1' },
  { text: 'Contracting', value: '64P1' },
  { text: 'Financial Management', value: '65F1' },
  { text: 'Special Investigations', value: '71S1' },
  { text: 'Helicopter Pilot Trainee', value: '11H1' },
  { text: 'Pilot Trainee', value: '92T0' },
  { text: 'Combat Systems Officer', value: '92T1' },
  { text: 'Air Battle Management (ABM), AWACs', value: '92T2' },
  { text: 'Remotely Piloted Aircraft', value: '92T3' },
]

export const FORM_VERSIONS = [
  { text: 'P1', value: 'P1' },
  { text: 'P2', value: 'P2' },
  { text: 'Q1', value: 'Q1' },
  { text: 'Q2', value: 'Q2' },
  { text: 'S1', value: 'S1' },
  { text: 'S2', value: 'S2' },
  { text: 'T0', value: 'T0' },
  { text: 'T1', value: 'T1' },
  { text: 'T2', value: 'T2' },
  { text: 'T5', value: 'T5' },
  { text: 'T6', value: 'T6' },
  { text: 'T7', value: 'T7' },
  { text: 'T8', value: 'T8' },
  { text: 'T9', value: 'T9' },
]

const ONE_MEGABYTE = 1048576
// 40,000 bytes are reserved for the form data on the payload, since the ALB limits lambda payloads to 1MB total
export const MAX_E2O_FILE_SIZE = ONE_MEGABYTE - 40000
export const MAX_E2O_FILE_SIZE_DISPLAY = '1MB'

export const FORM_TIMEOUT = 15 * 60
export const FORM_TIMEOUT_WARNING = 2 * 60

export const AFCEP_FORM_NAME = 'afcep'
