import { ThemeProvider } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Modal } from 'semantic-ui-react'
import { LightPalette } from '../../theme'
import styled from '@emotion/styled'
import { AimHighButton } from '../../components/Button/Button'
import { fontWeights, fontSizes, fontFamilies } from '../../theme/typography'
import spacing from '../../theme/spacing'
import { FORM_TIMEOUT_WARNING } from './afcep.constants'
import { formatTimeRemaining, getSecondsRemaining } from './timeoutUtil'

export const TimeRemaining = styled.div`
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.xl.toString()}px;
  font-family: ${fontFamilies.stratum2Bold};
  padding: ${spacing.m}px;
`

TimeoutModal.propTypes = {
  buttonHandler: PropTypes.func,
  expirationHandler: PropTypes.func,
  lastEdit: PropTypes.number,
}

function TimeoutModal({ buttonHandler, expirationHandler, lastEdit }) {

  const [modalProps, setModalProps] = useState({})
  const [timeRemaining, setTimeRemaining] = useState(getSecondsRemaining(lastEdit))

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = getSecondsRemaining(lastEdit)
      if (remaining <= 0) {
        expirationHandler()
      }
      setTimeRemaining(remaining)

    }, 1000)

    return () => clearInterval(interval)
  }, [lastEdit, expirationHandler])

  useEffect(() => {
    if (timeRemaining <= 0) {
      setModalProps({
        isOpen: true,
        buttonText: 'Restart',
        modalHeader: 'YOUR SESSION HAS EXPIRED',
        action: 'Please click "Restart" to start a new session',
      })

    } else if (timeRemaining < FORM_TIMEOUT_WARNING) {
      setModalProps({
        isOpen: true,
        showCountDown: true,
        buttonText: 'Continue',
        modalHeader: 'SESSION TIMEOUT ALERT',
        action: 'Please click "Continue" to keep working' })

    } else {
      setModalProps({ isOpen: false })
    }
  }, [timeRemaining])

  const { isOpen, modalHeader, buttonText, action, showCountDown } = modalProps

  return (
    <Modal
      open={ isOpen }
      style={ { textAlign: 'center', maxWidth: '400px', border: 'none' } }>
      <ThemeProvider theme={ LightPalette }>
        <Modal.Header style={ { border: 'none', fontFamily: fontFamilies.stratum2Bold } }>{ modalHeader }</Modal.Header>
        { showCountDown ? <div>Your online session will expire in:</div> : <></> }
        { showCountDown ? <TimeRemaining>{ formatTimeRemaining(timeRemaining) }</TimeRemaining> : <></> }
        <div>{ action }</div>
        <Modal.Actions style={ { textAlign: 'center', border: 'none' } }>
          <AimHighButton primary onClick={ buttonHandler }>
            { buttonText }
          </AimHighButton>
        </Modal.Actions>
      </ThemeProvider>
    </Modal>
  )
}

export default TimeoutModal
