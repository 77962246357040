import styled from '@emotion/styled'
import { Table } from 'semantic-ui-react'
import spacing from '../../theme/spacing'

export const EmailListContent = styled.div`
  background-color: ${props => props.theme.background.highlight};
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  padding: ${spacing.m}px;
`

export const StickyEmailTableHeader = styled(Table.Header)`
  position: sticky;
  top: 128px;
  z-index: 3;
`

export const HalfWidthTable = styled(Table)`
  width: 50% !important;
`
