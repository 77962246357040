import { commonActions } from '../../reducers/common.reducers'
import { put, take } from 'redux-saga/effects'

export default class SafeSaga {

  id
  REQUEST

  // parse standard API errors and handle them. Possibly in a subclass
  errorHandlers = []

  static defaultMeta = {
    persistErrors: false,
    globalErrors: true,
  }

  // Available Meta Params:
  // persistErrors: remove previous errors for itself when run again. Default: true
  // globalErrors: display caught exceptions in a global notification. Default: true
  static createRequest(type, params, meta) {
    meta = { ...SafeSaga.defaultMeta, ...meta }
    return { type, params, meta }
  }

  request(params, meta) {
    return SafeSaga.createRequest(this.REQUEST, params, meta)
  }

  error(error, global = true) {
    return {
      type: commonActions.ADD_ERROR,
      id: this.id,
      global,
      error,
    }
  }

  constructor(id, saga) {
    this.id = id
    this.REQUEST = id
    this.saga = this.wrapSafeSaga(saga)
    this.errorHandlers.push(function* fallbackHandler(error, action) {
      yield put(this.error(error, !action.meta || action.meta.globalErrors))
    }.bind(this))
  }

  wrapSafeSaga(saga) {
    return function* safeSaga(action) {
      if (action.meta && action.meta.persistErrors === false) {
        yield put({
          type: commonActions.REMOVE_ERROR,
          id: this.id,
        })
      }

      try {
        yield saga(action)
      } catch (error) {
        let current = error
        for (const handler of this.errorHandlers) {
          try {
            yield handler(current, action)
            break
          } catch (bubbled) {
            current = bubbled
          }
        }
      }
    }.bind(this)
  }

  catch(handler) {
    this.errorHandlers.splice(this.errorHandlers.length - 1, 0, handler)
    return this
  }
}

export function* takeError(errorId) {
  return yield take(({ type, id }) => type === commonActions.ADD_ERROR && id === errorId)
}
