import React from 'react'
import PropTypes from 'prop-types'
import {
  EventDirectionsWrapper,
  EventStartAndEndText,
  EventLocationTitleText,
  EventLocationAddressText,
  EventGetDirectionsLinkText } from './EventDirections.styles'
import { getDateTimeString } from '../../api/date.util'

const getAddressText = event => {
  if (event.address) {
    return `${event.address}, ${event.city}, ${event.state}`
  }

  return `${event.city}, ${event.state}`
}

const EventDirections = ({ event }) => {
  const directionsUrl = `https://www.google.com/maps/search/?api=1&query=${event.lat},${event.lng}`
  return (
    <EventDirectionsWrapper>
      <EventStartAndEndText>
        { event.startDate && event.endDate ? `${getDateTimeString(event.startDate)} - ${getDateTimeString(event.endDate)}` : <></> }
      </EventStartAndEndText>
      <EventLocationTitleText>{ event.locationName }</EventLocationTitleText>
      <EventLocationAddressText>{ getAddressText(event) }</EventLocationAddressText>
      <EventGetDirectionsLinkText>
        <a href={ directionsUrl } rel='noopener noreferrer' target='_blank'>
          Get Directions
        </a>
      </EventGetDirectionsLinkText>
    </EventDirectionsWrapper>
  )
}

EventDirections.propTypes = {
  event: PropTypes.object,
}

export default EventDirections
