import React from 'react'
import SportsFan from '../../icons/SportsFan'
import Compass from '../../icons/Compass'
import Download from '../../icons/Download'
import Team from '../../icons/Team'
import UserSearch from '../../icons/UserSearch'
import StarrHowItem from './starr.how.item'
import { ItemsWrapper } from './starr.page.styles'

const itemList = [
  {
    icon: SportsFan,
    title: 'Tell your story',
  },
  {
    icon: Compass,
    title: 'choose your path',
  },
  {
    icon: Download,
    title: 'download aim high app',
  },
  {
    icon: UserSearch,
    title: 'find a recruiter',
  },
  {
    icon: Team,
    title: 'there\'s a job for everyone',
  },
]

const TakeawayItems = () => {
  return (
    <ItemsWrapper>
      { itemList.map((item, index) => {
        return <StarrHowItem key={ index } { ...item } />
      }) }
    </ItemsWrapper>
  )
}

export default TakeawayItems
