import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Transforms } from 'slate'
import { useFocused, useSelected } from 'slate-react'
import colors from '../../../theme/colors'
import { borderRadius, fontSizes } from '../../../theme/typography'
import { selectVariables } from '../../redux/ducks/opa.duck'

export const withVariables = editor => {
  const { isInline, isVoid } = editor

  editor.isInline = element => {
    return element.id === 'variable' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.id === 'variable' ? true : isVoid(element)
  }

  return editor
}

export const insertVariable = (editor, variableInfo) => {
  Transforms.insertNodes(editor, { ...variableInfo, children: [{ text: '' }] })
  Transforms.move(editor)
}

export const Variable = ({ attributes, children, element }) => {
  const variables = useSelector(selectVariables)
  const [title, setTitle] = useState('')
  useEffect(() => {
    const currentVariable = variables && variables.find(variable => variable.sort === element.sort)
    let updatedTitle = ''
    if (currentVariable && currentVariable.sort) {
      updatedTitle = currentVariable.type === 'dynamic' ? currentVariable.value.text : currentVariable.description || ''
    }
    setTitle(updatedTitle)
  }, [variables, element.sort])

  const selected = useSelected()
  const focused = useFocused()

  return (
    <span
      { ...attributes }
      title={ title }
      contentEditable={ false }
      data-cy={ `variable-${element.display.replace(' ', '-')}` }
      style={ {
        padding: '3px 3px 2px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'inline-block',
        borderRadius,
        backgroundColor: colors.gray.extraLight,
        color: colors.blue.default,
        fontSize: fontSizes.s,
        boxShadow: selected && focused ? `0 0 0 2px ${colors.blue.default}` : 'none',
      } }>
      { children }$${ element.display }
    </span>
  )
}

Variable.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  element: PropTypes.object,
}
