/* eslint-disable max-len */
import React from 'react'
import { withTheme } from '@emotion/react';

const SvgExplore = withTheme(({ color, tone, theme, size = 32, ...props }) => {
  color = color || theme.text[tone] || theme.text.contrast;
  return (

    <svg width={ size } height={ size } viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <title>zoom 2</title>
      <g className="nc-icon-wrapper" fill={ color }>
        <path fill="#111111" d="M31.414,28.586l-7.931-7.931C25.058,18.505,26,15.864,26,13c0-7.168-5.832-13-13-13S0,5.832,0,13 s5.832,13,13,13c2.863,0,5.505-0.942,7.655-2.517l7.931,7.931L31.414,28.586z M2,13C2,6.935,6.935,2,13,2s11,4.935,11,11 s-4.935,11-11,11S2,19.065,2,13z" />
      </g>
    </svg>
  )
})

SvgExplore.displayName = SvgExplore
export default SvgExplore
