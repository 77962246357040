import styled from '@emotion/styled'

export const CardHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  background-size: cover !important;
  width: 100%;
  height: 333px;
  border-radius: 8px 8px 0 0;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

export const MilitaryTitle = styled.div`
  color: ${props => props.theme.text.contrast};
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 32px;
  text-transform: uppercase;
`

export const Title = styled.div`
  color: ${props => props.theme.text.contrast};
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 32px;
  text-transform: uppercase;
`
