import PropTypes from 'prop-types'
import React from 'react'
import { Field } from '../../components/Forms/Lead/Field'
import { Header } from '../../components/Forms/Lead/Header'
import RadioButton from './RadioButton'

PriorService.propTypes = {
  errors: PropTypes.object,
  dispatch: PropTypes.func,
  dispatchError: PropTypes.func,
}

function PriorService({ dispatch, dispatchError, errors }) {
  const onChange = name => value => {
    dispatch({ type: name, value })
    if (value !== undefined && value !== null) {
      dispatchError({ ...errors, [name]: false })
    }
  }
  return (
    <>
      <Header>Prior Service</Header>
      <Field>
        <RadioButton
          group='militaryService'
          label='Have you served in the military?'
          testid='militaryService'
          error={ errors.militaryService }
          onChange={ onChange('militaryService') }
        />
      </Field>
     
      <Field isBottom>
        <RadioButton
          group='recruiterProcess'
          label='Have you begun the recruiting process with another recruiter?'
          testid='recruiterProcess'
          error={ errors.recruiterProcess }
          onChange={ onChange('recruiterProcess') }
        />
      </Field>
    </>
  )
}

export default PriorService
