import * as React from 'react';

function Export(props) {
  return (
    <svg width={ props.size } height={ props.size } viewBox="0 0 32 32" { ...props }>
      <path
        d="M17.545 13.727l-5.091-5.091-5.091 5.091m5.091-5.091v12.727V8.636zM1 29h22.909V1H1v28z"
        stroke={ props.color }
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Export;
