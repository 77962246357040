/* eslint-disable */
import * as React from 'react';

function People(props) {
  return (
    <svg width={ props.size } height={ props.size } viewBox="0 0 32 32" { ...props }>
      <path
        d="M10.417 15.034h9.166v6.518h-9.166v-6.518zm1.833 0v-2.793c0-1.542 1.231-2.793 2.75-2.793h0c1.519 0 2.75 1.25 2.75 2.793v2.793M26 17.828C26 23.998 21.075 29 15 29S4 23.998 4 17.828V4.793L15 2l11 2.793v13.035z"
        stroke={ props.color }
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default People;
