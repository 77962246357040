/* eslint-disable */
export const MAJOR = [
  { key: 'Z052', text: 'Unmanned Aircraft Systems', value: 'Z052' },
  { key: '4IMB', text: 'ELEC/DIR ENERGY, BEAM CONTROL', value: '4IMB' },
  { key: '4IMD', text: 'ELEC/DIR ENERGY, HIGH PWR MICRO', value: '4IMD' },
  { key: '4IMC', text: 'ELEC/DIR ENERGY, STOCH CONTROL', value: '4IMC' },
  { key: '4AAX', text: 'A-N ENG ADNMIC OTHER', value: '4AAX' },
  { key: '4AEX', text: 'A-N ENG PROPULSION OTHER', value: '4AEX' },
  { key: '4AFX', text: 'A-N ENG STAB  AND  CNTL OTHER', value: '4AFX' },
  { key: '4AGA', text: 'A-N ENG STRUCTURES A-ELASTY', value: '4AGA' },
  { key: '4AGF', text: 'A-N ENG STRUCTURES MISSELE', value: '4AGF' },
  { key: '4AGX', text: 'A-N ENG STRUCTURES OTHER', value: '4AGX' },
  { key: '4VAF', text: 'ACFT-FUEL-SYS', value: '4VAF' },
  { key: '1CAX', text: 'ADMIN-MGT-OCC-TECH OTHER', value: '1CAX' },
  { key: '7EAG', text: 'ADULT HEALTH NURSING', value: '7EAG' },
  { key: '4VCX', text: 'AERO SCIENCE TECH OTHER', value: '4VCX' },
  { key: '4VAX', text: 'AERO-TECH OTHER', value: '4VAX' },
  { key: '4ADX', text: 'AERONAU ENG INSTRU OTHER', value: '4ADX' },
  { key: '4ACX', text: 'AERONAUTICAL ENG DESIGN OTHER', value: '4ACX' },
  { key: '4AXY', text: 'AERONAUTICAL ENGNEERING OTH', value: '4AXY' },
  { key: '4BXY', text: 'AEROSPACE ENGINEERING OTHER', value: '4BXY' },
  { key: '3BAX', text: 'AG AG AND FD CHEM OTHER', value: '3BAX' },
  { key: '3BCX', text: 'AG AGRONOMY OTHER', value: '3BCX' },
  { key: '3BDX', text: 'AG FISH AND WILDLIFE OTHER', value: '3BDX' },
  { key: '3BEX', text: 'AG FSTRY AND RGE SCI OTHER', value: '3BEX' },
  { key: '0YAY', text: 'AGRI  AND  FOOD CHEMISTRY', value: '0YAY' },
  { key: '3BBX', text: 'AGRI ANIMAL HUSBANDRY/OTHER', value: '3BBX' },
  { key: '3BFX', text: 'AGRI HORTICULTURE OTHER', value: '3BFX' },
  { key: '3BGX', text: 'AGRI SOIL SCI OTHER', value: '3BGX' },
  { key: '0YAC', text: 'AGRI-FD CHEM ANI-FEEDS. Animal Feeds--Inter-Area Specialization', value: '0YAC' },
  { key: '0YBX', text: 'AGRI-FD CHEM BIOCHEM OTH', value: '0YBX' },
  { key: '0YAX', text: 'AGRI-FD CHEM OTHER', value: '0YAX' },
  { key: '0YBR', text: 'AGRI-FD CHEM PHYS-BIOCHEM', value: '0YBR' },
  { key: '0YAA', text: 'AGRI-FD CHEM, ALCOHIC BEV. INTER-AREA SPECIALIZATION--ALCOHOLIC BEVERAGES', value: '0YAA' },
  { key: '0YAB', text: 'AGRI-FD CHEM, ANI-VEG FATS. Animal and Vegatable Fats and Oils--Inter-Area Specialization', value: '0YAB' },
  { key: '0YAD', text: 'AGRI-FD CHEM, BAK-CONF PROD. Bakery and Confectionary Products--Interarea Specialization', value: '0YAD' },
  { key: '0YAE', text: 'AGRI-FD CHEM, CEREAL-CARBOH.  Cereals and Carbohydrates--Inter-Area Specialization', value: '0YAE' },
  { key: '0YAG', text: 'AGRI-FD CHEM, FD-FEED ADDIT. Food and Feed Additives--Inter-Area Specialization', value: '0YAG' },
  { key: '0YAF', text: 'AGRI-FD CHEM, FERT-PLANT GR.  Fertilizers, Plant Growth Regulators--Inter-Area Specialization', value: '0YAF' },
  { key: '0YAH', text: 'AGRI-FD CHEM, FRUIT-VEG-JUI.  Fruits, Vegetables, Juices--Inter-Area Specialization', value: '0YAH' },
  { key: '0YAI', text: 'AGRI-FD CHEM, MET-FSH-DA-PO.  Meat, Fish, Dairy and Poultry Products--Inter-Area Specialization', value: '0YAI' },
  { key: '0YAJ', text: 'AGRI-FD CHEM, NONALCOHL BEV.  Nonalcoholic Beverages--Inter-Area Specialization', value: '0YAJ' },
  { key: '0YAK', text: 'AGRI-FD CHEM, NONFD CROP PR.  Nonfood Crop Products--Inter-Area Specialization', value: '0YAK' },
  { key: '0YAL', text: 'AGRI-FD CHEM, PESTICIDES. Pesticides--Inter-Area Specialization', value: '0YAL' },
  { key: '3BIX', text: 'AGRI-TECH OTHER', value: '3BIX' },
  { key: '4CXY', text: 'AGRICULTURAL ENGINEERING OT', value: '4CXY' },
  { key: '3BXY', text: 'AGRICULTURE OTHER', value: '3BXY' },
  { key: '2IAA', text: 'AIR TRAFFIC CONTROL', value: '2IAA' },
  { key: '4VAT', text: 'AIRCREW LIFE SUPPORT', value: '4VAT' },
  { key: '1BAY', text: 'AIRPOWER STUDIES', value: '1BAY' },
  { key: '6AXY', text: 'ALGEBRA-OTHER', value: '6AXY' },
  { key: '7AAX', text: 'ALL SCI-BAS BIO-OTHER', value: '7AAX' },
  { key: '7AAI', text: 'ALL SCI-PROF SV-BIO ENGINEER (BSC)', value: '7AAI' },
  { key: '7AAJ', text: 'ALL SCI-PROF SV-PUBLIC HEALTH (BSC)', value: '7AAJ' },
  { key: '7FEX', text: 'ANIMAL-TECHN OTHER', value: '7FEX' },
  { key: '6BIY', text: 'ANL/FUN-ANL INT-INTGR-DIFF', value: '6BIY' },
  { key: '6BXY', text: 'ANL/FUN-ANL OTHER', value: '6BXY' },
  { key: '9AXY', text: 'ANTHRO ANTHROPOLOGY OTHER', value: '9AXY' },
  { key: '9ABX', text: 'ANTHRO ARCHAE OTHER', value: '9ABX' },
  { key: '9ACX', text: 'ANTHRO CULT ANTH OTHER', value: '9ACX' },
  { key: '9ADY', text: 'ANTHRO LINGUISTICS', value: '9ADY' },
  { key: '9AEX', text: 'ANTHRO PHYS OTHER', value: '9AEX' },
  { key: '0CCX', text: 'APPLIED DATA PROC', value: '0CCX' },
  { key: '0CCY', text: 'APPLIED EDP INDUSTRL ENGR', value: '0CCY' },
  { key: '2DYA', text: 'ARABMOD (MODERN STANDARD)', value: '2DYA' },
  { key: '4DAX', text: 'ARCH ENG CY AND RGN PLN OTHER', value: '4DAX' },
  { key: '4DBX', text: 'ARCH ENG ELT SYS OTHER', value: '4DBX' },
  { key: '4DCX', text: 'ARCH ENG MECH SYS OTHER', value: '4DCX' },
  { key: '4DDX', text: 'ARCH ENG SANIT SYS OTHER', value: '4DDX' },
  { key: '4DXY', text: 'ARCHITECTURAL ENG OTHER', value: '4DXY' },
  { key: '4VBX', text: 'ARCHTRL-ENGR-TECH OTHER', value: '4VBX' },
  { key: '0YLY', text: 'AREA SPECIALIST', value: '0YLY' },
  { key: '4EAX', text: 'ASTR-ENG DSGN OTHER', value: '4EAX' },
  { key: '4EBX', text: 'ASTR-ENG GAS/DYN OTHER', value: '4EBX' },
  { key: '4EDA', text: 'ASTR-ENG INTR AUTO CNTL SYS', value: '4EDA' },
  { key: '4EDD', text: 'ASTR-ENG INTR FLT T AND RESEARH', value: '4EDD' },
  { key: '4EDX', text: 'ASTR-ENG INTR OTHER', value: '4EDX' },
  { key: '4EEX', text: 'ASTR-ENG PRPUL OTHER', value: '4EEX' },
  { key: '4EGX', text: 'ASTR-ENG STRUCT OTHER', value: '4EGX' },
  { key: '4EGG', text: 'ASTR-ENG STRUCT STRL OPTIM', value: '4EGG' },
  { key: '8AXY', text: 'ASTRO-ASTRONOMY OTHER', value: '8AXY' },
  { key: '8AAX', text: 'ASTRO-PHYS-OTHER', value: '8AAX' },
  { key: '8ABF', text: 'ASTRO-POSL-GEODETIC ASTRONO', value: '8ABF' },
  { key: '8ABX', text: 'ASTRO-POSL-OTHER', value: '8ABX' },
  { key: '4EAY', text: 'ASTRONAUTICAL ENG DESIGN', value: '4EAY' },
  { key: '4EXY', text: 'ASTRONAUTICAL ENG OTHER', value: '4EXY' },
  { key: '8FSY', text: 'ATMOSPHERIC AND SPACE PHYSICS SCIENCES: TERRESTRIAL AND SPACE', value: '8FSY' },
  { key: '4FBA', text: 'Abrasives', value: '4FBA' },
  { key: '8CBA', text: 'Absorption Spectroscopy', value: '8CBA' },
  { key: '4QAA', text: 'Accelerators-Instrumentation', value: '4QAA' },
  { key: '8HLA', text: 'Accelerators-Nuclear Physics', value: '8HLA' },
  { key: '4RAY', text: 'Accident Prevention', value: '4RAY' },
  { key: '9GDA', text: 'Accountability', value: '9GDA' },
  { key: '1AAY', text: 'Accounting', value: '1AAY' },
  { key: '41BA', text: 'Acoustics', value: '41BA' },
  { key: '1ACB', text: 'Acquisition Logistics Management', value: '1ACB' },
  { key: '1AMJ', text: 'Acquisition Logistics Management', value: '1AMJ' },
  { key: '8HAY', text: 'Actinide Series', value: '8HAY' },
  { key: '4QBA', text: 'Activation Analysis', value: '4QBA' },
  { key: '6EAY', text: 'Activity Analysis', value: '6EAY' },
  { key: '6EBY', text: 'Actuarial Mathematics', value: '6EBY' },
  { key: '8CGA', text: 'Adhesives', value: '8CGA' },
  { key: '1YYY', text: 'Administration Management and Military Science', value: '1YYY' },
  { key: '2BBA', text: 'Administration and Management (College Teaching)', value: '2BBA' },
  { key: '1CYY', text: 'Administration and Management Technologies', value: '1CYY' },
  { key: 'Z050', text: 'Administration of Justice ', value: 'Z050' },
  { key: '1CAA', text: 'Administrative Assistant', value: '1CAA' },
  { key: '2BAY', text: 'Administrative Functions (Education)', value: '2BAY' },
  { key: '5ACA', text: 'Administrative Law', value: '5ACA' },
  { key: '9GEA', text: 'Administrative Law And Regulation (Public Budgeting and Public Finance)', value: '9GEA' },
  { key: '9GBA', text: 'Administrative Law and Regulation (Constitutional and Administrative Law)', value: '9GBA' },
  { key: '9GFA', text: 'Administrative Leadership', value: '9GFA' },
  { key: '1CAN', text: 'Administrative Management', value: '1CAN' },
  { key: '9GAY', text: 'Administrative Policy and Public Policy', value: '9GAY' },
  { key: '7CAA', text: 'Administrative Services', value: '7CAA' },
  { key: '1CAY', text: 'Administrative/Management Occupational Technologies', value: '1CAY' },
  { key: '5ACB', text: 'Admiralty Law', value: '5ACB' },
  { key: '2BEA', text: 'Adolescence Development and Behavior', value: '2BEA' },
  { key: '9HCA', text: 'Adolescence and Courtship', value: '9HCA' },
  { key: '4GCA', text: 'Adsorption and Absorption', value: '4GCA' },
  { key: '2BAA', text: 'Adult Education', value: '2BAA' },
  { key: '4MHA', text: 'Advanced Project Design', value: '4MHA' },
  { key: '1AFA', text: 'Advanced Statistics', value: '1AFA' },
  { key: '1AMA', text: 'Advanced or Executive Management', value: '1AMA' },
  { key: '2CBA', text: 'Advertising (Commercial Art)', value: '2CBA' },
  { key: '1ANA', text: 'Advertising (Marketing)', value: '1ANA' },
  { key: '1CAB', text: 'Advertising Management', value: '1CAB' },
  { key: '2FDA', text: 'Advertising Writing', value: '2FDA' },
  { key: '8BBA', text: 'Aerial and Space Photogrammetry', value: '8BBA' },
  { key: '4AAA', text: 'Aerodynamic Loads', value: '4AAA' },
  { key: '4AAY', text: 'Aerodynamic--Aeronautical Engineering', value: '4AAY' },
  { key: '8HNA', text: 'Aerodynamic--Physics of Fluids', value: '8HNA' },
  { key: '4EGA', text: 'Aeroelasticity', value: '4EGA' },
  { key: '4VAA', text: 'Aeronautical Drafting Technology', value: '4VAA' },
  { key: '4AYY', text: 'Aeronautical Engineering', value: '4AYY' },
  { key: 'Z037', text: 'Aeronautical Science', value: 'Z037' },
  { key: '4VAY', text: 'Aeronautical Technology', value: '4VAY' },
  { key: '5ACC', text: 'Aeronautics-Space Law', value: '5ACC' },
  { key: '8FDA', text: 'Aeronomy', value: '8FDA' },
  { key: '8HNB', text: 'Aerosols', value: '8HNB' },
  { key: '2IAI', text: 'Aerospace Control System Technology', value: '2IAI' },
  { key: '4BYY', text: 'Aerospace Engineering', value: '4BYY' },
  { key: '4VAB', text: 'Aerospace Ground Equipment Maintenance', value: '4VAB' },
  { key: '9DHK', text: 'Aerospace Historian', value: '9DHK' },
  { key: '7DRA', text: 'Aerospace Medicine', value: '7DRA' },
  { key: '8GAA', text: 'Aerospace Photography', value: '8GAA' },
  { key: '7ABA', text: 'Aerospace Physiology', value: '7ABA' },
  { key: '7GAN', text: 'Aerospace Physiology Technology', value: '7GAN' },
  { key: '0SYY', text: 'Aerospace Safety', value: '0SYY' },
  { key: '0GAY', text: 'Aerospace Science', value: '0GAY' },
  { key: '4VCY', text: 'Aerospace Science Technology', value: '4VCY' },
  { key: 'AS00', text: 'Aerospace Studies', value: 'AS00' },
  { key: '4BAY', text: 'Aerospace-Mechanical Engineering', value: '4BAY' },
  { key: '2GAY', text: 'Aesthetics', value: '2GAY' },
  { key: '6CAY', text: 'Affine Geometry', value: '6CAY' },
  { key: '9DFA', text: 'Afghanistan (History)', value: '9DFA' },
  { key: '9DAY', text: 'Africa (History)', value: '9DAY' },
  { key: '2ICA', text: 'African Studies', value: '2ICA' },
  { key: '2ICB', text: 'Afro-American Studies', value: '2ICB' },
  { key: '1AEA', text: 'Agency Law', value: '1AEA' },
  { key: '9HCB', text: 'Aging and Retirement', value: '9HCB' },
  { key: 'Z070', text: 'Agribusiness Management ', value: 'Z070' },
  { key: '4VCA', text: 'Agricultural Aviation Technology', value: '4VCA' },
  { key: '9BFA', text: 'Agricultural Economics', value: '9BFA' },
  { key: '4CYY', text: 'Agricultural Engineering', value: '4CYY' },
  { key: '3ALA', text: 'Agricultural Entomology', value: '3ALA' },
  { key: '9CCA', text: 'Agricultural Geography', value: '9CCA' },
  { key: '8FGA', text: 'Agricultural Meteorology', value: '8FGA' },
  { key: '4CAY', text: 'Agricultural Structures and Equipment', value: '4CAY' },
  { key: '3BIY', text: 'Agricultural Technologies', value: '3BIY' },
  { key: '3BZY', text: 'Agricultural and Life Sciences', value: '3BZY' },
  { key: '3BYY', text: 'Agriculture', value: '3BYY' },
  { key: '3BAY', text: 'Agriculture and Food Chemistry', value: '3BAY' },
  { key: '4DCA', text: 'Air Conditioning', value: '4DCA' },
  { key: '4VKA', text: 'Air Conditioning and Engineering Technology', value: '4VKA' },
  { key: '4MIA', text: 'Air Conditioning and Refrigeration', value: '4MIA' },
  { key: '4HEA', text: 'Air Pollution (Sanitary Engineering)', value: '4HEA' },
  { key: '4HIA', text: 'Air Systems', value: '4HIA' },
  { key: '2UAA', text: 'Air Traffic Control', value: '2UAA' },
  { key: '1ATA', text: 'Air Transportation', value: '1ATA' },
  { key: '4REA', text: 'Air Transportation Safety', value: '4REA' },
  { key: '4ABY', text: 'Air Weapons', value: '4ABY' },
  { key: '4VRB', text: 'Aircraft Accessory Sys Tech', value: '4VRB' },
  { key: '4VRY', text: 'Aircraft Armament Systems Technology', value: '4VRY' },
  { key: '4VAC', text: 'Aircraft Electrical Systems', value: '4VAC' },
  { key: '4VRZ', text: 'Aircraft Engineering Technology', value: '4VRZ' },
  { key: '4VAE', text: 'Aircraft Instrument And Environmental Systems Maintenance', value: '4VAE' },
  { key: '4ADA', text: 'Aircraft Instrumentation', value: '4ADA' },
  { key: '4VAD', text: 'Aircraft Maintenance Technology', value: '4VAD' },
  { key: '4VAG', text: 'Aircraft Pneudraulic Systems', value: '4VAG' },
  { key: '4VAH', text: 'Aircraft Powerplant Maintenance', value: '4VAH' },
  { key: '4VAI', text: 'Aircraft Propeller Systems', value: '4VAI' },
  { key: '4AGB', text: 'Aircraft Structures', value: '4AGB' },
  { key: '4VAL', text: 'Aircrew Operations', value: '4VAL' },
  { key: '4VAN', text: 'Airframe Repair Tech', value: '4VAN' },
  { key: '9IAY', text: 'Airline Steward/Stewardess', value: '9IAY' },
  { key: '4VEN', text: 'Airway Sci', value: '4VEN' },
  { key: '3BAA', text: 'Alcoholic Beverages', value: '3BAA' },
  { key: '8CAA', text: 'Alcoholic Beverages--Chemistry', value: '8CAA' },
  { key: '6AYY', text: 'Algebra', value: '6AYY' },
  { key: '6CBY', text: 'Algebraic Geometry', value: '6CBY' },
  { key: '6FAY', text: 'Algebraic Number Theory', value: '6FAY' },
  { key: '6JAY', text: 'Algebraic Topology', value: '6JAY' },
  { key: '6GAY', text: 'Algorithm Construction', value: '6GAY' },
  { key: '8CEA', text: 'Alkaline Earths and Metals', value: '8CEA' },
  { key: '8CGB', text: 'Alkaloids', value: '8CGB' },
  { key: '7DGA', text: 'Allergy', value: '7DGA' },
  { key: '7GAL', text: 'Allied Health Sciences', value: '7GAL' },
  { key: '7AYY', text: 'Allied Sciences', value: '7AYY' },
  { key: '9GAA', text: 'American Government', value: '9GAA' },
  { key: '2FCA', text: 'American Literature', value: '2FCA' },
  { key: '8CGC', text: 'Amino Acids', value: '8CGC' },
  { key: '3ACA', text: 'Amino Acids, Peptides, Proteins--Biology', value: '3ACA' },
  { key: '8CCA', text: 'Amino Acids, Peptides, Proteins--Chemistry', value: '8CCA' },
  { key: '0YBA', text: 'Amino Acids, Peptides, Proteins--Inter-Area Specialization', value: '0YBA' },
  { key: '0CBA', text: 'Analog/Digital Computation', value: '0CBA' },
  { key: '0CDA', text: 'Analogue Systems, Coding and Programming', value: '0CDA' },
  { key: '2GBA', text: 'Analyic Philosophy', value: '2GBA' },
  { key: '6FBY', text: 'Analylic Number Theory', value: '6FBY' },
  { key: '8FEY', text: 'Analysis and Forecasting', value: '8FEY' },
  { key: '6BYY', text: 'Analysis and Functional Analysis', value: '6BYY' },
  { key: '8CBY', text: 'Analytical Chemistry', value: '8CBY' },
  { key: '8HIA', text: 'Analytical Mechanics', value: '8HIA' },
  { key: '8BBB', text: 'Analytical Photogrammetry', value: '8BBB' },
  { key: '6IAY', text: 'Analytical Statistics', value: '6IAY' },
  { key: '3AAY', text: 'Anatomy', value: '3AAY' },
  { key: '9GDB', text: 'Anatomy of Organization', value: '9GDB' },
  { key: '9DBY', text: 'Ancient History', value: '9DBY' },
  { key: '2GGA', text: 'Ancient Philosophy', value: '2GGA' },
  { key: '7EAA', text: 'Anesthesia', value: '7EAA' },
  { key: '7DAY', text: 'Anesthesiology', value: '7DAY' },
  { key: '3AJA', text: 'Animal Cytology', value: '3AJA' },
  { key: '3AKA', text: 'Animal Ecology', value: '3AKA' },
  { key: '3BAC', text: 'Animal Feeds--Agriculture And Food Chemistry', value: '3BAC' },
  { key: '8CAC', text: 'Animal Feeds--Chemistry', value: '8CAC' },
  { key: '3AMA', text: 'Animal Genetics', value: '3AMA' },
  { key: '3BBY', text: 'Animal Husbandry', value: '3BBY' },
  { key: '9FEA', text: 'Animal Learning', value: '9FEA' },
  { key: '3AOA', text: 'Animal Nutrition', value: '3AOA' },
  { key: '3ATA', text: 'Animal Physiology', value: '3ATA' },
  { key: '3AQA', text: 'Animal Science', value: '3AQA' },
  { key: '7FEY', text: 'Animal Technican and Animal Technology', value: '7FEY' },
  { key: '3BAB', text: 'Animal and Vegatable Fats and Oils--Agriculture and Food Chemistry', value: '3BAB' },
  { key: '8CAB', text: 'Animal and Vegatable Fats and Oils--Chemistry', value: '8CAB' },
  { key: '8HEA', text: 'Antenna Theory', value: '8HEA' },
  { key: '4IBB', text: 'Antennas', value: '4IBB' },
  { key: '2GCA', text: 'Anthropology (Cultural Philosophies)', value: '2GCA' },
  { key: '9AYY', text: 'Anthropology (Social Sciences)', value: '9AYY' },
  { key: '9AEA', text: 'Anthropometry', value: '9AEA' },
  { key: '5ACD', text: 'Anti-Trust Law', value: '5ACD' },
  { key: '8CGD', text: 'Antibiotics', value: '8CGD' },
  { key: '3ANA', text: 'Antibody Formation', value: '3ANA' },
  { key: '3ANB', text: 'Antigens', value: '3ANB' },
  { key: '3ANC', text: 'Antigens-Antibody Reaction', value: '3ANC' },
  { key: '3ACB', text: 'Antimetabilities--Biology', value: '3ACB' },
  { key: '8CCB', text: 'Antimetabilities--Chemistry', value: '8CCB' },
  { key: '0YBB', text: 'Antimetabilities--Inter-Area Specialization', value: '0YBB' },
  { key: '2HCA', text: 'Apologetics', value: '2HCA' },
  { key: '4VPA', text: 'Apparel Design Technology', value: '4VPA' },
  { key: '0CCA', text: 'Applications of Data Processing', value: '0CCA' },
  { key: '6DAY', text: 'Applications of Logic', value: '6DAY' },
  { key: '6HAY', text: 'Applications of Probability', value: '6HAY' },
  { key: '8HAA', text: 'Applied Acoustics, Instruments and Apparatus', value: '8HAA' },
  { key: '4ACA', text: 'Applied Aerodynamics and Performance', value: '4ACA' },
  { key: '9AAY', text: 'Applied Anthropology', value: '9AAY' },
  { key: '8FBA', text: 'Applied Climatology', value: '8FBA' },
  { key: '1ABY', text: 'Applied Comptrollership', value: '1ABY' },
  { key: '4LBA', text: 'Applied Problems', value: '4LBA' },
  { key: '9ABY', text: 'Archaeology', value: '9ABY' },
  { key: '4DYY', text: 'Architectrual Engineering', value: '4DYY' },
  { key: '8HAB', text: 'Architectural Acoustics', value: '8HAB' },
  { key: '4VBY', text: 'Architectural Engineering Technology', value: '4VBY' },
  { key: '2CAA', text: 'Architectural History', value: '2CAA' },
  { key: '2CKA', text: 'Architectural Sculpture', value: '2CKA' },
  { key: '4VBA', text: 'Architectural Woodworking Technology', value: '4VBA' },
  { key: '2CAY', text: 'Architecture', value: '2CAY' },
  { key: '2CGA', text: 'Archives, Historic Libraries', value: '2CGA' },
  { key: '0YLA', text: 'Area Specialization-- Western Europe', value: '0YLA' },
  { key: '0YLK', text: 'Area Specialization--Caribbean', value: '0YLK' },
  { key: '0YLB', text: 'Area Specialization--Eastern Europe', value: '0YLB' },
  { key: '0YLM', text: 'Area Specialization--Far East', value: '0YLM' },
  { key: '0YLL', text: 'Area Specialization--Latin America', value: '0YLL' },
  { key: '0YLH', text: 'Area Specialization--Mediterranean', value: '0YLH' },
  { key: '0YLF', text: 'Area Specialization--Middle East', value: '0YLF' },
  { key: '0YLD', text: 'Area Specialization--North Africa', value: '0YLD' },
  { key: '0YLG', text: 'Area Specialization--South Asia', value: '0YLG' },
  { key: '0YLJ', text: 'Area Specialization--Southeast Asia', value: '0YLJ' },
  { key: '0YLC', text: 'Area Specialization--Soviet Union', value: '0YLC' },
  { key: '0YLE', text: 'Area Specialization--Sub-Sahara Africa', value: '0YLE' },
  { key: '8DCA', text: 'Areal Geology', value: '8DCA' },
  { key: '9DIA', text: 'Argentina (History)', value: '9DIA' },
  { key: '9DKA', text: 'Armenian SSR (History)', value: '9DKA' },
  { key: '2BIA', text: 'Art (Secondary Teaching)', value: '2BIA' },
  { key: '4WAY', text: 'Artificial Intelligence', value: '4WAY' },
  { key: 'Z027', text: 'Arts and Sciences', value: 'Z027' },
  { key: '2BBB', text: 'Arts, Humanities and Education (College Teaching)', value: '2BBB' },
  { key: '2YYY', text: 'Arts, Humanities, and Education', value: '2YYY' },
  { key: '0YLN', text: 'Asian Languages and Civilizations', value: '0YLN' },
  { key: '5ABA', text: 'Associations Law (Non-Corporate)', value: '5ABA' },
  { key: '8ABA', text: 'Astrometry', value: '8ABA' },
  { key: '4EYY', text: 'Astronautical Engineering', value: '4EYY' },
  { key: '8AAA', text: 'Astronomical Instruments--Physical Astronomy', value: '8AAA' },
  { key: '8ABB', text: 'Astronomical Instruments--Positional Astronomy', value: '8ABB' },
  { key: '8AAB', text: 'Astronomical Photometry', value: '8AAB' },
  { key: '8AAC', text: 'Astronomical Specgroscopy--Physical Astronomy', value: '8AAC' },
  { key: '8ABC', text: 'Astronomical Spectroscopy--Positional Astronomy', value: '8ABC' },
  { key: '6ECY', text: 'Astronomy--Mathematics', value: '6ECY' },
  { key: '8AYY', text: 'Astronomy--Physical Sciences', value: '8AYY' },
  { key: '8AAD', text: 'Astrophysics--Astronomy', value: '8AAD' },
  { key: '8HBY', text: 'Astrophysics--Physics', value: '8HBY' },
  { key: '9FYZ', text: 'Athletic Training', value: '9FYZ' },
  { key: '8FFG', text: 'Atmoshperic Sciences', value: '8FFG' },
  { key: '8HMA', text: 'Atmosphere and Space Optics', value: '8HMA' },
  { key: '8FFB', text: 'Atmospheric Acoustics', value: '8FFB' },
  { key: '8FFC', text: 'Atmospheric Chemistry', value: '8FFC' },
  { key: '8FAY', text: 'Atmospheric Dynamics', value: '8FAY' },
  { key: '8FFA', text: 'Atmospheric Electricity', value: '8FFA' },
  { key: '8FAE', text: 'Atmospheric Energy Balance', value: '8FAE' },
  { key: '8FAB', text: 'Atmospheric Motion', value: '8FAB' },
  { key: '8FFF', text: 'Atmospheric Optics', value: '8FFF' },
  { key: '8FAA', text: 'Atmospheric Thermodynamics', value: '8FAA' },
  { key: '5ACE', text: 'Atomic Energy Law', value: '5ACE' },
  { key: '8HCB', text: 'Atomic Masses and Abundance', value: '8HCB' },
  { key: '8CEB', text: 'Atomic Nuclei', value: '8CEB' },
  { key: '8HCY', text: 'Atomic and Molecular Physics', value: '8HCY' },
  { key: '8HCC', text: 'Atomic and Molecular Structure And Spectra', value: '8HCC' },
  { key: '8HCA', text: 'Atomic, Ionic, and Molecular Beams', value: '8HCA' },
  { key: '2BDA', text: 'Audio-Visual Aids', value: '2BDA' },
  { key: '2AEA', text: 'Audio-Visual--Religious Education', value: '2AEA' },
  { key: '7AAH', text: 'Audiology', value: '7AAH' },
  { key: '2IAJ', text: 'Audiovisual Production', value: '2IAJ' },
  { key: '1AAA', text: 'Auditing', value: '1AAA' },
  { key: '9FEB', text: 'Audition', value: '9FEB' },
  { key: '9DDA', text: 'Australia, incl. New Zealand (History)', value: '9DDA' },
  { key: '4ADB', text: 'Automated Control Systems--Aeronautical Instrumentation', value: '4ADB' },
  { key: '8CCU', text: 'Automated Control Systems--Astronautical Instrumentation', value: '8CCU' },
  { key: '4GBA', text: 'Automatic Process Controls', value: '4GBA' },
  { key: '4VLA', text: 'Automotive Body Repair', value: '4VLA' },
  { key: '4MAY', text: 'Automotive Engineering', value: '4MAY' },
  { key: '4VKB', text: 'Automotive Engineering Technology', value: '4VKB' },
  { key: '2JBY', text: 'Automotive Technology', value: '2JBY' },
  { key: 'Z054', text: 'Aviation', value: 'Z054' },
  { key: 'Z023', text: 'Aviation Administration', value: 'Z023' },
  { key: 'Z028', text: 'Aviation Safety', value: 'Z028' },
  { key: 'Z022', text: 'Aviation Technology', value: 'Z022' },
  { key: '4VHS', text: 'Avionic Systems Tech', value: '4VHS' },
  { key: '4IAC', text: 'Avionics Applications to Navigation', value: '4IAC' },
  { key: '4VHA', text: 'Avionics Communication--Navigation Systems Technology', value: '4VHA' },
  { key: '4IAA', text: 'Avionics Fire Control and Guidance', value: '4IAA' },
  { key: '4VHB', text: 'Avionics Instrument Systems Technology', value: '4VHB' },
  { key: '4VHC', text: 'Avionics Radar Technology', value: '4VHC' },
  { key: '4IHA', text: 'Avionics Systems', value: '4IHA' },
  { key: '4IAY', text: 'Avionics and Armament Systems', value: '4IAY' },
  { key: '9DKB', text: 'Azerbaijan SSR (History)', value: '9DKB' },
  { key: '1BBA', text: 'BASIC SCIENCE', value: '1BBA' },
  { key: '3AAX', text: 'BIO ANAT OTHER', value: '3AAX' },
  { key: '3ABX', text: 'BIO BACT OTHER', value: '3ABX' },
  { key: '3ACX', text: 'BIO BIOC OTHER', value: '3ACX' },
  { key: '3ADX', text: 'BIO BIOG OTHER', value: '3ADX' },
  { key: '3AGX', text: 'BIO BIOP SPEC OTHER', value: '3AGX' },
  { key: '3EAX', text: 'BIO ENGINEERING', value: '3EAX' },
  { key: '0YBY', text: 'BIOCHEMISTRY', value: '0YBY' },
  { key: 'OYBY', text: 'BIOCHEMISTRY INTER-AREA SPECIALIZATION', value: 'OYBY' },
  { key: '0YCY', text: 'BIOGEOGRAPHY', value: '0YCY' },
  { key: '0YCX', text: 'BIOGEOGRAPHY OTHER', value: '0YCX' },
  { key: '3AIX', text: 'BIOLOGY BOTANY OTHER', value: '3AIX' },
  { key: '3AJX', text: 'BIOLOGY CYTOLOGY OTHER', value: '3AJX' },
  { key: '3AKX', text: 'BIOLOGY ECOLOGY OTHER', value: '3AKX' },
  { key: '3ALX', text: 'BIOLOGY ENTOMOLOGY OTHER', value: '3ALX' },
  { key: '3AMX', text: 'BIOLOGY GENETICS OTHER', value: '3AMX' },
  { key: '3ANX', text: 'BIOLOGY IMMUNOLOGY OTHER', value: '3ANX' },
  { key: '3AOX', text: 'BIOLOGY NUTRITION OTHER', value: '3AOX' },
  { key: '3AXY', text: 'BIOLOGY OTHER', value: '3AXY' },
  { key: '3AXX', text: 'BIOLOGY OTHER OTHER', value: '3AXX' },
  { key: '3APX', text: 'BIOLOGY PALEONTOLOGY OTHER', value: '3APX' },
  { key: '3ARX', text: 'BIOLOGY PATHOLOGY OTHER', value: '3ARX' },
  { key: '3ASX', text: 'BIOLOGY PHARMACOLOGY OTHER', value: '3ASX' },
  { key: '3ATX', text: 'BIOLOGY PHYSIOLOGY OTHER', value: '3ATX' },
  { key: '3AVX', text: 'BIOLOGY ZOOLOGY OTHER', value: '3AVX' },
  { key: '7GAX', text: 'BIOMED-SCI-TECH OTHER', value: '7GAX' },
  { key: '0YDK', text: 'BIOPHYS-SPEC ELECTRO-MICRO', value: '0YDK' },
  { key: '0YDX', text: 'BIOPHYS-SPEC OTHER', value: '0YDX' },
  { key: '1AVY', text: 'BUS ADM  AERO/AVIA MGT', value: '1AVY' },
  { key: '1ATI', text: 'BUS ADM-MGT TRANLAW', value: '1ATI' },
  { key: '1ATX', text: 'BUS ADM-MGT TRANTOTHER', value: '1ATX' },
  { key: '1AAD', text: 'BUS ADM/MGT ACTG FIS PROC', value: '1AAD' },
  { key: '1ADX', text: 'BUS ADM/MGT BUS ECO OTH ER', value: '1ADX' },
  { key: '1AEX', text: 'BUS ADM/MGT BUS LAW OTHER', value: '1AEX' },
  { key: '1AHX', text: 'BUS ADM/MGT FIN OTHER', value: '1AHX' },
  { key: '1AJC', text: 'BUS ADM/MGT GEN MGT ECO ANL', value: '1AJC' },
  { key: '1AJX', text: 'BUS ADM/MGT GEN MGT OTHER', value: '1AJX' },
  { key: '1ALX', text: 'BUS ADM/MGT INS OTHER', value: '1ALX' },
  { key: '1AMX', text: 'BUS ADM/MGT LOG OTHER', value: '1AMX' },
  { key: '1ANX', text: 'BUS ADM/MGT MARK OTHER', value: '1ANX' },
  { key: '1AXY', text: 'BUS ADM/MGT OTHER', value: '1AXY' },
  { key: '1AOX', text: 'BUS ADM/MGT PERSOTHER', value: '1AOX' },
  { key: '1AQX', text: 'BUS ADM/MGT REAL EST OTHER', value: '1AQX' },
  { key: '1AFX', text: 'BUS ADM/MGT STAT OTHER', value: '1AFX' },
  { key: '1APB', text: 'BUS ADM/MGT, ORG MGT', value: '1APB' },
  { key: '1APA', text: 'BUS ADM/MGT, QUALITY ASSUR', value: '1APA' },
  { key: '1AAH', text: 'BUS ADMIN/COMPUTER/INFO SYS', value: '1AAH' },
  { key: '1AKX', text: 'BUS MGT/ADM IND PROOTHER', value: '1AKX' },
  { key: '0CAX', text: 'BUS/ADMIN/MGMT/ADP-EDP', value: '0CAX' },
  { key: '1AAX', text: 'BUSS ADM/MGT ACTG OTHER', value: '1AAX' },
  { key: '3ABY', text: 'Bacteriology--Biology', value: '3ABY' },
  { key: '7ABB', text: 'Bacteriology--Professional Services', value: '7ABB' },
  { key: '7FDA', text: 'Bacteriology--Veterinary Research Medicine', value: '7FDA' },
  { key: '3BAD', text: 'Bakery and Confectionary Products--Agriculture And Food Chemistry', value: '3BAD' },
  { key: '8CAD', text: 'Bakery and Confectionary Products--Chemistryrc', value: '8CAD' },
  { key: '2CJA', text: 'Ballet', value: '2CJA' },
  { key: '4EGB', text: 'Ballistic Missile Structures', value: '4EGB' },
  { key: '8BBC', text: 'Ballistics and Satellite Photogrammetry', value: '8BBC' },
  { key: '4AAB', text: 'Ballistics--Aerodynamics', value: '4AAB' },
  { key: '8HIB', text: 'Ballistics--Physics-Mechanics', value: '8HIB' },
  { key: '6BAY', text: 'Banach Spaces and Algebras', value: '6BAY' },
  { key: '5ABB', text: 'Banking and Commercial Law', value: '5ABB' },
  { key: '5ABC', text: 'Bankruptcy Law', value: '5ABC' },
  { key: '7AAY', text: 'Basic Biomedical Sciences', value: '7AAY' },
  { key: '9FAA', text: 'Behavior Problems', value: '9FAA' },
  { key: '2GDA', text: 'Behavior Psychology', value: '2GDA' },
  { key: '4OAA', text: 'Beneficiation', value: '4OAA' },
  { key: '9DLA', text: 'Benelux Countries (History)', value: '9DLA' },
  { key: '2GBB', text: 'Bergsonism', value: '2GBB' },
  { key: '2FCB', text: 'Biblical Literature', value: '2FCB' },
  { key: '4VPB', text: 'Bindery Technology', value: '4VPB' },
  { key: '3AGF', text: 'Bio-Optics--Biology', value: '3AGF' },
  { key: '0YDF', text: 'Bio-Optics--Inter-Area Specialization', value: '0YDF' },
  { key: '8HDF', text: 'Bio-Optics--Physics', value: '8HDF' },
  { key: '4IKY', text: 'Bio-Systems Engineering', value: '4IKY' },
  { key: '3AGG', text: 'Bio-Systems, Control and Communications-- Biology', value: '3AGG' },
  { key: '0YDG', text: 'Bio-Systems; Control and Communications--Inter-Area Specialization', value: '0YDG' },
  { key: '8HDG', text: 'Bio-Systems; Control and Communications--Physics', value: '8HDG' },
  { key: '3AGA', text: 'Bioacoustics and Transmission--Biology', value: '3AGA' },
  { key: '0YDA', text: 'Bioacoustics and Transmission--Inter-Area Specialization', value: '0YDA' },
  { key: '8HDA', text: 'Bioacoustics and Transmission--Physics', value: '8HDA' },
  { key: '3ACC', text: 'Biochemical Mechanisms--Biology', value: '3ACC' },
  { key: '8CCC', text: 'Biochemical Mechanisms--Chemistry', value: '8CCC' },
  { key: '0YBC', text: 'Biochemical Mechanisms--Inter-Area Specialization', value: '0YBC' },
  { key: '3AGB', text: 'Biochemical Physics--Biology', value: '3AGB' },
  { key: '0YDB', text: 'Biochemical Physics--Inter-Area Specialization', value: '0YDB' },
  { key: '8HDB', text: 'Biochemical Physics--Physics', value: '8HDB' },
  { key: 'OYBZ', text: 'Biochemistry', value: 'OYBZ' },
  { key: '3ACY', text: 'Biochemistry--Biology', value: '3ACY' },
  { key: '8CCY', text: 'Biochemistry--Chemistry', value: '8CCY' },
  { key: '7AAA', text: 'Biochemistry--Professional Services', value: '7AAA' },
  { key: '7FDB', text: 'Biochemistry--Veterinary Research', value: '7FDB' },
  { key: '3ACD', text: 'Biochemorphology--Biology', value: '3ACD' },
  { key: '8CCD', text: 'Biochemorphology--Chemistry', value: '8CCD' },
  { key: '0YBD', text: 'Biochemorphology--Inter-Area Specialization', value: '0YBD' },
  { key: '8FBB', text: 'Bioclimatology', value: '8FBB' },
  { key: '3AGC', text: 'Bioelectricity and Transmission--Biology', value: '3AGC' },
  { key: '0YDC', text: 'Bioelectricity and Transmission--Inter-Area Specialization', value: '0YDC' },
  { key: '8HDC', text: 'Bioelectricity and Transmission--Physics', value: '8HDC' },
  { key: '3AGD', text: 'Bioelectronics--Biology', value: '3AGD' },
  { key: '0YDD', text: 'Bioelectronics--Inter-Area Specialization', value: '0YDD' },
  { key: '8HDD', text: 'Bioelectronics--Physics', value: '8HDD' },
  { key: '7GAM', text: 'Bioenvironmental Engineering Technology', value: '7GAM' },
  { key: '3ADY', text: 'Biogeography--Biology', value: '3ADY' },
  { key: '9CAY', text: 'Biogeography--Inter-Area Specialization', value: '9CAY' },
  { key: '8DFA', text: 'Biogeography--Physical Geography', value: '8DFA' },
  { key: '8HKA', text: 'Biological Effects', value: '8HKA' },
  { key: 'Z026', text: 'Biological Engineering', value: 'Z026' },
  { key: '8EBA', text: 'Biological Oceanography', value: '8EBA' },
  { key: 'Z031', text: 'Biological Sciences', value: 'Z031' },
  { key: '3AEY', text: 'Biological Warfare', value: '3AEY' },
  { key: '3YYY', text: 'Biological and Agricultural Sciences', value: '3YYY' },
  { key: '3AYY', text: 'Biology', value: '3AYY' },
  { key: '3AXA', text: 'Biology Teaching', value: '3AXA' },
  { key: '2BBC', text: 'Biology and Agriculture (College Teaching)', value: '2BBC' },
  { key: '3AFY', text: 'Biology, General', value: '3AFY' },
  { key: '8H11', text: 'Biomechanical Engineering', value: '8H11' },
  { key: '7AAB', text: 'Biomedical Engineering', value: '7AAB' },
  { key: '7GAA', text: 'Biomedical Equipment Technician and Biomedical Equipment Technology', value: '7GAA' },
  { key: '7GAY', text: 'Biomedical Sciences Technology', value: '7GAY' },
  { key: '6EDY', text: 'Biometrics, Biostatistics', value: '6EDY' },
  { key: '3AGE', text: 'Bionics--Biology', value: '3AGE' },
  { key: '0YDE', text: 'Bionics--Inter-Area Specialization', value: '0YDE' },
  { key: '8HDE', text: 'Bionics--Physics', value: '8HDE' },
  { key: '7AAC', text: 'Bionics--Professional Services', value: '7AAC' },
  { key: '3AGY', text: 'Biophysical Specialties--Biology', value: '3AGY' },
  { key: '0YDY', text: 'Biophysical Specialties--Inter-Area Specialization', value: '0YDY' },
  { key: '8HDY', text: 'Biophysical Specialties--Physics', value: '8HDY' },
  { key: '7AAD', text: 'Biophysics', value: '7AAD' },
  { key: '3AHY', text: 'Bioradiology', value: '3AHY' },
  { key: '3AGH', text: 'Biothermics and Bioenergetics--Biology', value: '3AGH' },
  { key: '0YDH', text: 'Biothermics and Bioenergetics--Inter-Area Specialization', value: '0YDH' },
  { key: '8HDH', text: 'Biothermics and Bioenergetics--Physics', value: '8HDH' },
  { key: '3AGI', text: 'Biotransport and Membrane Physics--Biology', value: '3AGI' },
  { key: '0YDI', text: 'Biotransport and Membrane Physics--Inter-Area Specialization', value: '0YDI' },
  { key: '8HDI', text: 'Biotransport and Membrane Physics--Physics', value: '8HDI' },
  { key: '4QCA', text: 'Blast and Thermal Effects', value: '4QCA' },
  { key: '4MGA', text: 'Boilers and Steam Engineering', value: '4MGA' },
  { key: '9DIB', text: 'Bolivia (History)', value: '9DIB' },
  { key: '4ADC', text: 'Bombardment, Fire Control and Navigation Systems', value: '4ADC' },
  { key: '1CAC', text: 'Bookkeeping', value: '1CAC' },
  { key: '1ASM', text: 'Boolean Algebra', value: '1ASM' },
  { key: '6AAY', text: 'Boolean Algebra', value: '6AAY' },
  { key: '8CEC', text: 'Boron Family', value: '8CEC' },
  { key: '3AIY', text: 'Botany', value: '3AIY' },
  { key: '8HNC', text: 'Boundary Layer Effects', value: '8HNC' },
  { key: '4MIB', text: 'Boundary Layer Flow', value: '4MIB' },
  { key: '8FAD', text: 'Boundary Layer Meteorology', value: '8FAD' },
  { key: '9DIC', text: 'Brazil (History)', value: '9DIC' },
  { key: '2HBA', text: 'Buddhism', value: '2HBA' },
  { key: '1AAB', text: 'Budgeting', value: '1AAB' },
  { key: '4HBA', text: 'Building Construction', value: '4HBA' },
  { key: '8CED', text: 'Building Products', value: '8CED' },
  { key: '4VEA', text: 'Building and Grounds Management', value: '4VEA' },
  { key: '9DDB', text: 'Burma (History)', value: '9DDB' },
  { key: '2BIB', text: 'Business Administration (Secondary Teaching)', value: '2BIB' },
  { key: '1AYY', text: 'Business Administration and/or Management', value: '1AYY' },
  { key: '1ADA', text: 'Business Cycles', value: '1ADA' },
  { key: '1ADY', text: 'Business Economics', value: '1ADY' },
  { key: '1AJA', text: 'Business History', value: '1AJA' },
  { key: '1AEY', text: 'Business Law', value: '1AEY' },
  { key: '7CAB', text: 'Business Office Administration', value: '7CAB' },
  { key: '1AEB', text: 'Business Organization and Regulation Law', value: '1AEB' },
  { key: '1AJB', text: 'Business Policies', value: '1AJB' },
  { key: '9GEB', text: 'Business Regulation', value: '9GEB' },
  { key: '1AFY', text: 'Business Statistics and Quantitive Methods', value: '1AFY' },
  { key: '1ANG', text: 'Buying', value: '1ANG' },
  { key: '9DKC', text: 'Byelorussian SSR (History)', value: '9DKC' },
  { key: '7DEA', text: 'CARDIOTHORACIC SURGERY', value: '7DEA' },
  { key: '8BXY', text: 'CART SCI OTHER', value: '8BXY' },
  { key: '8BAX', text: 'CART SCI-CART-OTHER', value: '8BAX' },
  { key: '8BCX', text: 'CART SCI-PHOTO ITER-OTHER', value: '8BCX' },
  { key: '8BBX', text: 'CART SCI-PHOTOG-OTHER', value: '8BBX' },
  { key: '4FBX', text: 'CERAMIC ENG MTRL OTHER', value: '4FBX' },
  { key: '4FCX', text: 'CERAMIC ENG PROC OTHER', value: '4FCX' },
  { key: '4FXY', text: 'CERAMIC ENGINEERING OTHER', value: '4FXY' },
  { key: '2AXY', text: 'CHAPLANCY  AND  PASTORAL OTHER', value: '2AXY' },
  { key: '8CAX', text: 'CHEM AGRI/FOOD OTHER', value: '8CAX' },
  { key: '8CBF', text: 'CHEM ANALYTICA GAS ANAL', value: '8CBF' },
  { key: '8CBX', text: 'CHEM ANALYTICAL OTHER', value: '8CBX' },
  { key: '8CCX', text: 'CHEM BIOCHEM OTHER', value: '8CCX' },
  { key: '4GBX', text: 'CHEM ENG EQP-DSGN OTHER', value: '4GBX' },
  { key: '4GCX', text: 'CHEM ENG UT OPR OTHER', value: '4GCX' },
  { key: '8CEQ', text: 'CHEM INORGANIC NONMIN PROD', value: '8CEQ' },
  { key: '8CEX', text: 'CHEM INORGANIC OTHER', value: '8CEX' },
  { key: '8CGX', text: 'CHEM ORGANIC OTHER', value: '8CGX' },
  { key: '8CXX', text: 'CHEM OTHER NO SUB SPEC', value: '8CXX' },
  { key: '8CHX', text: 'CHEM PHYS OTHER', value: '8CHX' },
  { key: '8CAY', text: 'CHEM-AGRICULTURE  AND  FOOD', value: '8CAY' },
  { key: '4GXY', text: 'CHEMICAL ENGINEERING OTHER', value: '4GXY' },
  { key: '2DYB', text: 'CHINESE (MANDARIN)', value: '2DYB' },
  { key: '7HYY', text: 'CHIROPRACTIC MEDICINE', value: '7HYY' },
  { key: '4HAY', text: 'CIV ENG CITY AND REGIONAL PLAN', value: '4HAY' },
  { key: '4HAX', text: 'CIV ENG CY AND RGNL PLN OTHER', value: '4HAX' },
  { key: '4HFX', text: 'CIV ENG SOIL AND FDN OTHER', value: '4HFX' },
  { key: '4HIX', text: 'CIV ENG TRANS AND TRAF OTHER', value: '4HIX' },
  { key: '4VEX', text: 'CIV-ENGRG-TECH OTHER', value: '4VEX' },
  { key: '4HBX', text: 'CIVIL ENG CONSTR OTHER', value: '4HBX' },
  { key: '4HDA', text: 'CIVIL ENG HYDRAULIC FLD MCH', value: '4HDA' },
  { key: '4HDX', text: 'CIVIL ENG HYDRAULIC OTHER', value: '4HDX' },
  { key: '4HEX', text: 'CIVIL ENG SANITARY OTHER', value: '4HEX' },
  { key: '4HGX', text: 'CIVIL ENG STRUCTURAL OTHER', value: '4HGX' },
  { key: '4HHX', text: 'CIVIL ENG SUR AND MAP OTHER', value: '4HHX' },
  { key: '4HXY', text: 'CIVIL ENGINEERING OTHER', value: '4HXY' },
  { key: '5ABK', text: 'CIVIL LAW-LABOR    PRIV LAW', value: '5ABK' },
  { key: '5AXY', text: 'CIVIL LAW-OTHER', value: '5AXY' },
  { key: '5ACX', text: 'CIVIL LAW-OTHER     PUB LAW', value: '5ACX' },
  { key: '5ABX', text: 'CIVIL LAW-OTHER    PRIV LAW', value: '5ABX' },
  { key: '5AAX', text: 'CIVIL LAW-OTHR INTNTL LAW', value: '5AAX' },
  { key: '7GDC', text: 'CLNCL-LAB-SCI', value: '7GDC' },
  { key: '7DEB', text: 'COLO-RECTAL SURGERY', value: '7DEB' },
  { key: '2IAX', text: 'COMM-TECH OTHER', value: '2IAX' },
  { key: '0CNY', text: 'COMP COMM NETWORKS', value: '0CNY' },
  { key: '4VFX', text: 'COMP-ENGRG-TECH OTHER', value: '4VFX' },
  { key: '0CCD', text: 'COMPUTER INFO SYSTEMS', value: '0CCD' },
  { key: '0CYY', text: 'COMPUTER SCIENCE', value: '0CYY' },
  { key: '0CAY', text: 'COMPUTER SYS/DATA PROCESSING', value: '0CAY' },
  { key: '9JYY', text: 'CRIMINAL JUSTICE', value: '9JYY' },
  { key: '0HAY', text: 'CRIMINOLOGY', value: '0HAY' },
  { key: '9HAX', text: 'CRIMINOLOGY OTHER', value: '9HAX' },
  { key: '0CYC', text: 'CYBER OPERATION', value: '0CYC' },
  { key: '0CYW', text: 'CYBER WARFARE', value: '0CYW' },
  { key: '2AAX', text: 'Caap and Past Counsl Other', value: '2AAX' },
  { key: '2AEX', text: 'Caap and Past Other', value: '2AEX' },
  { key: '4VEC', text: 'Cabinet Making Technology', value: '4VEC' },
  { key: '6BBY', text: 'Calculus or Variations', value: '6BBY' },
  { key: '2CCA', text: 'Calligraphy', value: '2CCA' },
  { key: '8HPA', text: 'Calorimetry', value: '8HPA' },
  { key: '9DDC', text: 'Cambodia, Laos, Vietnam (History)', value: '9DDC' },
  { key: '9DHA', text: 'Canada (History)', value: '9DHA' },
  { key: '8CCE', text: 'Carbohydrates', value: '8CCE' },
  { key: '3ACE', text: 'Carbohydrates', value: '3ACE' },
  { key: '0YBE', text: 'Carbohydrates--Inter-Area Specialization', value: '0YBE' },
  { key: '8CGE', text: 'Carbohydrates--Organic Chemistry', value: '8CGE' },
  { key: '8CEE', text: 'Carbon Family', value: '8CEE' },
  { key: '7EAB', text: 'Cardio-Vascular Nursing', value: '7EAB' },
  { key: '7GDA', text: 'Cardiopulmonary Laboratory Technician and Cardiopulmonary Laboratory   Technology', value: '7GDA' },
  { key: '7DGB', text: 'Cardiovascular Diseases (Cardiology)', value: '7DGB' },
  { key: '4VED', text: 'Carpentry', value: '4VED' },
  { key: '8BYY', text: 'Cartographic Sciences', value: '8BYY' },
  { key: '4HHA', text: 'Cartographic Surveying', value: '4HHA' },
  { key: '8BAY', text: 'Cartography', value: '8BAY' },
  { key: '2CBB', text: 'Cartooning', value: '2CBB' },
  { key: '2CKB', text: 'Casting and Metallurgical Techniques', value: '2CKB' },
  { key: '1ALA', text: 'Casualty Insurance', value: '1ALA' },
  { key: '2CGB', text: 'Cataloguing and Classification', value: '2CGB' },
  { key: '8CHA', text: 'Catalysis', value: '8CHA' },
  { key: '9IBY', text: 'Catering Arts', value: '9IBY' },
  { key: '2GIA', text: 'Causality', value: '2GIA' },
  { key: '8HND', text: 'Cavities and Jets', value: '8HND' },
  { key: '6EEY', text: 'Celestial Mechanics--Mathematics', value: '6EEY' },
  { key: '8ABD', text: 'Celestial Mechanics--Positional Astronomy', value: '8ABD' },
  { key: '8ABE', text: 'Celestial Navigation', value: '8ABE' },
  { key: '3AGJ', text: 'Cellular Biophysics--Biology', value: '3AGJ' },
  { key: '0YDJ', text: 'Cellular Biophysics--Inter-Area Specialization', value: '0YDJ' },
  { key: '8HDJ', text: 'Cellular Biophysics--Physics', value: '8HDJ' },
  { key: '4FBB', text: 'Cements, Limes, Plasters', value: '4FBB' },
  { key: '9DHB', text: 'Central America (History)', value: '9DHB' },
  { key: '9BIA', text: 'Central Government Finance', value: '9BIA' },
  { key: '9FEC', text: 'Central Nervous System Functions', value: '9FEC' },
  { key: '4FCA', text: 'Ceramic Fabrication Processes', value: '4FCA' },
  { key: '4FBC', text: 'Ceramic Metal Materials Systems', value: '4FBC' },
  { key: '4FCY', text: 'Ceramic Processes', value: '4FCY' },
  { key: '2CKC', text: 'Ceramic Sculpture', value: '2CKC' },
  { key: '4FCB', text: 'Ceramic Thermal Processes', value: '4FCB' },
  { key: '2CCB', text: 'Ceramics (Decorative Arts And Crafts)', value: '2CCB' },
  { key: '2CFA', text: 'Ceramics (Industrial Art-Design)', value: '2CFA' },
  { key: '8HOA', text: 'Ceramics (Physics)', value: '8HOA' },
  { key: '3BAE', text: 'Cereals and Carbohydrates--Agriculture and Food Chemistry', value: '3BAE' },
  { key: '8CAE', text: 'Cereals and Carbohydrates--Chemistry', value: '8CAE' },
  { key: '2AYY', text: 'Chaplaincy and Pastoral Care', value: '2AYY' },
  { key: '2GDB', text: 'Characterology', value: '2GDB' },
  { key: '8HCD', text: 'Chemical Bonds and Structure', value: '8HCD' },
  { key: '9ICY', text: 'Chemical Dependency Counseling Aid', value: '9ICY' },
  { key: '4GYY', text: 'Chemical Engineering', value: '4GYY' },
  { key: '4VDY', text: 'Chemical Engineering Technology', value: '4VDY' },
  { key: '8CHB', text: 'Chemical Kinetics', value: '8CHB' },
  { key: '8CBB', text: 'Chemical Microscopy', value: '8CBB' },
  { key: '8EBB', text: 'Chemical Oceanography', value: '8EBB' },
  { key: '3ASA', text: 'Chemical Pharmacology', value: '3ASA' },
  { key: '4GCB', text: 'Chemical Separation', value: '4GCB' },
  { key: '8CDY', text: 'Chemical Warfare', value: '8CDY' },
  { key: '8CYY', text: 'Chemistry', value: '8CYY' },
  { key: '8CXA', text: 'Chemistry Teaching', value: '8CXA' },
  { key: '8EAA', text: 'Chemistry of Water', value: '8EAA' },
  { key: '3ASB', text: 'Chemotherapy', value: '3ASB' },
  { key: '2ICC', text: 'Chicano Studies', value: '2ICC' },
  { key: '2CEA', text: 'Child Development', value: '2CEA' },
  { key: '2IDA', text: 'Child Development Technology', value: '2IDA' },
  { key: '2BEB', text: 'Child Development and Behavior', value: '2BEB' },
  { key: '9FCA', text: 'Child and Adolescent Psychology', value: '9FCA' },
  { key: '9DID', text: 'Chile (History)', value: '9DID' },
  { key: '2GKA', text: 'China (Philosophy)', value: '2GKA' },
  { key: '9DDD', text: 'China, incl. Mongolia and Tibet (History)', value: '9DDD' },
  { key: '2IFA', text: 'Christian Education Technology', value: '2IFA' },
  { key: '2IFB', text: 'Christian Ministries Technology', value: '2IFB' },
  { key: '2IFC', text: 'Christian Vocational Studies Technology', value: '2IFC' },
  { key: '8CBC', text: 'Chromatographic Analysis', value: '8CBC' },
  { key: '2IFD', text: 'Church History Technology', value: '2IFD' },
  { key: '4DAA', text: 'City Planning--Architectural Engineering', value: '4DAA' },
  { key: '4HAA', text: 'City Planning--Civil Engineering', value: '4HAA' },
  { key: '4DAY', text: 'City and Regional Planning (Engineering)', value: '4DAY' },
  { key: '2CAB', text: 'City, Regional, And Industrial Planning (Architecture)', value: '2CAB' },
  { key: '4HYY', text: 'Civil Engineering', value: '4HYY' },
  { key: '4VEY', text: 'Civil Engineering Technology', value: '4VEY' },
  { key: '4VEF', text: 'Civil Engineering Technology (Occupational)', value: '4VEF' },
  { key: '4HJY', text: 'Civil Engineering/Facilities', value: '4HJY' },
  { key: '5AYY', text: 'Civil Law', value: '5AYY' },
  { key: '4VEE', text: 'Civil and Highway Construction Technology', value: '4VEE' },
  { key: '1CAF', text: 'Civil and Public Administration', value: '1CAF' },
  { key: '2FCC', text: 'Classical Literature', value: '2FCC' },
  { key: '2ICD', text: 'Classics', value: '2ICD' },
  { key: '8CEF', text: 'Clay and Clay Products', value: '8CEF' },
  { key: '4FBD', text: 'Clay productS', value: '4FBD' },
  { key: '8FBC', text: 'Climatic Simulation', value: '8FBC' },
  { key: '8FBY', text: 'Climatology', value: '8FBY' },
  { key: '8DFB', text: 'Climatology (Physical Geography)', value: '8DFB' },
  { key: '7GDJ', text: 'Clinical Assistant, Health Care', value: '7GDJ' },
  { key: '7GDB', text: 'Clinical Audiology', value: '7GDB' },
  { key: '3ACF', text: 'Clinical Biochemistry--Biology', value: '3ACF' },
  { key: '8CCF', text: 'Clinical Biochemistry--Chemistry', value: '8CCF' },
  { key: '0YBF', text: 'Clinical Biochemistry--Inter-Area Specialization', value: '0YBF' },
  { key: '7ABD', text: 'Clinical Laboratory Management', value: '7ABD' },
  { key: '7GDD', text: 'Clinical Microbiology', value: '7GDD' },
  { key: '7ABC', text: 'Clinical Neurophysiology', value: '7ABC' },
  { key: '3AOB', text: 'Clinical Nutrition', value: '3AOB' },
  { key: '2HCD', text: 'Clinical Pastoral Education', value: '2HCD' },
  { key: '7DNA', text: 'Clinical Pathology', value: '7DNA' },
  { key: '7ABE', text: 'Clinical Psychology--Professional Services', value: '7ABE' },
  { key: '9FAY', text: 'Clinical Psychology--Psychology', value: '9FAY' },
  { key: '7EAC', text: 'Clinical Research Nursing', value: '7EAC' },
  { key: '7EAY', text: 'Clinician Nurse', value: '7EAY' },
  { key: '7CAC', text: 'Clinics Administration', value: '7CAC' },
  { key: '2CEB', text: 'Clothing and Textiles and Textile Chemistry', value: '2CEB' },
  { key: '8FFD', text: 'Cloud and Precipitation Physics', value: '8FFD' },
  { key: 'Z033', text: 'Cognitive Science', value: 'Z033' },
  { key: '9HIA', text: 'Collective Behavior and Social Movements', value: '9HIA' },
  { key: '2BBY', text: 'College Teaching', value: '2BBY' },
  { key: '8CHC', text: 'Colloid Chemistry', value: '8CHC' },
  { key: '9DIE', text: 'Colombia (History)', value: '9DIE' },
  { key: '7DBY', text: 'Colon and Rectal Surgery', value: '7DBY' },
  { key: '8HMB', text: 'Color, Colorimetry', value: '8HMB' },
  { key: '6ABY', text: 'Combinatorial Analysis (Mathematics)', value: '6ABY' },
  { key: '4AEA', text: 'Combustion--Aeronautical Engineering', value: '4AEA' },
  { key: '4EEA', text: 'Combustion--Astronautical Engineering', value: '4EEA' },
  { key: 'OYST', text: 'Command and Control Operational Test and Evaluation', value: 'OYST' },
  { key: '0YST', text: 'Command and Control Operational Test and Evaluation', value: '0YST' },
  { key: 'Z042', text: 'Commerce', value: 'Z042' },
  { key: '2CBY', text: 'Commercial Art', value: '2CBY' },
  { key: 'Z036', text: 'Commercial Aviation / Professional Pilot', value: 'Z036' },
  { key: '9BGA', text: 'Commercial Banking', value: '9BGA' },
  { key: '1CBA', text: 'Commercial Education Technology', value: '1CBA' },
  { key: '1AQA', text: 'Commercial Real Estate', value: '1AQA' },
  { key: 'Z048', text: 'Communication', value: 'Z048' },
  { key: '9GAB', text: 'Communication (Administrative Policy and Public Policy)', value: '9GAB' },
  { key: '2IAL', text: 'Communication Applications Technology', value: '2IAL' },
  { key: '2FDB', text: 'Communication Research', value: '2FDB' },
  { key: '9FED', text: 'Communication Research and Information Theory', value: '9FED' },
  { key: '9FJI', text: 'Communication and Culture', value: '9FJI' },
  { key: '4DBA', text: 'Communications (Electrical Systems)', value: '4DBA' },
  { key: '4VHD', text: 'Communications Equipment Technology', value: '4VHD' },
  { key: '5ABD', text: 'Communications Law', value: '5ABD' },
  { key: '2IAK', text: 'Communications Operations Technology', value: '2IAK' },
  { key: '2IAC', text: 'Communications Processing Management', value: '2IAC' },
  { key: '4IGA', text: 'Communications Systems, General', value: '4IGA' },
  { key: '2IAY', text: 'Communications Technology', value: '2IAY' },
  { key: '4VHE', text: 'Communications Wire Technology', value: '4VHE' },
  { key: '4ISA', text: 'Communications/Radar', value: '4ISA' },
  { key: '4IGY', text: 'Communications/Radar', value: '4IGY' },
  { key: '7EAR', text: 'Community Health Nursing', value: '7EAR' },
  { key: '2IED', text: 'Community Organization and Recreation', value: '2IED' },
  { key: '2BAB', text: 'Community Relations (Education)', value: '2BAB' },
  { key: '9HDA', text: 'Community Studies', value: '9HDA' },
  { key: '7GAB', text: 'Community and Mental Health', value: '7GAB' },
  { key: '3AAA', text: 'Comparative Anatomy', value: '3AAA' },
  { key: '1ADB', text: 'Comparative Economic Systems--Business Economics', value: '1ADB' },
  { key: '9BBA', text: 'Comparative Economic Systems--Economic Theory', value: '9BBA' },
  { key: '2IBA', text: 'Comparative Education', value: '2IBA' },
  { key: '9GCA', text: 'Comparative Government', value: '9GCA' },
  { key: '9EAY', text: 'Comparative Government - Political Science', value: '9EAY' },
  { key: '5ACF', text: 'Comparative Jurisprudence', value: '5ACF' },
  { key: '2FCD', text: 'Comparative Literature', value: '2FCD' },
  { key: '3ARA', text: 'Comparative Pathology', value: '3ARA' },
  { key: '9FEE', text: 'Comparative Psychology', value: '9FEE' },
  { key: '2HAY', text: 'Comparative Religions', value: '2HAY' },
  { key: '9BCA', text: 'Competition in American Industry', value: '9BCA' },
  { key: '2FFA', text: 'Compiling and Editing', value: '2FFA' },
  { key: '3AND', text: 'Complement (Complement Fixation)', value: '3AND' },
  { key: '6CCY', text: 'Complex Manifolds (Mathematics)', value: '6CCY' },
  { key: '8BAA', text: 'Compliation Cartography', value: '8BAA' },
  { key: '4ACB', text: 'Component Design (Aeronautical Engineering)', value: '4ACB' },
  { key: '4EAA', text: 'Component Design (Astronautical Engineering)', value: '4EAA' },
  { key: '2CHA', text: 'Composition (Music)', value: '2CHA' },
  { key: '4AAC', text: 'Compressibility', value: '4AAC' },
  { key: '8HNE', text: 'Compressible Fluid Dynamics', value: '8HNE' },
  { key: '4AEB', text: 'Compressor, Turbines', value: '4AEB' },
  { key: '4WBY', text: 'Computer Communications', value: '4WBY' },
  { key: '4WYY', text: 'Computer Engineering', value: '4WYY' },
  { key: '4VFY', text: 'Computer Engineering Technology', value: '4VFY' },
  { key: 'Z002', text: 'Computer Information Systems', value: 'Z002' },
  { key: 'Z059', text: 'Computer Information Technology', value: 'Z059' },
  { key: 'Z030', text: 'Computer Science', value: 'Z030' },
  { key: '0CAD', text: 'Computer Science/Artificial Intelligence', value: '0CAD' },
  { key: '0CCB', text: 'Computer Theory', value: '0CCB' },
  { key: 'Z041', text: 'Computerized Accounting', value: 'Z041' },
  { key: '2GHA', text: 'Concept, Judgment, and Reasoning', value: '2GHA' },
  { key: '2CFB', text: 'Concrete', value: '2CFB' },
  { key: '2IAD', text: 'Conference and Court Reporting', value: '2IAD' },
  { key: '4BBY', text: 'Configuration Design (Aerospace Engineering)', value: '4BBY' },
  { key: '4EAB', text: 'Configuration Design, Manned Ballistic Vehicles', value: '4EAB' },
  { key: '4ACC', text: 'Configuration Design, Manned Vehicles', value: '4ACC' },
  { key: '4EAC', text: 'Configuration Design, Unmanned Ballistic Vehicles', value: '4EAC' },
  { key: '4ACD', text: 'Configuration Design, Unmanned Vehicles', value: '4ACD' },
  { key: '2GDC', text: 'Consciousness', value: '2GDC' },
  { key: '8HXA', text: 'Constants Standards Metrology', value: '8HXA' },
  { key: '9EEA', text: 'Constitutional Law--Political Science', value: '9EEA' },
  { key: '5ACG', text: 'Constitutional Law--Public Law', value: '5ACG' },
  { key: '9GBY', text: 'Constitutional and Administrative Law', value: '9GBY' },
  { key: '4HBY', text: 'Construction Engineering', value: '4HBY' },
  { key: '4VEG', text: 'Construction Inspection Technology', value: '4VEG' },
  { key: '4HBB', text: 'Construction Management', value: '4HBB' },
  { key: '1AJF', text: 'Construction Management', value: '1AJF' },
  { key: '4VEH', text: 'Construction Technology', value: '4VEH' },
  { key: '4VEB', text: 'Construction Trades Technology and Building Construction Technology', value: '4VEB' },
  { key: '9HCC', text: 'Consumer Behavior', value: '9HCC' },
  { key: '9BGB', text: 'Consumer Finance and Mortgage Credit', value: '9BGB' },
  { key: '2GBC', text: 'Contemporary Metaphysics', value: '2GBC' },
  { key: '2GBY', text: 'Contemporary Philosophy', value: '2GBY' },
  { key: '1AMH', text: 'Contracting (AFIT)', value: '1AMH' },
  { key: '1ACC', text: 'Contracting Management', value: '1ACC' },
  { key: '1AEC', text: 'Contracts Law', value: '1AEC' },
  { key: '1CAO', text: 'Contracts Management', value: '1CAO' },
  { key: '6EFY', text: 'Control Systems (Mathematics)', value: '6EFY' },
  { key: '4TAY', text: 'Control Systems (Systems Engineering)', value: '4TAY' },
  { key: '4MHB', text: 'Control Systems Design', value: '4MHB' },
  { key: '4RDA', text: 'Control systems (Systems Safety)', value: '4RDA' },
  { key: '4LCA', text: 'Controls and Placement', value: '4LCA' },
  { key: '8FED', text: 'Convective Meteorology', value: '8FED' },
  { key: '6CDY', text: 'Convex Domains Extremum Problems (Mathematics)', value: '6CDY' },
  { key: '6BCY', text: 'Convexity Inequalities (Mathematics)', value: '6BCY' },
  { key: '1ANB', text: 'Cooperative Marketing', value: '1ANB' },
  { key: '8HOB', text: 'Cooperative Phenomena', value: '8HOB' },
  { key: '8CEG', text: 'Coordination Compounds', value: '8CEG' },
  { key: '5ABE', text: 'Copyright Law', value: '5ABE' },
  { key: '1AHA', text: 'Corporation Finance', value: '1AHA' },
  { key: '5ABF', text: 'Corporations Law', value: '5ABF' },
  { key: '9HAA', text: 'Correctional Administration', value: '9HAA' },
  { key: '9HHA', text: 'Corrections', value: '9HHA' },
  { key: '4GAY', text: 'Corrosion and Preservation', value: '4GAY' },
  { key: '9IDY', text: 'Cosmetology', value: '9IDY' },
  { key: '8HGA', text: 'Cosmic Rays', value: '8HGA' },
  { key: '8DAA', text: 'Cosmochemistry', value: '8DAA' },
  { key: '8AAE', text: 'Cosmogony', value: '8AAE' },
  { key: '8AAF', text: 'Cosmology (Astronomy)', value: '8AAF' },
  { key: '2GJA', text: 'Cosmology (Philosophy)', value: '2GJA' },
  { key: '1AAC', text: 'Cost Accounting', value: '1AAC' },
  { key: '1AKA', text: 'Cost Administration', value: '1AKA' },
  { key: '1ASA', text: 'Cost Analysis', value: '1ASA' },
  { key: '2AAY', text: 'Counseling and Guidance--Chaplaincy', value: '2AAY' },
  { key: '2BCY', text: 'Counseling and Guidance--Educational', value: '2BCY' },
  { key: '9FBY', text: 'Counseling and Guidance--Psychology', value: '9FBY' },
  { key: '9EAB', text: 'Country Specialization', value: '9EAB' },
  { key: '4GBB', text: 'Cracking Equipment', value: '4GBB' },
  { key: '2CKD', text: 'Creative Sculpture', value: '2CKD' },
  { key: '2IAE', text: 'Creative Writing', value: '2IAE' },
  { key: '9FAB', text: 'Crime and Delinquency--Clinical Psychology', value: '9FAB' },
  { key: '9HHB', text: 'Crime and Delinquency--Social Problems', value: '9HHB' },
  { key: '9HAB', text: 'Criminal Anthropology', value: '9HAB' },
  { key: '9HAC', text: 'Criminal Investigation', value: '9HAC' },
  { key: '5ACH', text: 'Criminal Law and Procedures', value: '5ACH' },
  { key: '9HAD', text: 'Criminal Psychology (Criminology and Police Administration)', value: '9HAD' },
  { key: '2GDD', text: 'Criminal Psychology (Psychology)', value: '2GDD' },
  { key: '9HAY', text: 'Criminology and Police Administration', value: '9HAY' },
  { key: 'Z035', text: 'Critical Care Medicine', value: 'Z035' },
  { key: '2GEA', text: 'Critique of Empirical Knowledge', value: '2GEA' },
  { key: '2GEB', text: 'Critique of Essential Knowledge', value: '2GEB' },
  { key: '3BCB', text: 'Crop Management', value: '3BCB' },
  { key: '3BCY', text: 'Crop Science', value: '3BCY' },
  { key: '3BCA', text: 'Crop, Breeding, Hybridization', value: '3BCA' },
  { key: '7BAA', text: 'Crown and Bridge', value: '7BAA' },
  { key: '8EAB', text: 'Cryology and Cryopedology', value: '8EAB' },
  { key: '6EGY', text: 'Cryptography', value: '6EGY' },
  { key: '4GCC', text: 'Crystallization', value: '4GCC' },
  { key: '8HOC', text: 'Crystallography', value: '8HOC' },
  { key: 'Z012', text: 'Culinary Arts', value: 'Z012' },
  { key: '9ACY', text: 'Cultural Anthropology', value: '9ACY' },
  { key: '9ACA', text: 'Cultural Dynamics', value: '9ACA' },
  { key: '9CBY', text: 'Cultural Geography', value: '9CBY' },
  { key: '2GBD', text: 'Cultural Idealism (Italian)', value: '2GBD' },
  { key: '2GCY', text: 'Cultural Philosophies', value: '2GCY' },
  { key: '9HEA', text: 'Cultural Sociology', value: '9HEA' },
  { key: '9FJA', text: 'Culture and Personality', value: '9FJA' },
  { key: '2BDY', text: 'Curriculum Development', value: '2BDY' },
  { key: '2BDB', text: 'Curriculum Research', value: '2BDB' },
  { key: 'Z060', text: 'Cyber Forensics and Information Security', value: 'Z060' },
  { key: 'Z057', text: 'Cyber security / System Administration', value: 'Z057' },
  { key: '4TBY', text: 'Cybernetics', value: '4TBY' },
  { key: '3ACG', text: 'Cyto-Histo-Chemistry--Biology', value: '3ACG' },
  { key: '8CCG', text: 'Cyto-Histo-Chemistry--Chemistry', value: '8CCG' },
  { key: '0YBG', text: 'Cyto-Histo-Chemistry--Inter-Area Specialization', value: '0YBG' },
  { key: '3AMB', text: 'Cytogenetics', value: '3AMB' },
  { key: '3AJY', text: 'Cytology, Cytotechnology and Cytology Technician--Biology', value: '3AJY' },
  { key: '7GAC', text: 'Cytology, Cytotechnology and Cytology Technician--Biomedical Sciences   Technology', value: '7GAC' },
  { key: '3ARB', text: 'Cytopathology', value: '3ARB' },
  { key: '0CCC', text: 'DATA PROC OPNS RES', value: '0CCC' },
  { key: '7GBX', text: 'DENTISTRY-TECH-OTHER', value: '7GBX' },
  { key: '0CDC', text: 'DIGITAL CMPTRSL/D. Digital Computers, Logic And Design (Mathematics)', value: '0CDC' },
  { key: '7BJY', text: 'DNTL SCI-DENTAL RADIOLOGY', value: '7BJY' },
  { key: '7BJM', text: 'DNTL SCI-DENTAL RADIOLOGY MASTERS', value: '7BJM' },
  { key: '7BGM', text: 'DNTL SCI-ENDODONTIC MASTERS', value: '7BGM' },
  { key: '7BAM', text: 'DNTL SCI-GEN DNTY-MASTERS', value: '7BAM' },
  { key: '7BAX', text: 'DNTL SCI-GEN DNTY-OTHER', value: '7BAX' },
  { key: '7BBM', text: 'DNTL SCI-ORAL PATHOLOGY MASTERS', value: '7BBM' },
  { key: '7BCX', text: 'DNTL SCI-ORAL SURGERY-OTHER', value: '7BCX' },
  { key: '7BDM', text: 'DNTL SCI-ORTHODONTICS MASTERS', value: '7BDM' },
  { key: '7BXY', text: 'DNTL SCI-OTHER', value: '7BXY' },
  { key: '7BKY', text: 'DNTL SCI-PEDIATRIC DENTISTRY', value: '7BKY' },
  { key: '7BKM', text: 'DNTL SCI-PEDIATRIC DENTISTRY MASTERS', value: '7BKM' },
  { key: '7BEM', text: 'DNTL SCI-PERIODONTICS MASTERS', value: '7BEM' },
  { key: '7BFM', text: 'DNTL SCI-PROSTHODONTICS MASTERS', value: '7BFM' },
  { key: '7BFX', text: 'DNTL SCI-PROSTHODONTICS-OTHER', value: '7BFX' },
  { key: '7BIM', text: 'DNTL SCI-PUBLIC HEALTH MASTERS', value: '7BIM' },
  { key: '1CBX', text: 'DSTRB-SVC-OCC-TECH OTHER', value: '1CBX' },
  { key: '2DYC', text: 'DUTCH', value: '2DYC' },
  { key: '1CAG', text: 'Data Processing and Computer Science--Occupational Technology', value: '1CAG' },
  { key: '4EDB', text: 'Data Transmission (Astronautical Engineering)', value: '4EDB' },
  { key: '4WCY', text: 'Database Systems', value: '4WCY' },
  { key: '2IFE', text: 'Deaconess', value: '2IFE' },
  { key: '9GDC', text: 'Decision Making', value: '9GDC' },
  { key: '6IBY', text: 'Decision Theory, Sequential Analysis--Mathematics', value: '6IBY' },
  { key: '4TCY', text: 'Decision Theory, Sequential Analysis--Systems Engineering', value: '4TCY' },
  { key: '2CCY', text: 'Decorative Arts and Crafts', value: '2CCY' },
  { key: '9ECA', text: 'Defense Policy', value: '9ECA' },
  { key: '9HBY', text: 'Demography', value: '9HBY' },
  { key: '7GBC', text: 'Dental Assisting and Dental Specialist', value: '7GBC' },
  { key: '7BIY', text: 'Dental Bio Materials', value: '7BIY' },
  { key: '7GBD', text: 'Dental Hygiene and Preventive Dentistry Specialist', value: '7GBD' },
  { key: '7GBB', text: 'Dental Laboratory Technology', value: '7GBB' },
  { key: '7BHY', text: 'Dental Materials', value: '7BHY' },
  { key: '7BYY', text: 'Dental Science', value: '7BYY' },
  { key: 'Z019', text: 'Dental Surgery', value: 'Z019' },
  { key: '1CAH', text: 'Dental and Medical Secretary', value: '1CAH' },
  { key: '7GBY', text: 'Dentistry Technology', value: '7GBY' },
  { key: '4LBB', text: 'Depreciation Techniques', value: '4LBB' },
  { key: '7DCY', text: 'Dermatology', value: '7DCY' },
  { key: '8EBC', text: 'Descriptive Oceanography', value: '8EBC' },
  { key: '4ACY', text: 'Design', value: '4ACY' },
  { key: '8BAB', text: 'Design Cartography', value: '8BAB' },
  { key: '4EEB', text: 'Design of Power Plants (Astronautical Engineering)', value: '4EEB' },
  { key: '4PAY', text: 'Design of Structures (Naval Architecture)', value: '4PAY' },
  { key: '4RAA', text: 'Design of Structures (Safety Engineering)', value: '4RAA' },
  { key: '4ACE', text: 'Detail Design (Aeronautical Engineering)', value: '4ACE' },
  { key: '8HLB', text: 'Detectors (Physics)', value: '8HLB' },
  { key: '9BAA', text: 'Development Economics', value: '9BAA' },
  { key: '2GDE', text: 'Developmental Psychology', value: '2GDE' },
  { key: '9HHC', text: 'Deviance', value: '9HHC' },
  { key: '7DUA', text: 'Diagnostic Radiology', value: '7DUA' },
  { key: '2GBE', text: 'Dialectical Materialism', value: '2GBE' },
  { key: '8HOD', text: 'Dielectics (incl Fluids)', value: '8HOD' },
  { key: '2CEC', text: 'Dietetics (Industrial Management)', value: '2CEC' },
  { key: '7ABF', text: 'Dietetics (Professionsl Services)', value: '7ABF' },
  { key: '6BDY', text: 'Difference and Functional Equations--Analysis And Functional Analysis', value: '6BDY' },
  { key: '6GCY', text: 'Difference and Functional Equations--Numerical Methods And Computation', value: '6GCY' },
  { key: '6ACY', text: 'Differential Algebra', value: '6ACY' },
  { key: '6CEY', text: 'Differential Geometry, Tensor Analysis', value: '6CEY' },
  { key: '4IDC', text: 'Digital Avionics Systems', value: '4IDC' },
  { key: '0CBB', text: 'Digital Computer Design (Engineering)', value: '0CBB' },
  { key: '0CDB', text: 'Digital Computers, Coding, And Programming (Mathematics)', value: '0CDB' },
  { key: '0CBC', text: 'Digital Data Systems', value: '0CBC' },
  { key: '4VFA', text: 'Digital Equipment Technology', value: '4VFA' },
  { key: '4IDA', text: 'Digital System Development and Evaluation', value: '4IDA' },
  { key: '4IDB', text: 'Digital Telecommunication Systems', value: '4IDB' },
  { key: '6FCY', text: 'Diophantine Approximation (Mathematics)', value: '6FCY' },
  { key: '9FBA', text: 'Directive Therapy', value: '9FBA' },
  { key: '9IMY', text: 'Disaster Preparedness Technology', value: '9IMY' },
  { key: '8HOE', text: 'Dislocations and Plasticity', value: '8HOE' },
  { key: '1CBB', text: 'Distribution Technology', value: '1CBB' },
  { key: '1CBY', text: 'Distributive Services Occupational Technologies', value: '1CBY' },
  { key: 'Z014', text: 'Doctor of Dentistry', value: 'Z014' },
  { key: 'Z020', text: 'Doctor of Optometry', value: 'Z020' },
  { key: 'Z021', text: 'Doctor of Pharmacy', value: 'Z021' },
  { key: 'Z025', text: 'Doctor of Social Work', value: 'Z025' },
  { key: '2HCB', text: 'Dogmatics', value: '2HCB' },
  { key: '5ABG', text: 'Domestic Relations Law', value: '5ABG' },
  { key: '2FFB', text: 'Drama', value: '2FFB' },
  { key: '2CJB', text: 'Drama and Theater', value: '2CJB' },
  { key: '2CDA', text: 'Drawing', value: '2CDA' },
  { key: '2CAC', text: 'Drawing and Creative Design', value: '2CAC' },
  { key: '3ASC', text: 'Drug Enzymology', value: '3ASC' },
  { key: '6EHY', text: 'Dynamic Programming', value: '6EHY' },
  { key: '8HOF', text: 'Dynamics of Crystal Lattices', value: '8HOF' },
  { key: '4MBA', text: 'Dynamics of Machinery', value: '4MBA' },
  { key: '4KAA', text: 'Dynamics of Structures', value: '4KAA' },
  { key: '4KAY', text: 'Dynamics--Engineering Sciences', value: '4KAY' },
  { key: '4MBY', text: 'Dynamics--Mechanical Engineering', value: '4MBY' },
  { key: '8DFX', text: 'EAR SCI PHYS GEO OTHER', value: '8DFX' },
  { key: '8DGX', text: 'EAR SCI SOIL SCI OTHER', value: '8DGX' },
  { key: '8DAX', text: 'EARTH SCI GEOCHEM OTHER', value: '8DAX' },
  { key: '8DBX', text: 'EARTH SCI GEODESY OTHER', value: '8DBX' },
  { key: '8DBF', text: 'EARTH SCI GEODESY SELENOLOG', value: '8DBF' },
  { key: '8DCB', text: 'EARTH SCI GEOLOGY CRYSTALL', value: '8DCB' },
  { key: '8DDX', text: 'EARTH SCI GEOPHYSICS OTHER', value: '8DDX' },
  { key: '8DXY', text: 'EARTH SCI OTHER', value: '8DXY' },
  { key: '8DEX', text: 'EARTH SCI PAL OTHER', value: '8DEX' },
  { key: '8DCX', text: 'EARTH SCIENCE GEOLOGY OTHER', value: '8DCX' },
  { key: '4ZYY', text: 'EARTH SCIENCES ENG', value: '4ZYY' },
  { key: '9BJY', text: 'ECON  QUANTITATIVE', value: '9BJY' },
  { key: '9CBX', text: 'ECON CULT  OTHER', value: '9CBX' },
  { key: '9CCC', text: 'ECON ECON  MARKETING', value: '9CCC' },
  { key: '9CCX', text: 'ECON ECON  OTHER', value: '9CCX' },
  { key: '9BAX', text: 'ECON HST-DEV OTHER', value: '9BAX' },
  { key: '9BCX', text: 'ECON INDUS OTHER', value: '9BCX' },
  { key: '9BDX', text: 'ECON INTNL ECON OTHER', value: '9BDX' },
  { key: '9BDB', text: 'ECON INTNL ECON RELATIONS', value: '9BDB' },
  { key: '9BEY', text: 'ECON LABOR ECONOMICS', value: '9BEY' },
  { key: '9BEX', text: 'ECON LABOR OTHER', value: '9BEX' },
  { key: '9BFX', text: 'ECON LAND OTHER', value: '9BFX' },
  { key: '9BGX', text: 'ECON MNY/CR/BKG OTHER', value: '9BGX' },
  { key: '9BIX', text: 'ECON PUB FIN OTHER', value: '9BIX' },
  { key: '9BJA', text: 'ECON QUANT ECONOMETRICS', value: '9BJA' },
  { key: '9BJX', text: 'ECON QUANT OTHER', value: '9BJX' },
  { key: '9BBX', text: 'ECON THEORY OTHER', value: '9BBX' },
  { key: '9BXY', text: 'ECONOMICS  OTHER', value: '9BXY' },
  { key: '0CAA', text: 'EDP (ACCTG)', value: '0CAA' },
  { key: '0CAB', text: 'EDP SYSTEM. Automated Data Processing Systems--(EDP Systems)', value: '0CAB' },
  { key: '2BAX', text: 'EDUC ADMT FUN OTHER', value: '2BAX' },
  { key: '2BBX', text: 'EDUC COLL TEACH OTHER', value: '2BBX' },
  { key: '2BCX', text: 'EDUC COUN GUID OTHER', value: '2BCX' },
  { key: '2BDX', text: 'EDUC CURR DEVL OTHER', value: '2BDX' },
  { key: '2BEX', text: 'EDUC ED PSYCH OTHER', value: '2BEX' },
  { key: '2BIX', text: 'EDUC SEC TEACH OTHER', value: '2BIX' },
  { key: '2BJX', text: 'EDUC SP TEAC TNG OTHER', value: '2BJX' },
  { key: '2IBX', text: 'EDUC-EXCL-REL-TECH OTHER', value: '2IBX' },
  { key: '2BXY', text: 'EDUCATION  OTHER', value: '2BXY' },
  { key: '2BMY', text: 'EDUCATION/FOREIGN LANGUAGE', value: '2BMY' },
  { key: '4ICX', text: 'ELEC ENG E-DVC OTHER', value: '4ICX' },
  { key: '4IEX', text: 'ELEC ENG EGY CON/DIS OTHER', value: '4IEX' },
  { key: '4IGX', text: 'ELEC ENG INFO/SYS OTHER', value: '4IGX' },
  { key: '4IHX', text: 'ELEC ENG LMPD PAR OTHER', value: '4IHX' },
  { key: '4IBA', text: 'ELEC ENG WAVES/PAR SYS ACOU', value: '4IBA' },
  { key: '4IBX', text: 'ELEC ENG WAVES/PAR SYS OTH', value: '4IBX' },
  { key: '4IXY', text: 'ELEC/ENG  OTHER', value: '4IXY' },
  { key: '4IAB', text: 'ELEC/ENG AVN AP FLT CTL SYS', value: '4IAB' },
  { key: '4IDX', text: 'ELEC/ENG DIGITAL ENG OTHER', value: '4IDX' },
  { key: '0CBX', text: 'ELECT COMPUTATION OTHER', value: '0CBX' },
  { key: '4VGX', text: 'ELECTRICAL TECH-OTHER', value: '4VGX' },
  { key: '4VHX', text: 'ELECTRONICS ENG-TECH-OTHER', value: '4VHX' },
  { key: '7DGI', text: 'EMERGENCY MEDICINE', value: '7DGI' },
  { key: '1EDM', text: 'EMERGENCY/DISASTER MANAGEMENT', value: '1EDM' },
  { key: '1AGE', text: 'ENG AND ENVIRONMENTAL MGT', value: '1AGE' },
  { key: '4KAX', text: 'ENG SCI DYNAMICS OTHER', value: '4KAX' },
  { key: '4KCX', text: 'ENG SCI ELAS PLAST OTHER', value: '4KCX' },
  { key: '4KBX', text: 'ENG SCI FLD MECH OTHER', value: '4KBX' },
  { key: '4KXY', text: 'ENG SCI OTHER', value: '4KXY' },
  { key: '4VIX', text: 'ENGINEERING TECH-GEN-OTHER', value: '4VIX' },
  { key: '4HEY', text: 'ENVIRONMENTAL ENGINEER', value: '4HEY' },
  { key: '5EAY', text: 'ENVIRONMENTAL LAW', value: '5EAY' },
  { key: '0YOY', text: 'ENVIRONMENTAL SCIENCES', value: '0YOY' },
  { key: '7AEA', text: 'EXERCISE  AND  SPORT SCIENCE', value: '7AEA' },
  { key: '7AEY', text: 'EXERCISE SCIENCE', value: '7AEY' },
  { key: '7AEX', text: 'EXERCISE SCIENCE  OTHER', value: '7AEX' },
  { key: '8HAC', text: 'Ear and Hearing', value: '8HAC' },
  { key: '2HDA', text: 'Early Christianity', value: '2HDA' },
  { key: '8DYY', text: 'Earth Sciences', value: '8DYY' },
  { key: '9DAA', text: 'East Central Africa (History)', value: '9DAA' },
  { key: '9DCY', text: 'Eastern Europe and the Balkans (History)', value: '9DCY' },
  { key: '2HBY', text: 'Eastern Religions', value: '2HBY' },
  { key: '3AKY', text: 'Ecological Controls', value: '3AKY' },
  { key: '6EIY', text: 'Econometrics--Mathematics of Resource Use', value: '6EIY' },
  { key: '4TDY', text: 'Econometrics--Systems Engineering', value: '4TDY' },
  { key: '9HAN', text: 'Economic Crime', value: '9HAN' },
  { key: '9HEB', text: 'Economic Development', value: '9HEB' },
  { key: '1ADC', text: 'Economic Geography--Business Economics', value: '1ADC' },
  { key: '9CCY', text: 'Economic Geography--Geography', value: '9CCY' },
  { key: '9BFB', text: 'Economic Geography--Land Economics', value: '9BFB' },
  { key: '9BAY', text: 'Economic History and Development', value: '9BAY' },
  { key: '4LBC', text: 'Economic Lot Size Determination', value: '4LBC' },
  { key: '9BDA', text: 'Economic Problems (Area Studies)', value: '9BDA' },
  { key: '9BBY', text: 'Economic Theory', value: '9BBY' },
  { key: '9BYY', text: 'Economics', value: '9BYY' },
  { key: '1ADD', text: 'Economics and Natural Resources', value: '1ADD' },
  { key: '9BCB', text: 'Economics of Industry (Specific Industry)', value: '9BCB' },
  { key: '9DIF', text: 'Ecuador (History)', value: '9DIF' },
  { key: '2BYY', text: 'Education', value: '2BYY' },
  { key: '2IBY', text: 'Education (Excluding Religion)-Technology', value: '2IBY' },
  { key: '9FBB', text: 'Education Counseling', value: '9FBB' },
  { key: '2BLY', text: 'Education Specialist - Terminal Decree', value: '2BLY' },
  { key: '1AOA', text: 'Education and Training', value: '1AOA' },
  { key: '2BAC', text: 'Educational Administration Func, Ed Adm/Mgt', value: '2BAC' },
  { key: '2BCA', text: 'Educational Guidance', value: '2BCA' },
  { key: '2BEC', text: 'Educational Measurement', value: '2BEC' },
  { key: '2BEY', text: 'Educational Psychology--Education', value: '2BEY' },
  { key: '9FDY', text: 'Educational Psychology--Psychology', value: '9FDY' },
  { key: '9HFA', text: 'Educational Sociology', value: '9HFA' },
  { key: '4RCA', text: 'Educational Techniques', value: '4RCA' },
  { key: '2BKY', text: 'Educational Technology', value: '2BKY' },
  { key: '6GFY', text: 'Eigenvalues, Rayleigh-Ritz Method (Mathematics)', value: '6GFY' },
  { key: '4KCA', text: 'Elastic Stability', value: '4KCA' },
  { key: '8HIC', text: 'Elasticity', value: '8HIC' },
  { key: '8CGF', text: 'Elastomers and Related Products', value: '8CGF' },
  { key: '4IDY', text: 'Elec Eng/Digital', value: '4IDY' },
  { key: '4GCD', text: 'Elecrochemical Operations', value: '4GCD' },
  { key: '4VGA', text: 'Electric Power Tech, Mechanical and Electrical Civil Engineer  Tech, and System and Electrical Tech', value: '4VGA' },
  { key: '4CBY', text: 'Electric Power and Processing (Agricultural Engineering)', value: '4CBY' },
  { key: '4FBE', text: 'Electrical Ceramics', value: '4FBE' },
  { key: '4IYY', text: 'Electrical Engineering', value: '4IYY' },
  { key: '4IMT', text: 'Electrical Engineering - Space Systems', value: '4IMT' },
  { key: '8HEB', text: 'Electrical Measurements and Instruments', value: '8HEB' },
  { key: '4DBY', text: 'Electrical Systems', value: '4DBY' },
  { key: '4VGY', text: 'Electrical Technology', value: '4VGY' },
  { key: '4IJY', text: 'Electro-Optics', value: '4IJY' },
  { key: '8HAD', text: 'Electroacoustics', value: '8HAD' },
  { key: '4IEA', text: 'Electrochemical Devices', value: '4IEA' },
  { key: '8CHD', text: 'Electrochemistry', value: '8CHD' },
  { key: '4IBC', text: 'Electromagnetic Field Theory', value: '4IBC' },
  { key: '8HED', text: 'Electromagnetic Wave Propagation', value: '8HED' },
  { key: '8HEC', text: 'Electromagnetic Waves', value: '8HEC' },
  { key: '4IBY', text: 'Electromagnetic Waves and Distributed Parameter Systems', value: '4IBY' },
  { key: '8HEY', text: 'Electromagnetism', value: '8HEY' },
  { key: '4IEB', text: 'Electromechanical Devices', value: '4IEB' },
  { key: '4NAA', text: 'Electrometallurgical Extraction', value: '4NAA' },
  { key: '8CBD', text: 'Electrometric Analysis', value: '8CBD' },
  { key: '8HFA', text: 'Electron Ballistics', value: '8HFA' },
  { key: '8HEE', text: 'Electron Dynamics', value: '8HEE' },
  { key: '8HOG', text: 'Electron Emission', value: '8HOG' },
  { key: '8HEF', text: 'Electron Microscopy, Ion Optics', value: '8HEF' },
  { key: '3AGK', text: 'Electron Microscopy--Biology', value: '3AGK' },
  { key: '8HDK', text: 'Electron Microscopy--Physics', value: '8HDK' },
  { key: '8HCE', text: 'Electron Paramagnetic Resonance', value: '8HCE' },
  { key: '8HFB', text: 'Electron Tubes', value: '8HFB' },
  { key: '4ICY', text: 'Electronic Circuits and Devices', value: '4ICY' },
  { key: '0CBY', text: 'Electronic Computation (EDP)', value: '0CBY' },
  { key: '4VFB', text: 'Electronic Computer Technology', value: '4VFB' },
  { key: '8HFC', text: 'Electronic Device Circuitry', value: '8HFC' },
  { key: '8CEH', text: 'Electronic Materials--Semiconductors, Ferroelectrics, Ferromagnetics', value: '8CEH' },
  { key: '4VHP', text: 'Electronic Systems Technology', value: '4VHP' },
  { key: '0YNY', text: 'Electronic Warfare Systems Technology', value: '0YNY' },
  { key: '4ISB', text: 'Electronic/Eng Circuits and Devices', value: '4ISB' },
  { key: '8HFY', text: 'Electronics', value: '8HFY' },
  { key: '2IAF', text: 'Electronics Countermeasures', value: '2IAF' },
  { key: '4IBD', text: 'Electronics Countermeasures-Electromagnetic Waves and Distributed Parameter  Systems', value: '4IBD' },
  { key: '4VHY', text: 'Electronics Engineering Technology', value: '4VHY' },
  { key: '8HFD', text: 'Electronics Instrumentation', value: '8HFD' },
  { key: '2JDY', text: 'Electronics Technology', value: '2JDY' },
  { key: 'Z007', text: 'Electronics and Communications Engineering', value: 'Z007' },
  { key: '4IEC', text: 'Electrothermal Devices', value: '4IEC' },
  { key: '6FDY', text: 'Elementary Number Theory (Mathematics)', value: '6FDY' },
  { key: '8HGY', text: 'Elementary Particle Physics', value: '8HGY' },
  { key: '2BFY', text: 'Elementary Teaching', value: '2BFY' },
  { key: '0CEA', text: 'Embedded Software', value: '0CEA' },
  { key: '7GDE', text: 'Emergency Medical Technician and Emergency Medical Technology', value: '7GDE' },
  { key: '8HFE', text: 'Emission', value: '8HFE' },
  { key: '8CBE', text: 'Emission Spectroscopy', value: '8CBE' },
  { key: '2GLA', text: 'Emotions', value: '2GLA' },
  { key: '2GDY', text: 'Empirical Psychology', value: '2GDY' },
  { key: '9FFA', text: 'Employee Morale and Attitudes', value: '9FFA' },
  { key: '1AOB', text: 'Employee Services', value: '1AOB' },
  { key: '3ACH', text: 'Endocrine Biochemistry--Biology', value: '3ACH' },
  { key: '8CCH', text: 'Endocrine Biochemistry--Chemistry', value: '8CCH' },
  { key: '0YBH', text: 'Endocrine Biochemistry--Inter-Area Specialization', value: '0YBH' },
  { key: '7DGC', text: 'Endocrinology', value: '7DGC' },
  { key: '7BGY', text: 'Endodontics', value: '7BGY' },
  { key: '4IEY', text: 'Energy Conversion Distribution', value: '4IEY' },
  { key: '8HXB', text: 'Energy Conversion Problems', value: '8HXB' },
  { key: '8FCD', text: 'Energy Sensing Instrumentation', value: '8FCD' },
  { key: '4MHC', text: 'Engine Design', value: '4MHC' },
  { key: '2BBD', text: 'Engineering (College Teaching)', value: '2BBD' },
  { key: '4YYY', text: 'Engineering (General Area of Study)', value: '4YYY' },
  { key: '4LBY', text: 'Engineering Economics', value: '4LBY' },
  { key: '8DCC', text: 'Engineering Geology', value: '8DCC' },
  { key: '1AGY', text: 'Engineering Management', value: '1AGY' },
  { key: '4KAB', text: 'Engineering Mechanics', value: '4KAB' },
  { key: '4NCA', text: 'Engineering Physical Metallurgy', value: '4NCA' },
  { key: '4KDY', text: 'Engineering Physiscs', value: '4KDY' },
  { key: '9FEF', text: 'Engineering Psychology (Human Performance Engineering)', value: '9FEF' },
  { key: '4KYY', text: 'Engineering Sciences', value: '4KYY' },
  { key: '4VYY', text: 'Engineering Technologies', value: '4VYY' },
  { key: '4VIY', text: 'Engineering Technology (General)', value: '4VIY' },
  { key: '4MIC', text: 'Engineering Thermodynamics', value: '4MIC' },
  { key: '4JYY', text: 'Engineering, General', value: '4JYY' },
  { key: '2BIC', text: 'English', value: '2BIC' },
  { key: '2FAA', text: 'English Composition', value: '2FAA' },
  { key: '2FAB', text: 'English Grammar', value: '2FAB' },
  { key: '2FAY', text: 'English Language', value: '2FAY' },
  { key: '2FCE', text: 'English Literature', value: '2FCE' },
  { key: '3ALY', text: 'Entomology', value: '3ALY' },
  { key: '4IFY', text: 'Environic Engineering', value: '4IFY' },
  { key: '4HCY', text: 'Environic Engineering--Civil Engineering', value: '4HCY' },
  { key: '4MCY', text: 'Environic Engineering--Mechanical Engineering', value: '4MCY' },
  { key: '4EDC', text: 'Environment Simulation (Astronautical Engineering)', value: '4EDC' },
  { key: 'Z038', text: 'Environmental', value: 'Z038' },
  { key: '4LCB', text: 'Environmental Effects', value: '4LCB' },
  { key: 'Z039', text: 'Environmental Engineering', value: 'Z039' },
  { key: '7GCA', text: 'Environmental Health Technology', value: '7GCA' },
  { key: '7GAO', text: 'Environmental Medicine Technology Text', value: '7GAO' },
  { key: 'Z040', text: 'Environmental Science', value: 'Z040' },
  { key: '9IEY', text: 'Environmental Services Technology and Environmental Control', value: '9IEY' },
  { key: '4VAJ', text: 'Environmental and Ejection Systems Maintenance', value: '4VAJ' },
  { key: '3ACI', text: 'Enzyme, Co-Enzyme--Biology', value: '3ACI' },
  { key: '8CCI', text: 'Enzyme, Co-Enzyme--Chemistry', value: '8CCI' },
  { key: '0YBI', text: 'Enzyme, Co-Enzyme--Inter-Area Specialization', value: '0YBI' },
  { key: '2GEY', text: 'Epistemology', value: '2GEY' },
  { key: '0YYZ', text: 'Equine Science', value: '0YYZ' },
  { key: '4GBY', text: 'Equipment Design', value: '4GBY' },
  { key: '3BEA', text: 'Erosion Control', value: '3BEA' },
  { key: '4LCC', text: 'Error Analysis--Human Factors in Engineering', value: '4LCC' },
  { key: '6GGY', text: 'Error Analysis--Numerical Methods and Computations', value: '6GGY' },
  { key: '2GIB', text: 'Essence and Existence', value: '2GIB' },
  { key: '2GAA', text: 'Essence of Beauty', value: '2GAA' },
  { key: '2GEC', text: 'Essence of Truth', value: '2GEC' },
  { key: '6IDY', text: 'Estimation and Testing, Parametric', value: '6IDY' },
  { key: '9DKD', text: 'Estonian SSR (History)', value: '9DKD' },
  { key: '2GFY', text: 'Ethics', value: '2GFY' },
  { key: '9ACB', text: 'Ethnography', value: '9ACB' },
  { key: '9ACC', text: 'Ethnology', value: '9ACC' },
  { key: '6CFY', text: 'Euclidean Geometry', value: '6CFY' },
  { key: '8EAC', text: 'Evapo-Transpiration', value: '8EAC' },
  { key: '4GCE', text: 'Evaporation', value: '4GCE' },
  { key: '9HAF', text: 'Evidence Identification', value: '9HAF' },
  { key: '2BED', text: 'Exceptional Child', value: '2BED' },
  { key: '1CAI', text: 'Executive Secretarial Science', value: '1CAI' },
  { key: 'Z015', text: 'Exercise Physiology', value: 'Z015' },
  { key: '77ES', text: 'Exercise Sciences', value: '77ES' },
  { key: '2GDF', text: 'Existential Psychology', value: '2GDF' },
  { key: '2GBF', text: 'Existentialism (French)', value: '2GBF' },
  { key: '2GBG', text: 'Existentialism (General)', value: '2GBG' },
  { key: '7GBA', text: 'Expanded Duty Dental Assistance and Dental Assistant', value: '7GBA' },
  { key: '9FIA', text: 'Experimental Design', value: '9FIA' },
  { key: '4MHD', text: 'Experimental Methods in Design', value: '4MHD' },
  { key: '3ARC', text: 'Experimental Pathology', value: '3ARC' },
  { key: '9FEY', text: 'Experimental Psychology', value: '9FEY' },
  { key: '9FAC', text: 'Experimental Psychopathology', value: '9FAC' },
  { key: '3ASD', text: 'Experimental Therapeutics, Clinical', value: '3ASD' },
  { key: '8DDA', text: 'Exploration Seismology', value: '8DDA' },
  { key: '8HNF', text: 'Explosion Phenomena', value: '8HNF' },
  { key: '4VRC', text: 'Explosive Ordnance Disposal', value: '4VRC' },
  { key: '8CEI', text: 'Explosives and Rocket Fuels--Inorganic Chemistry', value: '8CEI' },
  { key: '8CGG', text: 'Explosives and Rocket Fuels--Organic Chemistry', value: '8CGG' },
  { key: '4NAY', text: 'Extraction', value: '4NAY' },
  { key: '8CEJ', text: 'Extranuclear Structure', value: '8CEJ' },
  { key: '2CAX', text: 'F  AND  A ARTS ARCH OTHER', value: '2CAX' },
  { key: '2CBX', text: 'F AND A ART COMM ART OTHER', value: '2CBX' },
  { key: '2CCX', text: 'F AND A ART DECOR OTHER', value: '2CCX' },
  { key: '2CDX', text: 'F AND A ART GRAP ART OTHER', value: '2CDX' },
  { key: '2CEX', text: 'F AND A ART H-ECOM OTHER', value: '2CEX' },
  { key: '2CFF', text: 'F AND A ART IND DSGN LANDSCAPE', value: '2CFF' },
  { key: '2CFX', text: 'F AND A ART IND DSGN OTHER', value: '2CFX' },
  { key: '2CGX', text: 'F AND A ART LIB SC OTHER', value: '2CGX' },
  { key: '2CHX', text: 'F AND A ART MUSIC OTHER', value: '2CHX' },
  { key: '2CIX', text: 'F AND A ART PAINT OTHER', value: '2CIX' },
  { key: '2CJX', text: 'F AND A ART PER ARTS OTHER', value: '2CJX' },
  { key: '2CKX', text: 'F AND A ART SCULPT OTHER', value: '2CKX' },
  { key: '2CXY', text: 'F+A ART OTHER', value: '2CXY' },
  { key: '7EBG', text: 'FAMILY NURSE PRACTITIONER', value: '7EBG' },
  { key: '1FRS', text: 'FIT  REC  AND SVC MGT', value: '1FRS' },
  { key: '2DYY', text: 'FOGN LAG/AREA STUDY', value: '2DYY' },
  { key: '1AIX', text: 'FOOD SRVC  AND  INST MGT  OTHER', value: '1AIX' },
  { key: '2DYD', text: 'FRENCH', value: '2DYD' },
  { key: '1AIA', text: 'FS MGT, HOSPITAL & INST MGT', value: '1AIA' },
  { key: '1AIB', text: 'FS MGT, HOTEL OR CLUB MGT', value: '1AIB' },
  { key: '1AIC', text: 'FS MGT, RESTAURANT MGT', value: '1AIC' },
  { key: '4VPF', text: 'Fabr Parachute', value: '4VPF' },
  { key: '4VPC', text: 'Fabric and Rubber Products', value: '4VPC' },
  { key: '1AGA', text: 'Facilities Management', value: '1AGA' },
  { key: '2BAD', text: 'Facility Planning', value: '2BAD' },
  { key: '9FIB', text: 'Factor Analysis', value: '9FIB' },
  { key: '1AKB', text: 'Factory Management', value: '1AKB' },
  { key: '9HCY', text: 'Family', value: '9HCY' },
  { key: '7DDA', text: 'Family Physician', value: '7DDA' },
  { key: '2CED', text: 'Family Relations', value: '2CED' },
  { key: '9HCD', text: 'Family and Kinship', value: '9HCD' },
  { key: '9DDY', text: 'Far East, incl. Oceania (History)', value: '9DDY' },
  { key: '7FEA', text: 'Farrier', value: '7FEA' },
  { key: '2CCC', text: 'Fashion Illustration', value: '2CCC' },
  { key: '2CCM', text: 'Fashion Merchandising Mgmt', value: '2CCM' },
  { key: '4IHB', text: 'Feedback Control Systems', value: '4IHB' },
  { key: '8HOH', text: 'Ferromagnetism', value: '8HOH' },
  { key: '3BAF', text: 'Fertilizers, Plant Growth Regulators - Agriculture and Food Chemistry', value: '3BAF' },
  { key: '8CAF', text: 'Fertilizers, Plant Growth Regulators--Chemistry', value: '8CAF' },
  { key: '6JBY', text: 'Fiber Bundles and Spaces Mathematics', value: '6JBY' },
  { key: '8HMC', text: 'Fiber Optics', value: '8HMC' },
  { key: '2FFC', text: 'Fiction', value: '2FFC' },
  { key: '3BCC', text: 'Field Crops', value: '3BCC' },
  { key: '8HXC', text: 'Field Theory', value: '8HXC' },
  { key: '6ADY', text: 'Fields, Rings, Algebras', value: '6ADY' },
  { key: 'Z058', text: 'Film and Broadcasting', value: 'Z058' },
  { key: '4GBC', text: 'Filters', value: '4GBC' },
  { key: '1AHY', text: 'Finance', value: '1AHY' },
  { key: '9GEC', text: 'Financial Management', value: '9GEC' },
  { key: '2CYY', text: 'Fine and Applied Arts', value: '2CYY' },
  { key: '6CGY', text: 'Finite Geometries', value: '6CGY' },
  { key: '4RFY', text: 'Fire Protection Engineering', value: '4RFY' },
  { key: '4VOA', text: 'Fire Protection and Safety Technology', value: '4VOA' },
  { key: '9IFY', text: 'Fire Science Technology and Fire Safety Administration', value: '9IFY' },
  { key: '9GED', text: 'Fiscal Policy', value: '9GED' },
  { key: '1AAE', text: 'Fiscal Procedures', value: '1AAE' },
  { key: '9BIB', text: 'Fiscal Theory and Policy', value: '9BIB' },
  { key: '3BDY', text: 'Fish and Wildlife', value: '3BDY' },
  { key: '3BDA', text: 'Fish and Wildlife Controls', value: '3BDA' },
  { key: '8CHE', text: 'Flames and Explosives', value: '8CHE' },
  { key: '8HID', text: 'Flight Dynamics', value: '8HID' },
  { key: '4VCB', text: 'Flight Engineering', value: '4VCB' },
  { key: '77FO', text: 'Flight Operations', value: '77FO' },
  { key: '4AAD', text: 'Flight Test and Research', value: '4AAD' },
  { key: '3BIA', text: 'Floral Design and Management', value: '3BIA' },
  { key: '3BFA', text: 'Floriculture and Ornamentals', value: '3BFA' },
  { key: '3AGL', text: 'Fluid Biomechanics', value: '3AGL' },
  { key: '4KBA', text: 'Fluid Dynamics--Engineering Science', value: '4KBA' },
  { key: '0YDL', text: 'Fluid Dynamics--Inter-Area Specialization', value: '0YDL' },
  { key: '4MBB', text: 'Fluid Dynamics--Mechanical Engineering Dynamics', value: '4MBB' },
  { key: '8HDL', text: 'Fluid Dynamics--Physics', value: '8HDL' },
  { key: '4GCF', text: 'Fluid Flow', value: '4GCF' },
  { key: '4KBY', text: 'Fluid Mechanics', value: '4KBY' },
  { key: '4VKD', text: 'Fluid Power Engineering Technology', value: '4VKD' },
  { key: '8CGH', text: 'Fluorine Compounds', value: '8CGH' },
  { key: '4AGC', text: 'Flutter, Vibration', value: '4AGC' },
  { key: '3BDB', text: 'Food Habits', value: '3BDB' },
  { key: '7FBA', text: 'Food Microbiology', value: '7FBA' },
  { key: '7FDC', text: 'Food Science', value: '7FDC' },
  { key: '1AIY', text: 'Food Service and Institutional Management', value: '1AIY' },
  { key: '7FDD', text: 'Food Technology', value: '7FDD' },
  { key: '3BAG', text: 'Food and Feed Additives--Agriculture and Food Chemistry', value: '3BAG' },
  { key: '8CAG', text: 'Food and Feed Additives--Chemistry', value: '8CAG' },
  { key: '7GAD', text: 'Food and Nutritional Science', value: '7GAD' },
  { key: '2BID', text: 'Foreign Languages (Secondary Teaching)', value: '2BID' },
  { key: '9ECB', text: 'Foreign Policy', value: '9ECB' },
  { key: '7DNB', text: 'Forensic Pathology', value: '7DNB' },
  { key: '0YMY', text: 'Forensic SCI-AFIP (Pathology)', value: '0YMY' },
  { key: '9HAM', text: 'Forensic Science', value: '9HAM' },
  { key: '3ALB', text: 'Forest Entomology', value: '3ALB' },
  { key: '3BEB', text: 'Forest Products', value: '3BEB' },
  { key: '3BEC', text: 'Forest Protection', value: '3BEC' },
  { key: '3BGA', text: 'Forest and Range Soils', value: '3BGA' },
  { key: '3BED', text: 'Forestry Management', value: '3BED' },
  { key: '3BEY', text: 'Forestry and Range Science', value: '3BEY' },
  { key: '9DDE', text: 'Formosa (History)', value: '9DDE' },
  { key: '4HFA', text: 'Foundation Design and Construction', value: '4HFA' },
  { key: '6CHY', text: 'Foundations of Geometry', value: '6CHY' },
  { key: '6DBY', text: 'Foundations of Mathematics', value: '6DBY' },
  { key: '6HBY', text: 'Foundations of Probability', value: '6HBY' },
  { key: '4NBY', text: 'Foundry Engineering', value: '4NBY' },
  { key: '4NBA', text: 'Foundry Techniques and Design', value: '4NBA' },
  { key: '4NBB', text: 'Foundry Theory', value: '4NBB' },
  { key: '9DLB', text: 'France (History)', value: '9DLB' },
  { key: '8CGI', text: 'Free Radical', value: '8CGI' },
  { key: '2GIC', text: 'Freedom and Determinism', value: '2GIC' },
  { key: '8HIE', text: 'Friction', value: '8HIE' },
  { key: '3BFB', text: 'Fruits', value: '3BFB' },
  { key: '3BAH', text: 'Fruits, Vegetables, Juices--Agriculture and Food Chemistry', value: '3BAH' },
  { key: '8CAH', text: 'Fruits, Vegetables, Juices--Chemistry', value: '8CAH' },
  { key: '1CBF', text: 'Fuels Distribution Technology', value: '1CBF' },
  { key: '4VNA', text: 'Fuels Technology', value: '4VNA' },
  { key: '6BEY', text: 'Functions of Complex Variables (Mathematics)', value: '6BEY' },
  { key: '9GDD', text: 'Functions of Management', value: '9GDD' },
  { key: '6BFY', text: 'Functions of Real Variables (Mathematics)', value: '6BFY' },
  { key: '6BGY', text: 'Functions of Several Complex Variables (Mathematics)', value: '6BGY' },
  { key: '2CFC', text: 'Furniture', value: '2CFC' },
  { key: '8CHF', text: 'Fused Salts', value: '8CHF' },
  { key: '0GYY', text: 'GENERAL/LIBERAL STUDIES', value: '0GYY' },
  { key: '9CAX', text: 'GEOG  BIOGEO OTHER', value: '9CAX' },
  { key: '9CXY', text: 'GEOGRAPHY  OTHER', value: '9CXY' },
  { key: '4ZBY', text: 'GEOLOGICAL ENG', value: '4ZBY' },
  { key: '4ZBA', text: 'GEOLOGICAL ENG/ENVIRO ENG', value: '4ZBA' },
  { key: '6CXY', text: 'GEOM OTHER', value: '6CXY' },
  { key: '4ZAY', text: 'GEOPHYSICAL ENG', value: '4ZAY' },
  { key: '2DYE', text: 'GERMAN', value: '2DYE' },
  { key: '9EIY', text: 'GLOBAL SECURITY/INTEL STUDIES', value: '9EIY' },
  { key: '8AAG', text: 'Galaxies', value: '8AAG' },
  { key: 'Z046', text: 'Game Programming', value: 'Z046' },
  { key: '6EJY', text: 'Game Theory', value: '6EJY' },
  { key: '4TEY', text: 'Game Theory', value: '4TEY' },
  { key: '8AAH', text: 'Gamma Radiation Astronomy', value: '8AAH' },
  { key: '8HFF', text: 'Gas Devices (Electronics)', value: '8HFF' },
  { key: '4EBY', text: 'Gas Dynamics', value: '4EBY' },
  { key: '4AAE', text: 'Gas Dynamics, Subsonic', value: '4AAE' },
  { key: '4KBB', text: 'Gas Dynamics--Engineering Science', value: '4KBB' },
  { key: '4MBC', text: 'Gas Dynamics--Mechanical Engineering Dynamics', value: '4MBC' },
  { key: '4MGB', text: 'Gas Turbines', value: '4MGB' },
  { key: '4AAF', text: 'Gas, Dynamics, Supersonic And Hypersonic', value: '4AAF' },
  { key: '8CHG', text: 'Gaseous State', value: '8CHG' },
  { key: '7DGD', text: 'Gastroenterology', value: '7DGD' },
  { key: '1CAJ', text: 'General Business', value: '1CAJ' },
  { key: '7BAY', text: 'General Dentistry', value: '7BAY' },
  { key: '7EYY', text: 'General Duty Nursing', value: '7EYY' },
  { key: '4VHF', text: 'General Electronic Technology', value: '4VHF' },
  { key: '8DAB', text: 'General Inorganic Geochemistry', value: '8DAB' },
  { key: '1AJY', text: 'General Management', value: '1AJY' },
  { key: '6GHY', text: 'General Methods, Iteration (Mathematics)', value: '6GHY' },
  { key: '3ATB', text: 'General Physiology (Cell)', value: '3ATB' },
  { key: '5ABH', text: 'General Practice (Law)', value: '5ABH' },
  { key: '7DDY', text: 'General Practice (Medicine and Surgery)', value: '7DDY' },
  { key: 'ZZZZ', text: 'General Studies  ', value: 'ZZZZ' },
  { key: '7DEY', text: 'General Surgery', value: '7DEY' },
  { key: '3AMY', text: 'Genetics', value: '3AMY' },
  { key: '3AMC', text: 'Genetics of Microorganisms', value: '3AMC' },
  { key: '8DAY', text: 'Geochemistry', value: '8DAY' },
  { key: '8DAC', text: 'Geochronology', value: '8DAC' },
  { key: '8DBY', text: 'Geodesy', value: '8DBY' },
  { key: '8DBA', text: 'Geodetic Astronomy', value: '8DBA' },
  { key: '8AFB', text: 'Geodetic Astronomy--Positional Astronomy', value: '8AFB' },
  { key: '8DBB', text: 'Geodetic Gravimetry', value: '8DBB' },
  { key: '8DBC', text: 'Geodetic Leveling', value: '8DBC' },
  { key: '8DBD', text: 'Geodetic Surveying', value: '8DBD' },
  { key: '9CYY', text: 'Geography', value: '9CYY' },
  { key: '8DCY', text: 'Geology', value: '8DCY' },
  { key: '8DCD', text: 'Geology of Mineral Deposits', value: '8DCD' },
  { key: '8DCE', text: 'Geology of Petroleum Deposits', value: '8DCE' },
  { key: '8DCF', text: 'Geology of Solid Fuels', value: '8DCF' },
  { key: '8DDB', text: 'Geomagnetism', value: '8DDB' },
  { key: '8HMD', text: 'Geometrical Optics', value: '8HMD' },
  { key: '6CYY', text: 'Geometry', value: '6CYY' },
  { key: '6FEY', text: 'Geometry of Numbers', value: '6FEY' },
  { key: '8DCG', text: 'Geomorphology--Geology', value: '8DCG' },
  { key: '8DFC', text: 'Geomorphology--Physical Geography', value: '8DFC' },
  { key: '8HME', text: 'Geophysical Optics', value: '8HME' },
  { key: '8DDC', text: 'Geophysical Surveying', value: '8DDC' },
  { key: '8DDY', text: 'Geophysics', value: '8DDY' },
  { key: '9EBY', text: 'Geopolitics', value: '9EBY' },
  { key: '9DKE', text: 'Georgian SSR (History)', value: '9DKE' },
  { key: '7EAD', text: 'Geriatric Nursing', value: '7EAD' },
  { key: '7DFY', text: 'Geriatrics', value: '7DFY' },
  { key: 'Z000', text: 'German Literature', value: 'Z000' },
  { key: 'Z001', text: 'German Studies', value: 'Z001' },
  { key: '9DLC', text: 'Germany and Austria (History)', value: '9DLC' },
  { key: '8DCH', text: 'Glacial Geology', value: '8DCH' },
  { key: '8EAD', text: 'Glaciology', value: '8EAD' },
  { key: '4FBF', text: 'Glass', value: '4FBF' },
  { key: '2CFD', text: 'Glass and Fiberglas', value: '2CFD' },
  { key: '8CEK', text: 'Glass, Fused Silica', value: '8CEK' },
  { key: '2GMC', text: 'God His Existence and Nature', value: '2GMC' },
  { key: '2GMA', text: 'God and Man', value: '2GMA' },
  { key: '2GMB', text: 'God and the World', value: '2GMB' },
  { key: '2IEA', text: 'Golf Course Operations', value: '2IEA' },
  { key: '1AAF', text: 'Government Accounting', value: '1AAF' },
  { key: '1AMB', text: 'Government Accounting and Budgeting', value: '1AMB' },
  { key: '5ACI', text: 'Government Contracts Law', value: '5ACI' },
  { key: '1AJD', text: 'Government Policy', value: '1AJD' },
  { key: '2CDY', text: 'Graphic Arts', value: '2CDY' },
  { key: '2CDC', text: 'Graphic/Advertising Design', value: '2CDC' },
  { key: '6JCY', text: 'Graphs', value: '6JCY' },
  { key: '8CBG', text: 'Gravimetric Analysis', value: '8CBG' },
  { key: '8DDD', text: 'Gravimetry', value: '8DDD' },
  { key: '3AAB', text: 'Gross Anatomy', value: '3AAB' },
  { key: '4VHG', text: 'Ground Radar Technology', value: '4VHG' },
  { key: '8EAE', text: 'Ground Waters', value: '8EAE' },
  { key: '9FJB', text: 'Group Interaction', value: '9FJB' },
  { key: '9FAD', text: 'Group Therapy', value: '9FAD' },
  { key: '6AEY', text: 'Groups, Generalizations (Mathematics)', value: '6AEY' },
  { key: '3ABA', text: 'Growth and Reproduction (Bacteriology)', value: '3ABA' },
  { key: '4ISC', text: 'Guidance and Control', value: '4ISC' },
  { key: '4EDE', text: 'Guidance and Control', value: '4EDE' },
  { key: '4ECY', text: 'Guidance and Control--Astronautical Engineering', value: '4ECY' },
  { key: '4IHC', text: 'Guidance and Navigation Control Systems', value: '4IHC' },
  { key: '4BCY', text: 'Guided Missiles', value: '4BCY' },
  { key: '4VKE', text: 'Gunsmithing', value: '4VKE' },
  { key: '7EBM', text: 'HEALTH PROMOTION', value: '7EBM' },
  { key: '9DJX', text: 'HIST ACAD/DCPL/OTHER', value: '9DJX' },
  { key: '9DJD', text: 'HIST ACAD/DCPL/POLOTICAL TH', value: '9DJD' },
  { key: '9DAX', text: 'HIST AFRICA OTHER', value: '9DAX' },
  { key: '9DCA', text: 'HIST EA EUROPE/BALK BALKANS', value: '9DCA' },
  { key: '9DCX', text: 'HIST EA EUROPE/BALK OTHER', value: '9DCX' },
  { key: '9DDX', text: 'HIST FAR EAST OTHER', value: '9DDX' },
  { key: '9DFX', text: 'HIST MDL EAST OTHER', value: '9DFX' },
  { key: '9DGX', text: 'HIST NEAR EAST OTHER N.EAST', value: '9DGX' },
  { key: '9DHX', text: 'HIST OTHER NORTH AMERICAN', value: '9DHX' },
  { key: '9DIX', text: 'HIST OTHER SOUTH AMERICAN', value: '9DIX' },
  { key: '9DKX', text: 'HIST OTHER USSR', value: '9DKX' },
  { key: '9DLX', text: 'HIST OTHER/WESTERN EUROPE', value: '9DLX' },
  { key: '9DXY', text: 'HISTORY OTHER', value: '9DXY' },
  { key: '7GCX', text: 'HLTH-CARE-MGT OTHER', value: '7GCX' },
  { key: '7GXY', text: 'HLTH-CR-SCI-OTHER', value: '7GXY' },
  { key: '9HAP', text: 'HOMELAND SECURITY', value: '9HAP' },
  { key: '7CXY', text: 'HOSP-ADM OTHER', value: '7CXY' },
  { key: '7CAX', text: 'HOSP-ADM-ADM-OTHER', value: '7CAX' },
  { key: '2ICX', text: 'HUM-TECH OTHER', value: '2ICX' },
  { key: '9KYY', text: 'HUMAN RELATIONS', value: '9KYY' },
  { key: '1AOW', text: 'HUMAN RESOURCES/ORGANIZATIONAL DEVELOPMENT', value: '1AOW' },
  { key: '8EAX', text: 'HYDRO SCI HYDR  OTHER', value: '8EAX' },
  { key: '8EXY', text: 'HYDRO SCI HYDROSPHERIC OTH', value: '8EXY' },
  { key: '8EBX', text: 'HYDRO SCI OCEANO OTHER', value: '8EBX' },
  { key: '3BDC', text: 'Habitat Influences', value: '3BDC' },
  { key: '8CEL', text: 'Halogen Family', value: '8CEL' },
  { key: 'Z047', text: 'Health Care Administration', value: 'Z047' },
  { key: '7GCY', text: 'Health Care Mgt and Health Services Admin', value: '7GCY' },
  { key: '7GYY', text: 'Health Care Sciences Occupational Technologies', value: '7GYY' },
  { key: 'Z131', text: 'Health Fitness', value: 'Z131' },
  { key: '3AGM', text: 'Health Physics (Biophysical Specialties)--Biology', value: '3AGM' },
  { key: '0YDM', text: 'Health Physics (Biophysical Specialties)--Inter-Area Specialization', value: '0YDM' },
  { key: '8HDM', text: 'Health Physics (Biophysical Specialties)--Physics', value: '8HDM' },
  { key: '4QCB', text: 'Health Physics (Nuclear and Radiation Effects)', value: '4QCB' },
  { key: '4EBA', text: 'Heat Flow--Astronautical Engineering', value: '4EBA' },
  { key: '8DDE', text: 'Heat Flow--Geophysics', value: '8DDE' },
  { key: '4AAG', text: 'Heat Flow-Aeronautical Engineering', value: '4AAG' },
  { key: '4GCG', text: 'Heat Transfer', value: '4GCG' },
  { key: '8HPB', text: 'Heat Transmission', value: '8HPB' },
  { key: '4VEI', text: 'Heating, Air Conditioning, and Refrigeration Technology', value: '4VEI' },
  { key: '4VKF', text: 'Heavy Equipment Technology', value: '4VKF' },
  { key: '2GID', text: 'Heirarchy of Being', value: '2GID' },
  { key: '7DGE', text: 'Hematology--Internal Medicine', value: '7DGE' },
  { key: '7DNC', text: 'Hematology--Pathology', value: '7DNC' },
  { key: '8CGJ', text: 'Heterocycles', value: '8CGJ' },
  { key: '8HGB', text: 'High Energy Accelerators', value: '8HGB' },
  { key: '8HGC', text: 'High Energy Phenomena', value: '8HGC' },
  { key: '8HOI', text: 'High Polymers and Glasses', value: '8HOI' },
  { key: '8CHH', text: 'High Pressure Chemistry', value: '8CHH' },
  { key: '8HIF', text: 'High Pressure Physics', value: '8HIF' },
  { key: '8CHI', text: 'High Temperature Chemistry', value: '8CHI' },
  { key: '8HNG', text: 'High Temperature Flow', value: '8HNG' },
  { key: '8HXD', text: 'High Vacuum Techniques', value: '8HXD' },
  { key: '4VEJ', text: 'Highway Engineering Technology', value: '4VEJ' },
  { key: '1ATB', text: 'Highway Transportation Management', value: '1ATB' },
  { key: '4REB', text: 'Highway Transportation Safety', value: '4REB' },
  { key: '4HIB', text: 'Highway and Road Systems', value: '4HIB' },
  { key: '6BHY', text: 'Hilbert Spaces (Mathematics)', value: '6BHY' },
  { key: '2HBB', text: 'Hinduism', value: '2HBB' },
  { key: '7GAE', text: 'Histologic Technician and Histologic Technology', value: '7GAE' },
  { key: '3AAC', text: 'Histology', value: '3AAC' },
  { key: '3ARD', text: 'Histopathology', value: '3ARD' },
  { key: '9CBA', text: 'Historical Geography', value: '9CBA' },
  { key: '9HEC', text: 'Historical Sociology', value: '9HEC' },
  { key: '2GBH', text: 'Historicism (German)', value: '2GBH' },
  { key: '9DYY', text: 'History', value: '9DYY' },
  { key: '9BAB', text: 'History of Economic Thought--Economic History', value: '9BAB' },
  { key: '9DJA', text: 'History of Economic Thought--Subject Histories', value: '9DJA' },
  { key: '9DJB', text: 'History of Education', value: '9DJB' },
  { key: '9BEA', text: 'History of Labor Movements', value: '9BEA' },
  { key: '2CIA', text: 'History of Painting', value: '2CIA' },
  { key: '9DJC', text: 'History of Philosophy', value: '9DJC' },
  { key: '9EDA', text: 'History of Political Thought', value: '9EDA' },
  { key: '9DJE', text: 'History of Religion', value: '9DJE' },
  { key: '9DJF', text: 'History of Science', value: '9DJF' },
  { key: '2CKE', text: 'History of Sculpture', value: '2CKE' },
  { key: '9HKA', text: 'History of Sociology', value: '9HKA' },
  { key: '2GGY', text: 'History of Western Philosophy', value: '2GGY' },
  { key: '2CEY', text: 'Home Economics', value: '2CEY' },
  { key: '2CEE', text: 'Home Economics Journalism', value: '2CEE' },
  { key: '8CHJ', text: 'Homogeneous Chemical Equilibrium', value: '8CHJ' },
  { key: '6AFY', text: 'Homological Algebra', value: '6AFY' },
  { key: '4VKG', text: 'Horology', value: '4VKG' },
  { key: '3BFY', text: 'Horticulture', value: '3BFY' },
  { key: '7CAY', text: 'Hospital Administration', value: '7CAY' },
  { key: '7CYY', text: 'Hospital Administration--Hospital Administration', value: '7CYY' },
  { key: '2ABY', text: 'Hospital Ministry', value: '2ABY' },
  { key: '7CAE', text: 'Hospital Planning', value: '7CAE' },
  { key: '7CAF', text: 'Hospital Services Administration', value: '7CAF' },
  { key: 'Z010', text: 'Human Development', value: 'Z010' },
  { key: '9HDB', text: 'Human Ecology', value: '9HDB' },
  { key: '4LCY', text: 'Human Factors in Engineering--Industrial Engineering', value: '4LCY' },
  { key: '4TFY', text: 'Human Factors in Engineering--Systems Engineering', value: '4TFY' },
  { key: '9FEG', text: 'Human Learning', value: '9FEG' },
  { key: 'Z029', text: 'Human Nutrition and Health Science', value: 'Z029' },
  { key: '9AEB', text: 'Human Paleontology', value: '9AEB' },
  { key: '2GIE', text: 'Human Person', value: '2GIE' },
  { key: '3ATC', text: 'Human Physiology', value: '3ATC' },
  { key: '9FFB', text: 'Human Relations', value: '9FFB' },
  { key: '1AOY', text: 'Human Resource Management/Personnel Administration', value: '1AOY' },
  { key: '2ICY', text: 'Humanities Technology', value: '2ICY' },
  { key: '2EYY', text: 'Humanities, General', value: '2EYY' },
  { key: '4AEC', text: 'Hybrid Engines', value: '4AEC' },
  { key: '4HDY', text: 'Hydraulic Engineering', value: '4HDY' },
  { key: '4HDB', text: 'Hydraulic Machinery', value: '4HDB' },
  { key: '4HDC', text: 'Hydraulic Structures', value: '4HDC' },
  { key: '4PCY', text: 'Hydrodynamics (Naval Architecture)', value: '4PCY' },
  { key: '4AAH', text: 'Hydrodynamics--Aeronautical Engineering', value: '4AAH' },
  { key: '4KBC', text: 'Hydrodynamics--Engineering Sciences', value: '4KBC' },
  { key: '8CEM', text: 'Hydrogen', value: '8CEM' },
  { key: '8EBD', text: 'Hydrography', value: '8EBD' },
  { key: '4HDD', text: 'Hydrology (Hydraulic Engineering)', value: '4HDD' },
  { key: '8EAY', text: 'Hydrology (Hydrospheric Science)', value: '8EAY' },
  { key: '4HDE', text: 'Hydromechanics', value: '4HDE' },
  { key: '4NAB', text: 'Hydrometallurgical Extraction', value: '4NAB' },
  { key: '8FGC', text: 'Hydrometeorology', value: '8FGC' },
  { key: '8EYY', text: 'Hydrospheric Sciences', value: '8EYY' },
  { key: '4KBD', text: 'Hydrostatics', value: '4KBD' },
  { key: '3ANE', text: 'Hypersensitivity', value: '3ANE' },
  { key: '8BAC', text: 'Hypsographic Cartography', value: '8BAC' },
  { key: '8GYY', text: 'IMAGING SCIENCE', value: '8GYY' },
  { key: '4LBX', text: 'IND ENG ENG ECON OTHER', value: '4LBX' },
  { key: '4LCX', text: 'IND ENG HUM FAC ENG OTHER', value: '4LCX' },
  { key: '4LCF', text: 'IND ENG HUM FAC ENG WA LI', value: '4LCF' },
  { key: '4LDA', text: 'IND ENG JOB DES ERGONOMETR', value: '4LDA' },
  { key: '4LDX', text: 'IND ENG JOB DES OTHER', value: '4LDX' },
  { key: '4LFX', text: 'IND ENG OPR ANA OTHER', value: '4LFX' },
  { key: '4LXY', text: 'IND ENG OTHER', value: '4LXY' },
  { key: '4LGX', text: 'IND ENG PD PL/CO  OTHER', value: '4LGX' },
  { key: '4LHX', text: 'IND ENG QC OTHER', value: '4LHX' },
  { key: '4VJX', text: 'INDUSTRIAL ENG-TECH-OTHER', value: '4VJX' },
  { key: '0CYA', text: 'INFORMATION ASSURANCE', value: '0CYA' },
  { key: '2BDG', text: 'INSTRUCTIONAL PERFORMANCE AND TECHNOLOGY', value: '2BDG' },
  { key: '0YXY', text: 'INTER-AREA SPECL-OTHER', value: '0YXY' },
  { key: '2DYG', text: 'ITALIAN', value: '2DYG' },
  { key: '9DLD', text: 'Iberian Peninsula (History)', value: '9DLD' },
  { key: '2GLB', text: 'Idealional Process', value: '2GLB' },
  { key: '4DBB', text: 'Illumination (Electrical Systems)', value: '4DBB' },
  { key: '4IED', text: 'Illumination (Energy Conversion and Distribution)', value: '4IED' },
  { key: '8HMF', text: 'Illumination (Optics)', value: '8HMF' },
  { key: '2CBC', text: 'Illustration', value: '2CBC' },
  { key: '2GLC', text: 'Imagination', value: '2GLC' },
  { key: '3ACJ', text: 'Immunochemistry--Biology', value: '3ACJ' },
  { key: '8CCJ', text: 'Immunochemistry--Chemistry', value: '8CCJ' },
  { key: '0YBJ', text: 'Immunochemistry--Preventive Medicine', value: '0YBJ' },
  { key: '3ANY', text: 'Immunology--Biology', value: '3ANY' },
  { key: '7DRB', text: 'Immunology--Preventive Medicine', value: '7DRB' },
  { key: '8HIG', text: 'Impact Phenomena', value: '8HIG' },
  { key: '8HCF', text: 'Impact and Scattering Phenomena', value: '8HCF' },
  { key: '9BBB', text: 'Income and Employment Theory', value: '9BBB' },
  { key: '8HNH', text: 'Incompressible Fluid Dynamics', value: '8HNH' },
  { key: '1AFC', text: 'Indexes of Business Conditions', value: '1AFC' },
  { key: '9DFB', text: 'India (History)', value: '9DFB' },
  { key: '2GKB', text: 'India (Philosophy)', value: '2GKB' },
  { key: '2ICE', text: 'Indian History', value: '2ICE' },
  { key: '9FAE', text: 'Individual Diagnosis and Therapy', value: '9FAE' },
  { key: '2BEE', text: 'Individual Differences', value: '2BEE' },
  { key: '9DDF', text: 'Indonesia (History)', value: '9DDF' },
  { key: '2CFY', text: 'Industrial Art-Design', value: '2CFY' },
  { key: '2BIE', text: 'Industrial Arts (Secondary Teaching)', value: '2BIE' },
  { key: '8CEN', text: 'Industrial Carbon, Graphite, Carbon Black', value: '8CEN' },
  { key: '3ASE', text: 'Industrial Chemicals', value: '3ASE' },
  { key: '9BCY', text: 'Industrial Economics', value: '9BCY' },
  { key: '4VJY', text: 'Industrial Engineering Technology', value: '4VJY' },
  { key: '4LIY', text: 'Industrial Engineering/Computer Aided Manufacturing', value: '4LIY' },
  { key: '7ABG', text: 'Industrial Hygiene', value: '7ABG' },
  { key: '4VJA', text: 'Industrial Instruments Technology', value: '4VJA' },
  { key: '9BCC', text: 'Industrial Organization--Industrial Economics', value: '9BCC' },
  { key: '4LBD', text: 'Industrial Organization--Industrial Engineering', value: '4LBD' },
  { key: '2CBD', text: 'Industrial Patterns', value: '2CBD' },
  { key: '1AKC', text: 'Industrial Procurement', value: '1AKC' },
  { key: '1AQB', text: 'Industrial Real Estate', value: '1AQB' },
  { key: '1AOC', text: 'Industrial Relations', value: '1AOC' },
  { key: '4RBY', text: 'Industrial Safety', value: '4RBY' },
  { key: '9HAG', text: 'Industrial Security', value: '9HAG' },
  { key: '9HGA', text: 'Industrial Sociology', value: '9HGA' },
  { key: '4VQY', text: 'Industrial Technology', value: '4VQY' },
  { key: '1ATC', text: 'Industrial Traffic Management', value: '1ATC' },
  { key: '9FFY', text: 'Industrial and Personnel Psychology', value: '9FFY' },
  { key: '2BGY', text: 'Industrial and Vocational Education', value: '2BGY' },
  { key: '4LYY', text: 'Industrial engineering', value: '4LYY' },
  { key: '1AKY', text: 'Industrial or Production Management', value: '1AKY' },
  { key: '4EDF', text: 'Inertial Guidance Systems', value: '4EDF' },
  { key: '3ANF', text: 'Infection and Resistance', value: '3ANF' },
  { key: '4VCC', text: 'Inflight Refueling Operator', value: '4VCC' },
  { key: '1AUY', text: 'Information Resources Management', value: '1AUY' },
  { key: '0CBD', text: 'Information Storage and Return', value: '0CBD' },
  { key: '0IYY', text: 'Information Sys MGT', value: '0IYY' },
  { key: '0IYZ', text: 'Information Technology', value: '0IYZ' },
  { key: '4IGB', text: 'Information Theory', value: '4IGB' },
  { key: '8HMG', text: 'Information Theory (Image Evaluation)', value: '8HMG' },
  { key: '6EKY', text: 'Information and Communications Theory', value: '6EKY' },
  { key: '8HMH', text: 'Infrared Phenomena', value: '8HMH' },
  { key: '8HAE', text: 'Infrasonics', value: '8HAE' },
  { key: '2CCD', text: 'Inlay and Enameling', value: '2CCD' },
  { key: '8CEO', text: 'Inner-transition elements, lanthanide series and actinide series', value: '8CEO' },
  { key: '8CEY', text: 'Inorganic Chemistry', value: '8CEY' },
  { key: '9BJB', text: 'Input-Output Analysis', value: '9BJB' },
  { key: '3ALC', text: 'Insect Control, Chemical', value: '3ALC' },
  { key: '3ALD', text: 'Insect Control, Other', value: '3ALD' },
  { key: '3ALE', text: 'Insect Morphology', value: '3ALE' },
  { key: '3ALF', text: 'Insect Pests', value: '3ALF' },
  { key: '3ALG', text: 'Insect Physiology', value: '3ALG' },
  { key: '2GLD', text: 'Instincts', value: '2GLD' },
  { key: '9EAC', text: 'Institutional or Functional Specialization', value: '9EAC' },
  { key: '2BDC', text: 'Instructional Materials', value: '2BDC' },
  { key: '2BDF', text: 'Instructional System Development ISD', value: '2BDF' },
  { key: '2IBB', text: 'Instructor in Technology', value: '2IBB' },
  { key: '2CHB', text: 'Instrumental Music', value: '2CHB' },
  { key: '8FCY', text: 'Instrumentation', value: '8FCY' },
  { key: '4ADY', text: 'Instrumentation (Aeronautical Engineering)', value: '4ADY' },
  { key: '4EDY', text: 'Instrumentation (Astronautical Engineering)', value: '4EDY' },
  { key: '4IGC', text: 'Instrumentation (Electrical Engineering)', value: '4IGC' },
  { key: '4QAY', text: 'Instrumentation (Nuclear Engineering)', value: '4QAY' },
  { key: '4VHH', text: 'Instrumentation Technology', value: '4VHH' },
  { key: '8HIH', text: 'Instruments and Measurements', value: '8HIH' },
  { key: '1ALY', text: 'Insurance', value: '1ALY' },
  { key: '5ABI', text: 'Insurance Law', value: '5ABI' },
  { key: '6CIY', text: 'Integral Geometry', value: '6CIY' },
  { key: '6BJY', text: 'Integral Transforms', value: '6BJY' },
  { key: '6GIY', text: 'Integral and Integro-Differential Equations', value: '6GIY' },
  { key: '3AYZ', text: 'Integrative Biology', value: '3AYZ' },
  { key: '9INZ', text: 'Intelligence Analysis', value: '9INZ' },
  { key: '9INY', text: 'Intelligence Collection', value: '9INY' },
  { key: '9INA', text: 'Intelligence and Imagery Analysis', value: '9INA' },
  { key: '2IAG', text: 'Intelligence and Imagery Analysis and Graphics', value: '2IAG' },
  { key: '0YYY', text: 'Inter-Area Specializations', value: '0YYY' },
  { key: '8FGD', text: 'Interactive Graphics', value: '8FGD' },
  { key: 'Z034', text: 'Interdiscipliplinary Studies', value: 'Z034' },
  { key: 'Z045', text: 'Interface Design', value: 'Z045' },
  { key: '3ANG', text: 'Interference, Latency', value: '3ANG' },
  { key: '8HMI', text: 'Interferometry', value: '8HMI' },
  { key: '9HJA', text: 'Intergroup Relations', value: '9HJA' },
  { key: '2CBE', text: 'Interior Decoration', value: '2CBE' },
  { key: '0YBK', text: 'Intermediary Metabolism, Biosynthesis', value: '0YBK' },
  { key: '3ACK', text: 'Intermediary Metabolism, Biosynthesis--Biology', value: '3ACK' },
  { key: '8CCK', text: 'Intermediary Metabolism, Biosynthesis--Chemistry', value: '8CCK' },
  { key: '4VKH', text: 'Internal Combustion Engine Engineering Technology', value: '4VKH' },
  { key: '4MGC', text: 'Internal Combustion Engines', value: '4MGC' },
  { key: '8HOJ', text: 'Internal Friction', value: '8HOJ' },
  { key: '7DGY', text: 'Internal Medicine', value: '7DGY' },
  { key: '9BGC', text: 'Internation Finance', value: '9BGC' },
  { key: '9GCY', text: 'International Administration', value: '9GCY' },
  { key: '1AJE', text: 'International Economic Relations', value: '1AJE' },
  { key: '9BDY', text: 'International Economics', value: '9BDY' },
  { key: '9BEB', text: 'International Labor Problems', value: '9BEB' },
  { key: '9ECD', text: 'International Law and Regulation', value: '9ECD' },
  { key: '5AAY', text: 'International Law--Law', value: '5AAY' },
  { key: '9ECC', text: 'International Law--Political Science', value: '9ECC' },
  { key: '1AMI', text: 'International Logistics Management', value: '1AMI' },
  { key: '9ECE', text: 'International Politics', value: '9ECE' },
  { key: '9GCC', text: 'International Relations and Politics', value: '9GCC' },
  { key: '9ECY', text: 'International Relations--Political Science', value: '9ECY' },
  { key: '9HJB', text: 'International Relations--Social Structure', value: '9HJB' },
  { key: '1ADE', text: 'International Trade', value: '1ADE' },
  { key: '6GJY', text: 'Interpolation, Approximation, Curve Fitting', value: '6GJY' },
  { key: '8BCA', text: 'Interpretation, Cultural Features', value: '8BCA' },
  { key: '8BCB', text: 'Interpretation, Military Features', value: '8BCB' },
  { key: '8BCC', text: 'Interpretation; Natural Features And Resources', value: '8BCC' },
  { key: '2IAH', text: 'Interpreting and Translating', value: '2IAH' },
  { key: '6DCY', text: 'Intuitionism (Mathematics)', value: '6DCY' },
  { key: '4LGA', text: 'Inventory Control--Industrial Engineering Production And Control', value: '4LGA' },
  { key: '1AKD', text: 'Inventory Control--Industrial Or Production Management', value: '1AKD' },
  { key: '3AVA', text: 'Invertebrates', value: '3AVA' },
  { key: '4RAB', text: 'Investigative Techniques', value: '4RAB' },
  { key: '1AHB', text: 'Investments', value: '1AHB' },
  { key: '5ABJ', text: 'Investments Law', value: '5ABJ' },
  { key: '8CHK', text: 'Ion Exchange and Applications', value: '8CHK' },
  { key: '8FDD', text: 'Ionospheric Environment', value: '8FDD' },
  { key: '9DGA', text: 'Iran (History)', value: '9DGA' },
  { key: '9DGB', text: 'Iraq (History)', value: '9DGB' },
  { key: '3BEE', text: 'Irrigation', value: '3BEE' },
  { key: '2HBC', text: 'Islam (Religion)', value: '2HBC' },
  { key: '8DAD', text: 'Isotope Geochemistry', value: '8DAD' },
  { key: '9DGC', text: 'Israel (History)', value: '9DGC' },
  { key: '9DLE', text: 'Italy (History)', value: '9DLE' },
  { key: '2DYF', text: 'JAPANESE', value: '2DYF' },
  { key: '0YVC', text: 'JOINT CAMPAIGN PLANNING  AND  STRATEGY', value: '0YVC' },
  { key: '0YWJ', text: 'JOINT INFORMATION OPERATIONS (JIO)', value: '0YWJ' },
  { key: '9DDG', text: 'Japan (History)', value: '9DDG' },
  { key: '2CCE', text: 'Jewelry (Decorative Arts and Crafts)', value: '2CCE' },
  { key: '2CFE', text: 'Jewelry (Industrial Art-Design)', value: '2CFE' },
  { key: '9FFC', text: 'Job Analysis and Classification', value: '9FFC' },
  { key: '1AOD', text: 'Job Classification', value: '1AOD' },
  { key: '4LDY', text: 'Job Design', value: '4LDY' },
  { key: '1AOE', text: 'Job Evaluation', value: '1AOE' },
  { key: '9DGD', text: 'Jordan (History)', value: '9DGD' },
  { key: '2FBY', text: 'Journalism', value: '2FBY' },
  { key: '2HDB', text: 'Judaism', value: '2HDB' },
  { key: '9EEB', text: 'Jurisprudence', value: '9EEB' },
  { key: '2GFA', text: 'Justice, Individual and Social', value: '2GFA' },
  { key: '2DYP', text: 'KOREAN', value: '2DYP' },
  { key: '9DKF', text: 'Kazahk SSR (History)', value: '9DKF' },
  { key: '4FCC', text: 'Kilns, Furnaces and Pyrometry', value: '4FCC' },
  { key: '2BHA', text: 'Kinesiology', value: '2BHA' },
  { key: '9DKG', text: 'Kirghiz SSR (History)', value: '9DKG' },
  { key: '9DDH', text: 'Korea (History)', value: '9DDH' },
  { key: '2FXY', text: 'LANG  AND  COMMUNICAT ART OTHER', value: '2FXY' },
  { key: '2FAX', text: 'LANG ENGLISH OTHER', value: '2FAX' },
  { key: '2FCX', text: 'LANG LIT OTHER', value: '2FCX' },
  { key: '2FDX', text: 'LANG PUB REL OTHER', value: '2FDX' },
  { key: '2FEX', text: 'LANG SPEECH  OTHER', value: '2FEX' },
  { key: '2FFX', text: 'LANG WRITE OTHER', value: '2FFX' },
  { key: '9LYY', text: 'LEADERSHIP', value: '9LYY' },
  { key: '2BCF', text: 'LEADERSHIP  AND  COUNSELING', value: '2BCF' },
  { key: '6DXY', text: 'LOGIC-OTHER', value: '6DXY' },
  { key: '1ADF', text: 'Labor Economics--Business Economics', value: '1ADF' },
  { key: '9HBA', text: 'Labor Force', value: '9HBA' },
  { key: '1AED', text: 'Labor Law', value: '1AED' },
  { key: '4LBE', text: 'Labor Relations--Industrial Engineering Economics', value: '4LBE' },
  { key: '1AOF', text: 'Labor Relations--Personnel Administration', value: '1AOF' },
  { key: '9BEC', text: 'Labor-Management Relations--Economics', value: '9BEC' },
  { key: '9FFD', text: 'Labor-Management Relations--Psychology', value: '9FFD' },
  { key: '7FAA', text: 'Laboratory Animal Medicine', value: '7FAA' },
  { key: '9BFY', text: 'Land Economics', value: '9BFY' },
  { key: '2CAD', text: 'Landscape (Architecture)', value: '2CAD' },
  { key: '9FJC', text: 'Language and Communication', value: '9FJC' },
  { key: '2FYY', text: 'Language and Communicative Arts', value: '2FYY' },
  { key: '3BBA', text: 'Large Animal Husbandry', value: '3BBA' },
  { key: '7FAB', text: 'Large Animal Medicine', value: '7FAB' },
  { key: '9HGB', text: 'Large Scale Organization', value: '9HGB' },
  { key: '8HMJ', text: 'Lasers', value: '8HMJ' },
  { key: '8HCG', text: 'Lasers--Atomic and Molecular Physics', value: '8HCG' },
  { key: '8HOK', text: 'Lattice Effects and Diffusion', value: '8HOK' },
  { key: '6DDY', text: 'Lattices (Mathematics)', value: '6DDY' },
  { key: '9DKH', text: 'Latvian SSR (History)', value: '9DKH' },
  { key: '5YYY', text: 'Law', value: '5YYY' },
  { key: '2CGC', text: 'Law Library', value: '2CGC' },
  { key: '2CBF', text: 'Layout', value: '2CBF' },
  { key: '9GAC', text: 'Leadership--Public Administration', value: '9GAC' },
  { key: '9FJD', text: 'Leadership--Social Psychology', value: '9FJD' },
  { key: '2GDG', text: 'Learning', value: '2GDG' },
  { key: '2CFG', text: 'Leathercraft', value: '2CFG' },
  { key: '9DGE', text: 'Lebanon (History)', value: '9DGE' },
  { key: '9HFB', text: 'Legal Sociology', value: '9HFB' },
  { key: '9EDB', text: 'Legislative Process', value: '9EDB' },
  { key: '9HFC', text: 'Leisure and Popular Culture', value: '9HFC' },
  { key: '8HMK', text: 'Lenses', value: '8HMK' },
  { key: '2CBG', text: 'Lettering', value: '2CBG' },
  { key: 'Z013', text: 'Liberal Arts', value: 'Z013' },
  { key: '2CGY', text: 'Library Science', value: '2CGY' },
  { key: '2BJA', text: 'Library Training', value: '2BJA' },
  { key: '6BKY', text: 'Lie Groups and Algebras', value: '6BKY' },
  { key: '2GLE', text: 'Life (Philosophy)', value: '2GLE' },
  { key: '1ALB', text: 'Life Insurance', value: '1ALB' },
  { key: '6HCY', text: 'Limit Theorems (Mathematics)', value: '6HCY' },
  { key: '1AKE', text: 'Line Supervision', value: '1AKE' },
  { key: '6AGY', text: 'Linear Algebra and Matrix Theory', value: '6AGY' },
  { key: '6GKY', text: 'Linear Equations, Matrices', value: '6GKY' },
  { key: '6GLY', text: 'Linear Programming (Mathematics)', value: '6GLY' },
  { key: 'Z009', text: 'Linguistics', value: 'Z009' },
  { key: '3ACL', text: 'Lipids--Biology', value: '3ACL' },
  { key: '8CCL', text: 'Lipids--Chemistry', value: '8CCL' },
  { key: '0YBL', text: 'Lipids--Inter-Area Specialization', value: '0YBL' },
  { key: '4AED', text: 'Liquid Fuels--Aeronautical Engineering', value: '4AED' },
  { key: '4EEC', text: 'Liquid Fuels--stronautical Engineering', value: '4EEC' },
  { key: '8CHL', text: 'Liquid State', value: '8CHL' },
  { key: '2FCY', text: 'Literature', value: '2FCY' },
  { key: '9DKI', text: 'Lithuanian SSR (History)', value: '9DKI' },
  { key: 'Z071', text: 'Livestock and Poultry Management ', value: 'Z071' },
  { key: '4AGD', text: 'Loads', value: '4AGD' },
  { key: '1AID', text: 'Lodging Management', value: '1AID' },
  { key: '2GHY', text: 'Logic', value: '2GHY' },
  { key: '6DYY', text: 'Logic and Foundations (Mathematics)', value: '6DYY' },
  { key: '1AMC', text: 'Logistics Function Management', value: '1AMC' },
  { key: '1ACY', text: 'Logistics Management', value: '1ACY' },
  { key: '1AMY', text: 'Logistics Management', value: '1AMY' },
  { key: '1ACX', text: 'Logistics Management/Other', value: '1ACX' },
  { key: '1AMD', text: 'Logistics Statistics', value: '1AMD' },
  { key: '6ELY', text: 'Logistics, Inventory (Mathematics)', value: '6ELY' },
  { key: '8HPD', text: 'Low Temperature Physics', value: '8HPD' },
  { key: '4MDY', text: 'Lubrication Engineering', value: '4MDY' },
  { key: '8HOL', text: 'Luminescence', value: '8HOL' },
  { key: '4IHY', text: 'Lumped Parameter Systems (Electrical Engineering)', value: '4IHY' },
  { key: '0YKS', text: 'MANAGEMENT SCIENCE', value: '0YKS' },
  { key: '4FBY', text: 'MAT SCI AND ENG ELEC AND OPTIC MAT', value: '4FBY' },
  { key: '4FAY', text: 'MAT SCI AND ENG STRUCT ENGRING', value: '4FAY' },
  { key: '4FYY', text: 'MAT SCI AND ENGINEERING', value: '4FYY' },
  { key: '6EXY', text: 'MATH OF RESRC-OTHER', value: '6EXY' },
  { key: '4MBX', text: 'MECH ENG DYNAM OTHER', value: '4MBX' },
  { key: '4MXY', text: 'MECH ENG OTHER', value: '4MXY' },
  { key: '4MHX', text: 'MECH ENG PROD DES OTHER', value: '4MHX' },
  { key: '4MGX', text: 'MECH ENG PWR PLT OTHER', value: '4MGX' },
  { key: '4MID', text: 'MECH ENG THE/HT T HEAT TRAN', value: '4MID' },
  { key: '4MIX', text: 'MECH ENG THE/HT T OTHER', value: '4MIX' },
  { key: '0CAC', text: 'MECH-DATA-PROC-SYS(PCAM)', value: '0CAC' },
  { key: '4VKX', text: 'MECHANICAL ENG-TECH-OTHER', value: '4VKX' },
  { key: '7DGH', text: 'MED SURG NEPHROLOGY', value: '7DGH' },
  { key: '7GDX', text: 'MED-TECH OTHER', value: '7GDX' },
  { key: '7DNE', text: 'MED/SURG PATH HEMM ONCOLOGY', value: '7DNE' },
  { key: '7DGX', text: 'MED/SURG-INTL MED-OTHER', value: '7DGX' },
  { key: '7DGF', text: 'MED/SURG-INTL MED-PULMONARY', value: '7DGF' },
  { key: '7DXY', text: 'MED/SURG-MED/SURG OTHER', value: '7DXY' },
  { key: '7DNX', text: 'MED/SURG-PATH-OTHER', value: '7DNX' },
  { key: '7DOX', text: 'MED/SURG-PED-OTHER', value: '7DOX' },
  { key: '7DRX', text: 'MED/SURG-PREV MED-OTHER', value: '7DRX' },
  { key: '7DSX', text: 'MED/SURG-PSYCH-OTHER', value: '7DSX' },
  { key: '7DUX', text: 'MED/SURG-RADIOL-OTHER', value: '7DUX' },
  { key: '7GAP', text: 'MENTAL HEALTH SERVICES', value: '7GAP' },
  { key: '4NAX', text: 'MET ENG EXT OTHER', value: '4NAX' },
  { key: '4NBX', text: 'MET ENG FOU ENG OTHER', value: '4NBX' },
  { key: '4NXY', text: 'MET ENG OTHER', value: '4NXY' },
  { key: '4NCX', text: 'MET ENG PHY MET OTHER', value: '4NCX' },
  { key: '4NDX', text: 'MET ENG POW MET OTHER', value: '4NDX' },
  { key: '4VLX', text: 'METALLURGICAL ENG-TEC-OTHER', value: '4VLX' },
  { key: '1AMT', text: 'MGMT OF TECHNOLOGY', value: '1AMT' },
  { key: '1BAA', text: 'MIL OPERATIONAL ART/SCI', value: '1BAA' },
  { key: '5BXY', text: 'MIL-JUSTICE OTHER', value: '5BXY' },
  { key: '1BYY', text: 'MILITARY ARTS AND SCIENCES', value: '1BYY' },
  { key: '4OAY', text: 'MIN/PET ENG MIN ENG', value: '4OAY' },
  { key: '4OAX', text: 'MIN/PET ENG MIN ENG OTHER', value: '4OAX' },
  { key: '4OBX', text: 'MIN/PET ENG PET ENG OTHER', value: '4OBX' },
  { key: '4OXY', text: 'MINING/PETROLEUM ENG OTHER', value: '4OXY' },
  { key: '4WMY', text: 'MODELING AND SIMULATION', value: '4WMY' },
  { key: '4LCD', text: 'Machine Design (Industrial Engineering)', value: '4LCD' },
  { key: '4MHE', text: 'Machine Design (Mechanical Engineering)', value: '4MHE' },
  { key: '4ICA', text: 'Magneto-Electric Devices', value: '4ICA' },
  { key: '8HNI', text: 'Magneto-Fluid Dynamics', value: '8HNI' },
  { key: '4AAI', text: 'Magneto-Gas-Dynamics', value: '4AAI' },
  { key: '8FDE', text: 'Magnetohydrodynamics', value: '8FDE' },
  { key: '4LEY', text: 'Maintenance Engineering', value: '4LEY' },
  { key: '1AMM', text: 'Maintenance Management', value: '1AMM' },
  { key: '4VJG', text: 'Maintenance Production Management', value: '4VJG' },
  { key: '2JFY', text: 'Maintenance technology', value: '2JFY' },
  { key: '1AJI', text: 'Management', value: '1AJI' },
  { key: '4RCB', text: 'Management Functions (Safety)', value: '4RCB' },
  { key: '1AME', text: 'Management Information Systems', value: '1AME' },
  { key: '1AFD', text: 'Management Research Techniques', value: '1AFD' },
  { key: '4LFB', text: 'Management Science', value: '4LFB' },
  { key: '1AJH', text: 'Management Technology Operations', value: '1AJH' },
  { key: '2ACY', text: 'Management and Administration (Chaplaincy)', value: '2ACY' },
  { key: '6JDY', text: 'Manifolds (Mathematics)', value: '6JDY' },
  { key: '4AFA', text: 'Manned Vehicle Dynamics', value: '4AFA' },
  { key: '9BED', text: 'Manpower and Labor Markets', value: '9BED' },
  { key: '1AWY', text: 'Manpower, Personnel and Training Analysis', value: '1AWY' },
  { key: '4VJB', text: 'Manufacturing Engineering Technology', value: '4VJB' },
  { key: '9CCB', text: 'Manufacturing Geography', value: '9CCB' },
  { key: '8HXE', text: 'Many Body Theory', value: '8HXE' },
  { key: '4HHB', text: 'Mapping', value: '4HHB' },
  { key: '4MEY', text: 'Marine Engineering', value: '4MEY' },
  { key: '8FEE', text: 'Marine Meteorology', value: '8FEE' },
  { key: '1ALC', text: 'Maritime Insurance', value: '1ALC' },
  { key: 'Z044', text: 'Marketing - Administration', value: 'Z044' },
  { key: '1ANY', text: 'Marketing--Business Marketing', value: '1ANY' },
  { key: '9FFE', text: 'Marketing--Industrial and Personnel Psychology', value: '9FFE' },
  { key: '1AKF', text: 'Marketing--Industrial or Production Management', value: '1AKF' },
  { key: '6HDY', text: 'Markov Processes (Mathematics)', value: '6HDY' },
  { key: '2AAA', text: 'Marriage Counseling', value: '2AAA' },
  { key: '9HCE', text: 'Marriage and Divorce', value: '9HCE' },
  { key: '5ACJ', text: 'Martial Law', value: '5ACJ' },
  { key: '8HEG', text: 'Masers', value: '8HEG' },
  { key: '9FJE', text: 'Mass Media Communication', value: '9FJE' },
  { key: '8CBH', text: 'Mass Spectroscopy--Analytical Chemistry', value: '8CBH' },
  { key: '8HCH', text: 'Mass Spectroscopy--Atom and Molecular Physics', value: '8HCH' },
  { key: '4GCH', text: 'Mass Transfer (Chemical Engineering)', value: '4GCH' },
  { key: '2HCX', text: 'Masters of Divinity', value: '2HCX' },
  { key: 'Z024', text: 'Masters of Social Work', value: 'Z024' },
  { key: '1CBC', text: 'Material Management, Inventory Management, and Hospital Procurement and  Supply', value: '1CBC' },
  { key: '4AGE', text: 'Materials (Engineering)--Aeronautical Engineering', value: '4AGE' },
  { key: '4EGC', text: 'Materials (Engineering)--Astronautical Engineering', value: '4EGC' },
  { key: '4KCY', text: 'Materials Elasticity and Plasticity', value: '4KCY' },
  { key: '4MFY', text: 'Materials Engineering', value: '4MFY' },
  { key: '4LGB', text: 'Materials Handling (Industrial Engineering)', value: '4LGB' },
  { key: '4QBB', text: 'Materials Handling (Nuclear Engineering)', value: '4QBB' },
  { key: '4MLY', text: 'Materials Science/Engineering (Electronic Materials)', value: '4MLY' },
  { key: '7EAF', text: 'Maternal Child Nursing', value: '7EAF' },
  { key: '3AGN', text: 'Mathematical Biophysics--Biology', value: '3AGN' },
  { key: '0YDN', text: 'Mathematical Biophysics--Inter-Area Specialization', value: '0YDN' },
  { key: '8HDN', text: 'Mathematical Biophysics--Physics', value: '8HDN' },
  { key: '4GBD', text: 'Mathematical Physics', value: '4GBD' },
  { key: '9HKB', text: 'Mathematical Sociology', value: '9HKB' },
  { key: '6YYY', text: 'Mathematics', value: '6YYY' },
  { key: '2BBE', text: 'Mathematics (College Teaching)', value: '2BBE' },
  { key: '2BIF', text: 'Mathematics (Secondary Teaching)', value: '2BIF' },
  { key: '6EYY', text: 'Mathematics of Resource Use', value: '6EYY' },
  { key: '9FCB', text: 'Maturity and Old Age', value: '9FCB' },
  { key: '4GBE', text: 'Measurement and Controls (Chemical Engineering)', value: '4GBE' },
  { key: '7FBB', text: 'Meat and Dairy Food Hygiene', value: '7FBB' },
  { key: '3BAI', text: 'Meat, Fish, Dairy and Poultry Products--Agriculture and Food Chemistry', value: '3BAI' },
  { key: '8CAI', text: 'Meat, Fish, Dairy and Poultry Products--Chemistry', value: '8CAI' },
  { key: '4VKI', text: 'Mechanical Design Technology', value: '4VKI' },
  { key: '4MYY', text: 'Mechanical Engineering', value: '4MYY' },
  { key: '4VKY', text: 'Mechanical Engineering Technology', value: '4VKY' },
  { key: '4VKJ', text: 'Mechanical Power Technology', value: '4VKJ' },
  { key: '4KCB', text: 'Mechanical Properties of Materials', value: '4KCB' },
  { key: '4GCI', text: 'Mechanical Separation', value: '4GCI' },
  { key: '4DCY', text: 'Mechanical Systems (Architectural Engineering)', value: '4DCY' },
  { key: '8HAF', text: 'Mechanical Vibrations and Shock', value: '8HAF' },
  { key: '8HIY', text: 'Mechanics', value: '8HIY' },
  { key: '4MBD', text: 'Mechanics of Compressible Fluids', value: '4MBD' },
  { key: '3BHY', text: 'Mechanized Agriculture', value: '3BHY' },
  { key: '7GAK', text: 'Medical Assistant', value: '7GAK' },
  { key: 'Z003', text: 'Medical Doctor (MD/DO)', value: 'Z003' },
  { key: '3ALH', text: 'Medical Entomology--Biology', value: '3ALH' },
  { key: '7ABH', text: 'Medical Entomology--Professional Services', value: '7ABH' },
  { key: '3ADA', text: 'Medical Geography--Biology', value: '3ADA' },
  { key: '9CAA', text: 'Medical Geography--Geography', value: '9CAA' },
  { key: '0YCA', text: 'Medical Geography--Inter-Area Specialization', value: '0YCA' },
  { key: '5ACK', text: 'Medical Jurisprudence', value: '5ACK' },
  { key: '7GAF', text: 'Medical Laboratory Technician and Medical Laboratory Technology', value: '7GAF' },
  { key: '2CGD', text: 'Medical Library', value: '2CGD' },
  { key: '7CAG', text: 'Medical Material', value: '7CAG' },
  { key: '7CAH', text: 'Medical Records', value: '7CAH' },
  { key: '7YYY', text: 'Medical Sciences', value: '7YYY' },
  { key: '9HFD', text: 'Medical Sociology', value: '9HFD' },
  { key: '7GDY', text: 'Medical Technology', value: '7GDY' },
  { key: '7DYY', text: 'Medicine and Surgery', value: '7DYY' },
  { key: '2HDC', text: 'Medieval Christianity', value: '2HDC' },
  { key: '9DEY', text: 'Medieval History', value: '9DEY' },
  { key: '2GGB', text: 'Medieval Philosophy', value: '2GGB' },
  { key: '2GLF', text: 'Memory', value: '2GLF' },
  { key: '9FAF', text: 'Mental Deficiency', value: '9FAF' },
  { key: '9HHD', text: 'Mental Health', value: '9HHD' },
  { key: '8FEB', text: 'Mesometeorology', value: '8FEB' },
  { key: '4NDA', text: 'Metal Powder Part Fabrication', value: '4NDA' },
  { key: '4NDB', text: 'Metal Powder Production', value: '4NDB' },
  { key: '4NYY', text: 'Metallurgical Engineering', value: '4NYY' },
  { key: '4VLY', text: 'Metallurgical Engineering Technology', value: '4VLY' },
  { key: '8HJY', text: 'Metallurgy', value: '8HJY' },
  { key: '2CFH', text: 'Metals (Other than Jewelry)', value: '2CFH' },
  { key: '4VLB', text: 'Metalworking Technology', value: '4VLB' },
  { key: '2GIF', text: 'Metaphysical Principles', value: '2GIF' },
  { key: '2GIY', text: 'Metaphysics', value: '2GIY' },
  { key: '8FGE', text: 'Meteorological Computer Software Development', value: '8FGE' },
  { key: '4VHI', text: 'Meteorological Equipment Technology', value: '4VHI' },
  { key: '8DDF', text: 'Meteorological Geophysics', value: '8DDF' },
  { key: '8FCB', text: 'Meteorological Radar Instrumentation', value: '8FCB' },
  { key: '8FCA', text: 'Meteorological Satellite Instrumentation', value: '8FCA' },
  { key: '8FCC', text: 'Meteorological Sensing Instrumentation', value: '8FCC' },
  { key: '8FYY', text: 'Meteorology', value: '8FYY' },
  { key: '8FGB', text: 'Meteorology ADP Distribution', value: '8FGB' },
  { key: '8FXX', text: 'Meteorology Other', value: '8FXX' },
  { key: '9EDC', text: 'Method and Scope (Political Science)', value: '9EDC' },
  { key: '2GHB', text: 'Methodology (Logic)', value: '2GHB' },
  { key: '2BJB', text: 'Methodology (Special Teacher Training) and Instructional Methodology', value: '2BJB' },
  { key: '2GJB', text: 'Methodology of the Biological Sciences (Philosophy)', value: '2GJB' },
  { key: '2GJC', text: 'Methodology of the Physical Sciences (Philosophy)', value: '2GJC' },
  { key: '3AGO', text: 'Methodology, Instrumentation and Measurement (Biophysical)--Biology', value: '3AGO' },
  { key: '0YDO', text: 'Methodology, Instrumentation and Measurement (Biophysical)--Inter-Area  Specialization', value: '0YDO' },
  { key: '8HDO', text: 'Methodology, Instrumentation and Measurement (Biophysical)--Physics', value: '8HDO' },
  { key: '9DHC', text: 'Mexico (History)', value: '9DHC' },
  { key: '3ACM', text: 'Microbiological Chemistry--Biology', value: '3ACM' },
  { key: '8CCM', text: 'Microbiological Chemistry--Chemistry', value: '8CCM' },
  { key: '0YBM', text: 'Microbiological Chemistry--Inter-Area Specialization', value: '0YBM' },
  { key: '7AAE', text: 'Microbiology (Basic Biomedical Sciences)', value: '7AAE' },
  { key: '8CBI', text: 'Microchemistry', value: '8CBI' },
  { key: '8FBD', text: 'Microclimatology', value: '8FBD' },
  { key: '8FEC', text: 'Micrometeorology', value: '8FEC' },
  { key: '3APA', text: 'Micropaleontology--Biology', value: '3APA' },
  { key: '0YFA', text: 'Micropaleontology--Inter-Area Specialization', value: '0YFA' },
  { key: '8DEA', text: 'Micropaleontology--Paleontology', value: '8DEA' },
  { key: '4VIA', text: 'Microprecision Technology', value: '4VIA' },
  { key: '4ICB', text: 'Microwave Tubes', value: '4ICB' },
  { key: '8HEH', text: 'Microwaves', value: '8HEH' },
  { key: '9DFY', text: 'Middle East (History)', value: '9DFY' },
  { key: '2GKC', text: 'Middle East (Philosophy)', value: '2GKC' },
  { key: '7EAH', text: 'Midwifery', value: '7EAH' },
  { key: '5BAY', text: 'Military Criminal Law', value: '5BAY' },
  { key: '9CDY', text: 'Military Geography--Geography', value: '9CDY' },
  { key: '8DFD', text: 'Military Geography--Physical Geography', value: '8DFD' },
  { key: '9DJG', text: 'Military History', value: '9DJG' },
  { key: '5BYY', text: 'Military Justice', value: '5BYY' },
  { key: '5ACL', text: 'Military Law', value: '5ACL' },
  { key: '9HFE', text: 'Military Sociology', value: '9HFE' },
  { key: '4NAC', text: 'Mill Design', value: '4NAC' },
  { key: '4OAB', text: 'Mine Development', value: '4OAB' },
  { key: '4OAC', text: 'Mine Exploration', value: '4OAC' },
  { key: '4OAD', text: 'Mine Production', value: '4OAD' },
  { key: '8DAE', text: 'Mineral Synthesis and Stability Relations of Minerals', value: '8DAE' },
  { key: '8DCI', text: 'Mineralogy', value: '8DCI' },
  { key: '4AOY', text: 'Mining Engineering', value: '4AOY' },
  { key: '4OYY', text: 'Mining and Petroleum Engineering', value: '4OYY' },
  { key: 'Z072', text: 'Ministry', value: 'Z072' },
  { key: '4VAK', text: 'Missile Maintenance Technology', value: '4VAK' },
  { key: '4RDB', text: 'Missile Systems (Systems Safety)', value: '4RDB' },
  { key: '4GBF', text: 'Mixers (chemical engineering)', value: '4GBF' },
  { key: '4GCJ', text: 'Mixing', value: '4GCJ' },
  { key: '4PDY', text: 'Model Basin Studies', value: '4PDY' },
  { key: '4TGY', text: 'Modeling (Systems Engineering)', value: '4TGY' },
  { key: '9HKC', text: 'Models (Social Theory)', value: '9HKC' },
  { key: '2CJC', text: 'Modern Dance', value: '2CJC' },
  { key: '2GGC', text: 'Modern Philosophy', value: '2GGC' },
  { key: '9GDE', text: 'Modification and Control', value: '9GDE' },
  { key: '9DKJ', text: 'Moldavian SSR (History)', value: '9DKJ' },
  { key: '3AGP', text: 'Molecular Biophysics--Biology', value: '3AGP' },
  { key: '0YDP', text: 'Molecular Biophysics--Inter-Area Specialization', value: '0YDP' },
  { key: '8HDP', text: 'Molecular Biophysics--Physics', value: '8HDP' },
  { key: '4ICC', text: 'Molecular Electronics', value: '4ICC' },
  { key: '8CHM', text: 'Molecular Structure', value: '8CHM' },
  { key: 'Z032', text: 'Molecular and Cellular Biology', value: 'Z032' },
  { key: '9BGD', text: 'Monetary Theory and Policy', value: '9BGD' },
  { key: '9BGY', text: 'Money, Credit, Banking', value: '9BGY' },
  { key: '2GFB', text: 'Moral Acts and Habits', value: '2GFB' },
  { key: '2GFC', text: 'Moral Judgement; Value', value: '2GFC' },
  { key: '2GFD', text: 'Moral Laws', value: '2GFD' },
  { key: '2HCC', text: 'Moral Theology', value: '2HCC' },
  { key: '2GFE', text: 'Moral Virtues', value: '2GFE' },
  { key: '9IGY', text: 'Mortuary Science', value: '9IGY' },
  { key: '2CCF', text: 'Mosaic', value: '2CCF' },
  { key: '8BBD', text: 'Mosaic Preparation', value: '8BBD' },
  { key: '8HXG', text: 'Mossbauer Effect', value: '8HXG' },
  { key: '8GAB', text: 'Motion Picture Photography', value: '8GAB' },
  { key: '2FDC', text: 'Motion Pictures', value: '2FDC' },
  { key: '9FEH', text: 'Motivation', value: '9FEH' },
  { key: '5ACM', text: 'Motor Carriers Law', value: '5ACM' },
  { key: '6IEY', text: 'Multivariate Analysis (Mathematics)', value: '6IEY' },
  { key: '4VRA', text: 'Munitions Systems Technology', value: '4VRA' },
  { key: '2CCG', text: 'Mural', value: '2CCG' },
  { key: '2CHY', text: 'Music (Fine and Applied Arts)', value: '2CHY' },
  { key: '2BIG', text: 'Music (Secondary Teaching)', value: '2BIG' },
  { key: '8HAG', text: 'Musical Instruments and Music', value: '8HAG' },
  { key: '7ABI', text: 'Mycology', value: '7ABI' },
  { key: 'CODE', text: 'NAME', value: 'CODE' },
  { key: '0YVY', text: 'NAT SEC  AND  STRAT STUDIES', value: '0YVY' },
  { key: '9EHY', text: 'NATIONAL RESOURCES STRATEGY', value: '9EHY' },
  { key: '9EGY', text: 'NATIONAL SECURITY STRATEGY', value: '9EGY' },
  { key: '4PXY', text: 'NAV ARC OTHER', value: '4PXY' },
  { key: 'YYYY', text: 'NO ACADEMIC SPECIALTY APPL', value: 'YYYY' },
  { key: '4VXR', text: 'NONDESTRUCTIVE TESTING TECHNOLOGY', value: '4VXR' },
  { key: '2DYX', text: 'NONE SPECIFIC/OTHER', value: '2DYX' },
  { key: '4QAX', text: 'NUC ENG INST OTHER', value: '4QAX' },
  { key: '4QBX', text: 'NUC ENG NUC PROC OTHER', value: '4QBX' },
  { key: '4QBD', text: 'NUC ENG NUC PROC RADIOCHEM', value: '4QBD' },
  { key: '4QCX', text: 'NUC ENG NUC/RAD EFF OTHER', value: '4QCX' },
  { key: '4QDX', text: 'NUC RCTR ENG OTHER', value: '4QDX' },
  { key: '4QXY', text: 'NUCLEAR ENGRG OTHER', value: '4QXY' },
  { key: '4VMX', text: 'NUCLEAR SCIENCE-TECH-OTHER', value: '4VMX' },
  { key: '6GXY', text: 'NUM METHDS-OTHER', value: '6GXY' },
  { key: '0CDY', text: 'NUM METHODS IN EDP', value: '0CDY' },
  { key: '0CDX', text: 'NUM METHODS/COMP', value: '0CDX' },
  { key: '6FXY', text: 'NUMBER THEORY-OTHER', value: '6FXY' },
  { key: '7EAX', text: 'NUR SCI-CLIN-OTHER', value: '7EAX' },
  { key: '7EBX', text: 'NUR SCI-NUR ADM-OTHER', value: '7EBX' },
  { key: '7EBB', text: 'NUR SCI-NUR ADM-PERS MGMT', value: '7EBB' },
  { key: '7EXY', text: 'NUR SCI-NURS SCIENCE OTHER', value: '7EXY' },
  { key: '7EBD', text: 'NUR/MASTER BUS ADMIN', value: '7EBD' },
  { key: '7EBE', text: 'NURSE HEALTH CARE ADMINISTRATION', value: '7EBE' },
  { key: '7EBK', text: 'NURSE INFORMATICS', value: '7EBK' },
  { key: '7EBJ', text: 'NURSE MANAGED CARE', value: '7EBJ' },
  { key: '7EBI', text: 'NURSE UTILIZATION MANAGEMENT', value: '7EBI' },
  { key: '7GEX', text: 'NURSG-SCI-TECH OTHER', value: '7GEX' },
  { key: '7EAS', text: 'NURSING CLINICAL/BUSINESS ADMINISTRATION', value: '7EAS' },
  { key: '7EBN', text: 'NURSING COMMUNITY SYSTEMS MANAGEMENT', value: '7EBN' },
  { key: '9BHY', text: 'National Defense Economics', value: '9BHY' },
  { key: '9EFY', text: 'National Security Studies', value: '9EFY' },
  { key: '3ACN', text: 'Natural Pigments--Biology', value: '3ACN' },
  { key: '8CCN', text: 'Natural Pigments--Chemistry', value: '8CCN' },
  { key: '0YBN', text: 'Natural Pigments--Inter-Area Specialization', value: '0YBN' },
  { key: '9BFC', text: 'Natural Resources', value: '9BFC' },
  { key: '5ABL', text: 'Natural Resources Law', value: '5ABL' },
  { key: '2BIH', text: 'Natural Sciences (Secondary Teaching)', value: '2BIH' },
  { key: '2GJY', text: 'Natural and Scientific Philosophy', value: '2GJY' },
  { key: '4PYY', text: 'Naval Architecture', value: '4PYY' },
  { key: '9DGY', text: 'Near East (History)', value: '9DGY' },
  { key: '5ABM', text: 'Negligence Law', value: '5ABM' },
  { key: '1AEE', text: 'Negotiable Instruments Law', value: '1AEE' },
  { key: '1AMF', text: 'Negotiations Management', value: '1AMF' },
  { key: '2GBI', text: 'Neo-Kantianism', value: '2GBI' },
  { key: '2GBJ', text: 'Neopositivism', value: '2GBJ' },
  { key: '2GBK', text: 'Neorealism (English)', value: '2GBK' },
  { key: '7DWA', text: 'Nephrology', value: '7DWA' },
  { key: 'Z073', text: 'Network Engineering and Security ', value: 'Z073' },
  { key: '3ACO', text: 'Neurochemistry--Biology', value: '3ACO' },
  { key: '8CCO', text: 'Neurochemistry--Chemistry', value: '8CCO' },
  { key: '0YBO', text: 'Neurochemistry--Inter-Area Specialization', value: '0YBO' },
  { key: '7DHY', text: 'Neurology', value: '7DHY' },
  { key: 'Z018', text: 'Neuroscience', value: 'Z018' },
  { key: '7DIY', text: 'Neurosurgery', value: '7DIY' },
  { key: '8HLC', text: 'Neutrons', value: '8HLC' },
  { key: '9ABA', text: 'New World Archaeology', value: '9ABA' },
  { key: '8CEP', text: 'Nitrogen Family', value: '8CEP' },
  { key: '8HAH', text: 'Noise', value: '8HAH' },
  { key: '6GMY', text: 'Nomography Tables (Mathematics)', value: '6GMY' },
  { key: '4EED', text: 'Non-Chemical Propulsion', value: '4EED' },
  { key: '4AAJ', text: 'Non-Continuum Gas Dynamics', value: '4AAJ' },
  { key: '3BAJ', text: 'Nonalcoholic Beverages--Agriculature and Food Chemistry', value: '3BAJ' },
  { key: '8CAJ', text: 'Nonalcoholic Beverages--Chemistry', value: '8CAJ' },
  { key: '9FBC', text: 'Nondirective Therapy', value: '9FBC' },
  { key: '4NAD', text: 'Nonferrous Extraction', value: '4NAD' },
  { key: '2FFD', text: 'Nonfiction', value: '2FFD' },
  { key: '3BAK', text: 'Nonfood Crop Products--Agriculture and Food Chemistry', value: '3BAK' },
  { key: '8CAK', text: 'Nonfood Crop Products--Chemistry', value: '8CAK' },
  { key: '6IFY', text: 'Nonparametric Methods (Mathematics)', value: '6IFY' },
  { key: '8BBE', text: 'Nontopographic Photogrammetry', value: '8BBE' },
  { key: '3AIA', text: 'Nonvascular Plants', value: '3AIA' },
  { key: '6DEY', text: 'Normal and Symbolic Logic (Mathematics)', value: '6DEY' },
  { key: '9DAB', text: 'North Africa (History)', value: '9DAB' },
  { key: '9DHY', text: 'North America incl. Central America (History)', value: '9DHY' },
  { key: '8CFY', text: 'Nuclear Chemistry', value: '8CFY' },
  { key: '8HKC', text: 'Nuclear Effects', value: '8HKC' },
  { key: '8HKY', text: 'Nuclear Effects Physics', value: '8HKY' },
  { key: '4QYY', text: 'Nuclear Engineering', value: '4QYY' },
  { key: '4QEY', text: 'Nuclear Engineering - Nuclear Survivability and Vulnerability', value: '4QEY' },
  { key: '4QEG', text: 'Nuclear Engineering - Nuclear Survivability and Vulnerability to Blackout', value: '4QEG' },
  { key: '4QEA', text: 'Nuclear Engineering - Nuclear Survivability and Vulnerability to Blast, Shock and Thermal', value: '4QEA' },
  { key: '4QEF', text: 'Nuclear Engineering - Nuclear Survivability and Vulnerability to Dust and Debris', value: '4QEF' },
  { key: '7ABJ', text: 'Nuclear Health Physics', value: '7ABJ' },
  { key: '8HCI', text: 'Nuclear Magnetic Resonance', value: '8HCI' },
  { key: 'Z008', text: 'Nuclear Medicine and Therapeutic Radiology', value: 'Z008' },
  { key: '8HLY', text: 'Nuclear Physics', value: '8HLY' },
  { key: '4MGD', text: 'Nuclear Power Plants', value: '4MGD' },
  { key: '4QBY', text: 'Nuclear Processes--Nuclear Engineering', value: '4QBY' },
  { key: '4GCK', text: 'Nuclear Processes--Unit Operations, Ceramic Engineering', value: '4GCK' },
  { key: '8HLD', text: 'Nuclear Properties', value: '8HLD' },
  { key: '4EEE', text: 'Nuclear Propulsion', value: '4EEE' },
  { key: '8HLE', text: 'Nuclear Reactions and Scattering', value: '8HLE' },
  { key: '4QDY', text: 'Nuclear Reactor Engineering', value: '4QDY' },
  { key: '4VMY', text: 'Nuclear Science Technology', value: '4VMY' },
  { key: '4QBC', text: 'Nuclear Spectroscopy--Nuclear Engineering', value: '4QBC' },
  { key: '8HLF', text: 'Nuclear Spectroscopy--Nuclear Physics', value: '8HLF' },
  { key: '4QEH', text: 'Nuclear Survivability and Vulnerability to Transient Radiation Effects on Electronics (TREE)--Nuclear Engineering', value: '4QEH' },
  { key: '4QEX', text: 'Nuclear Survivability and Vulnerability to other Effects than those  Specified--Nuclear Engineering', value: '4QEX' },
  { key: '4VHJ', text: 'Nuclear Technology', value: '4VHJ' },
  { key: '4QCY', text: 'Nuclear and Radiation Effects', value: '4QCY' },
  { key: '3ACP', text: 'Nucleic-Acids--Biology', value: '3ACP' },
  { key: '8CCP', text: 'Nucleic-Acids--Chemistry', value: '8CCP' },
  { key: '0YBP', text: 'Nucleic-Acids--Inter-Area Specialization', value: '0YBP' },
  { key: '8CBJ', text: 'Nucleonics', value: '8CBJ' },
  { key: '6FYY', text: 'Number Theory', value: '6FYY' },
  { key: '6GNY', text: 'Numerical Differentiation, Quadrature', value: '6GNY' },
  { key: '6GYY', text: 'Numerical Methods and Computation', value: '6GYY' },
  { key: '6GOY', text: 'Numerical Solutions of Ordinary Differential Equations', value: '6GOY' },
  { key: '6GPY', text: 'Numerical Solutions of Partial Differential Equations', value: '6GPY' },
  { key: '8FAC', text: 'Numerical Weather Prediction', value: '8FAC' },
  { key: '7EAP', text: 'Nurse Supervision', value: '7EAP' },
  { key: '7EAE', text: 'Nurse Supervision', value: '7EAE' },
  { key: '9FCC', text: 'Nursery and Pre-School Psychology', value: '9FCC' },
  { key: '7EBT', text: 'Nursing', value: '7EBT' },
  { key: '7EBU', text: 'Nursing', value: '7EBU' },
  { key: '7EBY', text: 'Nursing Administration', value: '7EBY' },
  { key: '7EBA', text: 'Nursing Education', value: '7EBA' },
  { key: '7GCB', text: 'Nursing Home Administration', value: '7GCB' },
  { key: '7EAI', text: 'Nursing Science', value: '7EAI' },
  { key: '7GEY', text: 'Nursing Science Technology', value: '7GEY' },
  { key: '3AOC', text: 'Nutrient Value of Foods', value: '3AOC' },
  { key: '3AOY', text: 'Nutrition', value: '3AOY' },
  { key: '2CEF', text: 'Nutrition Technology', value: '2CEF' },
  { key: '3ABB', text: 'Nutrition and Physiology (Bacteriology)', value: '3ABB' },
  { key: '0YEA', text: 'OPERATIONAL ANALYSIS', value: '0YEA' },
  { key: '0YSY', text: 'OPS RES, STRAT and TACT. Strategic and Tactical Sciences', value: '0YSY' },
  { key: '9FAG', text: 'Objective Test', value: '9FAG' },
  { key: '4ILY', text: 'Observables Reduction', value: '4ILY' },
  { key: '7DJY', text: 'Obstetrics and Gynecology', value: '7DJY' },
  { key: '2IBC', text: 'Occupational Education Technology', value: '2IBC' },
  { key: '4RBA', text: 'Occupational Hazards', value: '4RBA' },
  { key: '7DRC', text: 'Occupational Medicine (Industrial Medicine)', value: '7DRC' },
  { key: '9HFF', text: 'Occupational Sociology', value: '9HFF' },
  { key: '2JYY', text: 'Occupational Technology', value: '2JYY' },
  { key: '7ABK', text: 'Occupational Therapy', value: '7ABK' },
  { key: '8EBE', text: 'Ocean-Bottom Processes', value: '8EBE' },
  { key: '8EBY', text: 'Oceanography--Hydrospheric', value: '8EBY' },
  { key: '8DFE', text: 'Oceanography--Physical Geography', value: '8DFE' },
  { key: '2CIB', text: 'Oil Painting', value: '2CIB' },
  { key: '2CIC', text: 'Oil Portrait Painting', value: '2CIC' },
  { key: '8CGK', text: 'Oils, Fats, Waxes', value: '8CGK' },
  { key: '9ABB', text: 'Old World Archoeology', value: '9ABB' },
  { key: '7EAQ', text: 'Oncology Nursing', value: '7EAQ' },
  { key: '3ACQ', text: 'Oncology, Carciogenesis--Biology', value: '3ACQ' },
  { key: '8CCQ', text: 'Oncology, Carciogenesis--Chemistry', value: '8CCQ' },
  { key: '0YBQ', text: 'Oncology, Carciogenesis--Inter-Area Specialization', value: '0YBQ' },
  { key: '4OAE', text: 'Open Cut Mining', value: '4OAE' },
  { key: '7GEA', text: 'Operating Room Technician and Operating Room Technology', value: '7GEA' },
  { key: '1AKG', text: 'Operation Research, Industrial Or Production--Management', value: '1AKG' },
  { key: '4LFC', text: 'Operation Research--Industrial Engineering', value: '4LFC' },
  { key: '0YEY', text: 'Operation Research--Inter-Area Specialization', value: '0YEY' },
  { key: '6EMY', text: 'Operation Research--Mathematics of Resource Use', value: '6EMY' },
  { key: '8HXH', text: 'Operation Research--Physics', value: '8HXH' },
  { key: '4THY', text: 'Operation Research--Systems Engineering', value: '4THY' },
  { key: '4LFY', text: 'Operational Analysis', value: '4LFY' },
  { key: '6BLY', text: 'Operational Calculus', value: '6BLY' },
  { key: '1AJG', text: 'Operations Management', value: '1AJG' },
  { key: 'OYEC', text: 'Operations Research Command and Control', value: 'OYEC' },
  { key: '0YEC', text: 'Operations Research Command and Control', value: '0YEC' },
  { key: '0YET', text: 'Operations Research Operational Test and Evaluation', value: '0YET' },
  { key: 'OYET', text: 'Operations Research Operational Test and Evaluation', value: 'OYET' },
  { key: '7GDI', text: 'Opthalmic Technology', value: '7GDI' },
  { key: '7DKY', text: 'Opthalmology', value: '7DKY' },
  { key: '8GAC', text: 'Optical Instrumentation Photography', value: '8GAC' },
  { key: '8HML', text: 'Optical Instruments', value: '8HML' },
  { key: '8HMM', text: 'Optical Materials', value: '8HMM' },
  { key: '8HMY', text: 'Optics', value: '8HMY' },
  { key: '4TIY', text: 'Optimizations', value: '4TIY' },
  { key: '7GAG', text: 'Optometric Technician And Optometric Technology', value: '7GAG' },
  { key: '7ABL', text: 'Optometry', value: '7ABL' },
  { key: '7BAB', text: 'Oral Diagnosis', value: '7BAB' },
  { key: '2FEA', text: 'Oral Interpretation', value: '2FEA' },
  { key: '7BAC', text: 'Oral Medicine', value: '7BAC' },
  { key: '7BBY', text: 'Oral Pathology', value: '7BBY' },
  { key: '7BCY', text: 'Oral Surgery', value: '7BCY' },
  { key: '4RDC', text: 'Orbital Vehicle Systems', value: '4RDC' },
  { key: '6DFY', text: 'Order, Total and Partial (Mathematics)', value: '6DFY' },
  { key: '6BMY', text: 'Ordinary Differential Equations', value: '6BMY' },
  { key: '8CGY', text: 'Organic Chemistry', value: '8CGY' },
  { key: '8DAF', text: 'Organic Geochemistry', value: '8DAF' },
  { key: '1AOG', text: 'Organization Planning and Development', value: '1AOG' },
  { key: '9GDY', text: 'Organizational Theory and Management Concepts', value: '9GDY' },
  { key: '8CGL', text: 'Organometallics', value: '8CGL' },
  { key: '2GKY', text: 'Oriental Philosophy', value: '2GKY' },
  { key: '8AAI', text: 'Origin of Cosmic Rays', value: '8AAI' },
  { key: '7BDY', text: 'Orthodontics', value: '7BDY' },
  { key: '7GDF', text: 'Orthopedic Assistant', value: '7GDF' },
  { key: '7DLY', text: 'Orthopedic Surgery', value: '7DLY' },
  { key: 'Z004', text: 'Osteopathic Doctor (DO)', value: 'Z004' },
  { key: '7DZY', text: 'Osteopathic Medicine', value: '7DZY' },
  { key: 'Other', text: 'Other', value: 'Other' },
  { key: '8CXY', text: 'Other Chemistry Specialties', value: '8CXY' },
  { key: '7GDG', text: 'Otholaryngology Technician and Otholaryngology Technology', value: '7GDG' },
  { key: '7DMY', text: 'Otorhinolaryngology', value: '7DMY' },
  { key: '8CER', text: 'Oxygen Family', value: '8CER' },
  { key: '0YFX', text: 'PALEONTOLOGY OTHER', value: '0YFX' },
  { key: '7GDP', text: 'PARARESCUE OPERATIONS', value: '7GDP' },
  { key: '7EBL', text: 'PEDIATRIC NURSE PRACTITIONER', value: '7EBL' },
  { key: '7DEC', text: 'PEDIATRIC SURGERY', value: '7DEC' },
  { key: '7BEX', text: 'PERIODONTICS MASTERS', value: '7BEX' },
  { key: '7EBH', text: 'PERIOPERATIVE NURSING', value: '7EBH' },
  { key: '2DYQ', text: 'PERSIAN FARSI', value: '2DYQ' },
  { key: '4VNX', text: 'PETROLEUM ENG-TECH-OTHER', value: '4VNX' },
  { key: '2GAX', text: 'PHIL AEST OTHER', value: '2GAX' },
  { key: '2GBX', text: 'PHIL CONT PHL OTHER', value: '2GBX' },
  { key: '2GCX', text: 'PHIL CUL PHIL OTHER', value: '2GCX' },
  { key: '2GDX', text: 'PHIL EMP PSY  OTHER', value: '2GDX' },
  { key: '2GEX', text: 'PHIL EPISTE OTHER', value: '2GEX' },
  { key: '2GFX', text: 'PHIL ETH OTHER', value: '2GFX' },
  { key: '2GGX', text: 'PHIL HST W PHILOS OTHER', value: '2GGX' },
  { key: '2GHX', text: 'PHIL LOGIC OTHER', value: '2GHX' },
  { key: '2GIX', text: 'PHIL METAP OTHER', value: '2GIX' },
  { key: '2GJX', text: 'PHIL N AND S PHIL OTHER', value: '2GJX' },
  { key: '2GKX', text: 'PHIL ORIEN PHILO  OTHER', value: '2GKX' },
  { key: '2GLX', text: 'PHIL PHIL PSY OTHER', value: '2GLX' },
  { key: '2GMX', text: 'PHIL RAT THE OTHER', value: '2GMX' },
  { key: '2GXY', text: 'PHILOSOPHY OTHERS', value: '2GXY' },
  { key: '8GAX', text: 'PHOTO SCI PHOTO OTHER', value: '8GAX' },
  { key: '8GXY', text: 'PHOTO SCI PHOTO SCI OTHER', value: '8GXY' },
  { key: '8HGX', text: 'PHYS ELE PAR PHY OTHER', value: '8HGX' },
  { key: '8HFX', text: 'PHYS ELECT OTHER', value: '8HFX' },
  { key: '8HIX', text: 'PHYS MECH OTHER', value: '8HIX' },
  { key: '8HKB', text: 'PHYS NUC EFF PHYS BLAS EFF', value: '8HKB' },
  { key: '8HKX', text: 'PHYS NUC EFF PHYS OTHER', value: '8HKX' },
  { key: '8HLX', text: 'PHYS NUC PHYS OTHER', value: '8HLX' },
  { key: '8HMP', text: 'PHYS OPT PHYSIOLOGICAL OPTI', value: '8HMP' },
  { key: '8HMX', text: 'PHYS OPTICS OTHER', value: '8HMX' },
  { key: '8HXF', text: 'PHYS OTH MATHEMATICAL PHYS', value: '8HXF' },
  { key: '8HXX', text: 'PHYS OTH OTHER', value: '8HXX' },
  { key: '8HNX', text: 'PHYS PHYS FLU OTHER', value: '8HNX' },
  { key: '8HOX', text: 'PHYS SOL ST PHYS OTHER', value: '8HOX' },
  { key: '8HPC', text: 'PHYS THER PHYS LO TEMP PHYS', value: '8HPC' },
  { key: '8HPX', text: 'PHYS THER PHYS OTHER', value: '8HPX' },
  { key: '8HCX', text: 'PHYSIC ATOM-MOL OTHER', value: '8HCX' },
  { key: '8HOZ', text: 'PHYSICS  SPACE PHYSICS', value: '8HOZ' },
  { key: '8HAX', text: 'PHYSICS ACOUSTICS OTHER', value: '8HAX' },
  { key: '8HDX', text: 'PHYSICS BIOPHYS OTHER', value: '8HDX' },
  { key: '8HDQ', text: 'PHYSICS BIOPHYS RADIATION', value: '8HDQ' },
  { key: '8HEX', text: 'PHYSICS ELECTROMAG OTHER', value: '8HEX' },
  { key: '8HHY', text: 'PHYSICS ENGINEERING PHYSICS', value: '8HHY' },
  { key: '8HXY', text: 'PHYSICS OTHER', value: '8HXY' },
  { key: '3BTY', text: 'PHYSIOLOGY AND NEUR0BIOLOGY', value: '3BTY' },
  { key: '9EAX', text: 'POL SCI COMP GOV OTHER', value: '9EAX' },
  { key: '9ECX', text: 'POL SCI INT REL OTHER', value: '9ECX' },
  { key: '9EXY', text: 'POL SCI POL SCIENCE OTHER', value: '9EXY' },
  { key: '9EEX', text: 'POL SCI PUBLIC LAW OTHER', value: '9EEX' },
  { key: '9EDX', text: 'POL SCI THEORY-BEH PTHER', value: '9EDX' },
  { key: '2DYI', text: 'POLISH', value: '2DYI' },
  { key: '2DYJ', text: 'PORT BRAZ (BRAZILIAN)', value: '2DYJ' },
  { key: '2IDX', text: 'PPSYCH-TECH OTHER', value: '2IDX' },
  { key: '6HXY', text: 'PROB-OTHER', value: '6HXY' },
  { key: '6HYY', text: 'PROBABILITY', value: '6HYY' },
  { key: '9FAX', text: 'PSYCH CLIN OTHER', value: '9FAX' },
  { key: '9FBX', text: 'PSYCH COUNSEL-GUID OTHER', value: '9FBX' },
  { key: '9FCX', text: 'PSYCH DEVELOP OTHER', value: '9FCX' },
  { key: '9FCY', text: 'PSYCH DEVELOPMENTAL PSYCH', value: '9FCY' },
  { key: '9FDA', text: 'PSYCH EDUCATION MEASUREMNT', value: '9FDA' },
  { key: '9FDX', text: 'PSYCH EDUCATION OTHER', value: '9FDX' },
  { key: '9FEX', text: 'PSYCH EXP OTHER', value: '9FEX' },
  { key: '9FFX', text: 'PSYCH INDUST-PERS OTHER', value: '9FFX' },
  { key: '9FFF', text: 'PSYCH INDUST-PERS PERF RTG', value: '9FFF' },
  { key: '9FGX', text: 'PSYCH PERSONALITY OTHER', value: '9FGX' },
  { key: '9FXY', text: 'PSYCH PSYCHOLOGY OTHER', value: '9FXY' },
  { key: '9FIX', text: 'PSYCH PSYCHOMETR OTHER', value: '9FIX' },
  { key: '9FJX', text: 'PSYCH SOCIAL OTHER', value: '9FJX' },
  { key: '7EAU', text: 'PSYCHIATRIC AND MENTAL HEALTH NURSE PRACTITIONER', value: '7EAU' },
  { key: '9FIG', text: 'PSYCHO-PHARMACOLOGY', value: '9FIG' },
  { key: '9GAX', text: 'PUB ADM ADM POL OTHER', value: '9GAX' },
  { key: '9GXY', text: 'PUB ADM ADM-OTHER', value: '9GXY' },
  { key: '9GEX', text: 'PUB ADM BUD-FIN OTHER', value: '9GEX' },
  { key: '9GBX', text: 'PUB ADM CONST-ADM LAW OTH', value: '9GBX' },
  { key: '9GCB', text: 'PUB ADM INTERNAT LAW-REGUL', value: '9GCB' },
  { key: '9GCX', text: 'PUB ADM INTERNAT OTHER', value: '9GCX' },
  { key: '9GDX', text: 'PUB ADM ORG THEO OTHER', value: '9GDX' },
  { key: '9GFX', text: 'PUB ADM PER-MGT OTHER', value: '9GFX' },
  { key: '9IXY', text: 'PUBC-SERV-OCC-TECH OTHER', value: '9IXY' },
  { key: '2FDE', text: 'PUBLIC INFORMATION', value: '2FDE' },
  { key: '9DDI', text: 'Pacific Islands (History)', value: '9DDI' },
  { key: '4VJC', text: 'Packaging Technology', value: '4VJC' },
  { key: '2CFI', text: 'Packaging--Industrial Art--Design', value: '2CFI' },
  { key: '4LGC', text: 'Packaging--Industrial Engineering', value: '4LGC' },
  { key: '2CIY', text: 'Painting', value: '2CIY' },
  { key: '9DFC', text: 'Pakistan (History)', value: '9DFC' },
  { key: '3APB', text: 'Paleobotany--Biology', value: '3APB' },
  { key: '0YFB', text: 'Paleobotany--Inter-Area Specialization', value: '0YFB' },
  { key: '8DEB', text: 'Paleobotany--Paleontology', value: '8DEB' },
  { key: '3APY', text: 'Paleontology--Biology', value: '3APY' },
  { key: '8DEY', text: 'Paleontology--Geology', value: '8DEY' },
  { key: '0YFY', text: 'Paleontology--Inter-Area Specialization', value: '0YFY' },
  { key: '3APC', text: 'Paleozoology--Biology', value: '3APC' },
  { key: '0YFC', text: 'Paleozoology--Inter-Area Specialization', value: '0YFC' },
  { key: '8DEC', text: 'Paleozoology--Paleontology', value: '8DEC' },
  { key: '3APD', text: 'Palynology--Biology', value: '3APD' },
  { key: '0YFD', text: 'Palynology--Inter-Area Specialization', value: '0YFD' },
  { key: '8DED', text: 'Palynology--Paleontology', value: '8DED' },
  { key: '4VJI', text: 'Paper Engineering', value: '4VJI' },
  { key: '4VJE', text: 'Paper Technology', value: '4VJE' },
  { key: '8HOM', text: 'Para-And Diamagnetism Phenomena', value: '8HOM' },
  { key: '9DIG', text: 'Paraguay (History)', value: '9DIG' },
  { key: '1CAM', text: 'Paralegal', value: '1CAM' },
  { key: '3AQY', text: 'Parasitelogy--Biology', value: '3AQY' },
  { key: '7ABM', text: 'Parasitelogy--Professional Services', value: '7ABM' },
  { key: '2IEB', text: 'Parks And Recreation Management Techhology', value: '2IEB' },
  { key: '6BNY', text: 'Partial Differential Equations', value: '6BNY' },
  { key: '8HGD', text: 'Particle Detectors', value: '8HGD' },
  { key: '4KAC', text: 'Particle Dynamics', value: '4KAC' },
  { key: '3BCD', text: 'Pasture and Forage Crops', value: '3BCD' },
  { key: '5ABN', text: 'Patent Law', value: '5ABN' },
  { key: '7FDE', text: 'Pathology (Veterinary)', value: '7FDE' },
  { key: '3ARY', text: 'Pathology--Biology', value: '3ARY' },
  { key: '7DNY', text: 'Pathology--Medicine', value: '7DNY' },
  { key: '2ICF', text: 'Peace Studies Technology', value: '2ICF' },
  { key: '7DOA', text: 'Pediatric Allergy', value: '7DOA' },
  { key: '7DOB', text: 'Pediatric Cardiology', value: '7DOB' },
  { key: '7EAJ', text: 'Pediatric Nursing', value: '7EAJ' },
  { key: '7DSA', text: 'Pediatric psychiatry', value: '7DSA' },
  { key: '7DOY', text: 'Pediatrics', value: '7DOY' },
  { key: '9HAH', text: 'Penal Code', value: '9HAH' },
  { key: '9HAI', text: 'Penology', value: '9HAI' },
  { key: '9FEI', text: 'Perception--Experimental Psychology', value: '9FEI' },
  { key: '2GLG', text: 'Perception--Philosphical Psychology', value: '2GLG' },
  { key: '2CJY', text: 'Performance Arts', value: '2CJY' },
  { key: '1AOH', text: 'Performance Rating', value: '1AOH' },
  { key: '7EAO', text: 'Perinatal Nursing', value: '7EAO' },
  { key: '9EDD', text: 'Periodic, Individual, Ideological, or National Studies', value: '9EDD' },
  { key: '7BEY', text: 'Periodontics', value: '7BEY' },
  { key: '2AAB', text: 'Personal Counseling', value: '2AAB' },
  { key: '2IDB', text: 'Personal Development Technology', value: '2IDB' },
  { key: '9FGY', text: 'Personality', value: '9FGY' },
  { key: '9FGA', text: 'Personality Development', value: '9FGA' },
  { key: '9FGB', text: 'Personality Measurement', value: '9FGB' },
  { key: '9FGC', text: 'Personality Theory', value: '9FGC' },
  { key: '7CAI', text: 'Personnel Administration (Hospital Administration)', value: '7CAI' },
  { key: '9GFB', text: 'Personnel Administration (Public Personnel Management)', value: '9GFB' },
  { key: '1AOI', text: 'Personnel Tests and Evaluation', value: '1AOI' },
  { key: '9DIH', text: 'Peru (History)', value: '9DIH' },
  { key: '3BAL', text: 'Pesticides--Agriculture and Food Chemistry', value: '3BAL' },
  { key: '8CAL', text: 'Pesticides--Chemistry', value: '8CAL' },
  { key: '8DCJ', text: 'Petrography', value: '8DCJ' },
  { key: '8CGM', text: 'Petroleum', value: '8CGM' },
  { key: '4OBY', text: 'Petroleum Engineering', value: '4OBY' },
  { key: '4VNY', text: 'Petroleum Engineering Technology', value: '4VNY' },
  { key: '4OBA', text: 'Petroleum Exploration Development', value: '4OBA' },
  { key: '4OBB', text: 'Petroleum Production', value: '4OBB' },
  { key: '3ASF', text: 'Pharmacodynamics', value: '3ASF' },
  { key: '3ASY', text: 'Pharmacology--Biology', value: '3ASY' },
  { key: '7ABN', text: 'Pharmacology--Professional Services', value: '7ABN' },
  { key: '7ABV', text: 'Pharmacy', value: '7ABV' },
  { key: '7GAH', text: 'Pharmacy and Pharmacy Technician--Biomedical Sciences Technology', value: '7GAH' },
  { key: '8CHN', text: 'Phase Equilibria', value: '8CHN' },
  { key: '8HGE', text: 'Phenomenological Computer Analysis', value: '8HGE' },
  { key: '2GBL', text: 'Phenomenology (School of Husserl)', value: '2GBL' },
  { key: '9DDJ', text: 'Philippine Islands (History)', value: '9DDJ' },
  { key: '2GLY', text: 'Philosophical Psychology', value: '2GLY' },
  { key: '2GYY', text: 'Philosophy', value: '2GYY' },
  { key: '2GAB', text: 'Philosophy of Art', value: '2GAB' },
  { key: '2GCB', text: 'Philosophy of Education--Cultural Philosophies', value: '2GCB' },
  { key: '2BAE', text: 'Philosophy of Education--Education Administration', value: '2BAE' },
  { key: '9CBB', text: 'Philosophy of Geography', value: '9CBB' },
  { key: '2GCC', text: 'Philosophy of History', value: '2GCC' },
  { key: '2GCD', text: 'Philosophy of Language', value: '2GCD' },
  { key: '2GCE', text: 'Philosophy of Religion', value: '2GCE' },
  { key: '2FEB', text: 'Phonetics', value: '2FEB' },
  { key: '8CGN', text: 'Phosphorous Compounds', value: '8CGN' },
  { key: '2CCH', text: 'Photo Ceramics', value: '2CCH' },
  { key: '8BCY', text: 'Photo-Interpretation', value: '8BCY' },
  { key: '8CHO', text: 'Photochemistry', value: '8CHO' },
  { key: '8HON', text: 'Photoconductivity', value: '8HON' },
  { key: '8HOO', text: 'Photoelectric Phenomena', value: '8HOO' },
  { key: '8DCK', text: 'Photogeology', value: '8DCK' },
  { key: '8BBY', text: 'Photogrammetry--Astronomy', value: '8BBY' },
  { key: '4HHC', text: 'Photogrammetry--Surveying and Mapping', value: '4HHC' },
  { key: '4VHK', text: 'Photographic Systems Technology', value: '4VHK' },
  { key: '2IAB', text: 'Photography, Cinematography, and Technical Photography--Communications  Technology', value: '2IAB' },
  { key: '8GAY', text: 'Photography, Cinematography, and Technical Photography--Photographic   Sciences', value: '8GAY' },
  { key: '8HMN', text: 'Photography--Physics, Optics', value: '8HMN' },
  { key: '9AEY', text: 'Physical Anthropology', value: '9AEY' },
  { key: '8AAY', text: 'Physical Astronomy', value: '8AAY' },
  { key: '3ACR', text: 'Physical Biochemistry', value: '3ACR' },
  { key: '8CCR', text: 'Physical Biochemistry--Chemistry', value: '8CCR' },
  { key: '8CHY', text: 'Physical Chemistry', value: '8CHY' },
  { key: '8FBE', text: 'Physical Climatology', value: '8FBE' },
  { key: '2BHY', text: 'Physical Education', value: '2BHY' },
  { key: '8HEI', text: 'Physical Electronics', value: '8HEI' },
  { key: '8DFY', text: 'Physical Geography', value: '8DFY' },
  { key: '4NCY', text: 'Physical Metallurgy', value: '4NCY' },
  { key: '8FFY', text: 'Physical Meteorology', value: '8FFY' },
  { key: '8EBF', text: 'Physical Oceanography', value: '8EBF' },
  { key: '8HMO', text: 'Physical Optics', value: '8HMO' },
  { key: '8DDG', text: 'Physical Properties of Materials', value: '8DDG' },
  { key: '8IYY', text: 'Physical Science Technologies', value: '8IYY' },
  { key: '8YYY', text: 'Physical Sciences', value: '8YYY' },
  { key: '2BBF', text: 'Physical Sciences (College Teaching)', value: '2BBF' },
  { key: '7GAI', text: 'Physical Therapist Assistance and Physical Therapy Assistant--Biomedical Sciences Technology', value: '7GAI' },
  { key: '7ABO', text: 'Physical Therapy', value: '7ABO' },
  { key: '7DPY', text: 'Physical and Rehabilitative Medicine', value: '7DPY' },
  { key: '7ACY', text: 'Physician Assistant', value: '7ACY' },
  { key: '8HYY', text: 'Physics', value: '8HYY' },
  { key: '8HXI', text: 'Physics Teaching', value: '8HXI' },
  { key: '8HNY', text: 'Physics of Fluids', value: '8HNY' },
  { key: '8AAJ', text: 'Physics of the Interstellar Medium', value: '8AAJ' },
  { key: '8AAK', text: 'Physics of the Solar System', value: '8AAK' },
  { key: '8AAL', text: 'Physics of the Sun', value: '8AAL' },
  { key: '7AAF', text: 'Physiological Optics', value: '7AAF' },
  { key: '9FEJ', text: 'Physiological Psychology', value: '9FEJ' },
  { key: '7GAJ', text: 'Physiological Training Technology', value: '7GAJ' },
  { key: '3ATY', text: 'Physiology', value: '3ATY' },
  { key: '7AAG', text: 'Physiology (Human)', value: '7AAG' },
  { key: '7FDF', text: 'Physiology (Veterinary)', value: '7FDF' },
  { key: '3ADB', text: 'Phytogeography--Biology', value: '3ADB' },
  { key: '9CAB', text: 'Phytogeography--Geography', value: '9CAB' },
  { key: '0YCB', text: 'Phytogeography--Inter-Area Specialization', value: '0YCB' },
  { key: '3ARE', text: 'Phytopathology', value: '3ARE' },
  { key: '8HOP', text: 'Piezo and Ferro-Electricity', value: '8HOP' },
  { key: '8CES', text: 'Pigments and Industrial Minerals', value: '8CES' },
  { key: '4OBC', text: 'Pipeline Transmission', value: '4OBC' },
  { key: '4OAF', text: 'Placer Mining', value: '4OAF' },
  { key: '8AAM', text: 'Planetary Atmospheres', value: '8AAM' },
  { key: '8AAN', text: 'Planets, Satellites', value: '8AAN' },
  { key: '3AJB', text: 'Plant Cytology', value: '3AJB' },
  { key: '3AKB', text: 'Plant Ecology', value: '3AKB' },
  { key: '3AMD', text: 'Plant Genetics', value: '3AMD' },
  { key: '4LGD', text: 'Plant Layout--Industrial Engineering', value: '4LGD' },
  { key: '1AKH', text: 'Plant Layout--Industrial or Production Management', value: '1AKH' },
  { key: '3AOD', text: 'Plant Nutrition', value: '3AOD' },
  { key: '3ATD', text: 'Plant Physiology', value: '3ATD' },
  { key: '8HNJ', text: 'Plasma Physics', value: '8HNJ' },
  { key: '4KCC', text: 'Plastic Stability', value: '4KCC' },
  { key: '7DQY', text: 'Plastic Surgery', value: '7DQY' },
  { key: '8CGO', text: 'Plastics and Synthetic Resins', value: '8CGO' },
  { key: '4DDA', text: 'Plumbing', value: '4DDA' },
  { key: '7ABW', text: 'Podiatry', value: '7ABW' },
  { key: '2FFE', text: 'Poetry', value: '2FFE' },
  { key: '6JEY', text: 'Point-Set Topology (Mathematics)', value: '6JEY' },
  { key: '8FEF', text: 'Polar Meteorology', value: '8FEF' },
  { key: '9HAJ', text: 'Police Administration', value: '9HAJ' },
  { key: '9IJY', text: 'Police Science and Security and Law Enforcement', value: '9IJY' },
  { key: '9CBC', text: 'Political Geography', value: '9CBC' },
  { key: '9GAD', text: 'Political Parties', value: '9GAD' },
  { key: '9EDE', text: 'Political Parties and Interest Groups', value: '9EDE' },
  { key: '9EYY', text: 'Political Science', value: '9EYY' },
  { key: '9HFG', text: 'Political Sociology', value: '9HFG' },
  { key: '9EDY', text: 'Political Theory and Political Behavior', value: '9EDY' },
  { key: '2GCF', text: 'Political and social philosophy', value: '2GCF' },
  { key: '4VEK', text: 'Pollution Abatement Technology', value: '4VEK' },
  { key: '4RBB', text: 'Pollution and Contamination', value: '4RBB' },
  { key: '8CHP', text: 'Polymer Chemistry', value: '8CHP' },
  { key: '6AHY', text: 'Polynomials (Mathematics)', value: '6AHY' },
  { key: '3BDD', text: 'Population Dynamics (Fish and Wildlife)', value: '3BDD' },
  { key: '9CBD', text: 'Population Geography', value: '9CBD' },
  { key: '9HBB', text: 'Population Structure', value: '9HBB' },
  { key: '9HBC', text: 'Population Trends', value: '9HBC' },
  { key: '8ABY', text: 'Positional Astronomy', value: '8ABY' },
  { key: '2HDD', text: 'Post-Reformation Christianity', value: '2HDD' },
  { key: '6BOY', text: 'Potential Theory, Subharmonic Functions (Mathematics)', value: '6BOY' },
  { key: '2CFJ', text: 'Pottery', value: '2CFJ' },
  { key: '3BBB', text: 'Poultry', value: '3BBB' },
  { key: '4NDY', text: 'Powder Metallurgy', value: '4NDY' },
  { key: '4AEE', text: 'Power Plant Testing--Aeronautical Engineering', value: '4AEE' },
  { key: '4EEF', text: 'Power Plant Testing--Astronautical Engineering', value: '4EEF' },
  { key: '4MGY', text: 'Power Plants', value: '4MGY' },
  { key: '4VPD', text: 'Power Sewing Technology', value: '4VPD' },
  { key: '4IEE', text: 'Power Systems', value: '4IEE' },
  { key: '4CCY', text: 'Power and Machinery', value: '4CCY' },
  { key: '2GBM', text: 'Pragmatism (American)', value: '2GBM' },
  { key: 'Z011', text: 'Pre Med', value: 'Z011' },
  { key: '7gew', text: 'Pre Nursing', value: '7gew' },
  { key: '4LDB', text: 'Pre-Determined Standard Data', value: '4LDB' },
  { key: '7ABX', text: 'Pre-Pharmacy', value: '7ABX' },
  { key: '8EAF', text: 'Precipitation', value: '8EAF' },
  { key: '2IBD', text: 'Preschool Child Care', value: '2IBD' },
  { key: '9GAE', text: 'Pressure Groups', value: '9GAE' },
  { key: '4EGD', text: 'Pressure Vessel', value: '4EGD' },
  { key: '9HAK', text: 'Prevention and Control', value: '9HAK' },
  { key: '7DRD', text: 'Preventive Medicine', value: '7DRD' },
  { key: '7DRY', text: 'Preventive Medicine--Medicine', value: '7DRY' },
  { key: '9BBC', text: 'Price and Allocation Theory', value: '9BBC' },
  { key: '4RAC', text: 'Principles of Prevention', value: '4RAC' },
  { key: '1ATD', text: 'Principles of Transportation', value: '1ATD' },
  { key: '2CDB', text: 'Printing', value: '2CDB' },
  { key: '2CDE', text: 'Printing Management', value: '2CDE' },
  { key: '2ADY', text: 'Prison Chaplaincy', value: '2ADY' },
  { key: '5AAA', text: 'Private International Law', value: '5AAA' },
  { key: '5ABY', text: 'Private Law', value: '5ABY' },
  { key: '2CAE', text: 'Private Residence Design', value: '2CAE' },
  { key: '1AFE', text: 'Probability', value: '1AFE' },
  { key: '2GMD', text: 'Problem of Evil', value: '2GMD' },
  { key: '9GDF', text: 'Process of Management', value: '9GDF' },
  { key: '1ACA', text: 'Procurement Management', value: '1ACA' },
  { key: '4MHY', text: 'Product Design', value: '4MHY' },
  { key: '4LGE', text: 'Production Engineering', value: '4LGE' },
  { key: '4LGF', text: 'Production Planning', value: '4LGF' },
  { key: '4VJD', text: 'Production Planning Technology', value: '4VJD' },
  { key: '4LGY', text: 'Production Planning and Control--Industrial Engineering', value: '4LGY' },
  { key: '1AKI', text: 'Production Planning and Control--Industrial or Production Management', value: '1AKI' },
  { key: '4AYZ', text: 'Professional Aeronautics', value: '4AYZ' },
  { key: 'Z006', text: 'Professional Flight Management', value: 'Z006' },
  { key: '7ABY', text: 'Professional Services', value: '7ABY' },
  { key: '4RCC', text: 'Program Planning and Supervision', value: '4RCC' },
  { key: '2BDD', text: 'Programmed Learning', value: '2BDD' },
  { key: '9FDB', text: 'Programmed Learning - Psychology', value: '9FDB' },
  { key: '9FAH', text: 'Projective Techniques', value: '9FAH' },
  { key: '6CJY', text: 'Projective, Non-Euclidean Geometries', value: '6CJY' },
  { key: '3BDE', text: 'Propagation and Management', value: '3BDE' },
  { key: '1ALD', text: 'Property Insurance', value: '1ALD' },
  { key: '4AEY', text: 'Propulsion', value: '4AEY' },
  { key: '4EEY', text: 'Propulsion (Astronautical Engineering)', value: '4EEY' },
  { key: '4AEF', text: 'Propulsion Systems (Aeronautical Engineering)', value: '4AEF' },
  { key: '4RDD', text: 'Propulsion Systems (Safety Engineering)', value: '4RDD' },
  { key: '7BFY', text: 'Prosthodontics', value: '7BFY' },
  { key: '8CGP', text: 'Protective Coatings', value: '8CGP' },
  { key: '4RBC', text: 'Protective Equipment', value: '4RBC' },
  { key: '7DSD', text: 'PsyD', value: '7DSD' },
  { key: '7EAK', text: 'Psychiatric Nursing', value: '7EAK' },
  { key: '7GEB', text: 'Psychiatric Nursing, Psychiatric Nursing Care, and Psychiatric Nursing  Technician', value: '7GEB' },
  { key: '7ABP', text: 'Psychiatric Social Work', value: '7ABP' },
  { key: '7DSY', text: 'Psychiatry', value: '7DSY' },
  { key: '2GDH', text: 'Psychoanalysis', value: '2GDH' },
  { key: '9FIC', text: 'Psychological Testing', value: '9FIC' },
  { key: '9FHY', text: 'Psychological Warfare', value: '9FHY' },
  { key: '9FYY', text: 'Psychology', value: '9FYY' },
  { key: '2IDY', text: 'Psychology Technology', value: '2IDY' },
  { key: '0YGY', text: 'Psychometrics--Inter-Area Specialization', value: '0YGY' },
  { key: '6ENY', text: 'Psychometrics--Mathematics of Resource Use', value: '6ENY' },
  { key: '9FIY', text: 'Psychometrics--Psychology', value: '9FIY' },
  { key: '3ASG', text: 'Psychopharmacology', value: '3ASG' },
  { key: '9FEK', text: 'Psychophysics', value: '9FEK' },
  { key: '2GDI', text: 'Psychophysics-Psychosomatics', value: '2GDI' },
  { key: '9GYY', text: 'Public Administration', value: '9GYY' },
  { key: '9GEY', text: 'Public Budgeting and Public Finance', value: '9GEY' },
  { key: '9IHY', text: 'Public Contact Representative', value: '9IHY' },
  { key: '9GEE', text: 'Public Finance', value: '9GEE' },
  { key: '9BIY', text: 'Public Finance--Economics', value: '9BIY' },
  { key: '7ECY', text: 'Public Health', value: '7ECY' },
  { key: '7DRE', text: 'Public Health Medicine (including Epidemiology)', value: '7DRE' },
  { key: '7EAL', text: 'Public Health Nursing', value: '7EAL' },
  { key: '5AAB', text: 'Public International Law', value: '5AAB' },
  { key: '5ACY', text: 'Public Law--Law', value: '5ACY' },
  { key: '9EEY', text: 'Public Law--Political Science', value: '9EEY' },
  { key: '9GAF', text: 'Public Opinion', value: '9GAF' },
  { key: '9HIB', text: 'Public Opinion and Communication', value: '9HIB' },
  { key: '9EDF', text: 'Public Opinion and Electoral Behavior', value: '9EDF' },
  { key: '9GFY', text: 'Public Personnel Management', value: '9GFY' },
  { key: '7CAJ', text: 'Public Relation (Hospital Administration)', value: '7CAJ' },
  { key: '2FDY', text: 'Public Relations and Related Communication', value: '2FDY' },
  { key: '9IYY', text: 'Public Services Occupational Technologies', value: '9IYY' },
  { key: '9BCD', text: 'Public Utilities', value: '9BCD' },
  { key: '7DGG', text: 'Pulmonary Diseases', value: '7DGG' },
  { key: '4VJH', text: 'Pulp and Paper Science Engineering', value: '4VJH' },
  { key: '4IEF', text: 'Pulsed Power Systems, Electrical Engineering', value: '4IEF' },
  { key: '1AKJ', text: 'Purchasing--Industrial or Production Management', value: '1AKJ' },
  { key: '1ANC', text: 'Purchasing--Marketing', value: '1ANC' },
  { key: '4NAE', text: 'Pyrometallurgical Extraction', value: '4NAE' },
  { key: '8CBK', text: 'Qualitative Analysis (Chemistry)', value: '8CBK' },
  { key: '4VJF', text: 'Quality Control Technology', value: '4VJF' },
  { key: '4LHY', text: 'Quality Control--Industrial Engineering', value: '4LHY' },
  { key: '6IGY', text: 'Quality Control--Mathematical Statistics', value: '6IGY' },
  { key: '4ICD', text: 'Quantum Electron Devices', value: '4ICD' },
  { key: '8HEJ', text: 'Quantum Electronics', value: '8HEJ' },
  { key: '8HXJ', text: 'Quantum Mechanics', value: '8HXJ' },
  { key: '8CHQ', text: 'Quantum Theory', value: '8CHQ' },
  { key: '1APY', text: 'R and D Management', value: '1APY' },
  { key: '2IEX', text: 'RECRN-TECH OTHER', value: '2IEX' },
  { key: '2HBX', text: 'RELIG COMP RELIG E REL OTH', value: '2HBX' },
  { key: '2HDX', text: 'RELIG W RELIG OTHER', value: '2HDX' },
  { key: '2HXY', text: 'RELIGION OTHER', value: '2HXY' },
  { key: '2IFX', text: 'RELIGION-TECH OTHER', value: '2IFX' },
  { key: '2DYL', text: 'RUSSIAN', value: '2DYL' },
  { key: '9AEC', text: 'Racial Genetics', value: '9AEC' },
  { key: '8AAO', text: 'Radar Astronomy', value: '8AAO' },
  { key: '8BAD', text: 'Radar Cartography', value: '8BAD' },
  { key: '8FEG', text: 'Radar Meteorology', value: '8FEG' },
  { key: '4IGD', text: 'Radar Systems', value: '4IGD' },
  { key: '8AAP', text: 'Radiatio--Physical Astronomy', value: '8AAP' },
  { key: '3ACS', text: 'Radiation Biochemistry--Biology', value: '3ACS' },
  { key: '8CCS', text: 'Radiation Biochemistry--Chemistry', value: '8CCS' },
  { key: '0YBS', text: 'Radiation Biochemistry--Inter-Area Specialization', value: '0YBS' },
  { key: '3AGQ', text: 'Radiation Biology--Biology', value: '3AGQ' },
  { key: '0YDQ', text: 'Radiation Biology--Inter-Area Specialization', value: '0YDQ' },
  { key: '7FDG', text: 'Radiation Biology--Veterinary Research Medicine', value: '7FDG' },
  { key: '8HOQ', text: 'Radiation Damage', value: '8HOQ' },
  { key: '4QCC', text: 'Radiation Defense', value: '4QCC' },
  { key: '4QAB', text: 'Radiation Detection and Measurement', value: '4QAB' },
  { key: '8HLG', text: 'Radiation Effects', value: '8HLG' },
  { key: 'Z017', text: 'Radiation Health Physics', value: 'Z017' },
  { key: 'Z043', text: 'Radiation Oncology', value: 'Z043' },
  { key: '4QCE', text: 'Radiation Shielding--Nuclear Engineering', value: '4QCE' },
  { key: '8HLI', text: 'Radiation Shielding--Nuclear Physics', value: '8HLI' },
  { key: '4VMA', text: 'Radiation and Nuclear Technology', value: '4VMA' },
  { key: '4QCD', text: 'Radiation hazards', value: '4QCD' },
  { key: '8FFE', text: 'Radiative Transfer', value: '8FFE' },
  { key: '4IBE', text: 'Radio Astronomy--Electrical Engineering', value: '4IBE' },
  { key: '8AAQ', text: 'Radio Astronomy--Physical Astronomy', value: '8AAQ' },
  { key: '8ABG', text: 'Radio Astronomy--Positional Astronomy', value: '8ABG' },
  { key: '4IGE', text: 'Radio Communications Systems', value: '4IGE' },
  { key: '4VHL', text: 'Radio Communications Technology', value: '4VHL' },
  { key: '4IBF', text: 'Radio Wave Propagation', value: '4IBF' },
  { key: '2FDD', text: 'Radio-Television', value: '2FDD' },
  { key: '8HLH', text: 'Radioactive Materials, Isotopes', value: '8HLH' },
  { key: '8CET', text: 'Radioactive Minerals and Products', value: '8CET' },
  { key: '7DTY', text: 'Radiobiology', value: '7DTY' },
  { key: '8CHR', text: 'Radiochemistry--Physical Chemistry', value: '8CHR' },
  { key: '7GDH', text: 'Radiologic Technology', value: '7GDH' },
  { key: '7DUY', text: 'Radiology', value: '7DUY' },
  { key: '8HMQ', text: 'Radiometry, Photometry', value: '8HMQ' },
  { key: '4HIC', text: 'Rail Systems', value: '4HIC' },
  { key: '1ATE', text: 'Rail Transportation', value: '1ATE' },
  { key: '4REC', text: 'Rail Transportation Safety', value: '4REC' },
  { key: '4AEG', text: 'Ramjet', value: '4AEG' },
  { key: '3BEF', text: 'Range Management', value: '3BEF' },
  { key: '4EBB', text: 'Rarefied Gas Dynamics', value: '4EBB' },
  { key: '8HNK', text: 'Rarefied Gas Flow', value: '8HNK' },
  { key: '1ATF', text: 'Rate Structures', value: '1ATF' },
  { key: '2GMY', text: 'Rational Theology', value: '2GMY' },
  { key: '4EBC', text: 'Re-Entry Mechanics', value: '4EBC' },
  { key: '8CGQ', text: 'Reaction Mechanisms', value: '8CGQ' },
  { key: '4QBE', text: 'Reactions and Scattering', value: '4QBE' },
  { key: '4QDA', text: 'Reactor Analysis', value: '4QDA' },
  { key: '4QDB', text: 'Reactor Design', value: '4QDB' },
  { key: '4QAC', text: 'Reactor Instrumentation and Control', value: '4QAC' },
  { key: '1AQY', text: 'Real Estate', value: '1AQY' },
  { key: '5ABO', text: 'Real Estate Law', value: '5ABO' },
  { key: '4AEH', text: 'Reciprocating Engines', value: '4AEH' },
  { key: '2IYY', text: 'Recreation', value: '2IYY' },
  { key: '2IEC', text: 'Recreation Grounds Management', value: '2IEC' },
  { key: '2IEY', text: 'Recreation Technology', value: '2IEY' },
  { key: '9FFG', text: 'Recruiting, Selection, Employment--Industrial and Personnel Psychology', value: '9FFG' },
  { key: '1AOJ', text: 'Recruiting, Selection, Employment--Personnel Administration', value: '1AOJ' },
  { key: '9GFC', text: 'Recruiting, Selection, Employment--Public Personnel Management', value: '9GFC' },
  { key: '6DGY', text: 'Recursive Functions (Mathematics)', value: '6DGY' },
  { key: '2HDE', text: 'Reformation', value: '2HDE' },
  { key: '4FBG', text: 'Refractory Materials Systems', value: '4FBG' },
  { key: '4DCB', text: 'Refrigeration', value: '4DCB' },
  { key: '9CBE', text: 'Regional Geography', value: '9CBE' },
  { key: '4DAB', text: 'Regional Planning--Architectural Engineering', value: '4DAB' },
  { key: '4HAB', text: 'Regional Planning--Civil Engineering', value: '4HAB' },
  { key: '9BFD', text: 'Regional and Urban Planning', value: '9BFD' },
  { key: '9FBD', text: 'Rehabilitation', value: '9FBD' },
  { key: '7EAM', text: 'Rehabilitation Nursing', value: '7EAM' },
  { key: '2CEG', text: 'Related Arts', value: '2CEG' },
  { key: '2GIG', text: 'Relation (Philosophy)', value: '2GIG' },
  { key: '8HXK', text: 'Relativity and Gravitation', value: '8HXK' },
  { key: '4TJY', text: 'Reliability', value: '4TJY' },
  { key: '4IRY', text: 'Reliability Engineering Electronic Systems', value: '4IRY' },
  { key: '6IRY', text: 'Reliability and Maintainability', value: '6IRY' },
  { key: '2HYY', text: 'Religion', value: '2HYY' },
  { key: '2IFY', text: 'Religion Technology', value: '2IFY' },
  { key: '2IFG', text: 'Religion Technology', value: '2IFG' },
  { key: '2AEY', text: 'Religious Education', value: '2AEY' },
  { key: '1CAE', text: 'Religious Institutions Administration, Church Business Administration, and  Chapel Administration', value: '1CAE' },
  { key: '2BCB', text: 'Remedial Education', value: '2BCB' },
  { key: '4LBF', text: 'Replacement Analysis', value: '4LBF' },
  { key: '6AIY', text: 'Representation Theory (Mathematics)', value: '6AIY' },
  { key: '8BAE', text: 'Reproduction Cartography', value: '8BAE' },
  { key: '2CDD', text: 'Reprographics', value: '2CDD' },
  { key: '3AOE', text: 'Requirements and Deficiencies (Nutrition)', value: '3AOE' },
  { key: '2CGE', text: 'Research Library-Research Studies', value: '2CGE' },
  { key: '1AQC', text: 'Residential Real Estate', value: '1AQC' },
  { key: '8HOR', text: 'Resonance Phenomena', value: '8HOR' },
  { key: '9CCD', text: 'Resource Geography', value: '9CCD' },
  { key: '1CAD', text: 'Resource Management Technology and Business Management', value: '1CAD' },
  { key: '7EAN', text: 'Respiratory Nursing', value: '7EAN' },
  { key: '1AND', text: 'Retailing', value: '1AND' },
  { key: '8HNL', text: 'Rheology', value: '8HNL' },
  { key: '6CKY', text: 'Riemannian Geometry', value: '6CKY' },
  { key: 'Z061', text: 'Robotics', value: 'Z061' },
  { key: '4AEI', text: 'Rockets--Aeronautical Engineering', value: '4AEI' },
  { key: '4EEG', text: 'Rockets--Astronautical Engineering', value: '4EEG' },
  { key: '9HIC', text: 'Role Theory', value: '9HIC' },
  { key: '4AAK', text: 'Rotary Wing', value: '4AAK' },
  { key: '9HDC', text: 'Rural Sociology', value: '9HDC' },
  { key: '9HDY', text: 'Rural-Urban Sociology', value: '9HDY' },
  { key: '9DKK', text: 'Russian SFSR (History)', value: '9DKK' },
  { key: '4RAX', text: 'SAFE ENG ACC PRE OTHER', value: '4RAX' },
  { key: '4RBX', text: 'SAFE ENG IND STY OTHER', value: '4RBX' },
  { key: '4RCX', text: 'SAFE ENG SAFE MGMT OTHER', value: '4RCX' },
  { key: '4RDX', text: 'SAFE ENG SYS STY OTHER', value: '4RDX' },
  { key: '4REX', text: 'SAFE ENG TRANS STOTHER', value: '4REX' },
  { key: '4RXY', text: 'SAFETY ENGINEERING OTHER', value: '4RXY' },
  { key: '0YVA', text: 'SCI  TECH  AND  GLOBALIZATION', value: '0YVA' },
  { key: '4VOX', text: 'SFTY-ENGRG-TECH OTHER', value: '4VOX' },
  { key: '4IPY', text: 'SIGNAL PROCESSING', value: '4IPY' },
  { key: '2DYT', text: 'SLAVIC LANGUAGES', value: '2DYT' },
  { key: '9HEX', text: 'SOCIAL SOC CHGE-DEV OTHER', value: '9HEX' },
  { key: '9HFX', text: 'SOCIAL SOC INSTIT OTHER', value: '9HFX' },
  { key: '9HDX', text: 'SOCIOL RURAL-URB OTHER', value: '9HDX' },
  { key: '9HKX', text: 'SOCIOL SOC INST OTHER', value: '9HKX' },
  { key: '9HGX', text: 'SOCIOL SOC ORGN OTHER', value: '9HGX' },
  { key: '9HHX', text: 'SOCIOL SOC PROB OTHER', value: '9HHX' },
  { key: '9HIX', text: 'SOCIOL SOC PSYC OTHER', value: '9HIX' },
  { key: '9HJX', text: 'SOCIOL SOC STRUC OTHER', value: '9HJX' },
  { key: '9HXY', text: 'SOCIOL SOCIOLOGY OTHER', value: '9HXY' },
  { key: '9HBX', text: 'SOCIOLOGY DEMOGRAPHY OTHER', value: '9HBX' },
  { key: '9HCX', text: 'SOCIOLOGY FAMILY OTHER', value: '9HCX' },
  { key: '0CFY', text: 'SOFTWARE ENGINEERING', value: '0CFY' },
  { key: '0YIX', text: 'SOIL SCIENCE  OTHER', value: '0YIX' },
  { key: '7GDK', text: 'SONOGRAPHY TECH', value: '7GDK' },
  { key: '2DYS', text: 'SOUTHEAST ASIAN LANGUAGES', value: '2DYS' },
  { key: '0YRI', text: 'SPACE OPS, INFORMATION OPS', value: '0YRI' },
  { key: '0YRS', text: 'SPACE STUDIES', value: '0YRS' },
  { key: '4TSY', text: 'SPACE SYSTEMS', value: '4TSY' },
  { key: '2DYK', text: 'SPANISH', value: '2DYK' },
  { key: '2DYH', text: 'SPANISH AMERICAN', value: '2DYH' },
  { key: '2DYM', text: 'SPANISH/CAS (CASTILLIAN)', value: '2DYM' },
  { key: '0YWY', text: 'SPEC OPS  AND  LOW INTENS CONFL', value: '0YWY' },
  { key: '7AAK', text: 'SPEECH PATHOLOGY', value: '7AAK' },
  { key: '7DGJ', text: 'SPORTS MEDICINE', value: '7DGJ' },
  { key: '6ICY', text: 'STAT-DESGN-ANAL OF EXPERM', value: '6ICY' },
  { key: '6IXY', text: 'STAT-OTHER', value: '6IXY' },
  { key: '1AMP', text: 'STRATEGIC PURCHASING', value: '1AMP' },
  { key: '0YVB', text: 'STRATEGIC STUDIES', value: '0YVB' },
  { key: '7DED', text: 'SURGICAL ONCOLOGY', value: '7DED' },
  { key: '2IBS', text: 'SURVIVAL INSTRUCTION', value: '2IBS' },
  { key: '4TXY', text: 'SYSTEMS ENGINEERING OTHER', value: '4TXY' },
  { key: '2CHC', text: 'Sacred Music', value: '2CHC' },
  { key: '4RYY', text: 'Safety Engineering', value: '4RYY' },
  { key: '4VOY', text: 'Safety Engineering Technology', value: '4VOY' },
  { key: '1ARY', text: 'Safety Management--Management', value: '1ARY' },
  { key: '4RCY', text: 'Safety Management--Safety-Management Engineering', value: '4RCY' },
  { key: '9IIY', text: 'Safety Technology', value: '9IIY' },
  { key: '1ANE', text: 'Sales', value: '1ANE' },
  { key: '1AEF', text: 'Sales Law', value: '1AEF' },
  { key: '6IHY', text: 'Sampling Techniques (Mathematics)', value: '6IHY' },
  { key: '7ABQ', text: 'Sanitary Engineering', value: '7ABQ' },
  { key: '4DDY', text: 'Sanitary Systems', value: '4DDY' },
  { key: '4IGF', text: 'Satellite Communications Systems', value: '4IGF' },
  { key: '8DBE', text: 'Satellite Geodesy', value: '8DBE' },
  { key: '8AAR', text: 'Satellite Instrumentation (Astronomy)', value: '8AAR' },
  { key: '8FEH', text: 'Satellite Meteorology', value: '8FEH' },
  { key: '9DGF', text: 'Saudi Arabia (History)', value: '9DGF' },
  { key: '9DLF', text: 'Scandinavian Countries (History)', value: '9DLF' },
  { key: '2BEF', text: 'School Adjustment--Education', value: '2BEF' },
  { key: '9FDC', text: 'School Adjustment--Psychology', value: '9FDC' },
  { key: '2BAF', text: 'School Business Administration and Management', value: '2BAF' },
  { key: '2GBN', text: 'School Idealism (French anD British)', value: '2GBN' },
  { key: '2BAG', text: 'School Law', value: '2BAG' },
  { key: '2BEG', text: 'School Learning-- Education', value: '2BEG' },
  { key: '9FDD', text: 'School Learning--Psychology', value: '9FDD' },
  { key: '9FCD', text: 'School Psychology', value: '9FCD' },
  { key: '4VES', text: 'Scientific Analysis Technology', value: '4VES' },
  { key: '9GDG', text: 'Scientific Management', value: '9GDG' },
  { key: '2CKY', text: 'Sculpture', value: '2CKY' },
  { key: '8EBG', text: 'Sea-Air Interactions', value: '8EBG' },
  { key: '2BIY', text: 'Secondary Teaching', value: '2BIY' },
  { key: '2CHD', text: 'Secular Music', value: '2CHD' },
  { key: '9HAO', text: 'Security Administration', value: '9HAO' },
  { key: '8DCL', text: 'Sedimentology', value: '8DCL' },
  { key: '3BCE', text: 'Seed', value: '3BCE' },
  { key: '8DDH', text: 'Seismology', value: '8DDH' },
  { key: '8DBG', text: 'Selenodesy--Geodesy', value: '8DBG' },
  { key: '8AAW', text: 'Selenodesy--Physical Astronomy', value: '8AAW' },
  { key: '8AAS', text: 'Selenology', value: '8AAS' },
  { key: '8HFG', text: 'Semiconductor Devices', value: '8HFG' },
  { key: '8HOS', text: 'Semiconductors', value: '8HOS' },
  { key: '4VHM', text: 'Sensor Systems Technology', value: '4VHM' },
  { key: '9FEL', text: 'Sensory Processes', value: '9FEL' },
  { key: '4GBG', text: 'Separators, Mechanical', value: '4GBG' },
  { key: '6BPY', text: 'Series, Summability (Mathematics)', value: '6BPY' },
  { key: '2CID', text: 'Serigraphy', value: '2CID' },
  { key: '7ABR', text: 'Serology', value: '7ABR' },
  { key: '6DHY', text: 'Set Theory (Mathematics)', value: '6DHY' },
  { key: '9CBF', text: 'Settlement Geography', value: '9CBF' },
  { key: '4HEB', text: 'Sewage and Industrial Wastes', value: '4HEB' },
  { key: '4DDB', text: 'Sewerage', value: '4DDB' },
  { key: '9HCF', text: 'Sexual Behavior', value: '9HCF' },
  { key: '4PBY', text: 'Shipbuilding', value: '4PBY' },
  { key: '8HNM', text: 'Shock Wave Phenomena', value: '8HNM' },
  { key: '8EBH', text: 'Shore and Near Shore Processes', value: '8EBH' },
  { key: '8CGR', text: 'Silicon Compounds', value: '8CGR' },
  { key: '3BEG', text: 'Silviculture', value: '3BEG' },
  { key: '4TKY', text: 'Simulation', value: '4TKY' },
  { key: '4GCL', text: 'Size Reduction', value: '4GCL' },
  { key: '9DCB', text: 'Slavic Countries (History)', value: '9DCB' },
  { key: '3BBC', text: 'Small Animal Husbandry', value: '3BBC' },
  { key: '7FAC', text: 'Small Animal Medicine', value: '7FAC' },
  { key: '2KYY', text: 'Small Arms Training and Maintenance', value: '2KYY' },
  { key: '9HID', text: 'Small Groups', value: '9HID' },
  { key: '9HGC', text: 'Small Scale Organization', value: '9HGC' },
  { key: '8CGS', text: 'Soaps, Detergents, Surfactants', value: '8CGS' },
  { key: '9BJC', text: 'Social Accounting', value: '9BJC' },
  { key: '9ACD', text: 'Social Anthropology', value: '9ACD' },
  { key: '9FJF', text: 'Social Attitudes', value: '9FJF' },
  { key: '9HEY', text: 'Social Change and Development', value: '9HEY' },
  { key: '9HED', text: 'Social Conflict', value: '9HED' },
  { key: '9ADC', text: 'Social Gerontology', value: '9ADC' },
  { key: '9HFY', text: 'Social Institutions', value: '9HFY' },
  { key: '9HGY', text: 'Social Organization', value: '9HGY' },
  { key: '9FJG', text: 'Social Perception and Cognition', value: '9FJG' },
  { key: '9HHY', text: 'Social Problems', value: '9HHY' },
  { key: '9HHE', text: 'Social Psychiatry', value: '9HHE' },
  { key: '9FJY', text: 'Social Psychology-- Psychology', value: '9FJY' },
  { key: '0YHY', text: 'Social Psychology--Inter-Area Specialization', value: '0YHY' },
  { key: '9HIY', text: 'Social Psychology--Sociology', value: '9HIY' },
  { key: '9YYY', text: 'Social Sciences', value: '9YYY' },
  { key: '2BBG', text: 'Social Sciences (College Teaching)', value: '2BBG' },
  { key: '2BII', text: 'Social Sciences (Secondary Teaching)', value: '2BII' },
  { key: '9IKY', text: 'Social Service Aide', value: '9IKY' },
  { key: '9HJY', text: 'Social Structure', value: '9HJY' },
  { key: '9HKY', text: 'Social Theory', value: '9HKY' },
  { key: '9HLY', text: 'Social Work', value: '9HLY' },
  { key: '9DJH', text: 'Social and Cultural History', value: '9DJH' },
  { key: '9HIE', text: 'Socialization and Personality', value: '9HIE' },
  { key: '9HYY', text: 'Sociology', value: '9HYY' },
  { key: '9HFJ', text: 'Sociology of Arts', value: '9HFJ' },
  { key: '9HKD', text: 'Sociology of Knowledge', value: '9HKD' },
  { key: '9HFH', text: 'Sociology of Religion', value: '9HFH' },
  { key: '9HFI', text: 'Sociology of Science', value: '9HFI' },
  { key: '0CEY', text: 'Software Design Systems', value: '0CEY' },
  { key: '4IDD', text: 'Software Engineering', value: '4IDD' },
  { key: '3BGC', text: 'Soil Chemistry--Agriculture', value: '3BGC' },
  { key: '0YIB', text: 'Soil Chemistry--Inter-Area Specialization', value: '0YIB' },
  { key: '8DGB', text: 'Soil Chemistry-Earth Sciences', value: '8DGB' },
  { key: '3BGD', text: 'Soil Fertility, Fertilizers, and Plant Nutrition--Agriculture', value: '3BGD' },
  { key: '8DGC', text: 'Soil Fertility, Fertilizers, and Plant Nutrition--Earth Sciences', value: '8DGC' },
  { key: '0YIC', text: 'Soil Fertility, Fertilizers, and Plant Nutrition--Inter-Area Specialization', value: '0YIC' },
  { key: '3BGE', text: 'Soil Gensesis, Morphology and Classification--Agriculture', value: '3BGE' },
  { key: '8DGD', text: 'Soil Gensesis, Morphology and Classification--Earth Sciences', value: '8DGD' },
  { key: '0YID', text: 'Soil Gensesis, Morphology and Classification--Inter-Area Specialization', value: '0YID' },
  { key: '4HFB', text: 'Soil Mechanics', value: '4HFB' },
  { key: '8DGE', text: 'Soil Mechanics and Engineering', value: '8DGE' },
  { key: '3BGF', text: 'Soil Microbiology--Agriculture', value: '3BGF' },
  { key: '8DGF', text: 'Soil Microbiology--Earth Sciences', value: '8DGF' },
  { key: '0YIE', text: 'Soil Microbiology--Inter-Area Specialization', value: '0YIE' },
  { key: '8DGG', text: 'Soil Mineralogy--Earth Sciences', value: '8DGG' },
  { key: '0YIF', text: 'Soil Mineralogy--Inter-Area Specialization', value: '0YIF' },
  { key: '8EAG', text: 'Soil Moisture', value: '8EAG' },
  { key: '3BGG', text: 'Soil Physics--Agriculture', value: '3BGG' },
  { key: '8DGH', text: 'Soil Physics--Earth Sciences', value: '8DGH' },
  { key: '0YIG', text: 'Soil Physics--Inter-Area Specialization', value: '0YIG' },
  { key: '3BGY', text: 'Soil Science--Agriculture', value: '3BGY' },
  { key: '8DGY', text: 'Soil Science--Earth Sciences', value: '8DGY' },
  { key: '0YIY', text: 'Soil Science--Inter-Area Specialization', value: '0YIY' },
  { key: '4HFY', text: 'Soil and Foundation Engineering', value: '4HFY' },
  { key: '4CDY', text: 'Soil and Water Engineering', value: '4CDY' },
  { key: '3BGB', text: 'Soil and Water Management--Agriculture', value: '3BGB' },
  { key: '8DGA', text: 'Soil and Water Management--Earth Sciences', value: '8DGA' },
  { key: '0YIA', text: 'Soil and Water Management-Inter-Area Specialization', value: '0YIA' },
  { key: '8DFF', text: 'Soil geography', value: '8DFF' },
  { key: '8FDC', text: 'Solar Physics', value: '8FDC' },
  { key: '8FDY', text: 'Solar and Space Sciences', value: '8FDY' },
  { key: '8FDB', text: 'Solar-Terrestrial Physics', value: '8FDB' },
  { key: '3AGR', text: 'Solid Biomechanics--Biology', value: '3AGR' },
  { key: '0YDR', text: 'Solid Biomechanics--Inter-Area Specialization', value: '0YDR' },
  { key: '8HDR', text: 'Solid Biomechanics--Physics', value: '8HDR' },
  { key: '4EEH', text: 'Solid Fuels Astronautical Engineering', value: '4EEH' },
  { key: '4AEJ', text: 'Solid Fuels--Aeronautical Engineering', value: '4AEJ' },
  { key: '4ICE', text: 'Solid State Electron Devices', value: '4ICE' },
  { key: '8HFH', text: 'Solid State Electronics', value: '8HFH' },
  { key: '4ICF', text: 'Solid State Materials Science', value: '4ICF' },
  { key: '8HOY', text: 'Solid State Physics', value: '8HOY' },
  { key: '8CHS', text: 'Solid methods (incl. X-Rays)', value: '8CHS' },
  { key: '8CEU', text: 'Solutions and Solvent Theory', value: '8CEU' },
  { key: '8CHT', text: 'Solutions of Electrolytes and Nonelectrolytes', value: '8CHT' },
  { key: '8CBL', text: 'Solvent Extraction', value: '8CBL' },
  { key: '2GLH', text: 'Soul (Philosophy)', value: '2GLH' },
  { key: '8HAI', text: 'Sound Transmission', value: '8HAI' },
  { key: '9DAC', text: 'South Africa (History)', value: '9DAC' },
  { key: '9DIY', text: 'South America (History)', value: '9DIY' },
  { key: '8AAT', text: 'Space Astronomy', value: '8AAT' },
  { key: '4EFY', text: 'Space Facilities', value: '4EFY' },
  { key: '0YRY', text: 'Space Operations', value: '0YRY' },
  { key: '7ABS', text: 'Space Pharmacodynamics', value: '7ABS' },
  { key: '4SYY', text: 'Space Physics Engineering', value: '4SYY' },
  { key: '4ISY', text: 'Space Systems', value: '4ISY' },
  { key: '1AVS', text: 'Space Systems Management', value: '1AVS' },
  { key: '4VAS', text: 'Space technology', value: '4VAS' },
  { key: '2GED', text: 'Space, Time (Philosophy)', value: '2GED' },
  { key: '4EAD', text: 'Spacecraft Design', value: '4EAD' },
  { key: '4EGE', text: 'Spacecraft Structures', value: '4EGE' },
  { key: 'Z016', text: 'Spanish', value: 'Z016' },
  { key: '8FGY', text: 'Special Areas', value: '8FGY' },
  { key: '2BEH', text: 'Special Education (Educational Psychology)--Education', value: '2BEH' },
  { key: '9FDE', text: 'Special Education (Educational Psychology)--Psychology', value: '9FDE' },
  { key: '2BJC', text: 'Special Educational (Special Teacher Training)', value: '2BJC' },
  { key: '6BQY', text: 'Special Functions (Mathematics)', value: '6BQY' },
  { key: '2BJY', text: 'Special Teacher Training', value: '2BJY' },
  { key: '8HMR', text: 'Spectroscopy', value: '8HMR' },
  { key: '2FEY', text: 'Speech', value: '2FEY' },
  { key: '8HAJ', text: 'Speech Communication', value: '8HAJ' },
  { key: '9FAI', text: 'Speech Pathology', value: '9FAI' },
  { key: '4AFY', text: 'Stability and Control', value: '4AFY' },
  { key: '2CCI', text: 'Stage Decoration', value: '2CCI' },
  { key: '2CCJ', text: 'Stained Glass and Fiberglass', value: '2CCJ' },
  { key: '4LHA', text: 'Standards and Testing of Materials', value: '4LHA' },
  { key: '9BIC', text: 'State and Local Finance', value: '9BIC' },
  { key: '8ABH', text: 'Statistical Astronomy', value: '8ABH' },
  { key: '4IGG', text: 'Statistical Communication Theory--Communication/Radar(Electrical Engineering)', value: '4IGG' },
  { key: '4TLY', text: 'Statistical Communication Theory--Systems Engineering', value: '4TLY' },
  { key: '1AFF', text: 'Statistical Design and Analysis', value: '1AFF' },
  { key: '9FID', text: 'Statistical Development', value: '9FID' },
  { key: '8HXL', text: 'Statistical Mechanics and Kinetic Theory', value: '8HXL' },
  { key: '9BJD', text: 'Statistical Methods', value: '9BJD' },
  { key: '4LHB', text: 'Statistical Quality Control', value: '4LHB' },
  { key: '6IYY', text: 'Statistics (Mathematics)', value: '6IYY' },
  { key: '4MGE', text: 'Steam Engines', value: '4MGE' },
  { key: '2CKF', text: 'Steel Sculpture', value: '2CKF' },
  { key: '8AAU', text: 'Stellar Energy Generation, Nucleogenesis', value: '8AAU' },
  { key: '8BBF', text: 'Stereo-Plotting', value: '8BBF' },
  { key: '3ACT', text: 'Steriods--Biology', value: '3ACT' },
  { key: '8CCT', text: 'Steriods--Chemistry', value: '8CCT' },
  { key: '0YBT', text: 'Steriods--Inter-Area Specialization', value: '0YBT' },
  { key: '8CGT', text: 'Steriods--Organic Chemistry', value: '8CGT' },
  { key: '8GAD', text: 'Still Photography', value: '8GAD' },
  { key: '6HEY', text: 'Stochastic Processes, General (Mathematics)', value: '6HEY' },
  { key: '2CKG', text: 'Stone Sculpture', value: '2CKG' },
  { key: '0YUY', text: 'Strategic Intelligence', value: '0YUY' },
  { key: '0YSB', text: 'Strategic and Tactical Science/C3', value: '0YSB' },
  { key: '0YSA', text: 'Strategic and Tactical Science/Electronic Warfare', value: '0YSA' },
  { key: '9HJC', text: 'Stratification', value: '9HJC' },
  { key: '8DCM', text: 'Stratigraphy', value: '8DCM' },
  { key: '4AGG', text: 'Stress Analysis--Aeronautical Engineering', value: '4AGG' },
  { key: '4EGF', text: 'Stress Analysis--Astronautical Engineering', value: '4EGF' },
  { key: '4HGA', text: 'Structural Analysis', value: '4HGA' },
  { key: '4HGB', text: 'Structural Design--Civil Engineering', value: '4HGB' },
  { key: '4MHF', text: 'Structural Design--Mechanical Engineering', value: '4MHF' },
  { key: '4HGC', text: 'Structural Dynamics', value: '4HGC' },
  { key: '4HGY', text: 'Structural Engineering', value: '4HGY' },
  { key: '8DCN', text: 'Structural Geology, Igneous and Metamorphic', value: '8DCN' },
  { key: '8DCO', text: 'Structural Geology, Sedimentary', value: '8DCO' },
  { key: '4VEM', text: 'Structural and Pavements Civil Engineering Technology', value: '4VEM' },
  { key: '4AGH', text: 'Structure Test and Analysis--Aeronautical Engineering', value: '4AGH' },
  { key: '4EGH', text: 'Structure Test and analysis', value: '4EGH' },
  { key: '9FGD', text: 'Structure and Dynamcis of Personality', value: '9FGD' },
  { key: '8HNN', text: 'Structure and Property of Fluids', value: '8HNN' },
  { key: '4AGY', text: 'Structures--Aeronautical Engineering', value: '4AGY' },
  { key: '4EGY', text: 'Structures--Astronautical Engineering', value: '4EGY' },
  { key: '2BCC', text: 'Student Personnel--Education', value: '2BCC' },
  { key: '9FDF', text: 'Student Personnel--Psychology', value: '9FDF' },
  { key: 'Z055', text: 'Studies in War and Peace', value: 'Z055' },
  { key: '9DJY', text: 'Subject Histories (Academic Disciplines)', value: '9DJY' },
  { key: '2GEE', text: 'Subject-Object and Their Relations (Philosophy)', value: '2GEE' },
  { key: '2GIH', text: 'Substance and Accident (Philosophy)', value: '2GIH' },
  { key: '8HOT', text: 'Superconductivity', value: '8HOT' },
  { key: '8HNO', text: 'Superfluidity', value: '8HNO' },
  { key: '4EBD', text: 'Supersonic and Hypersonic Dynamics', value: '4EBD' },
  { key: '2BAH', text: 'Supervisory Practices', value: '2BAH' },
  { key: '1AMS', text: 'Supply Management', value: '1AMS' },
  { key: '8CHU', text: 'Surface Chemistry', value: '8CHU' },
  { key: '8HOU', text: 'Surface Physics', value: '8HOU' },
  { key: '8EAH', text: 'Surface Waters', value: '8EAH' },
  { key: '7DND', text: 'Surgical Pathology', value: '7DND' },
  { key: '6IIY', text: 'Survey Methods (Mathematics)', value: '6IIY' },
  { key: '4VEL', text: 'Surveying Technology', value: '4VEL' },
  { key: '4HHY', text: 'Surveying and Mapping', value: '4HHY' },
  { key: '9FJH', text: 'Surveys and Polls', value: '9FJH' },
  { key: '9ILY', text: 'Survival & Rescue Operations', value: '9ILY' },
  { key: '9DLG', text: 'Switzerland (History)', value: '9DLG' },
  { key: '0CBE', text: 'Symbolic Language Development', value: '0CBE' },
  { key: '2GHC', text: 'Symbolic Logic (Philosophy)', value: '2GHC' },
  { key: '9FEM', text: 'Symbolic Processes, Problem Solving', value: '9FEM' },
  { key: '8FBF', text: 'Synoptic Climatology', value: '8FBF' },
  { key: '8FEA', text: 'Synoptic Meteorology', value: '8FEA' },
  { key: '9DGG', text: 'Syria (History)', value: '9DGG' },
  { key: '3AAD', text: 'Systemic Anatomy', value: '3AAD' },
  { key: '0YJY', text: 'Systems Analysis--Inter-Area Specialization', value: '0YJY' },
  { key: '1AMG', text: 'Systems Analysis--Logistics Management', value: '1AMG' },
  { key: '4ACF', text: 'Systems Design', value: '4ACF' },
  { key: '4TYY', text: 'Systems Engineering', value: '4TYY' },
  { key: '4BDY', text: 'Systems Integration', value: '4BDY' },
  { key: '0YKY', text: 'Systems Management--Inter-Area Specialization', value: '0YKY' },
  { key: '1ASY', text: 'Systems Management--Management', value: '1ASY' },
  { key: '4RDY', text: 'Systems Safety', value: '4RDY' },
  { key: '0YTA', text: 'Systems Technology (Command, Control and Communications)', value: '0YTA' },
  { key: '4TMY', text: 'Systems Theory (Systems Engineering)', value: '4TMY' },
  { key: '4LDC', text: 'Systems and Procedures Design', value: '4LDC' },
  { key: '2DYN', text: 'TAGALOG', value: '2DYN' },
  { key: '4VPX', text: 'TEXTL-TECH OTHER', value: '4VPX' },
  { key: '6JXY', text: 'TOP-OTHER', value: '6JXY' },
  { key: '1ATK', text: 'TRANS MGT/AIR MOB', value: '1ATK' },
  { key: '1CBD', text: 'TRANS-TRAF-MGT', value: '1CBD' },
  { key: '2DYO', text: 'TURKISH', value: '2DYO' },
  { key: '9DKL', text: 'Tadjik SSR (History)', value: '9DKL' },
  { key: '1AAG', text: 'Tax Accounting', value: '1AAG' },
  { key: '9GEF', text: 'Taxation', value: '9GEF' },
  { key: '5ABP', text: 'Taxation Law', value: '5ABP' },
  { key: '3ABC', text: 'Taxonomy (Bacteriology)', value: '3ABC' },
  { key: '2IBE', text: 'Teacher Aid', value: '2IBE' },
  { key: '2CGF', text: 'Technical Acquisition', value: '2CGF' },
  { key: '1CAK', text: 'Technical Management', value: '1CAK' },
  { key: 'Z051', text: 'Technical Studies ', value: 'Z051' },
  { key: '2FEC', text: 'Techniques of Delivery', value: '2FEC' },
  { key: '3ACU', text: 'Technology, Methodology (Biochemistry)--Biology', value: '3ACU' },
  { key: '8ECU', text: 'Technology, Methodology (Biochemistry)--Chemistry', value: '8ECU' },
  { key: '0YBU', text: 'Technology, Methodology (Biochemistry)--Inter-Area Specialization', value: '0YBU' },
  { key: '8DDI', text: 'Tectonophysics', value: '8DDI' },
  { key: '0YTY', text: 'Telecommunications', value: '0YTY' },
  { key: '4VHN', text: 'Telecommunications Sytems Control Technology', value: '4VHN' },
  { key: '4IGH', text: 'Telemetry', value: '4IGH' },
  { key: '0YTB', text: 'Teleprocessing Science', value: '0YTB' },
  { key: '4IGI', text: 'Television Systems', value: '4IGI' },
  { key: '2CIE', text: 'Tempera Painting', value: '2CIE' },
  { key: '8HPE', text: 'Temperature and Its Measurements', value: '8HPE' },
  { key: '8CGU', text: 'Terpenes and Other Alicyclics', value: '8CGU' },
  { key: '8BAF', text: 'Terrain Model Cartography', value: '8BAF' },
  { key: '8BBG', text: 'Terrestrial Photogrammetry', value: '8BBG' },
  { key: '9FIE', text: 'Test Construction, Validation', value: '9FIE' },
  { key: '9FIF', text: 'Test Theory, Scale Analysis', value: '9FIF' },
  { key: '2BCD', text: 'Tests and Measurements', value: '2BCD' },
  { key: '4UYY', text: 'Textile Engineering', value: '4UYY' },
  { key: '4VPY', text: 'Textile Technology', value: '4VPY' },
  { key: '8CGV', text: 'Textiles and Related Products', value: '8CGV' },
  { key: '9DDK', text: 'Thailand (History)', value: '9DDK' },
  { key: '2HCY', text: 'Theology', value: '2HCY' },
  { key: '8BAG', text: 'Theoretical Cartography', value: '8BAG' },
  { key: '9CBG', text: 'Theoretical Geography', value: '9CBG' },
  { key: '8CEV', text: 'Theoretical Inorganic Chemistry', value: '8CEV' },
  { key: '4NCB', text: 'Theoretical Physical Metallurgy', value: '4NCB' },
  { key: '9HKE', text: 'Theory Construction', value: '9HKE' },
  { key: '9BBD', text: 'Theory of Business Fluctuations', value: '9BBD' },
  { key: '4WEY', text: 'Theory of Computing', value: '4WEY' },
  { key: '4KCD', text: 'Theory of Elasticity', value: '4KCD' },
  { key: '6HFY', text: 'Theory of Generating Functions (Mathematics)', value: '6HFY' },
  { key: '9BDC', text: 'Theory of International Trade', value: '9BDC' },
  { key: '4KCE', text: 'Theory of Plasticity', value: '4KCE' },
  { key: '6IJY', text: 'Theory of Statistical Inference (Mathematics)', value: '6IJY' },
  { key: '8HAK', text: 'Theory of Waves and Vibrations', value: '8HAK' },
  { key: '7DUB', text: 'Therapeutic Radiology', value: '7DUB' },
  { key: '8HOV', text: 'Thermal Conduction in Solid State', value: '8HOV' },
  { key: '4AGI', text: 'Thermal Effects (Aeronautical Engineering)', value: '4AGI' },
  { key: '8HKD', text: 'Thermal Effects (Nuclear Effects Physics)', value: '8HKD' },
  { key: '8HPY', text: 'Thermal Physics', value: '8HPY' },
  { key: '8HPF', text: 'Thermal Properties', value: '8HPF' },
  { key: '4ICG', text: 'Thermionic Tubes (Non-Microwave)', value: '4ICG' },
  { key: '8CHV', text: 'Thermochemistry', value: '8CHV' },
  { key: '8HPH', text: 'Thermodynamic Relations', value: '8HPH' },
  { key: '8HPI', text: 'Thermodynamic Tables', value: '8HPI' },
  { key: '8HPG', text: 'Thermodynamics', value: '8HPG' },
  { key: '4DCC', text: 'Thermodynamics (Architectural Engineering)', value: '4DCC' },
  { key: '4MIY', text: 'Thermodynamics and Heat Transfer', value: '4MIY' },
  { key: '4MIE', text: 'Thermodynamics of Propulsion Systems', value: '4MIE' },
  { key: '4KAD', text: 'Thermodynamics--Engineering Sciences', value: '4KAD' },
  { key: '8CHW', text: 'Thermodynamics--Physical Chemistry', value: '8CHW' },
  { key: '8HOW', text: 'Thin Films', value: '8HOW' },
  { key: '2GBO', text: 'Thomism', value: '2GBO' },
  { key: '7DVY', text: 'Thoracic Surgery', value: '7DVY' },
  { key: '6IKY', text: 'Time Series Analysis', value: '6IKY' },
  { key: '1AFG', text: 'Time Series and Index Numbers', value: '1AFG' },
  { key: '4LCE', text: 'Time and Motion Study--Human Factors in Engineering', value: '4LCE' },
  { key: '1AKK', text: 'Time and Motion Study--Industrial or Production Management', value: '1AKK' },
  { key: '4LDD', text: 'Time and Motion Study--Job Design, Industrial Engineering', value: '4LDD' },
  { key: '3ANH', text: 'Tissue Antibodies; Autoantibodies', value: '3ANH' },
  { key: '8CBM', text: 'Titrimetric Analysis', value: '8CBM' },
  { key: '8BBH', text: 'Topographic Photogrammetry', value: '8BBH' },
  { key: '8BAH', text: 'Topography', value: '8BAH' },
  { key: '6JFY', text: 'Topological Algebra', value: '6JFY' },
  { key: '6JYY', text: 'Topology (Mathematics)', value: '6JYY' },
  { key: '9CBH', text: 'Toponymy', value: '9CBH' },
  { key: '3ASH', text: 'Toxicology--Pharmacology', value: '3ASH' },
  { key: '7ABT', text: 'Toxicology--Professional Services', value: '7ABT' },
  { key: '5ABQ', text: 'Trademark Law', value: '5ABQ' },
  { key: '1ATG', text: 'Traffic Control', value: '1ATG' },
  { key: '9HAL', text: 'Traffic Enforcement', value: '9HAL' },
  { key: '4VHO', text: 'Training Devices Technology', value: '4VHO' },
  { key: '9FFH', text: 'Training and Development', value: '9FFH' },
  { key: '4EDG', text: 'Trajectories and Orbits', value: '4EDG' },
  { key: '8CEW', text: 'Transitional Elements', value: '8CEW' },
  { key: '8HNP', text: 'Transport Phenomena (Physics of Fluids)', value: '8HNP' },
  { key: '8EAI', text: 'Transportation (Hydrology)', value: '8EAI' },
  { key: '9CCE', text: 'Transportation Geography--Economic Geography', value: '9CCE' },
  { key: '1ATH', text: 'Transportation Geography--Transportation Management', value: '1ATH' },
  { key: '1ATY', text: 'Transportation Management', value: '1ATY' },
  { key: '4REY', text: 'Transportation Safety', value: '4REY' },
  { key: '4HIY', text: 'Transportation and Traffic Engineering', value: '4HIY' },
  { key: '1CBE', text: 'Travel Agent', value: '1CBE' },
  { key: '4IBG', text: 'Traveling Wave Circuits', value: '4IBG' },
  { key: '5ABR', text: 'Trial Law', value: '5ABR' },
  { key: '6BRY', text: 'Trigonometric Series and Integrals', value: '6BRY' },
  { key: '8FEI', text: 'Tropical Meteorology', value: '8FEI' },
  { key: '5ABS', text: 'Trust Law', value: '5ABS' },
  { key: '4AEK', text: 'Turbojet', value: '4AEK' },
  { key: '8HNQ', text: 'Turbulence', value: '8HNQ' },
  { key: '3BCF', text: 'Turf and Ornamental Crops', value: '3BCF' },
  { key: '9DGH', text: 'Turkey (History)', value: '9DGH' },
  { key: '9DKM', text: 'Turkman SSR (History)', value: '9DKM' },
  { key: '9EAD', text: 'Type Specialization (Comparative Government)', value: '9EAD' },
  { key: '9DKN', text: 'Ukrainian SSR (History)', value: '9DKN' },
  { key: '8HAL', text: 'Ultrasonics', value: '8HAL' },
  { key: 'ZZZ1', text: 'Undeclared', value: 'ZZZ1' },
  { key: '4OAG', text: 'Underground Mining', value: '4OAG' },
  { key: '4OBD', text: 'Underground Storage', value: '4OBD' },
  { key: '4RED', text: 'Underground Transportation Safety', value: '4RED' },
  { key: '8HAM', text: 'Underwater Sound--Accoustics', value: '8HAM' },
  { key: '8EBI', text: 'Underwater Sound--Oceanography', value: '8EBI' },
  { key: '1AOK', text: 'Union Organization', value: '1AOK' },
  { key: '9DKY', text: 'Union of Soviet Socialist Republics (USSR) (History)', value: '9DKY' },
  { key: '4GCY', text: 'Unit Operations (Chemical Engineering)', value: '4GCY' },
  { key: '9DLH', text: 'United Kingdom and Ireland (History)', value: '9DLH' },
  { key: '9DHE', text: 'United States (1789-1900) (History)', value: '9DHE' },
  { key: '9DHF', text: 'United States (1900-Present) (History)', value: '9DHF' },
  { key: '9DHD', text: 'United States (to 1789) (History)', value: '9DHD' },
  { key: '9DHG', text: 'United States Economic History', value: '9DHG' },
  { key: '9DHH', text: 'United States Intellectual History', value: '9DHH' },
  { key: '9DHJ', text: 'United States Political History', value: '9DHJ' },
  { key: '9BDD', text: 'United States Tariff Policy', value: '9BDD' },
  { key: '9DHI', text: 'Unites States Military History', value: '9DHI' },
  { key: '4AFB', text: 'Unmanned Vehicle Dynamics', value: '4AFB' },
  { key: '4AAL', text: 'Unsteady Gas Dynamics', value: '4AAL' },
  { key: '4VPE', text: 'Upholstery', value: '4VPE' },
  { key: '4DAC', text: 'Urban Design--Architectural Engineering', value: '4DAC' },
  { key: '4HAC', text: 'Urban Design--Civil Engineering', value: '4HAC' },
  { key: '9CBI', text: 'Urban Geography', value: '9CBI' },
  { key: '9HDD', text: 'Urban Sociology', value: '9HDD' },
  { key: '7DWY', text: 'Urology', value: '7DWY' },
  { key: '9DII', text: 'Uruguay (History)', value: '9DII' },
  { key: '8CGW', text: 'Use of Isotopes', value: '8CGW' },
  { key: '5ABT', text: 'Utilities Law', value: '5ABT' },
  { key: '9DKO', text: 'Uzbek SSR (History)', value: '9DKO' },
  { key: '7DEF', text: 'VASCULAR SURGERY', value: '7DEF' },
  { key: '7FAX', text: 'VET MED-CLNCL-OTHER', value: '7FAX' },
  { key: '7FBX', text: 'VET MED-FOOD INSP-OTHER', value: '7FBX' },
  { key: '7FXY', text: 'VET MED-OTHER', value: '7FXY' },
  { key: '7FCA', text: 'VET MED-PUB HLTH-EPID/EPIZO', value: '7FCA' },
  { key: '7FCX', text: 'VET MED-PUB HLTH-OTHER', value: '7FCX' },
  { key: '7FDX', text: 'VET MED-RSRCH-OTHER', value: '7FDX' },
  { key: '2DYR', text: 'VIETNAMESE', value: '2DYR' },
  { key: '2FVY', text: 'VISUAL COMMUNICATIONS', value: '2FVY' },
  { key: '4WDY', text: 'VLSI System Desing', value: '4WDY' },
  { key: '4TNY', text: 'Value Theory (Systems Engineering)', value: '4TNY' },
  { key: '8AAV', text: 'Variable Stars', value: '8AAV' },
  { key: '3AIB', text: 'Vascular Plants', value: '3AIB' },
  { key: '3BFC', text: 'Vegetables', value: '3BFC' },
  { key: '4VKC', text: 'Vehicle Maintenance', value: '4VKC' },
  { key: '4EAE', text: 'Vehicle Performance', value: '4EAE' },
  { key: '4EDH', text: 'Vehicle Stabilization', value: '4EDH' },
  { key: '4EGI', text: 'Vehicle Structural Dynamics', value: '4EGI' },
  { key: '9DIJ', text: 'Venezuela (History)', value: '9DIJ' },
  { key: '3AVB', text: 'Vertebrates', value: '3AVB' },
  { key: '7FBY', text: 'Veterinary Food Inspection', value: '7FBY' },
  { key: '7FYY', text: 'Veterinary Medicine', value: '7FYY' },
  { key: '7FCY', text: 'Veterinary Public Health', value: '7FCY' },
  { key: '7FDY', text: 'Veterinary Research Medicine', value: '7FDY' },
  { key: '7FAD', text: 'Veterinary Surgery', value: '7FAD' },
  { key: '7FAY', text: 'Veterninary Clinical Medicine', value: '7FAY' },
  { key: '4MBE', text: 'Vibrations', value: '4MBE' },
  { key: '8GAE', text: 'Video Photography', value: '8GAE' },
  { key: '7FDH', text: 'Virology (Veterinary)', value: '7FDH' },
  { key: '3AUY', text: 'Virology--Biology', value: '3AUY' },
  { key: '7ABU', text: 'Virology--Professional Services', value: '7ABU' },
  { key: '8HNR', text: 'Viscosity', value: '8HNR' },
  { key: '4AAM', text: 'Viscous Aerodynamics', value: '4AAM' },
  { key: '4KBE', text: 'Viscous Flow', value: '4KBE' },
  { key: '9FEN', text: 'Vision', value: '9FEN' },
  { key: 'Z005', text: 'Visual Science', value: 'Z005' },
  { key: '9HBD', text: 'Vital Statistics', value: '9HBD' },
  { key: '2CHE', text: 'Vocal Music', value: '2CHE' },
  { key: '9FBE', text: 'Vocational Counseling', value: '9FBE' },
  { key: '2IGY', text: 'Vocational Counseling Technology', value: '2IGY' },
  { key: '2BDE', text: 'Vocational Education (Curriculum Development)', value: '2BDE' },
  { key: '2BIJ', text: 'Vocational Education (Secondary Teaching)', value: '2BIJ' },
  { key: '2BCE', text: 'Vocational Guidance', value: '2BCE' },
  { key: '2JKY', text: 'Vocational Technology', value: '2JKY' },
  { key: '8DDJ', text: 'Volcanology', value: '8DDJ' },
  { key: '9HGD', text: 'Voluntary Associations', value: '9HGD' },
  { key: '7EBF', text: 'WOMEN\'S HEALTH CARE NURSE PRACTITIONER', value: '7EBF' },
  { key: '2BAI', text: 'WORKFORCE EDUCATION  AND  DEVELOPMENT', value: '2BAI' },
  { key: '1AOL', text: 'Wage and Salary Administration', value: '1AOL' },
  { key: '7EBC', text: 'Ward Management', value: '7EBC' },
  { key: '4QCF', text: 'Waste Disposal', value: '4QCF' },
  { key: '4HEC', text: 'Water Pollution Control', value: '4HEC' },
  { key: '4DDC', text: 'Water Supply (Architectural Engineering)', value: '4DDC' },
  { key: '4HED', text: 'Water Supply (Sanitary Engineering)', value: '4HED' },
  { key: '4HID', text: 'Water Systems', value: '4HID' },
  { key: '1ATJ', text: 'Water Transportation Management', value: '1ATJ' },
  { key: '2CIF', text: 'Watercolor Painting', value: '2CIF' },
  { key: '3BEH', text: 'Watershed Management', value: '3BEH' },
  { key: '4HDF', text: 'Waterways and Harbors', value: '4HDF' },
  { key: '4RDE', text: 'Weapon System (Systems Safety)', value: '4RDE' },
  { key: '6EOY', text: 'Weapon Systems Evaluation (Mathematics)', value: '6EOY' },
  { key: '2CCK', text: 'Weaving', value: '2CCK' },
  { key: '3BCG', text: 'Weed Control', value: '3BCG' },
  { key: '4MJY', text: 'Welding Engineering', value: '4MJY' },
  { key: '9DAD', text: 'West Central Africa (History)', value: '9DAD' },
  { key: '9DLY', text: 'Western Europe (History)', value: '9DLY' },
  { key: '2HDY', text: 'Western Religions', value: '2HDY' },
  { key: '4FBH', text: 'Whitewares', value: '4FBH' },
  { key: '2GLI', text: 'Whole Man (Philosophy)', value: '2GLI' },
  { key: '1ANF', text: 'Wholesaling', value: '1ANF' },
  { key: '5ABU', text: 'Wills, Estate Planning, Probate Law', value: '5ABU' },
  { key: '4AAN', text: 'Wind Tunnels', value: '4AAN' },
  { key: '4IGJ', text: 'Wire Communications Systems', value: '4IGJ' },
  { key: '4DBC', text: 'Wiring', value: '4DBC' },
  { key: '2CCL', text: 'Wood Carving', value: '2CCL' },
  { key: '1CAL', text: 'Workcenter Management', value: '1CAL' },
  { key: '5ACN', text: 'Workmen s Compensation Law', value: '5ACN' },
  { key: 'Z056', text: 'World Languages and Cultures', value: 'Z056' },
  { key: '2FFY', text: 'Writing', value: '2FFY' },
  { key: '8CBN', text: 'X-Ray Analysis', value: '8CBN' },
  { key: '8HEK', text: 'X-Ray Interactions', value: '8HEK' },
  { key: '8HEL', text: 'X-Ray Phenomena', value: '8HEL' },
  { key: '8HEM', text: 'X-Ray Technology', value: '8HEM' },
  { key: '2AEB', text: 'Youth Activities', value: '2AEB' },
  { key: '2AEC', text: 'Youth Ministry', value: '2AEC' },
  { key: '3ADC', text: 'Zoogeography--Biology', value: '3ADC' },
  { key: '9CAC', text: 'Zoogeography--Geography', value: '9CAC' },
  { key: '0YCC', text: 'Zoogeography--Inter-Area Specialization', value: '0YCC' },
  { key: '3AVY', text: 'Zoology', value: '3AVY' },
  { key: '7FCB', text: 'Zoonoses', value: '7FCB' },
]
