import * as React from "react";

const SpaceForce = props => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="31.4 10 257.18 300"
    {...props}
    height={ props.size }
  >
    <path d="m181.32 175.1-17.17 10.01 51.64 31.15-50.23-150.31-.85-2.55 16.61 111.7z" />
    <path d="m82.05 243.84 78.06-47L238 243.63 160.1 10Zm78.14-52.69-69.13 41.42L160 25.7l69 206.86Z" />
    <path d="m156.69 157.06-10.97 3.44 11 3.25 3.38 11.01 3.26-11.01 10.66-3.37-10.62-3.32-3.38-11-3.33 11zM65.09 284.87l-8.39.05v22.32l3.7 2.76v-7.49h5.76c3 0 7.86-3.38 7.9-8.56a8.79 8.79 0 0 0-8.97-9.08Zm.75 14.18H60.4v-10.87h4.86a5.53 5.53 0 0 1 5.47 5.39 5.29 5.29 0 0 1-4.89 5.48ZM85.76 284.85 76.44 310h3.92l2.08-5.47h10.21l2.14 5.47h4l-9.49-25.09Zm-2.27 16.07 4.07-11.12 4 11.12ZM114.52 306a9.43 9.43 0 0 1-.09-18.86h.09a9.43 9.43 0 0 1 8.1 4.61l3-2a13.1 13.1 0 1 0 .48 13.4l-3.34-2a9.44 9.44 0 0 1-8.24 4.85ZM200.27 283.76a13.1 13.1 0 0 0 0 26.2 13.09 13.09 0 0 0 13.1-13.1 13.1 13.1 0 0 0-13.1-13.1Zm-.12 22.3a9.43 9.43 0 0 1-.08-18.86h.08a9.43 9.43 0 1 1 0 18.86ZM255.28 306a9.43 9.43 0 0 1-.09-18.86h.09a9.43 9.43 0 0 1 8.1 4.61l3-2.05a13.1 13.1 0 1 0 .48 13.4l-3.34-2a9.43 9.43 0 0 1-8.24 4.9ZM145.14 299l2.45-3.26h-11.3v-7.4h8.68l2.56-3.49H132.8v25.09h12.28l2.51-3.49h-11.3V299h8.85zM277.29 298.98h8.84l2.45-3.26h-11.29v-7.39h8.67l2.56-3.5h-14.73v25.1h12.29l2.5-3.5h-11.29v-7.45zM236.45 292.93c0-6.41-6.13-8.31-8.64-8.31l-8.07.08v22.64l3.62 2.64v-22.07h4.37c1.38.08 5.28.93 5.35 5-.14 3.56-3.42 4.82-5.19 4.86l-2.92.08 7.78 12.15h4.44l-6.38-9.64c2.71-.93 5.58-2.61 5.64-7.43Z" />
    <path d="M155.58 63.69 104.5 216.56l51.64-31.15-17.17-10.01 16.61-111.71zM180.64 288.05l2.31-3.34-14.99.11v22.71l3.81 2.42V299h8.29l2.54-3.46h-10.83v-7.49h8.87zM244.53 269.77a3.45 3.45 0 0 1-2.5-1.11l-.9 1a4.76 4.76 0 0 0 3.32 1.4c1 0 3-.14 3.15-2.53.06-1.57-1.23-2.09-3-2.48-.82-.25-1.79-.45-1.74-1.33.08-.67.52-1.11 1.57-1.09a3.42 3.42 0 0 1 2.09.91l.8-1.09a3.92 3.92 0 0 0-3-1.06c-1.75 0-2.9.92-3 2.44 0 1.76 1.72 2.26 3 2.59.78.23 1.73.2 1.79 1.2s-1.11 1.11-1.58 1.15ZM80.47 267.58v-4.84H78.8v4.65c0 3.27-5 3.16-5 0v-4.68h-1.71v5a4 4 0 0 0 4.25 3.67c2.15-.05 4.1-1.38 4.13-3.8ZM90.75 265.63l6.8 5.7v-8.62h-1.53v5.38l-6.88-5.66v8.71h1.61v-5.51zM106.7 262.75h1.61v8.39h-1.61zM120.79 271.19v-6.95h3.1v-1.61h-7.86v1.61h3.15v6.95h1.61zM204.37 269.55l.68 1.57h1.73l-4.26-8.47-4.28 8.44h1.63l.93-1.58Zm-1.87-3.65 1.31 2.46h-2.55ZM149.66 262.77h-3.88v8.4h4a4.32 4.32 0 0 0 3.82-4.17 4.42 4.42 0 0 0-3.94-4.23Zm-.57 7h-1.73v-5.44h1.73a2.71 2.71 0 0 1 2.91 2.65 2.87 2.87 0 0 1-2.91 2.74ZM173.81 270.05a3.41 3.41 0 0 1-2.5-1.11l-.9 1a4.72 4.72 0 0 0 3.32 1.4c1 0 3-.14 3.15-2.53.06-1.56-1.23-2.08-3-2.48-.82-.25-1.79-.45-1.74-1.33.08-.67.52-1.11 1.57-1.09a3.43 3.43 0 0 1 2.09.92l.8-1.09a3.93 3.93 0 0 0-3-1.07c-1.75 0-2.91.92-3 2.44 0 1.76 1.72 2.26 3 2.59.78.23 1.73.2 1.79 1.2s-1.12 1.1-1.58 1.15ZM137.76 264.2v-1.44h-6.32v8.39h6.38v-1.42h-4.77v-2.05h3.77v-1.44h-3.77v-2.04h4.71zM189.03 271.19v-6.94h3.09v-1.61h-7.86v1.61h3.15v6.94h1.62zM220.24 264.3v-1.61h-7.87v1.61h3.15v6.94h1.62v-6.94h3.1zM234.08 264.21v-1.44h-6.32v8.39h6.38v-1.41h-4.76v-2.06h3.77v-1.44h-3.77v-2.04h4.7zM41.66 295.23c-1.44-.49-5.27-1.3-5.45-4.26 0-1.84 1.73-3.58 4.62-3.53a6 6 0 0 1 4.94 2.66l2.9-2.16a10 10 0 0 0-7.93-3.6c-5.53.08-8.09 3.68-8.14 6.67 0 5.67 5.34 6.4 8.62 7.62 1.71.56 4.32 1.41 4.19 4-.32 3-2.24 3.68-4.62 4s-4.66-1.38-6.19-3.58l-3.18 2.42a12.15 12.15 0 0 0 9.39 4.53c5-.07 8.34-3.56 8.39-7.46-.06-4.93-4.52-6.35-7.54-7.31Z" />
  </svg>
);

export default SpaceForce;
