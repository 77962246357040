import React from 'react'
import { MAX_E2O_FILE_SIZE, MAX_E2O_FILE_SIZE_DISPLAY } from '../afcep.constants'
import theme, { LightPalette } from '../../../theme'
import {
  P,
  Section,
  SectionTitle,
  StyledLabel,
  UploadFileButton,
  StyledErrorLabel,
  UploadFileButtonWrapper
} from '../afcepLandingPage/AfcepLandingPage.styles'
import SvgUpload from '../../../icons/Upload'
import { FormItem } from '../../../forms/form.styles'
import PropTypes from 'prop-types'

export const Recommendation = ({ onFileSelected, submitError, setSubmitError, recommendationFileName }) => {

  // target is the field being evaluated;
  const validateFileSize = () => ({ target }) => {
    const files = target.files

    if (files[0] && files[0].size > MAX_E2O_FILE_SIZE) {
      setSubmitError(`File size must be under ${MAX_E2O_FILE_SIZE_DISPLAY}`)
      return
    }
    onFileSelected(files[0])
    setSubmitError(null)
  }

  return (
    <Section>
      <SectionTitle style={ { paddingBottom: theme.spacing.xs } } data-testid='commanders-approval-recommendation-section-title'>
        Commander's Approval / Recommendation
      </SectionTitle>
      <P data-testid='commanders-approval-recommendation-description'>Upload a copy of your Commander's AFCEP request Memo.</P>
      <P data-testid='file-requirements'>
        <strong data-testid='maximum-file-size-text'>Maximum file size:</strong> Under { MAX_E2O_FILE_SIZE_DISPLAY }<br />
        <strong data-testid='accepted-file-types-text'>Accepted file types:</strong> pdf
      </P>
      <FormItem style={ { paddingBottom: theme.spacing.l } }>
        <UploadFileButtonWrapper>
          <UploadFileButton htmlFor='recommendationLocation' error='' data-testid='upload-file-button'>
            <SvgUpload color={ LightPalette.text.action } />
            Upload File
          </UploadFileButton>
          <StyledLabel>{ recommendationFileName }</StyledLabel>
        </UploadFileButtonWrapper>
        <input
          hidden
          type='file'
          id='recommendationLocation'
          data-testid='recommendationLocation'
          name='recommendationLocation'
          accept='.pdf'
          onChange={ validateFileSize() }
        />
        { submitError ? <StyledErrorLabel htmlFor='recommendationLocation'>{ submitError }</StyledErrorLabel> : <></> }
      </FormItem>
    </Section>
  )
}

Recommendation.propTypes = {
  onFileSelected: PropTypes.func,
  submitError: PropTypes.string,
}
