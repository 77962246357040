import config from '../config'
import { get as getInternal } from './service'

const get = (url, params, token) => {
  let headers = {}
  if (token) {
    headers =
    {
      Authorization: `Bearer ${token}`,
    }
  }
  return getInternal(
    url,
    params,
    headers
  )
}

const getAimHighApp = () => {
  return get(`${config.afreCmsServiceApiUrl}/app-shares`, { populate: '*' })
}

const getEvents = () => {
  return get(`${config.afreCmsServiceApiUrl}/events`)
}

const getEvent = id => {
  return get(`${config.afreCmsServiceApiUrl}/events/${id}`)
}

const getCareer = slug => {
  return get(`${config.afreCmsServiceApiUrl}/afr-careers-v2?filters[slug][$eq]=${slug}`)
}

const getUserAvatarUrl = (userId, filename) => {
  return `${config.afreCmsServiceApiUrl}/user-avatar/${userId}/${filename}`
}

const getRecruitersByEmail = email => {
  return get(`${config.afreCmsServiceApiUrl}/recruiters-v2?filters[email][$eq]=${email}`)
}

const getFeatureToggles = () => {
  return get(`${config.afreCmsServiceApiUrl}/feature-toggles`)
}

export default {
  getEvents,
  getEvent,
  getCareer,
  getAimHighApp,
  getUserAvatarUrl,
  getRecruitersByEmail,
  getFeatureToggles,
}
