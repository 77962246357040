import { call, put, takeLatest } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import immutable from 'seamless-immutable'
import afreCmsService from '../api/afre.cms.service'
import AsyncSaga from '../redux/sagas/util/AsyncSaga'

const INITIAL_STATE = {
  features: [],
}

export default function reducer(state = immutable(INITIAL_STATE), action = {}) {
  switch (action.type) {
    case getFeatureTogglesSaga.SUCCESS :
      return state.set('features', action.payload)
    default:
      return state
  }
}

const getFeatureToggleState = state => (state && state.featureToggleStore) || {}

export const selectFeatureEnabled = createSelector(
  [getFeatureToggleState, (_state, featureName) => featureName],
  (featureToggleStore, featureName) => {
    const feature = (featureToggleStore.features || []).find(featureToggle => featureToggle.name === featureName)
    return (feature && feature.value) || false
  }
)

export const GET_FEATURE_TOGGLES = 'GET_FEATURE_TOGGLES'
export const getFeatureTogglesSaga = new AsyncSaga(GET_FEATURE_TOGGLES, function* getFeatureTogglesGenerator() {
  const featureTogglesResponse = yield call(afreCmsService.getFeatureToggles)
  const featureTogglesData = featureTogglesResponse?.data
  if (featureTogglesData) {
    yield put(getFeatureTogglesSaga.success(featureTogglesData))
  }
}).catch(function* getFeatureTogglesCatch() {
  yield put(getFeatureTogglesSaga.error(new Error('Error getting Feature Toggles')))
})

export const featureToggleSagas = [
  takeLatest(getFeatureTogglesSaga.REQUEST, getFeatureTogglesSaga.saga),
]
