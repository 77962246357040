import styled from '@emotion/styled'
import { Button as SemanticButton } from 'semantic-ui-react'

const Button = styled.button`
    color: ${props => props.theme.colors.button.default.color};
    background: ${props => props.theme.colors.button.default.background};
    border: 1px solid ${props => props.theme.colors.button.default.border};
    height: 60px;
    width: 100%;
    border-radius: 30px;
    text-transform: uppercase;
    font-size: ${props => props.theme.typography.fontSizes.s}px;
    font-weight: ${props => props.theme.typography.fontWeights.bold};
    margin: ${props => props.theme.spacing.xs}px 0;
    cursor: pointer;

    &:hover, &:active {
        background: ${props => props.theme.colors.button.default.hover.background};
    }
`
export const AimHighButton = styled(SemanticButton)`
    border-radius: ${props => props.theme.spacing.s}px!important;
    font-family: stratum2-regular!important;
    text-transform: uppercase!important;
`

export const GhostButton = styled.button`
    appearance: none;
    border: none;
    background: transparent;
    text-transform: uppercase;
    font-size: ${props => props.theme.typography.fontSizes.m}px;
    font-family: stratum2-bold;
`

export default Button
