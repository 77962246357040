import styled from '@emotion/styled'

const FieldLabel = styled.label`
font-size: ${props => props.theme.typography.fontSizes.m}px;
font-weight: ${props => props.theme.typography.fontWeights.bold};
color: ${props => {
  const errorColor = props.theme?.system?.error || props.theme.colors.red.default
  const color = props.theme?.text?.contrast || props.theme.colors.white.default
  return (props.hasError ? errorColor : color)
}};
display: block;
padding: ${props => props.theme.spacing.s}px;
padding-bottom: 0;
`

export default FieldLabel
