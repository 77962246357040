import { mergeColors, withOpacity } from '@aimhigh/theme'
import styled from '@emotion/styled'

export const HeaderTitle = styled.h1`
  font-family: ${({ theme }) => theme.fontFamilies.stratum2};
  font-size: ${({ theme }) => theme.fontSizes.xl}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  text-align: center;
  text-transform: uppercase;

  letter-spacing: 0.05em;

  margin-bottom: 0;
`

export const ProgressCircleWrapper = styled.div`
  position: relative;

  margin-top: ${({ theme }) => theme.spacing.m * 4}px;
  margin-bottom: ${({ theme }) => theme.spacing.m}px;
`

export const ProgressCircleLabel = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: ${({ theme }) => theme.spacing.s}px;

  > h3 {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes.xl}px;
    line-height: ${({ theme }) => theme.fontSizes.xl}px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-family: ${({ theme }) => theme.fontFamilies.stratum2};

    letter-spacing: 0.025em;
  }

  > p {
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    line-height: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-family: ${({ theme }) => theme.fontFamilies.stratum2};
    color: ${({ theme }) => withOpacity(theme.colors.text.white, 0.5)};

    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
`

export const StatusRows = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacing.xm}px;
`

export const StatusRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;

  padding: ${({ theme }) => theme.spacing.xm}px;
  gap: ${({ theme }) => theme.spacing.m}px;

  cursor: pointer;

  h2 {
    font-family: ${({ theme }) => theme.fontFamilies.openSans};
    font-size: ${({ theme }) => theme.fontSizes.m}px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};

    color: ${({ theme }) => theme.colors.text.white};

    margin: 0;
  }

  p {
    display: flex;
    align-items: center;

    gap: ${({ theme }) => theme.spacing.xs}px;

    font-family: ${({ theme }) => theme.fontFamilies.openSans};
    font-size: ${({ theme }) => theme.fontSizes.xm}px;
    font-weight: ${({ theme }) => theme.fontWeights.regular};

    color: ${({ theme }) => withOpacity(theme.colors.text.white, 0.75)};
  }
`

export const StatusLine = styled.div`
  position: absolute;
  top: 0;
  left: ${({ theme }) => theme.spacing.xm + theme.iconSizes.l / 2}px;

  margin: ${({ theme }) => theme.spacing.xm + theme.iconSizes.l / 2}px auto;

  width: 1px;
  height: ${({ theme }) => `calc(100% - ${(theme.spacing.xm + (theme.iconSizes.l / 2)) * 2}px)`};

  background: ${({ theme }) => theme.colors.brand.brand};
`

const getDotBackgroundColor = ({ theme, $next, $complete }) => {
  if ($complete) {
    return mergeColors(theme.colors.brand.brandDark, theme.colors.system.actionLight, 0.25)
  }

  if ($next) {
    return mergeColors(theme.colors.brand.brandDark, theme.colors.background.level3, 0.25)
  }

  return theme.colors.brand.brand
}

const getDotBorderColor = ({ theme, $next, $complete }) => {
  if ($complete) {
    return theme.colors.system.actionLight
  }

  if ($next) {
    return theme.colors.background.level3
  }

  return theme.colors.text.default
}

export const StatusCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ theme }) => theme.iconSizes.l}px;
  height: ${({ theme }) => theme.iconSizes.l}px;
  border-radius: 50%;

  background: ${getDotBackgroundColor};
  border: 1px solid ${getDotBorderColor};

  z-index: 1;

  > svg {
    stroke: ${getDotBorderColor};
  }
`
