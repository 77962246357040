import { ThemeProvider } from '@emotion/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dimmer, Loader, Table } from 'semantic-ui-react'
import { NAV_ROUTES } from '../../navigation/routes'
import UsersSearchForm from '../../pages/users/users.search.form'
import UsersRolesSelector from '../../pages/users/UsersRolesSelector'
import { fetchRoles, fetchUsers } from '../../redux/sagas/user.sagas'
import {
  selectFetchingUsers,
  selectLastUserKey,
  selectRoles,
  selectSearchingUsers,
  selectUpdateUserLoads,
  selectUsers
} from '../../redux/selectors/user.selectors'
import { LightPalette } from '../../theme'
import spacing from '../../theme/spacing'
import { OpaPageHeader } from '../components/PageTitle/OpaPageHeader'
import { ScrollablePage, StickyTableHeader } from './Opa.styles'
import { getStandardAmericanDate } from '../../api/date.util'

export const OpaUsersPage = () => {
  const UPDATING_ROLES = 'ROLES'

  const dispatch = useDispatch()
  const users = useSelector(selectUsers)
  const loading = useSelector(selectUpdateUserLoads)
  const selectedUserRoles = useSelector(selectRoles)
  const isFetching = useSelector(selectFetchingUsers)
  const isSearching = useSelector(selectSearchingUsers)
  const lastUserKey = useSelector(selectLastUserKey)
  const loadId = (id, type) => `${id}_${type}`
  const isLoading = (id, type) => loading.includes(loadId(id, type))
  const getUsers = email => dispatch(fetchUsers.request(email))

  React.useEffect(() => {
    dispatch(fetchUsers.request())
    dispatch(fetchRoles.request({ opaRoles: true }))
  }, [dispatch])

  const renderRoleSelection = (userRoles, id) => (
    <UsersRolesSelector userRoles={ userRoles } roles={ selectedUserRoles } id={ id } />
  )

  const headerControls = [<UsersSearchForm key='userSearchInput' width={ 16 } onSubmit={ values => getUsers({ email: values.emailText }) } />]

  return (
    <>
      <ThemeProvider theme={ LightPalette }>
        <ScrollablePage>
          <Dimmer.Dimmable dimmed={ isSearching || !!isFetching }>
            <Dimmer active={ isSearching || !!isFetching } page>
              <Loader>Loading</Loader>
            </Dimmer>
            <div style={ { margin: spacing.m } }>
              <OpaPageHeader
                pageRoute={ NAV_ROUTES.OPA_USERS_PAGE }
                pageTitle='OPA Users'
                headerComponents={ headerControls }
              />
              <Table compact striped singleLine>
                <StickyTableHeader data-testid='table-header' style={ { top: '150px' } }>
                  <Table.Row data-testid='table-row'>
                    <Table.HeaderCell data-testid='email-table-header-cell'>Email</Table.HeaderCell>
                    <Table.HeaderCell data-testid='first-table-header-cell'>First</Table.HeaderCell>
                    <Table.HeaderCell data-testid='last-table-header-cell'>Last</Table.HeaderCell>
                    <Table.HeaderCell data-testid='creation-date-table-header-cell'>Creation Date</Table.HeaderCell>
                    <Table.HeaderCell data-testid='roles-table-header-cell'>Roles</Table.HeaderCell>
                  </Table.Row>
                </StickyTableHeader>

                <Table.Body data-testid='table-body'>
                  { users.map(({ id,
                    email,
                    firstName,
                    lastName,
                    createdAt,
                    roles }) => {
                    createdAt = getStandardAmericanDate(createdAt)
                    const loadingRoles = isLoading(id, UPDATING_ROLES)

                    return (
                      <Table.Row key={ id } data-testid='user-row'>
                        <Table.Cell data-testid='email-table-cell'>{ email }</Table.Cell>
                        <Table.Cell data-testid='first-table-cell'>{ firstName }</Table.Cell>
                        <Table.Cell data-testid='last-table-cell'>{ lastName }</Table.Cell>
                        <Table.Cell data-testid='creation-date-table-cell'>{ createdAt }</Table.Cell>
                        <Dimmer.Dimmable as={ Table.Cell } dimmed={ loadingRoles } data-testid='roles-table-cell'>
                          <Dimmer active={ loadingRoles } inverted>
                            <Loader size='tiny' />
                          </Dimmer>
                          { renderRoleSelection(roles, id) }
                        </Dimmer.Dimmable>
                      </Table.Row>
                    )
                  }) }
                </Table.Body>
              </Table>
              { lastUserKey && <Button fluid onClick={ () => getUsers() }>Load More Users</Button> }
            </div>
          </Dimmer.Dimmable>
        </ScrollablePage>
      </ThemeProvider>
    </>
  )
}
