import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { Button, Form, Modal } from 'semantic-ui-react'
import FieldLabel from '../../components/Forms/FieldLabel'
import { getSquadronFlights } from '../../redux/sagas/squadron.sagas'
import { deleteUserSquadronInfo, updateUserSquadronInfo } from '../../redux/sagas/user.sagas'
import { required } from '../validation/index'
import { SubmitButtonWrapper } from './squadron.form.styles'

class SquadronInfoForm extends Component {

  state = { isModalOpen: false }

  open = () => this.setState({ isModalOpen: true })
  close = () => this.setState({ isModalOpen: false })

  handleSquadronInfoSubmit = values => {
    const { squadron: squadronId, flight: flightId } = values
    const squadronName = this.props.squadrons.find(s => s.id === squadronId).name
    const flightName = this.props.flights.find(f => f.flightId === flightId).name
    const squadronAndFlightInfo = { id: this.props.userInfo.id, squadronId, flightId, squadronName, flightName }
    this.props.updateUserSquadronInfo(squadronAndFlightInfo)
    this.close()
  }

  handleDeleteSubmit = () => {
    this.props.deleteSquadronInfo(this.props.userInfo.id)
    this.close()
  }

  sortDropdown = (a, b) => {
    let compare = 0
    if (a.text > b.text) {
      compare = 1
    } else if (b.text > a.text) {
      compare = -1
    }
    return compare
  }

  SquadronsDropdown = ({ input, label, squadrons, meta: { touched, error } }) => {
    const formattedOption = squadrons
      .map(l => ({ value: l.id, text: l.name, key: l.id }))
      .asMutable()
      .sort(this.sortDropdown)
    return (
      <>
        <FieldLabel hasError={ touched && error }>{ label }</FieldLabel>
        <Form.Dropdown
          fluid
          selection
          search
          value={ input.value }
          options={ formattedOption }
          onChange={ (_e, data) => {
            this.props.change('flight', null)
            input.onChange(data.value)
            this.props.getSquadronFlights(data.value)
          } }
        />
      </>
    )
  }

  FlightsDropdown = ({ input, label, flights, meta: { touched, error } }) => {
    const flightsForSquadron = flights.filter(f => f.id === this.props.squadronFieldValue)
    const formattedOption = flightsForSquadron
      .map(l => ({ value: l.flightId, text: l.name, key: l.sort }))
      .asMutable()
      .sort(this.sortDropdown)
    return (
      <>
        <FieldLabel hasError={ touched && error }>{ label }</FieldLabel>
        <Form.Dropdown
          fluid
          selection
          search
          value={ input.value }
          options={ formattedOption }
          onChange={ (_e, data) => input.onChange(data.value) }
        />
      </>
    )
  }

  renderModal = () => {
    const { isModalOpen } = this.state
    const { squadrons, handleSubmit, flights, userInfo, squadronFieldValue, getSquadronFlights: getFlights } = this.props
    const isEdit = !!userInfo.squadronId
    return (
      <Modal
        open={ isModalOpen }
        closeIcon
        onClose={ () => this.close() }
        onMount={ () => isEdit && getFlights(userInfo.squadronId) }>
        <Modal.Header>{ isEdit ? 'Edit' : 'Add' } Squadron Info for { userInfo.email }</Modal.Header>
        <Modal.Content>
          <Form onSubmit={ handleSubmit(this.handleSquadronInfoSubmit) }>
            <Field
              id='squadron'
              name='squadron'
              label='Squadron'
              placeholder='Select Squadron'
              props={ { squadrons } }
              component={ this.SquadronsDropdown }
              validate={ required }
            />
            { squadronFieldValue && (
              <Field
                id='flight'
                name='flight'
                label='Flight'
                placeholder='Select Flight'
                props={ { flights } }
                component={ this.FlightsDropdown }
                validate={ required }
              />
            ) }
            { squadronFieldValue && (
              <SubmitButtonWrapper >
                <Button primary type='submit'>Save</Button>
                { isEdit && <Button color='red' onClick={ () => this.handleDeleteSubmit() }>Remove</Button> }
              </SubmitButtonWrapper >
            ) }
          </Form>
        </Modal.Content>
      </Modal>
    )
  }

  render() {
    return (
      <>
        { this.renderModal() }
        <Button data-testid='add-button' onClick={ () => this.open() }>{ this.props.userInfo.squadronId ? 'Edit' : 'Add' }</Button>
      </>
    )
  }
}

SquadronInfoForm.propTypes = {
  handleSubmit: PropTypes.func,
  squadrons: PropTypes.array,
  flights: PropTypes.array,
  userInfo: PropTypes.object,
  squadronFieldValue: PropTypes.string,
  getSquadronFlights: PropTypes.func,
  updateUserSquadronInfo: PropTypes.func,
  change: PropTypes.func,
  deleteSquadronInfo: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.form)
  return {
    initialValues: {
      squadron: ownProps.userInfo.squadronId,
      flight: ownProps.userInfo.flightId,
    },
    squadronFieldValue: selector(state, 'squadron'),
  }
}

const mapActionsToProps = dispatch => {
  return {
    getSquadronFlights: id => dispatch(getSquadronFlights.request(id)),
    updateUserSquadronInfo: params => dispatch(updateUserSquadronInfo.request(params)),
    deleteSquadronInfo: id => dispatch(deleteUserSquadronInfo.request(id)),
  }
}

const Formed = reduxForm({
  enableReinitialize: true,
})(SquadronInfoForm)
const ConnectedSquadroInfoForm = connect(mapStateToProps, mapActionsToProps)(Formed)

export default withRouter(ConnectedSquadroInfoForm)
