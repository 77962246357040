import React from 'react'
import PropTypes from 'prop-types'
import FieldLabel from './FieldLabel'
import { FieldInputContainer } from '../../forms/form.styles'

InputField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  meta: PropTypes.object,
  tooltip: PropTypes.any,
  disabled: PropTypes.bool,
}

function InputField({ input, label, name, type, maxLength, placeholder, tooltip, meta: { touched, error }, disabled }) {
  return (
    <React.Fragment>
      <div style={ { flex: 1, display: 'flex', alignItems: 'baseline' } }>
        <FieldLabel
          htmlFor={ name }
          hasError={ touched && error }>
          { label }
        </FieldLabel>
        { tooltip }
      </div>
      <FieldInputContainer disabled={ disabled }>
        <input { ...input } type={ type } maxLength={ maxLength } placeholder={ placeholder } disabled={ disabled } />
        { touched && error ? <FieldLabel hasError>{ error }</FieldLabel> : <></> }
      </FieldInputContainer>
    </React.Fragment>
  )
}

export default InputField
