import React from 'react'
import PropTypes from 'prop-types'
import FieldLabel from './FieldLabel'
import { FieldInputContainer } from '../../forms/form.styles'
import { HelperText } from './TextAreaField.styles'

TextAreaField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  resize: PropTypes.string,
  helperText: PropTypes.string,
  meta: PropTypes.object,
}

function TextAreaField({
  input,
  label,
  name,
  maxLength,
  placeholder,
  resize = 'none',
  helperText,
  meta: { touched, error },
}) {

  const getHelperText = () => {
    const hasHelpText = helperText && helperText.length > 0

    return (
      <HelperText isFlexEnd={ !hasHelpText }>
        { hasHelpText && helperText }
        { maxLength && (<span>{ input.value.length }/{ maxLength }</span>) }
      </HelperText>
    )
  }

  return (
    <React.Fragment>
      <FieldLabel
        htmlFor={ name }
        hasError={ touched && error }>
        { label }
      </FieldLabel>
      <FieldInputContainer>
        <textarea { ...input } maxLength={ maxLength } placeholder={ placeholder } style={ { resize } } />
        { getHelperText() }
      </FieldInputContainer>
    </React.Fragment>
  )
}

export default TextAreaField
