import React, { useState } from 'react'
import { AfcepRequestCheckboxWrapper, AfcepRequestSectionContainer } from './AfcepLandingPage.styles'
import { Checkbox } from 'semantic-ui-react'
import { NAV_ROUTES } from '../../../navigation/routes'
import NavLink from '../../../components/Navigation/NavLink'
import { AimHighButton } from '../../../components/Button/Button'
import PropTypes from 'prop-types'

export const AfcepLandingPageActionButtons = ({ eventId }) => {
  const [isEnlistedChecked, setIsEnlistedChecked] = useState(false)

  return (
    <AfcepRequestSectionContainer>
      <AfcepRequestCheckboxWrapper>
        <Checkbox
          data-testid='verify-enlisted'
          label='Yes, I confirm that I am an active-duty AF enlisted Airman in the RegAF and have an approved
          Commander AFCEP Request Memo.'
          onChange={ () => setIsEnlistedChecked(!isEnlistedChecked) }
        />

      </AfcepRequestCheckboxWrapper>
      <NavLink to={ `${NAV_ROUTES.AFCEP_REQUEST_FORM}?eventId=${eventId}` } >
        <AimHighButton
          data-testid='begin-afcep-request-button'
          disabled={ !isEnlistedChecked }
          fluid
          primary
          size='large'
          type='button'
          name='Submit'
          style={ { maxWidth: '350px' } }>
          Begin AFCEP Request
        </AimHighButton>
      </NavLink>
    </AfcepRequestSectionContainer>
  )
}

AfcepLandingPageActionButtons.propTypes = {
  eventId: PropTypes.string,
}
