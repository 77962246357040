const calculateAge = birthday => { // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime()
  const ageDate = new Date(ageDifMs) // milliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export const required = value => (value && value !== 'Select' ? undefined : 'Required')

const minLength = min => value => (value && value.length < min ? `Minimum ${min} characters` : undefined)
export const minLength5 = minLength(5)
export const minLength8 = minLength(8)
export const minLength12 = minLength(12)

const maxLength = max => value => (value && value.length > max ? `Max ${max} characters` : undefined)

export const maxLength3 = maxLength(3)
export const maxLength5 = maxLength(5)
export const maxLength6 = maxLength(6)
export const maxLength8 = maxLength(8)
export const maxLength9 = maxLength(9)
export const maxLength12 = maxLength(12)
export const maxLength100 = maxLength(100)
export const maxLength200 = maxLength(200)
export const maxLength250 = maxLength(250)
export const maxLength320 = maxLength(320)

export const WAS_REFERRED = 'This individual was referred '
export const referredNotesMaxLength = maxLength(255 - WAS_REFERRED.length)

const maxValue = max => value => (value && value > max ? `Max value ${max}` : undefined)
export const maxValue99 = maxValue(99)
export const maxValue999 = maxValue(999)
export const maxValue99999 = maxValue(99999)

export const emailRegex = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/u
export const email = value => (value && !emailRegex.test(value) ?
  'Invalid email address' : undefined)

export const minAge = min => value => (value && calculateAge(new Date(value)) < min ? `Must be at least ${min} years old` : undefined)
export const minAge17 = minAge(17)
export const minAge16 = minAge(16)
export const validAfrissDate = value => (value && new Date(value).getTime() < 0 ? 'Must be after Jan. 1, 1970' : undefined)

export const requiredNamed = (value, _allValues, _props, name) => (value && value !== 'Select' ? undefined : `${name} Required`)

export const startDateIsOlderValidationBuilder = (startField, endField) => (_value, values) => {
  const startDate = new Date(values[startField])
  const endDate = new Date(values[endField])

  if (startDate.getTime() > endDate.getTime()) {
    return `Start date must come before end date.`
  }
  return undefined
}

export const startDateIsOlder = startDateIsOlderValidationBuilder('startDate', 'endDate')

export const dateInPast = value => (Date.parse(value) > Date.now() ? 'Date must be in the past' : undefined)

export const zipcode = value => (value && !/^\d{5}(?:[-\s]\d{4})?$/iu.test(value) ?
  'Invalid Zipcode' : undefined)

export const phoneNumber = value => {
  return value &&
      (!/^\d{10}$/u.test(value)) ?
    'Invalid phone number' : undefined
}

export const dodid = value => {
  return value && (!/^\d{10}$/u.test(value)) ? 'DoD Id Number must be 10 digits' : undefined
}

export const stripSpecialCharacters = string => {
  if (!string) {
    return string
  }
  return string.replace(/[^\w.,'\-[\]]/gu, '')
}

export const hasCharactersAfterStrippingSpecial = value => {
  if (!value) {
    return
  }
  const strippedValue = stripSpecialCharacters(value)
  const test = !/^[\w.,'\-[\]]+$/u.test(strippedValue)
  return test ? 'Invalid characters' : undefined
}

export const ssn = value => {
  const unformattedSSN = value.replace(/-/gu, '')
  return unformattedSSN && unformattedSSN.length < 9 ? 'Invalid SSN' : undefined
}

export const requiredBooleanField = value => (value !== undefined && value !== 'select' ? undefined : 'Required')

export const gpa = value => (value && !/^\d\.\d\d?$/u.test(value) && !/^\d$/u.test(value) ?
  'Expected GPA format #.##' : undefined)

export const url = value => (value &&
  // eslint-disable-next-line max-len
  !/^((https?:\/\/)(%[0-9A-Fa-f]{2}|[-()_.!~*';/?:@&=+$,A-Za-z0-9])+)([).!';/?:,])?(\.[a-zA-Z]+)+((\/)?(%[0-9A-Fa-f]{2}|[-()_.!~*';/?:@&=+$,A-Za-z0-9])+([).!';/?:,])*)*$/gmu.test(value) ?
  'Expected complete website link' : undefined)
