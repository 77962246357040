import { ThemeProvider } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Grid, Input, Modal } from 'semantic-ui-react'
import { LightPalette } from '../../../theme'
import spacing from '../../../theme/spacing'
import { ActionButton, CancelButton } from '../../pages/Opa.styles'
import { ErrorLabel } from '../../pages/UploadWeekGroupPage.styles'
import { useAddMtiEmail } from '../../reactQueries/opaQueries'
import { OpaLabel } from '../Forms/OpaLabel'
import { emailRegex } from '../../../forms/validation'

export const MtiEmailModal = ({ isOpen, setOpen }) => {
  const queryClient = useQueryClient()

  const [newEmail, setNewEmail] = useState('')

  const {
    isSuccess: addMtiEmailSuccess,
    error: addMtiEmailError,
    isLoading: addMtiEmailLoading,
    mutate: addMtiEmail,
    reset: resetAddMtiEmail,
  } = useAddMtiEmail(queryClient)

  useEffect(() => {
    if (addMtiEmailSuccess && !addMtiEmailError) {
      resetAddMtiEmail()
      setOpen(false)
    }
  }, [
    addMtiEmailSuccess,
    addMtiEmailError,
    resetAddMtiEmail,
    setOpen,
  ])

  const updateEmail = (eventData, email) => {
    eventData.target.defaultValue = email
    eventData.target.value = email
    setNewEmail(email)
  }

  const addError = addMtiEmailError && addMtiEmailError.response && addMtiEmailError.response.data
  const isLoading = addMtiEmailLoading
  return (
    <Modal
      open={ isOpen }>
      <ThemeProvider theme={ LightPalette }>
        <Modal.Header data-testid='modal-header'>Add MTI Email</Modal.Header>
        <Modal.Content>
          <Modal.Description data-testid='modal-description'>
            { addError && addError.error &&
              <div style={ { paddingBottom: spacing.m } }>
                <ErrorLabel>
                  { addError.error.toUpperCase() }
                </ErrorLabel>
              </div> }
            <Grid>
              <Grid.Column width={ 14 } >
                <Grid.Row data-testid='email-row'>
                  <OpaLabel label='Email' error={ addError && addError.value } required />
                </Grid.Row>
                <Grid.Row >
                  <Input
                    fluid
                    data-testid='email-value-input'
                    placeholder='Enter MTI Email'
                    type='email'
                    defaultValue=''
                    onChange={ (eventData, event) => updateEmail(eventData, event.value) }
                  />
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <CancelButton onClick={ () => {
            setOpen(false)
            resetAddMtiEmail()
            setNewEmail('')
          }
          }>
            Cancel
          </CancelButton>
          {
            isLoading ?
              <ActionButton loading>Loading</ActionButton> :
              <ActionButton
                content='Create'
                icon='checkmark'
                data-testid='action-button'
                disabled={ !emailRegex.test(newEmail) }
                onClick={ () => {
                  addMtiEmail({ updatedEmailData: newEmail })
                } }
              />
          }
        </Modal.Actions>
      </ThemeProvider>
    </Modal>
  )
}

MtiEmailModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
}
