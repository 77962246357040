import { ThemeProvider } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Dimmer, Divider, Form, Grid, Input, Loader, Message, Tab } from 'semantic-ui-react'
import { LargeFieldLabel } from '../../forms/form.styles'
import { NAV_ROUTES } from '../../navigation/routes'
import { selectIsOpaAdmin } from '../../redux/selectors/auth.selectors'
import { LightPalette } from '../../theme'
import spacing from '../../theme/spacing'
import { NewOpaPageHeader } from '../components/PageTitle/OpaPageHeader'
import { useGetWeekGroups, useMilpdsPull, useWeekGroupUpload } from '../reactQueries/opaQueries'
import { ActionButton } from './Opa.styles'

const getFiscalYear = date => {
  const year = new Date(date).getFullYear()

  // Fiscal year starts on October 1st and ends on September 30th
  return new Date(date).getTime() < new Date(year, 9, 1).getTime() ? year : year + 1
}

const calculateNewWeekGroupName = (isLastFiscalYear = false) => {
  if (isLastFiscalYear) {
    return 'WG-52'
  }

  const today = new Date()
  const thisYear = today.getFullYear()
  const lastYear = thisYear - 1
  const thisYearFiscalYearStart = new Date(`October 1, ${thisYear}`)
  const lastYearFiscalYearStart = new Date(`October 1, ${lastYear}`)
  let currentFiscalYearStart = lastYearFiscalYearStart
  if (today.getTime() > thisYearFiscalYearStart.getTime()) {
    currentFiscalYearStart = thisYearFiscalYearStart
  }
  const msInWeek = 1000 * 60 * 60 * 24 * 7
  const weeksSinceFiscalYear = Math.round(Math.abs(today - currentFiscalYearStart) / msInWeek)
  return `WG-${weeksSinceFiscalYear}`
}

const validateWeekGroup = (eventData, weekGroupValue, setterFunction) => {
  if (/^[a-z0-9-]*$/iu.test(weekGroupValue)) {
    eventData.target.defaultValue = weekGroupValue.toUpperCase()
    setterFunction(weekGroupValue.toUpperCase())
  } else {
    eventData.target.value = eventData.target.defaultValue.toUpperCase()
  }
}

const validateSpecialOrderNumber = (eventData, value, setterFunction) => {
  if (/^\d*$/iu.test(value)) {
    eventData.target.defaultValue = value
    setterFunction(value)
  } else {
    eventData.target.value = eventData.target.defaultValue
  }
}

const onControlNumberChange = (value, setterFunction) => {
  if (/^\d*$/iu.test(value) && value.length <= 2) {
    setterFunction(value)
  }
}

export const UploadWeekGroupPage = () => {
  const isOpaAdmin = useSelector(selectIsOpaAdmin)
  const [specialOrderNumber, setSpecialOrderNumber] = useState(undefined)
  const [weekGroup, setWeekGroup] = useState(undefined)
  const [milpdsSpecialOrderNumber, setMilpdsSpecialOrderNumber] = useState(undefined)
  const [milpdsWeekGroup, setMilpdsWeekGroup] = useState(undefined)
  const [selectedFile, setSelectedFile] = useState(undefined)
  const [success, setSuccess] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const [errorMessage, setErrorMessage] = useState({})
  const [successMessage, setSuccessMessage] = useState('Successfully Uploaded Week Group')
  const { status: uploadStatus, error: uploadError, isLoading: uploadingOrders, mutate: uploadWeekGroup } = useWeekGroupUpload()
  const { status: pullStatus, data: pullResponseMessage, error: pullError, isLoading: pullingOrders, mutate: pullMilpdsOrders } = useMilpdsPull()

  const [selectedControlNumber, setSelectedControlNumber] = useState(getFiscalYear(new Date()) % 100)

  const handleDismiss = () => {
    setSuccess(false)
    setHasErrors(false)
  }

  const handleSuccessMessage = () => {
    setSuccess(true)
    setHasErrors(false)
  }

  const handleErrorMessage = () => {
    setSuccess(false)
    setHasErrors(true)
  }

  const {
    status: weekGroupsStatus,
    data: weekGroups,
    isLoading: gettingWeekGroups,
    error: getWeekGroupsError,
    mutate: getWeekGroups,
  } = useGetWeekGroups({
    onSuccess: data => {
      if (data?.length > 0) {
        const currentFiscalYear = getFiscalYear(new Date())
        const selectedFiscalYear = 2000 + Number(selectedControlNumber)
        const isLastFiscalYear = selectedFiscalYear < currentFiscalYear

        const hasNewWeekGroupYear = data.some(wg => selectedFiscalYear < wg.weekGroupYear)
        const hasInvalidControlNumber = selectedFiscalYear > currentFiscalYear

        if (hasNewWeekGroupYear) {
          handleErrorMessage()
          setErrorMessage({
            error: 'Population Error',
            content: 'You can not upload a week group for an old control number when there is already orders for the latest control number.',
          })
          return
        }

        if (hasInvalidControlNumber) {
          handleErrorMessage()
          setErrorMessage({
            error: 'Population Error',
            content: 'You can not upload a week group for a new control number when it\'s newer then the current fiscal year control number.',
          })
          return
        }

        const filteredWeekGroups = data
          .filter(wg => wg.weekGroupYear === 2000 + Number(selectedControlNumber))
          .sort((a, b) => ((a.createdAt < b.createdAt) ? 1 : -1))
        const mostRecentWeekGroup = filteredWeekGroups[0]
        const newSpecialOrderNumber = mostRecentWeekGroup ? mostRecentWeekGroup.lastSpecialOrderNumber + 1 : 1

        setMilpdsWeekGroup(calculateNewWeekGroupName(isLastFiscalYear))
        setMilpdsSpecialOrderNumber(newSpecialOrderNumber.toString())
      }
    },
  })

  const isLoading = uploadingOrders || pullingOrders || gettingWeekGroups

  useEffect(() => {
    if (uploadStatus === 'error') {
      handleErrorMessage()
      setErrorMessage(uploadError.response.data)
    } else if (uploadStatus === 'success') {
      handleSuccessMessage()
      setSuccessMessage('Successfully Uploaded Week Group')
    }
  }, [uploadStatus, uploadError])

  useEffect(() => {
    if (pullStatus === 'error') {
      handleErrorMessage()
      setErrorMessage({ error: 'MilPDS Order Pull Error', content: pullError.response.data.error })
    } else if (pullStatus === 'success' && pullResponseMessage && pullResponseMessage.data) {
      handleSuccessMessage()
      setSuccessMessage(pullResponseMessage.data.data)
    }
  }, [pullStatus, pullError, pullResponseMessage])

  useEffect(() => {
    if (weekGroupsStatus === 'error') {
      handleErrorMessage()
      setErrorMessage({
        error: 'Population Error',
        content: 'An unexpected error occurred while trying to retrieve the data needed to fill out the form. ' +
          'Please try again later or contact our support team for assistance.',
      })
    } else if (weekGroupsStatus === 'success' && weekGroups && weekGroups.length === 0) {
      handleErrorMessage()
      setErrorMessage({
        error: 'Population Error',
        content: 'There are currently no available week groups to auto-populate from. Please manually enter the week group data to continue.',
      })
    }
  }, [weekGroupsStatus, weekGroups, getWeekGroupsError])

  const uploadOrdersData = () => {
    uploadWeekGroup({ specialOrderNumber, weekGroup, selectedFile, selectedControlNumber })
  }

  const orderForms = [
    {
      menuItem: 'Auto/MilPDS Orders Pull',
      pane: {
        key: 'milpds-orders',
        size: 'massive',
        content: (
          <div style={ { margin: spacing.m } }>
            <Grid>
              <Form>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center'>
                    <LargeFieldLabel data-testid='auto-control-number-label'>Control Number</LargeFieldLabel>
                    <Input
                      fluid
                      value={ selectedControlNumber }
                      data-testid='automated-control-number-input'
                      placeholder='Enter Control Number (YY)'
                      type='string'
                      onChange={ (_, event) => onControlNumberChange(event.value, setSelectedControlNumber) }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center'>
                    <div style={ { paddingTop: `${spacing.s}px` } }>
                      <Button data-testid='auto-populate-fields-button' style={ { width: '100%' } } disabled={ milpdsSpecialOrderNumber || milpdsWeekGroup } onClick={ () => getWeekGroups() }>
                        Auto-Populate Fields
                      </Button>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center'>
                    <div style={ { paddingTop: `${spacing.s}px`, maxWidth: '300px' } }>
                      <p data-testid='special-order-number-and-week-group-description'>
                        { `Special Order Number and Week Group will be pre-populated for your convenience ` +
                        `based off the most recent week group for the entered control number.` }
                      </p>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center' style={ { paddingTop: `${spacing.m}px` } }>
                    <Divider />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center'>
                    <LargeFieldLabel data-testid='auto-special-order-number-label'>Special Order Number</LargeFieldLabel>
                    <Input
                      fluid
                      value={ milpdsSpecialOrderNumber }
                      data-testid='automated-special-order-number-input'
                      placeholder='Enter Special Order Number'
                      defaultValue={ milpdsSpecialOrderNumber || '' }
                      type='string'
                      onChange={ (eventData, event) => validateSpecialOrderNumber(eventData, event.value, setMilpdsSpecialOrderNumber) }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center'>
                    <LargeFieldLabel data-testid='auto-week-group-label'>Week Group</LargeFieldLabel>
                    <Input
                      fluid
                      value={ milpdsWeekGroup }
                      data-testid='automated-week-group-input'
                      placeholder='Enter Week Group'
                      defaultValue={ milpdsWeekGroup || '' }
                      type='string'
                      onChange={ (eventData, event) => validateWeekGroup(eventData, event.value, setMilpdsWeekGroup) }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center'>
                    <div style={ { paddingTop: `${spacing.m}px` } }>
                      <ActionButton
                        data-testid='pull-orders-button'
                        style={ { width: '100%' } }
                        onClick={ () => pullMilpdsOrders({ milpdsSpecialOrderNumber, milpdsWeekGroup }) }
                        disabled={ !milpdsSpecialOrderNumber || !milpdsWeekGroup }>
                        Pull Orders
                      </ActionButton>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Form>
            </Grid>
          </div>
        ),
      },
    },
    {
      menuItem: 'Manual Orders Pull',
      pane: {
        key: 'upload-orders',
        size: 'massive',
        content: (
          <div style={ { margin: spacing.m } }>
            <Grid>
              <Form>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center'>
                    <LargeFieldLabel data-testid='manual-control-number-label'>Control Number</LargeFieldLabel>
                    <Input
                      fluid
                      value={ selectedControlNumber }
                      data-testid='control-number-input'
                      placeholder='Enter Control Number (YY)'
                      type='string'
                      onChange={ (_, event) => onControlNumberChange(event.value, setSelectedControlNumber) }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center'>
                    <LargeFieldLabel data-testid='manual-special-order-number-label'>Special Order Number</LargeFieldLabel>
                    <Input
                      fluid
                      value={ specialOrderNumber }
                      data-testid='special-order-number-input'
                      placeholder='Enter Special Order Number'
                      defaultValue={ specialOrderNumber || '' }
                      type='string'
                      onChange={ (eventData, event) => validateSpecialOrderNumber(eventData, event.value, setSpecialOrderNumber) }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center'>
                    <LargeFieldLabel data-testid='manual-week-group-label'>Week Group</LargeFieldLabel>
                    <Input
                      fluid
                      value={ weekGroup }
                      data-testid='week-group-input'
                      placeholder='Enter Week Group'
                      defaultValue={ weekGroup || '' }
                      type='string'
                      onChange={ (eventData, event) => validateWeekGroup(eventData, event.value, setWeekGroup) }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center'>
                    <LargeFieldLabel data-testid='file-label'>File</LargeFieldLabel>
                    <Input
                      fluid
                      data-testid='file-input'
                      type='file'
                      accept='.xls, .xlsx'
                      onChange={ event => setSelectedFile(event.target.files[0]) }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center'>
                    <div style={ { paddingTop: `${spacing.m}px` } }>
                      <ActionButton
                        data-testid='upload-orders-button'
                        style={ { width: '100%' } }
                        onClick={ uploadOrdersData }
                        disabled={ !selectedFile || !specialOrderNumber || !weekGroup || !selectedControlNumber }>
                        Upload Orders
                      </ActionButton>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Form>
            </Grid>
          </div>
        ),
      },
    },
  ]

  return (
    <>
      <ThemeProvider theme={ LightPalette }>
        <Dimmer.Dimmable dimmed={ isLoading }>
          <Dimmer active={ isLoading } page>
            <Loader>Loading</Loader>
          </Dimmer>
          <NewOpaPageHeader
            hideNav={ !isOpaAdmin }
            pageRoute={ NAV_ROUTES.UPLOAD_WEEK_GROUP_PAGE }
            pageTitle='Upload Week Group'
          />
          { hasErrors && Object.keys(errorMessage).length !== 0 &&
            <Message
              data-testid='orders-error-text'
              icon='cancel'
              color='red'
              onDismiss={ handleDismiss }
              header={ errorMessage.error }
              content={ () => {
                if (errorMessage.headers) {
                  return errorMessage.headers
                } else if (errorMessage.content) {
                  return errorMessage.content
                } else if (errorMessage.specialOrderNumber) {
                  return errorMessage.specialOrderNumber
                } else if (errorMessage.controlNumber) {
                  return errorMessage.controlNumber
                } else {
                  return 'An unknown error occurred while uploading the week group. ' +
                    'If this error continues to happen, please contact our support team for assistance.'
                }
              } }
            />
          }
          { success &&
            <Message
              data-testid='orders-success-text'
              icon='check circle outline'
              color='green'
              onDismiss={ handleDismiss }
              header='Success'
              content={ successMessage }
            />
          }
          <Tab
            renderActiveOnly={ false }
            menu={ { fluid: true, tabular: true } }
            panes={ orderForms }
          />
        </Dimmer.Dimmable>
      </ThemeProvider>
    </>
  )
}
