import * as React from 'react';

function Flight({ size = 24, color, ...rest }) {
  return (
    <svg width={ size } height={ size } viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M12.5 11.875L6.59414 5.59456C6.07131 5.03062 6.15504 4.6187 6.77161 4.26206C7.93067 3.59162 8.83361 3.57341 10.0553 4.24389L16.1862 7.60844C16.6227 7.84795 17.0507 8.09074 17.5 8.22319" stroke={ color } stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.625 17.079L18.2629 25.5871C18.4782 26.2819 18.8575 26.4079 19.445 26.071C20.5495 25.4376 20.9947 24.7062 21.0246 23.3884L21.1744 16.7747C21.1944 15.8914 21.1907 15.0269 21.875 14.375" stroke={ color } stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.4106 13.7304L12.7693 12.007L18.2971 7.9767L18.302 7.97314L18.3114 7.96626C18.4419 7.87076 20.3868 6.45297 21.4899 5.96976C22.8456 5.37584 24.1086 5.65195 25.4674 6.03144C26.1703 6.22772 26.5216 6.32586 26.7753 6.50896C27.1775 6.79947 27.4391 7.2466 27.4929 7.73565C27.5269 8.04387 27.4373 8.39324 27.2581 9.09195C26.9118 10.4429 26.5226 11.6602 25.3235 12.5219C24.3478 13.223 22.1306 14.1762 21.982 14.2399L21.9714 14.2444L21.9658 14.2467L15.6646 16.9556L12.9728 18.1095C11.9968 18.5279 11.5088 18.737 11.1767 19.1252C10.3992 20.0344 10.2894 21.6797 9.99914 22.8117C9.83877 23.4372 8.95935 24.5214 8.17573 24.3586C7.69194 24.2581 7.68267 23.6525 7.62245 23.2664L7.04275 19.5486C6.90413 18.6595 6.89349 18.6414 6.18249 18.0784L3.20946 15.7241C2.90066 15.4795 2.37379 15.1687 2.5275 14.7051C2.77649 13.9541 4.1676 13.7446 4.79684 13.9204C5.93543 14.2384 7.43434 14.9672 8.62105 14.7572C9.12775 14.6676 9.55538 14.3552 10.4106 13.7304Z" stroke={ color } stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  );
}

export default Flight;
