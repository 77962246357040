import React from 'react'
import { AfcepRequestLegalInfo, AfcepRequestSectionContainer } from './AfcepLandingPage.styles'

export const AfcepLandingPageRequiredReading = () => {
  return (
    <AfcepRequestSectionContainer>
      <h3 data-testid='required-reading-header'>Required Reading:</h3>
      <AfcepRequestLegalInfo data-testid='legal-info'>
        <p><b>Authority:</b> 10 U.S.C. 8013, Secretary of the Air Force; 10 U.S.C. 716, Commissioned officers: transfers among the armed forces, the
          National Oceanic and Atmospheric Administration, and the Public Health Service; 10 U.S.C. Chapter 1205, Appointment of Reserve Officers;
          10 U.S.C. 12301, Reserve Components Generally; 10 U.S.C. 12320, Reserve Officers: grade in which ordered to active duty;
          Department of Defense Instruction 1310.02, Appointing Commissioned Officers; Air Force Policy Directive 36-20, Accession of
          Air Force Military Personnel; Air Force Instruction (AFI) 36-2004, Interservice Transfer of Officers to the United States Air Force (UASF)
          and the United States Air Force Reserve (USAFR); Air Force Instruction (AFMAN) 36-2032, Military Recruiting and Accessions; and E.O. 9397
          (SSN), as amended.
        </p>
      </AfcepRequestLegalInfo>
    </AfcepRequestSectionContainer>
  )
}
