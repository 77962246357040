import { ThemeProvider } from '@emotion/react'
import React, { useEffect } from 'react'
import { Dimmer, Divider, Loader } from 'semantic-ui-react'
import PageHeader from '../../../components/Page/Header/PageHeader'
import { PageSubtitle, PageTitle } from '../../../components/Page/PageTitle/PageTitle.styles'
import SvgAfBird from '../../../icons/AfBird'
import { DarkPalette, LightPalette } from '../../../theme'
import {
  AfcepRequestContent,
  AfcepRequestContentWrapper
} from './AfcepLandingPage.styles'
import { withRouter } from 'react-router-dom'
import { NAV_ROUTES } from '../../../navigation/routes'
import * as afcepQueries from '../afcep.queries'
import { E2O_EVENT_TYPE } from '../../../components/event/event.duck'
import { AfcepLandingPageRequiredReading } from './AfcepLandingPageRequiredReading'
import { AfcepLandingPageBeforeYouProceed } from './AfcepLandingPageBeforeYouProceed'
import { AfcepLandingPageActionButtons } from './AfcepLandingPageActionButtons'
import { AfcepLandingPageFooter } from './AfcepLandingPageFooter'

const AfcepLandingPage = () => {
  const urlParams = new URLSearchParams(location.search)
  const eventId = urlParams.get('eventId')

  const checkIfEventIdValue = afcepQueries.useCheckIfEventIdValid(E2O_EVENT_TYPE, eventId)
  const {
    isError: eventIdCheckIsError,
    isPending: eventIdCheckIsPending,
    isSuccess: eventIdCheckIsSuccess,
    isLoading: eventIdCheckIsLoading,
    data: eventIdCheckData,
  } = checkIfEventIdValue

  useEffect(() => {
    if (eventIdCheckIsError) {
      window.location.replace(NAV_ROUTES.AFCEP_INVALID_EVENT)
    }
  }, [eventIdCheckIsError])
  return (
    <ThemeProvider theme={ LightPalette }>
      <Dimmer.Dimmable dimmed={ eventIdCheckIsLoading || eventIdCheckIsPending }>
        <Dimmer active={ eventIdCheckIsLoading || eventIdCheckIsPending }>
          <Loader>Loading</Loader>
        </Dimmer>
        { eventIdCheckIsSuccess ? (
          <>
            <ThemeProvider theme={ DarkPalette }>
              <PageHeader>
                <SvgAfBird data-testid='af-bird-logo' size={ 144 } color={ LightPalette.background.level0 } />
                <PageTitle data-testid='afcep-request-page-title'> AFCEP Request</PageTitle>
                <PageSubtitle data-testid='event-name'>
                  Line Officer Accession Board: { eventIdCheckData.title }
                </PageSubtitle>
                { eventIdCheckData ? (
                  <PageSubtitle data-testid='afcep-request-window-closes'>
                    AFCEP Request Window Closes: { new Date(eventIdCheckData?.endTime).toLocaleDateString('en-US') }
                  </PageSubtitle>
                ) : <></>
                }
              </PageHeader>
            </ThemeProvider>
            <AfcepRequestContentWrapper>
              <AfcepRequestContent>
                <AfcepLandingPageRequiredReading />
                <AfcepLandingPageBeforeYouProceed />
                <Divider />
                <AfcepLandingPageActionButtons eventId={ eventId } />
              </AfcepRequestContent>
            </AfcepRequestContentWrapper>
            <AfcepLandingPageFooter />
          </>
        ) : <></> }
      </Dimmer.Dimmable>
    </ThemeProvider>
  )
}

export default withRouter(AfcepLandingPage)
