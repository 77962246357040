/* eslint-disable */
import * as React from 'react';

function Groups(props) {
  return (
    <svg width={ props.size } height={ props.size } viewBox="0 0 32 32" { ...props }>
      <path
        d="M10.333 6.6h9.334v5.6h-9.334V6.6zm1.867 0V3.8A2.8 2.8 0 0115 1h0a2.8 2.8 0 012.8 2.8v2.8m5.6 14a2.8 2.8 0 100-5.6 2.8 2.8 0 000 5.6zm-16.8 0a2.8 2.8 0 100-5.6 2.8 2.8 0 000 5.6zm5.6 8.4v-2.784c0-.663-.347-1.272-.917-1.61C10.374 24.069 8.8 23.4 6.6 23.4c-2.229 0-3.793.665-4.69 1.202-.567.338-.91.946-.91 1.605V29h11.2zM29 29v-2.784c0-.663-.347-1.272-.917-1.61-.909-.537-2.482-1.206-4.683-1.206-2.229 0-3.793.665-4.69 1.202-.567.338-.91.946-.91 1.605V29H29z"
        stroke={ props.color }
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Groups;
