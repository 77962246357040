import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectSelectedReferral, selectIsReferralModalOpen } from '../../redux/selectors/referral.selectors'
import {
  setPreviousReferral,
  setNextReferral,
  toggleReferralModal,
  markReferralCompleted } from '../../redux/sagas/referral.sagas'
import styled from '@emotion/styled'
import 'semantic-ui-css/semantic.min.css'
import { Header, Modal, Button, Checkbox } from 'semantic-ui-react'
import { getStandardAmericanDate } from '../../api/date.util'

const DescriptionWrapper = styled.div`
  display: flex;
`

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing.s}px
`
const InfoLabel = styled.div`
  align-self: flex-end;
  width: 270px;
`

const InfoValue = styled.div`
  width: 100%;
  color: #2185d0;
`

class ReferralModal extends Component {

  handleCopy = text => {
    const el = document.createElement('textarea')
    el.value = text
    el.setAttribute('readonly', '')
    el.style = { position: 'absolute', left: '-9999px' }
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  handleToggleReferralModal = () => {
    const { onToggleReferralModal } = this.props
    onToggleReferralModal()
  }

  handlePreviousClicked = () => {
    const { onSetPreviousReferral } = this.props
    onSetPreviousReferral()
  }

  handleNextClicked = () => {
    const { onSetNextReferral } = this.props
    onSetNextReferral()
  }

  handleMarkReferralCompleted = referral => {
    const { onMarkReferralCompleted } = this.props
    onMarkReferralCompleted(referral)
  }

  renderInfo = (label, value) => {
    return (
      <InfoContainer>
        <InfoLabel><Header size='small'>{ label }: </Header></InfoLabel>
        <InfoValue onClick={ () => this.handleCopy(value) }>{ value }</InfoValue>
      </InfoContainer>
    )
  }

  render() {
    const { referral, isReferralModalOpen } = this.props

    if (!referral) {
      return null
    }

    const fullName = `${referral.firstName || ''} ${referral.middleName || ''} ${referral.lastName || ''}`
    const submitted = getStandardAmericanDate(referral.createdAt)
    const fastTrack = referral.fastTrack ? 'Yes' : 'No'
    const dateOfBirth = getStandardAmericanDate(referral.dateOfBirth)
    const contactByAirForce = referral.contactByAirForce ? 'Yes' : 'No'
    const contactByAirNationalGuard = referral.contactByAirNationalGuard ? 'Yes' : 'No'
    const contactByTheReserve = referral.contactByTheReserve ? 'Yes' : 'No'

    return (
      <Modal
        open={ isReferralModalOpen }
        closeIcon
        onClose={ () => this.handleToggleReferralModal() }>
        <Modal.Header>{ fullName } <span style={ { float: 'right' } }>{ submitted }</span></Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <DescriptionWrapper>
              <div style={ { marginRight: '20px' } }>
                <Header>INFORMATION</Header>
                { this.renderInfo('First', referral.firstName) }
                { this.renderInfo('Middle', referral.middleName) }
                { this.renderInfo('Last', referral.lastName) }
                { this.renderInfo('DoB', dateOfBirth) }
                { this.renderInfo('Gender', referral.gender) }
                { this.renderInfo('Phone', referral.phoneNumber) }
                { this.renderInfo('Email', referral.email) }
                { this.renderInfo('Address', referral.address) }
                { this.renderInfo('City', referral.city) }
                { this.renderInfo('State', referral.state) }
                { this.renderInfo('Zipcode', referral.zipCode) }
                { this.renderInfo('Citizenship', referral.citizenship) }
                { this.renderInfo('Education', referral.educationLevel) }
                { this.renderInfo('Armed Service', referral.armedServices) }
                { this.renderInfo('Join Military', referral.militaryLikelihood) }
                { this.renderInfo('Fast Track', fastTrack) }
              </div>
              <div style={ { width: '100%' } }>
                <Header>PREFERENCES</Header>
                { this.renderInfo('United States AF', contactByAirForce) }
                { this.renderInfo('Air National Guard', contactByAirNationalGuard) }
                { this.renderInfo('Reserves', contactByTheReserve) }
                { this.renderInfo('Method', referral.preferredMethodOfContact) }
              </div>
            </DescriptionWrapper>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Checkbox
            toggle
            color='green'
            style={ { marginRight: '20px' } }
            checked={ referral.completed }
            onClick={ () => this.handleMarkReferralCompleted(referral) }
          />
          <Button onClick={ this.handlePreviousClicked }>Previous</Button>
          <Button onClick={ this.handleNextClicked }>Next</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

ReferralModal.propTypes = {
  referral: PropTypes.object,
  isReferralModalOpen: PropTypes.bool,
  onSetPreviousReferral: PropTypes.func,
  onSetNextReferral: PropTypes.func,
  onToggleReferralModal: PropTypes.func,
  onMarkReferralCompleted: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    referral: selectSelectedReferral(state),
    isReferralModalOpen: selectIsReferralModalOpen(state),
  }
}

const mapActionsToProps = dispatch => {
  return {
    onSetPreviousReferral: referralId => dispatch(setPreviousReferral.request(referralId)),
    onSetNextReferral: referralId => dispatch(setNextReferral.request(referralId)),
    onToggleReferralModal: () => dispatch(toggleReferralModal.request()),
    onMarkReferralCompleted: referral => dispatch(markReferralCompleted.request(referral)),
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReferralModal)

