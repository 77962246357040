/* eslint-disable max-len */
import React from 'react'
import { withTheme } from '@emotion/react';

const SvgExplore = withTheme(({ color, tone, theme, size = 32, ...props }) => {
  color = color || theme.text[tone] || theme.text.contrast;
  return (
    <svg width={ size } height={ size } viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <title>a search</title>
      <g className="nc-icon-wrapper" fill={ color }>
        <path fill={ color } d="M14,17c4.579,0,8-4.751,8-9c0-4.411-3.589-8-8-8S6,3.589,6,8C6,12.249,9.421,17,14,17z" />
        <path fill={ color } d="M16,24c0-1.813,0.613-3.481,1.633-4.824C16.517,19.068,15.314,19,14,19c-5.647,0-9.442,1.158-11.632,2.129 C0.93,21.767,0,23.201,0,24.783V30c0,0.552,0.448,1,1,1h19.136C17.673,29.635,16,27.01,16,24z" />
        <path data-color="color-2" d="M31.707,30.293l-2.822-2.822C29.584,26.49,30,25.294,30,24c0-3.309-2.691-6-6-6s-6,2.691-6,6 s2.691,6,6,6c1.294,0,2.49-0.416,3.471-1.115l2.822,2.822C30.488,31.902,30.744,32,31,32s0.512-0.098,0.707-0.293 C32.098,31.316,32.098,30.684,31.707,30.293z M20,24c0-2.206,1.794-4,4-4s4,1.794,4,4s-1.794,4-4,4S20,26.206,20,24z" />
      </g>
    </svg>
  )
})

SvgExplore.displayName = SvgExplore
export default SvgExplore
