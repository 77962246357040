import React from 'react'
import PropTypes from 'prop-types'
import { ShareContentTitleWrapper, Title } from './ShareContentTitle.styles'
import { shareContentTypes } from '../../redux/constants/share.constants'

export const shareContentTitles = {
  SHARE_TEASER_BMT_PHOTO_TITLE: 'Explore more photos with the aim high app',
  SHARE_TEASER_CAREER_TITLE: 'Explore more careers with the aim high app',
  SHARE_TEASER_EVENT_TITLE: 'Discover more events with the aim high app',
  SHARE_TEASER_SHARE_APP: 'Discover what the Air Force has to offer',
}

const getShareTitleText = shareContentType => {
  switch (shareContentType) {
    case shareContentTypes.BMT_PHOTO:
      return shareContentTitles.SHARE_TEASER_BMT_PHOTO_TITLE

    case shareContentTypes.CAREER:
      return shareContentTitles.SHARE_TEASER_CAREER_TITLE

    case shareContentTypes.SHARE:
      return shareContentTitles.SHARE_TEASER_SHARE_APP

    default:
      return shareContentTitles.SHARE_TEASER_BMT_PHOTO_TITLE
  }
}

const ShareContentTitle = ({ shareContentType, label }) => {
  return (
    <ShareContentTitleWrapper data-testid='share-content-title'>
      { label && <Title>{ label }</Title> }
      { !label && <Title>{ getShareTitleText(shareContentType) }</Title> }
    </ShareContentTitleWrapper>
  )
}

ShareContentTitle.propTypes = {
  shareContentType: PropTypes.string,
  label: PropTypes.string,
}

export default ShareContentTitle
