const amplitudeConfig = {
  amplitudePointer: '',
}

switch (process.env.REACT_APP_ENVIRONMENT) {
  case 'production':
    amplitudeConfig.amplitudePointer = '9d153c98de42f52e814f1cd9decc8ddb'
    break
  case 'staging':
    amplitudeConfig.amplitudePointer = '4efe2dd2307c7d9dae2eaf761e342886'
    break
  default:
    // Develop
    amplitudeConfig.amplitudePointer = '68d1e8eb23f103ef130748fba085256a'
    break
}

export default {
  ...amplitudeConfig,
}
