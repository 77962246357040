/*eslint-disable*/
import * as React from 'react';

function Squadrons(props) {
  return (
    <svg width={ props.size } height={ props.size } viewBox="0 0 32 32" { ...props }>
      <path
        d="M15 23.4c2.462 0 4.508.165 6.05.358a3.736 3.736 0 013.283 3.708V29H5.667v-1.534a3.736 3.736 0 013.282-3.708A49.174 49.174 0 0115 23.4zm0-12.133a4.667 4.667 0 110 9.333 4.667 4.667 0 010-9.333zm-11.2-5.6a2.8 2.8 0 110 5.6 2.8 2.8 0 010-5.6zm22.4 0a2.8 2.8 0 110 5.6 2.8 2.8 0 010-5.6zM15 1a2.8 2.8 0 110 5.6A2.8 2.8 0 0115 1z"
        stroke={ props.color }
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Squadrons;
