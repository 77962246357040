import React from 'react'
import theme, { LightPalette } from '../../../theme/'

export const Message = ({ children, hasError, style, ...props }) => {
  const messageStyle = {
    textAlign: 'left',
    fontWeight: theme.typography.fontWeights.bold,
    color: hasError ? LightPalette.text.negative : LightPalette.text.positive,
    paddingTop: theme.spacing.s,
    paddingBottom: theme.spacing.s,
    ...style,
  }
  return <div style={ messageStyle } { ...props }>{ children }</div>

}
