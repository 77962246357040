import { put, takeLatest } from 'redux-saga/effects'
import { startupActions } from '../constants/startup.constants'
import { GET_FEATURE_TOGGLES } from '../../featureToggles/featureToggleDuck'
import AsyncSaga from './util/AsyncSaga'

export const appStartup = new AsyncSaga(startupActions.RUN_STARTUP_SAGA, function* appStartupSaga() {
  yield put(AsyncSaga.createRequest(GET_FEATURE_TOGGLES))
})

export default [
  takeLatest(appStartup.REQUEST, appStartup.saga),
]
