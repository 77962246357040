import PropTypes from 'prop-types'
import React from 'react'
import { Dropdown } from 'semantic-ui-react'

export const CustomDropdown = ({ variation, variationIndex, defaultCase, dropdownOptions, placeholder, dropdownValues, updateFunction }) => {
  return (
    <div style={ { width: '100%' } }>
      {
        variation.default ?
          defaultCase :
          <Dropdown
            fluid
            search
            selection
            multiple
            clearable
            options={ dropdownOptions }
            placeholder={ placeholder }
            value={ dropdownValues }
            onChange={ (_e, data) => updateFunction(data, variationIndex) }
          />
      }
    </div>
  )
}

CustomDropdown.propTypes = {
  variation: PropTypes.object,
  variationIndex: PropTypes.number,
  defaultCase: PropTypes.element,
  dropdownOptions: PropTypes.array,
  placeholder: PropTypes.string,
  dropdownValues: PropTypes.array,
  updateFunction: PropTypes.func,
}
