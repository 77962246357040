import { createSelector } from 'reselect'
import { squadronActions } from '../constants/squadrons.constants'
import { createErrorSelector } from './common.selectors'

export const selectSquadron = state => state.squadron

export const selectSquadrons = createSelector(
  [selectSquadron],
  squadron => squadron.squadrons
)

export const selectFlights = createSelector(
  [selectSquadron],
  squadron => squadron.flights
)

export const selectFlightsForSquadron = id => createSelector(
  [selectFlights],
  flights => flights.filter(f => f.id === id)
)

export const selectLastSquadronKey = createSelector(
  [selectSquadron],
  squadron => squadron.squadronLastKey
)

export const selectCreateSquadronErrors = createErrorSelector(squadronActions.CREATE_SQUADRON)
export const selectFetchSquadronErrors = createErrorSelector(squadronActions.FETCH_SQUADRONS)
export const selectUpdateSquadronErrors = createErrorSelector(squadronActions.UPDATE_SQUADRON)
export const selectDeleteSquadronErrors = createErrorSelector(squadronActions.DELETE_SQUADRON)
export const selectGetSquadronFlightsErrors = createErrorSelector(squadronActions.GET_SQUADRON_FLIGHTS)
export const selectCreateFlightErrors = createErrorSelector(squadronActions.CREATE_FLIGHT)
export const selectDeleteFlightErrors = createErrorSelector(squadronActions.DELETE_FLIGHT)
