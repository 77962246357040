/*eslint-disable*/
import * as React from "react";

function LineOfficerRedirect({ size, color, ...rest }) {
  return (
    <svg width={ size } height={ size } viewBox="0 0 32 32" x="0px" y="0px" {...rest}>
      <path fill={ color } d="M25,4H10.41l2.3-2.29A1,1,0,1,0,11.29.29l-4,4a1,1,0,0,0,0,1.42l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L10.41,6H25a3,3,0,0,1,3,3V24a1,1,0,0,0,2,0V9A5,5,0,0,0,25,4Z"/>
      <path fill={ color } d="M20.71,22.29a1,1,0,0,0-1.42,1.42L21.59,26H7a3,3,0,0,1-3-3V8A1,1,0,0,0,2,8V23a5,5,0,0,0,5,5H21.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,0-1.42Z"/>
    </svg>
  );
}

export default LineOfficerRedirect;
