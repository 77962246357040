import PropTypes from 'prop-types'
import * as React from 'react'

function DownloadFile(props) {
  return (
    <svg width={ props.size } height={ props.size } viewBox="6 0 25 25" { ...props }>
      <path
        // eslint-disable-next-line max-len
        d="M16.1052632,17.0526316 L16.1052632,2 M19.9649123,13.1929825 L16.1052632,17.0526316 L12.245614,13.1929825 M19.1929825,10.1052632 L24.2105263,10.1052632 L24.2105263,24 L8,24 L8,10.1052632 L13.0175439,10.1052632"
        stroke={ props.color }
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

DownloadFile.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

export default DownloadFile
