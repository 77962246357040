import { ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import axios from 'axios'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import Card from '../../components/Card/Card'
import CardFooter from '../../components/Card/CardFooter'
import CardHeader from '../../components/Card/CardHeader'
import CardItem from '../../components/Card/CardItem'
import CardItemDivider from '../../components/Card/CardItemDivider'
import ShareTeaser from '../../components/Share/ShareTeaser'
import config from '../../config'
import SvgAirForce from '../../icons/AirForce'
import { NAV_ROUTES } from '../../navigation/routes'
import { DarkPalette, LightPalette } from '../../theme'
import { Content, Footer, Header, Logo, Main, Page } from './share.page.styles'
import spacing from '../../theme/spacing'

export const ShareText = styled.div`
  font-family: stratum2-regular;
  color: ${props => props.theme.text.subtle};
  font-weight: bold;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`

export const FlightName = styled.div`
  font-family: stratum2-regular;
  color: ${props => props.theme.text.contrast};
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.01em;
  line-height: 32px;
  text-transform: uppercase;
`

const afreServiceUrl = config.apiUrl
const websiteHostUrl = config.websiteHostUrl
const defaultErrorLoadingImageText = 'Error loading image'
const FlightPhotoSharePage = ({ match }) => {
  const [photo, setPhoto] = useState('')
  const [photoError, setPhotoError] = useState('')
  const [shareUrls, setShareUrls] = useState({})
  const { flight } = match.params

  useEffect(() => {
    (async () => {
      const { activity, page, index } = match.params
      const url = `${afreServiceUrl}/photos/${flight}/${activity}/${page}/${index}?size=large`
      const headers = {
        'Content-Type': 'application/json',
      }
      const shareImageUrl = `${websiteHostUrl}/share/flightphoto/${flight}/${activity}/${page}/${index}`
      const facebookURL = `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareImageUrl)}`
      const twitterURL = `https://twitter.com/intent/tweet/?text=${encodeURIComponent('Aim High')}&url=${encodeURIComponent(shareImageUrl)}`
      const linkedInURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareImageUrl)}`

      setShareUrls({ facebookURL, twitterURL, linkedInURL })
      try {
        setPhotoError('')
        const response = await axios.get(url, { headers })
        if (response.status === 200 && response.data) {
          if (response.data.photosUnavailable) {
            setPhotoError('We apologize for the inconvenience, it seems we are having trouble retrieving photos.')
          } else if (!!response.data.data && response.data.data.image) {
            const uri = decodeURI(response.data.data.image)
            setPhoto(uri)
          } else {
            setPhotoError(defaultErrorLoadingImageText)
          }
        }
      } catch (e) {
        setPhotoError(defaultErrorLoadingImageText)
      }
    })()
  }, [match, flight])

  const contactButton = (url, iconName) => {
    return (
      <span style={ { paddingRight: '15px' } }>
        <Button
          data-testid={ `${iconName}_contactButton` }
          as='a' href={ url } target='_blank' rel='noopener noreferrer'
          className='circular mini ui icon button'>
          <Icon className={ iconName } size='large' data-testid='air-force-logo' />
        </Button>
      </span>
    )
  }

  const title = flight ? `Flight ${flight} Basic Training` : 'Flight Basic Training'
  const text = `Every Air Force journey is different, but no matter where your career takes you, every
   path begins in training. Challenged both mentally and physically, this is where you'll get the skills
    and training to officially become Airmen.`

  return (
    <ThemeProvider theme={ DarkPalette }>
      <Helmet>
        <meta property='og:url' content={ window.location.href } />
        <meta property='og:type' content='image' />
        <meta property='og:title' content='' />
        <meta property='og:description' content='' />
        <meta property='og:image' content={ photo } />
      </Helmet>
      <Page>
        <Header>
          <Logo rel='noopener noreferrer' target='_blank' href='https://airforce.com/'>
            <SvgAirForce size={ 79 } data-testid='air-force-logo' />
          </Logo>
        </Header>
        <Main>
          <div style={ { flexDirection: 'column' } }>
            <Card>
              { photoError ?
                <><CardHeader />
                  <div style={ { padding: '20px' } }>{ photoError }</div>
                </> :
                <CardHeader backgroundImageUrl={ photo } />
              }
              <ThemeProvider theme={ LightPalette }>
                <CardItem style={ { paddingTop: '40px', paddingBottom: '20px' } }>
                  <FlightName data-testid='flight-name'>{ title }</FlightName>
                </CardItem>
                <CardItem style={ { paddingBottom: '20px' } } data-testid='flight-description'>
                  { text }
                </CardItem>
                <CardItem>
                  <CardItemDivider />
                </CardItem>
                <CardFooter style={ { alignItems: 'center' } }>
                  <ShareText data-testid='share-text' style={ { paddingRight: '20px' } }>Share</ShareText>
                  { contactButton(shareUrls.facebookURL, 'facebook f') }
                  { contactButton(shareUrls.twitterURL, 'twitter') }
                  { contactButton(shareUrls.linkedInURL, 'linkedin') }
                </CardFooter>
              </ThemeProvider>
            </Card>
            <p style={ { marginTop: spacing.m } } data-testid='disclaimer'>* DoD does not endorse lacklandphotos.com</p>
          </div>
          <Content>
            <ShareTeaser data-testid='share-teaser' />
          </Content>
        </Main>
        <Footer>
          <a href={ NAV_ROUTES.PRIVACY_POLICY_PAGE } rel='noopener noreferrer' target='_blank' data-testid='terms-and-conditions-link'>Terms and Conditions</a>
        </Footer>
      </Page>
    </ThemeProvider>
  )
}

FlightPhotoSharePage.propTypes = {
  match: PropTypes.object,
}

export default FlightPhotoSharePage
