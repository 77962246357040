import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Dropdown, Input } from 'semantic-ui-react'
import { ErrorLabel } from '../../components/Forms/Lead/ErrorLabel'
import { Header } from '../../components/Forms/Lead/Header'
import { Label } from '../../components/Forms/Lead/Label'
import { TwoColumnGrid } from '../../components/Forms/Lead/TwoColumnGrid'
import { minAge17 } from '../../forms/validation'
import Spacing from '../../theme/spacing'
import { HEIGHT_FEET, HEIGHT_INCHES } from './constants'

const FirstNameField = styled.div`
  grid-column: 1;
  grid-row: 1;
  padding-top: ${({ theme }) => theme.spacing.m}px;
  padding-bottom: ${({ theme }) => theme.spacing.s}px;
  padding-right: ${({ theme }) => theme.spacing.s}px;

  @media (max-width: 767px) {
    padding-right: 0;
    grid-column: span 2;
  }
`
const LastNameField = styled.div`
  grid-column: 2;
  grid-row: 1;
  padding-top: ${({ theme }) => theme.spacing.m}px;
  padding-bottom: ${({ theme }) => theme.spacing.s}px;  
  padding-left: ${({ theme }) => theme.spacing.s}px;

  @media (max-width: 767px) {
    padding-left: 0;
    grid-column: span 2;
    grid-row: 2;
  }
`
const DateOfBirthField = styled.div`
  grid-column: 1;
  grid-row: 1;
  padding-top: ${({ theme }) => theme.spacing.s}px;
  padding-bottom: ${({ theme }) => theme.spacing.m}px;
  padding-right: ${({ theme }) => theme.spacing.s}px;
  @media (max-width: 767px) {
    grid-column: span 2;
    padding-right: 0;
    padding-top: ${({ theme }) => theme.spacing.s}px;
    padding-bottom: ${({ theme }) => theme.spacing.s}px;
  }
`
const WeightField = styled.div`
  grid-column: 1;
  grid-row: 1;
  padding-top: ${({ theme }) => theme.spacing.s}px;
  padding-bottom: ${({ theme }) => theme.spacing.m}px;
  padding-right: ${({ theme }) => theme.spacing.s}px;
  @media (max-width: 767px) {
    grid-column: span 2;
    grid-row: 3;
    padding-right: 0;
  }
`
const HeightField = styled.div`
  grid-column: 2;
  grid-row: 1;
  padding-top: ${({ theme }) => theme.spacing.s}px;
  padding-bottom: ${({ theme }) => theme.spacing.m}px;
  padding-left: ${({ theme }) => theme.spacing.s}px;
  @media (max-width: 767px) {
    grid-column: span 2;
    grid-row: 2;
    padding-left: 0;
    padding-top: ${({ theme }) => theme.spacing.s}px;
    padding-bottom: ${({ theme }) => theme.spacing.s}px;
  }
`
const StyledDropdown = styled(Dropdown)`
  margin-top: ${({ theme }) => theme.spacing.m}px;
`
const StyledInput = styled(Input)`
  margin-top: ${({ theme }) => theme.spacing.m}px;
`

PersonalInformation.propTypes = {
  errors: PropTypes.object,
  dispatch: PropTypes.func,
  dispatchError: PropTypes.func,
}

function PersonalInformation({ dispatch, dispatchError, errors }) {
  const [dateOfBirthError, setDateOfBirthError] = useState('')
  const [weightError, setWeightError] = useState('')

  const onFocus = name => () => {
    dispatchError({ ...errors, [name]: false })
  }

  const onChange = name => (_event, { value }) => {
    dispatch({ type: name, value })
  }

  const validateEmpty = name => ({ target }) => {
    if (target.value === undefined || target.value === null || target.value.trim() === '') {
      dispatchError({ ...errors, [name]: true })
    }
  }

  const validateDateOfBirth = ({ target }) => {
    if (target.value === undefined || target.value === null || target.value.trim() === '') {
      dispatchError({ ...errors, dateOfBirth: true })
      return
    }

    const minAge = minAge17(target.value || 0)

    if (minAge) {
      setDateOfBirthError(minAge)
      dispatchError({ ...errors, dateOfBirth: true })
    } else {
      setDateOfBirthError()
    }
  }

  const validateWeight = ({ target }) => {
    if (target.value === undefined || target.value === null || target.value.trim() === '') {
      dispatchError({ ...errors, weight: true })
      return
    }

    const weight = parseInt(target.value, 10)
    const invalidRange = weight && weight < 400 && weight > 90 ? '' : 'Weight must be between 90 and 400'
    setWeightError(invalidRange)
    if (invalidRange) {
      dispatchError({ ...errors, weight: true })
    }
  }

  return (
    <>
      <Header>Personal Information</Header>
      <TwoColumnGrid>
        <FirstNameField>
          <Label htmlFor='firstName' error={ errors.firstName }>First Name</Label>
          <StyledInput
            id='firstName'
            data-testid='firstName'
            fluid
            error={ errors.firstName }
            onFocus={ onFocus('firstName') }
            onChange={ onChange('firstName') }
            onBlur={ validateEmpty('firstName') }
          />
        </FirstNameField>
        <LastNameField>
          <Label htmlFor='lastName' error={ errors.lastName }>Last Name</Label>
          <StyledInput
            id='lastName'
            data-testid='lastName'
            fluid
            error={ errors.lastName }
            onFocus={ onFocus('lastName') }
            onChange={ onChange('lastName') }
            onBlur={ validateEmpty('lastName') }
          />
        </LastNameField>
      </TwoColumnGrid>
      <TwoColumnGrid>
        <DateOfBirthField>
          <Label htmlFor='dateOfBirth' error={ errors.dateOfBirth }>Date of Birth</Label>
          <StyledInput
            id='dateOfBirth'
            data-testid='dateOfBirth'
            fluid
            type='date'
            error={ errors.dateOfBirth }
            onFocus={ onFocus('dateOfBirth') }
            onChange={ onChange('dateOfBirth') }
            onBlur={ validateDateOfBirth }
          />
          <ErrorLabel htmlFor='dateOfBirth'>{ dateOfBirthError }</ErrorLabel>
        </DateOfBirthField>
      </TwoColumnGrid>
      <TwoColumnGrid>
        <WeightField>
          <Label htmlFor='weight' error={ errors.weight }>Weight (lbs)</Label>
          <StyledInput
            id='weight'
            data-testid='weight'
            fluid
            type='number'
            min={ 0 }
            error={ errors.weight }
            onFocus={ onFocus('weight') }
            onChange={ (_event, { value }) => onChange('weight')(_event, { value: parseInt(value, 10) }) }
            onBlur={ validateWeight }
          />
          <ErrorLabel htmlFor='weight'>{ weightError }</ErrorLabel>
        </WeightField>
        <HeightField>
          <Label htmlFor='heightFeet' error={ errors.heightFeet }>Height</Label>
          <div style={ { display: 'flex', flexDirection: 'row' } }>
            <StyledDropdown
              id='heightFeet'
              data-testid='heightFeet'
              fluid
              selection
              error={ errors.heightFeet }
              options={ HEIGHT_FEET }
              onFocus={ onFocus('heightFeet') }
              onChange={ onChange('heightFeet') }
              style={ { marginRight: `${Spacing.s}px` } }
            />
            <StyledDropdown
              id='heightInches'
              data-testid='heightInches'
              fluid
              selection
              error={ errors.heightInches }
              options={ HEIGHT_INCHES }
              onFocus={ onFocus('heightInches') }
              onChange={ onChange('heightInches') }
              style={ { marginLeft: `${Spacing.s}px` } }
            />
          </div>
        </HeightField>
        
      </TwoColumnGrid>
    </>
  )
}

export default PersonalInformation
