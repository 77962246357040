import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import opa from '../opa/redux/ducks/opa.duck'
import featureToggle from '../featureToggles/featureToggleDuck'
import amplitudeAnalytics from '../redux/reducers/amplitudeAnalytics.reducers'
import appShare from '../components/appShare/appShare.duck'
import auth from '../redux/reducers/auth.reducers'
import career from '../components/careers/career.duck'
import common from '../redux/reducers/common.reducers'
import event from '../components/event/event.duck'
import referral from '../redux/reducers/referral.reducers'
import squadron from '../redux/reducers/squadron.reducers'
import user from '../redux/reducers/user.reducers'

export default () => combineReducers({
  auth,
  career,
  common,
  event,
  appShare,
  form,
  referral,
  user,
  squadron,
  amplitudeAnalytics,
  opa,
  featureToggleStore: featureToggle,
})
